import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class actividades{
  public name?: string;
  public initial_date?: Date;
  public end_date?: Date;
  public projectId?: number;
  public responsibleId?: number;
}

export class ponderacion{
  projectWeighing?: number;
}

export class historicoPeso{
  period?: string;
  weighing?: number;
}
export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 

export class proyectos{
  objectiveIds?: number[];
  name?: string;
  weighing?: number;
  responsibleId?: number;
  description?: string;
  strategyIds?: number[];
}

export class comment{
  comment?: string;
  created_at?: String;
}
export class historico{
  period?: string;
  planned?: number;
  executed?: number;
}
@Injectable({
  providedIn: 'root'
})
export class ProyectosestrategicosService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getEstrategias(): Observable<any>{
    const apiURL = this.apiURL+"estrategia";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsable(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectos(): Observable<any>{
    const apiURL = this.apiURL+"proyectos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getActividades(): Observable<any>{
    const apiURL = this.apiURL+"actividad";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getActividadesID(act): Observable<any>{
    const apiURL = this.apiURL+"actividad/"+act.id;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

  public postActividades(act: any): Observable<any>{
    const acti: actividades = {
      projectId: Number(act.projectId),
      initial_date: act.fecha_inicio.toISOString(),
      end_date: act.fecha_fin.toISOString(),
      name: act.nombre,
      responsibleId: Number(act.responsableId),
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"actividad";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postProyectos(pro: any): Observable<any>{
    var acti: proyectos = {
      name: pro.nombre,
      objectiveIds: pro.objetiveId,
      responsibleId: Number(pro.responsableId),
    };
    if( pro.programaId[0] !== null ){
      acti.strategyIds =  pro.programaId;
    }
    if( pro.descripcion !== '' ){
      acti.description = pro.descripcion;
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"proyectos";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      activityId: Number(adicional.actividadId),
      name: adicional.nombre,
      amount: Number(adicional.monto),
      note: adicional.nota,
      type: adicional.tipo,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postHistoricoPeso(peso: any, idPro: any, anio: string): Observable<any>{
    const pon: historicoPeso = {
      weighing: peso,
      period: anio,
    }
    const body = JSON.stringify(pon);
    const apiURL = this.apiURL+"proyectos/"+idPro+"/ponderacion-historial";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  

  public patchActividades(act: any): Observable<any>{
    const acti: actividades = {
      initial_date: act.fecha_inicio.toISOString(),
      end_date: act.fecha_fin.toISOString(),
      name: act.nombre,
      projectId: Number(act.projectId),
      responsibleId: Number(act.responsableId),
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"actividad/"+act.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchProyectos(pro: any): Observable<any>{
    var acti: proyectos = {
      name: pro.nombre,
      objectiveIds: pro.objetiveId,
      responsibleId: Number(pro.responsableId),
    };
    if( pro.programasId[0] !== null )
      acti.strategyIds =  pro.programasId;
    if( pro.descripcion !== '' )
      acti.description = pro.descripcion;
    
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"proyectos/"+pro.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchPonder(pro: any, proId: any, objeId: any): Observable<any>{
    const acti: ponderacion = {
      projectWeighing: pro.projectWeighing,
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"proyectos/"+proId+'/objective/'+objeId;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchProyectosPond(pro: any): Observable<any>{
    const acti: proyectos = {
      weighing: Number(pro.ponderacion),
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"proyectos/"+pro.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchAdicionales(adicional: any, id: any): Observable<any>{
    const ad: Adicionales = {
      activityId: Number(id),
      name: adicional.name,
      amount: Number(adicional.amount),
      files: adicional.files,
      note: adicional.note,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchHistoricoPeso(peso: any, idPro: any, idPes: string): Observable<any>{
    const pon: historicoPeso = {
      weighing: peso,
    }
    const body = JSON.stringify(pon);
    const apiURL = this.apiURL+"proyectos/"+idPro+"/ponderacion-historial/"+idPes;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public deleteActividades(act: any): Observable<any>{
    const apiURL = this.apiURL+"actividad/"+act.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteProyectos(pro: any): Observable<any>{
    const apiURL = this.apiURL+"proyectos/"+pro.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteHistory(act: any, his: any): Observable<any>{
    const apiURL = this.apiURL+"actividad/"+act.id+"/history/"+his.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public patchAdicionalesNombre(adicional: any): Observable<any>{
    const ad: Adicionales = {
      name: adicional.name,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteAdicional(adicional: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+Number(adicional.id);
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  
  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public postHistorico(his: any, idAct: number): Observable<any>{
    const hist: historico = {
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
}
