<div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row">
      <div class="w3-quarter padreizquierda">
        <div class="w3-row hijoizquierda">
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
        </div>
      </div>
  
      <div class="w3-rest">
        <div class="w3-noventa2" style="width: 100%;">
          <div class="w3-quarter">
            <div class="w3-half hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
                Años
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menuanios="matMenu">
                <ng-container *ngFor="let anio of aniosDisponibles">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                  (change)="cambioAnio(anio)"
                                  (click)="$event.stopPropagation();"
                                  value="{{anio.name}}" aria-label="First checkbox"
                                  >
                      {{ anio.name }}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <div class="w3-half hijoizquierda titulochips">
              <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
                <div class="w3-row chips">
                  <div [ngClass]="anios.check?
                  'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                              *ngFor="let anios of aniosDisponibles"
                              style="margin-left: 5px;" class="filtroMesAnioSeleccionado" >
                              <span class="contenidoChips">{{anios.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- lideres -->
          <div class="w3-third">
            <div class="w3-quarter hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menulider" aria-label="Example icon-button with a menu">
                Líder
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menulider="matMenu">
                <button mat-menu-item>
                  <mat-checkbox [checked]="selecAll"
                  (change)="cambioLider()"
                  (click)="$event.stopPropagation();"
                  aria-label="First checkbox"> <!--  -->
                    Seleccionar todos
                  </mat-checkbox>
                </button>
                <mat-divider></mat-divider>
                <ng-container *ngIf="!selecAll">
                  <ng-container *ngFor="let anio of responsables2">
                    <button mat-menu-item>
                      <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                      (change)="cambioLider(anio)"
                      (click)="$event.stopPropagation();"
                      value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                        {{ anio.nombre }}
                      </mat-checkbox>
                    </button>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </div>

            <div class="w3-rest hijoizquierda titulochipsr">
              <div class="w3-row padreizquierda containerChips">
                <div class="chips nombres" id="Cchip">
                  <div class="auto_ajuste">
                    <ng-container *ngFor="let anio of responsables2; let i = index">
                      <div [ngClass]="anio.check?
                        'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioLider(anio)">
                        <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                          {{anio.nombre}}
                        </span> 
                        <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                      </div>
                      <br *ngIf="anio.check">
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="tresPuntosVis()" class="tresPuntos">
                  <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
                </div>
                <mat-menu #menuanios2="matMenu" xPosition="before">
                  <div *ngFor="let anio of responsables2"
                      [ngClass]="anio.check?
                           'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado w3-border" (click)="cambioLider(anio); $event.stopPropagation();">
                      <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
          <!-- responsables -->
          <div class="w3-third">
            <div class="w3-quarter hijoizquierda">
              <button class="w3-button w3-right" [matMenuTriggerFor]="menuliderres" aria-label="Example icon-button with a menu">
                Responsable
                <mat-icon class="iconoMen">expand_more</mat-icon>
              </button>
              <mat-menu class="w3-medium" #menuliderres="matMenu">
                <button mat-menu-item>
                  <mat-checkbox [checked]="selecAllAct"
                  (change)="cambioLiderAct()"
                  (click)="$event.stopPropagation();"
                  aria-label="First checkbox"> <!--  -->
                    Seleccionar todos
                  </mat-checkbox>
                </button>
                <mat-divider></mat-divider>
                <ng-container *ngIf="!selecAllAct">
                  <ng-container *ngFor="let anio of responsablesAct">
                    <button mat-menu-item>
                      <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                      (change)="cambioLiderAct(anio)"
                      (click)="$event.stopPropagation();"
                      value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                        {{ anio.nombre}}
                      </mat-checkbox>
                    </button>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </div>
            <div class="w3-rest hijoizquierda titulochipsr">
              <div class="w3-row padreizquierda containerChips">
                <div class="chips nombres" id="Cchip">
                  <div class="auto_ajuste">
                    <ng-container *ngFor="let anio of responsablesAct; let i = index">
                      <div [ngClass]="anio.check?
                        'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioLiderAct(anio)">
                        <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                          {{anio.nombre}}
                        </span> 
                        <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                      </div>
                      <br *ngIf="anio.check">
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="tresPuntosVis()" class="tresPuntos">
                  <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menures">more_horiz</mat-icon>
                </div>
                <mat-menu #menures="matMenu" xPosition="before">
                  <div *ngFor="let anio of responsablesAct"
                      [ngClass]="anio.check?
                           'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado w3-border" (click)="cambioLiderAct(anio); $event.stopPropagation();">
                      <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
          <!-- BORRAR FILTROS -->
          <div class="w3-rest w3-right">
            <div class="w3-row">
              <button mat-icon-button (click)="borrarFiltro()"> <!--(click)="quitarFiltros()"-->
                <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                  <img src="assets/img/graficos/eraser.png">
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row" style="height: 25vh; width: 99%;">
      <div class="w3-row">
        <div class="w3-quarter" style="margin-right: 4vh;">
          <div class="w3-right">
            <mat-form-field appearance="outline" style="width: 15vw; ">
              <mat-label> Seleccione una perspectiva </mat-label>
              <mat-select id="perspectiva" [(ngModel)]="perSelect" matTooltip="{{obtTooltip('perspectiva')}}">
                <ng-container *ngFor="let per of perspectivas">
                  <mat-option value="{{per.id}}" (click)="cambioPer()" matTooltip="{{per.nombre}}"> {{per.nombre}} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="w3-row">
        <div [ngClass]="perSelect?'':'w3-disabled'" class="w3-quarter" style="margin-right: 4vh;">
          <div class="w3-right">
            <mat-form-field appearance="outline" style="width: 15vw; ">
              <mat-label> Seleccione un objetivo </mat-label>
              <mat-select id="objetivo" [(ngModel)]="objSelect" matTooltip="{{obtTooltip('objetivo')}}">
                <ng-container *ngFor="let obj of objetivos">
                  <mat-option value="{{obj.id}}" (click)="cambioObj()" matTooltip="{{obj.nombre}}" *ngIf="perSelect === obj.perspectiveId">
                    {{obj.nombre}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div [ngClass]="objSelect?'':'w3-disabled'" class="w3-quarter">
          <mat-form-field appearance="outline" style="width: 15vw; ">
            <mat-label> Selecciona {{ 'ProyectosForm.selec' | translate}} </mat-label>
            <mat-select id="estrategia" [(ngModel)]="estSelect" matTooltip="{{obtTooltip('estrategia')}}">
              <ng-container *ngFor="let est of estrategias">
                <mat-option value="{{est.id}}" (click)="cambioEstra()" *ngIf="programa_pertenece(objSelect,est)" matTooltip="{{est.name}}"> {{est.name}} </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 53vh; width: 99%;">
      <div>
        <div class="w3-row w3-subtitulo botonDeNuevo">
          <div class="menuexp">
            <div class="w3-right" style="margin-top: 0.3vh;">
              <mat-icon class=" w3-zindex w3-dropdown-click" [ngStyle]="{'color':'gray'}"
                [matMenuTriggerFor]="menu2">more_vert</mat-icon>
            </div>
            <mat-menu #menu2="matMenu">
              
              <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span>Exportar xlsx</span>
              </button>
              <button (click)="validarPro()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span>Validar ponderaciones</span>
              </button>
            </mat-menu>
          </div>
          <div class="botones">
            <button *appRoles="['Rol_Administrador','Rol_Carga']" matTooltip="Agregar proyecto" class="w3-right colorBotonDeNuevo" (click)="agregarPro()">
              <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
              Agregar proyecto
            </button>
          </div>
        </div>
        <div class="w3-row" style="overflow: scroll; height: 46vh; width: 99%;">
          <div *ngIf="!mostabla">
            <section>
              <table class="w3-table">
                <tr>
                  <td>
                    <div class="info"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                    <div class="info" style="margin-left: 3vw !important;"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                </tr>
              </table>
            </section>
          </div>
          <table *ngIf="mostabla" class="w3-table">
            <thead>
              <tr>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun" style="border-left: 1px solid #B9B9BC;">
                  <tr>
                    <th>
                      <mat-icon class="iconoMen w3-button" matTooltip="Ocultar todo" matTooltipPosition="left" *ngIf="expandirProg" (click)="mosOculAll()">expand_less</mat-icon>
                      <mat-icon class="iconoMen w3-button" matTooltip="Mostrar todo" matTooltipPosition="left" *ngIf="!expandirProg" (click)="mosOculAll()">expand_more</mat-icon>
                    </th>
                  </tr>
                </th>
                <th (click)="ordenamientoTabla()" class="w3-elemento-titulo-table borderSup enbezadosPro contenedorSort" style="width: 15vw; cursor: pointer;">
                  Proyecto
                  <mat-icon *ngIf="ordenamiento === 'id'" class="iconoSortIni">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'asc'" class="iconoSort">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'des'" class="iconoSort">download</mat-icon>
                </th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun" style="width: 16vw;"> Líder / Responsable </th>
                <th *ngIf="objSelect !== undefined" class="w3-elemento-titulo-table alinCen borderSup enbezados"> Ponderación </th>
                <th class="w3-elemento-titulo-table borderSup enbezados alinCen"> Inicio </th>
                <th class="w3-elemento-titulo-table borderSup enbezados alinCen"> Días </th>
                <th class="w3-elemento-titulo-table borderSup enbezados alinCen" style="border-right: 1px solid #B9B9BC;"> Fin </th>
                <ng-container *ngFor="let mes of meses">
                  <ng-container>
                    <th class="w3-elemento-titulo-table borderSup enbezados alinCen" style="border-right: 1px solid #B9B9BC;"> {{mes.name}} </th>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="actividades.length != 0">
                  <ng-container *ngFor="let ad of actividades[0].adicionales; let i = index">
                    <th class="w3-elemento-titulo-table borderSup tresPun enbezados alinCen adicionAdicional textoOverflow" [matMenuTriggerFor]="manuEdicionAd"
                      style="border-right: 1px solid #B9B9BC; padding: 0vw 0.5vw;">
                      {{ad.name}}
                    </th>
                    <mat-menu class="w3-medium" #manuEdicionAd="matMenu">
                      <button (click)="editarAdicional(ad)" class="mat-menu-item" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button (click)="eliminarAdicional(ad)" class="mat-menu-item" mat-menu-item> 
                        <mat-icon [ngStyle]="{'color':'gray'}">delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </ng-container>
                <th *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup enbezados" style="border-right: 1px solid #B9B9BC;"> 
                  <button matTooltip="Agregar adicional" class="colorBotonDeNuevo" (click)="agregarAdicional()">
                    <mat-icon class="iconoMen">add_circle_outline</mat-icon>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody class="borderInf borderIzq" *ngFor="let pro of proyectos; let i = index">
              <ng-container>  
                <td class="w3-table-trtdBalance borderSup">
                  <tr>
                    <td class="tresPun">
                      <mat-icon class="iconoMen w3-button" matTooltip="Ocultar" matTooltipPosition="left" *ngIf="pro.check" (click)="mosOcul(i)">expand_less</mat-icon>
                      <mat-icon class="iconoMen w3-button" matTooltip="Mostrar" matTooltipPosition="left" *ngIf="!pro.check" (click)="mosOcul(i)">expand_more</mat-icon>
                      <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-button iconoMen" (click)="editarPro(pro)">edit</mat-icon>
                      <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" class="iconoMen w3-button" (click)="alertas('eliminarPro',pro)">delete</mat-icon>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="tresPun" style="margin-left: 2vh; min-width: 3vw; max-width: 20vw;">
                        <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" class="iconoMen w3-button" (click)="editActiv(act,pro.id)">edit</mat-icon>
                        <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" class="iconoMen w3-button" (click)="alertas('eliminar',act)">delete</mat-icon>
                      </td>
                      <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td class="w3-table-trtdBalance borderSup proyecto">
                  <tr>
                    <td class="textoOverflow tresPunv nombreProy" style="min-width: 5vw; max-width: 20vw;">
                      <span matTooltip="{{pro.nombre}}" matTooltipPosition="right">{{pro.nombre}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      <button *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-button" (click)="agragarActividades(pro.id)">
                        <mat-icon class="iconoMen w3-button">add_circle_outline</mat-icon> 
                        Agregar actividad
                      </button>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <span matTooltip="{{act.nombre}}" matTooltipPosition="right">{{act.nombre}}</span>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td class="w3-table-trtdBalance borderSup">
                  <tr>
                    <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                      <span matTooltip="{{obtres(pro.responsableId)}}" matTooltipPosition="right">{{obtres(pro.responsableId)}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <span matTooltip="{{obtres(act.responsableId)}}" matTooltipPosition="right">{{obtres(act.responsableId)}}</span>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td *ngIf="objSelect !== undefined" class="w3-table-trtdBalance w3-center borderSup">
                  <tr>
                    <td *appRoles="['Rol_Administrador','Rol_Carga']" class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw; cursor: pointer;"
                     (dblclick)="edicionPond(pro)">
                      <span>{{escribirPond(pro)!==null?formatearnormalDec(escribirPond(pro)):0}}</span>
                    </td>
                    <td *appRolesNeg="['Rol_Administrador','Rol_Carga']" class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                      <span>{{escribirPond(pro)!==null?formatearnormalDec(escribirPond(pro)):0}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <br>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td class="w3-table-trtdBalance w3-center borderSup">
                  <tr>
                    <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                      <span>{{obtFecha(pro.fecha_ini)}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <span>{{obtFecha(act.fecha_inicio)}}</span>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td class="w3-table-trtdBalance w3-center borderSup">
                  <tr>
                    <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                      <span>{{obtDias(pro.fecha_ini,pro.fecha_fin)}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <span>{{obtDias(act.fecha_inicio,act.fecha_fin)}}</span>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC;">
                  <tr>
                    <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                      <span>{{obtFecha(pro.fecha_fin)}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                      <br>
                    </td>
                  </tr>
                  <ng-container *ngFor="let act of pro.actividades;">
                    <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                    <tr>
                      <td class="textoOverflow tresPun" style="margin-left: 2vh; min-width: 5vw; max-width: 20vw;">
                        <span>{{obtFecha(act.fecha_fin)}}</span>
                      </td>
                    </tr>
                    </ng-container>
                  </ng-container>
                </td>
                <ng-container *ngFor="let mes of meses; let me = index">
                  <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC; padding: 0vh !important;">
                    <tr>
                      <td style="min-width: 3.2vw !important; padding: 0vh;">
                        <ng-container *ngFor="let dias of mes.value">
                          <div [ngClass]="pintarGantPro(pro, dias.name,me)"> <br> </div>
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="pro.check">
                        <br>
                      </td>
                    </tr>
                    <ng-container *ngFor="let act of pro.actividades;">
                      <ng-container *ngIf="pro.check && responsableActOfAct(act) && mostrarAct(act)">
                      <tr>
                        <td style="min-width: 3.2vw !important; padding: 0vh;">
                          <ng-container *ngFor="let dias of mes.value">
                            <div [ngClass]="pintarGantAct(act, dias.name,me)"> <br> </div>
                          </ng-container>
                        </td>
                      </tr>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container *ngIf="actividades.length != 0">
                  <ng-container *ngFor="let ad of actividades[0].adicionales; let j = index;">
                    <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC;">
                      <tr>
                        <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                          <div *ngIf="edicionPro(i,j,pro)" class="w3-row tresPun textoOverflow alinDer" style=" min-width: 3vw; max-width: 10vw;">
                            {{imprimirPro(pro,j)}} 
                          </div>
                          <div *ngIf="!edicionPro(i,j,pro)" class="w3-row tresPun textoOverflow" style="min-width: 3vw; max-width: 10vw;">
                            <br>
                          </div> 
                        </td>
                      </tr>
                      <tr>
                        <td class="w3-subtitulo" *ngIf="pro.check">
                          <br>
                        </td>
                      </tr>
                      <ng-container *ngFor="let act of actividades; let k = index;">
                        <ng-container *ngIf="pro.id === act.projectId  && pro.check && responsableActOfAct(act) && mostrarAct(act)">
                        <tr>
                          <td class="textoOverflow tresPun" style=" min-width: 5vw; max-width: 20vw;">
                            <ng-container *appRoles="['Rol_Administrador','Rol_Carga']">
                              <div class="alinDer" *ngIf="edicion(k,j,pro)" style="cursor: pointer;" (dblclick)="editar(k,j)"> {{imprimir(k,j)}} <br> </div>
                            </ng-container>
                            <ng-container  *appRolesNeg="['Rol_Administrador','Rol_Carga']">
                              <div class="alinDer" *ngIf="edicion(k,j,pro)"> {{imprimir(k,j)}} </div>
                            </ng-container>
                            <ng-container>
                              <div *ngIf="!edicion(k,j,pro)"> {{imprimir(k,j)}} </div>
                            </ng-container>
                          </td>
                        </tr>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>
                <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup" style="border-right: 1px solid #B9B9BC;">  </td>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="agregarPro" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Agregar proyecto </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow-y: scroll;">
          <div style="width: 99%;">
          
            <span id="inicioPro"></span>

            <form [formGroup]="formPro" (ngSubmit)="guardarPro()">

              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-error *ngIf="formPro.hasError('isValid')" class="error-message">
                    Nombre <strong> existente.</strong>
                  </mat-error>
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Ingrese el nombre del proyecto </mat-label>
                    <input type="text" id="nomPro" matInput id="nombrePro" formControlName="inputNamePro" 
                    required> <!--(keyup)="capitalizacionTexto('nombrePro')"-->
                    <mat-error>
                      <span>Campo <strong> obligatorio.</strong></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Ingrese una descripción </mat-label>
                    <textarea type="text" #input maxlength="1000" id="desPro" formControlName="inputDescripcion" matInput></textarea>
                    <mat-hint align="end">{{input.value.length || 0}}/1000</mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> {{ 'ProyectosForm.nom' | translate }}: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Seleccione {{ 'ProyectosForm.selec' | translate }} </mat-label>
                    <mat-select formControlName="inputEstrategia">
                      <ng-container *ngFor="let obj of estrategias">
                        <mat-option value="{{obj.id}}"> {{obj.name}} </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Objetivo: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Seleccione un objetivo asociado </mat-label>
                    <mat-select required formControlName="inputObjetivo"> <!--multiple-->
                      <ng-container *ngFor="let obj of objetivos">
                        <mat-option *ngIf="mostrarObj(obj)" value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>
                      <span>Campo <strong> obligatorio.</strong></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Líder: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Seleccione un lider </mat-label>
                    <mat-select required formControlName="inputLider">
                      <ng-container *ngFor="let obj of responsables">
                        <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>
                      <span>Campo <strong> obligatorio.</strong></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div *ngIf="modal" style="width: 99%;">
                <div class="w3-right">
                  <div class="botonesModales" [ngClass]="puedeguardarPro()">
                    <button mat-flat-button color="success" style="height: 4vh;">
                      Guardar
                    </button>
                  </div>
                  <div class="botonesModales">
                    <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="editPro" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Editar proyecto </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow-y: scroll;">

          <span id="inicioProEdit"></span>

          <form [formGroup]="formProEdit" (ngSubmit)="guardarEditPro()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formProEdit.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del proyecto </mat-label>
                  <input type="text" id="nomProEdit" matInput id="nombreProEdit" formControlName="inputNamePro"
                   required> <!--(keyup)="capitalizacionTexto('nombreProEdit')"-->
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese una descripción </mat-label>
                  <textarea type="text" #input maxlength="1000" id="desPro" formControlName="inputDescripcion" matInput></textarea>
                  <mat-hint align="end">{{input.value.length || 0}}/1000</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> {{ 'ProyectosForm.nom' | translate }}: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione {{ 'ProyectosForm.selec' | translate }} </mat-label>
                  <mat-select formControlName="inputEstrategia">
                    <ng-container *ngFor="let obj of estrategias">
                      <mat-option value="{{obj.id}}"> {{obj.name}} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Objetivo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un objetivo asociado </mat-label>
                  <mat-select required formControlName="inputObjetivo"> <!--multiple-->
                    <ng-container *ngFor="let obj of objetivos">
                      <mat-option *ngIf="mostrarObj(obj)" value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Líder: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un lider </mat-label>
                  <mat-select required formControlName="inputLider">
                    <ng-container *ngFor="let obj of responsables">
                      <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modal2" style="width: 99%;">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeguardarProEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                      Guardar
                    </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="agregarAct" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Agregar actividades </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicioAct"></span>

          <form [formGroup]="formAct" (ngSubmit)="guardarAct()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Actividad: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre de la actividad </mat-label>
                  <input type="text" id="actividad" matInput id="nombreAct" formControlName="inputNameAct"
                   required> <!--(keyup)="capitalizacionTexto('nombreAct')"-->
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker"
                  formControlName="inputFechaIn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="this.formAct.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker2"
                  formControlName="inputFechaFn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker2 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select formControlName="inputLider" required>
                    <ng-container *ngFor="let obj of responsables">
                      <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modal3">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardar()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="editarAct" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Editar actividad </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicioActEdit"></span>


          <form [formGroup]="formActEdit" (ngSubmit)="alertas('editar')">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Actividad: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre de la actividad </mat-label>
                  <input type="text" id="actividad" matInput id="nombreActEdit" formControlName="inputNameAct"
                   required> <!--(keyup)="capitalizacionTexto('nombreActEdit')"-->
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker3"
                  formControlName="inputFechaIn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker3">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker3 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="this.formActEdit.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker4"
                  formControlName="inputFechaFn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker4">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker4 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select formControlName="inputLider" required>
                    <ng-container *ngFor="let obj of responsables">
                      <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modal4">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardarEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="adicional" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Columna adicional </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh;">
          <ng-container>

            <div class="w3-row" style="width: 90%;" [formGroup]="formAdi">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Adicional: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formAdi.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del adicional </mat-label>
                  <input type="text" id="nomAdi" matInput formControlName="inputNameAdi" required>
                  <mat-error *ngIf="formAdi.controls['inputNameAdi'].errors">
                    <span *ngIf="formAdi.controls['inputNameAdi'].errors['required']">Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;" [formGroup]="formAdi">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tipo de adicional: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un objetivo </mat-label>
                  <mat-select id="objPro" [(ngModel)]="tipSelect" required [ngModelOptions]="{standalone: true}">
                    <mat-option value="texto" matTooltip="Agrega un campo para texto que se rellena mes a mes"> Texto </mat-option>
                    <mat-option value="moneda" matTooltip="Agrega un campo para cantidades"> Moneda </mat-option>
                    <mat-option value="archivo" matTooltip="Agrega un campo para adjuntar archivos"> Archivo </mat-option>
                    <mat-option value="numero" matTooltip="Agrega un campo para texto"> Texto fijo </mat-option>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

          </ng-container>

          <div *ngIf="modal5">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puedeGuardarAdi()">
                <button mat-flat-button color="success" (click)="guardarAdi()" style="height: 4vh;">
                  Guardar
                </button>  
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>