import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Meses, Objetivos } from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CuadromandoService } from '../../../services/seguimiento/cuadromando/cuadromando.service';
import { IMG } from '../../../services/Exportar/exportImg';
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cuadromando',
  templateUrl: './cuadromando.component.html',
  styleUrls: ['./cuadromando.component.css']
})
export class CuadromandoComponent implements OnInit {
  public Nombre = 'CMI';
  public acum = true;

  // Filtros
  public todasPer = true;
  public aniosDisponibles: any = [];
  public perspectives: any = [];

  // Variables planeación
  private planeacionID: number = 0;
  public avancePlan = 0;
  public diccionarioPer: any[] = [];
  private semaforo: any[] = [];
  
  public indicadores: any = [];
  public objectivos: any = [];
  public indicadoresMostrar: any = [];

  private mensajes = [
    'mens1',
    'mens2',
    'mens3'
  ];
  public mision = undefined;
  public vision = undefined;
  public textoMostrar = 'Texto informativo';
  public tarjOrTab = true;
  public expandirObj = false;
  public verCodigo = false;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private cuadromandoService: CuadromandoService,
    private translate: TranslateService,
    private excelService: ExcelService,
  ) {
    var param = this.rutaActiva.snapshot.params;
    this.planeacionID = Number(param['planeacion']);
    this.consultas();
  }

  ngOnInit(): void {
  }

  private consultas(){
    this.cuadromandoService.getPlaneacion().subscribe(response =>{
      this.obtenerFiltros(response);
      this.cuadromandoService.getObjectives().subscribe(response =>{
        this.diccionario(response);
        this.cuadromandoService.getIndicadores().subscribe( response =>{
          this.obtenerIndicadores(response);
        });
      });
    });
    this.cuadromandoService.getPlaneacion().subscribe(response =>{
      for(const plan of response){
        const fil = this.ordenamientoId(plan.philosophy);
        if(plan.id === ''+this.planeacionID){
          for(var i = 0; i <= fil.length-1; i++){
            if(i === 0){
              this.mision = fil[i];
            }else if(i === 1){
              this.vision = fil[i];
            }else{
              break;
            }
          }
        } 
      }
    });
  }
  private obtenerFiltros(array: any){
    for(const plan of array){
      if(this.planeacionID === Number(plan.id)){
        if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
          //this.verCodigo = true;
        }
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        const sema = this.ordenamientoSem(plan.signals);
        for(const sem of sema){
          this.semaforo.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }
        
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            id: per.id,
            check: true,
          });
        }
        this.perspectives = this.ordenamientoId(this.perspectives);
      }      
    }
  }
  private diccionario(array: any){
    var auxPer: any = [], auxO = [];
    var dicci: any = [];
    for (const per of this.perspectives){
      for (const obj of array){
        if(per.id === obj.perspective.id){
          auxPer.push({
            name: obj.name,
            id: obj.id,
            weighing: obj.weighing,
            avance: 0,
            check: false,
          });
          auxO.push({
            nombre: obj.name,
            id: obj.id,
            idPer: obj.perspective.id,
            peso: obj.weighing,
            indicadores: [],
          });
        }
      }
      dicci.push({perspective: per, objetivos: auxPer});
      auxPer = [];
    }
    this.objectivos = [];
    this.objectivos = auxO;
    this.objectivos = this.ordenamientoId(this.objectivos);
    this.diccionarioPer = dicci;
    
    var aux = 0;
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        aux += Number((obj.data*obj.weighing/100).toFixed(2)); 
      }
    }
  }
  private obtenerIndicadores(array: any){
    var aux = [];
    for(const ind of array){
      for(const obj of this.objectivos){
        if(ind.objective.id === obj.id){
          var metas = [];
          for(const met of ind.goals){
            metas.push({
              id: met.id,
              period: met.period,
              value: met. value,
              real: met.real,
            });
          }
          aux.push({
            nombre: ind.name,
            id: ind.id,
            ponderacion: ind.weighing,
            metas: this.ordenamientoMetas(metas),
            tendencia: ind.tendency,
            objectiveId: ind.objective,
            fechaIni: ind.initial_date,
            fechaFin: ind.end_date,
            areaId: ind.area.id,
          });
          break;
        }
      }
    }
    this.indicadores = [];
    this.indicadores = aux;
    this.indicadores = this.ordenamientoId(this.indicadores);
    this.indicadores = this.ordenamientoObjId(this.indicadores);

    if(this.acum){
      this.indicadoresMostrar = [].concat(this.indicadores);
    }else{
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
    }

    this.obtAvance();
    this.obtenerIndicadoresObj();
  }
  private obtenerIndicadoresObj(){
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        var indEx = this.indicadores.filter(ind => ind.objectiveId.id === obj.id);
        if(indEx.length !== 0){
          var indicators = [];
          for(const ind of indEx){
            indicators.push({
              nombre: ind.nombre,
              avance: this.acum?this.porcentajeIndAcum(ind):this.porcentajeInd(ind),
            })
          }
          obj.indicadores = indicators;
        }
      }
    }
  }
  private obtAvance(){
    if(this.acum){
      this.avancePlan = this.porcentajeObjecAcum();
    }else{
      this.avancePlan = this.porcentajeObjec();
    }
    this.diccionarioPer
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        var avance = 0;
        if(this.acum){
          avance = this.porcentajeObjAcum(obj.id);
        }else{
          avance = this.porcentajePers(obj.id);
        }
        obj.avance = avance;
      }
    }
  }
  // filtros
  public anioChange(per: any){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === per? mes.check = true: mes.check = false;        
      }
    }

    if(this.acum){
      this.indicadoresMostrar = [].concat(this.indicadores);
    }else{
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
    }

    this.obtAvance();
    this.obtenerIndicadoresObj();
  }
  public persChange(pers: any){
    var sel = 0;
    for(const per of this.diccionarioPer){
      per.perspective.id === pers.id? per.perspective.check = !per.perspective.check: per.perspective.check = per.perspective.check;
      if(per.perspective.check){
        sel++;
      }
    }
    if(sel === this.diccionarioPer.length){
      this.todasPer = true;
    }else{
      this.todasPer = false;
    }


    if(this.acum){
      this.indicadoresMostrar = [].concat(this.indicadores);
    }else{
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
    }

    this.obtAvance();
    this.obtenerIndicadoresObj();

  }
  public selecAllPer(){
    this.todasPer = !this.todasPer;
    for(const per of this.diccionarioPer){
      if(this.todasPer){
        per.perspective.check = true;
      }else{
        per.perspective.check = false;
      }
    }

    if(this.todasPer){
      if(this.acum){
        this.indicadoresMostrar = [].concat(this.indicadores);
      }else{
        this.indicadoresMostrar = [];
        for(const ind of this.indicadores){
          if(this.Mostrar(ind)){
            this.indicadoresMostrar.push(ind);
          }
        }
      }
      this.obtAvance();
      this.obtenerIndicadoresObj();
    }
  }
  // Metodo mostrar
  public Mostrar(ind: any){
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var metasInd = [];
    var existeMet = false;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSele.push(mes.periodo);
        }
      }
    }
    for(const met of ind.metas){
      metasInd.push(Meses.getMesesN(this.obtFech(met.period).getMonth()+1) + '-' + (this.obtFech(met.period).getFullYear()+'').substring(2,4));
    }
    for(const per of metasInd){
      for(const perS of perSele){
        if(per === perS){
          existeMet = true;
        }
      }
    }

    //Observar que la perspectiva este activa
    var existPer = false, perInd;
    for(const obj of this.objectivos){
      if(obj.id === ind.objectiveId.id){
        perInd = this.obtPer(obj.idPer);
      }
    }

    if(this.todasPer){
      existPer = true;
    }else{
      for(const per of this.perspectives){
        if(per.check && per.name === perInd){
          existPer = true;
        }
      }
    }

    if(existeMet && existPer)
      return true;
    return false;
  }
  public mostrarPers(pers: any){    
    var perSelected = 0 ;
    for(const per of this.diccionarioPer){
      if(per.perspective.check){
        perSelected++;
      }
    }
    if(this.todasPer || perSelected === 0){
      return true;
    }else if(pers.perspective.check){
      return true;
    }
    return false;
  }
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  // obtencion de nombres
  private obtPer(perId: any){
    for(const per of this.perspectives){
      if(per.id === perId){
        return per.name
      }
    }
  }
  public perSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  //Tarjetas
  public pintarBorde(valor: any){
    if(valor != 0 ){
      var color = '', valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(valor >= valfin){
        valor = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(valor >= ab && valor <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }else{
      return 'bordeGrey';
    }
  }
  public texto(valor: any){
    var color = '', valfin = 0;
    
    if( valor !== 0){
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(valor >= valfin){
        valor = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(valor >= ab && valor <= sem.valfin){
          color = sem.color;
          break;
        }
      }
    }
    
    switch(color){
      case 'verde': return 'cifraSecVerd';
      case 'azul': return 'cifraSecAzul';
      case 'amarillo': return 'cifraSecAmar';
      case 'naranja': return 'cifraSecNarj';
      case 'rojo': return 'cifraSecRojo';
      default:  return 'cifraSecGris';
    }
  }
  //Rutas
  public rutas(direc: string){
    if(direc === 'avanzar')
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'objetivos/' +this.planeacionID);
  }
  // Metodos de ordenamiento
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoObjId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objectiveId.id) > Number(b.objectiveId.id)) {
        return 1;
      }
      if (Number(a.objectiveId.id) < Number(b.objectiveId.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoMetas(array: any){
    array.sort(function (a: any, b: any) {
      if ((a.period) > (b.period)) {
        return 1;
      }
      if ((a.period) < (b.period)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  // Datos de tarjetas
  public escribirPerT(ind: any, i: any){
    var perid1;
    for(const obj of this.objectivos){
      if(obj.id === ind.objectiveId.id){
        perid1 = obj.idPer;
      }
    }
    return this.obtPer(perid1);

  }
  //Porcentajes acumulados
  public porcentajeObjecAcum(){
    var persPon = 0, perTot = 0;

    var pesoFin = 0; 
    for(const obj of this.objectivos){
      var por = this.porcentajeObjAcum(obj.id), porcentajeObj = obj.peso;
      if(por != undefined){
        pesoFin += obj.peso;
        persPon += por*porcentajeObj/100;
      }
    }
    if(persPon !== 0){
      persPon = persPon*100/pesoFin;
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin;
      }
    }
    if(persPon >= valfin){
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  public porcentajePersAcum(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    for(const obj of this.objectivos){
      if(Number(obj.idPer) === Number(nomPer)){
        for(const ind of this.indicadores){
          var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
          let metas = ind.metas;
          for(var i = metas.length-1; i>=0; i--){
            const peer = ind.metas[i].period;
            if(fechaPer === peer && Number(obj.id) === Number(ind.objectiveId.id)){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              var porcen = undefined;
              let dat = this.rendimiento(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val) || isNaN(Number(dat[0]))){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
                porcen=0;
              }
              break;
            }else if(fechaPer>peer && metas[i].real !== null && Number(obj.id) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              var porcen = undefined;
              let dat = this.rendimiento(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val) || isNaN(Number(dat[0]))){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }else{
                porcen === 0;
              }
              break;
            }
          }
        }
        if(porcentaje !== 0){
          peso += porcentaje*obj.peso/100;
          pesoPer += obj.peso;
        }
        porcentaje = Number(porcentaje.toFixed(0));
        porcentaje = 0;
      }
    }
    porcentaje = peso*100/pesoPer;

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  private obtenerPondIndAcum(ind: any){
    var Nind = 0;
    for(const indi of this.indicadores){
      if( Number(ind.objectiveId.id) === Number(indi.objectiveId.id) && this.porcentajeIndAcum(indi) !== undefined ){
        Nind += indi.ponderacion;
      }
    }
    if(isNaN(ind.ponderacion * 100 /Nind)){
      return 0;
    }
    return ind.ponderacion * 100 /Nind;
  }
  private porcentajeIndAcum(ind: any){
    var persel = this.perSelected(), metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = undefined;
    for(var i = ind.metas.length-1; i>=0; i--){
      const peer = ind.metas[i].period;
      if(fechaPer === peer){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        let dat = this.rendimiento(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val) || isNaN(Number(dat[0]))){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }else{
          porcentaje === 0;
        }
        break;
      }else if(fechaPer>peer && ind.metas[i].real !== null && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        let dat = this.rendimiento(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val) || isNaN(Number(dat[0]))){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }
        break;
      }
    }
    if( porcentaje!== undefined )
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public porcentajeObjAcum(objId: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    
    
    for(const ind of this.indicadores){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
      let metas = ind.metas;
      for(var i = metas.length-1; i>=0; i--){
        const peer = ind.metas[i].period;
        if(fechaPer === peer && Number(objId) === Number(ind.objectiveId.id)){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          var porcen = undefined;
          let dat = this.rendimiento(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val) || isNaN(Number(dat[0]))){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }else{
            porcen === 0;
          }
          break;
        }else if(fechaPer>peer && metas[i].real !== null && Number(objId) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          var porcen = undefined;
          let dat = this.rendimiento(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val) || isNaN(Number(dat[0]))){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }else{
            porcen === 0;
          }
          break;
        }
      }
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }

  // Porcentajes normales
  public porcentajeObjec(){
    var persPon = 0, perTot = 0;

    var pesoFin = 0; 
    for(const obj of this.objectivos){
      var por = this.porcentajePers(obj.id), porcentajeObj = obj.peso//this.obtenerPonder(obj);
      if(por != undefined){
        pesoFin += obj.peso;
        persPon += por*porcentajeObj/100;
      }
    }
    persPon = persPon*100/pesoFin;
    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin;
      }
    }
    if(persPon >= valfin){
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  public porcentajePers(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    var porcentaje = 0;
    for(const ind of this.indicadoresMostrar){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondInd(ind);
      for(const meta of ind.metas){
        if(persel === Meses.obtnerPer(this.obtFech(meta.period)) && Number(nomPer) === Number(ind.objectiveId.id)){
          const val = this.escribirReal(persel, ind), met = this.escribirMeta(persel, ind);
          var porcen = undefined;
          let dat = this.rendimiento(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val) || isNaN(Number(dat[0]))){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }
      }
      i++;
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  private obtenerPondInd(ind: any){
    var Nind = 0;
    for(const indi of this.indicadoresMostrar){
      if( ind.objectiveId.id === indi.objectiveId.id ){
        Nind += indi.ponderacion;
      }
    }
    return ind.ponderacion * 100 /Nind;
  }
  private porcentajeInd(ind: any){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    var porcentaje = undefined;
    for(const meta of ind.metas){
      if( persel === Meses.obtnerPer(this.obtFech(meta.period)) ){
        const val = this.escribirReal(persel, ind), met = this.escribirMeta(persel, ind);
        let dat = this.rendimiento(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val) || isNaN(Number(dat[0]))){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }
      }
    }
    if( porcentaje!== undefined )
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  private obtenerPonder(obj: any){
    var ponderacionPer = 0;
    var i = 0, objList: any = [];

    for(const ind of this.indicadoresMostrar){
      if(!objList.includes(ind.objectiveId.id)){
        objList.push(ind.objectiveId.id);
      }
    }
    for(const obj of this.objectivos){
      if(objList.includes(obj.id)){
        ponderacionPer += obj.peso;
      }
    }
    return obj.peso * 100 / ponderacionPer;
  }

  private fechaMeta(periodo){
    let fecha = new Date('01-'+Meses.getMesesNum(periodo.split('-')[0])+'-'+periodo.split('-')[1]);
    return moment(fecha).format("YYYY-DD-MM");
  }
  public rendimiento(real: any, value: any, ind){
    const met = {real: real===null?null:real, value: value===null?null:value};
    if(met.real < 0 && met.value >= 0){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  public escribirMeta(periodo: any, ind: any){
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(this.obtFech(met.period).getMonth()+1)+'-'+(''+this.obtFech(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo){
        return met.value;
      }
    }
    return ''
  }
  public escribirReal(periodo: any, ind: any){ //avance
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(this.obtFech(met.period).getMonth()+1)+'-'+(''+this.obtFech(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo){
        return met.real;
      }
    }
    return ''
  }
  public modalInd(obj: any){
    var nombreObj = obj.name, indicadores = [];
    this.cuadromandoService.getIndicadoresObj(obj).subscribe(response =>{
      indicadores = [];
      for(const ind of response.indicators){
          indicadores.push({
            nombre: ind.name,
          });
      }
      indicadores = this.ordenamientoPer(indicadores);
      //document.getElementById('mostrarIndicador')!.style.display = 'block';
      var html = '<div class="w3-row">'+
      '<div class="w3-row">'+
      '  <div style="text-align: justify; margin-left: 1vw; margin-right: 2vw;"> Indicadores asociados </div>'+
      '</div>'+
      '<mat-list style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">';

      for(const ind of indicadores){
        html += '<div mat-line style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">'+
        '<span style="color: #ff6b00">&#8226;</span>'+
        (this.verCodigo?'Código | ':'')+' '+ind.nombre+
        '</div>';
      }
      html += '</mat-list>'+'</div>';

      var ancho = 'auto';
      if(nombreObj.length >100){
        ancho = '60%'
      }

      Swal.fire({
        title: ''+ nombreObj,
        html: html,
        showConfirmButton: false,
        width: ancho+'',
      });
    });
  }
  public modalInfo(number: any){
    this.textoMostrar =  this.mensajes[number];
    Swal.fire({
      title: this.translate.instant('seguimiento.cmi.info'),
      text: this.translate.instant('seguimiento.cmi.'+this.mensajes[number]),
      iconColor: '#ff6b00',
      confirmButtonText: this.translate.instant('swal.acep'),
      cancelButtonText: this.translate.instant('swal.cerr'),
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
    });
  }
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  public exportarImge(id: string, graf: string){
    var grafica: string = "";
    var name: string = "";
    
    switch(graf){
      case 'per':
        grafica = 'graf'+id;
        name = ''+id;
        IMG.exportimagenes(grafica,name);
      break;
      case 'prin':
        grafica = id;
        name = 'Avance plan';
        IMG.exportimagenes(grafica,name);
      break;
      case 'tot':
        grafica = id;
        name = 'CMI';
        IMG.exportimagenes(grafica,name);
      break;
    }
  }
  public closeModal(){
    document.getElementById('info')!.style.display = 'none';
  }
  /** Cambio de tarjetas a tablas */
  public cambioTarjTab(){
    this.tarjOrTab = !this.tarjOrTab;
  }
  public pintarBordes(i: number){
    if(i===0){
      return 'borderSup';
    }
    return '';
  }
  /* Abrir y cerrar los proyectos */
  public oculMost(obj: any){
    obj.check = !obj.check;
    var check = 0;
    for(const per of this.diccionarioPer){
      for(const ob of per.objetivos){
        if(ob.check){
          check++;
        }
      }
    }
    if(check === this.objectivos.length){
      this.expandirObj = true;
    }else if(check === 0){
      this.expandirObj = false;
    }
  }
  public oculMostAll(){
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        if(this.expandirObj){
          obj.check = false;
        }else{
          obj.check = true;
        }
      }
    }
    this.expandirObj = !this.expandirObj;
  }
  public exportarExcelTab(){
    const title = ''+this.translate.instant('menu.Cuadro Mando Integral');
    var header = [""+this.translate.instant('seguimiento.cmi.persp'),
    ""+this.translate.instant('seguimiento.cmi.objec') +' / '+this.translate.instant('seguimiento.cmi.indic'),
    ""+this.translate.instant('seguimiento.cmi.avanc')];
    var data = [];
    var aux = [], i = 0;
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        aux.push( i===0?per.perspective.name:'' );
        aux.push( obj.name );
        aux.push( obj.avance!==undefined?obj.avance/100:'' );
        data.push(aux);
        i++;
        aux = [];
        if(obj.indicadores !== undefined){
          for(const ind of obj.indicadores){
            aux.push( '' );
            aux.push( '- '+(this.verCodigo?'Código | ':' ')+''+ind.nombre );
            aux.push( ind.avance!==undefined?ind.avance/100:'' );
            data.push(aux);
            aux = [];    
          }
        }
      }
      i=0;
    }
    const NomArch = ''+this.translate.instant('menu.Cuadro Mando Integral');
    this.excelService.generateExcelCMISeguimiento(title, header, data, NomArch,this.semaforo);
  }
  /* Cambio en el avance */
  public changeAvance(){
    this.acum = !this.acum;
    if(this.acum){
      this.indicadoresMostrar = [].concat(this.indicadores);
    }else{
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
    }
    this.obtAvance();
    this.obtenerIndicadoresObj();
  }
  /* formateo de datos */
  public escrituraPeriodo(Periodo){
    if(Periodo.split(' ').length === 2){
      const datos = Periodo.split(' ');
      return this.translate.instant('filtros.'+datos[0])+' '+datos[1];
    }else if(Periodo.split(' ').length === 5){
      const datos = Periodo.split(' ');
      return this.translate.instant('filtros.'+datos[0])+' '+datos[1]+' '+datos[2]+' '+this.translate.instant('filtros.'+datos[0])+' '+datos[1];
    }
    return Periodo;
  }
}
