import { ConstantesImagenes } from './../modules/reportes/codigoimagenes';
import Swal from 'sweetalert2';

export var pestel = [
  {"id": 1, "name":"Políticos",},
  {"id": 2, "name":"Económicos"},
  {"id": 3, "name":"Sociales"},
  {"id": 4, "name":"Tecnológicos"},
  {"id": 5, "name":"Ecológicos"},
  {"id": 6, "name":"Legales"}
]

export class exclusiones{
  static puedeVer(correo: string){
    const logos = {
      'info@rflatina.com': false,
      'ecruz@rflatina.com': false,
    }
    return logos[correo] ?? true;
  }
}
//clases
export class Instituciones{
  id?: number;
  nombre?:string;
  acronimo?: string;
}
export class Planificacion{ 
  id?: number;
  nombre?: string;
  fecha_inicio?:Date;
  fecha_fin?:Date;
  isActive?:boolean;
  business_days?:string;
  responsible?:number;
  enableMailReports?:string;

  institucionId?: number;
}
export class Semaforos{
  id?: number;
  nombre?: string; //cantidad de colores
  descripcion?: string;
  operador_00?: string;
  valor_00?: number;
  operador_01?: string;
  valor_01?: number;
  valor_10?: number;
  valor_11?: number;
  color?: string[];

  planningId?: number;
}
export class Perspectivas{
  id?: number;
  nombre?: string;
  descripcion?: string;
  check?: boolean;
  objectives?: any[];

  planningId?: number;
}
export class Filosofia_Corporativa{
  id?: number;
  nombre?: string;
  descripcion?: string;
  
  planningId?: number;
}
export class Matriz_FLOA{
  id?: number;
  tipo?: string;
  nombre?: string;

  perspectiveId?:number;
}
export class Perspectiva {
  public id?: number;
  public nombre?: string;
  public descripcion?: string;

  public planningId?: number;
}
export class Objetivos {
  public id?: number;
  public nombre?: string;
  public descripcion?: string;
  public ponderacion?: number;

  public check?: boolean;
  public perspectiveId?: number;
  public responsableId?: number[];
}
export class Responsable_Objetivos{
  public responsablesId?: number;
  public objetiveId?: number;
}
export class Responsables {
  public id?: number;
  public nombre?: string;
  public apellido_paterno?: string;
  public apellido_materno?: string;
  public cargo?: string;
  public email?: string;
  public check?: boolean;
  public institucionId?: number;
  public isValidator?: boolean;

  public areaId?: number;
}

export class Areas{
  public id?: number;
  public nombre?: string;
  public descripcion?: string;
  public institucionId?: number;
}
export class Indicadores {
  public id?: number;
  public nombre?: string;
  public descripcion?: string;
  public calculo?: string;
  public linea_base?: string;
  public periodicidad?: string;
  public fecha_inicio?: Date;
  public fecha_fin?: Date;
  public ponderacion?: string;
  public meta_global?: number;
  public tendencia?: string;
  public parametro?: string;
  public adicionales?: any[];

  public objetiveId?: number;
  public areaId?: number;
  public measureId?: number;
}
export class Metas{
  public id?: number;
  public descripcion?: string;
  public nombre?: Date;

  public indicatorId?: number;
}
export class Adicionales {
  public nombre?: string;
  public tipo?: string;
  public monto?: number;
  public archivos?: string;
  public nota?: string
  public id?: number;

  public indicadorId?: number;
  public actividadId?: number;
}
export class Unidad_Medida {
  nombre?: string;
  id?: number;
}
export class Estrategias {
  public id?: number;
  public name?: string;
  public description?: string;
  public objetiveId?: number[];
}
export class Proyectos {
  public nombre?: string;
  public ponderacion?: number;
  public descripcion?: string;
  //public objetiveId?: number;
  public objetiveId?: number[];
  public programaId?: number[];
  public id?: number;
  public fecha_ini?: Date;
  public fecha_fin?: Date;
  public responsableId?: number;
}
export class Actividades {
  public nombre?: string;
  public fecha_inicio?: Date;
  public fecha_fin?: Date;
  public projectId?: number;
  public id?: number; 
  public responsableId?: number;
  public adicionales?: any[];
  public historico?: any[];
}
export class estrategias_proyectos {
  estrategiasId?: number;
  proyectosId?: number;
}

export class Meses{
  static getMeses(mes: any){
    var mess = (new Date(mes)).getMonth()+1;
    switch (mess){
      case 1: return 'ene';
      case 2: return 'feb';
      case 3: return 'mar';
      case 4: return 'abr';
      case 5: return 'may';
      case 6: return 'jun';
      case 7: return 'jul';
      case 8: return 'ago';
      case 9: return 'sep';
      case 10: return 'oct';
      case 11: return 'nov';
      case 12: return 'dic';
    }
    return mess;
  }
  static getMesesN(mes: any){
    switch (mes){
      case 1: return 'ene';
      case 2: return 'feb';
      case 3: return 'mar';
      case 4: return 'abr';
      case 5: return 'may';
      case 6: return 'jun';
      case 7: return 'jul';
      case 8: return 'ago';
      case 9: return 'sep';
      case 10: return 'oct';
      case 11: return 'nov';
      case 12: return 'dic';
    }
    return mes;
  }
  static getMesesS(mes: any){
    switch (mes){
      case 'ene': return 1;
      case 'feb': return 2;
      case 'mar': return 3;
      case 'abr': return 4;
      case 'may': return 5;
      case 'jun': return 6;
      case 'jul': return 7;
      case 'ago': return 8;
      case 'sep': return 9;
      case 'oct': return 10;
      case 'nov': return 11;
      case 'dic': return 12;
    }
    return mes;
  }
  static getMesesNum(mes: any){
    switch (mes){
      case 'ene': return '01';
      case 'feb': return '02';
      case 'mar': return '03';
      case 'abr': return '04';
      case 'may': return '05';
      case 'jun': return '06';
      case 'jul': return '07';
      case 'ago': return '08';
      case 'sep': return '09';
      case 'oct': return '10';
      case 'nov': return '11';
      case 'dic': return '12';
    }
    return mes;
  }
  static compararPer(per1: any, per2: any, periodoact: any){
    var fecha_ini = new Date(this.getMesesS(per1.split('-')[0])+'/1/'+per1.split('-')[1]);
    var fecha_fin = new Date(this.getMesesS(per2.split('-')[0])+'/1/'+per2.split('-')[1]);
    var fecha_act = new Date(this.getMesesS(periodoact.split('-')[0])+'/1/'+periodoact.split('-')[1]);

    if(fecha_ini <= fecha_act && fecha_act <= fecha_fin){
      return true;
    }

    return false;
  }
  static compararAnio(per1: any, per2: any, periodoact: any){
    if(per1 <= periodoact && per2 >= periodoact){
      return true;
    }
    return false;
  }
  static obtenerAvance(fech_ini: any, fech_fin: any){
    var avance: any = []
    
    var year1 = fech_ini.getFullYear(), year2 = fech_fin.getFullYear(), mes1 = fech_ini.getMonth()+1, mes2 = fech_fin.getMonth()+1;
    var meses = (12-mes1) + (12*(year2 - year1 - 1)) + (mes2+1); 

    for(var i = mes1; i< mes1+meses; i++){
      var mes;
      if(i%12 != 0){
        mes = this.getMesesN(i%12);
        var anio = ''+(year1+Math.trunc(i/12));
      }else{
        mes = this.getMesesN(12);
        var anio = ''+(year1+Math.trunc(i/12)-1);
      }
      avance.push({periodo: mes +'-'+ (''+anio).substring(2,4), value: 0});
    }
    
    return avance;
  }
  
  static obtenerPlaneado(fech_ini: any, fech_fin: any, diasLab: any){
    var diasNlab = 0;
    switch(diasLab){
      case 'Lunes-Viernes': diasNlab = 2; break;
      case 'Lunes-Sabado': diasNlab = 1; break;
      case 'Lunes-Domingo': diasNlab = 0; break;
    }
    
    var avance: any = [];
    
    var year1 = fech_ini.getFullYear(), year2 = fech_fin.getFullYear(), mes1 = fech_ini.getMonth()+1, mes2 = fech_fin.getMonth()+1;
    var meses = (12-mes1) + (12*(year2 - year1 - 1)) + (mes2+1);

    var diastot = 0, aux = [];

    for(var i = mes1; i< mes1+meses; i++){
      var mes;
      if(i%12 != 0){
        mes = this.getMesesN(i%12)
        var anio = ''+(year1+Math.trunc(i/12));
      }else{
        mes = this.getMesesN(12);
        var anio = ''+(year1+Math.trunc(i/12)-1);
      }

      var d;

      if(i === mes1 ){
        d = this.maxdias(mes,year1)-(fech_ini.getDate()-1);
      }else if(i === (mes1 + meses -1)){
        d = (fech_fin.getDate());
      }else{
        d = this.maxdias(mes,anio)
      }
      d = d - Math.trunc(d/7)*diasNlab;
      diastot += d;
      aux.push({periodo: mes + '-' +(''+anio).substring(2,4), value: 0, dias: d});
    }
    
    for(var j = 0; j<aux.length; j++){
      var val = 0;
      if(j === 0){
        val = Number(((aux[j].dias*100)/diastot).toFixed(0));
      }else{
        val = Number(((aux[j].dias*100)/diastot).toFixed(0)) + avance[j-1].planeado;
      }

      if(j != aux.length-1){
        avance.push({periodo: aux[j].periodo, planeado: val, ejecutado: null, id: ''});
      }else{
        avance.push({periodo: aux[j].periodo, planeado: 100, ejecutado: null, id: ''});
      }
    }

    return avance;
  }
  static obtnerPer(fecha: Date){
    var mes = this.getMesesN(fecha.getMonth()+1);
    var anio = (''+fecha.getFullYear()).substring(2,4);

    return mes+'-'+anio;
  }
  static obtnerPerAnt(fecha: Date){
    var mes = this.getMesesN(fecha.getMonth());
    if(mes === 0){
      var anio = (''+(fecha.getFullYear()-1)).substring(2,4);
      mes = this.getMesesN(12);
    }else{
      var anio = (''+fecha.getFullYear()).substring(2,4);
    }

    return mes+'-'+anio;
  }
  
  static maxdias(mes: any, anio: any){
    var dias = 0;
    switch (mes){
      case 'ene': dias = 31; break;
      case 'feb': (4%anio != 0)? dias = 28 : dias = 29; break;
      case 'mar': dias = 31; break;
      case 'abr': dias = 30; break;
      case 'may': dias = 31; break;
      case 'jun': dias = 30; break;
      case 'jul': dias = 31; break;
      case 'ago': dias = 31; break;
      case 'sep': dias = 30; break;
      case 'oct': dias = 31; break;
      case 'nov': dias = 30; break;
      case 'dic': dias = 31; break;
    }
    return dias;
  }
  static obtenerPeriodos(fecha_inicial: Date, fecha_final: Date){
    const date = new Date();
    if( date.getFullYear() < fecha_final.getFullYear() ){
      fecha_final = date;
    }else if(date.getFullYear() === fecha_final.getFullYear() && date.getMonth() < fecha_final.getMonth()){
      fecha_final = date;
    }
    var aniosDisponibles: any = [];
    var messin = 0, messfinal;
    aniosDisponibles = [];
    const fech = new Date();
    const perAct = this.getMesesN(fech.getMonth()+1)+'-'+fech.getFullYear().toString().substring(2,4);
    for(var i = fecha_inicial.getFullYear(); i<=fecha_final.getFullYear(); i++){
      if(i === fecha_inicial.getFullYear() && i != fecha_final.getFullYear()){
        messin = fecha_inicial.getMonth();
        messfinal = 11;
      }else if(i === fecha_final.getFullYear() && i != fecha_inicial.getFullYear()){
        messin = 0;
        messfinal = fecha_final.getMonth();
      }else if( i != fecha_inicial.getFullYear() && i != fecha_final.getFullYear() ){
        messin = 0;
        messfinal = 11;
      }else{
        messin = fecha_inicial.getMonth();
        messfinal = fecha_final.getMonth();
      }
      var anio = ''+i;
      aniosDisponibles.push({anio: i, series: []})
      for(var j = messin; j <= messfinal; j++){
        aniosDisponibles[i-fecha_inicial.getFullYear()].series.push({
          periodo: this.getMesesN(j+1) + '-' + anio.substring(2,4),
          anio: anio.substring(2,4),
          mes: this.getMesesN(j+1),
          check: false,
        })
      }
    }
    aniosDisponibles[aniosDisponibles.length-1].series[aniosDisponibles[aniosDisponibles.length-1].series.length-1].check = true;
    return aniosDisponibles;
  }
  static obtenerPeriodosFull(fecha_inicial: Date, fecha_final: Date){
    var aniosDisponibles: any = [];
    var messin = 0, messfinal;
    aniosDisponibles = [];
    const fech = new Date();
    const perAct = this.getMesesN(fech.getMonth()+1)+'-'+fech.getFullYear().toString().substring(2,4);
    for(var i = fecha_inicial.getFullYear(); i<=fecha_final.getFullYear(); i++){
      if(i === fecha_inicial.getFullYear() && i != fecha_final.getFullYear()){
        messin = fecha_inicial.getMonth();
        messfinal = 11;
      }else if(i === fecha_final.getFullYear() && i != fecha_inicial.getFullYear()){
        messin = 0;
        messfinal = fecha_final.getMonth();
      }else if( i != fecha_inicial.getFullYear() && i != fecha_final.getFullYear() ){
        messin = 0;
        messfinal = 11;
      }else{
        messin = fecha_inicial.getMonth();
        messfinal = fecha_final.getMonth();
      }
      var anio = ''+i;
      aniosDisponibles.push({anio: i, series: []})
      for(var j = messin; j <= messfinal; j++){
        aniosDisponibles[i-fecha_inicial.getFullYear()].series.push({
          periodo: this.getMesesN(j+1) + '-' + anio.substring(2,4),
          mes: this.getMesesN(j+1),
          check: false,
        })
      }
    }
    aniosDisponibles[aniosDisponibles.length-1].series[aniosDisponibles[aniosDisponibles.length-1].series.length-1].check = true;
    return aniosDisponibles;
  }
}
export class procesamiento{
  static procesamiento_Texto(texto: string): string{
    var textoPros = texto.split('\t')[0];
    while( textoPros.split(' ')[textoPros.split(' ').length-1] === '' ){
      var aux = textoPros.split(' ');
      aux.pop();
      textoPros = aux.join(" ");
    }
    return textoPros;
  }
}
export class obtenerError{
  static errorObt(err: string){
    const map = {
      "Internal server error": "Ocurrio un error",
      undefined: "Ocurrio un error",
    };
    return map[err] ?? err+'';
  }
}
export class obtenerLogo{
  static obtLogo(acron: string){
    const logos = {
      'OSCUS': ConstantesImagenes.imgLogo,
      'COOPSEGUROS': ConstantesImagenes.logoCopseguros,
      'La Huerta': ConstantesImagenes.logoLaHuerta,
      'COOPSAFA': ConstantesImagenes.logoCoopsafa,
      'Equidad': ConstantesImagenes.logoEquidad,
    }
    return logos[acron] ?? '';
  }
}
export class obtenerTamMax{
  static peso = 5;
  static mbMax = 1024*1000*this.peso;
  static errorMessage = 'El archivo no debe ser mayor a '+this.peso+' Mb';

  static getPeso(): Number{
    return this.mbMax;
  }
  
  static getTexto(): string{
    return this.errorMessage;
  }
}
export class alertas{
  static swalEspera(text: any){
    Swal.fire({
      title: ''+text,
      html: 'Por favor espere.',
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });
  }
  /* Mensaje de error */
  static messageError(err, time){
    let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: time,
      });
  }
}
