import { Component, OnInit, AfterViewInit, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-grafica-lineal',
  templateUrl: './grafica-lineal.component.html',
  styleUrls: ['./grafica-lineal.component.css']
})
export class GraficaLinealComponent implements OnInit, AfterViewInit {

  @Input() DatosGraf: any = [];

  //@ts-ignore
  @ViewChild('graf') canvas: ElementRef;//QueryList<ElementRef<HTMLCanvasElement>>;


  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    var labels = [], dataMeta: any = [], dataReal : any = [];
    for(const dat of this.DatosGraf){
      labels.push(dat[0]);
      dataMeta.push(dat[1]);
      dataReal.push(dat[2]);
    }
    var dataG: any = [
      {label: 'Meta', data: dataMeta, borderColor: '#003772', pointBackgroundColor: '#fff', pointRadius: 4},
      {label: 'Real', data: dataReal, borderColor: '#79B2D1', pointBackgroundColor: '#fff', pointRadius: 4}
    ]  
    var chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
      type: 'line',
      data: {
        labels: labels,
        datasets: dataG,
      },
      options: {
        responsive: true,
        aspectRatio: 3,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxHeight: 6,
              boxWidth: 6,
              useBorderRadius: true,
              borderRadius: 3,
              pointStyle: 'circle',
              font: {
                family: "'Roboto'",
                size: 14
              }
            }
          }
        },
      }
    });
  }



}
