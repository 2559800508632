import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Matriz_FLOA, pestel, procesamiento, obtenerError } from './../../../model/datos';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2' ;
import { MatrizFloa2Service } from '../../../services/formulacion/matrizfoda2/matriz-floa2.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-matriz-foda-n',
  templateUrl: './matriz-foda-n.component.html',
  styleUrls: ['./matriz-foda-n.component.css']
})
export class MatrizFodaNComponent implements OnInit {
  private idPlan = 0;
  private planeacion;
  public perspectiva = [];
  public idelem: number = 0;
  public tipoelem: any; //para el tipo
  public elementt: any; //para el edit
  public modal = false;
  public modal2 = false;
  public modal3 = false;
  public modal4 = false;
  public PerSelect: any;
  public ncodigo: number = 0;
  public catalogo = pestel;
  public elemento = [];
  public internos = [
    {id:1, type:'FORTALEZA', elemento:'Fortalezas'},
    {id:2, type:'DEBILIDAD', elemento:'Debilidades'},
  ];
  public externos = [
    {id:3, type:'OPORTUNIDAD', elemento:'Oportunidades'},
    {id:4, type: 'AMENAZA', elemento:'Amenazas'},
  ];
  
  public diccionarioFLOA1: any = [];
  public diccionarioFLOA2: any = [];

  formGeneral = new FormGroup({
    inputName: new FormControl('', [Validators.required]),
    inputNameEdit: new FormControl('', [Validators.required]),
    inputPerspective: new FormControl('', [Validators.required]),
    inputPerspectiveEdit: new FormControl('', [Validators.required]),

    inputNameExt: new FormControl('', [Validators.required]),
    inputNameEditExt: new FormControl('', [Validators.required]),
    inputPerspectiveExt: new FormControl('', [Validators.required]),
    inputPerspectiveEditExt: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  constructor(
    public dataService: DataService,
    private matrizService: MatrizFloa2Service,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) {
    var param = this.rutaActiva.snapshot.params
    this.idPlan = Number(param['planeacion']);
    this.obtData();
  }

  ngOnInit(): void {}
  /* Error en la consulta */
  private error(err){
    let er = obtenerError.errorObt(err['error'].message);
    Swal.fire({
      title: er,
      text: '',
      icon: 'error',
      showConfirmButton: false,
      timer: this.dataService.tiempo,
    });
  }
  /* Metodos de ordenamiento */
  private ordenarPerspe(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /* Consultas */
  private obtData(){
    this.matrizService.getPlanificacion(this.idPlan).subscribe( respuesta => {
      this.planeacion = {
        id: respuesta.id,
        nombre: respuesta.name,
        fecha_inicio: new Date(respuesta.initial_date),
        fecha_fin: new Date(respuesta.end_date),
        institucionId: respuesta.institution.id,
        isActive: respuesta.isActive
      };

      for(const per of respuesta.perspectives){
        this.perspectiva.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: this.idPlan,
          id: per.id,
        });
      }
      this.perspectiva = this.ordenarPerspe(this.perspectiva);

      this.internos.forEach(per => { this.diccionarioFLOA1.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva}); });
      this.externos.forEach(per1 => { this.diccionarioFLOA2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva}); });

        this.elemento = [];
        this.matrizService.getFLOA().subscribe( res => {
          this.getFloa(res);
        });
    }, err =>{
      this.error(err)
    });
  }
  private getFloa(res){
    this.elemento = [];
    for(const mat of res){
      const ex = this.perspectiva.filter(per => Number(per.id) === Number(mat.perspective.id));
      if(ex.length !== 0 && mat.name.includes('&&')){
        this.elemento.push(mat);
      }            
    }
    this.elemento = this.ordenarPerspe(this.elemento);

    for(const dicc of this.diccionarioFLOA1){
      const elements = this.elemento.filter(mat => mat.type === dicc['internosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: ''
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }
      }
    }

    for(const dicc of this.diccionarioFLOA2){
      const elements = this.elemento.filter(mat => mat.type === dicc['externosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: elem.name.split('&&')[1],
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }            
      }
    }
  }
  // Obtención de los codigos
  public obtCodigo(inter:any){
    switch(inter.id){
      case 1: return 'F';
      case 2: return 'D';
      case 3: return 'O';
      case 4: return 'A';
    }
    return 'no';
  }
  /** Metodo de deción de guardado de datos */
  public alertas(aler: any,ele?:any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'editarExt'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEditExt();
        }
      });
    }else if(aler === 'guardarExt'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDExter();
        }
      });
    }else if(aler === 'eliminar'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar este elemento?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarele(ele);
        }
      });
    }
  }
  /** Almacenamiento de los datos */
  //Elementos internos
  public almacenarD(){
    var element = new Matriz_FLOA();
    element.tipo = this.tipoelem;
    element.nombre = document.getElementById('nombre')['value'] + '&&';
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspective'].value);
    this.matrizService.postFLOA(element).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(res => {
        this.getFloa(res);
        Swal.fire({
          title: 'Elemento agregado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        this.error(err);
      });
    },err =>{
      this.error(err);
    });
    this.cerrarForm();
  }
  public almacenarDatosEdit(){
    var item = new Matriz_FLOA();
    item.id = this.elementt.id;
    item.tipo = this.elementt.tipo;
    item.nombre = document.getElementById('nombreedit')['value']+ '&&';
    item.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveEdit'].value);
    this.matrizService.patchFLOA(item).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(response => {
        this.getFloa(response);
        Swal.fire({
          title: 'Elemento actualizado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        this.error(err);
      });
    },err =>{
      this.error(err);
    })
    this.cerrarForm();
  }
  //elementos externos
  public almacenarDExter(){
    var element = new Matriz_FLOA();
    element.tipo = this.tipoelem;
    element.nombre = document.getElementById('nombreExt')['value'] + '&&'+Number(this.formGeneral.controls['inputPerspectiveExt'].value);
    element.perspectiveId = Number(this.perspectiva[0].id);
    this.matrizService.postFLOA(element).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(res => {
        this.getFloa(res);
        Swal.fire({
          title: 'Elemento agregado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        this.error(err);
      });
    },err =>{
      this.error(err);
    });
    this.cerrarForm();
  }
  public almacenarDatosEditExt(){
    var item = new Matriz_FLOA();
    item.id = this.elementt.id;
    item.tipo = this.elementt.tipo;
    item.nombre = document.getElementById('nombreeditExt')['value']+ '&&'+ Number(this.formGeneral.controls['inputPerspectiveEditExt'].value);
    item.perspectiveId = this.elementt.perspectiveId;
    this.matrizService.patchFLOA(item).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(response => {
        this.getFloa(response);
        Swal.fire({
          title: 'Elemento actualizado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        this.error(err);
      });
    },err =>{
      this.error(err);
    });
    this.cerrarForm();
  }
  /** Eliminación de los datos */
  public eliminarele(ele: any){
    this.matrizService.deleteFLOA(ele).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(response => {
        this.getFloa(response);
        Swal.fire({
          title: 'Elemento eliminado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        this.error(err);
      });
    },err =>{
      this.error(err);
    });
  }
  /** Desición de guardado */
  public puede_guardar(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombre')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspective'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombreedit')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveEdit'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarExt(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombreExt')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveExt'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEditExt(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombreeditExt')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveEditExt'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Modales */
    // Abrir elementos internos
  public abrirFormAgregar(elem: any){
    this.idelem = elem.id;
    this.tipoelem = elem.type;
    document.getElementById('formularionuevo')!.style.display = 'block';
    this.modal = true;
  }
  public abrirFormEdit(ele: any, eleme: any){
    this.modal2 = true;
    this.elementt = ele;
    this.idelem = eleme.id;
    this.formGeneral.controls['inputNameEdit'].setValue(this.elementt.nombre);
    this.formGeneral.controls['inputPerspectiveEdit'].setValue(this.elementt.perspectiveId);
    document.getElementById('formularioedit')!.style.display = 'block'
  }
    // Abrir elementos internos
  public abrirFormAgregarExt(elem: any){
    this.idelem = elem.id;
    this.tipoelem = elem.type;
    document.getElementById('formularionuevoExt')!.style.display = 'block';
    this.modal3 = true;
  }
  public abrirFormEditExt(ele: any, eleme: any){
    this.modal4 = true;
    this.elementt = ele;
    this.idelem = eleme.id;
    this.formGeneral.controls['inputNameEditExt'].setValue(this.elementt.nombre);
    this.formGeneral.controls['inputPerspectiveEditExt'].setValue(this.elementt.pestel);
    document.getElementById('formularioeditExt')!.style.display = 'block'
  }
    // Cerrar
  public cerrarForm(){
    this.modal = false;
    this.modal2 = false;
    this.modal3 = false;
    this.modal4 = false;
    document.getElementById('formularionuevo')!.style.display = 'none';
    document.getElementById('formularioedit')!.style.display = 'none';
    document.getElementById('formularionuevoExt')!.style.display = 'none';
    document.getElementById('formularioeditExt')!.style.display = 'none';
    
    this.formGeneral.reset();
  }
  /** Exportación */
  public exportar_excel(){
    let subtituloElementosInternos = "Elementos internos";
    let headersInternos = [];
    let elementosInternos = [];
    for(let i = 0; i < this.diccionarioFLOA1.length; i++){
      let header = [];
      headersInternos.push('#');
      headersInternos.push(this.diccionarioFLOA1[i].internosss.elemento);
      headersInternos.push('');
      let elemento = [];
        for(let j = 0; j < this.diccionarioFLOA1[i].elementooo.length; j++){
          let col1 = this.obtCodigo(this.diccionarioFLOA1[i].internosss).concat(''+(j+1));
          elemento.push([col1, this.diccionarioFLOA1[i].elementooo[j].nombre]);
          
        }
      
      elementosInternos.push(elemento);
    }

    let subtituloElementosExternos = "Elementos externos";
    let headersExternos = [];
    let elementosExternos: any = [];
    for(let i = 0; i < this.diccionarioFLOA1.length; i++){
      let header = [];
      headersExternos.push('#');
      headersExternos.push(this.diccionarioFLOA2[i].externosss.elemento);
      headersExternos.push('');
      let elemento = [];
      for(let j = 0; j < this.diccionarioFLOA2[i].elementooo.length; j++){
        let col1 = this.obtCodigo(this.diccionarioFLOA2[i].externosss).concat(''+(j+1));
        elemento.push([col1, this.diccionarioFLOA2[i].elementooo[j].nombre]);
      }
      elementosExternos.push(elemento);
    }

    this.excelService.generateExcelMatrizFoda('Formulación: Matriz FODA', headersInternos,  headersExternos, elementosInternos,  elementosExternos, 'matriz_foda', subtituloElementosInternos, subtituloElementosExternos);
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nombre')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nombreedit')["value"];
      }else if(this.modal3){
        nombre = document.getElementById('nombreExt')["value"];
      }else if(this.modal4){
        nombre = document.getElementById('nombreeditExt')["value"];
      }
      const existe = this.elemento.find( element => {
        if(this.modal2 || this.modal4){
          return ( procesamiento.procesamiento_Texto(element.name.split('&&')[0]).toLowerCase() === nombre.toLowerCase() && element.id !== this.elementt.id );
        }
        return ( procesamiento.procesamiento_Texto(element.name.split('&&')[0]).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formGeneral.hasError('isValid');
  }
}
