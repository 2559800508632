// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const estrategico = 'formulacion/';
const seg = 'seguimiento/';
const rep = 'reportes/';
const adm = 'administrador/';
export const environment = {
  production: false,
  baseUrl: 'https://api-aleemacpsr.rflatina.com/rfl/api/v1/',
  modules: [
    {identifier: 'est-administrador', name: 'Administrador', route: '', submodules: [], module: 'Administrador'},
    {identifier: 'est-formulación', name: 'Formulación', route: '', submodules: [], module: 'Formulación'},
    {identifier: 'est-seguimiento', name: 'Seguimiento', route: '', submodules: [], module: 'Seguimiento'},
    {identifier: 'est-informes', name: 'Reportes', route: '', submodules: [], module: 'Reportes'},
  ],
  submodules: [
    //Administrador
    { identifier: 'est-catalágos', name: 'Catálogos', route: adm + 'catalogo', module: 'Administrador' },
    { identifier: 'est-altadeplanificaciones', name: 'Alta de planes', route: adm + 'altaplanes', module: 'Administrador' },
    //Formulación
    { identifier: 'est-filosofíacorporativa', name: 'Perspectivas', route: estrategico + 'perspectivas', module: 'Formulación' },
    { identifier: 'est-filosofíacorporativa', name: 'Filosofía corporativa', route: estrategico + 'filosofiacorporativa', module: 'Formulación' },
    { identifier: 'est-matrizfloa', name: 'Matriz FODA', route: estrategico + 'matrizfoda', module: 'Formulación' },
    { identifier: 'est-matrizderiesgosyoportunidades', name: 'Matriz de riegos y oportunidades', route: estrategico + 'matrizfodaV2', module: 'Formulación' },
    { identifier: 'est-objetivos', name: 'Objetivos', route: estrategico + 'objetivos', module: 'Formulación' },
    { identifier: 'est-indicadores', name: 'Indicadores - metas', route: estrategico + 'indicadores', module: 'Formulación' },
    { identifier: 'est-alineamientoestratégico', name: 'Alineamiento estratégico', route: estrategico + 'alineamiento', module: 'Formulación' },
    { identifier: 'est-proyectosestratégicos', name: 'Proyectos estratégicos', route: estrategico + 'proyectosestrategicos', module: 'Formulación' },
    { identifier: 'est-cuadromandointegralcmi', name: 'Cuadro Mando Integral', route: estrategico + 'cmi', module: 'Formulación' },
    { identifier: 'est-altadeplanes', name: 'Alta de planes', route: estrategico + 'altaProyectos2', module: 'Formulación' },
    { identifier: 'est-altaactas', name: 'Alta actas', route: estrategico + 'altaactas', module: 'Formulación' },
    // Seguimiento
    { identifier: 'est-cmi', name: 'Cuadro Mando Integral', route: seg + 'cuadromando', module: 'Seguimiento' },
    { identifier: 'est-objetivos_s', name: 'Objetivos', route: seg + 'objetivos', module: 'Seguimiento' },
    { identifier: 'est-indicadores-metas', name: 'Indicadores - metas', route: seg + 'indicadoresMeta', module: 'Seguimiento' },
    { identifier: 'est-estratégias', name: 'estrategia', route: seg + 'programas', module: 'Seguimiento' },
    { identifier: 'est-proyectosestratégicos_s', name: 'Proyectos estratégicos', route: seg + 'proyectosest', module: 'Seguimiento' },
    //{ identifier: 'est-proyectosestratégicos_s', name: 'Avance Proyectos', route: seg + 'avanceProyectos', module: 'Seguimiento' },
    { identifier: 'est-planes', name: 'Planes', route: seg + 'proyectos2', module: 'Seguimiento' },
    { identifier: 'est-actas', name: 'Actas', route: seg + 'actas', module: 'Seguimiento' },
    //Reportes
    { identifier: 'est-planestratégico', name: 'Plan estratégico', route: rep + 'planestrategico', module: 'Reportes' },
    { identifier: 'est-avancedeseguimientodelplan', name: 'Avance de seguimiento del plan', route: rep + 'reporteseguimiento', module: 'Reportes' },
  ],
  //apiDevURL_institucion: 'https://www.api-aleema-gestionestrategica.rflatina.com/api/v1/',
  apiDevURL_institucion: 'https://www.dev.aleema-gestionestrategica.rflatina.com/api/v1/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
