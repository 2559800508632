<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-caja-menu padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ 'menu.'+dataService.currentModule | translate }} </b></span> 
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ 'menu.'+dataService.currentSubmodule | translate }} </span>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row">
      <div class="w3-twothird">
        <div class="menuexp">
          <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
            [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        </div>
        <div class="botones">
          <div *appRoles="['Rol_Administrador']">
            <button matTooltip="{{'formulacion.perspectivas.agregPers'|translate}}" class="colorBotonDeNuevo" (click)="abrirFormAgregar(perspectiva)">
              <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
              {{'formulacion.perspectivas.agregPers'|translate}}
            </button>
          </div>
        </div>
        <mat-menu #menu2="matMenu">
          <ng-container *ngIf="planeacion">
          </ng-container>
          <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>{{'formulacion.perspectivas.export'|translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="w3-row">
      <div *ngFor="let perspe of perspectiva; let i = index;">
        <div class="w3-twothird " style="height: 30vh; margin-top: 1vh;">
          <div class="w3-row w3-white">
            <div class="w3-noventa titulo-contenidomenu">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas"> {{perspe.nombre}} </span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest">
              <div class="w3-right hijoderechaTitle" style="z-index: 1;">
                <mat-icon *appRoles="['Rol_Administrador']" class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
                  matTooltip="{{'formulacion.perspectivas.tooltip1'|translate}}" (click)="abrirForm(perspe)">
                  edit
                </mat-icon>
                <ng-container *ngIf="i>3">
                  <mat-icon *appRoles="['Rol_Administrador']" class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
                    matTooltip="{{'formulacion.perspectivas.tooltip2'|translate}}" (click)="eliminar(perspe)">
                    delete
                  </mat-icon>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="w3-row w3-white" style="height: 22vh;">
            <div class="w3-margin w3-margin-bottom">
              <mat-form-field appearance="outline" style="width: 95%;">
                <textarea matInput placeholder="Descripción" style="height:12vh;" value="{{perspe.descripcion}}" disabled></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="formulario" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <form [formGroup]="formEdit" (ngSubmit)="alertas('editar')">
      <div class="w3-row" style="height: 10vh;">
        <div class="w3-container" style="overflow: scroll;">
  
          <div class="w3-col inputDisp">
            <mat-error *ngIf="formEdit.hasError('isValid')" class="error-message">
              {{'formulacion.perspectivas.formText1'|translate}} <strong> {{'formulacion.perspectivas.formText2'|translate}}.</strong>
            </mat-error>
            <mat-form-field appearance="legacy" class="input-nombres">
              <mat-label> {{'formulacion.perspectivas.formText3'|translate}} </mat-label>
              <input type="text" matInput placeholder="Ingrese un nombre" id="nombreedit" formControlName="inputName" required>
              <mat-error>
                <span>{{'formulacion.perspectivas.formText4'|translate}} <strong> {{'formulacion.perspectivas.formText5'|translate}}.</strong></span>
              </mat-error>
            </mat-form-field>
          </div>
  
        </div> 
        <div class="w3-rest">
          <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
        </div>
      </div>
      <div class="w3-row w3-white" style="height: 35vh;">
        <div class="w3-container" style="height: 28vh; overflow: scroll;">
  
          <div class="w3-row text-area">
            <div class="w3-col">
              <mat-form-field appearance="outline" class="input-nombres">
                <mat-label> {{'formulacion.perspectivas.formText6'|translate}} </mat-label>
                <textarea matInput placeholder="Ingrese una descripción" formControlName="inputDescription" id="descripcionedit" style="height:10vh;"></textarea>
                <mat-error>
                  <span>{{'formulacion.perspectivas.formText4'|translate}} <strong> {{'formulacion.perspectivas.formText5'|translate}}.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row" *ngIf="modal2">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarEdit()" >
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  {{'formulacion.perspectivas.formText7'|translate}}
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">
                  {{'formulacion.perspectivas.formText8'|translate}}
                </button>
              </div>
            </div>
          </div>
  
        </div>
      </div> 
    </form>   
  </div>
</div>

<div id="formularionuevo" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <form [formGroup]="formNuevo" (ngSubmit)="alertas('guardar')">
      <div class="w3-row" style="height: 10vh;">
        <div class="w3-container" style=" overflow: scroll;">
  
            <div class="w3-col inputDisp">
              <mat-error *ngIf="formNuevo.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="legacy" class="input-nombres">
                <mat-label> Perspectiva </mat-label>
                <input type="text" matInput placeholder="Ingrese un nombre" id="nombre" formControlName="inputName" required>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
  
        </div>
        <div class="w3-rest">
          <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
        </div>
      </div>
      <div class="w3-row" style="height: 35vh;">
        <div class="w3-container" style="height: 30vh; overflow: scroll;">
          
          <div class="w3-row text-area">
            <div class="w3-col">
              <mat-form-field appearance="outline" class="input-nombres">
                <mat-label>Descripción </mat-label>
                <textarea matInput placeholder="Ingrese una descripción" id="descripcion" formControlName="inputDescription" style="height:10vh;"></textarea>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row" *ngIf="modal">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardar()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
  
        </div>
      </div> 
    </form>   
  </div>
</div>