import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { ConstantesQubos } from 'src/app/util/app-util';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginFormComponent } from './login-form/login-form.component';

const PATH = ConstantesQubos.strPATH;

const routes: Routes = [
  { path: 'login', component: LoginFormComponent },
  {path: 'forgot-password', component: ForgotPasswordComponent},
];

@NgModule({
      imports: [RouterModule.forChild(routes)],
      exports: [RouterModule]
    })
export class LoginRoutingModule { }
