import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Area{
  public institutionId?: Number[];
  public name?: String;
  public id?:Number;
}
export class Settings{
  public name?: String;
  public value?: String;
}
export class Responsable{
  public areaId?: Number[];
  public name?: String;
  public first_surname?: String;
  public second_surname?: String;
  public email?: String;
  public position?: String;
  public id?:Number;
  public isValidator?: boolean;
}
export class institucion{
  public templateIds?: Number[];
}

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {

  private apiURLadm = 'https://api-adminclient.rflatina.com/rfl/api/v1/';
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getAreas(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getInstitucion(idInstitucion): Observable<any>{
    const apiURL = this.apiURL+"institucion/"+idInstitucion;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public patchInstitucion(idInstitucion, templates): Observable<any>{
    const ar: institucion = {
      templateIds: templates
    }
    const body = JSON.stringify(ar);
    const apiURL = this.apiURL+"institucion/"+idInstitucion;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public getTemplates(): Observable<any>{
    const apiURL = this.apiURL+"template-management";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  
  public postArea(area: any): Observable<any>{
    const ar: Area = {
      institutionId: area.institutionId,
      name: area.name
    }
    const body = JSON.stringify(ar);
    const apiURL = this.apiURL+"area";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postResponsable(res: any): Observable<any>{
    const responsable: Responsable = {
      areaId: res.areaId,
      name: res.name,
      first_surname: res.first_surname,
      second_surname: res.second_surname,
      email: res.email,
      position: res.position,
    }
    const body = JSON.stringify(responsable);
    const apiURL = this.apiURL+"responsable";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postSettings(idIns: any): Observable<any>{
    const responsable: Settings = {
      name: 'false',
      value: '5',
    }
    const body = JSON.stringify(responsable);
    const apiURL = this.apiURL+"institucion/"+idIns+"/settings";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchSettings(name: any,value: any,idSettings: any,idIns: any): Observable<any>{
    const responsable: Settings = {
      name: name+'',
      value: value+'',
    }
    const body = JSON.stringify(responsable);
    const apiURL = this.apiURL+"institucion/"+idIns+"/settings/"+idSettings;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public patchArea(area: any): Observable<any>{
    const ar: Area = {
      institutionId: area.institutionId,
      name: area.name
    }
    const body = JSON.stringify(ar);
    const apiURL = this.apiURL+"area/"+area.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchResponsable(res: any): Observable<any>{
    const responsable: Responsable = {
      areaId: res.areaId,
      name: res.name,
      first_surname: res.first_surname,
      second_surname: res.second_surname,
      email: res.email,
      position: res.position,
    }
    const body = JSON.stringify(responsable);
    const apiURL = this.apiURL+"responsable/"+res.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchResponsableValidador(res: any): Observable<any>{
    const responsable: Responsable = {
      isValidator: res.isValidator,
    }
    const body = JSON.stringify(responsable);
    const apiURL = this.apiURL+"responsable/"+res.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public deleteArea(area: any): Observable<any>{
    const apiURL = this.apiURL+"area/"+area.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteResponsable(res: any): Observable<any>{
    const apiURL = this.apiURL+"responsable/"+res.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  //consultas del administrador
  public getArchivosAdm(idInst): Observable<any>{
    const apiURL = this.apiURLadm+"files?id_company="+idInst+"&folder=expediente ";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getArchivosA(idFile): Observable<any>{
    const apiURL = this.apiURLadm+"files/"+idFile;
    return this.http.get(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
}
