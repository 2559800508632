import { JsonpClientBackend } from '@angular/common/http';
import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router, ActivatedRoute} from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import Swal from 'sweetalert2';
import { IndicadoresService } from '../../services/formulacion/indicadores/indicadores.service';
import { WelcomeService } from '../../services/welcome/welcome.service';
import { Planificacion } from '../../model/datos';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public planeacion: Planificacion[] = [];
  public acceso = [];
  public url: any;
  @Input() planeacionId = 0;
  @Input() planeacionSelec: any = ''

  constructor(
    private router: Router,
    public dataService: DataService,
    private indicadoresService: IndicadoresService,
    private activateRouter: ActivatedRoute,
    private welcomeService: WelcomeService,
    public translate: TranslateService,
  ) {
    this.url = this.activateRouter.snapshot['url'];
    welcomeService.getAccesos(dataService.currentUser).subscribe(response =>{
      if(response && response.access.length !== 0){
        this.acceso = [];
        for(const acces of response.access){
          this.acceso.push(''+acces);
        }
      }else{
        this.acceso = ['no encontrado'];
      }
    });
    this.dataService.fillerNav.forEach(module => {
      if(this.url.length>1 && (this.url[1]['path']).toLowerCase() === this.removeAccents(module.name.toLocaleLowerCase())){
        this.arrowState.push('keyboard_arrow_up');
      }else{
        this.arrowState.push('keyboard_arrow_down');
      }
      //this.arrowState.push('keyboard_arrow_down');
    });    
  }

  //@Input() sidenav: MatSidenav;

  public arrowState: Array<string> = [];

  ngOnInit(): void {
    
  }

  private removeAccents = (str: any) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public isActiveModule(menu: object): boolean {
    const rutaActual = this.router.url;
    const rutas = rutaActual.split('/');
    rutas.shift();
    rutas.shift();
    const moduloActual = this.dataService.fillerNav
      // @ts-ignore
      .find(module => this.removeAccents(module.name) === this.removeAccents(menu.name)
        && rutas[0] === this.removeAccents(module.name).toLowerCase()
      );
    if (moduloActual) {
      this.dataService.currentModule = moduloActual.name;
      return true;
    }else {
      return false;
    }
  }
  public isActiveSubmodule(submenu: object): boolean {
    let rutaActual = this.router.url;
    const rutas = rutaActual.split('/');
    rutas.shift();
    rutas.shift();
    if( !!Number(rutas[rutas.length-1]) ){
      rutas.pop();
    }
    const rutas2 = [rutas[0], rutas[rutas.length-1]];
    rutaActual = rutas2.join('/');
    if (submenu['route'] === rutaActual) {
      this.dataService.currentSubmodule = submenu['name'];
      return true;
    }else {
      return false;
    }
  }
  public enviar(submenu: any, menu: any){
    if(Number(this.planeacionId) !== 0 && submenu.name !== 'Catálogos' && this.url[1].path !== 'administrador'){
      var name = 'Alta de planes';
      if(submenu.name === name && menu.module === 'Administrador'){
        this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ submenu.route.split('/')[1]);
      }else{
        this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ submenu.route.split('/')[1] +'/'+this.planeacionId);
      }
    }else{
      this.enviarA(submenu, menu)
    }
  }
  public ayuda(){
    window.open("assets/documentos/Guía de usuario_ModStratega.pdf","_blank");
  }
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private accesoPlan(id: any){
    if ( this.acceso[0] === 'all' || this.acceso.includes(id) || this.acceso[0] === 'no encontrado'){
      return true;
    }
    return false;
  }
  public async enviarA(submenu:any, menu: any){
    if( (submenu.name !== 'Catálogos' && submenu.name !== 'Alta de planes') || (submenu.name === 'Alta de planes' && menu.module === 'Formulación') ){
      this.indicadoresService.getPlanificaciones().subscribe(response => {
        this.planeacion = [];
        for(const plan of response){
          if(this.accesoPlan(plan.institution.id)){
            this.planeacion.push({
              id: plan.id,
              nombre: plan.name,
              fecha_inicio: new Date(plan.initial_date),
              fecha_fin: new Date(plan.end_date),
              institucionId: plan.institution.id,
              isActive: plan.isActive
            });
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);
  
        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }
        if(Planeaciones.length > 0){
          Swal.fire({
            title: this.translate.instant('menu.select'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  var name = 'Alta de planes';
                  /*if(submenu.name === name){
                    this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ submenu.route.split('/')[1]);
                  }/*else if(submenu.module === 'Reportes' && (this.acceso[0] === 'all' || this.acceso.includes('3')) ){
                    window.open("assets/documentos/informe_OSCUS.pdf","_blank");
                  }*else{*/
                    this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ submenu.route.split('/')[1] +'/'+this.planeacionId);
                  //}
                }else{
                  resolve(this.translate.instant('menu.select'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFound'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
      });
    }else if(submenu.name === 'Alta de planes' && menu.module === 'Administrador'){
      this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+0+'/'+'planeaciones'+'/'+ submenu.route.split('/')[1]);
    }else{
      this.welcomeService.getInstituciones().subscribe(response =>{
        this.planeacion = [];
        for(const inst of response){
          if(this.accesoPlan(inst.id) && (inst.code === this.dataService.company_id || this.dataService.roleReal === 'Administrador')){
            this.planeacion.push({
              id: inst.id,
              nombre: inst.name,
            });
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);

        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }

        if(Planeaciones.length > 0){
          Swal.fire({
            title: this.translate.instant('menu.selectInst'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  this.router.navigateByUrl('/app/'+ submenu.route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ submenu.route.split('/')[1]);
                }else{
                  resolve(this.translate.instant('menu. SelectInst'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFoundInst'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
      });
    }
  }
  public cambioIdioma(){
    Swal.fire({
      title: this.translate.instant('menu.select'),
      input: 'select',
      inputOptions: {
        es: ''+this.translate.instant('menu.esp'),
        en: ''+this.translate.instant('menu.ing')

      },
      inputPlaceholder: this.translate.instant('menu.seleccione'),
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: this.translate.instant('swal.acep'),
      cancelButtonText: this.translate.instant('swal.cerr'),
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if(value){
            resolve('');
            this.cambioLenguaje(value);
          }else{
            resolve(this.translate.instant('menu.idiom'));
          }
        })
      }
    });
  }
  private cambioLenguaje(idioma){
    this.translate.setDefaultLang(idioma);
    sessionStorage.setItem('lenguaje', idioma)
  }

}

