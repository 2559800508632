<div class="w3-row w3-white " style="width: 100%;height: 100%;">
  <!-- Header -->
  <div class="w3-row w3-container-header w3-border w3-border-gray">
    <div class="w3-quarter w3-margin-top-header">
      <table>
        <tr>
          <td>
            <button mat-icon-button class="w3-margin-right-header" style="margin: 4px;"
            (click)="validacionTableros(drawer);"><!--(mouseenter)="validacionTableros(drawer);"-->
              <mat-icon class="w3-margin-left-header" style="cursor: pointer;" [ngStyle]="{'color':'gray'}">menu</mat-icon>
            </button>
          </td>
          <td>
            <button mat-icon-button class="w3-margin-right-header">
              <mat-icon  (click)="home()" class="w3-margin-left-header" style="margin-top: -1.5vh;"><img src="assets/img/logoaleema.png" > </mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="w3-third">
      <div class="MostrarPlan" (click)="enviarA()">
        <span style="cursor: pointer;">{{planeacionSelec}}</span>
      </div>
    </div>
    <div class="w3-rest w3-margin-top-header">
      <div class="correo w3-border-right w3-icon-header">
        <table class="w3-table" style="margin-top: 0.5vh;">
          <tbody>
            <tr>
              <td class="w3-right">
                <a target="_blank" href="https://cooing-diamond-ff5.notion.site/25f34f5cb7654060a7ebc10af0a23594?v=f7fa6433e03e4e9fa016fa090bf64dfa&pvs=4">
                  <mat-icon class="w3-margin-right-header" matTooltip="{{'welcome.ayuda' | translate}}" [ngStyle]="{'color':'gray'}">help_outline</mat-icon>
                </a>
              </td>
              <td class="w3-right" *ngIf="idioma"> <!--idioma-->
                <div class='correo2 w3-letra_header helpUser w3-icon-header' style="margin-top: 0.4vh;" [matMenuTriggerFor]="idioma">{{ this.activeLang }}</div>
                <mat-icon class="w3-margin-right-header helpUser" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="idioma">language</mat-icon>
                <mat-menu class="w3-medium" #idioma="matMenu">
                  <button class="mat-menu-item" mat-menu-item (click)="cambioLenguaje('es')">
                    {{ 'menu.esp' | translate}}
                  </button>
                  <button class="mat-menu-item" mat-menu-item (click)="cambioLenguaje('en')">
                    {{ 'menu.ing' | translate}}
                  </button>
                </mat-menu>
              </td>
              <td class="w3-right">
                <mat-icon class="w3-margin-right-header" [ngStyle]="{'color':'orange'}">face</mat-icon>
              </td>
              <td class="w3-right" style="margin-top: 0.4vh;">
                <span class='w3-letra_header w3-icon-header'>{{dataService.currentUser}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="logout">
        <button mat-icon-button class="w3-margin-right-header" (click)="logout()" style="margin: 4px;">
          <mat-icon [ngStyle]="{'color':'gray'}">exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Header -->  
  <mat-drawer-container class="w3-row">
    <mat-drawer #drawer class="w3-row" mode="over">
      <app-menu [planeacionId]="planeacionId" [planeacionSelec]="planeacionSelec"></app-menu>
    </mat-drawer>
    <!--<mat-drawer #drawerHelp mode="over" position="end" style="width: 50vw;">
      <!--<iframe [src]="urlSafe" height="98%" width="100%" frameborder="0"></iframe>--
      <iframe src="https://coda.io/embed/Qwuuy4ZGGF/_suOGY?viewMode=embedplay" width=900 height=500 style="max-width: 100%;" allow="fullscreen"></iframe>
    </mat-drawer>-->
  
    <div class="w3-row w3-white"  style="width: 100%;height: 100%;">
      <router-outlet></router-outlet>
    </div>
  
  </mat-drawer-container>
</div>
