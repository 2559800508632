import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Perspectivas{
  public id?: number;
  public name?: string;
  public description?: string;
  public planningId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PerspectivasService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postPerspectivas(perspectiv: any): Observable<any>{
    var perspe: Perspectivas;
    if(perspectiv.descripcion !== ''){
      perspe = {
        name: perspectiv.nombre,
        description: perspectiv.descripcion ,
        planningId: Number(perspectiv.planningId),
      }
    }else{
      perspe = {
        name: perspectiv.nombre,
        planningId: Number(perspectiv.planningId),
      }
    }
    const body = JSON.stringify(perspe);
    const apiURL = this.apiURL+"perspectivas";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchPerspectivas(perspectiv: any): Observable<any>{
    var perspe: Perspectivas;
    if(perspectiv.descripcion !== ''){
      perspe = {
        name: perspectiv.nombre,
        description: perspectiv.descripcion ,
        planningId: Number(perspectiv.planningId),
      }
    }else{
      perspe = {
        name: perspectiv.nombre,
        planningId: Number(perspectiv.planningId),
      }
    }
    const body = JSON.stringify(perspe);
    const apiURL = this.apiURL+"perspectivas/"+perspectiv.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public deletePer(per): Observable<any>{
    const apiURL = this.apiURL+"perspectivas/"+per.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }

}
