import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeguimientoRoutingModule } from './seguimiento-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { IndicadoresmetaComponent } from './indicadoresmeta/indicadoresmeta.component';
import { ProgramasComponent } from './programas/programas.component';
import { ProyectosestrategicosComponent } from './proyectosestrategicos/proyectosestrategicos.component';
import { CuadromandoComponent } from './cuadromando/cuadromando.component';
import { GraficaLinealComponent } from '../../components/grafica-lineal/grafica-lineal.component';
import { ActasComponent } from './actas/actas.component';
import { Proyectos2Component } from './proyectos2/proyectos2.component';
import { AvanceProyectosComponent } from './avance-proyectos/avance-proyectos.component';

@NgModule({
  declarations: [
    ObjetivosComponent,
    IndicadoresmetaComponent,
    ProgramasComponent,
    ProyectosestrategicosComponent,
    CuadromandoComponent,
    ActasComponent,
    Proyectos2Component,
    AvanceProyectosComponent, 
  ],
  imports: [
    CommonModule,
    SeguimientoRoutingModule,
    SharedModule
  ]
})
export class SeguimientoModule { }
