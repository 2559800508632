import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Semaforos, Planificacion, Instituciones, Responsables, obtenerError } from '../../../model/datos';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import {AltaplanificacionesService } from '../../../services/formulacion/altaplanificaciones/altaplanificaciones.service';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WelcomeService } from './../../../services/welcome/welcome.service';

@Component({
  selector: 'app-altaplanificaciones',
  templateUrl: './altaplanificaciones.component.html',
  styleUrls: ['./altaplanificaciones.component.css']
})
export class AltaplanificacionesComponent implements OnInit {

  public instituciones: Instituciones[] = [];
  public planeacion: Planificacion[] = [];
  public semaforo: Semaforos[] = []; //guarda las propiedades
  public newsemaforo: Semaforos[] = []; //guarda las propiedades
  public posSem: Semaforos[] = []; //posicion del semaforo
  public responsables: Responsables[] =[];
  public displayedColumns: string[] = ['nombre','fechaini','fechafin','institucion','activa', 'semaforo', 'editar', 'eliminar', 'config'];
  public diasLab = ['Lunes-Viernes', 'Lunes-Sabado', 'Lunes-Domingo',]
  private acceso = [];

  public modal = false; //para la planeación
  public modal2 = false; //para el semáforo
  public modal3 = false; //para el semáforo
  public idplan: number = 0; //id de planificacion
  public idsem: number = 0; //id de semaforo cx a ver si lo ocupo. edit: si lo voy a ocupar yeii
  public planeacin: any; //para edit
  public semafors: any; //para edit
  //form select
  public numcolorSelect: any; //semáforo a elegir "3 colores"
  public operadorSelect: any; //mayor, menor, entre
  public coloresSelect:any; //rojo, amarillo, verde,...
  public institucionSelect:any; //institucion
  public diaslaborablesSelect:any;
  public isActiveSelect:any; //isActive por ser check
  public resSelect:any;
  
  public selecsemEdit = false;
  public borraoSem = false;
  public plansemEdit: any;
  public c3 = false;
  public c4 = false;
  public c5 = false;
  public colorSelect: any;
  
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return true;
  };

  /** Validaciones inputs */

  formNuevo = new FormGroup({
    'inputName': new FormControl('', [Validators.required]),
    'inputDays': new FormControl('', [Validators.required]),
    'inputFechaIni': new FormControl('',[Validators.required]),
    'inputFechaFin': new FormControl('', [Validators.required]),
    'inputIntstitucion': new FormControl('', [Validators.required]),
    'inputResponsble': new FormControl('', [Validators.required]),
    'inputNumColor': new FormControl('', [Validators.required]),
    'inputColor0': new FormControl('', [Validators.required]),
    'inputColor1': new FormControl('', [Validators.required]),
    'inputColor2': new FormControl('', [Validators.required]),
    'inputColor3': new FormControl('', [Validators.required]),
    'inputColor4': new FormControl('', [Validators.required]),
    'inputVal1Sem0': new FormControl({value: 0, disabled: true}, [Validators.required]),
    'inputVal2Sem0': new FormControl('', [Validators.required]),
    'inputVal1Sem1': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem1': new FormControl('', [Validators.required]),
    'inputVal1Sem2': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem2': new FormControl('', [Validators.required]),
    'inputVal1Sem3': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem3': new FormControl('', [Validators.required]),
    'inputVal1Sem4': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem4': new FormControl({value: 100}, [Validators.required]),
  });
  formEdit = new FormGroup({
    'inputName': new FormControl('', [Validators.required]),
    'inputDays': new FormControl('', [Validators.required]),
    'inputFechaIni': new FormControl('',[Validators.required]),
    'inputFechaFin': new FormControl('', [Validators.required]),
    'inputIntstitucion': new FormControl('', [Validators.required]),
    'inputResponsble': new FormControl('', [Validators.required]),
  });
  formSemaforo = new FormGroup({
    'inputNumColor': new FormControl('', [Validators.required]),
    'inputColor0': new FormControl('', [Validators.required]),
    'inputColor1': new FormControl('', [Validators.required]),
    'inputColor2': new FormControl('', [Validators.required]),
    'inputColor3': new FormControl('', [Validators.required]),
    'inputColor4': new FormControl('', [Validators.required]),
    'inputVal1Sem0': new FormControl({value: 0, disabled: true}, [Validators.required]),
    'inputVal2Sem0': new FormControl('', [Validators.required]),
    'inputVal1Sem1': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem1': new FormControl('', [Validators.required]),
    'inputVal1Sem2': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem2': new FormControl('', [Validators.required]),
    'inputVal1Sem3': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem3': new FormControl('', [Validators.required]),
    'inputVal1Sem4': new FormControl({value: '', disabled: true}, [Validators.required]),
    'inputVal2Sem4': new FormControl({value: 100}, [Validators.required]),
  });


  public submitted = false; 
  get fn() { return this.formNuevo.controls; }
  get fe() { return this.formEdit.controls; }
  get fc() { return this.formSemaforo.controls; }

  public planeacionId = 0;
  public planeacionSelec: any = '';
  public url: any;

  constructor(
    private router: Router,
    public dataService: DataService,
    public altaplanificacionesService: AltaplanificacionesService,
    private activateRouter: ActivatedRoute,
    private welcomeService: WelcomeService,
    private excelService: ExcelService,
  ) {
    welcomeService.getAccesos(dataService.currentUser).subscribe(response =>{
      if(response && response.access.length !== 0){
        this.acceso = [];
        for(const acces of response.access){
          this.acceso.push(''+acces);
        }
      }else{
        this.acceso = ['no encontrado'];
      }
      this.obtData();
    });
   }

  ngOnInit(): void { }
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenarSem(array: any){
    array.sort(function (a: any, b: any) {
      if (a.nombre > b.nombre) {
        return 1;
      }
      if (a.nombre < b.nombre) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private accesoPlan(id: any){
    if ( this.acceso[0] === 'all' || this.acceso.includes(id) || this.acceso[0] === 'no encontrado'){
      return true;
    }
    return false;
  }
  public obtData(){
    this.altaplanificacionesService.getInstituciones().subscribe(response=>{
      this.instituciones = [];
      for(const inst of response){
        if(this.accesoPlan(inst.id)){
          this.instituciones.push({
            nombre: inst.name,
            acronimo: inst.acronym,
            id: inst.id,
          });
        }
      }
      this.altaplanificacionesService.getPlanificaciones().subscribe(response =>{
        this.guardarPlan(response);
        this.planeacion = this.ordenamientoPer(this.planeacion);
        this.semaforo = this.ordenarSem(this.semaforo);
      });
    });
    this.altaplanificacionesService.getResponsable().subscribe(response => {
      this.responsables = [];
      for(const are of response){
        for(const res of are.responsibles){
          this.responsables.push({
            nombre: res.name,//res.position,
            id: res.id,
            apellido_paterno: res.first_surname,
            apellido_materno: res.second_surname,
            institucionId: are.institution.id,
          });
        }
      }
      this.responsables = this.ordenamientoPer(this.responsables);
    });
  }
  public guardarPlan(array: any){
    var aux = [], aux2 = [];
    for(const plan of array){
      if( plan.institution !== null && this.accesoPlan(plan.institution.id)){
        aux.push({
          id: plan.id,
          nombre: plan.name,
          fecha_inicio: new Date(plan.initial_date),
          fecha_fin: new Date(plan.end_date),
          institucionId: plan.institution.id,
          isActive: plan.isActive,
          business_days: plan.business_days,
          responsible: plan.responsible.id,
          enableMailReports: plan.enableMailReports,
        });
        for(const sema of plan.signals){
          aux2.push({
            id: sema.id,
            nombre: sema.name,
            valor_00: sema.value_00,
            valor_01: sema.value_01,
            color: sema.color[0],
            planningId: plan.id,
          });
        }
      }
    }
    this.planeacion = [];
    this.semaforo = [];
    this.planeacion = aux;
    this.semaforo = aux2;
  }
  
  public alertas(aler: any, plan?: any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEditados();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'editSem'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.borraoSem){
            this.almacenarDEditSemaforoNew();
          }else{
            this.almacenarDEditSemaforo();
          }
        }
      });
    }else if(aler === 'eliminarPlan'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar la planeación?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.Eliminar(plan)          
        }
      });
    }
  }
  public almacenarD(){
    //datos de la planificación
    var plan = new Planificacion();
    plan.id = this.planeacion.length+1;
    plan.nombre = this.fn['inputName'].value;
    plan.business_days = this.fn['inputDays'].value;
    plan.fecha_inicio = new Date(this.fn['inputFechaIni'].value);
    plan.fecha_fin = new Date(this.fn['inputFechaFin'].value);
    if(this.isActiveSelect === true){
      plan.isActive = true;
    }else if(this.isActiveSelect === undefined){
      plan.isActive = false;
    }
    plan.institucionId = Number(this.fn['inputIntstitucion'].value); //instituto
    plan.responsible = this.fn['inputResponsble'].value;

    this.altaplanificacionesService.postPlan(plan).subscribe(response =>{
      this.altaplanificacionesService.getPlanificaciones().subscribe(response => {
        this.guardarPlan(response);
        this.planeacion = this.ordenamientoPer(this.planeacion);
        this.semaforo = this.ordenarSem(this.semaforo);
        var idpla = this.planeacion[this.planeacion.length-1].id;
        //aquí debe empezar lo de semáforos.
        for(var i = 0; i < this.newsemaforo.length; i++){
          this.newsemaforo[i].valor_00 = Number(this.formNuevo.controls['inputVal1Sem'+i].value);
          if(i === this.newsemaforo.length-1){
            this.newsemaforo[i].valor_01 = Number(this.formNuevo.controls['inputVal2Sem4'].value);
          }else{
            this.newsemaforo[i].valor_01 = Number(this.formNuevo.controls['inputVal2Sem'+i].value);
          }
          this.newsemaforo[i].color = this.formNuevo.controls['inputColor'+i].value;
          this.newsemaforo[i].operador_00 = 'entre';
          this.newsemaforo[i].operador_01 = 'and';
          this.newsemaforo[i].nombre = 'Semaforo '+(i+1);
          this.newsemaforo[i].planningId = idpla;
          this.altaplanificacionesService.postSemaforo(this.newsemaforo[i]).subscribe(response =>{
            this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
              this.guardarPlan(response);
              this.planeacion = this.ordenamientoPer(this.planeacion);
              this.semaforo = this.ordenarSem(this.semaforo);
              this.newsemaforo = [];
            });
          });
        }
        this.cerrarForm();
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  public elegirSem(numcolorSelect1: any){
    this.newsemaforo = [];
    switch(numcolorSelect1){
      case 'tres':
        for(var i = 0; i < 3; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
      case 'cuatro':
        for(var i = 0; i < 4; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
      case 'cinco':
        for(var i = 0; i < 5; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
    }
  }
  public elegirSemEdit(numcolorSelect1: any){
    for(const sem of this.newsemaforo){
      this.altaplanificacionesService.deleteSemaforo(sem).subscribe(response =>{},err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
    this.newsemaforo = [];
    switch(numcolorSelect1){
      case 'tres':
      for(var i = 0; i < 3; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
      case 'cuatro':
        for(var i = 0; i < 4; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
      case 'cinco':
        for(var i = 0; i < 5; i++)
          this.newsemaforo.push(new Semaforos());

        this.newsemaforo[0].valor_00 = 0;
        this.newsemaforo[this.newsemaforo.length-1].valor_01 = 100;
      break;
    }
    for(const sem of this.newsemaforo){
      sem.planningId = this.plansemEdit.id;
    }
    this.borraoSem = true;
  }

  public cambioEdit(){
    if(!this.selecsemEdit){
      Swal.fire({
        title: 'Si se cambia el número de semáforos se borrará la información, ¿Desea continuar?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.selecsemEdit = true;
        }
      });
    }
  }
  public puedeSelec(){
    if(this.selecsemEdit){
      return '';
    }
    return 'w3-disabled';
  }
  public haysem(){
    var semaforos = 0;
    for(const sem of this.semaforo){
      if( sem.planningId === this.plansemEdit){
        semaforos ++;
      }
    }
    if(semaforos === 0){
      return 'w3-disabled'
    }
    return '';
  }
  public setcolor(pos: any, color: any){
    this.newsemaforo[pos].color =  color;
  }
  public intNumero(introdujo: any){
    if(this.modal3){
      if(Number( this.formSemaforo.controls['inputVal2Sem'+introdujo].value ) != 0){
        this.formSemaforo.controls['inputVal1Sem'+(introdujo+1)].setValue(Number(this.formSemaforo.controls['inputVal2Sem'+introdujo].value)+0.1);
      }else{
        this.formSemaforo.controls['inputVal1Sem'+(introdujo+1)].setValue("");
      }
    }else if(this.modal){
      if(Number( this.formNuevo.controls['inputVal2Sem'+introdujo].value ) != 0){
        this.formNuevo.controls['inputVal1Sem'+(introdujo+1)].setValue(Number(this.formNuevo.controls['inputVal2Sem'+introdujo].value)+0.1);
      }else{
        this.formNuevo.controls['inputVal1Sem'+(introdujo+1)].setValue("");
      }
    }

  }
  public intNumeroE(introdujo: any){
    if(Number(document.getElementById('valor2e'+introdujo)["value"]) != 0){
      document.getElementById('valor1e'+(introdujo+1))["value"] = Number(document.getElementById('valor2e'+introdujo)["value"])+0.1;
    }else{
      document.getElementById('valor1e'+(introdujo+1))["value"] = '';
    }

  }
  public existe(idRes: any): boolean{
    if(this.fn["inputIntstitucion"].value === idRes || this.fe["inputIntstitucion"].value === idRes)
      return true;
    return false;
  }
  /* activación y desactivación notificación */
  public activacionNotify(planeacion: any){
    if(planeacion.enableMailReports === null){
      planeacion.enableMailReports = true;
    }else{ 
      planeacion.enableMailReports = !planeacion.enableMailReports;
    }
    var text = planeacion.enableMailReports?'Notificaciones activadas':'Notificaciones desactivadas';
    this.altaplanificacionesService.patchPlanNotificaciones(planeacion).subscribe(response =>{
      this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
        this.guardarPlan(response);
        this.planeacion = this.ordenamientoPer(this.planeacion);
        this.semaforo = this.ordenarSem(this.semaforo);
        Swal.fire({
          title: text,
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    }, error =>{
      this.messageError(error);
    });
  }
  public activacionEdicion(planeacion: any){
    planeacion.isActive = !planeacion.isActive;
    var text = planeacion.isActive?'Edición de todos los periodos':'Edición restringida';
    this.altaplanificacionesService.patchPlanEdicion(planeacion).subscribe(response =>{
      this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
        this.guardarPlan(response);
        this.planeacion = this.ordenamientoPer(this.planeacion);
        this.semaforo = this.ordenarSem(this.semaforo);
        Swal.fire({
          title: text,
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    }, error =>{
      this.messageError(error);
    });
  }
  /* Mensaje de error */
  private messageError(err){
    let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
  }
  /* */
  public almacenarDatosEditados(){
    this.planeacin.nombre = this.fe['inputName'].value;
    this.planeacin.business_days = this.fe['inputDays'].value;
    this.planeacin.fecha_inicio = new Date(this.fe['inputFechaIni'].value);
    this.planeacin.fecha_fin = new Date(this.fe['inputFechaFin'].value);
    if(this.isActiveSelect){
      this.planeacin.isActive = true;
    }else if(this.isActiveSelect === undefined || !this.isActiveSelect){
      this.planeacin.isActive = false;
    }
    this.planeacin.institucionId = Number(this.fe['inputIntstitucion'].value); //instituto
    this.planeacin.responsible = this.fe['inputResponsble'].value;

    this.altaplanificacionesService.patchPlan(this.planeacin).subscribe(response =>{
      this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
        this.guardarPlan(response);
        this.planeacion = this.ordenamientoPer(this.planeacion);
        this.semaforo = this.ordenarSem(this.semaforo);
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });

    this.cerrarForm();
  }
  public almacenarDEditSemaforo(){
    for(var i = 0; i < this.newsemaforo.length; i++){
      this.newsemaforo[i].valor_00 = Number(this.formSemaforo.controls['inputVal1Sem'+i].value);
      if(i === this.newsemaforo.length-1){
        this.newsemaforo[i].valor_01 = Number(this.formSemaforo.controls['inputVal2Sem4'].value);
      }else{
        this.newsemaforo[i].valor_01 = Number(this.formSemaforo.controls['inputVal2Sem'+i].value);
      }
      this.newsemaforo[i].color = this.formSemaforo.controls['inputColor'+i].value;
      this.altaplanificacionesService.patchSemaforo(this.newsemaforo[i]).subscribe(response =>{
        this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
          this.guardarPlan(response);
          this.planeacion = this.ordenamientoPer(this.planeacion);
          this.semaforo = this.ordenarSem(this.semaforo);
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
    Swal.fire({
      title: 'Información actualizada',
      text: '',
      icon: 'success',
      showConfirmButton: false,
      timer: this.dataService.tiempo
    });
    this.selecsemEdit = false;
    this.newsemaforo = [];
    document.getElementById('formSemaforo')!.style.display='none';
  }
  public almacenarDEditSemaforoNew(){
    for(var i = 0; i < this.newsemaforo.length; i++){
      this.newsemaforo[i].valor_00 = Number(this.formSemaforo.controls['inputVal1Sem'+i].value);
      if(i === this.newsemaforo.length-1){
        this.newsemaforo[i].valor_01 = Number(this.formSemaforo.controls['inputVal2Sem4'].value);
      }else{
        this.newsemaforo[i].valor_01 = Number(this.formSemaforo.controls['inputVal2Sem'+i].value);
      }
      this.newsemaforo[i].color = this.formSemaforo.controls['inputColor'+i].value;
      this.newsemaforo[i].operador_00 = 'entre';
      this.newsemaforo[i].operador_01 = 'and';
      this.newsemaforo[i].nombre = 'Semaforo '+(i+1);
      this.newsemaforo[i].planningId = this.plansemEdit;
      this.altaplanificacionesService.postSemaforo(this.newsemaforo[i]).subscribe(response =>{
        this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
          this.guardarPlan(response);
          this.planeacion = this.ordenamientoPer(this.planeacion);
          this.semaforo = this.ordenarSem(this.semaforo);
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
    Swal.fire({
      title: 'Información actualizada',
      text: '',
      icon: 'success',
      showConfirmButton: false,
      timer: this.dataService.tiempo
    });
    this.newsemaforo = [];
    this.borraoSem = false;
    this.selecsemEdit = false;
    document.getElementById('formSemaforo')!.style.display='none';
  }
  public puede_guardar(){
    //datos de la planificación
    let nombre = this.fn['inputName'].value;
    let business_days = this.fn['inputDays'].value;
    let fecha_inicio = this.fn['inputFechaIni'].value;
    let fecha_fin = this.fn['inputFechaFin'].value;
    let institucion = this.fn['inputIntstitucion'].value;
    let responsable = this.fn['inputResponsble'].value;

    var semafor = false, sn = 0;
    for(var i= 0; i < this.newsemaforo.length; i++){
      if(i === this.newsemaforo.length-1){
        if(this.fn['inputColor'+i].value === ''){
          sn++;
        }
      }else{
        if(this.fn['inputVal2Sem'+i].value === '' || this.fn['inputColor'+i].value === ''){
          sn++;
        }
      }
    }
    if(sn===0 && this.fn['inputNumColor'].value !== ''){
      semafor = true;
    }

    if(nombre !== '' && business_days !== '' && fecha_inicio !== '' && fecha_fin !== '' && institucion !== '' && responsable !== '' && semafor){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    //datos de la planificación
    let nombre = this.fe['inputName'].value;
    let business_days = this.fe['inputDays'].value;
    let fecha_inicio = this.fe['inputFechaIni'].value;
    let fecha_fin = this.fe['inputFechaFin'].value;
    let institucion = this.fe['inputIntstitucion'].value;
    let responsable = this.fe['inputResponsble'].value;

    if(nombre !== '' && business_days !== '' && fecha_inicio !== '' && fecha_fin !== '' && institucion !== '' && responsable !== ''){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEditSem(){
    var semafor = false, sn = 0;
    for(var i= 0; i < this.newsemaforo.length; i++){
      if(i === this.newsemaforo.length-1){
        if(this.fc['inputColor'+i].value === ''){
          sn++;
        }
      }else{
        if(this.fc['inputVal2Sem'+i].value === '' || this.fc['inputColor'+i].value === ''){
          sn++;
        }
      }
    }
    if(sn===0 && this.fc['inputNumColor'].value !== ''){
      semafor = true;
    }

    if(semafor){
      return ''
    }
    return 'w3-disabled'
  }
  //obtener nombres de instituciones
  public obtInst(inst: any){
    for(const ins of this.instituciones){
      if(Number(inst.institucionId) === Number(ins.id)){
        return ins.nombre;
      }
    }
    return '';
  }
  public Eliminar(idp:number){
    this.altaplanificacionesService.deletePlan(idp).subscribe(response =>{
      this.altaplanificacionesService.getPlanificaciones().subscribe(response =>{
        this.altaplanificacionesService.getPlanificaciones().subscribe( response => {
          this.guardarPlan(response);
          this.planeacion = this.ordenamientoPer(this.planeacion);
          this.semaforo = this.ordenarSem(this.semaforo);
          Swal.fire({
            title: 'Planeación eliminada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  public escribirmes(ele:any){
    return ele.getDate()+'/'+(ele.getMonth()+1)+'/'+ele.getFullYear();
  }
  public escribirres(resid: any){
    for(const res of this.responsables){
      if(res.id === resid ){
        return res.nombre +' '+ res.apellido_paterno +' '+ res.apellido_materno ;
      }
    }
    return '';
  }

  public abrirFormAgregar(plann:any){
    this.idplan = plann.id;
    document.getElementById('formularionuevo')!.style.display='block';
    this.modal = true;
  }
  public abrirFormEdit(plann:any){
    this.planeacin = plann;
    this.idplan = plann.id;
    this.diaslaborablesSelect = plann.business_days;
    this.resSelect = plann.responsible;

    switch(this.diaslaborablesSelect){
      case 'Lunes-Viernes': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return day !== 0 && day !== 6;
        };
      break;
      case 'Lunes-Sabado': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return day !== 0;
        };
      break;
      case 'Lunes-Domingo': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return true;
        };
      break;
    }

    this.formEdit.controls['inputName'].setValue(this.planeacin.nombre);
    this.formEdit.controls['inputDays'].setValue(this.planeacin.business_days);
    this.formEdit.controls['inputFechaIni'].setValue(this.planeacin.fecha_inicio);
    this.formEdit.controls['inputFechaFin'].setValue(this.planeacin.fecha_fin);
    this.formEdit.controls['inputIntstitucion'].setValue(this.planeacin.institucionId);
    this.formEdit.controls['inputResponsble'].setValue(this.planeacin.responsible);
    
    if(this.planeacin.isActive){
      this.isActiveSelect = true;
    }else{
      this.isActiveSelect = false;
    }

    document.getElementById('formularioedit')!.style.display='block';
  }
  public abrirFormSem(plann:any){ //editar semáforo
    this.newsemaforo = [];
    this.plansemEdit = plann.id;
    for(const sem of this.semaforo){
      if( sem.planningId === plann.id){
        this.newsemaforo.push(sem);
      }
    }
    switch(this.newsemaforo.length){
      case 3: this.formSemaforo.controls['inputNumColor'].setValue('tres'); break; 
      case 4: this.formSemaforo.controls['inputNumColor'].setValue('cuatro'); break;
      case 5: this.formSemaforo.controls['inputNumColor'].setValue('cinco'); break;
    }
    for(var i = 0; i < this.newsemaforo.length; i++){
      this.formSemaforo.controls['inputVal1Sem'+i].setValue(this.newsemaforo[i].valor_00);
      if(i === this.newsemaforo.length-1){
        this.formSemaforo.controls['inputVal2Sem4'].setValue(this.newsemaforo[i].valor_01);
      }else{
        this.formSemaforo.controls['inputVal2Sem'+i].setValue(this.newsemaforo[i].valor_01);
      }
      this.formSemaforo.controls['inputColor'+i].setValue(this.newsemaforo[i].color);
    }
    this.modal3 = true;
    document.getElementById('formSemaforo')!.style.display='block';
  }
  public cerrarForm(){
    this.modal3 = false;
    this.modal2 = false;
    this.modal = false;

    document.getElementById('formularionuevo')!.style.display='none';
    document.getElementById('formularioedit')!.style.display='none';
    document.getElementById('formSemaforo')!.style.display='none';

    this.isActiveSelect = undefined;
    
    this.formNuevo.reset();
    this.formEdit.reset();

    this.newsemaforo = [];

    this.formNuevo.controls['inputVal1Sem0'].setValue('0');
    this.formNuevo.controls['inputVal2Sem4'].setValue('100');

    this.myFilter = (d: Date | null): boolean => {
      const day = (d || new Date()).getDay();
      return true;
    };
  }
  //rutas
  public isActiveperspectivas(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'perspectivas/' + this.idplan);
  }
  public isActivefilosofia(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'filosofiacorporativa/' + this.idplan);
  }
  public isActiveMatrizfloa(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'matrizfoda/' + this.idplan);
  }
  public isActiveObjetivos(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'objetivos/' + this.idplan);
  }
  public isActiveIndicadores(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'indicadores/' + this.idplan);
  }
  public isActiveAlineamiento(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'alineamiento/' + this.idplan);
  }
  public isActiveProyectos(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'proyectosestrategicos/' + this.idplan);
  }
  public isActiveCuadro(plann: any) {
    this.idplan = plann.id;
    this.router.navigateByUrl('/app/'+ 'formulacion/' + plann.id + '/' + plann.nombre + '/'  + 'cmi/' + this.idplan);
  }
  public cambiarEstatus(){
    this.isActiveSelect = !this.isActiveSelect;
  }
  public filtrodias(lab: any){
    switch(lab){
      case 'Lunes-Viernes': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return day !== 0 && day !== 6;
        };
      break;
      case 'Lunes-Sabado': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return day !== 0;
        };
      break;
      case 'Lunes-Domingo': 
        this.myFilter = (d: Date | null): boolean => {
          const day = (d || new Date()).getDay();
          return true;
        };
      break;
    }
  }
  public exportar_excel(){
    const title = 'Formulación: Alta de planes';
    const header = ["Nombre", "Responsable", "Fecha inicial", "Fecha final", "Institución", "Activa"];
    let rows = [];
    let cols = [];
    for(var i = 0; i < this.planeacion.length; i++){
      cols.push(this.planeacion[i].nombre);
      cols.push(this.escribirres(this.planeacion[i].responsible));
      cols.push(this.escribirmes(this.planeacion[i].fecha_inicio));
      cols.push(this.escribirmes(this.planeacion[i].fecha_fin));
      cols.push(this.obtInst(this.planeacion[i]));
      this.planeacion[i].isActive ? cols.push('✓') : cols.push('―');
      rows.push(cols);
      cols = [];
    }
    this.excelService.generateExcelPlanificaciones(title, header,rows, 'Alta_de_planes');
  }
}

