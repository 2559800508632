import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { TokenModel } from 'src/app/model/token-model';
import { environment } from 'src/environments/environment';
import { exclusiones } from 'src/app/model/datos';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private token = sessionStorage.getItem('auth_token');
  public modules: Array<string> = [];
  public currentUser: string = "";
  public company_country: string = "";
  public company_name: string = "";
  public company_id: string = "";
  public company_alias: string = "";
  public role: string = "";
  public roleReal: string = "";
  public currentModule: string = "";
  public currentSubmodule: string = "";
  public name: string = "";
  public last_name: string = "";
  public edition = false;
  public tiempo = 1500;
  public veActas = false;
  public filtradoIndicadores = false;
  public submodules = [
    {
      identifier: '',
      name: '',
      route: '',
      module: ''
    }
  ];

  public fillerNav = [
    {
      identifier: '',
      name: '',
      route: '',
      module: '',
      submodules: this.submodules,

    }
  ];

  constructor() {
  }

  public initializeMenu(): void {
    this.fillerNav = [];
    this.submodules = [];
    if (this.token) {
      const decoded = jwtDecode<TokenModel>(this.token); // Returns with the JwtPayload type
      this.name = decoded.name;
      this.last_name = decoded.last_name;
      this.currentUser = decoded.email;    
      this.company_country = decoded.company_country;
      this.company_name = decoded.company_name;
      this.company_id = decoded.company_id;
      this.company_alias = decoded.company_alias;

      this.role = decoded.role;
      //this.role = 'Rol_Seguimiento_Responsables'

      this.vistaIndicadores(this.role);

      this.role = this.simplifyRole(this.role);
      this.role = this.compleateRole(this.role);

      this.roleReal = decoded.role;

      const existeTab = decoded.modules.filter(mod => mod === 'est-altaactas' || mod === 'est-actas');
      if(existeTab.length !== 0){
        this.veActas = true;
      }

      this.modules = decoded.modules.filter((value:any, index:any, self:any) => {
        return self.indexOf(value) === index;
      });

      if(exclusiones.puedeVer(this.currentUser) && this.roleReal === 'Administrador'){
        this.modules = this.modules.filter(mod => mod !== 'est-matrizderiesgosyoportunidades')
      }
      
      this.fillerNav.push({ identifier: 'Inicio', name: 'Inicio', route: 'app', submodules: [], module: '' });
      this.fillerNav.push({ identifier: 'Inicio', name: 'Inicio', route: 'inicio', submodules: [], module: '' });

      this.modules.forEach(module => {
        const navModule = environment.modules.find(result => result.identifier === module);
        // el filtro es para evitar que se repitan módulos cuyo nombre se repite en los submódulos (ej. cartera)
        if (navModule && this.fillerNav.filter(result => result.identifier === module).length === 0) {
          this.fillerNav.push(navModule);
        }else {
          // Submodulos
          const navSubmodules = environment.submodules.filter(result => result.identifier === module);
          // el filtro es para evitar que se repitan módulos cuyo nombre se repite en los submódulos (ej. cartera)
          if (navSubmodules) {
            this.submodules = this.submodules.concat(navSubmodules);
          }
        }
      });

      var aux = [];
      environment.submodules.forEach( subm => {
        const navSubmodules = this.submodules.filter(result => result.identifier === subm.identifier && result.name === subm.name);
        if(navSubmodules){
          aux = aux.concat(navSubmodules);
        }
      });
      this.submodules = aux;
      /** Respeta el orden del environment */
      let ele:any [] = []
      this.fillerNav.forEach(module => {
        
        this.submodules.forEach(result => {          
          if (result.module == module.module ){
            ele.push({identifier: result.identifier, name: result.name, route: result.route, module: result.module});
          }
        });
        module.submodules = ele;
        ele = [];
      });
    }
  }
  private simplifyRole(role: string){
    let rolSep = role.split('_');
    if(rolSep.length > 2){
      rolSep.pop();
      return this.simplifyRole(rolSep.join('_'));
    }
    return rolSep.join('_');
  }
  private compleateRole(role: string){
    if(role === 'Administrador'){
      role = 'Rol_'+role;
    }
    return role;
  }
  private vistaIndicadores(role: string){
    const sep = role.split('_');
    if(sep.includes('Responsables') && sep.includes('Seguimiento')){
      this.filtradoIndicadores = true;
    }else{
      this.filtradoIndicadores = false;
    }
  }
}

