import html2canvas from 'html2canvas';
export class IMG{
  static exportimagenes(grafica,name){
    var cadena = "Some description Entidad ";
    setTimeout(() => {
      const chart = document.getElementById(grafica);
      html2canvas(chart, {
          scale: 2,
          backgroundColor: 'white',
          logging: false,
          onclone: (document) => {
          }
      }).then((canvas) => {
        const chartData = canvas.toDataURL('image/PNG');
        var a = document.createElement('a');
        a.href = chartData;
        a.download = name+'.png';
        a.click();
      });
    }, 100);
  }
}