import { Component, OnInit, HostListener } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { DataService } from './../../services/data-service/data.service';
import Swal from 'sweetalert2';
import { Planificacion } from '../../model/datos';
import { IndicadoresService } from '../../services/formulacion/indicadores/indicadores.service';
import { TranslateService } from '@ngx-translate/core';
import { WelcomeService } from './../../services/welcome/welcome.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import $ from 'jquery'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  showFiller = false;
  public planeacion: Planificacion[] = [];
  private acceso = [];
  public planeacionId = 0;
  public planeacionSelec: any = '';
  public url: any;
  private time;
  public limiteTiempo = 60;
  public urlSafe: SafeResourceUrl;
  public urlSend = 'https://coda.io/embed/Qwuuy4ZGGF/_sutnI?viewMode=embedplay'; //'https://cooing-diamond-ff5.notion.site/25f34f5cb7654060a7ebc10af0a23594?v=f7fa6433e03e4e9fa016fa090bf64dfa&pvs=4';
  public urlEmbed = 'https://v2-embednotion.com/25f34f5cb7654060a7ebc10af0a23594?v=f7fa6433e03e4e9fa016fa090bf64dfa&pvs=4';
  private activacionLogOut = false;
  private tiempoLogOut = 5;
  public idioma = false;
  private sideNavActive = 'close';

  public activeLang = 'es';

  constructor(
    private router: Router,
    public dataService: DataService,
    private indicadoresService: IndicadoresService,
    private activateRouter: ActivatedRoute,
    private translate: TranslateService,
    private welcomeService: WelcomeService,
    public sanitizer: DomSanitizer
    ) {
    this.activeLang = sessionStorage.getItem('lenguaje')
    this.translate.setDefaultLang(this.activeLang);
    if(dataService.company_alias === 'YAZAKI' || dataService.roleReal === 'Administrador'){
      this.idioma = true;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    welcomeService.getAccesos(dataService.currentUser).subscribe(response =>{
      if(response && response.access.length !== 0){
        this.acceso = [];
        for(const acces of response.access){
          this.acceso.push(''+acces);
        }
      }else{
        this.acceso = ['no encontrado'];
      }
      this.url = this.activateRouter.snapshot['url'];
      var parametros = this.activateRouter.snapshot['params'];
      if(this.url.length !== 1){
        this.planeacionId = parametros['idplan'];
        this.planeacionSelec = parametros['planNom'];
      }
    });
    this.welcomeService.getInstituciones().subscribe(response =>{
      for(const inst of response){
        if(dataService.company_alias === inst.acronym){
          this.activacionLogOut = inst.settings[0]['name']==='false'?false:true;
          this.tiempoLogOut = Number(inst.settings[0]['value']);
          this.limiteTiempo = this.limiteTiempo*this.tiempoLogOut;
          this.resetTime();
        }
      }
    });
  }
  
  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlSend);
    //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlEmbed);
  }

  private resetTime(){
    if(this.activacionLogOut){
      var interval, mouseMove;

      var time = this.limiteTiempo, tiempo = this.dataService.tiempo;
      $(document).mousemove(function(){
        mouseMove = new Date();
        inactividad(function(){
          Swal.fire({
            title: this.translate.instant('welcome.expiredSecion'),
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: tiempo,
            willClose: () => {
              logOut()
            }
          });
        }, time);
      });

      $(document).keyup(function(){
        mouseMove = new Date();
        inactividad(function(){
          Swal.fire({
            title: this.translate.instant('welcome.expiredSecion'),
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: tiempo,
            willClose: () => {
              logOut()
            }
          });
        }, time);
      });

      var inactividad = function(callback, seconds){
        clearInterval(interval);
        interval = setInterval(function(){
          var now = new Date();
          var diff = (now.getTime()-mouseMove.getTime())/1000;
          if(diff >= seconds){
            clearInterval(interval);
            callback();          
          }
        }, 200);
      }
    }
  }

  public home(): void{
    this.router.navigateByUrl(`/app`);
  }
  public logout(): void {
    sessionStorage.clear();
    location.assign(`/app`);
  }

  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoInd(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objetiveId) > Number(b.objetiveId)) {
        return 1;
      }
      if (Number(a.objetiveId) < Number(b.objetiveId)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }

  private accesoPlan(id: any){
    if ( this.acceso[0] === 'all' || this.acceso.includes(id) || this.acceso[0] === 'no encontrado'){
      return true;
    }
    return false;
  }
  /** Método de envio de tableros */
  public async enviarA(){
    if(this.url[1].path !== 'administrador'){
      this.indicadoresService.getPlanificaciones().subscribe(response => {
        this.planeacion = [];
        for(const plan of response){
          if(this.accesoPlan(plan.institution.id)){
            this.planeacion.push({
              id: plan.id,
              nombre: plan.name,
              fecha_inicio: new Date(plan.initial_date),
              fecha_fin: new Date(plan.end_date),
              institucionId: plan.institution.id,
              isActive: plan.isActive
            });
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);
  
        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }
        if(Planeaciones.length > 0){
          Swal.fire({
            title: this.translate.instant('menu.select'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  //@ts-ignore
                  if(this.activateRouter.snapshot._routerState.url.split('/').length !== 2 && (this.activateRouter.snapshot._routerState.url.split('/')[2] === 'seguimiento' || this.activateRouter.snapshot._routerState.url.split('/')[2] === 'formulacion' || this.activateRouter.snapshot._routerState.url.split('/')[2] === 'reportes')){
                    //@ts-ignore
                    this.router.navigateByUrl(this.activateRouter.snapshot._routerState.url.split('/')[1]+'/'+this.activateRouter.snapshot._routerState.url.split('/')[2]+'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+this.activateRouter.snapshot._routerState.url.split('/')[5]+'/'+this.planeacionId);
                  }else{
                    //@ts-ignore
                    this.router.navigateByUrl(this.activateRouter.snapshot._routerState.url.split('/')[1]+'/'+this.planeacionId+'/'+this.planeacionSelec);
                  }
                 }else{
                  resolve(this.translate.instant('menu.select'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFound'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
  
      });
    }else if(this.url[1].path === 'administrador' && this.url[2].path !== '0'){
      this.welcomeService.getInstituciones().subscribe(response =>{
        this.planeacion = [];
        for(const inst of response){
          if(this.accesoPlan(inst.id) && (inst.code === this.dataService.company_id || this.dataService.roleReal === 'Administrador')){
            this.planeacion.push({
              id: inst.id,
              nombre: inst.name,
            });
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);

        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }

        if(Planeaciones.length > 0){
          Swal.fire({
            title: this.translate.instant('menu.selectInst'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  this.router.navigateByUrl('/app/'+ this.activateRouter.snapshot['_routerState'].url.split('/')[2] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ this.activateRouter.snapshot['_routerState'].url.split('/')[5] );
                }else{
                  resolve(this.translate.instant('menu.selectInst'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFoundInst'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
      });
    }
  }
  /** decición de que tablero es el que se evalua o si no es necesario evaluarlo */
  public validacionTableros(drawer: any){
    const tablero = this.router.url.split('/')[this.router.url.split('/').length-2];
    const modulo = this.router.url.split('/')[2];
    if(modulo === 'formulacion'){// && this.sideNavActive === 'close'
      switch(tablero){
        case 'objetivos':
          this.validarObj(drawer);
        break;
        case 'indicadores':
          this.validarInd(drawer);
        break;
        case 'proyectosestrategicos':
          this.validarPro(drawer);
        break;
        default:
          drawer.toggle().then((sidenavIsOpen) => {
            this.sideNavActive = sidenavIsOpen; 
          });;
        break;
      }
    }else{
      drawer.toggle().then((sidenavIsOpen) => {
        this.sideNavActive = sidenavIsOpen; 
      });;
    }
  }
  /** Validación de los tableros */
  private async validarObj(drawer){
    this.validacionProgres();
    const idPlan = this.router.url.split('/')[this.router.url.split('/').length-1];
    this.welcomeService.getPlanificaciones().subscribe(response =>{
      if( response ){
        const perspecId = this.obtenerPerspectivas(response, idPlan);
        this.welcomeService.getObjetivos().subscribe(response => {
          if( response ){
            const obj = this.obtenerObjetivos(response, perspecId);
            var pond = 0, objsnP = '';
            for(const ob of obj){
              if(ob.weighing !== 0){
                pond = Number( ( pond + ob.weighing ).toFixed(2) );
              }else{
                objsnP += this.formatearTexto(ob.name);
              }
            }
            if(pond < 100 || pond > 100 || objsnP.length !== 0){
              Swal.close();
              var tit = '';
              if(objsnP){
                tit = this.translate.instant('swal.objEv');
              }else if( pond > 100 || pond < 100){
                tit = this.translate.instant('swal.noPond')
                objsnP += this.formatearTexto(this.translate.instant('swal.ponAc')+''+pond+' %');
              }
              this.finalizacionValidacion(tit, objsnP, drawer);
            }else{
              Swal.close();
              this.finalizacionCorrecta(drawer);
            }
          }
        });
      }
    });
  }
  private async validarInd(drawer){
    this.validacionProgres();
    const idPlan = this.router.url.split('/')[this.router.url.split('/').length-1];
    this.welcomeService.getPlanificaciones().subscribe(response =>{
      if( response ){
        const perspecId = this.obtenerPerspectivas(response, idPlan);
        this.welcomeService.getObjetivos().subscribe(response => {
          if( response ){
            const objetivos = this.obtenerObjetivos(response, perspecId);
            this.welcomeService.getIndicadores().subscribe(response =>{
              if(response){
                const indicadores = this.obtenerIndicadores(response, objetivos);
                var ponTot = 0, noPond = '', indic = 0;
                for(const obj of objetivos){
                  for(const ind of indicadores){
                    if(ind.objetiveId === obj.id){
                      ponTot = Number( ( ponTot + Number(ind.ponderacion) ).toFixed(2) );
                      indic++;
                    }
                  }
                  if(indic != 0 && (ponTot<100 || ponTot >100)){
                    noPond += this.formatearTexto(obj.name, ponTot);
                  }
                  ponTot = 0;
                  indic = 0;
                }

                Swal.close();
                if(noPond != ''){
                  let title = this.translate.instant('swal.indNo');
                  this.finalizacionValidacion(title, noPond, drawer);
                }else{
                  Swal.close();
                  this.finalizacionCorrecta(drawer);
                }
              }
            });
          }
        });
      }
    });
    
  }
  private async validarPro(drawer){
    this.validacionProgres();
    const idPlan = this.router.url.split('/')[this.router.url.split('/').length-1];
    this.welcomeService.getPlanificaciones().subscribe(response =>{
      if(response){
        const perspecId = this.obtenerPerspectivas(response, idPlan);
        this.welcomeService.getObjetivos().subscribe(response => {
          if( response ){
            const objetivos = this.obtenerObjetivos(response, perspecId);
            this.welcomeService.getProyectos().subscribe(response =>{
              if(response){
                const proyectos = this.obtenerProyectos(response, objetivos);
                var noPond = '', proyec = 0, pondT = 0, pondD = 0;
                let ponTot = 0;
                const anio = new Date().getFullYear();
                for(const obj of objetivos){
                  for(const pro of proyectos){
                    if (Number(pro.objetiveId) === Number(obj.id) && this.existeAnioValidacion(pro, anio)){
                      pondD++;
                      for(const pon of pro.ponderacion){
                        if(pon.period === anio+'' && pon.weighing !== 0){
                          ponTot = Number( ( ponTot + pon.weighing ).toFixed(2) );
                          proyec++;
                          pondT++;
                        }
                      }
                    }
                  }
                  if(pondD !== pondT){
                    noPond += this.formatearTexto(obj.name,undefined,this.translate.instant('swal.proSi'));
                  }else if( (proyec != 0 && ponTot !== 100)){
                    noPond += this.formatearTexto(obj.name, ponTot);
                  }
                  pondD = 0;
                  pondT = 0;
                  ponTot = 0;
                  proyec = 0;
                }
                if(noPond != ''){
                  let title = this.translate.instant('swal.objNo');
                  this.finalizacionValidacion(title, noPond, drawer);
                }else{
                  Swal.close();
                  this.finalizacionCorrecta(drawer);
                }
              }
            });
          }
        });
      }
    });
  }
  private existeAnioValidacion(pro: any, anio){
    const fechas = this.obtenerFechasPro(pro);
    if(fechas[1] !== undefined && fechas[0] !== undefined){
      var fecha_ini = fechas[0].getFullYear(), fecha_fin = fechas[1].getFullYear();
      if((Number(anio) === fecha_ini || Number(anio) === fecha_fin) ){
        return true;
      }
      return false;
    }else{
      return false;
    }
  }
  private obtenerFechasPro(pro){
    var fecha_fin = undefined;
    var fecha_ini = undefined;
    for(const act of pro.actividades){
      if(fecha_ini === undefined){
        fecha_ini = new Date(act.initial_date);
      }else{
        if(fecha_ini > new Date(act.initial_date)){
          fecha_ini = new Date(act.initial_date);
        }
      }
      if(fecha_fin === undefined){
        fecha_fin = new Date(act.end_date);
      }else{
        if(fecha_fin < new Date(act.end_date)){
          fecha_fin = new Date(act.end_date);
        }
      }
    }
    return [fecha_ini, fecha_fin]
  }
  /** Formateo de datos */
  private formatearTexto(texto: string, pond?: number, texto2?: string){
    var linea = '';
    if(pond !== undefined){
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+'</span><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt"> - </span> <span>'+ this.translate.instant('swal.ponTot') +pond+"%</span></div>"; 
    }else if(texto2 !== undefined){
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+'</span><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt"> - </span> <span>'+texto2+"</span></div>"; 
    }else{
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+"</span></div>"
    }
    return linea;
  }
  /** swals que permiten la interacción */
  private validacionProgres(){
    Swal.fire({
      title: this.translate.instant('swal.eval'),
      html: this.translate.instant('swal.espe'),
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });
  }
  private finalizacionValidacion(title, texto, drawer){
    texto += '<br/><div style="text-aling: center;font-weight: 500;">'+this.translate.instant('swal.cont')+'</div>';
    Swal.fire({
      title: ''+title,
      html: ""+texto,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: this.translate.instant('swal.yes'),
      cancelButtonText: this.translate.instant('swal.no')
    }).then((result) => {
      if (result.isConfirmed) {
        drawer.toggle().then((sidenavIsOpen) => {
          this.sideNavActive = sidenavIsOpen; 
        });;
      }
    });
  }
  private finalizacionCorrecta(drawer){
    Swal.fire({
      title: this.translate.instant('welcome.ponder'),
      text: '',
      icon: 'success',
      showConfirmButton: false,
      timer: this.dataService.tiempo,
      didClose: () =>{
        drawer.toggle().then((sidenavIsOpen) => {
          this.sideNavActive = sidenavIsOpen; 
        });
      }
    });
  }
  /** obtención de arrays necesario */
  private obtenerPerspectivas(response, idPlan){
    var perspecId = [];
    for(const plan of response){
      if(plan.id === idPlan){
        for(const per of plan.perspectives){
          perspecId.push(per.id)
        }
        break;
      }
    }
    return perspecId;
  }
  private obtenerObjetivos(response, perspecId){
    var obj = [];
    for(const per of perspecId){
      let ob = response.filter( objec => Number(objec.perspective.id) === Number(per));
      if( ob ){
        obj = obj.concat(ob);
      }
    }
    return obj;
  }
  private obtenerIndicadores(response, objetivos){
    var aux = [], aux2 = [], indicadores = [];
    for(const ind of response){
      for(const obj of objetivos){
        if(obj.id === ind.objective.id){
          aux.push({
            id: ind.id,
            ponderacion: ind.weighing,
            nombre: ind.name,
            objetiveId: ind.objective.id,
          });
        }
      }
    }
    indicadores = aux;
    indicadores = this.ordenamientoPer(indicadores);
    indicadores = this.ordenamientoInd(indicadores);
    return indicadores;
  }
  private obtenerProyectos(response, objetivos){
    var aux = [], proyectos = [];
    for(const pro of response){
      for( const ob of objetivos){
        if(pro.objectives.length !== 0 && pro.objectives[0].id === ob.id){
          var objectiveid = pro.objectives[0].id;
          /*for(const objid of pro.objectives){
            objectiveid.push(objid.id)
          }*/

          var estrategiaId: any = [];
          for(const estId of pro.strategies){
            estrategiaId.push(estId.id)
          }
          aux.push({
            nombre: pro.name,
            ponderacion: pro.historicWeighing,
            objetiveId: objectiveid, 
            programasId: estrategiaId,
            responsableId: pro.responsible.id,
            id: pro.id,
            descripcion: pro.description,
            actividades: pro.activities,
          });
          break;
        }
      }
    }
    return aux;
  }
  /* Cambio de idioma */
  public cambioLenguaje(idioma){
    this.translate.setDefaultLang(idioma);
    sessionStorage.setItem('lenguaje', idioma);
    this.activeLang = idioma;
  }
  /* ayuda de ususario */
  public expancionHelp(drawer){
    drawer.toggle();
  }
}
function logOut(): void {
  sessionStorage.clear();
  location.assign(`/app`);
}
