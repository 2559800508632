import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Metas{
  public indicatorId?: Number;
  public period?: Date;
  public value?: Number;
  public real?: Number;
}

@Injectable({
  providedIn: 'root'
})
export class IndicadoresmetaService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlaneacion(): Observable< any >{
    const apiURL = this.apiURL+"planificacion/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAreas(): Observable< any >{
    const apiURL = this.apiURL+"area/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjectives(): Observable< any >{
    const apiURL = this.apiURL+"objetivos/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getIndicadores(): Observable< any >{
    const apiURL = this.apiURL+"indicador/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getMetas(): Observable< any >{
    const apiURL = this.apiURL+"meta/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

  public patchMetasNull(meta: any): Observable<any>{
    const met: Metas = {
      real: meta.real,
    }
    const body = JSON.stringify(met);
    const apiURL = this.apiURL+"meta/"+Number(meta.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchMetas(meta: any): Observable<any>{
    const met: Metas = {
      real: meta.real,
    }
    const body = JSON.stringify(met);
    const apiURL = this.apiURL+"meta/"+Number(meta.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
