import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class actividades{
  public name?: string;
  public initial_date?: Date;
  public end_date?: Date;
  public minuteId?: number;
  public responsibleId?: number;
}

export class ponderacion{
  projectWeighing?: number;
}
export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 

export class actas{
  name?: string;
  validatorId?: number;
  description?: string;
  attachment?: File;
  planId?:  number;
}
export class envioActa{
  responsibleIds?: Number[];
}

export class comment{
  additionalId?: Number
  comment?: string;
  created_at?: String;
}
@Injectable({
  providedIn: 'root'
})
export class AltaactasService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  constructor( private http: HttpClient ) { }

  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsable(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  /* Actas */
  public getActas(): Observable<any>{
    const apiURL = this.apiURL+"actas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postActas(acta): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('description', acta.descripcion);
    formData.append('validatorId', acta.responsableId);
    formData.append('name', acta.nombre);
    formData.append('planId', acta.planId);
    if(acta.archivo !== undefined){
      formData.append('file', acta.archivo);
    }

    const apiURL = this.apiURL+"actas";
    return this.http.post(`${apiURL}`, formData, {headers: this.headersAr});
  }
  public patchActas(acta): Observable<any>{
    var actaEdit: actas = {
      name: acta.nombre,
      validatorId: acta.responsableId,
      description: acta.descripcion,
      planId: acta.planId,
    };

    const body = JSON.stringify(actaEdit);
    const apiURL = this.apiURL+"actas/"+acta.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchActasfile(acta): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('name', acta.nombre);
    formData.append('file', acta.archivo);
    const apiURL = this.apiURL+"actas/"+acta.id;
    return this.http.patch(`${apiURL}`, formData, {headers: this.headersAr});
  }
  public deleteActas(acta: any): Observable<any>{
    const apiURL = this.apiURL+"actas/"+acta.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public envioActas(responsables: any, actaId: number): Observable<any>{
    var enviar: envioActa = {
      responsibleIds: responsables,
    };

    const body = JSON.stringify(enviar);
    const apiURL = this.apiURL+"actas/"+actaId+"/enviar";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  /* Actividades Actas */
  public getActividadesActas(): Observable<any>{
    const apiURL = this.apiURL+"acta/actividades";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postActividades(act: any): Observable<any>{
    const acti: actividades = {
      minuteId: Number(act.actaId),
      initial_date: act.fechaIni.toISOString(),
      end_date: act.fechaFin.toISOString(),
      name: act.nombre,
      responsibleId: Number(act.responsableId),
    }

    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"acta/actividades";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchActividades(act: any): Observable<any>{
    const acti: actividades = {
      initial_date: act.fechaIni.toISOString(),
      end_date: act.fechaFin.toISOString(),
      name: act.nombre,
      responsibleId: Number(act.responsableId),
    }

    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"acta/actividades/"+act.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteActividades(actividad: any): Observable<any>{
    const apiURL = this.apiURL+"acta/actividades/"+actividad.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteHistory(his: any): Observable<any>{
    const apiURL = this.apiURL+"acta/actividad/historic/"+his.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  //Adicionales
  public postAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      activityId: Number(adicional.activityId),
      name: adicional.name,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"acta/actividad/adicionales";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchAdicionales(adicional: any, id: any): Observable<any>{
    const ad: Adicionales = {
      amount: Number(adicional.amount),
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"acta/actividad/adicionales/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchAdicionalesN(adicional: any, id: any): Observable<any>{
    const ad: Adicionales = {
      note: adicional.note,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"acta/actividad/adicionales/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchAdicionalesNombre(adicional: any): Observable<any>{
    const ad: Adicionales = {
      name: adicional.name,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"acta/actividad/adicionales/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteAdicional(adicional: any): Observable<any>{
    const apiURL = this.apiURL+"acta/actividad/adicionales/"+Number(adicional.id);
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }

  /* Adicionales comentarios */
  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      additionalId: Number(idAct),
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"acta/actividad/adicional/comentarios";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"acta/actividad/adicional/comentarios/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
