import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { PermisosSubmodulosGuard } from 'src/app/guards/permisos-submodulos/permisos-submodulos.guard';
import { IndicadoresmetaComponent } from './indicadoresmeta/indicadoresmeta.component';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { ProgramasComponent } from './programas/programas.component';
import { ProyectosestrategicosComponent } from './proyectosestrategicos/proyectosestrategicos.component';
import { CuadromandoComponent } from './cuadromando/cuadromando.component';
import { ActasComponent } from './actas/actas.component'
import { Proyectos2Component } from './proyectos2/proyectos2.component';
import { AvanceProyectosComponent } from './avance-proyectos/avance-proyectos.component';

const routes: Routes = [
  {
    path: 'app/seguimiento/:idplan/:planNom',
    component: WelcomeComponent,
    children: [
      { path: 'cuadromando/:planeacion', component: CuadromandoComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'objetivos/:planeacion', component: ObjetivosComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'indicadoresMeta/:planeacion', component: IndicadoresmetaComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'programas/:planeacion', component: ProgramasComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'proyectosest/:planeacion', component: ProyectosestrategicosComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'proyectos2/:planeacion', component: Proyectos2Component,canActivate: [PermisosSubmodulosGuard]},
      { path: 'avanceProyectos/:planeacion', component: AvanceProyectosComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'actas/:planeacion', component: ActasComponent,canActivate: [PermisosSubmodulosGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeguimientoRoutingModule { }