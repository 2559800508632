import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjetivosService } from './../../../services/seguimiento/objetivos/objetivos.service';
import { Responsables, Meses, obtenerError, obtenerTamMax } from '../../../model/datos';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-objetivos',
  templateUrl: './objetivos.component.html',
  styleUrls: ['./objetivos.component.css']
})

export class ObjetivosComponent implements OnInit {

  private idPlanificacion: number = 0;
  public Periodo = '';
  // Variables de filtros
  public aniosDisponibles: any = [];
  public perspectives: any = [];
  public objectivos: any = [];
  public indicadores: any = [];
  public indicadoresMostrar: any = [];
  private semaforo: any = [];
  public responsables: Responsables[] = [];
  public responsables2: any = [] // MatTableDataSource<Responsables> = [];
  public todasPer = true;
  public selAll = true;
  
  public tarjetaVis = false;
  private fechaAct = new Date();
  private edicion: boolean;
  
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  /** lectura de archivos */

  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = [];
  message = '';
  imageName = "";


  /** click izquierdo */

  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition =  {x: '0', y: '0'};
  private adDelete : any;
  /** dato mayor */

  private datoMax = 0;
  public porcentajeAvance = 0;
  public verCodigo = false;

  constructor(
    public dataService: DataService,
    private objetivosService: ObjetivosService,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) {
    var param = this.rutaActiva.snapshot.params    
    this.idPlanificacion = Number(param['planeacion']);
    this.obtenerDatos();
  }

  ngOnInit(): void {
  }

  private obtenerDatos(){
    this.objetivosService.getPlanificaciones().subscribe( response =>{
      this.obtenerFiltros(response);
      this.objetivosService.getObjetivos().subscribe( response =>{
        this.obtenerObjetivos(response);
        this.objetivosService.getIndicadores().subscribe( response =>{
          this.obtenerIndicadores(response);
          this.objetivosService.getAdicionales().subscribe(response =>{
            this.obtenerAdicionales(response);
          });
        });
      });
    });
    //this.obtenertit();
  }
  private obtenerFiltros(planeacion: any){
    for(const plan of planeacion){
      if(Number(plan.id) === this.idPlanificacion){
        if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
          //this.verCodigo = true;
        }
        this.edicion = plan.isActive;
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        this.Periodo = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1].periodo;
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            check: false,
            id: per.id,
            avance: 0,
            pondObj: 0 
          })
        }

        const sema = this.ordenamientoSem(plan.signals);
        for(const sem of sema){
          this.semaforo.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }

        this.objetivosService.getAreas().subscribe(response => {
          this.responsables = [];
          for(const res of response){
            if(res.institution.id === plan.institution.id){
              if(this.dataService.filtradoIndicadores){
                if(res.responsibles[0].email === this.dataService.currentUser){
                  this.responsables.push({
                    id: res.id,
                    nombre: res.name,
                    check: true,
                  });
                }
              }else{
                this.responsables.push({
                  id: res.id,
                  nombre: res.name,
                  check: true,
                });
              }
            }
          }
          this.responsables = this.ordenamientoId(this.responsables);
          this.responsables2 = new MatTableDataSource(this.responsables);
        });
      }
    }
    this.perspectives = this.ordenamientoId(this.perspectives);
  }
  private obtenerObjetivos(array: any){
    var aux = [];
    for(const obj of array){
      for(const per of this.perspectives){
        if(obj.perspective.id === per.id){
          aux.push({
            nombre: obj.name,
            id: obj.id,
            idPer: obj.perspective.id,
            peso: obj.weighing,
          });
          per.pondObj += obj.weighing;
          break;
        }
      }
    }
    this.objectivos = [];
    this.objectivos = aux;
    this.objectivos = this.ordenamientoId(this.objectivos);
  }
  private obtenerIndicadores(array: any){
    var aux = [];
    for(const ind of array){
      for(const obj of this.objectivos){
        if(ind.objective.id === obj.id){
          var metas = [];
          for(const met of ind.goals){
            const date = this.obtFech(met.period);
            metas.push({
              id: met.id,
              period: date,
              value: met. value,
              real: met.real,
            });
          }
          aux.push({
            nombre: ind.name,
            additions: [],
            tendencia: ind.tendency,
            id: ind.id,
            ponderacion: ind.weighing,
            metas: this.ordenamientoMetas(metas),
            objectiveId: ind.objective,
            fechaIni: ind.initial_date,
            fechaFin: ind.end_date,
            areaId: ind.area.id,
          });
          break;
        }
      }
    }
    this.indicadores = [];
    this.indicadores = aux;
    this.indicadores = this.ordenamientoId(this.indicadores);
    this.indicadores = this.ordenamientoObjId(this.indicadores);
    this.indicadores = this.ordenarPorPerspectiva(this.indicadores);
  }
  private obtenerAdicionales(array: any){ 
    for(const ind of this.indicadores){
      var aux = [];
      for(const ad of array){
        if( ad.indicator !== null && ad.indicator.id === ind.id){
          var fil = [];  
          if(ad.files && ad.files.length !== 0){
            for(const f of ad.files){
              const date = this.obtFech(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      ind.additions = aux;
    }
    
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    if(this.indicadoresMostrar.length === 0){
      Swal.fire({
        title: 'No existen metas para este periodo',
        text: '',
        icon: 'info',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Aceptar',
      });
    }
    this.obtenerPesoPers();
  }
  private ordenarPorPerspectiva(array: any){
    var aux = [];
    for(const per of this.perspectives){
      for(var i = 0; i < array.length; i++){
        if( per.id === this.obtenerIdPer(array[i].objectiveId.id) ){
          aux.push(array[i]);
        }
      }
    }
    return aux;
  }
  private obtenerIdPer(idObj){
    for(const obj of this.objectivos){
      if(obj.id === idObj){
        return obj.idPer;
      }
    }
  }
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  private obtenerPesoPers(){
    this.porcentajeAvance = this.porcentajeObjecAcum();// this.porcentajeObjec();
    for(const per of this.perspectives){
      per.avance = this.porcentajePersAcum(per.id);// this.porcentajePers(per.name);
    }
  }
  // Cambios en filtros
  public cambioAnio(periodoSel: any){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === periodoSel? mes.check = true: mes.check = false;
        mes.periodo === periodoSel? this.Periodo = mes.periodo: '';
      }
    }

    this.indicadoresMostrar = [];
    for(const pro of this.indicadores){
      if(this.Mostrar(pro)){
        this.indicadoresMostrar.push(pro);
      }
    }
    if(this.indicadoresMostrar.length === 0){
      Swal.fire({
        title: 'No existen metas para este periodo',
        text: '',
        icon: 'info',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Aceptar',
      });
    }
    this.obtenerPesoPers();
  }
  public cambioPer(perSelec: any){
    for(const per of this.perspectives){
      if(per.id === perSelec.id){
        per.check = true;
      }
      else{
        per.check = false;
      }
    }
    this.todasPer = false;
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.obtenerPesoPers();
  }
  public selecAllPer(){
    this.todasPer = !this.todasPer;
    for(const per of this.perspectives){
      per.check = false;
    }
    if(this.todasPer){
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
      this.obtenerPesoPers();
    }
  }
  public cambioLider(lidSelec: any){
    var sele = 0;
    for(const res of this.responsables){
      if(res.id === lidSelec.id){
        res.check = !res.check;
      }
      if(res.check){
        sele++;
      }
    }
    if(sele != this.responsables.length)
      this.selAll = false;
    else
      this.selAll = true;
    
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.obtenerPesoPers();
  }
  public selectAll(){
    this.responsables.forEach(res =>{ this.selAll? res.check = false: res.check = true });
    this.selAll = !this.selAll;
    
    if(this.selAll){
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
    }
    this.obtenerPesoPers();
  }
  // Condicionales de filtros
  public numPerSel(){
    var num = 0;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          num++;
        }
      }
    }
    if( num > 1 )
      return true;
    return false;
  }
  public numAreSel(){
    var num = 0;
    for(const res of this.responsables){
        if(res.check){
          num++;
        }
    }
    if( num > 1 )
      return true;
    return false;
  }
  // Metodos de ordenamiento
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoObjId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objectiveId.id) > Number(b.objectiveId.id)) {
        return 1;
      }
      if (Number(a.objectiveId.id) < Number(b.objectiveId.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoMetas(array: any){
    array.sort(function (a: any, b: any) {
      if ((a.period) > (b.period)) {
        return 1;
      }
      if ((a.period) < (b.period)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoAd(array: any){
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  // metodos de escritura tabla
  public escribirObj(ind: any, i: any){
    if(this.indicadoresMostrar.length === 1){
      return this.obtObj(ind.objectiveId.id);
    }else{
      if(i!=0){
        if(Number(ind.objectiveId.id) != Number(this.indicadoresMostrar[i-1].objectiveId.id))
          return this.obtObj(ind.objectiveId.id);
      }else if(i === 0){
        return this.obtObj(ind.objectiveId.id);
      }
    }
    return '';
  }
  public escribirPer(ind: any, i: any){
    var perid1, perid2;
    for(const obj of this.objectivos){
      if(obj.id === this.indicadoresMostrar[i].objectiveId.id){
        perid1 = obj.idPer;
      }
    }
    if(i != 0){
      for(const obj of this.objectivos){
        if(obj.id === this.indicadoresMostrar[i-1].objectiveId.id){
          perid2 = obj.idPer;
        }
      }
      if(perid1 != perid2){
        return this.obtPer(perid1);
      }
    }else{
      return this.obtPer(perid1);
    }
    
    return '';
  }
  public escribirMeta(periodo: any, ind: any, sinfor?: any){
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.value);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.value;
      }
    }
    return ''
  }
  public rendimiento(periodo: any, ind: any){
    var nombre = '';
    const metas = ind.metas.filter( meta => 
      Meses.getMesesN(new Date(meta.period).getMonth()+1)+'-'+(''+new Date(meta.period).getFullYear()).substring(2,4) === periodo
    );
    //const met = metas[0];
    const met = {real: metas[0].real===null?null:metas[0].real, value: metas[0].value===null?null:metas[0].value};
    if(met.real < 0 && met.value >= 0){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  public escribirReal(periodo: any, ind: any, sinfor?: any){ //avance
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.real);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.real;
      }
    }
    return ''
  }
  public pintarBorde(valor: any, meta: any, ind: any) {
    const met = Number(meta), val = Number(valor);
    var porcen = undefined, col = '';
    if(val === 0 && met !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro'
    }
    let dat = ind.split('%');
    porcen = Number(dat[0]);
    if(isNaN(val)){
      porcen = undefined;
    }
    if( porcen !== undefined ){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcen >= valfin){
        porcen = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcen >= ab && porcen <= sem.valfin){
          col = sem.color;
          break;
        }
      }
      switch(col){
        case 'rojo': return 'bordeRojo';
        case 'naranja': return 'bordeNaranja';
        case 'amarillo': return 'bordeAmarillo';
        case 'azul': return 'bordeAzul';
        case 'verde': return 'bordeVerde';
      }
    }
    return 'bordeGris';
  }
  public pintarBordeTarj(valor: any, meta: any) {
    if(valor !== '' && valor !== 0){
      var porcen = Number(((valor*100)/meta).toFixed(2));
      if(porcen > 100){
        porcen = 100;
      }
      var col = '';
      for(const sem of this.semaforo){
        if(sem.valIn > sem.valFin){
          if(sem.valIn >= porcen && sem.valfin <= porcen){
            col = sem.color;
          }  
        }else{
          if(sem.valIn <= porcen && sem.valfin >= porcen){
            col = sem.color;
          }  
        }
      }
      switch(col){
        case 'rojo': return 'bordeRojo';
        case 'naranja': return 'bordeNaranja';
        case 'amarillo': return 'bordeAmarillo';
        case 'azul': return 'bordeAzul';
        case 'verde': return 'bordeVerde';
      }
    }
    return '';
  }
  public Mostrar(ind: any){
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var metasInd = [];
    var existeMet = false;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSele.push(mes.periodo);
        }
      }
    }
    for(const met of ind.metas){
      metasInd.push(Meses.getMesesN(new Date(met.period).getMonth()+1) + '-' + (new Date(met.period).getFullYear()+'').substring(2,4));
    }
    for(const per of metasInd){
      for(const perS of perSele){
        if(per === perS){
          existeMet = true;
        }
      }
    }

    //Observar que la perspectiva este activa
    var existPer = false, perInd;
    for(const obj of this.objectivos){
      if(obj.id === ind.objectiveId.id){
        perInd = this.obtPer(obj.idPer);
      }
    }

    if(this.todasPer){
      existPer = true;
    }else{
      for(const per of this.perspectives){
        if(per.check && per.name === perInd){
          existPer = true;
        }
      }
    }

    //que el area este activa
    var areaAct = false;

    for(const area of this.responsables){
      if(area.id === ind.areaId && area.check){
        areaAct = true;
        break;
      }
    }
    
    if(existeMet && existPer && areaAct)
      return true;
    return false;
  }
  public editar(periodo: any, ind: any){
    var perAct = Meses.obtnerPer(this.fechaAct);
    var perAnt = Meses.obtnerPerAnt(this.fechaAct);    
    if(this.edicion){
      this.dat(periodo, ind);
      return ;
    }
    if(Meses.compararPer(perAnt,perAct,periodo)){
      this.dat(periodo, ind)
    }else{
      Swal.fire({
        title: 'Periodo no editable',
        text: 'Solo se puede editar la información del mes actual y un mes anterior',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Aceptar'
      });
    }
  }
  private async dat(periodo: any, ind: any){
    var inputValue: any, introdujo: any, guardo = false;
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo){
        inputValue = Number(met.real)
      }
    }
     
    introdujo = await Swal.fire({
      title: 'Ingrese el dato',
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        guardo = true;
        return '';
      }
    });

    if(guardo){
      for(const indd of this.indicadores){
        if(indd.id  === ind.id){
          for(const met of indd.metas){
            nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
            if(nombre === periodo){
              if(introdujo.value !== ''){
                met.real = Number(introdujo.value);
              }else{
                met.real = null;
              }
              this.objetivosService.patchMetas(met).subscribe(response =>{
                this.obtenerPesoPers();
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
        }
      }
    }
  }
  public escribirAdicional(adic: any){
    if(adic != undefined){
      switch(adic.type){
        case 'texto':
          return this.obtComment(adic.comments);
        case 'moneda':
          return this.formatearnormal(adic.amount);
        case 'numero':
          return this.obtCommentNumero(adic.comments);
        case 'archivo':
          var per  = this.perSelected();
          for(const file of adic.files){
            if( per === Meses.obtnerPer(file.created_at)){
              return file.name.split('-')[file.name.split('-').length - 1];
            }
          }
          return '';
      }
    }
  }
  private obtCommentNumero(com : any){
    for(const comen of com){
      var per = 0;
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            per = an.anio;
          }
        }
      }
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
  private obtComment(com : any){
    for(const comen of com){
      var per =  this.perSelected();
      var percom = Meses.obtnerPer(new Date(comen.created_at));
      if(per === percom){
        return comen.comment;
      }
    }
    return '';
  }
  public async edicionAd(adic: any, id: any, event?: any){
    var inputValue : any, introdujo: any, guardo = false;

    switch(adic.type){
      case 'texto':
        inputValue =  this.obtComment(adic.comments);
        introdujo = await Swal.fire({
          title: 'Ingrese el comentario',
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'moneda':
        inputValue =  adic.amount;
        introdujo = await Swal.fire({
          title: 'Ingrese el monto',
          text: "",
          input: 'number',
          inputValue: inputValue,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'archivo':
        inputValue =  adic.file;
        introdujo = await Swal.fire({
          title: 'Seleccione su archivo',
          text: "",
          input: 'file',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }

    if(guardo){
      switch(adic.type){
        case 'texto':
          var existe = false;
          var per =  this.perSelected();
          for(const comen of adic.comments){
            var percom = Meses.obtnerPer(new Date(comen.created_at));
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.objetivosService.patchComment(comen, adic.id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          if(!existe){
            var date = (new Date(Meses.getMesesS(per.split('-')[0])+'-1-'+per.split('-')[1]));
            adic.comments.push({
              comment: introdujo.value,
              created_at: date.toISOString(),
            });
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString()
            }
            this.objetivosService.postComment(aux, adic.id).subscribe(response =>{
              this.objetivosService.getAdicionales().subscribe(response =>{
                this.obtenerAdicionales(response);
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  showConfirmButton: false,
                  icon: 'success',
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        break;
        case 'moneda':
          adic.amount = introdujo.value;
          this.objetivosService.patchAdicionales(adic).subscribe(response =>{
            if(!response){
              Swal.fire({
                title: 'No se pudo actualizar la información',
                text: '',
                confirmButtonColor: '#ff6b00',
                cancelButtonColor: '#B9B9BC',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
              });
            }else{
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            }
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        break;
        case 'archivo':
          this.progressInfo = [];
          introdujo.value ? this.imageName = introdujo.value.name : this.imageName = introdujo.value.length + " archivos";
          this.selectedFiles = introdujo.value;
          var per  = this.perSelected();
          var existe = false;
          if( this.selectedFiles["size"] > obtenerTamMax.getPeso() ){
            Swal.fire({
              title: obtenerTamMax.getTexto(),
              text: '',
              showConfirmButton: false,
              icon: 'error',
              timer: this.dataService.tiempo,
              didClose: () => {
                this.edicionAd(adic, id, event);
              }
            });
          }else{
            if(adic.files.length !== 0){
              for(const fil of adic.files){
                if( fil.periodo === per){
                  existe = true;
                }
              }
            }
            if(!existe){
              var per = this.perSelected();
              const fecha = new Date(Meses.getMesesS(per.split('-')[0])+'/1/'+per.split('-')[1]);
              document.getElementById('cargando_arch')['style'].display = 'block';
              this.objetivosService.postFile(this.selectedFiles, adic.id, fecha.toISOString()).subscribe(response =>{
                if(response){
                  this.objetivosService.getAdicionales().subscribe(response =>{
                    document.getElementById('cargando_arch')['style'].display = 'none';
                    this.obtenerAdicionales(response);
                    Swal.fire({
                      title: 'Información actualizada',
                      text: '',
                      showConfirmButton: false,
                      icon: 'success',
                      timer: this.dataService.tiempo
                    });
                  });
                }
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
        break;
      }
    }else{
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public abirPDF(ad: any, event?: any){
    const per = this.perSelected();
    for(const fil of ad.files){
      if(Meses.obtnerPer(fil.created_at) === per){
        this.objetivosService.getFile(ad.id, fil.id).subscribe(response =>{
          switch(fil.name.split('.')[fil.name.split('.').length-1]){
            case 'pdf':
              const mediaType = 'application/pdf';
              const blob = new Blob([response], { type: mediaType });
              const nav = (window.navigator as any);

              if (nav.msSaveOrOpenBlob)  {
                nav.msSaveOrOpenBlob(blob, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blob);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            case 'png':
            case 'jpg':
              const mediaTypei = 'image/'+fil.name.split('.')[fil.name.split('.').length-1];
              const blobi = new Blob([response], { type: mediaTypei });
              const navi = (window.navigator as any);

              if (navi.msSaveOrOpenBlob)  {
                navi.msSaveOrOpenBlob(blobi, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobi);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            default:
              const mediaTyped = 'aplication/'+fil.name.split('.')[fil.name.split('.').length -1];
              const blobd = new Blob([response], { type: mediaTyped });
              const navd = (window.navigator as any);

              if (navd.msSaveOrOpenBlob)  {
                navd.msSaveOrOpenBlob(blobd, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobd);
                a.href = url;
                a.download = fil.name.split('-')[fil.name.split('-').length -1];
                document.body.appendChild(a);
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
          }
        });
      }
    }
  }
  // Busqueda
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.responsables2.filter = filterValue.trim().toLowerCase();
  }
  //eliminar archivos
  public deleteArchivo(){
    const per = this.perSelected();
    Swal.fire({
      title: '¿Seguro que deseas eliminar el archivo?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        for(const fil of this.adDelete.files){
          if(Meses.obtnerPer(fil.created_at) === per){
            this.objetivosService.deleteFile(this.adDelete.id, fil.id).subscribe(response =>{
              this.objetivosService.getAdicionales().subscribe(response =>{
                this.obtenerAdicionales(response);
                Swal.fire({
                  title: 'Información eliminada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        }
      }
    });
  }  
  // obtencion de nombres
  private obtObj(objectiveid: any){
    for(const obj of this.objectivos){
      if(obj.id === objectiveid){
        return obj.nombre;
      }
    }
  }
  private obtPer(perId: any){
    for(const per of this.perspectives){
      if(per.id === perId){
        return per.name
      }
    }
  }
  public obtRes(i: any){
    var areaId= this.indicadoresMostrar[i].areaId;
    for(const res of this.responsables){
      if(res.id === areaId){
        return res.nombre;
      }
    }
    return '';
  }
  private perSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  //Formatear datos
  public formatearnormal(num: any){
    num = Number(Number(num).toFixed(2));
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight === '.00' || splitRight === '.0')
      return  splitLeft;
    return  splitLeft+splitRight;
  }
  //Rutas
  public rutas(direc: string){
    if(direc === 'avanzar')
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'indicadoresMeta/' +this.idPlanificacion);
    else
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'cuadromando/' +this.idPlanificacion);
  }
  //
  //Porcentajes acumulados
  public porcentajeObjecAcum(){
    var persPon = 0, perTot = 0;

    var pesoFin = 0; 
    for(const obj of this.objectivos){
      var por = this.porcentajeObjAcum(obj.id), porcentajeObj = obj.peso;
      if(por != undefined){
        pesoFin += obj.peso;
        persPon += por*porcentajeObj/100;
      }
    }
    
    if(persPon !== 0){
      persPon = persPon*100/pesoFin;
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin;
      }
    }
    if(persPon >= valfin){
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  public porcentajePersAcum(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    for(const obj of this.objectivos){
      if(Number(obj.idPer) === Number(nomPer)){
        for(const ind of this.indicadores){
          var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
          let metas = ind.metas;
          for(var i = metas.length-1; i>=0; i--){
            const peer = this.fechaMetas(metas[i].period);
            if(fechaPer === peer && Number(obj.id) === Number(ind.objectiveId.id)){
              const val = this.escribirRealacum(Meses.obtnerPer(metas[i].period), ind);
              const met = this.escribirMetaacum(Meses.obtnerPer(metas[i].period), ind);
              var porcen = undefined;
              let dat = this.rendimientoacum(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val)){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }
              break;
            }else if(fechaPer>peer && metas[i].real !== null && Number(obj.id) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
              const val = this.escribirRealacum(Meses.obtnerPer(metas[i].period), ind);
              const met = this.escribirMetaacum(Meses.obtnerPer(metas[i].period), ind);
              var porcen = undefined;
              let dat = this.rendimientoacum(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val)){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }else{
                porcen === 0;
              }
              break;
            }
          }
        }
        if(porcentaje !== 0){
          peso += porcentaje*obj.peso/100;
          pesoPer += obj.peso;
        }
        porcentaje = 0;
      }
    }
    
    if(peso!==0){
      porcentaje = peso*100/pesoPer;
    }else{
      porcentaje = 0;
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  private obtenerPondIndAcum(ind: any){
    var Nind = 0;
    for(const indi of this.indicadores){
      if( Number(ind.objectiveId.id) === Number(indi.objectiveId.id) && this.porcentajeIndAcum(indi) !== undefined ){
        Nind += indi.ponderacion;
      }
    }
    return ind.ponderacion * 100 /Nind;
  }
  private porcentajeIndAcum(ind: any){
    var persel = this.perSelected(), metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = undefined;
    for(var i = ind.metas.length-1; i>=0; i--){
      const peer = this.fechaMetas(ind.metas[i].period);
      if(fechaPer === peer){
        const val = this.escribirRealacum(Meses.obtnerPer(ind.metas[i].period), ind);
        const met = this.escribirMetaacum(Meses.obtnerPer(ind.metas[i].period), ind);
        let dat = this.rendimientoacum(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val)){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }else{
          porcentaje === 0;
        }
        break;
      }else if(fechaPer>peer && ind.metas[i].real !== null && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
        const val = this.escribirRealacum(Meses.obtnerPer(ind.metas[i].period), ind);
        const met = this.escribirMetaacum(Meses.obtnerPer(ind.metas[i].period), ind);
        let dat = this.rendimientoacum(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val)){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }
        break;
      }
    }
    if( porcentaje!== undefined )
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public porcentajeObjAcum(objId: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    
    
    for(const ind of this.indicadores){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
      let metas = ind.metas;
      for(var i = metas.length-1; i>=0; i--){
        const peer = this.fechaMetas(metas[i].period);
        if(fechaPer === peer && Number(objId) === Number(ind.objectiveId.id)){
          const val = this.escribirRealacum(Meses.obtnerPer(ind.metas[i].period), ind);
          const met = this.escribirMetaacum(Meses.obtnerPer(ind.metas[i].period), ind);
          var porcen = undefined;
          let dat = this.rendimientoacum(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val)){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }else if(fechaPer>peer && metas[i].real !== null && Number(objId) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
          const val = this.escribirRealacum(Meses.obtnerPer(ind.metas[i].period), ind);
          const met = this.escribirMetaacum(Meses.obtnerPer(ind.metas[i].period), ind);
          var porcen = undefined;
          let dat = this.rendimientoacum(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val)){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }else{
            porcen === 0;
          }
          break;
        }
      }
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }

  public escribirMetaacum(periodo: any, ind: any){
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(met.period.getMonth()+1)+'-'+(''+met.period.getFullYear()).substring(2,4);
      if(nombre === periodo){
        return met.value;
      }
    }
    return ''
  }
  public escribirRealacum(periodo: any, ind: any){ //avance
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(met.period.getMonth()+1)+'-'+(''+met.period.getFullYear()).substring(2,4);
      if(nombre === periodo){
        return met.real;
      }
    }
    return ''
  }
  public rendimientoacum(real: any, value: any, ind){
    const met = {real: real===null?null:real, value: value===null?null:value};
    if(met.real < 0 && met.value >= 0 ){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null ){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  private fechaMeta(periodo){
    let fecha = new Date(Meses.getMesesNum(periodo.split('-')[0])+'-01-'+periodo.split('-')[1]);
    return moment(fecha).format("YYYY-DD-MM");
  }
  private fechaMetas(periodo){
    return moment(periodo).format("YYYY-DD-MM");
  }
  // Datos de tarjetas
  public escribirPerT(ind: any, i: any){
    var perid1;
    for(const obj of this.objectivos){
      if(obj.id === ind.objectiveId.id){
        perid1 = obj.idPer;
      }
    }
    return this.obtPer(perid1);

  }
  public porcentajeObjec(){
    var persPon = 0;
    for(const per of this.perspectives){
      var por = this.porcentajePers(per.name), porPer = this.obtenerPorcentajePer(this.obtenerPonder(per.name));
      if(por !== undefined){
        persPon += Number((por*porPer/100).toFixed(2));
      }
    }
    return Number(persPon.toFixed(0));
  }
  private obtenerPorcentajePer(per: any){
    var ponderacionPer = 0;
    var i = 0, objList: any = [];

    for(const ind of this.indicadoresMostrar){
      if(!objList.includes(ind.objectiveId.id)){
        objList.push(ind.objectiveId.id);
      }
    }
    for(const obj of this.objectivos){
      if(objList.includes(obj.id)){
        ponderacionPer += obj.peso;
      }
    }
    return Number((per * 100 / ponderacionPer).toFixed(2));
  }
  private obtenerPonder(nomPer: any){
    var ponderacionPer = 0, persel = this.perSelected();
    var i = 0, objList: any = [];

    for(const  ind of this.indicadoresMostrar){
      var per = this.escribirPerT(ind,i);
      for(const met of ind.metas){
        if(persel === Meses.obtnerPer(new Date(met.period)) && nomPer === per){
          if(!objList.includes(ind.objectiveId.id)){
            objList.push(ind.objectiveId.id);
          }
        }
      }
      i++;
    }

    for(const per of this.perspectives){
      for(const obj of this.objectivos){
        if(per.id === obj.idPer && per.name === nomPer && objList.includes(obj.id)){
          ponderacionPer += obj.peso;
        }
      }
    }
    return ponderacionPer;
  }
  private obtenerPondInd(ind: any){
    var Nind = 0;
    for(const indi of this.indicadoresMostrar){
      if( ind.objectiveId.id === indi.objectiveId.id ){
        Nind += indi.ponderacion;
      }
    }
    return Number((ind.ponderacion * 100 /Nind).toFixed(2));
  }
  public porcentajePers(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    var porcentaje = 0, ponderacionPer = this.obtenerPonder(nomPer);

    for(const ind of this.indicadoresMostrar){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondInd(ind);
      for(const met of ind.metas){
        if(persel === Meses.obtnerPer(new Date(met.period)) && nomPer === per){
          const val = Number(this.escribirReal(persel, ind)), met = Number(this.escribirMeta(persel, ind));
          
          var porcen = Number(this.rendimiento(persel, ind).split('%')[0]);
          if(porcen === 0){
            porcen = undefined;
            var por = 0;
            porcentaje += 0;
            metasTot++;
          }
          
          if( porcen !== undefined ){  
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcen = porcen * ind.objectiveId.weighing/100;
            porcen = porcen * 100/ ponderacionPer;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }
      }
      
      i++;
    }
    if(metasTot != 0)
     return Number((porcentaje).toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public escribirObjTar(ind: any, i: any){
    return this.obtObj(ind.objectiveId.id);    
  }
  private porcentajeObj(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    var porcentaje = 0;

    for(const ind of this.indicadoresMostrar){
      var per = this.escribirObjTar(ind,i), pondInd = this.obtenerPondInd(ind);
      for(const met of ind.metas){
        if(persel === Meses.obtnerPer(new Date(met.period)) && nomPer === per){
          /*const val = Number(this.escribirReal(persel, ind)), met = Number(this.escribirMeta(persel, ind));
          
          if(val !== 0 && !isNaN(val)){
            switch(ind.tendencia){
              case '-1':
                if( met === 0 && val <= 0 ){
                  porcen = 100;
                }else if( met === 0 && val > 0 ){
                  porcen = 0;
                }else if( val < 0 ){
                  const sem = this.semaforo.filter( sem => sem.valfin >= 100);
                  porcen =  sem[0].valfin;
                }else{
                  porcen = Number(((met*100)/val).toFixed(2));
                }
              break;
              case '1':
              case '0':
                if( met === 0 && val >= 0 ){
                  const sem = this.semaforo.filter( sem => sem.valfin >= 100);
                  porcen =  sem[0].valfin;
                }else if( met === 0 && val < 0 ){
                  porcen = 0;
                }else if( val < 0 ){
                  porcen =  0;
                }else{
                  porcen = Number(((val*100)/met).toFixed(2)); 
                }
              break;
            }
          }else if( met === 0 && !isNaN(val)){
            switch(ind.tendencia){
              case '-1':
                if( met === 0 && val <= 0 ){
                  const sem = this.semaforo.filter( sem => sem.valfin >= 100);
                  porcen =  sem[0].valfin;
                }else if( met === 0 && val > 0 ){
                  porcen = 0;
                }
              break;
              case '1':
              case '0':
                if( met === 0 && val >= 0 ){
                  const sem = this.semaforo.filter( sem => sem.valfin >= 100);
                  porcen =  sem[0].valfin;
                }else if( met === 0 && val < 0 ){
                  porcen = 0;
                }
              break;
            }
          }else{
            var por = 0;
            porcentaje += 0;
            metasTot++;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }*/
          var porcen = Number(this.rendimiento(persel, ind).split('%')[0])* pondInd/100;
          porcentaje += porcen;
          metasTot++;
          break;
        }
      }
      i++;
    }
    if(metasTot != 0)
     return Number((porcentaje).toFixed(2));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public pintarBordeT(valor: any){
    if(valor != undefined){
      var color = '';
    if(valor !== 0){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(valor >= valfin){
        valor = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(valor >= ab && valor < sem.valfin){
          color = sem.color;
          break;
        }
      }
    }
    switch(color){
      case 'verde': return 'bordeVerde';
      case 'azul': return 'bordeAzul';
      case 'amarillo': return 'bordeAmarillo';
      case 'naranja': return 'bordeNaranja';
      case 'rojo': return 'bordeRojo';
      default:  return 'bordeGrey';
    }
    }else{
      return 'bordeGrey';
    }
  }
  public texto(valor: any){
    var color = '';
    if(valor != 0){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(valor >= valfin){
        valor = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(valor >= ab && valor < sem.valfin){
          color = sem.color;
          break;
        }
      }
    }
    switch(color){
      case 'verde': return 'cifraSecVerd';
      case 'azul': return 'cifraSecAzul';
      case 'amarillo': return 'cifraSecAmar';
      case 'naranja': return 'cifraSecNarj';
      case 'rojo': return 'cifraSecRojo';
      default:  return 'cifraSecGris';
    }
  }
  public exportar_excel(){
    const title = 'Seguimiento: Objetivos de '+this.Periodo;
    let tit = [];
    let esp = [];
    for(const ad of this.indicadoresMostrar[0].additions){
      if(ad.type === 'texto'){
        tit.push(''+ad.name);
        esp.push('');
      }
    }
    let header = ["Indicador", "Ponderación", "Responsable", "Meta", "Avance", "Rendimiento"];
    header = header.concat(tit);
    var data = [], aux = [];
    for(var i = 0; i < this.indicadoresMostrar.length; i++){
      if( this.escribirPer(this.indicadoresMostrar[i], i) != '' ){
        aux.push( '',this.escribirPer(this.indicadoresMostrar[i], i) );
        aux.push('','', 'Ponderación', this.porcentajePers( this.escribirPer(this.indicadoresMostrar[i], i) )/100 );
        aux = aux.concat(esp);
        data.push(aux);
        aux = [];  
      }
      if( this.escribirObj(this.indicadoresMostrar[i], i) != '' ){
        aux.push( this.escribirObj(this.indicadoresMostrar[i], i) );
        aux.push('','','','Ponderación',this.porcentajeObj( ''+this.escribirObj(this.indicadoresMostrar[i], i) )/100 );
        aux = aux.concat(esp);
        data.push(aux);
        aux = [];  
      }
      aux.push( (this.verCodigo?'Código | ':'')+''+this.indicadoresMostrar[i].nombre );
      aux.push( this.indicadoresMostrar[i].ponderacion );
      aux.push( this.obtRes(i));
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            var real = (Number(this.escribirReal(mes.periodo, this.indicadoresMostrar[i],'ss')));
            aux.push( Number(this.escribirMeta(mes.periodo, this.indicadoresMostrar[i],'ss')) );
            aux.push( isNaN(real)? 0:real );
            aux.push( Number((this.rendimiento(mes.periodo, this.indicadoresMostrar[i])).split(' ')[0])/100 );
          }
        }
      }
      for(const ad of this.indicadoresMostrar[i].additions){
        if(ad.type === 'texto'){
          aux.push( this.escribirAdicional(ad) );
        }
      }
      data.push(aux);
      aux = [];
    }
    const avance = this.porcentajeObjecAcum()/100;
    const NomArch = 'Inidicadores_objetivos';
    this.excelService.generateExcelObjetivos2(title, header, data, NomArch, this.semaforo, avance);
  }
  // Menu click izquierdo
  public onContextMenu(event: MouseEvent, item) {
    event.preventDefault(); 
    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px'; 
    this.menuTopLeftPosition.y = event.clientY + 'px'; 

    this.adDelete = item;

    this.matMenuTrigger.openMenu();
  }
}
