<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-caja-menu padreizquierda w3-noventa">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span> 
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>  
      </div>
    </div>
    <div class="w3-rest w3-right">
      <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" style="margin-top: 2vh;"
        [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        <mat-menu #menu2="matMenu">
          <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="exportar_excel()">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>Exportar xlsx</span>
          </button>
        </mat-menu>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <!--Elementos externos-->
    <div class="zonaGen">
      <div class="w3-row">
        <div class="noventa titulo-contenidomenu">
          <span class="w3-margin-left-contenido">Factores externos</span>
        </div>
      </div>
      <ng-container *ngFor="let elem of diccionarioFLOA2">
        <div class="espacioElemnts">
          <div class="w3-row w3-margin-left-caja">
            <div class="noventa titulo-contenidomenu">
              <span>{{elem.externosss.elemento}}</span>
            </div>
          </div>
          <div class="zonaTabla w3-scroll">
            <table class="w3-table w3-border">
              <thead>
                <tr>
                  <th class="elemento-titulo-table borderInf tresPun encabezado alinIzq" style="width: 3vw;"> # </th>
                    <th class="elemento-titulo-table borderInf tresPun encabezado">{{elem.externosss.elemento}}</th>
                    <th *appRoles="['Rol_Administrador']" class="elemento-titulo-table alinCen borderInf tresPun encabezado" style="width: 4vw;">
                      <button matTooltip="Agregar elemento" class="colorBotonDeNuevo" (click)="abrirFormAgregarExt(elem.externosss)">
                        <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                      </button>
                    </th>
                </tr>
              </thead>
              <tbody *ngFor="let item of elem.elementooo; let i = index">
                <tr>
                  <td class="w3-table-trtdBalance alinIzq tresPun">{{obtCodigo(elem.externosss)}}{{i+1}} </td>
                  <td class="w3-table-trtdBalance tresPun alinIzq" style="max-width: 20vw; overflow: hidden;">
                    <span matTooltip="{{item.nombre}}">{{item.nombre}}</span> 
                  </td>
                  <td *appRoles="['Rol_Administrador']" class="w3-table-trtdBalance w3-center tresPun"> 
                    <mat-icon class="iconoMen" matTooltip="Editar" (click)="abrirFormEditExt(item,elem.externosss)">edit</mat-icon>
                    <mat-icon class="iconoMen" matTooltip="Eliminar elemento" (click)="alertas('eliminar',item)">delete</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
    <!--Elementos internos-->
    <div class="zonaGen">
      <div class="w3-row">
        <div class="noventa titulo-contenidomenu">
          <span class="w3-margin-left-contenido">Factores Internos</span>
        </div>
      </div>
      <ng-container *ngFor="let elem of diccionarioFLOA1">
        <div class="espacioElemnts">
          <div class="w3-row w3-margin-left-caja">
            <div class="noventa titulo-contenidomenu">
              <span>{{elem.internosss.elemento}}</span>
            </div>
          </div>
          <div class="zonaTabla w3-scroll">
            <table class="w3-table w3-border">
              <thead>
                <tr>
                  <th class="elemento-titulo-table borderInf tresPun encabezado alinIzq" style="width: 3vw;"> # </th>
                    <th class="elemento-titulo-table borderInf tresPun encabezado">{{elem.internosss.elemento}}</th>
                    <th *appRoles="['Rol_Administrador']" class="elemento-titulo-table alinCen borderInf tresPun encabezado" style="width: 4vw;">
                      <button matTooltip="Agregar elemento" class="colorBotonDeNuevo" (click)="abrirFormAgregar(elem.internosss)">
                        <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                      </button>
                    </th>
                </tr>
              </thead>
              <tbody *ngFor="let item of elem.elementooo; let i = index">
                <tr>
                  <td class="w3-table-trtdBalance alinIzq tresPun">{{obtCodigo(elem.internosss)}}{{i+1}} </td>
                  <td class="w3-table-trtdBalance tresPun alinIzq" style="max-width: 20vw; overflow: hidden;">
                    <span matTooltip="{{item.nombre}}">{{item.nombre}}</span> 
                  </td>
                  <td *appRoles="['Rol_Administrador']" class="w3-table-trtdBalance w3-center tresPun"> 
                    <mat-icon class="iconoMen" matTooltip="Editar" (click)="abrirFormEdit(item,elem.internosss)">edit</mat-icon>
                    <mat-icon class="iconoMen" matTooltip="Eliminar elemento" (click)="alertas('eliminar',item)">delete</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!--Modales elementos internos-->
<div id="formularionuevo" class="w3-modal">
  <div class="w3-modal-content" style="width: 35vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido">Agregar elemento</span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 30vh;">
      <div class="w3-container" style="height: 28vh; overflow: scroll;">

        <form [formGroup]="formGeneral" (ngSubmit)="alertas('guardar')">
          <div id="nombreForm" class="w3-row" >
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Elemento: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formGeneral.hasError('isValid')" class="error-message">
                Elemento <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Ingrese el concepto</mat-label>
                <input placeholder="Ingrese un nombre corto" formControlName="inputName" type="text" matInput id="nombre">
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Perspectiva: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Seleccione una perspectiva </mat-label>
                <mat-select id="perspectiva" formControlName="inputPerspective" required>
                  <ng-container *ngFor="let pers of perspectiva">
                    <mat-option value="{{pers.id}}" > {{pers.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardar()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>
<div id="formularioedit" class="w3-modal">
  <div class="w3-modal-content" style="width: 35vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido">Editar elemento</span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 30vh;">
      <div class="w3-container" style="height: 28vh; overflow: scroll;">

        <form [formGroup]="formGeneral" (ngSubmit)="alertas('editar')">
          <div class="w3-row" >
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Elemento: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formGeneral.hasError('isValid')" class="error-message">
                Elemento <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Ingrese el concepto</mat-label>
                <input placeholder="Ingrese un nombre corto" formControlName="inputNameEdit" type="text" matInput id="nombreedit">
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Perspectiva: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Seleccione una perspectiva </mat-label>
                <mat-select id="perspectiva" formControlName="inputPerspectiveEdit" required>
                  <ng-container *ngFor="let pers of perspectiva">
                    <mat-option value="{{pers.id}}" > {{pers.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal2">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarEdit()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>
<!--Modales elementos externos-->
<div id="formularionuevoExt" class="w3-modal">
  <div class="w3-modal-content" style="width: 35vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido">Agregar elemento</span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 30vh;">
      <div class="w3-container" style="height: 28vh; overflow: scroll;">

        <form [formGroup]="formGeneral" (ngSubmit)="alertas('guardarExt')">
          <div id="nombreForm" class="w3-row" >
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Elemento: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formGeneral.hasError('isValid')" class="error-message">
                Elemento <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Ingrese el concepto</mat-label>
                <input placeholder="Ingrese un nombre corto" formControlName="inputNameExt" type="text" matInput id="nombreExt">
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Pestel: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Seleccione una elemento </mat-label>
                <mat-select id="perspectivaExt" formControlName="inputPerspectiveExt" required>
                  <ng-container *ngFor="let pers of catalogo">
                    <mat-option value="{{pers.id}}" > {{pers.name}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal3">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarExt()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>
<div id="formularioeditExt" class="w3-modal">
  <div class="w3-modal-content" style="width: 35vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido">Editar elemento</span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 30vh;">
      <div class="w3-container" style="height: 28vh; overflow: scroll;">

        <form [formGroup]="formGeneral" (ngSubmit)="alertas('editarExt')">
          <div class="w3-row" >
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Elemento: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formGeneral.hasError('isValid')" class="error-message">
                Elemento <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Ingrese el concepto</mat-label>
                <input placeholder="Ingrese un nombre corto" formControlName="inputNameEditExt" type="text" matInput id="nombreeditExt">
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Pestel: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 80%;">
                <mat-label> Seleccione un elemento </mat-label>
                <mat-select id="perspectivaExt" formControlName="inputPerspectiveEditExt" required>
                  <ng-container *ngFor="let pers of catalogo">
                    <mat-option value="{{pers.id}}" > {{pers.name}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal4">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarEditExt()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>