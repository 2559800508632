import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { ActivatedRoute } from '@angular/router';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import { Meses } from 'src/app/model/datos';
import { ConstantesImagenes } from '../codigoimagenes';
import pdfMake from 'pdfmake/build/pdfmake';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { AltaplanificacionesService } from 'src/app/services/formulacion/altaplanificaciones/altaplanificaciones.service';

@Component({
  selector: 'app-avance-segimiento-fin',
  templateUrl: './avance-segimiento-fin.component.html',
  styleUrls: ['./avance-segimiento-fin.component.css']
})
export class AvanceSegimientoFinComponent implements OnInit {
  /* Variables  */
  public Nombre = 'Avance de seguimiento';
  public introduccion: string = '';
  public fechaReporteIni = '';

  public porPeriodo = false;
  public numPeridos = 12;

  private idPlan = 0;
  private imgLogo;
  public periodos = [];
  public periodosIm = [];
  private periodSelec;
  private diasLab;

  private semaforos = [];
  private semaforosProyectos = [];
  public perspectivas = [];
  public perspectiva2 = [];
  public persSeleccionadas = [];
  private perspectivaSelec = '';

  public responsables = [];
  public lideres = [];

  public objectivos = [];
  public objSeleccionados = [];
  private diccionarioPer = [];

  private indicadores = [];
  private indicadoresMostrar = [];
  private indicadoresMostrarIm = [];

  public todosObjetivos: boolean = true;
  public todosLideresSeleccionados = true;
  public todasPerIM = true;

  private colorScheme_balanceIM: any = {
    domain: ['#FF495C', '#FF8300', '#FFD100', '#0075C9', '#72D54A'],
  };
  
  public treemap = [];
  public graficas_lineales = [];

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  public isLoadingObtenerDatos: boolean = false;
  public isDownloadingPdf = false;
  public isDownloadingData = false;

  private programas = [];
  private proyectos = [];
  private proyectosMostrar = [];
  private proyectosFin = [];
  private actividades = [];

  private objSelect: any;
  private proSelect: any;
  private mostrarPeriodo =  true;
  public objetivo = '';
  public programa = '';


  public imprimirIntro: boolean = false;
  public imprimirFoda: boolean = false;
  public imprimirFodaV2: boolean = false;
  public imprimirObjetivos: boolean = false;
  public imprimirIndicadores: boolean = true;
  public imprimirCmi: boolean = true;
  public imprimirPlanOperativo: boolean = true;
  public imprimirPlanOperativoActividades: boolean = false;
  public imprimirGraficas: boolean = false;

  public numActivid = 0;

  public verFodaV1 = false;
  public verFodaV2 = false;

  public subtitulo = '';
  public imgFloa: any;

  public diccionarioFLOA1: any = [];
  public diccionarioFLOA2: any = [];
  public diccionarioFLOA1V2: any = [];
  public diccionarioFLOA2V2: any = [];
  elemento = [];
  public internos = [
    {id:1, type:'FORTALEZA', elemento:'Fortalezas'},
    {id:2, type:'DEBILIDAD', elemento:'Debilidades'},
  ];
  public externos = [
    {id:3, type:'OPORTUNIDAD', elemento:'Oportunidades'},
    {id:4, type: 'AMENAZA', elemento:'Amenazas'},
  ];

  //email
  public emailsControl: any = {
    emails: [],
  };
  nuevoEmail: string = '';
  pdfFile: any;
  reportName = 'Reporte.pdf';
  companyId = '';

  //Reporte
  private fechaReporte;
  private anioInicial;
  private anioFinal;
  private nombreInstitucion = '';
  private acronimoInstitucion = '';
  private codigoIntitucion = '';
  public reporteGeneradoExitosamente: boolean = true;

  img_graficas = new Map<String, String>();

  constructor(
    private rutaActiva: ActivatedRoute,
    private reportesService: ReportesService,
    public dataService: DataService,
    private altaplanificacionesService: AltaplanificacionesService,
  ) {
    if(dataService.modules.includes('est-matrizfloa')){
      this.verFodaV1 = true;
    }
    if(dataService.modules.includes('est-matrizderiesgosyoportunidades')){
      this.verFodaV2 = true;
    }
    var param = this.rutaActiva.snapshot.params;
    this.idPlan = Number(param['planeacion']);
    this.isLoadingObtenerDatos = true;
    
    this.obtenerData();
  }

  ngOnInit(): void {}
  /* Consultas */
  private obtenerData(){
    this.reportesService.getPlan(this.idPlan).subscribe( (res) =>{
      this.obtenerFiltros(res);
      this.reportesService.getObjectives().subscribe( (res) => {
        this.obtObjectives(res);
        this.reportesService.getIndicadores().subscribe((response) => {
          this.obtenerIndicadores(response);
          this.reportesService.getAdicionales().subscribe((response) => {
            this.obtenerAdicionales(response);
            this.obtenerProyectos();
          });
        });
      });
    });
    this.altaplanificacionesService.getPlanById(this.idPlan.toString()).subscribe((r: any) => (this.introduccion = r.report_intro ?? ''));
  }
  private obtenerProyectos(){
    this.reportesService.getProyectos().subscribe((response) => {
      this.obtProyectos(response);
      this.reportesService.getActividades().subscribe((response) => {
        this.obtActividades(response);
        this.reportesService.getProyectosPeriodo(this.periodSelec).subscribe(response =>{
          const proyect = this.obtenerProyectosPlaneacion(response);
          this.obtenerProyectosPeriodo(proyect);
          this.reportesService.getAdicionales().subscribe(response =>{
            this.obtAdditions(response);
          });
        });
      });
    });
    this.reportesService.getProgramas().subscribe((response) => {
      this.obtProgramas(response);
    });
  }
  private obtenerLogo(){
    this.reportesService.getLogo(this.codigoIntitucion).subscribe( response =>{
      for(const doc of response){
        const name = (''+doc['filename']).split('.');
        const nomsep = name[0].split('_');
        for(const nam of nomsep){
          if( nam.toLocaleLowerCase() === 'logo' ){
            this.reportesService.getArchivosA(doc._id).subscribe(response =>{
              var base = 'data:image/'+name[name.length-1]+';base64,'+btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
              this.imgLogo = base;
            });
            break;
          }
        }
      }
    });
  }
  /* Obtención de datos */
  private obtenerFiltros(plan){
    this.periodos = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
    this.periodosIm = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
    this.periodSelec = this.getPeriod();
    this.selPeriodosIndMetas(this.periodSelec, 12);

    this.diasLab = plan.business_days;

    this.nombreInstitucion = plan.institution.name;
    this.acronimoInstitucion = plan.institution.acronym;
    this.codigoIntitucion = plan.institution.code;
    this.obtenerLogo();

    let anioIni = 0, anioFin = 0;
    anioIni = (new Date(plan.initial_date)).getFullYear();
    anioFin = (new Date(plan.end_date)).getFullYear();
    this.fechaReporte = anioIni + ' - ' + anioFin;

    let selec = this.getPeriod();
    this.anioInicial = this.periodos[0].series[0].periodo.charAt(0).toUpperCase() + this.periodos[0].series[0].periodo.replace('-', '. ').slice(1);
    this.anioFinal = selec.charAt(0).toUpperCase() + selec.replace('-', '. ').slice(1);

    const sema = this.ordenamientoSem(plan.signals);

    for (const sem of sema) {
      this.semaforos.push({
        valIn: Number(sem.value_00),
        valfin: Number(sem.value_01),
        color: sem.color[0],
      });
    }
    if( Number(sema[sema.length-1].value_01) > 100){
      const valFinal = Number(sema[sema.length-1].value_01);
      for(const sem of sema){
        this.semaforosProyectos.push({
          valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
          valfin: Math.round((Number(sem.value_01)*100)/valFinal),
          color: sem.color[0],
        });
      }
    }else{
      this.semaforosProyectos = [].concat(this.semaforos);
    }

    this.perspectivas = [];
    for(const per of plan.perspectives){
      const existe = this.perspectivas.filter( p => p.id === per.id);
      if( existe.length === 0 ){
        this.perspectivas.push({
          name: per.name,
          id: per.id,
          check: true,
        });
      }
    }
    this.perspectivas = this.ordenamientoId(this.perspectivas);

    this.reportesService.getAreas().subscribe((response) => {
      this.responsables = [];
      for (const res of response) {
        if (res.institution.id === plan.institution.id) {
          this.lideres.push({ ...res, check: true });
          this.responsables.push({
            id: res.id,
            nombre: res.name,
            check: true,
          });
        }
      }
      this.responsables = this.ordenamientoId(this.responsables);
    });

    for(const per of plan.perspectives){
      this.perspectiva2.push({
        nombre: per.name,
        descripcion: per.descripcion,
        planningId: this.idPlan,
        id: per.id,
      });
    }
    this.perspectiva2 = this.ordenarPerspe(this.perspectiva2);

    this.internos.forEach(per => { this.diccionarioFLOA1.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva2}); });
    this.externos.forEach(per1 => { this.diccionarioFLOA2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva2}); });

    this.internos.forEach(per => { this.diccionarioFLOA1V2.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva2}); });
    this.externos.forEach(per1 => { this.diccionarioFLOA2V2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva2}); });

    this.elemento = [];
    this.reportesService.getFLOA().subscribe( res => {
      this.getFloaV1(res);
      this.getFloaV2(res);
    });
  }
  private obtObjectives(array){
    var auxPer: any = [], auxO = [];
    var dicci: any = [];
    for (const per of this.perspectivas){
      for (const obj of array){
        if(per.id === obj.perspective.id){
          auxPer.push({
            name: obj.name,
            id: obj.id,
            weighing: obj.weighing,
            avance: 0,
            check: true
          });
          auxO.push({
            nombre: obj.name,
            id: obj.id,
            idPer: obj.perspective.id,
            peso: obj.weighing,
            check: true
          });
        }
      }
      dicci.push({perspective: per, objetivos: auxPer});
      auxPer = [];
    }
    this.objectivos = [].concat(auxO);
    this.objectivos = this.ordenamientoId(this.objectivos);
    this.diccionarioPer = dicci;
  }
  private obtenerIndicadores(array: any) {
    var aux = [];
    for (const ind of array) {
      for (const obj of this.objectivos) {
        if (ind.objective.id === obj.id) {
          var metas = [];
          for (const met of ind.goals) {
            metas.push({
              id: met.id,
              period: met.period,
              value: met.value,
              real: met.real,
            });
          }
          aux.push({
            nombre: ind.name,
            id: ind.id,
            ponderacion: ind.weighing,
            metas: this.ordenamientoMetas(metas),
            tendencia: ind.tendency,
            objectiveId: ind.objective.id,
            objectives: [this.objectivos.find((obj) => obj.id === ind.objective.id)],
            fechaIni: ind.initial_date,
            fechaFin: ind.end_date,
            areaId: ind.area.id,
          });
          break;
        }
      }
    }
    this.indicadores = [].concat(aux);
    this.indicadores = this.ordenamientoId(this.indicadores);
    this.indicadores = this.ordenamientoObjId(this.indicadores);
    this.indicadores = this.ordenarPorPerspectiva(this.indicadores);
    this.indicadoresMostrar = [];
    this.indicadoresMostrarIm = [];

    for (const ind of this.indicadores) {
      if (this.mostrar(ind)) {
        this.indicadoresMostrar.push(ind);
      }
      if (this.mostrar(ind, '2')) {
        this.indicadoresMostrarIm.push(ind);
      }
    }
    this.contar_coloresIM();
  }
  private obtProyectos(array: any) {
    var aux = [];
    for (const pro of array) {
      for (const prog of this.objectivos) {
        if (pro.objectives.length !== 0 && pro.objectives[0].id === prog.id) {
          pro.perspective = prog.perspective;
          aux.push(pro);
        }
      }
    }
    this.proyectos = this.ordenamientoId(aux);
  }
  private obtActividades(array: any) {
    var aux = [];
    for (const act of array) {
      for (const pro of this.proyectos) {

        if (act.project && act.project.id === pro.id) {
          var hist = [];
          for (const his of act.history) {
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: Number(his.executed),
              id: his.id,
            });
          }
          aux.push({
            additions: [],
            end_date: act.end_date,
            id: act.id,
            initial_date: act.initial_date,
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            proyecto: pro,
          });
        }
      }
    }
    this.actividades = this.ordenamientoId(aux);
    aux = [];
    var aux2 = [];
    for (const pro of this.proyectos) {
      for (const act of this.actividades) {
        if (pro.id === act.project.id) {
          aux2.push(act);
        }
      }
      if (aux2.length) {
        aux.push({
          proyecto: pro,
          actividades: aux2,
          check: true,
          fecha_ini: undefined,
          fecha_fin: undefined,
          planeado: [],
          ejecutado: [],
        });
      }
      aux2 = [];
    }
    aux = this.obtfechasProy(aux);
    this.proyectosFin = aux;
  }
  private obtenerProyectosPlaneacion(array: any){
    var aux = [];
    for(const pro of array){
      for(const prog of this.objectivos){
        if(pro.objectives.length !== 0 && pro.objectives[0].id === prog.id){
          pro.perspective = prog.perspective;
          aux.push(pro);
        }
      }
    }
    return aux;
  }
  private obtenerProyectosPeriodo(array: any){
    array = this.ordenamientoId(array);
    var proyectosPeriodo = [], actividadesPeriodo = [];
    var auxAct = []
    for(const pro of array){
      for(const act of pro.activities){
        if(act.history.length !== 0){
          var hist = [];
          for(const his of act.history){
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: his.executed,
              id: his.id,
            });
          }
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else if(this.actividadIncompleta(act)){
          var hist = [];
          hist.push({
            periodo: this.periodSelec,
            planeado: 100,
            ejecutado: null,
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else{
          var hist = [];
          hist.push({
            periodo: this.periodSelec,
            planeado: '-',
            ejecutado: '-',
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });       
        }
      }
      if(auxAct.length !== 0){
        proyectosPeriodo.push({proyecto: pro, actividades: auxAct, check: false,fecha_ini:undefined, fecha_fin: undefined, planeado: [], ejecutado: []});
      }
      auxAct = [];
    }
    proyectosPeriodo = this.obtfechasProy(proyectosPeriodo);
    this.proyectosFin = proyectosPeriodo;
    this.actividades = actividadesPeriodo;
  }
  private obtAdditions(array: any){
    for(const act of this.actividades){
      var aux = [];
      for(const ad of array){
        if( ad.activity !== null && ad.activity.id === act.id){
          var fil = [];  
          if(ad.files && ad.files.length !== 0){
            for(const f of ad.files){
              const date = this.obtFech(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      act.additions = aux;
    }
    for(const pro of this.proyectosFin){
      for(const act of pro.actividades){
        var aux = [];
        for(const ad of array){
          if( ad.activity !== null && ad.activity.id === act.id){
            var fil = [];  
            if(ad.files && ad.files.length !== 0){
              for(const f of ad.files){
                const date = this.obtFech(f.created_at);
                fil.push({
                  id: f.id,
                  name: f.name,
                  description: f.description,
                  path: f.path,
                  created_at: date,
                  updated_at: f.updated_at,
                });
              }
            }
            aux.push({
              amount: ad.amount,
              comments: ad.comments,
              id: ad.id,
              name: ad.name,
              note: ad.note,
              type: ad.type,
              files: fil,
            });
          }
        }
        aux = this.ordenamientoAd(aux);
        act.additions = aux;
      }
    }
    this.obtenerProy('cam');
    this.isLoadingObtenerDatos = false;
  }
  private obtProgramas(array: any) {
    var aux = [];
    for (const prog of array) {
      for (const obj of this.objectivos) {
        if (prog.objectives.length > 0 && prog.objectives[0].id === obj.id) {
          aux.push({
            id: prog.id,
            name: prog.name,
            description: prog.description,
            projects: this.ordenamientoId(prog.projects),
            objectives: this.ordenamientoId(prog.objectives),
            avance: 0,
            atraso: 0,
          });
        }
      }
    }
    this.programas = [];
    this.programas = this.ordenamientoObjId(this.ordenamientoId(aux));
  }
  /* metodos varios */
  //Proyectos
  private actividadIncompleta(act: any){
    var mes = Meses.getMesesS(this.periodSelec.split('-')[0])<10?'0'+Meses.getMesesS(this.periodSelec.split('-')[0]):''+Meses.getMesesS(this.periodSelec.split('-')[0]);
    const momentAct = moment('01-'+mes+'-20'+this.periodSelec.split('-')[1], 'DD-MM-YYYY');

    var fechaFinAct;
    if((act.end_date+'').includes('Z')){
      fechaFinAct = this.obtFech(act.end_date);
    }else{
      fechaFinAct = act.end_date;
    }
    mes = (fechaFinAct.getMonth()+1)<10?'0'+(fechaFinAct.getMonth()+1):''+(fechaFinAct.getMonth()+1);
    var dia = fechaFinAct.getDate()<10?'0'+fechaFinAct.getDate():''+fechaFinAct.getDate();
    var anio = fechaFinAct.getFullYear();
    const momnetFinAct = moment(dia+'-'+mes+'-'+anio, 'DD-MM-YYYY');
    
    if(momentAct.isAfter(momnetFinAct) && act.completed !== true){
      return true;
    }
    return false;
  }
  private obtenerProy(zon){
    var auxmos = [];
    for(const pro of this.proyectosFin){
      switch(zon){
        case 'cam':
          if(this.mostrarPro(pro)){
            auxmos.push(pro)
          }
        break;
      }
    }
    this.proyectosMostrar = auxmos;
  }
  private anSelected(){
    for(const an of this.periodos){
      for(const mes of an.series){
        if(mes.check){
          return an.anio;
        }
      }
    }
    return '';
  }
  private pertenece_perspective(pro: any){
    if(!this.todasPerIM){
      var obj = [];
      for(const obje of pro.proyecto.objectives){
        const om = this.objectivos.filter(obb => obb.id === obje.id);
        obj = obj.concat(om);
      }
      const ex = this.perspectivas.filter(per => per.check && per.id === obj[0].idPer);
      if( ex.length !== 0 ){
        return true;
      }
      return false;
    }else{
      return true;
    }
  }
  //
  private formatearnormal(num: any){
    num = Number(Number(num).toFixed(2));
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight === '.00' || splitRight === '.0')
      return  splitLeft;
    return  splitLeft+splitRight;
  }
  private obtFech(isodate: any) {
    const fecha = isodate.split('T')[0];
    var fech = new Date(
      fecha.split('-')[1] +
        '/' +
        fecha.split('-')[2] +
        '/' +
        fecha.split('-')[0]
    );
    return fech;
  }
  private obtfechasProy(array: any) {
    for (const pro of array) {
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for (const act of pro.proyecto.activities) {
        var fecha_inicio = new Date(act.initial_date),
          fecha_fin = new Date(act.end_date);
        if (pro.fecha_ini === undefined) {
          pro.fecha_ini = fecha_inicio;
        } else {
          if (pro.fecha_ini > fecha_inicio) {
            pro.fecha_ini = fecha_inicio;
          }
        }
        if (pro.fecha_fin === undefined) {
          pro.fecha_fin = fecha_fin;
        } else {
          if (pro.fecha_fin < fecha_fin) {
            pro.fecha_fin = fecha_fin;
          }
        }
      }
      pro.ejecutado = Meses.obtenerAvance(pro.fecha_ini, pro.fecha_fin);
      pro.planeado = Meses.obtenerPlaneado(
        pro.fecha_ini,
        pro.fecha_fin,
        this.diasLab
      );
    }

    return array;
  }
  //Metodos de pintar bordes
  private pintarBorde(valor: any, meta: any, ind: any) {
    const met = Number(meta), val = Number(valor);
    var porcen = undefined, col = '';
    if(ind === 'null' || ind === null){
      return 'bordeGris';
    }
    if(val === 0 && met !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro'
    }
    let dat = ind.split('%');
    porcen = Number(dat[0]);
    if(isNaN(val)){
      porcen = undefined;
    }
    if( porcen !== undefined ){
      var valfin = 0;
      for(const sem of this.semaforos){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcen >= valfin){
        porcen = valfin-1;
      }
      for(const sem of this.semaforos){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcen >= ab && porcen <= sem.valfin){
          col = sem.color;
          break;
        }
      }
      switch(col){
        case 'rojo': return 'bordeRojo';
        case 'naranja': return 'bordeNaranja';
        case 'amarillo': return 'bordeAmarillo';
        case 'azul': return 'bordeAzul';
        case 'verde': return 'bordeVerde';
      }
    }
    return 'bordeGris';
  }
  public pintarBordeVal(valor: any) {
    if (valor != 0) {
      var color = '',
        valfin = 0;
      for (const sem of this.semaforos) {
        if (valfin < sem.valfin) {
          valfin = sem.valfin;
        }
      }
      if (valor > valfin) {
        valor = valfin - 1;
      }
      for (const sem of this.semaforos) {
        var ab = sem.valIn;
        if (ab !== 0) {
          ab = ab - 0.1;
        }
        if (valor >= ab && valor <= sem.valfin) {
          color = sem.color;
          break;
        }
      }
      switch (color) {
        case 'verde':
          return 'bordeVerde';
        case 'azul':
          return 'bordeAzul';
        case 'amarillo':
          return 'bordeAmarillo';
        case 'naranja':
          return 'bordeNaranja';
        case 'rojo':
          return 'bordeRojo';
        default:
          return 'bordeGrey';
      }
    } else {
      return 'bordeGrey';
    }
  }
  //Optencion del periodo
  private obtPer(perId: any) {
    for (const per of this.perspectivas) {
      if (per.id === perId) {
        return per.name;
      }
    }
  }
  private perAMos(perA: any) {
    var ultPerconDat = '',
      datos = 0;
    var periodos_mostrados = [];

    for (const an of this.periodos) {
      for (const mes of an.series) {
        if (mes.check) {
          periodos_mostrados.push(mes.periodo);
        }
      }
    }

    for (var i = periodos_mostrados.length - 1; i >= 0; i--) {
      for (const ind of this.indicadoresMostrar) {
        for (const met of ind.metas) {
          var peri = Meses.getMesesN(new Date(met.period).getMonth() + 1);
          peri +=
            '-' + (new Date(met.period).getFullYear() + '').substring(2, 4);
          if (met.real != '0' && peri === periodos_mostrados[i]) {
            datos++;
            break;
          }
        }
      }
      if (datos != 0) {
        ultPerconDat = periodos_mostrados[i];
        break;
      }
    }
    if (perA === ultPerconDat) return true;
    return false;
  }
  private muestraPer(ind: any) {
    var perSele = [];
    var existeMet = false;
    for (const an of this.periodosIm) {
      for (const mes of an.series) {
        if (mes.check) {
          perSele.push(mes.periodo);
        }
      }
    }
    for (const perS of perSele) {
      if (ind === perS) {
        existeMet = true;
      }
    }

    if (existeMet) return true;
    return false;
  }
  private contar_coloresIM() {
    this.graficaslineales();
    var rojo = 0,
      naranja = 0,
      amarillo = 0,
      azul = 0,
      verde = 0;
    for (var i = 0; i < this.indicadoresMostrar.length; i++) {
      for (var j = 0; j < this.indicadoresMostrar[i].metas.length; j++) {
        var per = Meses.getMesesN(new Date(this.indicadoresMostrar[i].metas[j].period).getMonth() + 1);
        per += '-' + ( new Date( this.indicadoresMostrar[i].metas[j].period ).getFullYear() + '' ).substring(2, 4);
        if (this.muestraPer(per) && this.perAMos(per)) {
          var existPer = false,
            perInd;
          for (const obj of this.objectivos) {
            if (obj.id === this.indicadoresMostrar[i].objectiveId) {
              perInd = this.obtPer(obj.idPer);
            }
          }
          if (this.todasPerIM) {
            existPer = true;
          } else {
            for (const per of this.perspectivas) {
              if (per.check && per.name === perInd) {
                existPer = true;
              }
            }
          }
          if (existPer) {
            let real = this.escribirReal(per, this.indicadoresMostrar[i],'s');
            let value = this.escribirMeta(per, this.indicadoresMostrar[i],'s');
            switch(''+this.pintarBorde(real,value, this.rendimiento(per, this.indicadoresMostrar[i]))){
              case 'bordeRojo':
                rojo++;
                break;
              case 'bordeNaranja':
                naranja++;
                break;
              case 'bordeAmarillo':
                amarillo++;
                break;
              case 'bordeAzul':
                azul++;
                break;
              case 'bordeVerde':
                verde++;
                break;
            }
          }
        }
      }
    }
    var aux = [];
    switch (this.semaforos.length) {
      case 3:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FFD100', '#72D54A'],
        };
        break;
      case 4:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Azul', value: azul });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FFD100', '#0075C9', '#72D54A'],
        };
        break;
      case 5:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Naranja', value: naranja });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Azul', value: azul });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FF8300', '#FFD100', '#0075C9', '#72D54A'],
        };
        break;
    }
    this.treemap = [];
    this.treemap = aux;
  }
  private graficaslineales() {
    this.graficas_lineales = [];
    var graflin: any = [], aux = [], aux2 = [];
    for (var i = 0; i < this.indicadoresMostrarIm.length; i++) {
      for (var j = 0; j < this.indicadoresMostrarIm[i].metas.length; j++) {
        var per =  Meses.getMesesN(moment(this.indicadoresMostrarIm[i].metas[j].period).month()+1)+'-'+(''+moment(this.indicadoresMostrarIm[i].metas[j].period).year()).substring(2,4);
        if (this.muestraPer(per)) {
          var existPer = false,
            perInd;
          for (const obj of this.objectivos) {
            if (obj.id === this.indicadoresMostrarIm[i].objectiveId) {
              perInd = this.obtPer(obj.idPer);
            }
          }
          if (this.todasPerIM) {
            existPer = true;
          } else {
            for (const per of this.perspectivas) {
              if (per.check && per.name === perInd) {
                existPer = true;
              }
            }
          }
          if (existPer) {
            aux.push([ per, Number(this.indicadoresMostrarIm[i].metas[j].value), Number(this.indicadoresMostrarIm[i].metas[j].real) ]);
          }
        }
      }
      graflin.push(aux);
      this.graficas_lineales.push({
        name: this.indicadoresMostrarIm[i].nombre,
        indicadorId: this.indicadoresMostrarIm[i].id,
        series: aux,
        check: true,
      });
      aux = [];
      aux2 = [];
      graflin = [];
    }
    for (const per of this.perspectivas) {
      if (per.check) {
        this.perspectivaSelec = per.name;
        break;
      }
    }
  }
  private obtenerAdicionales(array: any) {
    for (const ind of this.indicadores) {
      var aux = [];
      for (const ad of array) {
        if (ad.indicator !== null && ad.indicator.id === ind.id) {
          var fil = [];
          if (ad.files && ad.files.length !== 0) {
            for (const f of ad.files) {
              const date = this.obtFech(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      ind.additions = aux;
    }
  }
  //Metodos de muestreo
  public mostrar(ind: any, antes?) {
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var existeMet = false;
    for (const an of this.periodos) {
      var mes = an.series.filter((mes) => mes.check);
      if (mes.length !== 0) {
        perSele = perSele.concat(mes);
      }
    }

    for (const met of ind.metas) {
      var per = Meses.getMesesN(moment(met.period).month()+1)+'-'+(''+moment(met.period).year()).substring(2,4);
      const perEx = perSele.find((perS) => perS.periodo === per);
      if (perEx) {
        existeMet = true;
        break;
      }
    }

    //Observar que la perspectiva este activa
    let existPer = false;
    let perInd;

    if (this.todasPerIM) {
      existPer = true;
    } else {
      for (const obj of this.objectivos) {
        if (obj.id === ind.objectiveId) {
          perInd = this.obtPer(obj.idPer);
          break;
        }
      }

      for (const per of this.perspectivas) {
        if (per.check && per.name === perInd) {
          existPer = true;
          break;
        }
      }
    }

    //que el area este activa
    var areaAct = false;

    if (this.todosLideresSeleccionados) {
      areaAct = true;
    } else {
      for (const area of this.lideres) {
        if (area.id === ind.areaId && area.check) {
          areaAct = true;
          break;
        }
      }
    }

    let objSel = false;
    if (!this.todosObjetivos) {
      for (const obj of this.objectivos) {
        if (obj.id === ind.objectiveId && obj.check) {
          objSel = true;
          break;
        }
      }
    } else {
      objSel = true;
    }

    if (existPer && areaAct && objSel && antes !== undefined){
      return true;
    }
    if (existPer && areaAct && objSel && existeMet && antes === undefined){
      return true;
    }
    return false;
  }
  public mostrarPro(pro: any){
    var perSelected = this.anSelected();
    var perIni = pro.fecha_ini.getFullYear();
    var perFin = pro.fecha_fin.getFullYear();
    
    var tieneAct = false;
    var actTiene = 0;
    for(const act of pro.actividades){
      if(act.editable){
        actTiene++;
      }
    }
    if(actTiene!==0){
      tieneAct = true;
    }
    var existeLid = false;
    if(!this.todosLideresSeleccionados){
      const ex = this.lideres.filter(lid => lid.responsibles[0].id === pro.proyecto.responsible.id && lid.check);
      if(ex.length !== 0){
        existeLid = true;
      }
    }else{
      existeLid = true;
    }
    /** verificación del periodo */
    var existPer = false;//
    if(!this.mostrarPeriodo){
      existPer = Meses.compararAnio(perIni, perFin, perSelected);
    }
    /** Vaerificar los objetivos */
    var selObj = false;
    if(!this.todosObjetivos){
      for( const ob of pro.proyecto.objectives){
        const ex = this.objectivos.filter(obj => obj.check && obj.id === ob.id);
        if(ex.length !== 0){
          selObj = true;
          break;
        }
      }
    }else{
      selObj = true
    }
    /** Vaerificar los programas */
    var selProg = false;
    if(this.proSelect){
      if(pro.proyecto.strategies[0].id === this.proSelect){
        selProg = true;
      }
    }else{
      selProg = true;
    }

    if(existPer && selObj && selProg && existeLid && this.pertenece_perspective(pro) ){
      return true;
    }else if(tieneAct && selObj && selProg && existeLid && this.pertenece_perspective(pro)){
      return true;
    }
    return false;
  }
  private selPeriodosIndMetas(per: any, periodos: any) {
    if (!this.porPeriodo) {
      var b = true;
      for (const an of this.periodosIm) {
        if(an.anio === (new Date('01-'+Meses.getMesesNum(per.split('-')[0])+'-'+per.split('-')[1])).getFullYear()){
          b = true;
        }else{
          b = false;
        }
        for (const mes of an.series) {
          if (b) {
            mes.check = true;
          } else {
            mes.check = false;
          }
          if (mes.periodo === per) {
            b = false;
          }
        }
      }
    }else{
      let b = false, sel = 0;
      for (var i=this.periodosIm.length-1; i>=0; i--) {
        for (var j=this.periodosIm[i].series.length-1; j>=0; j--) {
          if (this.periodosIm[i].series[j].periodo === per) {
            this.periodosIm[i].series[j].check = true;
            b = true;
            sel++;
          } else if(b && sel < periodos){
            this.periodosIm[i].series[j].check = true;
            sel++;
          }else {
            this.periodosIm[i].series[j].check = false;
          }
        }
      }
    }
  }
  public cambioPorPeriodo(){
    var per = this.getPeriod();
    this.selPeriodosIndMetas(per,this.numPeridos);
  }
  //Filtros
  //años
  public anioChange(per: any, anio: any) {
    for (const an of this.periodos) {
      for (const mes of an.series) {
        mes.periodo === per ? (mes.check = true) : (mes.check = false);
      }
    }
    for(let i = 0; i < this.periodosIm.length; i++) {
      for (let j = 0; j < this.periodosIm[i].series.length; j++) {
        this.periodosIm[i].series[j].check = false;
      }
    }
    this.periodSelec = this.getPeriod();
    this.selPeriodosIndMetas(per, this.numPeridos);
    this.indicadoresMostrar = [];
    for (const ind of this.indicadores) {
      if (this.mostrar(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }
    this.contar_coloresIM();
    this.proyectosMostrar = [];
    this.reportesService.getProyectosPeriodo(this.periodSelec).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.reportesService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        
        console.log("🚀 ~ this.escribirDatPro(pro, 'eje'); =>", this.escribirDatPro(this.proyectosMostrar[0], 'eje'))
      });
    });
  }
  public cambioPeriodos(){
    if(Number(document.getElementById('numPer')['value']) > 12){
      document.getElementById('numPer')['value'] = 12;
    }
    if(Number(document.getElementById('numPer')['value']) < 1){
      document.getElementById('numPer')['value'] = 1;
    }
    var per = this.getPeriod();
    this.numPeridos = Number(document.getElementById('numPer')['value']);
    this.selPeriodosIndMetas(per,this.numPeridos);
  }
  public perImpresion(){
    if(this.periodosIm.length !== 0){
      let avan = 0, contar = false;
      for(var i = this.periodosIm.length-1; i>=0; i--){
        for(var j = this.periodosIm[i].series.length-1; j>=0; j--){
          if(this.periodosIm[i].series[j].check){
            avan++;
            contar = true;
          }else if(contar){
            avan++;
          }
          if(avan === this.numPeridos){
            return this.periodosIm[i].series[j].periodo;
          }
        }
      }
      return this.periodosIm[0].series[0].periodo;
    }else{
      return '';
    }
  }
  public getPeriod(){
    for(const an of this.periodos){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  //Perspectivas
  public selecAllPerIM(todasPerIM) {
    this.todasPerIM = todasPerIM;
    if(!this.todasPerIM){
      for(const per of this.perspectivas){
        per.check = false;
      }
    }else{
      for(const per of this.perspectivas){
        per.check = true;
      }
      this.obtenerDatosFiltros();
    }
  }
  public cambioPerIM(perSelec) {
    perSelec.check = perSelec.check;
    this.persSeleccionadas = [];
    let tod = 0, todN = 0;
    for(const per of this.perspectivas){
      if(per.check){
        tod++;
        this.persSeleccionadas.push(per);
      }else{
        todN++;
      }
    }

    if(tod === this.perspectivas.length || todN === this.perspectivas.length){ this.selecAllPerIM(true) }else { this.todasPerIM = false; }

    this.obtenerDatosFiltros();
  }
  //Objetivos
  public selectAllObjetivos(todosobj) {
    this.todosObjetivos = todosobj;
    if(!this.todosObjetivos){
      for(const obj of this.objectivos){
        obj.check = false;
      }
    }else{
      for(const obj of this.objectivos){
        obj.check = true;
      }
      this.obtenerDatosFiltros();
    }
  }
  public cambioObj(obj: any) {
    obj.check = obj.check;

    let tod = 0, todN = 0, objec = 0;
    for(const obj of this.objectivos){
      if(obj.idPer === this.persSeleccionadas[0].id){
        if(obj.check){
          tod++;
        }else{
          todN++;
        }
        objec++;
      }
    }

    if(tod === objec || todN === objec){ this.selectAllObjetivos(true) }

    this.obtenerDatosFiltros();
  }
  //Lideres
  public selecciondarTodosLideres(todosLideresSeleccionados) {
    this.todosLideresSeleccionados = todosLideresSeleccionados;
    if(!this.todosLideresSeleccionados){
      for(const lid of this.lideres){
        lid.check = false;
      }
    }else{
      for(const lid of this.lideres){
        lid.check = true;
      }
      this.obtenerDatosFiltros();
    }
  }
  public cambioLiderIM(lidSelec: any) {
    lidSelec.check = lidSelec.check;
    
    let tod = 0, todN = 0, objec = 0;
    for(const obj of this.lideres){
      if(obj.check){
        tod++;
      }else{
        todN++;
      }
    }

    if(tod === this.lideres.length || todN === this.lideres.length){ this.selecciondarTodosLideres(true) }

    this.obtenerDatosFiltros();
  }
  //Obtencion data filtros
  private obtenerDatosFiltros(){
    this.indicadoresMostrar = [];
    this.indicadoresMostrarIm = [];
    for (const ind of this.indicadores) {
      if (this.mostrar(ind)) {
        this.indicadoresMostrar.push(ind);
      }
      if (this.mostrar(ind, 'a')) {
        this.indicadoresMostrarIm.push(ind);
      }
    }

    this.contar_coloresIM();
    
    var auxmos = [];
    this.proyectosMostrar = [];
    for (const pro of this.proyectosFin) {
      if (this.mostrarPro(pro)) {
        auxmos.push(pro);
      }
    }
    this.proyectosMostrar = auxmos;
  }
  public escribirPerspectiva(ind: any, i: any) {
    let perid1;
    for (const obj of this.objectivos) {
      if (obj.id === this.indicadoresMostrarIm[i].objectives[0].id) {
        perid1 = obj.idPer;
      }
    }
    return this.obtPer(perid1);
  }
  private getFloaV1(res){
    for (const per of this.diccionarioFLOA1) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.internosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
    for (const per of this.diccionarioFLOA2) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.externosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
  }
  private getFloaV2(res){
    this.elemento = [];
    for(const mat of res){
      const ex = this.perspectiva2.filter(per => Number(per.id) === Number(mat.perspective.id));
      if(ex.length !== 0 && mat.name.includes('&&')){
        this.elemento.push(mat);
      }            
    }
    this.elemento = this.ordenarPerspe(this.elemento);

    for(const dicc of this.diccionarioFLOA1V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['internosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: ''
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }
      }
    }

    for(const dicc of this.diccionarioFLOA2V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['externosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: elem.name.split('&&')[1],
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }            
      }
    }
  }
  // Metodos de ordenamiento
  private ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoMetas(array: any) {
    array.sort(function (a: any, b: any) {
      if ((a.period) > (b.period)) {
        return 1;
      }
      if ((a.period) < (b.period)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoObjId(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.objectives[0].id) > Number(b.objectives[0].id)) {
        return 1;
      }
      if (Number(a.objectives[0].id) < Number(b.objectives[0].id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoAd(array: any) {
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenarPorPerspectiva(array: any){
    var aux = [];
    for(const per of this.perspectivas){
      for(var i = 0; i < array.length; i++){
        if( per.id === this.obtenerIdPer(array[i].objectiveId) ){
          aux.push(array[i]);
        }
      }
    }
    return aux;
  }
  private obtenerIdPer(idObj){
    for(const obj of this.objectivos){
      if(obj.id === idObj){
        return obj.idPer;
      }
    }
  }
  private ordenarPerspe(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  /* Obtencion de email */
  public async enviarFormEmail() {
    Swal.fire({
      title: 'Enviando Reporte',
      html: 'Por favor espere...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(undefined);
      },
    });
    this.cerrarFormEnviarEmail();

    await this.DescargarPDF(false);
    let emails = [];
    for (let i = 0; i < this.emailsControl.emails.length; i++) {
      const e = this.emailsControl.emails[i].email;

      emails.push(e);
    }
    this.reportesService
      .sendReport(
        this.idPlan.toString(),
        this.pdfFile,
        emails,
        this.reportName
      ).subscribe(
        {next : ()=> {
          Swal.close();
          Swal.fire({ title: 'Reporte enviado correctamente', timer: 15000 });
        },
        error: error => {
          Swal.close();
          Swal.fire({ title: 'Error al enviar el reporte', timer: 15000 });
        }}
      );
  }
  public agregarEmail() {
    this.emailsControl.emails.push({
      id: this.emailsControl.emails.length + 1,
      email: this.nuevoEmail,
    });
    this.nuevoEmail = '';
  }
  public cerrarFormEnviarEmail() {
    document.getElementById('formEnviarEmail').style.display = 'none';
  }
  public eliminar(index: number) {
    this.emailsControl.emails.splice(index, 1);
  }
  public abrirFormEnviarEmail() {
    document.getElementById('formEnviarEmail').style.display = 'block';
  }
  /* metodos de pintado */
  public obtenerDias(fIni: any, fFin: any) {
    var dias = 0,
      f1: any = new Date(fIni),
      f2: any = new Date(fFin);
    var diff = Math.round((f2 - f1) / (1000 * 60 * 60 * 24));
    switch (this.diasLab) {
      case 'Lunes-Viernes':
        var sem = Math.trunc(diff / 7);
        dias = diff - sem * 2;
        break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(diff / 7);
        dias = diff - sem;
        break;
      case 'Lunes-Domingo':
        dias = diff;
        break;
    }
    return dias;
  }
  public escribirDat(pro: any, tip: string){
    var per = this.getPeriod();
    for(const dat of pro){
      if(per === dat.periodo){
        if(tip === 'plan'){
          return dat.planeado;
        }else if(tip === 'ejec'){
          return dat.ejecutado;
        }
      }
    }
  }
  public escribirDatPro(pro: any, tip: string){
    var per = this.getPeriod();
    var dias = 0, porcentajeplan = 0, porcentajeejec = 0;
    for(const act of pro.actividades){
      if(act.editable){
        dias += this.obtenerDias(act.initial_date,act.end_date);
      }
    }
    var suma = false;
    for(const act of pro.actividades){
      if(act.editable){
        if(new Date( 20+per.split('-')[1], Meses.getMesesS(per.split('-')[0])-1 ) > new Date(act.end_date)){
          suma = true;
        }
        for(const his of act.historico){
          if(his.periodo === per || suma){
            if(suma){
              suma = false;
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((100*pesoAct/100).toFixed(2));
              let perAct;
              if(!this.actividadIncompleta(act)){
                perAct = Meses.obtnerPer( new Date(act.end_date) );
              }else{
                perAct = this.getPeriod();
              }
              var ejeAct = 0;
              for(const h of act.historico){
                if(h.periodo === perAct){
                  ejeAct = h.ejecutado;
                  break;
                }
              }
              console.log("🚀 ~ Number  =>", Number((ejeAct*pesoAct/100).toFixed(2)));
              porcentajeejec += Number((ejeAct*pesoAct/100).toFixed(2));
            }else{
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((his.planeado*pesoAct/100).toFixed(2));
              porcentajeejec += Number((his.ejecutado*pesoAct/100).toFixed(2));
            }
          }
        }
      }
    }
    if(tip === 'plan'){
      if( Number(porcentajeplan.toFixed(2)) >= 99.99 ){
        return 100;
      }
      return Number(porcentajeplan.toFixed(2));
    }
    if( Number(porcentajeejec.toFixed(2)) >= 100 ){
      return 100;
    }
    return Number(porcentajeejec.toFixed(2));
  }
  public pintarBord(eje: any, plan: any){
    var porcentaje = undefined;
    var color = '';
    if(eje === 0 && plan !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro';
    }else if(eje === 0 && plan !== 0){
      porcentaje = 0;
    }else if(eje !== 0 && eje !== null){
      porcentaje = eje*100/plan;
      
    }
    if(porcentaje !== undefined){
      var valfin = 0;
      for(const sem of this.semaforosProyectos){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcentaje >= valfin){
        porcentaje = valfin-1;
      }
      for(const sem of this.semaforosProyectos){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcentaje >= ab && porcentaje <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintarBordPro(eje: any, plan: any){
    if(eje !== 0){
      var color = '';
      var por = eje*100/plan;
      var valfin = 0;
      for(const sem of this.semaforosProyectos){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(por >= valfin){
        por = valfin-1;
      }
      for(const sem of this.semaforosProyectos){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(por >= ab && por <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintBorde(col) {
    let resp = '';
    switch (col) {
      case 'bordeRojo':
        resp = '#FF495C';
        break;
      case 'bordeNaranja':
        resp = '#FF8300';
        break;
      case 'bordeAmarillo':
        resp = '#FFD100';
        break;
      case 'bordeAzul':
        resp = '#0075C9';
        break;
      case 'bordeVerde':
        resp = '#72D54A';
        break;
      case 'bordeNegro':
        resp = '#000000';
        break;
      default:
        resp = '#C8C7C8';
    }

    return resp;
  }
  private printGrafsInTable(arrGrafs: any) {
    let respArr = {
      table: {
        widths: [350, 350],
        body: [],
      },
      layout: 'noBorders',
    };
    let auxArr = [];
    let cont = 1;
    let i = 0;
    for (const el of arrGrafs) {
      if (cont <= 2) {
        auxArr.push(el);
      }
      if (cont === 2) {
        respArr.table.body.push(auxArr);
        cont = 0;
        auxArr = [];
      }
      if (i + 1 >= arrGrafs.length && auxArr.length === 1) {
        auxArr.push({ text: '' });
        respArr.table.body.push(auxArr);
      }
      cont++;
      i++;
    }
    const resp: [any, number] = [respArr, respArr.table.body.length];
    return resp;
  }
  private estatus(col){
    switch(col){
      case 'bordeAzul':
      case 'bordeVerde':
        return 'Cumplida';
      case 'bordeRojo':
      case 'bordeNaranja':
        return 'No cumplida';
      case 'bordeAmarillo':
        return 'En proceso';
      case 'bordeGrey':
      case 'bordeGris':
        return 'Sin iniciar';
      default:
        return '';
    }

  }
  public obtRes(i: any, arreglo: any) {
    if(arreglo === 'n'){
      var areaId = this.indicadoresMostrar[i].areaId;
    }else{
      var areaId = this.indicadoresMostrarIm[i].areaId;
    }

    for (const res of this.lideres) {
      if (res.id === areaId) {
        return res.name;
      }
    }
    return '';
  }
  private obtObjIM(objectiveid: any) {
    for (const obj of this.objectivos) {
      if (obj.id === objectiveid) {
        return obj.nombre;
      }
    }
  }
  public obtCodigo(inter: any) {
    switch (inter.id) {
      case 1:
        return 'F';
      case 2:
        return 'D';
      case 3:
        return 'O';
      case 4:
        return 'A';
    }
    return 'no';
  }
  public escribirPerT(ind: any, i: any) {
    var perid1;
    for (const obj of this.objectivos) {
      if (obj.id === ind.objectiveId) {
        perid1 = obj.idPer;
      }
    }
    return this.obtPer(perid1);
  }
  //////////
  public rendimientoCMI(real: any, value: any, ind){
    const met = {real: real==='null'?null:real, value: value==='null'?null:value};
    if(met.real < 0 && met.value >= 0 ){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforos.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null ){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforos){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforos){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  private rendimiento(periodo: any, ind: any){
    var nombre = '';
    const metas = ind.metas.filter( meta => Meses.getMesesN(moment(meta.period).month()+1)+'-'+(''+moment(meta.period).year()).substring(2,4) === periodo);
    const met = { real: metas[0].real === null ? null : metas[0].real, value: metas[0].value === null ? null : metas[0].value};
    if(met.real === null){
      return null;
    }

    if(met.real < 0 && met.value >= 0){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforos.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforos.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforos){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforos){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  private escribirMeta(periodo: any, ind: any, sinfor?: any){
    if(sinfor==='ss')
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(moment(met.period).month()+1)+'-'+(''+moment(met.period).year()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.value);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.value;
      }
    }
    return ''
  }
  private escribirReal(periodo: any, ind: any, sinfor?: any){
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(moment(met.period).month()+1)+'-'+(''+moment(met.period).year()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.real);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.real;
      }
    }
    return ''
  }
  private fechaMeta(periodo){
    let fecha = new Date('01-'+Meses.getMesesNum(periodo.split('-')[0])+'-'+periodo.split('-')[1]);
    return moment(fecha).format("YYYY-DD-MM");
  }
  ///Porcentajes acumulados
  public porcentajeObjecAcum(){
    var persPon = 0, perTot = 0;

    var pesoFin = 0; 
    for(const obj of this.objectivos){
      var por = this.porcentajeObjAcum(obj.id), porcentajeObj = obj.peso;
      if(por != undefined){
        pesoFin += obj.peso;
        persPon += por*porcentajeObj/100;
      }
    }
    if(persPon !== 0){
      persPon = persPon*100/pesoFin;
    }
    
    var valfin = 0;
    for(const sem of this.semaforos){
      if(valfin < sem.valfin){
        valfin = sem.valfin;
      }
    }
    if(persPon >= valfin){
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  public porcentajePersAcum(nomPer: string){
    var persel = this.getPeriod(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    for(const obj of this.objectivos){
      if(Number(obj.idPer) === Number(nomPer)){
        for(const ind of this.indicadores){
          var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
          let metas = ind.metas;
          for(var i = metas.length-1; i>=0; i--){
            const peer = ind.metas[i].period;
            if(fechaPer === peer && Number(obj.id) === Number(ind.objectiveId)){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
              var porcen = undefined;
              let dat = this.rendimientoCMI(val,met, ind).split('%');
              porcen = Number(dat[0]);
              
              if(val === undefined){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforos){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }
              break;
            }else if(fechaPer>peer && metas[i].real !== null && Number(obj.id) === Number(ind.objectiveId) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
              var porcen = undefined;
              let dat = this.rendimientoCMI(val,met, ind).split('%');
              porcen = Number(dat[0]);
              
              if(val === undefined){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforos){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }else{
                porcen === 0;
              }
              break;
            }
          }
        }
        if(porcentaje !== 0){
          peso += porcentaje*obj.peso/100;
          pesoPer += obj.peso;
        }
        porcentaje = 0;
      }
    }
    porcentaje = peso*100/pesoPer;

    var valfin = 0;
    for(const sem of this.semaforos){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  private obtenerPondIndAcum(ind: any){
    var Nind = 0;
    for(const indi of this.indicadores){
      if( Number(ind.objectiveId) === Number(indi.objectiveId) && this.porcentajeIndAcum(indi) !== undefined ){
        Nind += indi.ponderacion;
      }
    }
    return ind.ponderacion * 100 /Nind;
  }
  private porcentajeIndAcum(ind: any){
    var persel = this.getPeriod(), metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = undefined;
    for(var i = ind.metas.length-1; i>=0; i--){
      const peer = ind.metas[i].period;
      if(fechaPer === peer){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
        let dat = this.rendimientoCMI(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        
        if(val === undefined){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforos){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }else{
          porcentaje === 0;
        }
        break;
      }else if(fechaPer>peer && ind.metas[i].real !== null && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
        let dat = this.rendimientoCMI(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        
        if(val === undefined){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforos){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }
        break;
      }
    }
    if( porcentaje!== undefined )
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public porcentajeObjAcum(objId: string){
    var persel = this.getPeriod(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    
    for(const ind of this.indicadores){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
      let metas = ind.metas;
      for(var i = metas.length-1; i>=0; i--){
        const peer = ind.metas[i].period;
        if(fechaPer === peer && Number(objId) === Number(ind.objectiveId)){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
          var porcen = undefined;
          let dat = this.rendimientoCMI(val,met, ind).split('%');
          porcen = Number(dat[0]);

          if(val === undefined){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforos){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }else if(fechaPer>peer && metas[i].real !== null && Number(objId) === Number(ind.objectiveId) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind,'s');
          var porcen = undefined;
          let dat = this.rendimientoCMI(val,met, ind).split('%');
          porcen = Number(dat[0]);
          
          if(val === undefined){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforos){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }else{
            porcen === 0;
          }
          break;
        }
      }
    }

    var valfin = 0;
    for(const sem of this.semaforos){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  // reporte
  public async generarReporte() {
    Swal.fire({
      title: 'Generando reporte',
      html: 'Por favor espere...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(undefined);
      },
    });

    await this.DescargarPDF(true);
    if (this.reporteGeneradoExitosamente) {
      Swal.close();
      Swal.fire({
        title: 'Reporte descargado exitosamente',
        text: 'Puede encontrar el archivo en la carpeta "Descargas"',
        timer: 15000,
      });
    }
  }
  public async DescargarPDF(descargar: boolean) {
    this.isDownloadingPdf = true;
    this.reporteGeneradoExitosamente = false;

    try {
      if (this.imprimirGraficas) {
        await this.obtenerGraficasIM();
      }

      let tableFoda;
      if (this.imprimirFoda && this.verFodaV1) {
        await this.getFloa_img();
        tableFoda = this.matrizFodaReporte();
      }
      let tableFodaV2;
      if (this.imprimirFodaV2 && this.verFodaV2) {
        await this.getFloa_img();
        tableFodaV2 = this.matrizFodaReporteV2();
      }

      let tableObjEst;
      if (this.imprimirObjetivos) {
        tableObjEst = this.objetivosEstrategicosReporte();
      }
      let tablaObjetivosReporte = this.obtenerObjetivosReporte();
      let tablaIndicadoresMetasReporte = this.obtenerIndicadoresMetasReporte();
      let tablaProyectos = this.obtenerProyectosEstrategicos();
      let tablaSemaforos = this.obtenerTablaSem();

      let conteoProyectos = this.obtenerTablaConteo();
      let conteoActividades = this.obtenerTablaConteoActividades();

      const fechaPiePag = new Date().toLocaleDateString('es-MX', {month: '2-digit',day: '2-digit',year: '2-digit',});
      const horaPiePag = new Date().toLocaleTimeString('es-MX');
      const currentUser = this.dataService.currentUser;
      const docDefinition = {
        images: {
          logoAleema: ConstantesImagenes.fondoblanco,
        },
        pageSize: 'FOLIO',
        footer: function (currentPage, pageCount) {
          if (currentPage == 1) {
            return null;
          }
          if(currentPage === pageCount){
            return {
              columns: [
                {
                  image: 'logoAleema',
                  width: 70,
                },

                {
                  text: `${currentUser}  |  ${fechaPiePag}  |  ${horaPiePag}`,
                  alignment: 'center',
                  width: '*',
                  color: '#808080',
                  fontSize: 10,
                },

                {
                  text: (currentPage - 1).toString(),
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },
              ],
              margin: [25, -1, 30, 0],
            };
          }else{
            return{
              text: {
                  text: (currentPage - 1).toString(),
                  alignment: 'right',
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },margin: [10, -1, 30, 0],
            }
          }
        },
        content: [
          this.imgLogo !== '' ? {
            image: this.imgLogo,
            width: 220,
            margin: [0, 0, 10, 0],
            absolutePosition: { y: 60 },
            alignment: 'right',
          } : { text: '' },
          {
            text: 'Informe de',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 250, 0, 0],
          },
          {
            text: 'seguimiento al plan',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 5, 0, 0],

          },
          {
            text: 'estratégico',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 5, 0, 0],

          },
          {
            text: this.nombreInstitucion,
            style: 'header',
            bold: true,
            fontSize: 24,
            color: '#90BC40',
            margin: [60, 45, 0, 0],
          },
          {
            text: this.fechaReporte,
            style: 'header',
            bold: true,
            fontSize: 20,
            color: '#0455B2',
            margin: [60, 45, 0, 0],
          },
          {
            text: 'fecha de corte: ' + this.periodSelec.replace('-', '. '),
            fontSize: 18,
            color: '#808080',
            margin: [60, 35, 0, 0],
          },
          {
            image: 'logoAleema',
            width: 100,
            absolutePosition: { x: 450, y: 850 },
          },

          this.imprimirIntro ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },
                {
                  text: `Avance de seguimiento ${this.anioInicial} - ${this.anioFinal}`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  absolutePosition: { x: 50, y: 40 },
                  bold: true,
                },
                {
                  text: 'introducción',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  absolutePosition: { x: 60, y: 70 },
                },
                {
                  alignment: 'justify',
                  text: this.introduccion,
                  style: 'header',
                  fontSize: 11,
                  color: '#808080',
                  absolutePosition: { x: 90, y: 130 },
                  margin: [0, 18],
                },
            ] : null,
          this.imprimirFoda ? [
                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: [
                    'La matriz estratégica FODA, corresponde a una metodología de diagnóstico que permite analizar los principales elementos internos y externos vistos desde diferentes áreas estratégicas, ofreciendo así un diagnóstico amplio y claro de la situación actual de la misma y sobre la cual podrá establecerse los lineamientos a trabajarse en un periodo futuro y bajo una visión transparente de lo que se puede lograr.\n\n',
                    'Esta matriz se encuentra dividida en cuatro cuadrantes que definen aspectos tanto internos',
                    '-fortalezas y limitaciones- como externos -oportunidades y amenazas-. En el caso de los factores',
                    'internos, estos corresponden a aquellos que han surgido de los diferentes procesos de análisis en',
                    `las 4 perspectivas: I. ${
                      this.perspectivas[0].name
                    }, II. ${this.perspectivas[1].name}, III. ${
                      this.perspectivas[2].name
                    } ${
                      this.perspectivas[3].name
                        ? 'y IV. ' + this.perspectivas[3].name
                        : ''
                    }, `,
                    'dentro del modelo de Cuadro de Mando Integral aplicado.\n\n',
                    'Uno de los pasos más importantes de la metodología de cuadro de mando integral consiste',
                    'en la definición de estrategias a partir del análisis y combinación de los elementos detectados y',
                    'plasmados en la herramienta de diagnóstico del FODA:',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },
                {
                  image: this.imgFloa,
                  width: 400,
                  alignment: 'center',
                  margin: [45, 30, 0, 0],
                },
                {
                  text: [
                    `La matriz FODA resultante de los análisis para institución ${this.nombreInstitucion}, definió los siguientes factores:`,
                  ],
                  alignment: 'justify',
                  margin: [45, 30, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },

                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  alignment: 'left',
                  bold: true,
                },
                {
                  table: tableFoda,
                  layout: {
                    hLineColor: function (i) {
                      return '#8190B1';
                    },
                    vLineColor: function (i, node) {
                      return '#8190B1';
                    },
                  },
                },
            ] : null,

          this.imprimirFodaV2 ? [
                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz de riesgos y oportunidades',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  alignment: 'left',
                  bold: true,
                },
                {
                  table: tableFodaV2,
                  layout: {
                    hLineColor: function (i) {
                      return '#8190B1';
                    },
                    vLineColor: function (i, node) {
                      return '#8190B1';
                    },
                  },
                },
            ] : null, 
          this.imprimirObjetivos ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Objetivos Estratégicos',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'definición',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                  alignment: 'left',
                },
                {
                  text: [
                    'Estos corresponden a propósitos macro que pretende lograr la organización en el período para el cual ha formulado su desarrollo y bajo los cuales alineará los planes operativos y demás actividades. Los objetivos estratégicos se construyen a través de la sumatoria conceptual de las estrategias elaboradas y se valida su alineamiento con la filosofía corporativa. Los objetivos resultantes por perspectiva son los siguientes:\n\n',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },
                {
                  text: [
                    {
                      text: 'Perspectivas\n',
                      fontSize: 16,
                      color: '#0455B2',
                      fillColor: '#FFFFFF',
                      colSpan: 2,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  margin: [45, 15, 0, 0],

                },
                {
                  table: tableObjEst,
                  margin: [45, 15, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                  markerColor: '#808080',
                },
            ] : null,
          this.imprimirCmi ? [
                  { text: '', pageBreak: 'after' },
                {
                  text: `Objetivos estratégicos`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: 'cuadro de Mando Integral - CMI',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },

                ...this.cmiPage(),
            ] : null,

          this.imprimirIndicadores ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },
                {
                  text: `Indicadores de gestión`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: `reporte de avance de objetivos ${this.periodSelec.replace('-', '. ')}`,
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  table: tablaObjetivosReporte,
                  margin: [0, 30, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                },
                { text: '', pageBreak: 'after', pageOrientation: 'landscape' },

                {
                  text: `Cumplimiento de metas por perspectiva`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],

                  bold: true,
                },
                {
                  text: `resultados por periodo | ${this.fechaReporteIni.replace(
                    '-',
                    '. '
                  )} - ${this.periodSelec.replace('-', '. ')}`,
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  table: tablaIndicadoresMetasReporte,
                  margin: [0, 30, 0, 0],
                  layout: 'noBorders',
                },
            ] : null,
          this.imprimirPlanOperativo ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },

                {
                  text: `Avance de ejecución del plan operativo`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],

                  bold: true,
                },
                {
                  text: 'proyectos',
                  style: 'header',
                  fontSize: 20,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },

                {
                  table: tablaProyectos,
                  margin: [0, 30, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                },
            ] : null,
          /*[
            { text: '', pageBreak: 'after', pageOrientation: 'portrait' },

            {
              text: `Resumen de avance de POA`,
              style: 'header',
              fontSize: 24,
              color: '#0455B2',
              margin: [15, 0, 0, 0],

              bold: true,
            },
            {
              text: `${this.periodSelec.replace('-', '. ')}`,
              style: 'header',
              fontSize: 20,
              color: '#ACBD3D',
              margin: [25, 0, 0, 0],
            },
            {
              text: [
                `El portafolio de proyectos corresponde a `, { text: `${this.proyectosMostrar.length}`, bold: true, fontSize: 12}, ` proyectos totales, `,
                this.imprimirPlanOperativoActividades? `cada uno con activiades asociadas, las cuales para este periodo  suman un total de `:'',
                this.imprimirPlanOperativoActividades? { text: `${this.numActivid}`, bold: true, fontSize: 12}:'',
                this.imprimirPlanOperativoActividades? ` actividades, `:'',
                'esto se encuentra distribuido en los siguientes estatus.',
              ],
              alignment: 'justify',
              margin: [45, 15, 0, 0],
              color: '#808080',
              fontSize: 11
            },
            {
              table: conteoProyectos,
              margin: [45, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            },

            this.imprimirPlanOperativoActividades?{
              table: conteoActividades,
              margin: [45, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            }:{},
            {
              table: tablaSemaforos,
              margin: [120, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            },
          ]*/
        ],
      };

      this.reporteGeneradoExitosamente = true;
      this.reportName =
        'Reporte_seguimiento_Plan_Estrategico-' + this.fechaReporte + '.pdf';
      const pdf = pdfMake.createPdf(docDefinition);
      if (descargar) {
        pdf.download(this.reportName);
      }
      await new Promise<void>((resolve) => {
        pdf.getBlob((blob) => {
          this.pdfFile = blob;
          resolve();
        });
      });

      setTimeout(() => {
        this.isDownloadingPdf = false;
      }, 2000);
    } catch (error) {
      this.reporteGeneradoExitosamente = false;
      Swal.close();
      Swal.fire({ title: 'Error al generar el reporte', timer: 15000 });
    }
  }
  public cmiPage() {
    const fontSize = 10;
    const sinBorders = [false, false, false, false];
    const conBorders = [true, true, true, true];
    let table: any = {
      widths: ['*'],
      body: [],
      heights: 50,
      layout: 'noBorders',
      dontBreakRows: true,
    };
    let i = 0;
    for (const per of this.diccionarioPer) {
      if (per.perspective.check || this.todasPerIM) {
        table.body.push([
          {
            text: per.perspective.name,
            color: '#808080',
            bold: true,
            border: sinBorders,
            margin: [0, 15, 0, 0],
          },
        ]);
        for (const obj of per.objetivos) {
          let cols = [];
          const obs = this.objectivos.filter((o) => o.id === obj.id);
          if (obs.length > 0 && obs[0].check) {
            const porcPers = this.porcentajeObjAcum(obj.id); //////////
            const colorBorde = this.pintBorde(this.pintarBordeVal(porcPers));
            cols.push({
              text: '',
              border: conBorders,
              fillColor: colorBorde,
              margin: [0, 0, 0, 5],
              fontSize: 4,
            });
            cols.push({
              text: obj.name,
              color: '#808080',
              border: conBorders,
              margin: [0, 5, 0, 5],
              fontSize,
            });
            cols.push({ text: '', border: sinBorders, fontSize: 2 });
            if (porcPers !== undefined) {
              cols.push({
                text: `${porcPers}%`,//////////
                color: colorBorde,
                margin: [0, 5, 0, 5],
                fontSize: 18,
                alignment: 'center',
              });
            } else {
              cols.push({ text: '', color: '#808080', margin: [0, 0, 0, 5] });
            }
            table.body.push(
              [
                {
                  table: {
                    body: [[...cols]],
                    widths: [5, 220, 5, 75],
                    heights: 40,
                  },

                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return '#E0E0E0';
                    },
                  },
                },
              ]
            );
          }
        }
      }
      i++;
    }
    if (table.body.length === 0) {
      table.body.push([{ text: '' }]);
    }
    let text = [
      {
        text: [
          { text: 'Avance del plan ', color: '#808080', fontSize: 16 },
          {
            text: `${this.porcentajeObjecAcum()} %`, //////////
            color: '#808080',
            bold: true,
            fontSize: 17,
          },
        ],
        margin: [30, 20, 0, 10],
      },
      {
        table: table,
        margin: [30, 10, 0, 0],
        layout: 'noBorders',
      },
    ];

    return text;
  }
  public async getFloa_img() {
    await html2canvas(document.querySelector('#floa_img'), {
      scale: 2.5,
      onclone: (doc) => {
        doc.getElementById('floa_img_cont').style.display = 'block';
      },
    }).then((canvas) => {
      this.imgFloa = canvas.toDataURL();
    });
  }
  /* Metodos de construcción */
  public async obtenerGraficasIM() {
    for (let i = 0; i < this.graficas_lineales.length; i++) {
      if ( this.graficas_lineales[i].series.length > 0 && this.graficas_lineales[i].check ) {
        await html2canvas(
          document.querySelector(
            '#graf' + this.graficas_lineales[i].indicadorId
          ),
          { scale: 1 }
        ).then((canvas) => {
          this.img_graficas.set(
            this.graficas_lineales[i].indicadorId,
            canvas.toDataURL()
          );
        });
      }
    }
  }
  private matrizFodaReporte() {
    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos internos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };

    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t1, t2]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos externos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t3, t4]);
    return table1;
  }
  private matrizFodaReporteV2() {
    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1V2[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1V2[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1V2[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1V2[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1V2[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1V2[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2V2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2V2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2V2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2V2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos externos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };
    // @ts-ignore
    table1.body.push([t3, t4]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos internos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );
    // @ts-ignore
    table1.body.push([t1, t2]);
    return table1;
  }
  private objetivosEstrategicosReporte() {
    let table = {
      widths: ['*'],
      body: [],
    };
    let body = [];
    let i = 0;
    for (const per of this.perspectivas) {
      const title = {
        text: per.name,
        border: [false, false, false, true],
        color: '#808080',
        bold: true,
        fontSize: 11
      };
      if (i > 0) {
        title['margin'] = [0, 15, 0, 0];
      }
      let elem = {
        ul: [],
        border: [false, false, false, false],
        markerColor:'#4E9AC7'
      };
      for (const ele of this.objectivos) {
        if (ele.idPer === per.id) {
          elem.ul.push({ text: ele.nombre, color: '#808080', fontSize: 11,  });
        }
      }
      i++;
      body.push([title]);
      body.push([elem]);
    }
    table.body = body;
    return table;
  }
  private obtenerObjetivosReporte() {
    const fontSize = 10;

    let table: any = {
      widths: ['auto', 'auto', 'auto', 85, 'auto', 'auto', 6, 55, ],
      body: [
        [
          {
            text: 'Perspectiva',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Objetivo',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Indicador',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Responsable',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Meta',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Avance',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Rendimiento',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            colSpan: 2,
            fontSize: 11,
          },
          { text: '', fontSize: 8 },
        ],
      ],
    };

    let metaAnterior = '';
    let perspAnterior = '';
    let objAnterior = '';
    for (let i = 0; i < this.indicadoresMostrar.length; i++) {
      const p = this.obtPer(this.indicadoresMostrar[i].objectives[0].idPer);
      let metaDistinta = perspAnterior === '' || perspAnterior !== p;
      let objDistinto = objAnterior === '' || objAnterior !== this.indicadoresMostrar[i].objectives[0].nombre;
      
      const border = [false, true, false, false];
      const borderColor = [
        'white',
        p === '' ? '#F5F5F5' : '#E0E0E0',
        'white',
        'white',
      ];
      let persp = {
        text: metaDistinta ? p : '',
        color: '#808080',
        border: metaDistinta
          ? [false, true, false, false]
          : [false, false, false, false],
        borderColor,
        fontSize,
      };

      let obj = {
        text: objDistinto ? this.indicadoresMostrar[i].objectives[0].nombre : '',
        color: '#808080',
        border: objDistinto ? [false, true, false, false]:[false, false, false, false],
        borderColor,
        fontSize,
      };
      let meta: any = { fontSize };
      let avance: any = { fontSize };
      let rendimiento: any = { fontSize };
      let barra: any = { text: '', fontSize: 8 };

      let m = this.escribirMeta(this.periodSelec,this.indicadoresMostrar[i],'s');
      let r = this.escribirReal(this.periodSelec,this.indicadoresMostrar[i],'s');

      meta.text = m === '' ? '0' : m;
      if(Number(m) && Number(m) !== 0){
        meta.text = Number(m).toLocaleString('es-MX',{
          maximumFractionDigits: 1
        });
      }
      meta.color = '#808080';
      meta.border = border;
      meta.borderColor = borderColor;
      meta.alignment = 'right';
      avance.text = r === 'null' || r === '' ? '0' : r;
      if(Number(r) && Number(r) !== 0){
        avance.text = Number(r).toLocaleString('es-MX',{maximumFractionDigits: 1});
      }
      avance.color = '#808080';
      avance.border = border;
      avance.borderColor = borderColor;
      avance.alignment = 'right';
      rendimiento.text = this.rendimientoCMI(r, m, this.indicadoresMostrar[i]);
      rendimiento.color = '#808080';
      rendimiento.border = border;
      rendimiento.borderColor = borderColor;
      rendimiento.alignment = 'right';
      barra.border = border;
      barra.borderColor = borderColor;
      let real = this.escribirReal( this.periodSelec, this.indicadoresMostrar[i],'s');
      let metaa = this.escribirMeta( this.periodSelec, this.indicadoresMostrar[i],'s');
      barra.fillColor = this.pintBorde(
        this.pintarBorde(real,metaa, this.rendimiento(this.periodSelec, this.indicadoresMostrar[i]))
      );

      if (meta.text !== '0') {
        table.body.push([
          persp,
          obj,
          {
            text: (false?'Código | ':'')+''+this.indicadoresMostrar[i].nombre,
            color: '#808080',
            border: border,
            borderColor,
            fontSize,
          },
          {
            text: this.obtRes(i,'n'),
            color: '#808080',
            border: border,
            borderColor,
            fontSize,
          },
          meta,
          avance,
          barra,
          rendimiento,
        ]);
        perspAnterior = p;
        objAnterior = this.indicadoresMostrar[i].objectives[0].nombre;
      }
    }
    return table;
  }
  private obtenerIndicadoresMetasReporte() {
    const bordeSuperior = [false, false, false, true];
    let table: any = {
      widths: ['*'],
      body: [],
    };
    let cont = 0;
    let tableMeses: any = {
      table: {
        widths: [],
        body: [],
      },
      layout: 'noBorders',
    };
    let tableTitulos: any = {
      table: {
        widths: [],
        body: [
          [
          ],
        ],
      },
      layout: 'noBorders',
    };

    for (const an of this.periodosIm) {
      for (const mes of an.series) {
        if (mes.check) {
          cont++;
        }
      }
    }

    let widths: any = [];
    let ancho = (600/cont)/2;
    if(cont >= 6){
      ancho = (600/6)/2
    }
    for (let i = 0; i < cont; i++) {
        widths.push(ancho, 1, ancho);
    }
    let impFlag = false;
    let perspAnterior = this.indicadoresMostrarIm.length > 0 ? this.escribirPerspectiva(this.indicadoresMostrarIm[0], 0): '';
    let objAnterior = '';
    let arrGrafs = [];
    let arrLength = this.indicadoresMostrarIm.length;
    const sinBorders = [false, false, false, false];
    let contMetasPorPersp = 0;
    let isPrintable = false;

    for (let i = 0; i < this.indicadoresMostrarIm.length; i++) {
      if ( !this.indicadoresMostrarIm[i].metas || this.indicadoresMostrarIm[i].metas.length === 0 ) {
        continue;
      }
      isPrintable = false;

      tableTitulos = {
        table: {
          widths: [],
          body: []
        },
        layout: 'noBorders',
      };
      const objAntDistinto = this.obtObjIM(this.indicadoresMostrarIm[i].objectiveId) !== objAnterior;

      tableMeses = {
        table: {
          widths: [],
          body: [],
        },
        layout: {
          hLineColor: function (i) {
            return '#E0E0E0';
          },
          vLineColor: function (i, node) {
            return '#E0E0E0';
          },
        },
      };
      tableMeses.table.widths = [...widths];
      const respp = this.obtRes(i,'');
      let metReal: any = [];
      const psp = this.escribirPerspectiva(this.indicadoresMostrarIm[i], i);
      if (psp !== perspAnterior) {
        contMetasPorPersp = 0;
      }
      let metaHeaders: any = [
      ];
      let validaMetaHeaders = perspAnterior !== psp || i === 0 || contMetasPorPersp === 0;
      let headers: any = [
      ]
      let subheaders: any = [
      ]
      let num = 0, espacio: any = [];
      for (const an of this.periodosIm) {
        for (const mes of an.series) {
          if (mes.check) {
            let meta = this.escribirMeta( mes.periodo, this.indicadoresMostrarIm[i] ,'s');
            if (meta !== 'null' && meta !== '') {
              isPrintable = true;
            }
          }
        }
      }

      for (const an of this.periodosIm) {
        for (const mes of an.series) {
          if (mes.check) {
            let meta = this.escribirMeta(mes.periodo,this.indicadoresMostrarIm[i],'s');
            let real = this.escribirReal(mes.periodo,this.indicadoresMostrarIm[i],'s');

            if (meta !== 'null' && meta !== '') {
              if (Number(meta) && Number(meta) !== 0) {
                meta = Number(meta).toLocaleString('es-MX', {maximumFractionDigits: 1,} ) ;
              }
            }
            if (real !== 'null' && real !== '') {
              if (Number(real) && Number(real) !== 0) {
                real = Number(real).toLocaleString('es-MX', {maximumFractionDigits: 1});
              }
            }
            let metaCal = this.escribirMeta(mes.periodo,this.indicadoresMostrarIm[i],'s');
            let realCal = this.escribirReal(mes.periodo,this.indicadoresMostrarIm[i],'s');
            
            metaHeaders.push(
              {
                text: mes.periodo,
                colSpan: 3,
                fillColor: '#eeeeee',
                color: '#808080',
                bold: true,
                alignment: 'center',
                border: [true, false, true, false],
                borderColor: ['white', '#F5F5F5', 'white', '#F5F5F5'],
                fontSize: 10,
              },
              { text: '', border: [false, false, true, false] },
              { text: '', border: [false, false, true, false] }
            );
            espacio.push(
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              },
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              },
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              }
            );
            headers.push(
              {
                text: 'meta',
                color: '#808080',
                border: [true, false, true, true],
                alignment: 'left',
                fontSize: 8,
                bold: true,
              },
              {
                text: 'real',
                color: '#808080',
                border: [false, false, true, true],
                alignment: 'center',
                colSpan: 2,
                fontSize: 8,
                bold: true,
              },
              { text: '', fontSize: 7 }
            );
            if (objAntDistinto) {
              subheaders.push(
                {
                  text: meta === 'null' ? '' : meta,
                  color: '#808080',
                  border: [true, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                },
                {
                  text: '',
                  fillColor:
                    this.escribirMeta(mes.periodo,this.indicadoresMostrarIm[i],'s') !== '' ? this.pintBorde( 
                      this.pintarBorde(realCal, metaCal, this.rendimiento(mes.periodo, this.indicadoresMostrarIm[i])) ) : null,
                  fontSize: 4,
                  border: [false, true, false, true],
                },
                {
                  text:
                    real === 'null' && meta === 'null'
                      ? ''
                      : real === 'null'
                      ? '0'
                      : real,
                  color: '#808080',
                  border: [false, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                }
              );
            } else {
              subheaders.push(
                {
                  text: meta === 'null' ? '' : meta,
                  color: '#808080',
                  border: [true, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                },
                {
                  text: '',
                  fillColor:
                    this.escribirMeta( mes.periodo, this.indicadoresMostrarIm[i],'s') !== ''? this.pintBorde(
                    this.pintarBorde(realCal,metaCal, this.rendimiento(mes.periodo, this.indicadoresMostrarIm[i])) ) : null,
                  fontSize: 4,
                  border: [false, true, false, true],
                },
                {
                  text:
                    real === 'null' && meta === 'null'
                      ? ''
                      : real === 'null'
                      ? '0'
                      : real,
                  color: '#808080',
                  border: [false, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                }
              );
            }

            metReal.push(
              {
                text: meta === 'null' ? '' : meta,
                color: '#808080',
                border: [true, true, true, true],
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                fillColor:
                  this.escribirMeta( mes.periodo, this.indicadoresMostrarIm[i],'s') !== ''? this.pintBorde(
                    this.pintarBorde(realCal,metaCal, this.rendimiento(mes.periodo, this.indicadoresMostrarIm[i])) ) : null,
                fontSize: 4,
                border: [false, true, false, true],
              },
              {
                text:
                  real === 'null' && meta === 'null'
                    ? ''
                    : real === 'null'
                    ? '0'
                    : real,
                color: '#808080',
                border: [false, true, true, true],
                alignment: 'left',
                fontSize: 6,
              }
            );

            num++;
            if(num !== 0 && num < 12 && (num%6 === 0) && isPrintable){
              tableMeses.table.body.push(metaHeaders);
              tableMeses.table.body.push(headers);
              tableMeses.table.body.push(subheaders);
              tableMeses.table.body.push(espacio);

              objAnterior = this.obtObjIM(this.indicadoresMostrarIm[i].objectiveId);
              metaHeaders = [];
              headers = [];
              subheaders = [];
              espacio = [];
            }
          }
        }
      }

      if(cont > 6 && cont < 12){
        for(var ag = cont; ag < 12; ag++){
          metaHeaders.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          espacio.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          headers.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          subheaders.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
        }
      }

      if (isPrintable && cont > 6) {
        tableMeses.table.body.push(metaHeaders);
        tableMeses.table.body.push(headers);
        tableMeses.table.body.push(subheaders);
        tableMeses.table.body.push(espacio);
        objAnterior = this.obtObjIM(this.indicadoresMostrarIm[i].objectiveId);
      }else if (isPrintable && cont < 6) {
        tableMeses.table.body.push(metaHeaders);
        tableMeses.table.body.push(headers);
        tableMeses.table.body.push(subheaders);
        tableMeses.table.body.push(espacio);
        objAnterior = this.obtObjIM(this.indicadoresMostrarIm[i].objectiveId);
      }
      const b = perspAnterior !== psp;
      if ( i === 0 || (b && contMetasPorPersp === 0 && tableMeses.table.body.length > 0) ) {
        table.body.push([
          {
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: psp,
                    color: '#808080',
                    bold: true,
                    fontSize: 12,
                    margin: [0, 20, 0, 0],
                    border: sinBorders,
                  },
                ],
              ],
            },
            layout: 'noBorders',
          },
        ]);
      }
      let r: String = '';
      if (tableMeses.table.body.length > 0) {
        if(objAntDistinto){
          table.body.push([
            {
              table: {

                widths: ['*'],
                body: [
                  [
                    {
                      text:  this.obtObjIM(this.indicadoresMostrarIm[i].objectiveId)

                        ,
                      color: '#808080',
                      border: sinBorders,
                      margin: [0, 0, 0, 0],
                      bold: true,
                      fontSize: 12,
                    }
                  ],
                ],
              },
            }
          ]);
        }

        let newTable: any = {
          table: {
            widths: [220, 220,],
            body: [
                 [{
                  text: 'Indicador',
                  fillColor: '#eeeeee',
                  color: '#808080',
                  bold: true,
                  fontSize: 10,
                  margin: [5, 0, 0, 0],
                },

                {
                  text: 'Responsable',
                  fillColor: '#eeeeee',
                  color: '#808080',
                  bold: true,
                  fontSize: 10,
                  margin: [5, 0, 0, 0],
                },],
                [
                  {
                    text: (false?'Código | ':'')+''+this.indicadoresMostrarIm[i].nombre,
                    color: '#808080',
                    border: bordeSuperior,
                    fontSize: 10,
                    margin: [5, 0, 0, 0],
                  },
                  {
                    text: this.obtRes(i,''),
                    color: '#808080',
                    border: bordeSuperior,
                    fontSize: 10,
                    margin: [5, 0, 0, 0],
                  },
                ],
            ],
          },
          layout: 'noBorders',
        };
        table.body.push([newTable]);
        table.body.push([tableMeses]);

        contMetasPorPersp++;
        perspAnterior = psp;

        if (this.imprimirGraficas) {
          r = this.img_graficas.get(this.indicadoresMostrarIm[i].id);
          if (r !== undefined) {
            arrGrafs.push({ image: r, width: 300, alignment: 'center' });
          }
        }
      }

      if (!this.imprimirGraficas) continue;

      if ( i + 1 < arrLength && this.escribirPerspectiva(this.indicadoresMostrarIm[i + 1], i + 1) !== psp && arrGrafs.length > 0 ) {
        const [grT, grtSize] = this.printGrafsInTable(arrGrafs);
        grT.margin = [60, 40, 0, 0];
        table.body.push([grT]);
        arrGrafs = [];
      } else if (i + 1 >= arrLength && arrGrafs.length > 0) {
        const [grT, grtSize] = this.printGrafsInTable(arrGrafs);
        grT.margin = [60, 40, 0, 0];
        table.body.push([grT]);
        arrGrafs = [];
      }
    }
    if (table.body.length === 0) {
      table.body.push({ text: '' });
    }
    return table;
  }
  private obtenerProyectosEstrategicos() {
    const fontSize = 10;
    let text = this.imprimirPlanOperativoActividades?' / Actividades':''
    let table: any = {
      headerRows: 1,
      widths: ['*', 90, 'auto', 5, 50, 60],
      body: [
        [
          {
            text: 'Proyecto' + text,
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Líder/Responsables',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Planeado',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Ejecutado',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            colSpan: 2,
            fontSize: 11,
          },
          { text: '' },
          {
            text: 'Estatus',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
        ],
      ],
    };
    let actAdic: any = [];

    for (const pro of this.proyectosMostrar) {
      table.body.push([
        {
          text: pro.proyecto.name,
          color: '#808080',
          border: [false, true, false, true],
          bold: true,
          fontSize,
        },
        {
          text: pro.proyecto.responsible.position,
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: this.escribirDatPro(pro, 'plan'),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: '',
          fillColor: this.pintBorde(
            this.pintarBordPro(
              this.escribirDatPro(pro, 'ejec'),
              this.escribirDatPro(pro, 'plan')
            )
          ),
          fontSize,
        },
        {
          text: this.escribirDatPro(pro, 'ejec'),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+this.estatus(
            this.pintarBordPro(
              this.escribirDatPro(pro, 'ejec'),
              this.escribirDatPro(pro, 'plan')
            )
          ),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
      ]);
      if(this.imprimirPlanOperativoActividades){
        for(const act of pro.actividades){
          if(act.editable){
            table.body.push([
              {
                text: act.name,
                color: '#808080',
                border: [false, true, false, true],
                margin: [10,0,0,0],
                fontSize,
              },
              {
                text: act.responsible.position,
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: this.escribirDat(act.historico, 'plan'),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: '',
                fillColor: this.pintBorde(
                  this.pintarBord(
                    this.escribirDat(act.historico, 'ejec'),
                    this.escribirDat(act.historico, 'plan')
                  )
                ),
                fontSize,
              },
              {
                text: this.escribirDat(act.historico, 'ejec')===null?0:this.escribirDat(act.historico, 'ejec'),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: ''+this.estatus(
                  this.pintarBord(
                    this.escribirDat(act.historico, 'ejec'),
                    this.escribirDat(act.historico, 'plan')
                  )
                ),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
            ]);
          }
        }
      }
    }
    return table;
  }
  private obtenerTablaConteo(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150, 150, 150],
      body: [
        [
          {
            text: 'Estatus del proyecto',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Número de proyectos',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
          {
            text: 'Porcentaje de proyectos',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };
    
    let colors = [0, 0, 0, 0];
    for(const pro of this.proyectosMostrar){
      const col = this.pintarBordPro( this.escribirDatPro(pro, 'ejec'), this.escribirDatPro(pro, 'plan') );
      switch(col){
        case 'bordeAzul':
        case 'bordeVerde':
          colors[0]++;
          break;
        case 'bordeRojo':
        case 'bordeNaranja':
          colors[1]++;
          break;
        case 'bordeAmarillo':
          colors[2]++;
          break;
        case 'bordeGrey':
        case 'bordeGris':
          colors[3]++;
          break;
      }
    }
    const etiquetas = ['Cumplidos', 'No cumplido', 'En proceso', 'Sin iniciar'];
    for(var i = 0; i<4; i++){
      table.body.push([
        {
          text: ''+etiquetas[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+colors[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
        {
          text: ''+Number((colors[i]*100/this.proyectosMostrar.length).toFixed(1)) + ' %',
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
      ]);
    }

    table.body.push([
      {
        text: 'Total',
        color: '#808080',
        border: [false, true, false, true],
        fillColor: '#eeeeee',
        bold: true,
        fontSize,
      },
      {
        text: ''+this.proyectosMostrar.length,
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        fontSize,
        bold: true,
        alignment: 'right'
      },
      
      {
        text: '100 %',
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
    ]);
    return table;
  }
  private obtenerTablaConteoActividades(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150,150,150],
      body: [
        [
          {
            text: 'Estatus de la actividad',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Número de actividades',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
          {
            text: 'Porcentaje de actividades',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };
    
    let colors = [0, 0, 0, 0];
    this.numActivid = 0;
    for(const pro of this.proyectosMostrar){
      for(const act of pro.actividades){
        if(act.editable){
          const col = this.pintarBord( this.escribirDat(act.historico, 'ejec'), this.escribirDat(act.historico, 'plan') );
          switch(col){
            case 'bordeAzul':
            case 'bordeVerde':
              colors[0]++;
              break;
            case 'bordeRojo':
            case 'bordeNaranja':
              colors[1]++;
              break;
            case 'bordeAmarillo':
              colors[2]++;
              break;
            case 'bordeGrey':
            case 'bordeGris':
              colors[3]++;
              break;
          }
          this.numActivid++;
        }
      }
    }
    const etiquetas = ['Cumplidas', 'No cumplida', 'En proceso', 'Sin iniciar'];
    for(var i = 0; i<4; i++){
      table.body.push([
        {
          text: ''+etiquetas[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+colors[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
        {
          text: ''+Number((colors[i]*100/this.numActivid).toFixed(1)) + ' %',
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
      ]);
    }

    table.body.push([
      {
        text: 'Total',
        color: '#808080',
        border: [false, true, false, true],
        fillColor: '#eeeeee',
        fontSize,
        bold: true,
      },
      {
        text: ''+this.numActivid,
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
      
      {
        text: '100 %',
        fillColor: '#eeeeee',
        color: '#808080',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
    ]);
    return table;
  }
  private obtenerTablaSem(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150, 150],
      body: [
        [
          {
            text: 'Valores',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Color',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };

    const sema = this.semaforosProyectos.sort(function (a: any, b: any) {
      if ( a.valIn > b.valIn ) {
        return 1;
      }
      if ( a.valIn < b.valIn ) {
        return -1;
      }
      return 0;
    });

    for(const sem of sema){
      
    }

    return table;
  }
}