import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getIndicadores(): Observable<any>{
    const apiURL = this.apiURL+"indicador";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectos(): Observable<any>{
    const apiURL = this.apiURL+"proyectos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  } 
  public getAccesos(correo: string): Observable<any>{
    const apiURL = this.apiURL+"access/search?email="+correo;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }  
}
