import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DataService } from './../data-service/data.service';


@Directive({
  selector: '[appRoles]'
})
export class RolesDirective {

  private usuarioLog: DataService;
  private permisosUser;
  private permisosNes;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dataService: DataService,
  ) { }

  @Input() set appRoles(val: Array<string>){
    this.permisosNes = val;
    this.usuarioLog = this.dataService;
    this.permisosUser = this.usuarioLog.role;
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.actualizarVista();
  }

  private actualizarVista(){
    this.viewContainer.clear();
    if(this.revisarPermisos()){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
  private revisarPermisos(): boolean{
    let tienePermiso = false;
    if(this.permisosUser && this.permisosNes){
        const permisoEncontrado = this.permisosNes.find( per => {
          return ( per.toUpperCase() === this.permisosUser.toUpperCase() );
        });
        if(permisoEncontrado){
          tienePermiso = true;
        }
    }
    return tienePermiso;
  }
}
