import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Planificacion{
  public id?: Number;
  public name?: String;
  public initial_date?: Date;
  public end_date?: Date;
  public isActive?: boolean;
  public institutionId?: Number;
  public business_days?:string;
  public responsibleId?:number;
  public enableMailReports?: boolean;
}
export class Semaforos{
  public name?: String;
  public operator_00?: String;
  public operator_01?: String;
  public value_00?: Number;
  public value_01?: Number;
  public color?: String[];
  public planningId?: Number;
}
@Injectable({
  providedIn: 'root'
})
export class AltaplanificacionesService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getSemaforos(): Observable<any>{
    const apiURL = this.apiURL+"semaforo";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsable(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  
  public postPlan(plan: any): Observable<any>{
    const planif: Planificacion = {
      name: plan.nombre,
      initial_date: plan.fecha_inicio.toISOString(),
      end_date: plan.fecha_fin.toISOString(),
      isActive: Boolean(plan.isActive),
      institutionId: plan.institucionId,
      business_days: plan.business_days,
      responsibleId: Number(plan.responsible),
    }
    const body = JSON.stringify(planif);
    const apiURL = this.apiURL+"planificacion";
    return this.http.post(`${apiURL}`,body, {headers: this.headers});
  }
  public patchPlan(plann: any): Observable<any>{
    const planni: Planificacion = {
      name: plann.nombre,
      initial_date: plann.fecha_inicio.toISOString(),
      end_date: plann.fecha_fin.toISOString(),
      isActive: Boolean(plann.isActive),
      institutionId: plann.institucionId,
      business_days: plann.business_days,
      responsibleId: Number(plann.responsible),
    }
    const body = JSON.stringify(planni);
    const apiURL = this.apiURL+"planificacion/"+plann.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchPlanNotificaciones(plann: any): Observable<any>{
    const planni: Planificacion = {
      enableMailReports: plann.enableMailReports,
    }
    const body = JSON.stringify(planni);
    const apiURL = this.apiURL+"planificacion/"+plann.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchPlanEdicion(plann: any): Observable<any>{
    const planni: Planificacion = {
      isActive: plann.isActive,
    }
    const body = JSON.stringify(planni);
    const apiURL = this.apiURL+"planificacion/"+plann.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public postSemaforo(sem: any): Observable<any>{
    const semaf: Semaforos = {
      name: sem.nombre,
      operator_00: sem.operador_00,
      value_00: sem.valor_00,
      value_01: sem.valor_01,
      color: [sem.color],

      planningId: Number(sem.planningId),
    }
    const body = JSON.stringify(semaf);
    const apiURL = this.apiURL+"semaforo";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchSemaforo(sem: any): Observable<any>{
    const semaf: Semaforos = {
      name: sem.nombre,
      operator_00: sem.operador_00,
      value_00: sem.valor_00,
      value_01: sem.valor_01,
      color: [sem.color],

      planningId: Number(sem.planningId),
    }
    const body = JSON.stringify(semaf);
    const apiURL = this.apiURL+"semaforo/"+sem.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deletePlan(plann: any): Observable<any>{
    const apiURL = this.apiURL+"planificacion/"+plann.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteSemaforo(sem: any): Observable<any>{
    const apiURL = this.apiURL+"semaforo/"+sem.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }

  public patchReportIntro(planId: string, reportIntro: string){
    const apiUrl = this.apiURL+"planificacion/"+planId;
    return this.http.patch(apiUrl, {'report_intro': reportIntro}, {headers: this.headers}, );
  }

  public getPlanById(planId: string){
    const apiUrl = this.apiURL+"planificacion/"+planId;
    return this.http.get(apiUrl, {headers: this.headers});
  }
  
}
