<div class="contenedor">
  <div class="w3-row" style="height: 100vh;">
    <div class="w3-col s4 contenedor-left" style="height: 100%;">
      <div class="flex-center">
        <img src="assets/img/logoblanco.png" alt="">
      </div>
      <div class="w3-text-white w3-frase slogan">
        <b>Quien todo lo sabe</b>
      </div>
    </div>
    <div class="w3-col w3-white s8 flex-center" style="height: 100%;">
      <div class="w3-half contenedorRecuper">
        <form (ngSubmit)="onSubmit()" [formGroup]="myForm">
          <mat-card-content fxLayout="column">
            <br>
            <span class="textoTit"><b>Recuperar Contraseña</b></span>
            <mat-form-field appearance="outline" class="col-12 mt-2">
              <input [errorStateMatcher]="matcher" autocomplete="true" formControlName="inputEmail" matInput
                placeholder="Correo electrónico" required type="email">
              <span matPrefix>
                <mat-icon style="color: gray;">face</mat-icon>
              </span>
              <mat-label>&nbsp;&nbsp;&nbsp;Correo electrónico</mat-label>
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Correo electrónico <strong>inválido.</strong>
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                Correo electrónico <strong>es obligatorio.</strong>
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <div class="botones flex-center">
            <a class="botonCancel" mat-button (keydown.enter)="$event.preventDefault()" routerLink='/'>Cancelar</a>
            <button class="w3-button" (keydown.enter)="$event.preventDefault()" type="submit">ENVIAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>