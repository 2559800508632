import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { ProyectosestrategicosService } from './../../../services/seguimiento/proyectosestrategicos/proyectosestrategicos.service';
import Swal from 'sweetalert2';
import { Meses, obtenerError, obtenerTamMax } from '../../../model/datos';
import * as moment from 'moment';

@Component({
  selector: 'app-avance-proyectos',
  templateUrl: './avance-proyectos.component.html',
  styleUrls: ['./avance-proyectos.component.css']
})
export class AvanceProyectosComponent implements OnInit {
  private idPlanificacion = 0;
  //Variables de datos
  public perspectives: any = [];
  public aniosDisponibles: any = [];
  public aniosDisponiblesTot: any = [];
  private semaforo: any = [];
  public objectives: any = [];
  public programas: any = [];
  public responsables: any = [];
  public responsables2: any = [];
  public responsablesAct: any = [];
  public responsablesAct2: any = [];
  public proyectos: any = [];
  private proyectosFin: any = [];
  public actividades: any = [];
  public actividadesFull: any = [];
  public proyectosMostrar: any = [];
  private diasLab: any;
  private edicion: boolean;
  public Periodo = '';
  public mesMos = '';
  public aniMos = '';
  public perspectiva = '';
  public objetivo = '';
  public programa = '';
  public expandirProg = false;

  private porcentajeProyectos = 0;

  //filtros seleccionables
  public perSelect: any;
  public objSelect: any;
  public proSelect: any;
  public mostrarTable = false;
  public mostrarPeriodo =  true;

  // Filtros
  public todasPer = true;
  public todasPerAct = true;

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

/** Lectura de archivos */
  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = [];
  message = '';
  imageName = "";

  public  ordenamiento = 'id';

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private proyectosestrategicosService: ProyectosestrategicosService,
  ) {
    var param = this.rutaActiva.snapshot.params;
    this.idPlanificacion = Number(param['planeacion']);
    this.obtenerDatos();
  }

  ngOnInit(): void {}

  private obtenerDatos(){
    this.proyectosestrategicosService.getPlaneacion().subscribe(response => {
      this.obtenerFiltros(response);
      this.proyectosestrategicosService.getObjectives().subscribe(response =>{
        this.obtObjectives(response);
        this.proyectosestrategicosService.getProyectos().subscribe(response =>{
          this.obtProyectos(response);
          this.proyectosestrategicosService.getActividades().subscribe(response =>{
            this.obtActividades(response);
          });
        });
      });
    });
  }

  private obtenerFiltros(planeacion: any){
    for(const plan of planeacion){
      if(Number(plan.id) === this.idPlanificacion){
        this.edicion = plan.isActive;
        this.diasLab = plan.business_days;
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        this.aniosDisponiblesTot = Meses.obtenerPeriodosFull(new Date(plan.initial_date), new Date(plan.end_date));
        var per = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1]; 
        this.Periodo = per.periodo;
        this.mesMos = per.mes;
        this.aniMos = per.anio;
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            check: false,
            id: per.id,
          })
        }
        this.perspectives = this.ordenamientoId(this.perspectives);
        const sema = this.ordenamientoSem(plan.signals);
        if( Number(sema[sema.length-1].value_01) > 100){
          const valFinal = Number(sema[sema.length-1].value_01); 
          for(const sem of sema){
            this.semaforo.push({
              valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
              valfin: Math.round((Number(sem.value_01)*100)/valFinal),
              color: sem.color[0],
            });
          }      
        }else{
          for(const sem of sema){
            this.semaforo.push({
              valIn: Number(sem.value_00),
              valfin: Number(sem.value_01),
              color: sem.color[0],
            });
          }
        }
      }
    }
  }
  private obtObjectives(array: any){
    var aux = [];
    for(const obj of array){
      for(const per of this.perspectives){
        if(obj.perspective.id === per.id){
          aux.push(obj);
        }
      }
    }
    this.objectives = [];
    this.objectives = this.ordenamientoId(aux);
  }
  private obtProyectos(array: any) {
    var aux = [];
    for(const pro of array){
      for(const prog of this.objectives){
        if(pro.objectives.length !== 0 && pro.objectives[0].id === prog.id){
          aux.push(pro);
        }
      }
    }
    this.proyectos = this.ordenamientoId(aux);
  }
  private obtActividades(array: any){
    var aux = [];
    for(const act of array){
      for(const pro of this.proyectos){
        if(act.project && act.project.id === pro.id){
          var hist = [];
          for(const his of act.history){
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: his.executed,
              id: his.id,
            });
          }
          aux.push({
            additions: [],
            end_date: act.end_date,
            id: act.id,
            initial_date: act.initial_date,
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
          });
        }
      }
    }
    this.actividades = this.ordenamientoId(aux);
    aux = [];
    var aux2 = [];
    for(const pro of this.proyectos){
      for(const act of this.actividades){
        if(pro.id === act.project.id){
          aux2.push(act);
        }
      }
      if(aux2.length){
        aux.push({proyecto: pro, actividades: aux2, check: false,fecha_ini:undefined, fecha_fin: undefined, planeado: [], ejecutado: []});
      }
      aux2 = [];
    }
    aux = this.obtfechasProy(aux);
    this.proyectosFin = aux;
    this.obtenerNumProyectos();
  }
  public obtfechasProy(array: any){
    for(const pro of array){
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for(const act of pro.proyecto.activities){
        var fecha_inicio = this.obtFech(act.initial_date), fecha_fin = this.obtFech(act.end_date)
        if(pro.fecha_ini === undefined){
          pro.fecha_ini = fecha_inicio;
        }else{          
          if(pro.fecha_ini > fecha_inicio){
            pro.fecha_ini = fecha_inicio;
          }
        }
        if(pro.fecha_fin === undefined){
          pro.fecha_fin = fecha_fin;
        }else{
          if(pro.fecha_fin < fecha_fin){
            pro.fecha_fin = fecha_fin;
          }
        }
      }
      pro.ejecutado = Meses.obtenerAvance(pro.fecha_ini, pro.fecha_fin);
      pro.planeado = Meses.obtenerPlaneado(pro.fecha_ini, pro.fecha_fin,this.diasLab);
    }

    return array;
  }
  // Metodos de ordenamiento
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  /* Fechas */
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  /* Filtros */
  public anioChange(per: any){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === per? mes.check = true: mes.check = false;        
        mes.periodo === per? this.Periodo = mes.periodo: '';
        mes.periodo === per? this.mesMos = mes.mes: '';
        mes.periodo === per? this.aniMos = mes.anio : '';
      }
    }
    this.obtenerNumProyectos();
  }

  /* */
  private obtenerNumProyectos(){
    let anio = 0;
    for(const per of this.aniosDisponibles){
      const mesSel = per.series.filter( mes => mes.check );
      if(mesSel.length !== 0){
        anio = per.anio;
      }
    }

    let numPro = 0;

    for(const p of this.proyectosFin){
      let fIn = moment(p.fecha_ini).format("YYYY-MM-DD");
      let fFn = moment(p.fecha_fin).format("YYYY-MM-DD");
      if( moment(fIn).year() <= anio && anio <= moment(fFn).year() ){
        numPro++;
      }
    }
    
     this.porcentajeProyectos = Number((100/numPro).toFixed(2));
     console.log("🚀 ~ this.porcentajeProyectos =>", this.porcentajeProyectos)

  }
  private obtenerPonderaciones(){
    
  }
}
