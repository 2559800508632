import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { AdministradorRoutingModule } from './administrador-routing.module';
import { CatalogosComponent } from './catalogos/catalogos.component';
import { AltaplanificacionesComponent } from '../formulacion/altaplanificaciones/altaplanificaciones.component';


@NgModule({
  declarations: [
    CatalogosComponent,
    AltaplanificacionesComponent,
  ],
  imports: [
    CommonModule,
    AdministradorRoutingModule,
    SharedModule,
  ]
})
export class AdministradorModule { }
