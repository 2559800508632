<!-- an hidden div is created to set the position of appearance of the menu--> 
<div id="menuIzq" style="position: fixed;" 
[style.left]="menuTopLeftPosition.x" 
[style.top]="menuTopLeftPosition.y" 
[matMenuTriggerFor]="rightMenu">
  <!-- standard material menu --> 
  <mat-menu #rightMenu="matMenu"> 
    <ng-template matMenuContent let-item="item"> 

      <button mat-menu-item (click)="deleteArchivo()">
        <mat-icon class="iconoMenVer" [ngStyle]="{'color':'grey'}">delete</mat-icon>
        Eliminar
      </button> 
    </ng-template> 
  </mat-menu> 
</div> 

<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios">
              Periodo
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); cambioAnio(child.periodo)">
                          <mat-checkbox [id]="'check_'+child.anio+child.mes"
                          (change)="cambioAnio(child.periodo);"
                          [checked]="child.check" type="checkbox"
                          (click)="$event.stopPropagation();"
                          aria-label="First checkbox">
                            {{child.mes}}
                          </mat-checkbox>
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado">
                        <span style="margin-left: 5px; margin-right: 5px;">{{mes.periodo}}</span> 
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Perspectivas -->
        <div *ngIf="!dataService.filtradoIndicadores" class="w3-quarter_e padreizquierda" style="margin-right: 1vh;">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer">
              Perspectivas
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <button mat-menu-item (click)="$event.stopPropagation(); selecAllPer()">
                <mat-checkbox (change)="selecAllPer()"
                [checked]="todasPer" type="checkbox"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox">
                  Seleccionar todas
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPer">
                <ng-container *ngFor="let per of perspectives">
                  <button mat-menu-item (click)="$event.stopPropagation(); cambioPer(per)">
                    <mat-checkbox [checked]="per.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    (change)="cambioPer(per)"
                    aria-label="First checkbox">
                      {{per.name}}
                    </mat-checkbox>        
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
        </div>

        <!-- Lider -->
        <div class="w3-rest padreizquierda" style="margin-right: 1vh;">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuLid">
              {{ 'filtros.respon' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuLid="matMenu">
              <button mat-menu-item (click)="$event.stopPropagation(); selectAll()">
                <mat-checkbox [checked]="selAll" type="checkbox"
                (click)="$event.stopPropagation();"
                (change)="selectAll()"
                aria-label="First checkbox">
                {{ 'filtros.selAll' | translate }}
                </mat-checkbox>           
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selAll">
                <ng-container *ngFor="let res of responsables2.filteredData">
                  <button mat-menu-item (click)="$event.stopPropagation(); cambioLider(res)">
                    <mat-checkbox [checked]="res.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    (change)="cambioLider(res)"
                    aria-label="First checkbox">
                      <span matTooltip=" {{res.nombre}} ">{{res.nombre}}</span> 
                    </mat-checkbox>        
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochips">
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="contenedor_total">
      <div class="contenedorBlan">
        <div class="w3-row">
          <div class="titulo">
            <div class="w3-noventa2 w3-titulo-contenidomenuSC">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">Indicadores de gestión</span>
                <span class="w3-margin-left-contenido w3-tituloZonas">&bull;</span>
                <span class="w3-margin-left-contenido w3-subtitulo">{{Periodo}}</span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-right">
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon>
                <mat-menu #menutabla="matMenu">
                    <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                      <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>Exportar xlsx</span>
                    </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-row">
          <div class="tablaArm w3-scroll">
            <div class="w3-row">
              <table class="w3-table borderInf borderDer">
                <thead>
                  <tr class="borderIzq">
                    <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Perspectiva</th>
                    <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Objetivo</th>
                    <th *ngIf="verCodigo" class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup Tfijo">Código</th>
                    <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup Tfijo">Indicador</th>
                    <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Responsable</th>
                    <th class="w3-elemento-titulo-table FijaTit1 alinDer textoOverflow borderSup">Meta</th>
                    <ng-container *ngFor="let an of aniosDisponibles">
                      <ng-container *ngFor="let mes of an.series">
                        <ng-container *ngIf=" mes.check">
                          <th class="w3-elemento-titulo-table FijaTit1 textoOverflow borderSup">
                            <table class="w3-table">
                              <tr>
                                <th class="tresPun">Avance</th>
                              </tr>
                            </table>
                          </th>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <th class="w3-elemento-titulo-table FijaTit1 alinDer textoOverflow borderSup">Rendimiento</th>
                    <ng-container *ngIf="indicadoresMostrar.length !== 0">
                      <ng-container *ngFor="let ad of indicadoresMostrar[0].additions">
                        <th *ngIf="ad.type !== 'texto' && ad.type !== 'moneda' && ad.type !== 'numero'"class="w3-elemento-titulo-table w3-center FijaTit1 textoOverflow borderSup">{{ad.name}}</th>
                        <th *ngIf="ad.type === 'texto' || ad.type === 'numero'" class="w3-elemento-titulo-table FijaTit1 textoOverflow alinIzaq borderSup">{{ad.name}}</th>
                        <th *ngIf="ad.type === 'moneda'" class="w3-elemento-titulo-table FijaTit1 textoOverflow alinDer borderSup">{{ad.name}}</th>
                      </ng-container>
                    </ng-container>
                  </tr>
                </thead>
                <tbody class="borderIzq" *ngFor="let ind of indicadoresMostrar; let i = index">
                  <ng-container>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos">
                      <span matTooltip="{{escribirPer(ind,i)}}">{{escribirPer(ind,i)}}</span>
                    </td>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos">
                      <span matTooltip="{{escribirObj(ind,i)}}">{{escribirObj(ind,i)}}</span>
                    </td>
                    <td *ngIf="verCodigo" [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos fijo">
                      <span matTooltip="Código">Código</span>
                    </td>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos fijo">
                      <span matTooltip="{{ind.nombre}}">{{ind.nombre}}</span>
                    </td>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos">
                      <span matTooltip="{{obtRes(i)}}">{{obtRes(i)}}</span>
                    </td>
                    <ng-container *ngFor="let an of aniosDisponibles">
                      <ng-container *ngFor="let mes of an.series">
                        <ng-container *ngIf=" mes.check">
                          <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan alinDer anchosN">
                            <span matTooltip="{{escribirMeta(mes.periodo, ind)}}">
                              {{escribirMeta(mes.periodo, ind)}}
                            </span>
                          </td>
                          <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="anchosN alinDer">
                            <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirMeta(mes.periodo, ind) != ''" (dblclick)="editar(mes.periodo,ind)"
                                class="textoOverflow w3-elemento-titulo-tableBlan" style="cursor: pointer;">
                                <span *ngIf="escribirReal(mes.periodo, ind) !== 'null'">
                                  <span matTooltip="{{escribirReal(mes.periodo, ind)}}">
                                    {{escribirReal(mes.periodo, ind)}}
                                  </span>
                                </span> 
                                <span *ngIf="escribirReal(mes.periodo, ind) === 'null'" class="textoAyud">
                                  0
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirMeta(mes.periodo, ind) !== ''" class="textoOverflow w3-elemento-titulo-tableBlan">
                                <span *ngIf="escribirReal(mes.periodo, ind) !== 'null'">
                                  <span matTooltip="{{escribirReal(mes.periodo, ind)}}">
                                    {{escribirReal(mes.periodo, ind)}}
                                  </span>
                                </span> 
                                <span *ngIf="escribirReal(mes.periodo, ind) === 'null'" class="textoAyud">
                                  0
                                </span>
                              </div>
                            </ng-container>
                          </td>
                          <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="anchosN alinDer"> 
                            <div [ngClass]="pintarBorde(escribirReal(mes.periodo, ind,'s'),escribirMeta(mes.periodo, ind,'s'), rendimiento(mes.periodo, ind))"
                            class="textoOverflow w3-elemento-titulo-tableBlan">
                              <span>{{rendimiento(mes.periodo, ind)}}</span>
                            </div> 
                          </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let ad of ind.additions">
                      <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type !== 'moneda' && ad.type !== 'archivo' && ad.type !== 'numero'"
                        class="textoOverflow w3-elemento-titulo-tableBlan anchosad" style="cursor: pointer;" (dblclick)="edicionAd(ad, ind.id, $event)">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                      </ng-container>
                      <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type !== 'moneda' && ad.type !== 'archivo'"
                        class="textoOverflow w3-elemento-titulo-tableBlan anchosad">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                      </ng-container>
                      <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type === 'moneda'" 
                        class="textoOverflow w3-elemento-titulo-tableBlan anchosad alinDer">
                        <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                      </td>
                      <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type === 'numero'" 
                        class="textoOverflow w3-elemento-titulo-tableBlan anchosad alinIzq">
                        <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                      </td>
                      <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type === 'archivo'" 
                          class="textoOverflow w3-elemento-titulo-tableBlan alinCen anchosad">
                          <div>
                            <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch" style="cursor: pointer;"
                            (contextmenu)="onContextMenu($event, ad)" (click)="abirPDF(ad, $event);">
                              <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                            </div>
                            <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch" style="cursor: pointer;"
                            (dblclick)="edicionAd(ad,$event)">
                              <mat-icon  matTooltip="Adjuntar archivo" class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                          </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" *ngIf="ad.type === 'archivo'"
                          class="textoOverflow w3-elemento-titulo-tableBlan alinCen anchosad">
                          <div>
                            <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch" style="cursor: pointer;"  (click)="abirPDF(ad, $event);">
                              <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                            </div>
                            <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch">
                              <mat-icon class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                          </div>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w3-row" style="height: 2vh;"></div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="w3-container w3-contenedor-template" *ngIf="indicadoresMostrar.length != 0">
    <div class="w3-row">
      <ng-container>
        <div class="w3-row" style="height: 2vh"></div>
        <div class="w3-row">
          <div [ngClass]="pintarBordeT(porcentajeAvance)" class="tarjetas">
            <div class="textoTarjeta">
              <div class="textoObj">
                <span>
                  Calificación Objetivos
                </span>
              </div>
              <div class="iconoInform">
              </div>
            </div>
            <div class="textoTarjeta2">
              <div style="margin-top: 1.5vh;" *ngIf="porcentajeAvance !== undefined">
                <span [ngClass]="texto(porcentajeAvance)" class="cifrasTarjeta">
                  {{porcentajeAvance}}%
                </span>
              </div>
              <div style="margin-top: 1.5vh;" *ngIf="porcentajeAvance === undefined">
                <span [ngClass]="texto(porcentajeAvance)" class="cifrasTarjeta">
                </span>
                <div class="iconoInform">
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div class="w3-row" style="height: 2vh;"></div>
        <div class="w3-row">
          <ng-container *ngFor="let per of perspectives">
            <div [ngClass]="pintarBordeT(per.avance)" class="tarjetas">
              <div class="textoTarjeta">
                <div class="textoObj">
                  <span #tooltip="matTooltip" matTooltipShowDelay="1000" matTooltip="{{per.name}}">
                    {{per.name}}
                  </span>
                </div>
                <div class="iconoInform">
                </div>
              </div>
              <div class="textoTarjeta2">
                <div style="margin-top: 1.5vh;" *ngIf="per.avance !== undefined">
                  <span [ngClass]="texto(per.avance)" class="cifrasTarjeta">
                    {{per.avance}}%
                  </span>
                </div>
                <div style="margin-top: 1.5vh;" *ngIf="per.avance === undefined">
                  <span [ngClass]="texto(per.avance)" class="cifrasTarjeta">
                  </span>
                  <div class="iconoInform">
                  </div>
                </div>
              </div> 
            </div>
          </ng-container>
          <div class="w3-row" style="height: 2vh;"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div id="cargando_arch" class="w3-modal">
  <div class="contenedorCargando" style="width: 25%; height: 25%;">
    <div class="iconoCarga spinner-border text-secondary"></div>
  </div>
</div>