import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermisosSubmodulosGuard } from 'src/app/guards/permisos-submodulos/permisos-submodulos.guard';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { PlanestrategicoComponent } from './planestrategico/planestrategico.component';
import { AvanceseguimientoComponent } from './avanceseguimiento/avanceseguimiento.component';
import { AvanceSegimientoFinComponent } from './avance-segimiento-fin/avance-segimiento-fin.component';

const routes: Routes = [
  {
    path: 'app/reportes/:idplan/:planNom',
    component: WelcomeComponent,
    children: [
      { path: 'reporteseguimiento/:planeacion', component: AvanceSegimientoFinComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'planestrategico/:planeacion', component: PlanestrategicoComponent,canActivate: [PermisosSubmodulosGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportesRoutingModule { }
