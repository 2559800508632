import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Indicadores, Metas, Perspectivas, alertas, Adicionales, Unidad_Medida, Meses, Areas, procesamiento, obtenerError} from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { IndicadoresService } from '../../../services/formulacion/indicadores/indicadores.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

function wait(espera_segundos) {
  var espera = espera_segundos * 1000
  const tiempo_inicio = Date.now();
  let tiempo_actual= null;
  do {
    tiempo_actual= Date.now();
  } while (tiempo_actual - tiempo_inicio < espera);
}
@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
  
  public periodicidad: any = [];
  public data: any = [];
  public perspectivas: Perspectivas[] = [];
  public objetivos: any[] = [];
  public areas: Areas[] = [];

  public tipSelect: any;

  public diccionarioAdicionales: any = [];
  public unidades_med: Unidad_Medida[] = [];

  public aniosDisponibles = [ {name: '2022', check: true}, {name: '2023', check: false},]
  public meses = [{name: 'ene'}, {name: 'feb'}, {name: 'mar'}, {name: 'abr'}, {name: 'may'}, {name: 'jun'}, {name: 'jul'}, {name: 'ago'}, {name: 'sep'}, {name: 'oct'}, {name: 'nov'}, {name: 'dic'}];
  public idPers: number = 0;
  public idObj: number = 0;
  public indic: any;
  public indicadoresAll: any[] = [];
  public indicadores: any[] = [];

  public metas: any[] = [];
  public adicionales: Adicionales[] = [];
  public displayedColumns: string[] = ['obj', 'pon', 'ind', 'par', 'lin', 'met', 'fre'];
  public tenden = [{id: '1', name: 'Ascendente'},{id: '-1', name: 'Descendente'}]; /** , {id: '0', name: 'Indistinto'} */
  public dataSource = [];

  public ObjSelect: any;
  public PerSelect: any;
  public UniSelect: any;
  public AreSelect: any;
  public TenSelect: any;
  public modal = false;
  public modal2 = false;
  public modal3 = false;
  public modal4 = false;
  public posMet = [];

  public selectAll = true;

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  minDate = new Date(2023, 1, 1);
  maxDate = new Date(2023, 11, 31);

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  private set = {
    inputCodigo: '',
    inputNameInd: '',
    inputDescription: '',
    inputCalculation: '',
    inputObjective: '',
    inputFechaIn: '',
    inputFechaFn: '',
    inputParam: '',
    inputBaseLine: '',
    inputGlobalGoal: '',
    inputPeriodicidad: '',
    inputUnidadMed: '',
    inputTendencia: '',
    inputArea: '',
    inputNameAdi: ''
  } 

  /** Grupo de validación de datos */
  formIndicador = new FormGroup({ 
    inputCodigo: new FormControl(''),
    inputNameInd: new FormControl('', [Validators.required]),
    inputDescription: new FormControl(''),
    inputCalculation: new FormControl('', [Validators.required]),
    inputObjective: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputParam: new FormControl(''),
    inputBaseLine: new FormControl('', [Validators.required]),
    inputGlobalGoal: new FormControl('', [Validators.required]),
    inputPeriodicidad: new FormControl('', [Validators.required]),
    inputUnidadMed: new FormControl('', [Validators.required]),
    inputTendencia: new FormControl('', [Validators.required]),
    inputArea: new FormControl('', [Validators.required]),

    inputNameAdi: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });
  formIndicadorEdit = new FormGroup({ 
    inputCodigo: new FormControl(''),
    inputNameInd: new FormControl('', [Validators.required]),
    inputDescription: new FormControl(''),
    inputCalculation: new FormControl('', [Validators.required]),
    inputObjective: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputParam: new FormControl(''),
    inputBaseLine: new FormControl('', [Validators.required]),
    inputGlobalGoal: new FormControl('', [Validators.required]),
    inputPeriodicidad: new FormControl('', [Validators.required]),
    inputUnidadMed: new FormControl('', [Validators.required]),
    inputTendencia: new FormControl('', [Validators.required]),
    inputArea: new FormControl('', [Validators.required]),

    inputNameAdi: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  public idPlanificacion :any;
  public verCodigo = false;
  constructor(
    private router: Router,
    public dataService: DataService,
    private indicadoresService: IndicadoresService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
    private translate: TranslateService
  ) {
    this.obtData();
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param["planeacion"]);
  }

  ngOnInit(): void {
  }
  /* Correccion errores */
  private eliminacionMetasDup(){
    var eliminacion = [];
    for(const ind of this.indicadoresAll){
      for(const met of ind.metas){
        const ex = ind.metas.filter(m => m.nombre.getTime() === met.nombre.getTime());
        ex.sort(function (a: any, b: any) {
          if (Number(a.id) > Number(b.id)) {
            return 1;
          }
          if (Number(a.id) < Number(b.id)) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        if(ex.length === 2 && (Number(ex[0].real) === Number(ex[1].real) || Number(ex[1].real) === null) ){
          const agreg = eliminacion.filter( m => Number(m.id) === Number(ex[1].id));
          if(agreg.length === 0){
            eliminacion.push(ex[1]);
          }
        }else if(ex.length >= 2){
          for(var i=1; i<ex.length; i++){
            if((Number(ex[0].real) === Number(ex[i].real) || ex[i].real === null) ){
              eliminacion.push(ex[i]);
            }
          }
        }
        if(eliminacion.length !== 0){
        }
        for(const met of eliminacion){        
          this.indicadoresService.deleteMetas(met).subscribe(response =>{
          });
          /*if(ex.length === 2){
          }*/
        }
        if(eliminacion.length !== 0){
          eliminacion = [];
          break;
        }
        eliminacion = [];
      }
    }
  }
  /** Metodo de escritura de cantidades */
  public formatearnormal(num: any){
    num = Number(Number(num).toFixed(2));
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight === '.0')
      return  splitLeft;
    return  splitLeft+splitRight;
  }
  /** Metodos de ordenamiento */
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoInd(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objetiveId) > Number(b.objetiveId)) {
        return 1;
      }
      if (Number(a.objetiveId) < Number(b.objetiveId)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAdi(array: any){
    array.sort(function (a: any, b: any) {
      if ((a.name) > (b.name)) {
        return 1;
      }
      if ((a.name) < (b.name)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  public ordIndi(array: any){
    array.sort(function(a: any,b: any){
      if(a.objetiveId > b.objetiveId)
        return 1
      if(a.objetiveId < b.objetiveId)
        return -1
      return 0
    });
    return array;
  }
  /** Obtención de los datos */
  public obtData(){
    this.indicadoresService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
            //this.verCodigo = true;
          }
          const fechaini = new Date(plan.initial_date);
          const fechafin = new Date(plan.end_date);
          
          switch(plan.business_days){
            case 'Lunes-Viernes':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return day !== 0 && day !== 6;
              };
            break;
            case 'Lunes-Sabado':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return day !== 0;
              };
            break;
            case 'Lunes-Domingo':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return true;
              };
            break;
          }
          this.aniosDisponibles = [];
          for(var i = fechaini.getFullYear(); i <= fechafin.getFullYear(); i++){
            this.aniosDisponibles.push({
              name: ''+i,
              check: false,
            });
          }
          this.aniosDisponibles[0].check = true;
          this.minDate = fechaini;
          this.maxDate = fechafin;

          this.indicadoresService.getAreas().subscribe(response =>{
            this.areas = [];
            for(const are of response){
              if(plan.institution.id === are.institution.id){
                this.areas.push({
                  id: are.id,
                  nombre: are.name,
                });
              }
            }
            this.areas = this.ordenamientoPer(this.areas);
          });
        }
      }
    });
    this.indicadoresService.getPerspectivas().subscribe(response => {
      this.perPers(response);
      this.perspectivas =  this.ordenamientoPer(this.perspectivas);
      this.objetivos = this.ordenamientoPer(this.objetivos);
      this.indicadoresService.getUnides().subscribe(response => {
        this.unidades_med = [];
        for(const uni of response){
          this.unidades_med.push({
            nombre: uni.name,
            id: uni.id,
          });
        }
        this.unidades_med = this.ordenamientoPer(this.unidades_med);
      });
      this.indicadoresService.getIndicadores().subscribe(response => {
        this.indPers(response);
      });
    });
  }
  /** Tratado y filtrado de los datos */
  public perPers(array: any){
    var aux = [], aux2 = [];
    for(const per of array){
      if(Number(per.planning.id) === Number(this.idPlanificacion)){        
        aux.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: per.planning.id,
          id: per.id,
          check: true,
        });
        for(const obj of per.objectives){
          aux2.push({
            nombre: obj.name,
            descripcion: obj.description, 
            ponderacion: obj.weighing, 
            perspectiveId: per.id,
            perspectiva: per, 
            id: obj.id
          })
        }
      }
    }
    this.perspectivas = [];
    this.objetivos = [];
    this.perspectivas = aux;
    this.objetivos = aux2;
  }
  public indPers(array: any){
    var aux = [], aux2 = [];
    for(const ind of array){
      for(const obj of this.objetivos){
        if(obj.id === ind.objective.id){
          var metas = [];
          for(const met of ind.goals){
            const date = this.obtFech(met.period);
            aux2.push({
              id: met.id,
              nombre: date,
              descripcion: met.value,
              real: met.real,
              indicatorId: ind.id,
            });
            metas.push({
              id: met.id,
              nombre: date,
              descripcion: met.value,
              real: met.real,
            });
          }
          aux.push({
            id: ind.id,
            nombre: ind.name,
            descripcion: ind.description,
            calculo: ind.calculation,
            ponderacion: ind.weighing,
            objetiveId: ind.objective.id,
            objective: ind.objective,
            linea_base: ind.baseline,
            fecha_inicio: new Date(ind.initial_date),
            fecha_fin: new Date(ind.end_date),
            periodicidad: ind.periodicity,
            meta_global: ind.globalGoal,
            measureId: ind.measure.id,
            areaId: ind.area.id,
            area: ind.area,
            tendencia: ind.tendency,
            parametro: ind.param_type,
            adadicionales: this.ordenamientoAdi(ind.additions),
            perspective: this.perspectiva(ind.objective.id),
            metas: metas,
          });
        }
      }
    }
    this.indicadoresAll = [];
    this.metas = [];

    this.indicadoresAll = aux;
    this.metas = aux2;

    this.indicadoresAll = this.ordenamientoPer(this.indicadoresAll);
    this.indicadoresAll = this.ordenamientoInd(this.indicadoresAll);

    //this.eliminacionMetasDup();
    
    this.metas = this.ordenamientoPer(this.metas);
    
    this.obtDataMostrar();
  }
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  /** Metodo de envio al guardado */
  public alertas(aler: any, indi?: any){
    if(aler === 'editar'){
      var linbas: any = this.formIndicadorEdit.controls['inputBaseLine'].value;///
      var meta: any = this.formIndicadorEdit.controls['inputGlobalGoal'].value;///
      var fecIn: any = new Date((this.formIndicadorEdit.controls['inputFechaIn'].value));
      var fecFin: any = new Date(this.formIndicadorEdit.controls['inputFechaFn'].value);

      var per = this.formIndicadorEdit.controls['inputPeriodicidad'].value;
      var text = '', i=0;      
      this.metas.forEach(met => { if(met.indicatorId === this.indic.id){i++;} })
      if((per != this.indic.periodicidad || (fecIn - this.indic.fecha_inicio) !== 0 || (fecFin - this.indic.fecha_fin) !== 0) && i != 0 ){//|| linbas != this.indic.linea_base
        text = 'Se borrarán las metas creadas';
      }

      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: ""+text,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if(text != ''){
            var aux: Metas[] = [];
            for(const met of this.metas){
              if(met.indicatorId === this.indic.id){
                this.indicadoresService.deleteMetas(met).subscribe(response => {
                  
                },err =>{
                  let er = obtenerError.errorObt(err['error'].message);
                  Swal.fire({
                    title: er,
                    text: '',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo,
                  });
                });
              }
            }
            Swal.fire({
              title: 'Metas eliminadas',
              text: '',
              icon: 'success',
              showConfirmButton: false,
              timer: this.dataService.tiempo
            });
          }
          if(meta !== this.indic.meta_global && this.indic.metas.length !== 0){
            this.indic.metas.sort(function (a: any, b: any) {
              if(a.nombre !== undefined && b.nombre !== undefined){
                if (a.nombre.getTime() > b.nombre.getTime()) {
                  return 1;
                }
                if (a.nombre.getTime() < b.nombre.getTime()) {
                  return -1;
                }
              }
              return 0;
            });
            this.indic.metas[this.indic.metas.length-1].descripcion = meta;
            this.indicadoresService.patchMetas(this.indic.metas[this.indic.metas.length-1]).subscribe(response => {
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
            wait(1)
          }
          this.guardarEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'eliminar'){
      var text = '', i=0;
      this.metas.forEach(met => { if(met.indicatorId === indi.id){i++;} })
      if(i != 0 ){
        text = 'Se borrarán las metas creadas';
      }
      Swal.fire({
        title: '¿Seguro que deseas eliminar el indicador?',
        text: ""+text,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          for(const met of this.metas){
            if(met.indicatorId === indi.id){
              this.indicadoresService.deleteMetas(met).subscribe(response => {
                Swal.fire({
                  title: 'Información eliminada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          this.eliminarInd(indi);
        }
      });
    }
  }
  /** Almacenamiento de los datos */
    // Nuevo indicador
  public almacenarD(){
    var indicador = new Indicadores();
    var codigo = document.getElementById('codigo')["value"];
    if(indicador.descripcion === ''){
      codigo = ' ';
    }
    indicador.nombre = document.getElementById('indicador')["value"];
    indicador.descripcion = document.getElementById('descripcion')["value"];
    if(indicador.descripcion === ''){
      indicador.descripcion = ' ';
    }
    indicador.calculo = document.getElementById('calculo')["value"];
    //indicador.ponderacion = document.getElementById('ponderacion')["value"];
    indicador.objetiveId = Number(this.formIndicador.controls['inputObjective'].value);///
    indicador.parametro =  document.getElementById('parametro')["value"];
    if(indicador.parametro === ''){
      indicador.parametro = ' ';
    }
    indicador.linea_base = document.getElementById('lineaB')["value"];
    indicador.fecha_inicio = new Date(this.formIndicador.controls['inputFechaIn'].value);///
    indicador.fecha_fin = new Date(this.formIndicador.controls['inputFechaFn'].value);///
    indicador.meta_global = document.getElementById('meta')["value"];
    indicador.periodicidad = this.formIndicador.controls['inputPeriodicidad'].value;///
    indicador.measureId = this.formIndicador.controls['inputUnidadMed'].value;///
    indicador.tendencia = this.formIndicador.controls['inputTendencia'].value;///
    indicador.areaId = this.formIndicador.controls['inputArea'].value;///

    this.indicadoresService.postIndicadores(indicador).subscribe(response => {
      const indNuevo = response;
      this.indicadoresService.getIndicadores().subscribe(response => {
        if(this.indicadores[0].adadicionales.length !== 0){
          alertas.swalEspera('Guardando indicador');
          const cant = this.indicadores[0].adadicionales.length;
          var alta = 0;
          for(const ad of this.indicadores[0].adadicionales){
            var adic = new Adicionales();
            adic.nombre = ad.name;
            adic.tipo = ad.type;
            adic.indicadorId = indNuevo.id;
            adic.nota = '';
            adic.archivos = '';
            adic.monto = 0;

            this.indicadoresService.postAdicionales(adic).subscribe(response =>{
              this.indicadoresService.getIndicadores().subscribe(response => {
                this.indPers(response);
                alta++;
                if(alta >= cant){
                  Swal.close();
                  Swal.fire({
                    title: 'Información agregada',
                    text: '',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo
                  });
                }
              });
            },err =>{
              Swal.close();
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            })
          }
        }else{
          this.indPers(response);
          Swal.fire({
            title: 'Información agregada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        }
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.closeModal();
  }
    // Metas
  public async almacenarMet(){
    this.modal = false;
    var totalAccionesEchas = 0;
    this.closeModal();
    Swal.fire({
      title: this.translate.instant('swal.elimMet'),
      html: this.translate.instant('swal.espe'),
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });
    for(var i = 0; i < this.posMet.length; i++){
      this.posMet[i].descripcion =  document.getElementById('meta'+i)["value"];
    }
    var cumplio = 0, min = Number(this.indic.linea_base), max = Number(this.indic.meta_global), text = '';
    if(cumplio === 0){
      var en = 0, totalAcciones = 0;
      for(const pmet of this.posMet){
        for(const met of this.metas){
          if(met.nombre === pmet.nombre && met.indicatorId === pmet.indicatorId && pmet.descripcion !== ''){
            totalAcciones++;
            en++;
          }else if(met.nombre === pmet.nombre && met.indicatorId === pmet.indicatorId && pmet.descripcion === ''){
            totalAcciones++;
          }
        }
        if (en === 0 && pmet.descripcion !== ''){
          totalAcciones++;
        }
        en = 0;
      }
      en=0;
      
      for(const pmet of this.posMet){
        for(const met of this.metas){
          if(met.nombre === pmet.nombre && met.indicatorId === pmet.indicatorId && pmet.descripcion !== ''){
            met.descripcion = pmet.descripcion;
            this.indicadoresService.patchMetas(met).subscribe(response => {
              totalAccionesEchas++;
              if(totalAccionesEchas === totalAcciones){
                wait(0.2);
                this.indicadoresService.getIndicadores().subscribe(response => {
                  this.indPers(response);
                  Swal.fire({
                    title: 'Información agregada',
                    text: '',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo
                  });
                });
              }
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
            en++;
            wait(0.2);
            break;
          }else if(met.nombre === pmet.nombre && met.indicatorId === pmet.indicatorId && pmet.descripcion === ''){
            if(met.real !== null){
              Swal.close();
              Swal.fire({
                title: '',
                text: '',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
                didOpen: () => {
                  Swal.hideLoading();
                },
              });
              wait(0.2);
              await Swal.fire({
                title: '¿Seguro que desea eliminar la meta de '+this.nomMes(met.nombre)+'?',
                text: "Esta meta contiene datos cargados en seguimiento",
                showCancelButton: true,
                confirmButtonColor: '#ff6b00',
                cancelButtonColor: '#B9B9BC',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.indicadoresService.deleteMetas(met).subscribe(response =>{
                    totalAccionesEchas++;
                    if(totalAccionesEchas === totalAcciones){
                      this.indicadoresService.getIndicadores().subscribe(response => {
                        this.indPers(response);
                        Swal.fire({
                          title: 'Información agregada',
                          text: '',
                          icon: 'success',
                          showConfirmButton: false,
                          timer: this.dataService.tiempo
                        });
                      });
                    }
                  },err =>{
                    let er = obtenerError.errorObt(err['error'].message);
                    Swal.fire({
                      title: er,
                      text: '',
                      icon: 'error',
                      showConfirmButton: false,
                      timer: this.dataService.tiempo,
                    });
                  });
                  wait(0.2);
                }else{
                  totalAccionesEchas++;
                  Swal.fire({
                    title: this.translate.instant('swal.eval'),
                    html: this.translate.instant('swal.espe'),
                    showCancelButton: false,
                    showConfirmButton: false,
                    didOpen: () => {
                      Swal.showLoading(undefined)
                    },
                  });
                }
              });
            }else{
              this.indicadoresService.deleteMetas(met).subscribe(response =>{
                totalAccionesEchas++;
                if(totalAccionesEchas === totalAcciones){
                  this.indicadoresService.getIndicadores().subscribe(response => {
                    this.indPers(response);
                    Swal.fire({
                      title: 'Información agregada',
                      text: '',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: this.dataService.tiempo
                    });
                  });
                }
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
              wait(0.2);
            }
          }
        }
        if (en === 0 && pmet.descripcion !== ''){
          
          this.indicadoresService.postMetas(pmet).subscribe(response => {
            totalAccionesEchas++;
            if(totalAccionesEchas === totalAcciones){
              this.indicadoresService.getIndicadores().subscribe(response => {
                this.indPers(response);
                Swal.fire({
                  title: 'Información agregada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
          wait(0.2);
        }
        en = 0;
      }
    }else{
      if(text === ''){
        text = 'Ingrese las metas correctamente';
      }
      Swal.fire({
        html: ''+text,
        text: '',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Regresar'
      });
    }
  }
    // Edición de indicador
  public guardarEdit(){
    this.indic.nombre = this.formIndicadorEdit.controls['inputNameInd'].value;///
    this.indic.descripcion = this.formIndicadorEdit.controls['inputDescription'].value;///
    if(this.indic.descripcion === ''){
      this.indic.descripcion = ' ';
    }
    this.indic.calculo = this.formIndicadorEdit.controls['inputCalculation'].value;///
    //this.indic.ponderacion = document.getElementById('ponderacionEdit')["value"];
    this.indic.objetiveId = Number(this.formIndicadorEdit.controls['inputObjective'].value);///
    this.indic.parametro =  this.formIndicadorEdit.controls['inputParam'].value;
    if(this.indic.parametro === ''){
      this.indic.parametro = ' ';
    }
    this.indic.linea_base = ''+this.formIndicadorEdit.controls['inputBaseLine'].value;///
    this.indic.fecha_inicio = new Date((this.formIndicadorEdit.controls['inputFechaIn'].value));
    this.indic.fecha_fin = new Date(this.formIndicadorEdit.controls['inputFechaFn'].value);
    this.indic.meta_global = this.formIndicadorEdit.controls['inputGlobalGoal'].value;///
    this.indic.periodicidad = this.formIndicadorEdit.controls['inputPeriodicidad'].value;///
    this.indic.measureId = this.formIndicadorEdit.controls['inputUnidadMed'].value;///
    this.indic.tendencia = this.formIndicadorEdit.controls['inputTendencia'].value;///
    this.indic.areaId = this.formIndicadorEdit.controls['inputArea'].value;///

    this.indicadoresService.patchIndicadores(this.indic).subscribe(response =>{
      this.indicadoresService.getIndicadores().subscribe(response => {
        this.indPers(response); 
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.closeModal();
  }
    // Obtener comentarios
  private obtComment(com : any){
    for(const comen of com){
      var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
    //  Impresión de los adicionales 
  public imprimir(ad: any){
    switch(ad.type){
      case 'texto': return ad.note;
      case 'moneda': return this.formatearnormal(ad.amount);
      case 'numero': return this.obtComment(ad.comments);
      case 'archivo': return ad.files;
    }
  } 
    // Adicionales
  public guardarAdi(){
    var name = document.getElementById('nomAdi')["value"];
    var tipo = this.tipSelect;
    var adidionales = [];
    const total = this.indicadoresAll.length;
    var altas = 0;
    for(const ind of this.indicadoresAll){
      alertas.swalEspera('Agregando adicionales');
      var adic = new Adicionales();
      adic.nombre = name;
      adic.tipo = tipo;
      adic.indicadorId = ind.id;
      switch(tipo){
        case 'texto': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'moneda': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'numero': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'archivo': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
      }
      adidionales.push(adic);
      this.indicadoresService.postAdicionales(adic).subscribe(response =>{
          altas++;
          if(altas >= total){
            this.indicadoresService.getIndicadores().subscribe(response => {
              this.indPers(response);
              Swal.close();
              Swal.fire({
                title: 'Información agregada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
            });
          }  
      },err =>{
        Swal.close();
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
    this.diccionarioAdicionales.push({
      nombre: name,
      tipo: tipo,
      adicionales: adidionales,
    })
    this.closeModal();
  }
    // Agregar adicional
  public agregarAdicional(){
    this.modal3 = true;
    document.getElementById('adicional')!.style.display = 'block';
  }
  /** Metodos de decición del guardado */
  public puede_guardar(){
    let nombre = document.getElementById('indicador')["value"];
    let calculo = document.getElementById('calculo')["value"];
    let objetiveId = Number(this.formIndicador.controls['inputObjective'].value);///
    let linea_base = document.getElementById('lineaB')["value"];
    let fecha_inicio = (this.formIndicador.controls['inputFechaIn'].value);///
    let fecha_fin = (this.formIndicador.controls['inputFechaFn'].value);///
    let meta_global = document.getElementById('meta')["value"];
    let periodicidad = this.formIndicador.controls['inputPeriodicidad'].value;///
    let measureId = this.formIndicador.controls['inputUnidadMed'].value;///
    let tendencia = this.formIndicador.controls['inputTendencia'].value;///
    let areaId = this.formIndicador.controls['inputArea'].value;///
    
    if(nombre !== '' && calculo !== '' && objetiveId !== 0 && linea_base !== '' && fecha_inicio !== '' && fecha_fin !== '' &&
    periodicidad !== '' && meta_global !== '' && measureId !== '' && tendencia !== '' && areaId !== '' && !this.nombreExiste()
    && this.formIndicador.controls['inputFechaFn'].status === 'VALID'){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    let nombre = this.formIndicadorEdit.controls['inputNameInd'].value;///
    let calculo = this.formIndicadorEdit.controls['inputCalculation'].value;///
    let objetiveId = Number(this.formIndicadorEdit.controls['inputObjective'].value);///
    let linea_base = this.formIndicadorEdit.controls['inputBaseLine'].value;///
    let fecha_inicio = (this.formIndicadorEdit.controls['inputFechaIn'].value);///
    let fecha_fin = (this.formIndicadorEdit.controls['inputFechaFn'].value);///
    let meta_global = this.formIndicadorEdit.controls['inputGlobalGoal'].value;///
    let periodicidad = this.formIndicadorEdit.controls['inputPeriodicidad'].value;///
    let measureId = this.formIndicadorEdit.controls['inputUnidadMed'].value;///
    let tendencia = this.formIndicadorEdit.controls['inputTendencia'].value;///
    let areaId = this.formIndicadorEdit.controls['inputArea'].value;///
    
    if(nombre !== '' && calculo !== '' && objetiveId !== 0 && linea_base !== null && fecha_inicio !== '' && fecha_fin !== '' &&
    periodicidad !== '' && meta_global !== null && measureId !== '' && tendencia !== '' && areaId !== '' && !this.nombreExisteEdit()
    && this.formIndicadorEdit.controls['inputFechaFn'].status === 'VALID'){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarAdi(){
    var adicional = new Adicionales();
    adicional.nombre = document.getElementById('nomAdi')["value"];
    adicional.tipo = this.tipSelect;
    if(adicional.nombre != '' && adicional.tipo != undefined && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Metodo de edición del adicional */
  public async editar(adi: any, id:any){
    var inputValue: any, introdujo: any, guardo = false;
    switch(adi.type){
      case 'texto': inputValue = adi.note; break;
      case 'moneda': inputValue  = adi.amount; break;
      case 'numero': inputValue  = this.obtComment(adi.comments); break;
      case 'archivo': inputValue  = adi.files; break;
    }
    switch(adi.type){
      case 'moneda':
        introdujo = await Swal.fire({
          title: 'Ingresa el valor',
          text: "",
          input: 'number',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingeresa un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break; 
      case 'numero': 
        introdujo = await Swal.fire({
          title: 'Ingresa el valor',
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingeresa un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }
    if(guardo){
      switch(adi.type){
        case 'texto': adi.note = introdujo.value; break;
        case 'moneda':
          adi.amount = introdujo.value;
          this.indicadoresService.patchAdicionales(adi,id).subscribe(response =>{
            this.indicadoresService.getPerspectivas().subscribe(response => {
              this.perPers(response);
              this.perspectivas =  this.ordenamientoPer(this.perspectivas);
              this.objetivos = this.ordenamientoPer(this.objetivos);
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
            });
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        break;
        case 'numero':
          adi.note = introdujo.value;
          var existe = false;
          var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
          for(const comen of adi.comments){
            var percom = new Date(comen.created_at).getFullYear();
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.indicadoresService.patchComment(comen, adi.id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          if(!existe){
            var date = new Date('1'+'-1-'+Number(this.aniosDisponibles.filter(an => an.check)[0].name));
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString(),
              id: 0,
            }
            this.indicadoresService.postComment(aux, adi.id).subscribe(response =>{
              aux.id = response.id;
              adi.comments.push(aux);
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        break;
        case 'archivo': adi.files = introdujo.value; break;
      }
      
    }else {
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  /** Metodos de escritura y formato den tabla */
    // Escritura de los datos
  public escribiObj(obj: any,ind: any, i: any){
    if(this.indicadores.length === 1){
      return obj;
    }else{
      if(i!=0){
        if(Number(ind.objetiveId) != Number(this.indicadores[i-1].objetiveId))
          return obj;
      }else if(i === 0){
        return obj;
      }
    }
    return '';
  }
  public ultVal(pos: any){
    if(pos === this.posMet.length-1)
      return 'w3-disabled';
    return '';
  }
  public escrituraMet(mes: any, id: any, ind: any){
    for(const met of ind.metas){
      var anio = met.nombre?.getFullYear();
      if(Meses.getMeses(met.nombre) === mes && this.anioig(anio)){
        return ''+this.formatearnormal(met.descripcion);
      }
    }
    return '';
  }
  // Metodos de activos
  public isActivate(){
    if( this.aniosDisponibles.length === 0 )
      return 'w3-disabled w3-quarter_e hijoizquierda'
    return 'w3-quarter_e hijoizquierda'
  }
  public isActivate2(){
    if( this.perspectivas.length === 0 )
      return 'w3-disabled w3-quarter_e hijoizquierda'
    return 'w3-quarter_e hijoizquierda'
  }
  /* Validar el año */
  private perteneceAlAnio(ind: any){
    var anioIn = ind.fecha_inicio.getFullYear(), anioFn = ind.fecha_fin.getFullYear();
    var anioAc = Number(this.aniosDisponibles.filter(an => an.check)[0].name);
    if(Meses.compararAnio(anioIn, anioFn, anioAc)){
      return true;
    }
    return false;
  }
  private aniofin(){
    var i = 0
    for(const ind of this.indicadoresAll){
      if(ind.fecha_fin.getFullYear() !== this.maxDate.getFullYear()){
        ind.fecha_fin = this.maxDate;
        this.indicadoresService.patchIndicadoresFechaFin(ind).subscribe(response=>{

        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        }); 
      }
      i++;
    }
  }
  /** Datos a mostrar */
  private obtDataMostrar(){
    this.indicadores = [];
    for(const ind of this.indicadoresAll){
      if(this.perteneceAlAnio(ind)){
        this.indicadores.push(ind);
      }
    }
  }
  public mostrarPers(ind: any){
    var anio: any, anioInd = ind.fecha_fin.getFullYear();
    for(const an of this.aniosDisponibles){
      if(an.check){
        anio = an.name;
        break;
      }
    }
    if(anio <= anioInd){
      return true;
    }
    return false;
  }
  private perspectiva(objetiveId){
    for(const obj of this.objetivos){
      if(Number(obj.id) === Number(objetiveId)){
        return obj.perspectiva.name;
      }
    }
  }
  /** Filtros */
  public anioig(an: any){
    for(const ani of this.aniosDisponibles){
      if(ani.check && Number(ani.name) === an){
        return true;
      }
    }
    return false;
  }
  public cambioPer(per: any){
    for(const pers of this.perspectivas){
      if(per.id === pers.id){
        pers.check = !pers.check;
      }
    }
  }
  public cambioAnio(anio: any) {
    this.aniosDisponibles.forEach(an => { an.name === anio.name? an.check=true:an.check=false });
    this.obtDataMostrar();
  }
  public seleccionarTodo(){
    for(const pers of this.perspectivas){
      if(this.selectAll){pers.check = false;}
      else {pers.check = true;}
    }
    this.selectAll = !this.selectAll;
  }
  /** Mostrar tablas */
  public mostrarPer(per: any){
    if(this.selectAll){
      return true;
    }
    var numSelec = 0;
    for(const per of this.perspectivas){
      if(per.check){
        numSelec ++;
      }
    }
    if(numSelec === 0){
      return true;
    }
    return per.check;
  }
  /** Modales */
    // Metas
  public ageMet(idIn: any){
    this.indic = idIn;
    var per = 0;
    switch(this.indic.periodicidad){
      case 'mensual': per = 1; break;
      case 'bimestral': per = 2; break;
      case 'trimestral': per = 3; break;
      case 'cuatrimestral': per = 4; break;
      case 'semestral': per = 6; break;
      case 'anual': per = 12; break;
    }
    var year1 = this.indic.fecha_inicio.getFullYear(), year2 = this.indic.fecha_fin.getFullYear(), mes1 = this.indic.fecha_inicio.getMonth(), mes2 = this.indic.fecha_fin.getMonth();
    var meses = (12-mes1) + (12*(year2 - year1 - 1)) + (mes2+1); 
    this.posMet = [];
    var mesN, en = 0, mess, i = 0;
    for(i =  mes1+per; i <= mes1+meses; i+=per){
      if(i%12 != 0){
        var anio = ''+(year1+Math.trunc(i/12));
        mesN = Meses.getMeses((i%12)+'/'+1+'/'+year1);
        mess = i%12;
      }else{
        var anio = ''+(year1+Math.trunc(i/12)-1);
        mesN = Meses.getMeses(12+'/'+1+'/'+year1);
        mess = 12;
      }
      for(const met of this.metas){
        if( (Meses.getMeses(met.nombre)+'-'+met.nombre?.getFullYear()) === (mesN+'-'+anio) && met.indicatorId === this.indic.id){
          this.posMet.push({
            nombre: met.nombre,
            descripcion: Number(met.descripcion),
            indicatorId: met.indicatorId,
          });
          en++;
          break;
        }
      }
      if(en === 0){
        this.posMet.push({
          nombre: new Date(mess+'/'+1+'/'+anio),
          descripcion: '',
          indicatorId: this.indic.id,
        });
      }
      en=0;
    }
    if((meses%per)!=0){
      var anio = ''+(year1+Math.trunc(i/12)-1);
      mesN = Meses.getMeses((mes2+1)+'/'+1+'/'+year2);
      for(const met of this.metas){
        if(Meses.getMeses(met.nombre) === mesN && met.indicatorId === this.indic.id){
          this.posMet.push({
            nombre: met.nombre,
            descripcion: met.descripcion,
            indicatorId: met.indicatorId,
          });
          en++;
        }
      }
      if(en === 0){
        this.posMet.push({
          nombre: new Date((mes2+1)+'/'+1+'/'+year2),
          descripcion: '',
          indicatorId: this.indic.id,
        });
      }
    }
    this.posMet[this.posMet.length-1].descripcion = this.indic.meta_global;
    document.getElementById('agregarMet')!.style.display = 'block'
    this.modal4 = true;
  }
    // indicadores
  public open_modal(per: any){
    this.idPers = per.id;
    this.formIndicador.setValue(this.set);
    this.formIndicador.clearValidators();
    var mesfechfin = (this.minDate.getMonth()+11)%12;
    var anifechfin = this.minDate.getFullYear() + Math.trunc((this.minDate.getMonth()+11)/12);
    let fii = new Date((this.minDate.getMonth()+1)+'/'+this.minDate.getDate()+'/'+this.minDate.getFullYear())
    this.formIndicador.controls['inputFechaIn'].setValue(this.minDate)
    let fff = new Date((mesfechfin+1)+'/'+Meses.maxdias(Meses.getMesesN(mesfechfin+1),anifechfin)+'/'+anifechfin);
    this.formIndicador.controls['inputFechaFn'].setValue(this.maxDate)
    document.getElementById('agregarInd')!.style.display = 'block';
    document.getElementById('inicioIndAgregar').scrollIntoView(true);

    this.modal = true;
  }
  public open_edit(ind: any, per: any){
    this.indic = ind;
    this.idPers = per.id;

    this.formIndicadorEdit.controls['inputNameInd'].setValue(this.indic.nombre);///
    this.formIndicadorEdit.controls['inputDescription'].setValue(this.indic.descripcion);///
    this.formIndicadorEdit.controls['inputCalculation'].setValue(this.indic.calculo);///
    //document.getElementById('ponderacionEdit')["value"] = this.indic.ponderacion;
    this.formIndicadorEdit.controls['inputObjective'].setValue(this.indic.objetiveId);///
    this.formIndicadorEdit.controls['inputParam'].setValue(this.indic.parametro);///
    this.formIndicadorEdit.controls['inputBaseLine'].setValue(this.indic.linea_base);///
    this.formIndicadorEdit.controls['inputFechaIn'].setValue(this.indic.fecha_inicio);///
    this.formIndicadorEdit.controls['inputFechaFn'].setValue(this.indic.fecha_fin);///
    this.formIndicadorEdit.controls['inputGlobalGoal'].setValue(this.indic.meta_global);///
    this.formIndicadorEdit.controls['inputPeriodicidad'].setValue(this.indic.periodicidad);///
    this.formIndicadorEdit.controls['inputUnidadMed'].setValue(this.indic.measureId);///
    this.formIndicadorEdit.controls['inputTendencia'].setValue(this.indic.tendencia);///
    this.formIndicadorEdit.controls['inputArea'].setValue(this.indic.areaId);///

    this.modal2 = true;
    document.getElementById('editIndicador')!.style.display = 'block';
    document.getElementById('inicioIndEditar').scrollIntoView(true);
  }
    // Cerrar modales
  public closeModal(){
    this.modal = false;
    this.modal2 = false;
    this.modal3 = false;
    this.modal4 = false;
    
    document.getElementById('agregarInd')!.style.display = 'none';
    document.getElementById('agregarMet')!.style.display = 'none';
    document.getElementById('editIndicador')!.style.display = 'none';
    document.getElementById('adicional')!.style.display = 'none';

    this.formIndicador.reset('');
    this.formIndicadorEdit.reset('');


    //document.getElementById('nomAdi')["value"] = '';
  }
  /** Edición de los adicionales */
  public edicion(adi: any){
    if(adi.type === 'moneda')
      return true;
    return false;
  }
  /** Obtención de datos */
  public nomMes(mes: any){
    return Meses.getMeses(mes)+'-'+(''+mes.getFullYear()).substring(2,4);
  } 
  public escArea(id: any){
    for(const are of this.areas){
      if(are.id === id)
        return are.nombre;
    }
    return '';
  }
  public obtObj(ind: any){
    for(const obj of this.objetivos){
      if(Number(ind.objetiveId) === Number(obj.id)){
        return obj.nombre;
      }
    }
    return '';
  }
  public tend(ten: any){
    for(const t of this.tenden){
      if(ten === t.id)
        return t.name;
    }
    return '';
  }
  /** Eliminación de indicadores */
  public eliminarInd(ind: any){
    this.indicadoresService.deleteIndicadores(ind).subscribe(response =>{
      this.indicadoresService.getIndicadores().subscribe(response => {
        this.indPers(response);
        Swal.fire({
          title: 'Información eliminada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Edición en tabla */
  public async edicionPond(Ind: any){
    var inputValue = Ind.ponderacion, introdujo: any, guardo = false;
    var nombre = 'obj.name';

    introdujo = await Swal.fire({
      title: 'Ingrese la ponderación',
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Ingerese un dato valido';
        }
        guardo = true;
        return '';
      }
    });

    if(guardo){
      Ind.ponderacion = Number(introdujo.value);
      this.indicadoresService.patchIndicadoresPond(Ind).subscribe(response =>{
        this.indicadoresService.getIndicadores().subscribe(response => {
          this.indPers(response);
          Swal.fire({
            title: 'Información actualizada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });

    }
  }
  /** Exportación en excel */
  public exportar_excel(){
    let subtitulosTablas: any = [];
    let contenidoTabla: any = [];
    let headers: any = [];
    
    this.perspectivas.forEach((pers: any, indx) => {
      if(pers.check){

      subtitulosTablas.push(pers.nombre);
      let meses: string[] = [];
      this.meses.forEach((m) => {
        meses.push(m.name);
      });
      let indicadoresAdicionales: string[] = [];
      if (this.indicadores.length != 0) {
        this.indicadores[0].adadicionales.forEach((i: any) => {
          indicadoresAdicionales.push(i.name);
        });
      }
      headers.push([
        'Objetivo',
        'Indicador',
        'Ponderación',
        'Área responsable',
        'Línea base',
        'Meta global',
        'Frecuencia',
        ...meses,
        ...indicadoresAdicionales,
      ]);
      let contenido: any = [];
      this.indicadores.forEach((ind, indx) => {
        if(ind.perspective === pers.nombre){
          let mss: any = []
          for(const m of this.meses){
            mss.push( this.escrituraMet(m.name,ind.id, ind)) //----------------------------------------------------------------------------------------------
          }
          let adds: any = []
          for(const a of ind.adadicionales){
            adds.push(this.imprimir(a))
          }
          contenido.push(
            [this.escribiObj(this.obtObj(ind),ind,indx), 
              ind.nombre, 
              ind.ponderacion, 
              this.escArea(ind.areaId),
              ind.linea_base,
              ind.meta_global,
              ind.periodicidad,
              ...mss,
              ...adds
            ])
            }
          });
      contenidoTabla.push(contenido);
    }

    });

    const aniosSelecc = [];
    for(const a of this.aniosDisponibles){
      if(a.check){
        aniosSelecc.push(a.name);
      }
    }

    this.excelService.generateExcelIndicadoresFormulacion(
      'Formulación: Indicadores-metas',aniosSelecc, subtitulosTablas, headers, contenidoTabla, "Indicadores_metas"

    );

    
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('indicador')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('indicadorEdit')["value"];
      }else if(this.modal3){
        nombre = document.getElementById('nomAdi')["value"];
      }
      if(this.modal || this.modal2){
        const existe = this.indicadoresAll.find( element => {
          if(this.modal2){
            return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.indic.id );
          }
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
        });
        if(existe) return {isValid:true};  
      }else{
        for(const element of this.indicadoresAll){
          const existe =  element.adadicionales.find(adic => {
            return ( procesamiento.procesamiento_Texto(adic.name).toLowerCase() === nombre.toLowerCase() );
          });
          if(existe) return {isValid:true};
        }
      }
      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formIndicador.hasError('isValid');
  }
  public nombreExisteEdit(): boolean{    
    return  this.formIndicadorEdit.hasError('isValid');
  }
  public tamTabla(zona: string){
    const perAct = this.perspectivas.filter(per => per.check);
    var tam = 25;
    if(perAct.length === 2){
      tam = 35;
    }else if(perAct.length === 1){
      tam = 70;
    }

    if(zona === 'tab')
      return tam+'vh';
    else
      return (tam+5)+'vh';
  }
  /* Edición y eliminación de adicionales */
  public async editarAdicional(adi: any){
    var guardo = false;
    var introdujo = await Swal.fire({
      title: 'Ingrese el nombre del adicional',
      text: "",
      input: 'text',
      inputValue: adi.name,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: (value) => {
        guardo = true;
        return '';
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Editando adicional');
      for(const ind of this.indicadoresAll){
        const adEdit = ind.adadicionales.filter(ad => ad.name === name && ad.type === adi.type);
        if(adEdit.length !== 0){
          adEdit[0].name = introdujo.value;
          this.indicadoresService.patchAdicionalesNombre(adEdit[0]).subscribe(response =>{
            edit++;
            if(edit === this.indicadoresAll.length){
              this.indicadoresService.getIndicadores().subscribe(response => {
                this.indPers(response);
                Swal.close();
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }
      }
    }else{
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public async eliminarAdicional(adi: any){
    var guardo = false;
    await Swal.fire({
      title: '¿Esta seguro de eliminar este adicional?',
      text: "Esta acción borrara permanente la información contenida en el adicional.",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        guardo = true;
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Eliminando adicional');
      for(const ind of this.indicadoresAll){
        const adEdit = ind.adadicionales.filter(ad => ad.name === name && ad.type === adi.type);
        if(adEdit.length !== 0){
          this.indicadoresService.deleteAdicional(adEdit[0]).subscribe(response =>{
            edit++;
            if(edit === this.indicadoresAll.length){
              this.indicadoresService.getIndicadores().subscribe(response => {
                this.indPers(response);
                Swal.close();
                Swal.fire({
                  title: 'Adicional eliminado',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }
      }
    }
  }
}
