import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Indicadores{
  public objectiveId?: Number;
  public areaId?: Number;
  public measureId?: Number;
  public name?: String;
  public description?: String;
  public calculation?: String;
  public baseline?: String;
  public periodicity?: String;
  public initial_date?: Date;
  public end_date?: Date;
  public weighing?: Number;
  public globalGoal?: Number;
  public tendency?: string;
  public param_type?: string;
}

export class Metas{
  public indicatorId?: Number;
  public period?: Date;
  public value?: Number;
}

export class Adicionales{
  indicatorId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 

export class comment{
  comment?: string;
  created_at?: String;
}
@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getUnides(): Observable<any>{
    const apiURL = this.apiURL+"medidas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getIndicadores(): Observable<any>{
    const apiURL = this.apiURL+"indicador";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getMetas(): Observable<any>{
    const apiURL = this.apiURL+"meta";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAreas(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

  public postIndicadores(indicador: any): Observable<any>{
    const ind: Indicadores = {
      objectiveId: indicador.objetiveId,
      areaId: Number(indicador.areaId),
      measureId: Number(indicador.measureId),
      name: indicador.nombre,
      description: indicador.descripcion,
      calculation: indicador.calculo,
      baseline: indicador.linea_base,
      periodicity: indicador.periodicidad,
      initial_date: indicador.fecha_inicio.toISOString(),
      end_date: indicador.fecha_fin.toISOString(),
      weighing: Number(indicador.ponderacion),
      globalGoal: Number(indicador.meta_global),
      tendency: indicador.tendencia,
      param_type: indicador.parametro,
    }
    const body = JSON.stringify(ind);
    const apiURL = this.apiURL+"indicador";
    return this.http.post(`${apiURL}`,body, {headers: this.headers});
  }
  public postMetas(meta: any): Observable<any>{
    const met: Metas = {
      indicatorId: Number(meta.indicatorId),
      period: meta.nombre.toISOString(),
      value: Number(meta.descripcion),
    }
    const body = JSON.stringify(met);
    const apiURL = this.apiURL+"meta";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public postAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      indicatorId: Number(adicional.indicadorId),
      name: adicional.nombre,
      amount: Number(adicional.monto),
      note: adicional.nota,
      type: adicional.tipo,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public patchAdicionales(adicional: any, id: any): Observable<any>{
    const ad: Adicionales = {
      indicatorId: Number(id),
      name: adicional.name,
      amount: Number(adicional.amount),
      files: adicional.files,
      note: adicional.note,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public patchIndicadores(indicador: any): Observable<any>{
    const ind: Indicadores = {
      objectiveId: indicador.objetiveId,
      areaId: Number(indicador.areaId),
      measureId: Number(indicador.measureId),
      name: indicador.nombre,
      description: indicador.descripcion,
      calculation: indicador.calculo,
      baseline: indicador.linea_base,
      periodicity: indicador.periodicidad,
      initial_date: indicador.fecha_inicio.toISOString(),
      end_date: indicador.fecha_fin.toISOString(),
      weighing: Number(indicador.ponderacion),
      globalGoal: Number(indicador.meta_global),
      tendency: indicador.tendencia,
      param_type: indicador.parametro,
    }
    const body = JSON.stringify(ind);
    const apiURL = this.apiURL+"indicador/"+indicador.id;
    return this.http.patch(`${apiURL}`,body, {headers: this.headers});
  }
  public patchIndicadoresFechaFin(indicador: any): Observable<any>{
    const ind: Indicadores = {
      end_date: indicador.fecha_fin.toISOString(),
    }
    const body = JSON.stringify(ind);
    const apiURL = this.apiURL+"indicador/"+indicador.id;
    return this.http.patch(`${apiURL}`,body, {headers: this.headers});
  }
  public patchIndicadoresPond(indicador: any): Observable<any>{
    const ind: Indicadores = {
      weighing: Number(indicador.ponderacion),
    }
    const body = JSON.stringify(ind);
    const apiURL = this.apiURL+"indicador/"+indicador.id;
    return this.http.patch(`${apiURL}`,body, {headers: this.headers});
  }
  public patchMetas(meta: any): Observable<any>{
    const met: Metas = {
      indicatorId: Number(meta.indicatorId),
      period: meta.nombre.toISOString(),
      value: Number(meta.descripcion),
    }
    const body = JSON.stringify(met);
    const apiURL = this.apiURL+"meta/"+Number(meta.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public deleteIndicadores(indicador: any): Observable<any>{
    const apiURL = this.apiURL+"indicador/"+indicador.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteMetas(meta: any): Observable<any>{
    const apiURL = this.apiURL+"meta/"+Number(meta.id);
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }

  public patchAdicionalesNombre(adicional: any): Observable<any>{
    const ad: Adicionales = {
      name: adicional.name,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteAdicional(adicional: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+Number(adicional.id);
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }

  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
