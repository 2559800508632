import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Filosofia_Corporativa{
  public id?: number;
  public name?: string;
  public description?: string;

  public planningId?: number;
}
@Injectable({
  providedIn: 'root'
})
export class FilosofiacorporativaService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }
  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getFilosofia(): Observable<any>{
    const apiURL = this.apiURL+"filosofia";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postfilosofia(concept: any): Observable<any>{
    const filo: Filosofia_Corporativa = {
      name: concept.nombre,
      description: concept.descripcion ,
      planningId: Number(concept.planningId),
    }
    const body = JSON.stringify(filo);
    const apiURL = this.apiURL+"filosofia";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchfilosofia(concept: any): Observable<any>{
    const filo: Filosofia_Corporativa = {
      name: concept.nombre,
      description: concept.descripcion ,
      planningId: Number(concept.planningId),
    }
    const body = JSON.stringify(filo);
    const apiURL = this.apiURL+"filosofia/"+concept.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deletefil(per): Observable<any>{
    const apiURL = this.apiURL+"filosofia/"+per.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
}
