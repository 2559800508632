import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { ConstantesImagenes } from '../codigoimagenes';
import { obtenerLogo } from './../../../model/datos';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import html2canvas from 'html2canvas';
import { CuadromandoService } from '../../../services/formulacion/cruadromando/cuadromando.service';
import {
  Perspectivas,
  Filosofia_Corporativa,
  Planificacion,
  Responsables,
  Proyectos,
} from '../../../model/datos';
import { ActivatedRoute } from '@angular/router';
import { FilosofiacorporativaService } from '../../../services/formulacion/filosofiacorporativa/filosofiacorporativa.service';
import { MatrizFloa2Service } from '../../../services/formulacion/matrizfoda2/matriz-floa2.service';
import { ObjetivosService } from 'src/app/services/formulacion/objetivos/objetivos.service';
import { IndicadoresService } from 'src/app/services/formulacion/indicadores/indicadores.service';
import {
  Areas,
  Unidad_Medida,
  Metas,
  Objetivos,
  Perspectiva,
  Actividades,
} from '../../../model/datos';
import { ProyectosestrategicosService } from 'src/app/services/formulacion/proyectosestrategicos/proyectosestrategicos.service';
import Swal from 'sweetalert2';
import { AltaplanificacionesService } from '../../../services/formulacion/altaplanificaciones/altaplanificaciones.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';

@Component({
  selector: 'app-planestrategico',
  templateUrl: './planestrategico.component.html',
  styleUrls: ['./planestrategico.component.css'],
})
export class PlanestrategicoComponent implements OnInit {
  public nombre = 'Plan estratégico';
  isDownloadingPdf = false;
  htmlContent = '';
  imgCmi: any;
  img_metod_trabajo: any;
  imgFloa: any;
  private planeacionId: any;
  public perspectivas: Perspectivas[] = [];
  public diccionarioPerspectivas: any = [];

  public concepto: Filosofia_Corporativa[] = [];
  public planeacion = [];
  private planeacionFoda;

  public perspectiva: Perspectivas[] = [];
  public internos = [
    {id:1, type:'FORTALEZA', elemento:'Fortalezas'},
    {id:2, type:'DEBILIDAD', elemento:'Debilidades'},
  ];
  public externos = [
    {id:3, type:'OPORTUNIDAD', elemento:'Oportunidades'},
    {id:4, type: 'AMENAZA', elemento:'Amenazas'},
  ];
  elemento = [];
  public diccionarioFLOA1: any = [];
  public diccionarioFLOA2: any = [];
  public diccionarioFLOA1V2: any = [];
  public diccionarioFLOA2V2: any = [];
  planeacionObj: Planificacion[] = [];
  responsable: Responsables[] = [];
  public perspectivaObj: Perspectivas[] = [];
  public objetivos: Objetivos[] = [];
  public Oobjetivos: any = [];

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };

  public aniosDisponibles = [
    { name: '2022', check: true },
    { name: '2023', check: false },
  ];

  minDate = new Date(2023, 1, 1);
  maxDate = new Date(2023, 11, 31);
  public areas: Areas[] = [];

  public perspectivasIndMet: Perspectivas[] = [];
  public objetivosIndMet: Objetivos[] = [];

  public unidades_med: Unidad_Medida[] = [];
  indicadoresIndMet: any[] = [];

  public metas: Metas[] = [];
  aniosDisponiblesPE = [];
  minDatePE = new Date(2022, 0, 1);
  maxDatePE = new Date(2022, 11, 31);
  responsablesPE = [];
  public responsables: Responsables[] = [];
  public responsablesAct: Responsables[] = [];
  perspectivasPE: Perspectiva[] = [];
  objetivosPE: Objetivos[] = [];
  proyectos: any[] = [];
  public actividades: Actividades[] = [];
  public estrategias: any[] = [];
  nombreInstitucion = { nombre: '', acronimo: '' };
  reporteGeneradoExitosamente: boolean;
  private verCodigo = false;

  imprimirIntro: boolean = true;
  imprimirMetodologia: boolean = true;
  imprimirFoda: boolean = true;
  imprimirFodaV2: boolean = true;
  imprimirFilosofia: boolean = true;
  imprimirObjetivos: boolean = true;
  imprimirIndicadores: boolean = true;
  imprimirCmi: boolean = true;
  imprimirPlanOperativo: boolean = true;
  imgLogo = ''
  companyId = ''

  verFodaV1 = false;
  verFodaV2 = false;
  constructor(
    public dataService: DataService,
    private cuadromandoService: CuadromandoService,
    private activateRouter: ActivatedRoute,
    private filosofiaService: FilosofiacorporativaService,
    private matrizService: MatrizFloa2Service,
    private objetivosService: ObjetivosService,
    private indicadoresService: IndicadoresService,
    private proyextosestrategicoService: ProyectosestrategicosService,
    private altaplanificacionesService: AltaplanificacionesService,
    private reportesService: ReportesService,
  ) {
    this.isDownloadingPdf = true;
    if(dataService.modules.includes('est-matrizfloa')){
      this.verFodaV1 = true;
    }else{
      this.imprimirFoda = false;
    }
    if(dataService.modules.includes('est-matrizderiesgosyoportunidades')){
      this.verFodaV2 = true;
    }else{
      this.imprimirFodaV2 = false;
    }
    //@ts-ignore
    this.planeacionId = Number(this.activateRouter.snapshot.params.planeacion);
    this.altaplanificacionesService
      .getPlanById(this.planeacionId.toString())
      .subscribe(
        (r) =>
          // @ts-ignore
          (document.getElementById('txt_introduccion').value = r.report_intro ?? '')
      );
    this.obtData();
    this.obtDataFilo();
    this.obtDataFoda();
    this.obtDataObjetivos();
    this.obtDataIndicadoresMetas();
  }

  ngOnInit(): void {}

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async generarReporte() {
    Swal.fire({
      title: 'Generando reporte',
      html: 'Por favor espere...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(undefined);
      },
    });

    await this.DescargarPDF();
    if (this.reporteGeneradoExitosamente) {
      Swal.close();
      Swal.fire({
        title: 'Reporte descargado exitosamente',
        text: 'Puede encontrar el archivo en la carpeta "Descargas"',
        timer: 15000,
      });
    }
  }

  public async DescargarPDF() {
    try {
      this.reporteGeneradoExitosamente = false;
      this.isDownloadingPdf = true;
      //@ts-ignore
      var introduccion = document.getElementById('txt_introduccion').value;

      let cmi;
      if (this.imprimirCmi) {
        cmi = this.obtenerCMI();
      }
      if (this.imprimirMetodologia) {
        await this.getimgCmi();
      }
      let tableFoda;
      await this.getFloa_img();
      if (this.imprimirFoda && this.verFodaV1) {
        tableFoda = this.matrizFodaReporte();
      }
      let tableFodaV2;
      if (this.imprimirFodaV2 && this.verFodaV2) {
        tableFodaV2 = this.matrizFodaReporteV2();
      }
      let tableObjEst;
      if (this.imprimirObjetivos) {
        tableObjEst = this.objetivosEstrategicosReporte();
      }

      let tableIndMetas;
      if (this.imprimirIndicadores) {
        tableIndMetas = this.indicadoresMetasReporte();
      }
      let tableProyectosEstrategicos;
      if (this.imprimirPlanOperativo) {
        tableProyectosEstrategicos = this.proyectosEstrategicosReporte();
      }

      const logo = obtenerLogo.obtLogo(this.nombreInstitucion.acronimo);

      const fechaPiePag = new Date().toLocaleDateString('es-MX', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      });
      const horaPiePag = new Date().toLocaleTimeString('es-MX');
      const currentUser = this.dataService.currentUser;

      const docDefinition = {
        images: {
          logoAleema: ConstantesImagenes.fondoblanco,
        },
        footer: function (currentPage, pageCount) {
          if (currentPage == 1) {
            return null;
          }
          if(currentPage === pageCount){
            return {
              columns: [
                {
                  image: 'logoAleema',
                  width: 70,
                },

                {
                  text: `${currentUser}  |  ${fechaPiePag}  |  ${horaPiePag}`,
                  alignment: 'center',
                  width: '*',
                  color: '#808080',
                  fontSize: 10,
                },

                {
                  text: (currentPage - 1).toString(),
                  // alignment: 'right',
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },
              ],
              margin: [25, -1, 30, 0],
            };
          }else{
            return{
              text:
                {
                  text: (currentPage - 1).toString(),
                  alignment: 'right',
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },margin: [10, -1, 30, 0],

            }
          }
        },
        content: [
          this.imgLogo !== ''
            ? {
                image: this.imgLogo,
                width: 150,
                margin: [0, 0, 10, 0],
                absolutePosition: { y: 60 },
                alignment: 'right',
              }
            : { text: '' },
          {
            text: 'Informe de Plan',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            // absolutePosition: { x: 90, y: 290 },
            margin: [60, 250, 0, 0],
          },
          {
            text: 'Estratégico',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 5, 0, 0],

            // absolutePosition: { x: 90, y: 330 },
          },
          {
            text: this.nombreInstitucion.nombre,
            style: 'header',
            bold: true,
            fontSize: 24,
            color: '#90BC40',
            margin: [60, 45, 0, 0],
            // absolutePosition: { x: 90, y: 400 },
          },
          {
            text: `${this.aniosDisponiblesPE[0].name} - ${
              this.aniosDisponiblesPE[this.aniosDisponiblesPE.length - 1].name
            }`,
            style: 'header',
            bold: true,
            fontSize: 20,
            color: '#0455B2',
            margin: [60, 45, 0, 0],
          },

          this.imprimirIntro
            ? [
                { text: '', pageBreak: 'after' },

                {
                  text: `Plan estratégico ${
                    this.aniosDisponiblesPE[0].name
                  } - ${
                    this.aniosDisponiblesPE[this.aniosDisponiblesPE.length - 1]
                      .name
                  }`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  absolutePosition: { x: 50, y: 40 },
                  bold: true,
                },
                {
                  text: 'introducción',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  absolutePosition: { x: 60, y: 70 },
                },
                {
                  alignment: 'justify',
                  text: introduccion,
                  style: 'header',
                  fontSize: 11,
                  color: '#808080',
                  absolutePosition: { x: 90, y: 130 },
                  margin: [0, 18],
                },
              ]
            : null,

          this.imprimirMetodologia
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Metodología de Trabajo',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,

                  // absolutePosition: { x: 50, y: 40 },
                },
                {
                  text: 'cuadro de mando integral',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  alignment: 'justify',
                  text: [
                    {
                      text: `El proceso metodológico implementado para el planteamiento estratégico se basa en el concepto del Cuadro de Mando Integral -CMI- (Balanced Scorecard), el cual corresponde a un sistema de gestión que permite transformar la visión de la organización en estrategias concretas y éstas a su vez en acciones, a partir de un conjunto de medidas de actuación y responsables en cada área estratégica.\n\n`,
                    },
                    {
                      text: `El objetivo fundamental del CMI es el de contar con una estructura lógica de objetivos prioritarios que se encuentran interrelacionados y a los cuales se les asigna indicadores que facilitarán su ejecución, medición y la toma de decisiones oportuna.\n\n`,
                    },
                    {
                      text: `Este modelo, apoya su ejecución en las siguientes perspectivas: ${this.obtenerNombresPerspectivas().toLocaleLowerCase()}, las cuales permiten alinear las iniciativas individuales y organizacionales que lleven a cumplir los objetivos propuestos.`,
                    },
                  ],
                  style: 'header',
                  fontSize: 11,
                  color: '#808080',
                  margin: [45, 15, 0, 0],
                },
                {
                  image: this.imgCmi,
                  width: 400,
                  alignment: 'center',
                  margin: [45, 15, 0, 0],
                },
                {
                  alignment: 'justify',
                  text: 'Dentro del esquema metodológico que ha servido de base para la formulación del presente plan estratégico, cada una de las perspectivas evaluadas ha aportado de manera sistemática a la definición de acciones o proyectos estratégicos y a su vez a la determinación de estrategias integrales que aportan al cumplimiento de objetivos que monitorean su cumplimiento a lo largo del tiempo a través de indicadores, permitiendo, de ser el caso, tomar acciones preventivas o correctivas por parte del equipo gerencial que tiene como responsabilidad la ejecución del plan.',
                  style: 'header',
                  fontSize: 11,
                  color: '#808080',
                  margin: [45, 15, 0, 0],
                },
                { text: '', pageBreak: 'after' },

                {
                  text: 'Metodología de Trabajo',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: 'fases de la planeación estratégica',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  text: [
                    { text: 'Fase diagnóstica:', bold: true },
                    ' en la cual se determinaron aquellos ',
                    'aspectos tanto internos como externos que inciden de forma directa o indirecta en la actuación',
                    'futura de la organización. Como resultado de este proceso, se han desprendido una serie de',
                    'documentos e informes que se convierten en insumos para la definición estratégica. ',
                    '\n\n',
                    { text: 'Fase estratégica:', bold: true },
                    ' a través de un proceso lógico, se han definido los principales ',
                    'elementos estratégicos que guiarán las acciones de la organización en los próximos tres años. ',
                    'Estos elementos correspondieron a objetivos estratégicos, indicadores que monitorean su avance y estrategias ',
                    '\n\n',
                    'En esta fase igualmente se llevó a cabo un proceso de “alineamiento estratégico”, que garantice que',
                    ' todos los elementos –objetivos, estrategias y posteriormente proyectos o iniciativas estratégicas- se encuentren ',
                    'consensuados y alineados al cumplimiento de la filosofía y cultura corporativa. Finalmente ',
                    'se incorporó a los objetivos, al menos un indicador que permita llevar una medición en el tiempo de ',
                    'su desenvolvimiento y cumplimiento.',
                    '\n\n',
                    { text: 'Fase operativa: ', bold: true },
                    ,
                    'esta fase se consolida con la generación de iniciativas (o proyectos) estratégicas para cada una',
                    ' de las estrategias previstas. Estos planes proponen un cronograma de trabajo con sus ',
                    'respectivos tiempos de ejecución y responsables de su ejecución.',
                    '\n\n',
                    { text: 'Fase de sistematización: ', bold: true },
                    'Una vez validados los elementos estratégicos ',
                    'estos se sistematizan en la ',
                    'herramienta Aleema y su módulo de gestión estratégica, el cual permitirá el monitoreo ',
                    'del cumplimiento del plan y facilidar el seguimiento de las acciones previstas.',
                    '\n\n',
                    { text: 'Fase de seguimiento y monitoreo:', bold: true },
                    'corresponde al proceso permanente de monitoreo de las ',
                    'actividades que se van ejecutando por parte del equipo de planificación, así como del cumplimiento de metas, indicadores y proyectos a lo largo ',
                    'del tiempo.',
                  ],
                  color: '#808080',
                  fontSize: 11,
                  // absolutePosition: {x: 90, y: 120},
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                },
              ]
            : null,

          this.imprimirFilosofia
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Filosofía Corporativa',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  // absolutePosition: { x: 50, y: 40 },
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: 'misión / visión',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },

                {
                  text: [
                    {
                      text: 'La filosofía corporativa corresponde a la identidad que una organización promueve de forma permanente frente a sus grupos de interés y que define su propósito y acciones expresado principalmente en elementos como la visión y misión corporativa.',
                    },
                  ],
                  color: '#808080',
                  fontSize: 11,
                  // absolutePosition: {x: 90, y: 120},
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                },
                //TODO
                ...this.filosofiaCorporativa(),
              ]
            : null,

          this.imprimirFoda
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  // absolutePosition: { x: 50, y: 40 },/
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'análisis estratégico',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  text: [
                    'La matriz estratégica FODA, corresponde a una metodología de diagnóstico que permite analizar elementos internos y externos más relevantes y vistos desde diferentes perspectivas estratégicas, ofreciendo así un diagnóstico amplio y claro del entorno en cual se desenvuelve.\n\n',
                    'La matriz se encuentra dividida en cuatro cuadrantes que definen tanto aspectos internos',
                    '-fortalezas y limitaciones- como externos -oportunidades y amenazas-. En el caso de los factores',
                    'internos, estos corresponden a aquellos que han surgido de los diferentes procesos de análisis en',
                    `las ${this.perspectivas.length} perspectivas: ${this.obtenerNombresPerspectivas().toLocaleLowerCase()}`,
                    'bajo la metodología del Cuadro de Mando Integral.\n',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11,
                },
                {
                  image: this.imgFloa,
                  width: 400,
                  alignment: 'center',
                  margin: [45, 30, 0, 0],
                },
                {
                  text: [
                    `La matriz FODA resultante de los análisis para institución ${this.nombreInstitucion.nombre}, ha propuesto los siguientes factores:`,
                  ],
                  alignment: 'justify',
                  margin: [45, 30, 0, 0],
                  color: '#808080',
                  fontSize: 11,
                },
                { text: '', pageBreak: 'after' },
                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'corporativa',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  table: tableFoda,
                  layout: {
                    hLineColor: function (i) {
                      return '#8190B1';
                    },
                    vLineColor: function (i, node) {
                      return '#8190B1';
                    },
                  },
                },
              ]
            : null,
          
          this.imprimirFodaV2
          ? [
              { text: '', pageBreak: 'after' },

              {
                text: 'Matriz de riesgos y oportunidades',
                style: 'header',
                fontSize: 24,
                color: '#0455B2',
                alignment: 'left',
                bold: true,
              },
              {
                text: 'corporativa',
                style: 'header',
                fontSize: 18,
                color: '#ACBD3D',
                margin: [25, 0, 0, 0],
              },
              {
                table: tableFodaV2,
                layout: {
                  hLineColor: function (i) {
                    return '#8190B1';
                  },
                  vLineColor: function (i, node) {
                    return '#8190B1';
                  },
                },
              },
            ]
          : null,

          this.imprimirObjetivos
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Objetivos Estratégicos',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  // absolutePosition: { x: 50, y: 40 },/
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'definición',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                  alignment: 'left',
                },

                {
                  text: [
                    'Los objetivos formulados corresponden a propósitos macro que se pretenden alcanzar en el transcurso del período para el cual ha sido formulado el plan y bajo los cuales se han alineado las acciones estratégicas y planes operativos. Los objetivos resultantes por perspectiva son los siguientes:\n\n',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11,
                },
                {
                  text: [
                    {
                      text: 'Perspectivas\n',
                      // style: 'header',
                      fontSize: 16,
                      color: '#0455B2',
                      fillColor: '#FFFFFF',
                      colSpan: 2,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  margin: [45, 15, 0, 0],
                },
                {
                  table: tableObjEst,
                  margin: [45, 15, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                  markerColor: '#808080',
                },
              ]
            : null,

          this.imprimirIndicadores
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Indicadores Claves de Desempeño -ICD-',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  // absolutePosition: { x: 50, y: 40 },/
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'indicadores - metas por perspectiva',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                  alignment: 'left',
                },

                {
                  text: 'Los indicadores que se han formulado como parte del presente proceso buscan cuantificar de manera periódica los resultados obtenidos en el cumplimiento de cada uno de los objetivos, a los cuales se les ha asignado al menos un indicador relevante.\nEl set de indicadores propuesto se resume a continuación.\n\n',
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11,
                },
                {
                  text: [
                    {
                      text: 'Perspectivas\n',
                      // style: 'header',
                      fontSize: 16,
                      color: '#0455B2',
                      fillColor: '#FFFFFF',
                      colSpan: 2,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  margin: [45, 15, 0, 0],
                },
                ...tableIndMetas,
              ]
            : null,

          this.imprimirCmi
            ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },
                {
                  text: 'Cuadro de Mando Integral',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,

                  // absolutePosition: { x: 50, y: 40 },
                },
                {
                  table: cmi,
                  margin: [45, 15, 0, 0],
                  layout: 'noBorders',
                },
              ]
            : null,

          this.imprimirPlanOperativo
            ? [
                { text: '', pageBreak: 'after', pageOrientation: 'landscape' },
                {
                  text: 'Plan Operativo',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  // absolutePosition: { x: 50, y: 40 },/
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'consolidado de proyectos',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                  alignment: 'left',
                },
                {
                  text: [
                    {
                      text: 'Como última fase y una vez consolidada la propuesta estratégica se definieron los planes de acción, mismos que vienen alineados y estructurados para dar cumplimiento a cada una de las estrategias, a su vez alineadas a los objetivos corporativos. Los elementos que se definieron corresponden a:',
                      color: '#808080',
                      fontSize: 11,
                    },
                  ],
                  margin: [45, 30, 0, 0],
                },
                {
                  ul: [
                    {
                      text: 'Líder de plan o proyecto',
                      color: '#808080',
                      fontSize: 11,
                    },
                    // { text: 'Actividades relevantes', color: '#808080' },
                    { text: 'Fecha inicio', color: '#808080', fontSize: 11 },
                    // { text: 'Días para su desarrollo', color: '#808080' },
                    { text: 'Fecha fin', color: '#808080', fontSize: 11 },
                    {
                      text: 'Responsable de la actividad',
                      color: '#808080',
                      fontSize: 11,
                    },
                  ],
                  markerColor: '#4E9AC7',
                  margin: [45, 10, 0, 0],
                },
                {
                  table: tableProyectosEstrategicos,
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                  margin: [45, 0, 0, 0],
                },
              ]
            : null,
        ],
      };
      pdfMake
        .createPdf(docDefinition)
        .download(
          `Reporte_Global_${this.aniosDisponiblesPE[0].name}_${
            this.aniosDisponiblesPE[this.aniosDisponiblesPE.length - 1].name
          }` + '.pdf'
        );
      this.reporteGeneradoExitosamente = true;
      setTimeout(() => {
        this.isDownloadingPdf = false;
      }, 2000);
    } catch (error) {
      this.reporteGeneradoExitosamente = false;
      Swal.close();
      Swal.fire({ title: 'Error al generar el reporte', timer: 15000 });
    }
  }

  async getimgCmi() {
    await html2canvas(document.querySelector('#cmi_img'), {
      scale: 2.5,
      onclone: (doc) => {
        doc.getElementById('cmi_img_container').style.display = 'block';
      },
    }).then((canvas) => {
      this.imgCmi = canvas.toDataURL();
    });
  }

  iconoPerspectivas(pos, per){
    if(per.nombre.includes('Financiera')){
      return 'assets/img/reportes/perspectivas/0.png';
    }else if(per.nombre.includes('Tecnología') || per.nombre.includes('Innovación') || per.nombre.includes('Gestión')){
      return 'assets/img/reportes/perspectivas/2.png';
    }else if(per.nombre.includes('Crecimiento') || per.nombre.includes('Aprendizaje')){
      return 'assets/img/reportes/perspectivas/3.png';
    }else if(per.nombre.includes('Gobernabilidad') || per.nombre.includes('Social')){
      return 'assets/img/reportes/perspectivas/4.png';
    }else if(per.nombre.includes('Responsabilidad')){
      return 'assets/img/reportes/perspectivas/5.png';
    }else if(per.nombre.includes('Cliente') || per.nombre.includes('Mercado') || per.nombre.includes('Socio') || per.nombre.includes('Asociados')){
      return 'assets/img/reportes/perspectivas/6.png';
    }else if(per.nombre.includes('Educación')){
      return 'assets/img/reportes/perspectivas/7.png';
    }else if(per.nombre.includes('Procesos') || per.nombre.includes('Internos')){
      return 'assets/img/reportes/perspectivas/8.png';
    }else if(per.nombre.includes('Comunidades')){
      return 'assets/img/reportes/perspectivas/9.png';
    }else{
      return 'assets/img/reportes/perspectivas/4.png'
    }
  }

  async getFloa_img() {
    await html2canvas(document.querySelector('#floa_img'), {
      scale: 2.5,
      onclone: (doc) => {
        doc.getElementById('floa_img_cont').style.display = 'block';
      },
    }).then((canvas) => {
      this.imgFloa = canvas.toDataURL();
    });
  }

  async getMetodTrabajo() {
    await html2canvas(document.querySelector('#metod_trabajo'), {
      scale: 2.5,
      onclone: (doc) => {
        doc.getElementById('metod_trabajo').style.display = 'block';
      },
    }).then((canvas) => {
      this.img_metod_trabajo = canvas.toDataURL();
    });
  }

  private ordenamientoPer(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private obtData() {
    this.cuadromandoService.getPerspectivas().subscribe((response) => {
      this.perspectivas = [];
      var aux: Perspectivas = new Perspectivas();
      for (const per of response) {
        if (Number(per.planning.id) === this.planeacionId) {

          this.perspectivas.push({
            nombre: per.name,
            descripcion: per.descripcion,
            planningId: per.planning.id,
            id: per.id,
            check: true,
          });
        }
      }
      this.perspectivas = this.ordenamientoPer(this.perspectivas);
      this.perspectivas.forEach((per) => {
        this.diccionarioPerspectivas.push({ perspectiva: per, objetivos: [] });
      });
      this.cuadromandoService.getObjetivos().subscribe((response) => {
        for (const per of this.diccionarioPerspectivas) {
          for (const obj of response) {
            if (obj.perspective.id === per.perspectiva.id) {
              per.objetivos.push({
                nombre: obj.name,
                descripcion: obj.description,
                ponderacion: obj.weighing,
                perspectiveId: obj.perspective.id,
                id: obj.id,
              });
            }
          }
        }
      });
    });
  }

  public obtDataFilo() {
    this.filosofiaService.getPlanificaciones().subscribe((response) => {
      for (const plan of response) {
        if (this.planeacionId === Number(plan.id)) {
        if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
          //this.verCodigo = true;
        }
        this.companyId = plan.institution.code
          this.obtenerLogo()
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive,
          });
        }
      }
      this.filosofiaService.getFilosofia().subscribe((response) => {
        this.obtFilosofia(response);
      });
    });
  }
  private obtFilosofia(response: any) {
    this.concepto = [];
    for (const per of response) {
      if (Number(per.planning.id) === this.planeacionId) {
        this.concepto.push({
          nombre: per.name,
          descripcion: per.description,
          planningId: per.planning.id,
          id: per.id,
        });
      }
    }
    if (this.concepto.length === 0) {
      //crear misión, visión, principios
      var conceptodef1 = new Filosofia_Corporativa();
      var conceptodef2 = new Filosofia_Corporativa();
      var conceptodef3 = new Filosofia_Corporativa();
      conceptodef1.planningId = this.planeacionId;
      conceptodef1.nombre = 'Misión';
      conceptodef1.descripcion = 'Misión';
      conceptodef2.planningId = this.planeacionId;
      conceptodef2.nombre = 'Visión';
      conceptodef2.descripcion = 'Visión';
      conceptodef3.planningId = this.planeacionId;
      conceptodef3.nombre = 'Principios';
      conceptodef3.descripcion = 'Principios';
      this.filosofiaService
        .postfilosofia(conceptodef1)
        .subscribe((response) => {
          this.filosofiaService
            .postfilosofia(conceptodef2)
            .subscribe((response) => {
              this.filosofiaService
                .postfilosofia(conceptodef3)
                .subscribe((response) => {
                  this.filosofiaService.getFilosofia().subscribe((response) => {
                    this.concepto = [];
                    for (const fil of response) {
                      if (Number(fil.planningId) === this.planeacionId) {
                        this.concepto.push({
                          nombre: fil.name,
                          descripcion: fil.description,
                          planningId: fil.planningId,
                          id: fil.id,
                        });
                      }
                    }
                  });
                });
            });
        });
    }
    this.concepto = this.ordenarConceptos(this.concepto);
  }

  private ordenarConceptos(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public obtDataFoda() {
    this.matrizService.getPlanificacion(this.planeacionId).subscribe( respuesta => {
      this.planeacionFoda = {
        id: respuesta.id,
        nombre: respuesta.name,
        fecha_inicio: new Date(respuesta.initial_date),
        fecha_fin: new Date(respuesta.end_date),
        institucionId: respuesta.institution.id,
        isActive: respuesta.isActive
      };

      for(const per of respuesta.perspectives){
        this.perspectiva.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: this.planeacionId,
          id: per.id,
        });
      }
      this.perspectiva = this.ordenarPerspe(this.perspectiva);

      this.internos.forEach(per => { this.diccionarioFLOA1.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva}); });
      this.externos.forEach(per1 => { this.diccionarioFLOA2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva}); });

      this.internos.forEach(per => { this.diccionarioFLOA1V2.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva}); });
      this.externos.forEach(per1 => { this.diccionarioFLOA2V2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva}); });

      this.elemento = [];
      this.matrizService.getFLOA().subscribe( res => {
        this.getFloaV1(res);
        this.getFloaV2(res);
      });
    });
  }
  private getFloaV1(res){
    for (const per of this.diccionarioFLOA1) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.internosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
    for (const per of this.diccionarioFLOA2) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.externosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
  }
  private getFloaV2(res){
    this.elemento = [];
    for(const mat of res){
      const ex = this.perspectiva.filter(per => Number(per.id) === Number(mat.perspective.id));
      if(ex.length !== 0 && mat.name.includes('&&')){
        this.elemento.push(mat);
      }            
    }
    this.elemento = this.ordenarPerspe(this.elemento);

    for(const dicc of this.diccionarioFLOA1V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['internosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: ''
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }
      }
    }

    for(const dicc of this.diccionarioFLOA2V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['externosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: elem.name.split('&&')[1],
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }            
      }
    }
  }

  private ordenarPerspe(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private matrizFodaReporte() {
    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos internos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };

    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t1, t2]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos externos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t3, t4]);
    return table1;
  }
  private matrizFodaReporteV2() {
    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1V2[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1V2[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1V2[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1V2[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1V2[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1V2[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2V2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2V2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2V2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2V2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos externos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };
    // @ts-ignore
    table1.body.push([t3, t4]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos internos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );
    // @ts-ignore
    table1.body.push([t1, t2]);
    return table1;
  }
  public mostrarElem(eleme: any, ele: any) {
    for (const obj of this.perspectiva) {
      if (Number(obj.id) === Number(ele.perspectiveId) && ele.tipo === eleme) {
        return true;
      }
    }
    return false;
  }
  // Obtención de los codigos
  public obtCodigo(inter: any) {
    switch (inter.id) {
      case 1:
        return 'F';
      case 2:
        return 'D';
      case 3:
        return 'O';
      case 4:
        return 'A';
    }
    return 'no';
  }

  public obtDataObjetivos() {
    this.objetivosService.getPlanificaciones().subscribe((response) => {
      for (const plan of response) {
        if (this.planeacionId === Number(plan.id)) {
          this.planeacionObj.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive,
          });
          this.objetivosService.getResponsable().subscribe((response) => {
            this.responsable = [];
            for (const are of response) {
              if (are.institution.id === plan.institutionId) {
                for (const resp of are.responsibles) {
                  this.responsable.push({
                    id: resp.id,
                    nombre: resp.name,
                    cargo: resp.position,
                  });
                }
              }
            }
            this.responsable = this.ordenarPerspe(this.responsable);
          });
        }
      }
      this.objetivosService.getPerspectivas().subscribe((response) => {
        this.perspectivaObj = [];
        for (const per of response) {
          if (Number(per.planning.id) === this.planeacionId) {
            this.perspectivaObj.push({
              nombre: per.name,
              descripcion: per.descripcion,
              planningId: per.planning.id,
              id: per.id,
            });
          }
        }
        this.perspectivaObj = this.ordenarPerspe(this.perspectivaObj);
        this.perspectivaObj.forEach((per) => {
          this.Oobjetivos.push({ perspectivaaa: per, objetivosss: [] });
        });
        this.objetivosService.getObjetivos().subscribe((response) => {
          this.obtObjetivos(response);
        });
      });
    });
  }

  private obtObjetivos(response) {
    for (const per of this.Oobjetivos) {
      per.objetivosss = [];
      for (const obj of response) {
        if (obj.perspective.id === per.perspectivaaa.id) {
          per.objetivosss.push({
            nombre: obj.name,
            descripcion: obj.description,
            ponderacion: obj.weighing,
            perspectiveId: obj.perspective.id,
            responsableId: obj.responsibles,
            id: obj.id,
          });
          this.objetivos.push({
            nombre: obj.name,
            descripcion: obj.description,
            ponderacion: obj.weighing,
            perspectiveId: obj.perspective.id,
            responsableId: obj.responsibles,
            id: obj.id,
          });
          per.objetivosss = this.ordenarPerspe(per.objetivosss);
        }
      }
    }
    this.objetivos = this.ordenarPerspe(this.objetivos);
  }

  private objetivosEstrategicosReporte() {
    let table = {
      widths: ['*'],
      body: [],
    };
    let body = [];
    let i = 0;
    for (const per of this.Oobjetivos) {
      const title = {
        text: per.perspectivaaa.nombre,
        border: [false, false, false, true],
        color: '#808080',
        bold: true,
        fontSize: 11,
      };
      if (i > 0) {
        // @ts-ignore
        title.margin = [0, 15, 0, 0];
      }
      let elem = {
        ul: [],
        border: [false, false, false, false],
        markerColor: '#4E9AC7',
      };
      for (const ele of per.objetivosss) {
        elem.ul.push({ text: ele.nombre, color: '#808080', fontSize: 11 });
      }
      i++;
      body.push([title]);
      body.push([elem]);
    }
    table.body = body;
    return table;
  }

  public obtDataIndicadoresMetas() {
    this.indicadoresService.getPlanificaciones().subscribe((response) => {
      for (const plan of response) {
        if (this.planeacionId === Number(plan.id)) {
          const fechaini = new Date(plan.initial_date);
          const fechafin = new Date(plan.end_date);

          switch (plan.business_days) {
            case 'Lunes-Viernes':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                return day !== 0 && day !== 6;
              };
              break;
            case 'Lunes-Sabado':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                return day !== 0;
              };
              break;
            case 'Lunes-Domingo':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                return true;
              };
              break;
          }
          this.aniosDisponibles = [];
          for (
            var i = fechaini.getFullYear();
            i <= fechafin.getFullYear();
            i++
          ) {
            this.aniosDisponibles.push({
              name: '' + i,
              check: false,
            });
          }
          this.aniosDisponibles[0].check = true;
          this.minDate = fechaini;
          this.maxDate = fechafin;

          this.indicadoresService.getAreas().subscribe((response) => {
            this.areas = [];
            for (const are of response) {
              if (plan.institutionId === are.institution.id) {
                this.areas.push({
                  id: are.id,
                  nombre: are.name,
                });
              }
            }
            this.areas = this.ordenamientoPer(this.areas);
          });
        }
      }
    });
    this.indicadoresService.getPerspectivas().subscribe((response) => {
      this.perPers(response);
      this.perspectivasIndMet = this.ordenamientoPer(this.perspectivasIndMet);
      this.objetivosIndMet = this.ordenamientoPer(this.objetivosIndMet);
      this.indicadoresService.getUnides().subscribe((response) => {
        this.unidades_med = [];
        for (const uni of response) {
          this.unidades_med.push({
            nombre: uni.name,
            id: uni.id,
          });
        }
        this.unidades_med = this.ordenamientoPer(this.unidades_med);
      });
      this.indicadoresService.getIndicadores().subscribe((response) => {
        this.indPers(response);
        this.indicadoresIndMet = this.ordenamientoPer(this.indicadoresIndMet);
        this.indicadoresIndMet = this.ordenamientoInd(this.indicadoresIndMet);
        this.metas = this.ordenamientoPer(this.metas);
        this.obtDataProyectosEstrategicos();
      });
    });
  }

  public perPers(array: any) {
    var aux = [],
      aux2 = [];
    for (const per of array) {
      if (Number(per.planning.id) === Number(this.planeacionId)) {
        aux.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: per.planning.id,
          id: per.id,
          check: true,
        });
        for (const obj of per.objectives) {
          aux2.push({
            nombre: obj.name,
            descripcion: obj.description,
            ponderacion: obj.weighing,
            perspectiveId: per.id,
            id: obj.id,
          });
        }
      }
    }
    this.perspectivasIndMet = [];
    this.objetivosIndMet = [];
    this.perspectivasIndMet = aux;
    this.objetivosIndMet = aux2;
  }

  public indPers(array: any) {
    var aux = [],
      aux2 = [];
    for (const ind of array) {
      for (const obj of this.objetivosIndMet) {
        if (obj.id === ind.objective.id) {
          aux.push({
            id: ind.id,
            nombre: ind.name,
            objetivo: obj.nombre,
            descripcion: ind.description,
            calculo: ind.calculation,
            ponderacion: ind.weighing,
            objetiveId: ind.objective.id,
            linea_base: ind.baseline,
            fecha_inicio: new Date(ind.initial_date),
            fecha_fin: new Date(ind.end_date),
            periodicidad: ind.periodicity,
            meta_global: ind.globalGoal,
            measureId: ind.measure.id,
            areaId: ind.area.id,
            tendencia: ind.tendency,
            parametro: ind.param_type,
            adadicionales: this.ordenamientoAdi(ind.additions),
          });
          for (const met of ind.goals) {
            const date = this.obtFech(met.period);
            aux2.push({
              id: met.id,
              nombre: date,
              descripcion: met.value,
              indicatorId: ind.id,
            });
          }
        }
      }
    }
    this.indicadoresIndMet = [];
    this.metas = [];

    this.indicadoresIndMet = aux;
    this.metas = aux2;
  }

  private ordenamientoInd(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.objetiveId) > Number(b.objetiveId)) {
        return 1;
      }
      if (Number(a.objetiveId) < Number(b.objetiveId)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private ordenamientoAdi(array: any) {
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private obtFech(isodate: any) {
    const fecha = isodate.split('T')[0];
    var fech = new Date(
      fecha.split('-')[1] +
        '/' +
        fecha.split('-')[2] +
        '/' +
        fecha.split('-')[0]
    );
    return fech;
  }

  public mostrarPers(per: any, ind: any) {
    var anio: any,
      anioInd = ind.fecha_fin.getFullYear();
    for (const an of this.aniosDisponibles) {
      if (an.check) {
        anio = an.name;
        break;
      }
    }
    for (const obj of this.objetivosIndMet) {
      if (
        Number(obj.id) === Number(ind.objetiveId) &&
        Number(obj.perspectiveId) === Number(per) &&
        anio <= anioInd
      ) {
        return true;
      }
    }
    return false;
  }

  public obtObj(ind: any) {
    for (const obj of this.objetivosIndMet) {
      if (Number(ind.objetiveId) === Number(obj.id)) {
        return obj.nombre;
      }
    }
    return '';
  }
  public escribiObj(obj: any, ind: any, i: any) {
    if (this.indicadoresIndMet.length === 1) {
      return obj;
    } else {
      if (i != 0) {
        if (
          Number(ind.objetiveId) !=
          Number(this.indicadoresIndMet[i - 1].objetiveId)
        )
          return obj;
      } else if (i === 0) {
        return obj;
      }
    }
    return '';
  }

  private indicadoresMetasReporte() {
    let content = [];
    let table = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: 'Objetivo',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'indicador',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
        ],
      ],
    };
    let body = [];
    let perspectvas = [];
    for (let per of this.perspectivasIndMet) {
      let objetivos = new Map<String, Object[]>();
      let cols = [];
      let objetivo = null;
      let i = 0;
      for (let ele of this.indicadoresIndMet) {
        if (this.mostrarPers(per.id, ele)) {
          if (objetivos.has(ele.objetivo)) {
            let v: any = objetivos.get(ele.objetivo);
            v[0].ul = [
              ...v[0].ul,
              { text: (this.verCodigo?'Código | ':'')+''+ele.nombre, color: '#808080', fontSize: 11 },
            ];
            objetivos.delete(ele.objetivo);
            objetivos.set(ele.objetivo, v);
          } else {
            objetivos.set(ele.objetivo, [
              {
                ul: [{ text: (this.verCodigo?'Código | ':'')+''+ele.nombre, color: '#808080', fontSize: 11 }],
                markerColor: '#4E9AC7',
              },
            ]);
          }
        }
      }
      perspectvas.push([per.nombre, objetivos]);
    }
    let i = 0;
    for (const per of perspectvas) {
      let table = {
        widths: ['*', '*'],
        body: [
          [
            {
              text: 'Objetivo',
              style: 'tableHeader',
              fillColor: '#eeeeee',
              color: '#808080',
              bold: true,
              fontSize: 11,
            },
            {
              text: 'indicador',
              style: 'tableHeader',
              fillColor: '#eeeeee',
              color: '#808080',
              bold: true,
              fontSize: 11,
            },
          ],
        ],
      };
      const t = {
        text: per[0],
        margin: [45, 15, 0, 0],
        bold: true,
        color: '#808080',
      };
      let keys = (per[1] as Map<String, String[]>).keys();
      for (const k of keys) {
        let o = {
          text: k,
          color: '#808080',
          fontSize: 11,
        };
        //  @ts-ignore
        table.body.push([[o], (per[1] as Map<String, String[]>).get(k)]);
      }
      if (i === 2) {
        content.push({ text: '', pageBreak: 'after' });
        content.push({
          text: 'Indicadores Claves de Desempeño -ICD-',
          style: 'header',
          fontSize: 24,
          color: '#0455B2',
          margin: [15, 0, 0, 0],
          alignment: 'left',
          bold: true,
        });
        content.push({
          text: 'indicadores - metas por perspectiva',
          style: 'header',
          fontSize: 20,
          color: '#ACBD3D',
          margin: [15, 0, 0, 0],
          alignment: 'left',
        });

        content.push({
          text: [
            {
              text: 'Perspectivas\n',
              fontSize: 16,
              color: '#0455B2',
              fillColor: '#FFFFFF',
              colSpan: 2,
              alignment: 'left',
              border: [false, false, false, false],
            },
          ],
          margin: [45, 15, 0, 0],
        });
      }
      content.push(t);
      content.push({
        table: table,
        layout: {
          hLineColor: function (i) {
            return '#E0E0E0';
          },
          vLineColor: function (i, node) {
            return 'white';
          },
        },
        margin: [45, 5, 0, 0],
      });
      i++;
    }
    return content;
  }

  private obtDataProyectosEstrategicos() {
    this.proyextosestrategicoService
      .getPlanificaciones()
      .subscribe((response) => {
        for (const plan of response) {
          if (this.planeacionId === Number(plan.id)) {
            const fechaini = new Date(plan.initial_date);
            const fechafin = new Date(plan.end_date);

            switch (plan.business_days) {
              case 'Lunes-Viernes':
                this.myFilter = (d: Date | null): boolean => {
                  const day = (d || new Date()).getDay();
                  return day !== 0 && day !== 6;
                };
                break;
              case 'Lunes-Sabado':
                this.myFilter = (d: Date | null): boolean => {
                  const day = (d || new Date()).getDay();
                  return day !== 0;
                };
                break;
              case 'Lunes-Domingo':
                this.myFilter = (d: Date | null): boolean => {
                  const day = (d || new Date()).getDay();
                  return true;
                };
                break;
            }
            this.aniosDisponiblesPE = [];
            for (
              var i = fechaini.getFullYear();
              i <= fechafin.getFullYear();
              i++
            ) {
              this.aniosDisponiblesPE.push({
                name: '' + i,
                check: false,
              });
            }
            this.aniosDisponiblesPE[0].check = true;
            this.minDatePE = fechaini;
            this.maxDatePE = fechafin;

            this.proyextosestrategicoService
              .getResponsable()
              .subscribe((response) => {
                this.responsablesPE = [];
                for (const are of response) {
                  if (are.institution.id === plan.institution.id) {
                    for (const res of are.responsibles) {
                      this.responsablesPE.push({
                        nombre: res.position,
                        apellido_materno: '',
                        apellido_paterno: '',
                        id: res.id,
                        check: true,
                      });
                      this.responsablesAct.push({
                        nombre: res.position,
                        apellido_materno: '',
                        apellido_paterno: '',
                        id: res.id,
                        check: true,
                      });
                    }
                  }
                }
                this.responsablesPE = this.ordenamientoPer(this.responsablesPE);
                this.responsablesAct = this.ordenamientoPer(
                  this.responsablesAct
                );
              });
          }
        }
      });
    this.proyextosestrategicoService.getPerspectivas().subscribe((response) => {
      this.perPersPE(response);
      this.perspectivasPE = this.ordenamientoPer(this.perspectivasPE);
      this.objetivosPE = this.ordenamientoPer(this.objetivosPE);
      this.proyextosestrategicoService.getProyectos().subscribe((response) => {
        this.perProyec(response);
        this.proyectos = this.ordenamientoPer(this.proyectos);
        this.proyextosestrategicoService
          .getActividades()
          .subscribe((response) => {
            this.perActiv(response);
            this.actividades = this.ordenamientoPer(this.actividades);
            this.obtfechasProy();
          });
          this.proyextosestrategicoService
          .getEstrategias()
          .subscribe((response) => {
            this.estrategias = [];
            for (const est of response) {
              for (const obj of this.objetivosPE) {
                if (
                  est.objectives.length !== 0 &&
                  obj.id === est.objectives[0].id
                ) {
                  this.estrategias.push({
                    name: est.name,
                    description: est.description,
                    objetiveId: est.objectives,
                    id: est.id,
                  });
                }
              }
            }
            this.estrategias = this.ordenamientoPer(this.estrategias);
            this.isDownloadingPdf = false;
          });
      });
    });
  }

  public perProyec(array: any) {
    var aux = [];
    for (const pro of array) {
      for (const ob of this.objetivosPE) {
        if (pro.objectives.length !== 0 && pro.objectives[0].id === ob.id) {
          var objectiveid = [];
          for (const objid of pro.objectives) {
            objectiveid.push(objid.id);
          }
          var estrategiaId: any = [];
          for (const estId of pro.strategies) {
            estrategiaId.push(estId.id);
          }
          aux.push({
            nombre: pro.name,
            ponderacion: pro.projectWeighing,
            objetiveId: ob,
            programasId: estrategiaId,
            responsableId: pro.responsible,
            id: pro.id,
            descripcion: pro.description,
          });
          break;
        }
      }
    }
    this.proyectos = [];
    this.proyectos = aux;
    this.obtfechasProy();
  }

  public obtfechasProy() {
    for (const pro of this.proyectos) {
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for (const act of this.actividades) {
        if (pro.id === act.projectId) {
          if (pro.fecha_ini === undefined) {
            pro.fecha_ini = act.fecha_inicio;
          } else {
            if (pro.fecha_ini > act.fecha_inicio) {
              pro.fecha_ini = act.fecha_inicio;
            }
          }
          if (pro.fecha_fin === undefined) {
            pro.fecha_fin = act.fecha_fin;
          } else {
            if (pro.fecha_fin < act.fecha_fin) {
              pro.fecha_fin = act.fecha_fin;
            }
          }
        }
      }
    }
  }

  public perActiv(array: any) {
    var aux = [];
    for (const act of array) {
      for (const pro of this.proyectos) {
        if (pro.id === act.project.id) {
          aux.push({
            nombre: act.name,
            fecha_inicio: new Date(act.initial_date),
            fecha_fin: new Date(act.end_date),
            projectId: act.project.id,
            responsableId: act.responsible.id,
            id: act.id,
            adicionales: this.ordenamientoAdi(act.additions),
          });
        }
      }
    }
    this.actividades = [];
    this.actividades = aux;
    this.obtfechasProy();
  }

  public perPersPE(array: any) {
    var aux = [],
      aux2 = [];
    for (const per of array) {
      if (Number(per.planning.id) === Number(this.planeacionId)) {
        aux.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: per.planning.id,
          id: per.id,
          check: true,
        });
        for (const obj of per.objectives) {
          aux2.push({
            nombre: obj.name,
            descripcion: obj.description,
            ponderacion: obj.weighing,
            perspectiveId: per.id,
            id: obj.id,
          });
        }
      }
    }
    this.perspectivasPE = [];
    this.objetivosPE = [];
    this.perspectivasPE = aux;
    this.objetivosPE = aux2;
  }

  public obtFecha(fec: any) {
    var fecha = '';
    if (fec != undefined) {
      switch (fec.getDate()) {
        case 1:
          fecha += '01-';
          break;
        case 2:
          fecha += '02-';
          break;
        case 3:
          fecha += '03-';
          break;
        case 4:
          fecha += '04-';
          break;
        case 5:
          fecha += '05-';
          break;
        case 6:
          fecha += '06-';
          break;
        case 7:
          fecha += '07-';
          break;
        case 8:
          fecha += '08-';
          break;
        case 9:
          fecha += '09-';
          break;
        default:
          fecha += fec.getDate() + '-';
          break;
      }
      switch (fec.getMonth() + 1) {
        case 1:
          fecha += '01';
          break;
        case 2:
          fecha += '02';
          break;
        case 3:
          fecha += '03';
          break;
        case 4:
          fecha += '04';
          break;
        case 5:
          fecha += '05';
          break;
        case 6:
          fecha += '06';
          break;
        case 7:
          fecha += '07';
          break;
        case 8:
          fecha += '08';
          break;
        case 9:
          fecha += '09';
          break;
        default:
          fecha += fec.getMonth() + 1 + '';
          break;
      }
      fecha += '-' + fec.getFullYear();
    }
    return fecha;
  }

  private proyectosEstrategicosReporte() {
    let data = [];
    let objetivosSet = new Map<String, Object[]>();

    this.proyectos.sort(function (a: any, b: any) {
      if(a.fecha_ini !== undefined && b.fecha_ini !== undefined){
        if (a.fecha_ini.getTime() > b.fecha_ini.getTime()) {
          return 1;
        }
        if (a.fecha_ini.getTime() < b.fecha_ini.getTime()) {
          return -1;
        }
      }
      return 0;
    });

    for (const p of this.proyectos) {
      if (objetivosSet.has(p.objetiveId.nombre)) {
        let content: any = objetivosSet.get(p.objetiveId.nombre);
        content = [
          ...content,
          [
            {
              ul: [
                {
                  text: p.nombre,
                  color: '#808080',
                  fontSize: 11,
                },
              ],
              markerColor: '#4E9AC7',
              border: [false, false, false, false],
            },
            {
              text: this.obtFecha(p.fecha_ini),
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
            {
              text: this.obtFecha(p.fecha_fin),
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
            {
              text: p.responsableId.position,
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
          ],
        ];
        objetivosSet.delete(p.objetiveId.nombre);
        objetivosSet.set(p.objetiveId.nombre, content);
      } else {
        objetivosSet.set(p.objetiveId.nombre, [
          [
            {
              text: 'proyecto',
              color: '#808080',
              border: [false, false, false, true],
              fontSize: 11,
            },
            {
              text: 'Fecha Inicio',
              color: '#808080',
              border: [false, false, false, true],
              fontSize: 11,
            },
            {
              text: 'Fecha fin',
              color: '#808080',
              border: [false, false, false, true],
              fontSize: 11,
            },
            {
              text: 'Líder',
              color: '#808080',
              border: [false, false, false, true],
              fontSize: 11,
            },
          ],
          [
            {
              ul: [
                {
                  text: p.nombre,
                  color: '#808080',
                  fontSize: 11,
                },
              ],
              markerColor: '#4E9AC7',
              border: [false, false, false, false],
            },
            {
              text: this.obtFecha(p.fecha_ini),
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
            {
              text: this.obtFecha(p.fecha_fin),
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
            {
              text: p.responsableId.position,
              color: '#808080',
              border: [false, false, false, false],
              fontSize: 11,
            },
          ],
        ]);
      }
    }
    // headerRows
    let table = {
      widths: ['*', 90, 90, 220],
      body: [],
    };
    objetivosSet.forEach((v, k) => {
      table.body = [
        ...table.body,
        [
          { text: ' ', colSpan: 4, border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
        ],
        [
          {
            text: k,
            colSpan: 4,
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          { text: '' },
          { text: '' },
          { text: '' },
        ],
        ...v,
      ];
    });
    if (table.body.length === 0) {
      table.body = [
        [
          { text: ' ', colSpan: 4, border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
        ],
      ];
    }
    return table;
  }

  private obtenerNombresPerspectivas() {
    let str = '';
    for (const per of this.perspectivas) {
      if (str === '') {
        str = per.nombre;
      } else {
        str += ', ' + per.nombre;
      }
    }

    return str.replace(/,(?=[^,]+$)/, ' y');
  }

  public guardarIntroPlanificacion() {
    this.altaplanificacionesService
      .patchReportIntro(
        this.planeacionId,
        // @ts-ignore
        document.getElementById('txt_introduccion').value
      )
      .subscribe((r) => {
        if (r) {
          Swal.fire({
            title: 'Introducción guardada exitosamente',
            timer: 2000,
          });
        }
      });
  }

  private filosofiaCorporativa() {
    let resp: any = [];
    for (const c of this.concepto) {
      resp.push(
        {
          text: `${c.nombre}\n`,
          style: 'header',
          fontSize: 16,
          color: '#0455B2',
          margin: [45, 15, 0, 0],
        },
        {
          text: `\n\n${c.descripcion}\n\n\n\n`,
          alignment: 'justify',
          color: '#808080',
          fontSize: 11,
          margin: [45, 0, 0, 0],
        }
      );
    }
    return resp;
  }

  private obtenerCMI() {
    const border = [false, false, false, false];
    let table = {
      widths: ['auto', 40, 200],
      body: [],
    };

    let i = 0;
    for (const per of this.diccionarioPerspectivas) {
      table.body.push([
        {
          text: per.perspectiva.nombre,
          colSpan: 3,
          color: '#0B4E97',
          border,
          margin: i > 0 ? [0, 30, 0, 10] : [0, 0, 0, 10],
          fontSize: 14,
        },
        { text: '', border },
        { text: '', border },
      ]);
      borderColor: ['white', '#F5F5F5', 'white', '#F5F5F5'];
      for (let j = 0; j < per.objetivos.length; j += 2) {
        let table2 = {
          widths: [40, 150, 10, 40, 150],
          body: [],
        };
        if (per.objetivos[j + 1]) {
          table2.body.push([
            {
              text: '' + per.perspectiva.nombre[0] + (j + 1),
              fillColor: this.colorCMI(i),
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              color: 'white',
              fontSize: 20,
              bold: true,
              margin: [10, 0, 0, 0],
            },
            {
              text: per.objetivos[j].nombre,
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              color: '#808080',
              fontSize: 11,
            },
            { text: '', border },
            {
              text: '' + per.perspectiva.nombre[0] + (j + 2),
              fillColor: this.colorCMI(i),
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              border: [false, true, true, true],
              color: 'white',
              fontSize: 20,
              bold: true,
              margin: [10, 0, 0, 0],
            },
            {
              text: per.objetivos[j + 1].nombre,
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              color: '#808080',
              fontSize: 11,
            },
          ]);
          table2.body.push([
            { text: '', border, fontSize: 11 },
            { text: '', border },
            { text: '', border, fontSize: 11 },
            { text: '', border },
            { text: '', border },
          ]);
        } else {
          table2.body.push([
            {
              text: '' + per.perspectiva.nombre[0] + (j + 1),
              fillColor: this.colorCMI(i),
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              color: 'white',
              fontSize: 20,
              bold: true,
              margin: [10, 0, 0, 0],
            },
            {
              text: per.objetivos[j].nombre,
              borderColor: ['#F5F5F5', '#F5F5F5', '#F5F5F5', '#F5F5F5'],
              color: '#808080',
              fontSize: 11,
            },
            { text: '', border },
            { text: '', border },
            { text: '', border },
          ]);
          table2.body.push([
            { text: '', border, fontSize: 11 },
            { text: '', border },
            { text: '', border },
            { text: '', border },
            { text: '', border },
          ]);
        }
        table.body.push([
          { table: table2, colSpan: 3, layout: 'noborders' },
          { text: '', layout: 'noborders' },
          { text: '', layout: 'noborders' },
        ]);
      }
      i++;
    }
    return table;
  }

  public colorCMI(indice: any) {
    switch (indice) {
      case 0:
        return '#003772';
      case 1:
        return '#004A97';
      case 2:
        return '#1A65A7';
      case 3:
        return '#347FB7';
      case 4:
        return '#4E9AC7';
      case 5:
        return '#79B2D1';
      case 6:
        return '#97CCE3';
      case 7:
        return '#A9D5E8';
      case 8:
        return '#B8DCEC';
    }
    return null;
  }

  obtenerLogo(){
    const name = 'logo';
            const nomsep = name[0].split('_');
    this.reportesService.getLogo(this.companyId).subscribe(

      response =>{
        for(const doc of response){
          const name = (''+doc['filename']).split('.');
          const nomsep = name[0].split('_');
          for(const nam of nomsep){
            if( nam.toLocaleLowerCase() === 'logo' ){
              this.reportesService.getArchivosA(doc._id).subscribe(response =>{
                var base = 'data:image/'+name[name.length-1]+';base64,'+btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                this.imgLogo = base;
                const bufferArray = [{buffer: response, tipo: name[name.length-1]}];
              },err =>{
              });
              break;
            }
          }
        }

      }

    )

  }
}
