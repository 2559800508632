<div >
  <div class="w3-container w3-contenedor-template" style="height: 7vh;">
    <div class="w3-row">
      <div class="w3-caja-menu padreizquierda">
        <div class="w3-row hijoizquierda">
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
        </div>
      </div>

      <div class="w3-rest">
        <div class="w3-noventa2">
        </div>
      </div>
    </div>
  </div>

  <div id="contenedorPag" class="contenedorPag">
    <div class="w3-container w3-contenedor-template" id="img-1">
      <div class="w3-row contenerdorObj mantenerFija">
        <div class="filosofiaCMI" *ngIf="mision !== undefined">
          <div class="w3-row tituloFilosofiaCMI">{{mision.name}}</div>
          <div class="w3-row contenidoFilosofiaCMI" matTooltipPosition="right" matTooltip="{{mision.description}}">
            <span>{{mision.description}}</span>
          </div>
        </div>
        <div class="filosofiaCMI" *ngIf="vision !== undefined">
          <div class="w3-row tituloFilosofiaCMI">{{vision.name}}</div>
          <div class="w3-row contenidoFilosofiaCMI" matTooltipPosition="right" matTooltip="{{vision.description}}">
            <span>{{vision.description}}</span>
          </div>
        </div>
        <div class="w3-right" style="z-index: 1; margin-top: 1vh;" id="menu-exp">
          <mat-icon class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
            [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        </div>
        <mat-menu #menu2="matMenu">
          <button *ngIf="tarjOrTab" (click)="imprimirImg()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
            <span>Exportar</span>
          </button>
          <button *ngIf="!tarjOrTab" (click)="exportarTab()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>Exportar</span>
          </button>
          <button (click)="cambioTarjetOrTable()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon *ngIf="tarjOrTab" [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
            <mat-icon *ngIf="!tarjOrTab" [ngStyle]="{'color':'gray'}">crop_landscape</mat-icon>
            <span *ngIf="tarjOrTab">Tabla</span>
            <span *ngIf="!tarjOrTab">Tarjetas</span>
          </button>
        </mat-menu>
      </div>
      <ng-container *ngIf="tarjOrTab">
        <ng-container *ngFor="let per of diccionarioPerspectivas; let j = index;">
          <div class="w3-titulo-contenidomenu">
            <span class="w3-margin-left-contenido"> {{per.perspectiva.nombre}} </span>
            <!-- <div class="hr"></div> -->
          </div>
          <div class="w3-row w3-margin-left-contenido">
            <ng-container *ngFor="let obj of per.objetivos; let i = index;">
                <div class="w3-third_e">
                  <div class="w3-row tarjetasPer">
                    <div class="w3-veinte tarjetasCol" [ngClass]="color(j)">
                      <div class="numeroTarj"> {{obtLet(per)}}{{i+1}} </div>
                    </div>
                    <div class="w3-rest tarjetas2">
                      <div class="w3-threequarter w3-total-TextoMetaVsReal textObj"
                      matTooltipPosition="right" matTooltip="{{obj.nombre}}">
                        {{obj.nombre}}
                      </div>
                      <div class="w3-rest w3-right" style="margin-top: 5vh; margin-right: 0.5vh;">
                        <div class="w3-rest hijoderechaTitle w3-tiny ">
                          <mat-icon class="w3-margin-right-contenido  w3-button" [ngStyle]="{'color':'gray'}" (click)="modal_ind(obj,per,i)">
                            <span class="material-icons-outlined">info_outline</span>
                          </mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!tarjOrTab">
        <div class="w3-row contenedorTable">
          <div class="tablaCont">
            <table class="w3-table">
              <thead>
                <tr class="allBordes">
                  <th class="w3-elemento-titulo-table enbezados textosNorm">Perspectivas</th>
                  <th class="w3-elemento-titulo-table enbezados textosNorm">Objetivo</th>
                  <!--<th class="w3-elemento-titulo-table enbezados ponderaciones">Ponderación</th>-->
                  <th class="w3-elemento-titulo-table enbezados textosNorm">Indicador</th>
                  <th class="w3-elemento-titulo-table enbezados alinCen ponderaciones">Ponderación</th>
                </tr>
              </thead>
              <tbody class="allBordes">
                <ng-container *ngFor="let per of diccionarioPerspectivas">
                  <ng-container *ngFor="let obj of per.objetivos; let i = index">
                    <ng-container *ngFor="let ind of obj.indicators; let j = index">
                      <tr>
                        <td *ngIf="i === 0 && j === 0" class="w3-table-trtdBalance borderSup">{{per.perspectiva.nombre}}</td>
                        <td *ngIf="i !== 0 || j !== 0" class="w3-table-trtdBalance"></td>
                        <td [ngClass]="bordes(i,j)" *ngIf="j === 0" class="w3-table-trtdBalance borderSup borderIzq">{{obj.nombre}}</td>
                        <td *ngIf="j !== 0" class="w3-table-trtdBalance borderIzq"></td>
                        <!--<td [ngClass]="bordes(i,j)" *ngIf="j === 0" class="w3-table-trtdBalance borderSup">{{obj.ponderacion}}</td>
                        <td *ngIf="j !== 0" class="w3-table-trtdBalance"></td>-->
                        <td [ngClass]="bordes(i,j)" class="w3-table-trtdBalance borderIzq borderInf">{{ind.name}}</td>
                        <td [ngClass]="bordes(i,j)" class="w3-table-trtdBalance alinCen borderInf">{{ind.weighing}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div id="mostrarIndicador" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-noventa2">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 5.8vh; margin-top: 10px; margin-left: 20px; overflow-y: hidden;">
          <div class="w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle textoOverflow">
                      <span *ngIf="perspectivaSel!==''" class="w3-margin-right-filter w3-tituloCuadro" matTooltip="{{nombreObj}}"> Objetivo  {{obtLet(perspectivaSel)}}{{numeroPer+1}}: {{nombreObj}} </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="close_Modal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <div class="w3-row">
            <div class="w3-row subtitulosModal">
              <div class="w3-rest w3-subTituloCuadro"> Indicadores asociados </div>
            </div>
            <div class="w3-row subtitulosModal">
              <div class="w3-quince">
                <div class="w3-right w3-subtituloCuadro" style="margin-right: 2vh;">  </div>
              </div>
              <div class="w3-rest w3-subtituloCuadro"> </div>
            </div>
            <div class="w3-row" style="height: 2vh;"></div>
            <ng-container *ngFor="let ind of indicadores; let i =index">
              <div class="w3-row subtitulosModal">
                <div class="w3-quince">
                  <div class="w3-right w3-subtituloCuadro" style="margin-right: 2vh; color: #4E9AC7;font-weight: 500;"> &bull; </div>
                </div>
                <div class="w3-rest w3-subtituloCuadro"> {{ind.nombre}} </div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>