<!-- an hidden div is created to set the position of appearance of the menu--> 
<div id="menuIzq" style="position: fixed;" 
[style.left]="menuTopLeftPosition.x" 
[style.top]="menuTopLeftPosition.y" 
[matMenuTriggerFor]="rightMenu">
  <!-- standard material menu --> 
  <mat-menu #rightMenu="matMenu"> 
    <ng-template matMenuContent let-item="item"> 

      <button mat-menu-item (click)="deleteArchivo()">
        <mat-icon class="iconoMenVer" [ngStyle]="{'color':'grey'}">delete</mat-icon>
        Eliminar
      </button> 
    </ng-template> 
  </mat-menu> 
</div> 
<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> <span>{{ 'estrategia' | translate }}</span> </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios"> <!--  -->
              Períodos
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); anioChange(child.periodo);">
                          <mat-checkbox [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                          (change)="anioChange(child.periodo);"
                          [checked]="child.check" type="checkbox"
                          (click)="$event.stopPropagation();"
                          aria-label="First checkbox">
                            {{child.mes}} 
                          </mat-checkbox>         
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" >
                        <span style="margin-left: 5px; margin-right: 5px;">{{mes.periodo}}</span>
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Perspectivas -->
        <div class="w3-quarter_e padreizquierda" style="margin-right: 1vh;">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer">
              Perspectivas
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
                <button mat-menu-item (click)="$event.stopPropagation(); selecAllPer();">
                  <mat-checkbox (change)="selecAllPer();"
                  [checked]="todasPer" type="checkbox"
                  (click)="$event.stopPropagation();"
                  aria-label="First checkbox">
                    Seleccionar todas
                  </mat-checkbox>       
                </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPer">
                <ng-container *ngFor="let per of perspectives">
                  <button mat-menu-item (click)="$event.stopPropagation(); persChange(per);">
                    <mat-checkbox (change)="persChange(per)"
                    [checked]="per.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    aria-label="First checkbox">
                      {{per.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="contenedor_total">
      <div class="contenedorBlan">
        <div class="w3-row">
          <div class="titulo">
            <div class="w3-noventa2 w3-titulo-contenidomenuSC">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">Ejecución de <span>{{ 'toltip' | translate }}</span></span>
                <span class="w3-margin-left-contenido w3-tituloZonas">&bull;</span>
                <span class="w3-margin-left-contenido w3-subtitulo">{{Periodo}}</span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-right">
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon>
                <mat-menu #menutabla="matMenu">
                    <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                      <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>Exportar xlsx</span>
                    </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <div class="tabla w3-scroll">
          <div class="w3-row">
            <table class="w3-table">
              <thead class="borderIzq borderDer">
                <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Perspectiva</th>
                <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Objetivos</th>
                <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup"><span>{{ 'estrategia' | translate }}</span></th>
                <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Planeado</th>
                <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Avance</th>
                <!--<th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow borderSup">Atraso (Días)</th>-->
              </thead>
              <tbody class="borderInf borderDer borderIzq">
                <tr *ngFor="let pro of programasMostrar; let i = index">
                  <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan" style="max-width: 8vw;">
                    <span matTooltip="{{escribirPer(pro.programa, i)}}">{{escribirPer(pro.programa, i)}}</span>
                  </td>
                  <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan textoOverflow" style="max-width: 10vw;">
                    <span matTooltip="{{escribirObj(pro.programa)}}">{{escribirObj(pro.programa)}}</span>
                  </td>
                  <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan textoOverflow">
                    <span matTooltip="{{pro.programa.name}}">{{pro.programa.name}}</span>
                  </td>
                  <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan numerosAnch">
                    {{avanceProg(pro, 'plan')}}
                  </td>
                  <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                    <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan numerosAnch" style="cursor: pointer;">
                      <div *ngIf="avanceProg(pro, 'eje') !== 0" [ngClass]="pintarBordPro(avanceProg(pro, 'eje'),avanceProg(pro, 'pla'))"
                        (dblclick)="openModal(pro)"><span style="padding-left: 5px;">{{avanceProg(pro, 'eje')}}
                        </span>
                      </div>
                      <div *ngIf="avanceProg(pro, 'eje') === 0" class="textoAyud" [ngClass]="pintarBordPro(avanceProg(pro, 'eje'),avanceProg(pro, 'pla'))"
                        (dblclick)="openModal(pro)">
                        <span style="padding-left: 5px;">{{avanceProg(pro, 'eje')}}</span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                    <td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan numerosAnch">
                      <div *ngIf="avanceProg(pro, 'eje') !== 0" [ngClass]="pintarBordPro(avanceProg(pro, 'eje'),avanceProg(pro, 'pla'))">
                        <span style="padding-left: 5px;">{{avanceProg(pro, 'eje')}}</span>
                      </div>
                      <div *ngIf="avanceProg(pro, 'eje') === 0" class="textoAyud" [ngClass]="pintarBordPro(avanceProg(pro, 'eje'),avanceProg(pro, 'pla'))">
                        <span style="padding-left: 5px;">{{avanceProg(pro, 'eje')}}</span>
                      </div>
                    </td>
                  </ng-container>
                  <!--<td [ngClass]="escribirPer(pro.programa,i) != ''? 'borderSup':''" class="w3-elemento-titulo-tableBlan numerosAnch"> {{pro.atraso}} </td>-->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w3-row" style="height: 2vh"></div>
      </div>
    </div>
  </div>
</div>

<div id="modalAct" class="w3-modal">
  <div class="w3-modal-content modalForm">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenuG bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenuG padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Proyectos asiciados </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div (click)="closeModal()" class="w3-third">
        <mat-icon class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="tablaModal" *ngIf="actividades.length != 0">
          <table class="w3-table">
            <thead class="borderDer borderIzq borderSup">
              <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow anchos"> Proyecto </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow"> Inicio </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow"> Días </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow"> Fin </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow"> Líder </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow"> Planeado </th>
              <th class="w3-elemento-titulo-table FijaTit1 alinIzaq textoOverflow"> Ejecutado </th>
              <ng-container *ngFor="let ad of actividades[0].activities[0].additions">
                <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow">{{ad.name}}</th>
              </ng-container>
            </thead>
            <tbody class="borderDer borderIzq borderInf">
              <ng-container *ngFor="let pro of actividades">
                <tr *ngIf="existPro(pro)" class="borderInf">
                  <td class="w3-elemento-titulo-tableBlan textoOverflow alingIzq"> 
                    <table class="w3-table">
                      <td style="max-width: 1vw; padding: 0vh;">
                        <mat-icon *ngIf="!pro.check" (click)="oculMost(pro)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                        <mat-icon *ngIf="pro.check" (click)="oculMost(pro)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                      </td>
                      <td style="font-weight: bold;"><span matTooltip="{{pro.name}}">{{pro.name}}</span></td>
                    </table>
                  </td>
                  <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{escFechPro(pro,'fi')}} </td>
                  <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{escFechPro(pro,'di')}} </td>
                  <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{escFechPro(pro,'ff')}} </td>
                  <td class="w3-elemento-titulo-tableBlan anchoResp textoOverflow">
                    <span matTooltip="{{escResp(pro.responsable)}}">{{escResp(pro.responsable)}}</span>
                  </td>
                  <td class="w3-elemento-titulo-tableBlan"> {{escribirDatPro(pro,'plan')}} </td>
                  <td class="w3-elemento-titulo-tableBlan" [ngClass]="pintarBordPro(escribirDatPro(pro, 'ejec'),escribirDatPro(pro, 'plan'))"> {{escribirDatPro(pro,'ejec')}} </td>
                  <ng-container *ngFor="let ad of actividades[0].activities[0].additions; let i = index">
                    <td class="w3-elemento-titulo-tableBlan alinDer formAd">
                      <span matTooltip="{{escribirAdicionalPro(pro.activities,i)}}">{{escribirAdicionalPro(pro.activities,i)}}</span>
                    </td>
                  </ng-container>
                </tr>
                <ng-container *ngIf="pro.check">
                  <ng-container *ngFor="let act of pro.activities">
                    <tr *ngIf="existAct(act)">
                      <td class="w3-elemento-titulo-tableBlan textoOverflow alingIzq"> 
                        <table class="w3-table">
                          <td style="min-width: 0.6vw; padding: 0vh;"></td>
                          <td><span matTooltip="{{act.name}}"></span>{{act.name}}</td>
                        </table>
                      </td>
                      <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{escFech(act.initial_date)}} </td>
                      <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{diasTot(act.initial_date, act.end_date)}} </td>
                      <td class="w3-elemento-titulo-tableBlan anchoDias textoOverflow"> {{escFech(act.end_date)}} </td>
                      <td class="w3-elemento-titulo-tableBlan anchoResp textoOverflow">
                        <span matTooltip="{{escResp(act.responsible)}}">{{escResp(act.responsible)}}</span>
                      </td>
                      <td class="w3-elemento-titulo-tableBlan"> {{escribirDat(act.historico, 'plan')}} </td>
                      <td *ngIf="escribirDat(act.historico, 'ejec') === 0" 
                      [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))" 
                      class="w3-elemento-titulo-tableBlan" (dblclick)="edicionEj(act.historico, act.id)" style="cursor: pointer;"> 
                        {{escribirDat(act.historico, 'ejec')}} 
                      </td>
                      <td *ngIf="escribirDat(act.historico, 'ejec') !== 0 && escribirDat(act.historico, 'ejec') !== null" 
                      [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))" 
                      class="w3-elemento-titulo-tableBlan" (dblclick)="edicionEj(act.historico, act.id)" style="cursor: pointer;"> 
                        {{escribirDat(act.historico, 'ejec')}} 
                      </td>
                      <td *ngIf="escribirDat(act.historico, 'ejec') === null" 
                      [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))" 
                      class="w3-elemento-titulo-tableBlan textoAyud" (dblclick)="edicionEj(act.historico, act.id)" style="cursor: pointer;">
                        0 
                      </td>
                      <ng-container *ngFor="let ad of act.additions">
                        <td *ngIf="ad.type !== 'numero' && ad.type !== 'moneda' && ad.type !== 'archivo'" class="textoOverflow w3-elemento-titulo-tableBlan alinIzq anchosad"
                          style="cursor: pointer;" (dblclick)="edicionAd(ad, act.id, $event)" matTooltip="{{escribirAdicional(ad)}}">
                          {{escribirAdicional(ad)}}
                        </td>
                        <td *ngIf="ad.type === 'moneda'" class="textoOverflow w3-elemento-titulo-tableBlan alinDer anchosad">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                        <td *ngIf="ad.type === 'numero'" class="textoOverflow w3-elemento-titulo-tableBlan alinIzq anchosad">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                        <td *ngIf="ad.type === 'archivo'" class="textoOverflow w3-elemento-titulo-tableBlan alinCen anchosad">
                          <div class="alinCen">
                            <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch" style="cursor: pointer;"
                            (contextmenu)="onContextMenu($event, ad)" (click)="abirPDF(ad, $event);">
                              <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                            </div>
                            <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch" style="cursor: pointer;"
                            (dblclick)="edicionAd(ad, act.id, $event)">
                              <mat-icon  matTooltip="Adjuntar archivo" class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="cargando_arch" class="w3-modal">
  <div class="contenedorCargando" style="width: 25%; height: 25%;">
    <div class="iconoCarga spinner-border text-secondary"></div>
  </div>
</div>