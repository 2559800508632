import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Meses, obtenerError, Responsables, procesamiento, obtenerTamMax } from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ActasService } from './../../../services/seguimiento/actas/actas.service';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-proyectos2',
  templateUrl: './proyectos2.component.html',
  styleUrls: ['./proyectos2.component.css']
})
export class Proyectos2Component implements OnInit {

  /* Variables de plan */
  private idPlaneacion = 0;

  /* Variables de edición en tabla */
  private edicion: boolean;
  private semaforo: any = [];
  /* Variables de funcionamiento de tablas */
  public mostabla = false;
  public skeleton = new Array(15);
  public skeletonColums = new Array(6);
  public mostrarAllActas = false;
  public actividadesFull: any = [];
  public adicionales = [];
  public  ordenamiento = 'id';
  private fechaAct = new Date();
  /* Variables de las tabla */
  private actas = [];
  public actasMostrar = [];
  public actividadesActas = [];
  /* Variables de filtros */
  public aniosDisponibles = [];
  public diasLab;
  public Periodo = '';
  public responsables: Responsables[] =[];
  public responsablesActas: Responsables[] = [];
  public responsablesActividadesActas: Responsables[] = [];
  public selecAllResActas = true;
  public selecAllResActiv = true;
  /* Variables para el formateo de cantidades */
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  /** Lectura de archivos */
  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = [];
  message = '';
  imageName = "";
  /** click izquierdo */
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition =  {x: '0', y: '0'};
  private adDelete : any;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private actasService: ActasService,
    private excelService: ExcelService,
    private translate: TranslateService,
  ) {
    this.edicion = dataService.edition;
    var param = this.rutaActiva.snapshot.params;
    this.idPlaneacion = Number(param['planeacion']);
    this.obtenerDatos();
  }
  ngOnInit(): void {
  }
  /* Consultas para obtención de información */
  private obtenerDatos(){
    this.actasService.getPlaneacion().subscribe(response =>{
      this.obtenerFiltros(response);
      this.actasService.getActas().subscribe(response => {
        this.obtenerActas(response);
        this.actasService.getActividadesActas().subscribe(response => {
          this.obtenerActividadesActas(response);
          this.actasService.getAdicionales().subscribe(response =>{
            this.obtenerAdicionalesActividades(response);
          });
        });
      });
    });
  }
  /* Extracción de datos */
  // Filtros
  private obtenerFiltros(array: any){
    const plan = array.filter(plan => plan.id === this.idPlaneacion+'');
    if(plan.length !== 0){
      const planeacion = plan[0];
      
      this.edicion = planeacion.isActive;
      const sema = this.ordenamientoSem(planeacion.signals);
      if( Number(sema[sema.length-1].value_01) > 100){
        const valFinal = Number(sema[sema.length-1].value_01); 
        for(const sem of sema){
          this.semaforo.push({
            valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
            valfin: Math.round((Number(sem.value_01)*100)/valFinal),
            color: sem.color[0],
          });
        }      
      }else{
        for(const sem of sema){
          this.semaforo.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }
      }

      this.diasLab = planeacion.business_days;
      this.aniosDisponibles = Meses.obtenerPeriodos(new Date(planeacion.initial_date), new Date(planeacion.end_date));
      this.Periodo = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1].periodo;


      this.actasService.getResponsables().subscribe(response => {
        this.responsables = [];
        for(const are of response){
          if(are.institution.id === planeacion.institution.id){
            for(const res of are.responsibles){
              this.responsables.push({
                nombre: res.position,
                id: res.id,
                check: true,
              });
            }
          }
        }
        this.responsables = this.ordenamientoAlf(this.responsables);
      });
    }
  }
  // Actas
  private obtenerActas(array: any){
    var auxiliar = [];
    for(const acta of array){
      //var resExiste = this.responsables.filter(res => res.id === acta.validator.id);
      if(Number(acta.plan.id) === this.idPlaneacion){//resExiste.length !== 0 && 
        auxiliar.push({
          nombre: acta.name,
          descripcion: acta.description,
          responsable: acta.validator,
          acta: acta.attachment,
          id: acta.id,
          check: true,
          fecha_ini: undefined,
          fecha_fin: undefined,
          actividades: [],  
        });
      }
    }
    this.actas = auxiliar;
    this.actas = this.ordenamientoPer(this.actas);
  }
  //Actividades de las actas
  private obtenerActividadesActas(array){
    if(this.actas.length !== 0){
      for(const actas of this.actas){
        const actividades = array.filter(actaId => actaId.minute.id === actas.id);
        if(actividades.length !== 0){
          var auxiliar = [];
          for(const acti of actividades){
            var hist = [];
            if(acti.history.length === 0){
              hist = Meses.obtenerPlaneado(new Date(acti.initial_date), new Date(acti.end_date), this.diasLab);
              for(const his of hist){
                this.actasService.postHistorico(his, Number(acti.id)).subscribe(response =>{
                  his.id = response.id;
                },err =>{
                  let er = obtenerError.errorObt(err['error'].message);
                  Swal.fire({
                    title: er,
                    text: '',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo,
                  });
                });
              }
            }else{
              for(const his of acti.history){
                hist.push({
                  periodo: his.period,
                  planeado: Number(his.planned),
                  ejecutado: his.executed,
                  id: his.id,
                });
              }
            }

            auxiliar.push({
              nombre: acti.name,
              fecha_inicio: this.obtFech(acti.initial_date),
              fecha_fin: this.obtFech(acti.end_date),
              projectId: acti.minute.id,
              responsable: acti.responsible,
              validacion: acti.validated,
              completada: acti.completed,
              id: acti.id,
              adicionales: [],
              history: hist,
            });
          }
          actas.actividades = auxiliar;
          this.actividadesFull = this.actividadesFull.concat(auxiliar);

        }
      }
    }
  }
  private actividadIncompleta(act: any){
    var mes = Meses.getMesesS(this.Periodo.split('-')[0])<10?'0'+Meses.getMesesS(this.Periodo.split('-')[0]):''+Meses.getMesesS(this.Periodo.split('-')[0]);
    const momentAct = moment('01-'+mes+'-20'+this.Periodo.split('-')[1], 'DD-MM-YYYY');

    var fechaFinAct;
    if((act.fecha_fin+'').includes('Z')){
      fechaFinAct = this.obtFech(act.fecha_fin);
    }else{
      fechaFinAct = act.fecha_fin;
    }
    mes = (fechaFinAct.getMonth()+1)<10?'0'+(fechaFinAct.getMonth()+1):''+(fechaFinAct.getMonth()+1);
    var dia = fechaFinAct.getDate()<10?'0'+fechaFinAct.getDate():''+fechaFinAct.getDate();
    var anio = fechaFinAct.getFullYear();
    const momnetFinAct = moment(dia+'-'+mes+'-'+anio, 'DD-MM-YYYY');
    
    if(momentAct.isAfter(momnetFinAct) && act.completed !== true){
      return true;
    }
    return false;
  }
  //Adicionales de las actividades
  private obtenerAdicionalesActividades(array){
    if(this.actas.length !== 0){
      for(const acta of this.actas){
        if(acta.actividades.length !== 0){
          for(const act of acta.actividades){
            var adicional = array.filter(adi => adi.activity !== null && adi.activity.id === act.id);
            adicional = this.ordenamientoAdi(adicional);
            act.adicionales = adicional;
          }
        }
      }
      this.obtenerfechasActas();
      this.adicionales = this.actas[0].actividades[0].adicionales;
      this.dataTablaMostrar('cam');
      this.obtResponsableDinamico();
    }
    this.mostabla = true;
  }
  /** Obtención de los datos */
  //Fechas de proyectos
  public obtenerfechasActas(){
    for(const pro of this.actas){
      for(const act of pro.actividades){
        if(pro.fecha_ini === undefined){
          pro.fecha_ini = act.fecha_inicio;
        }else{
          if(pro.fecha_ini > act.fecha_inicio){
            pro.fecha_ini = act.fecha_inicio;
          }
        }
        if(pro.fecha_fin === undefined){
          pro.fecha_fin = act.fecha_fin;
        }else{
          if(pro.fecha_fin < act.fecha_fin){
            pro.fecha_fin = act.fecha_fin;
          }
        }
      }
    }
  }
  //Obtención de fechas
  public obtFecha(fec: any){
    var fecha =  '';
    if(fec != undefined){
      switch(fec.getDate()){
        case 1: fecha+='01-'; break;
        case 2: fecha+='02-'; break;
        case 3: fecha+='03-'; break;
        case 4: fecha+='04-'; break;
        case 5: fecha+='05-'; break;
        case 6: fecha+='06-'; break;
        case 7: fecha+='07-'; break;
        case 8: fecha+='08-'; break;
        case 9: fecha+='09-'; break;
        default: fecha+= fec.getDate()+'-'; break;
      }
      switch((fec.getMonth()+1)){
        case 1: fecha+='01'; break;
        case 2: fecha+='02'; break;
        case 3: fecha+='03'; break;
        case 4: fecha+='04'; break;
        case 5: fecha+='05'; break;
        case 6: fecha+='06'; break;
        case 7: fecha+='07'; break;
        case 8: fecha+='08'; break;
        case 9: fecha+='09'; break;
        default: fecha+= (fec.getMonth()+1)+''; break;
      }
      fecha +='-'+fec.getFullYear();
    }
    return fecha;
  }
  /* Obtención de datos a mostrar */
  public dataTablaMostrar(parametros: any){
    var actasTot = [].concat(this.actas);    
    this.actasMostrar = [];
    for(const acta of actasTot){
      switch(parametros){
        case 'all':
          if( this.mostrarConResponsables(acta) ){
            this.actasMostrar.push({
              nombre: acta.nombre,
              descripcion: acta.descripcion,
              responsable: acta.responsable,
              acta: acta.acta,
              id: acta.id,
              check: acta.check,
              fecha_ini: acta.fecha_ini,
              fecha_fin: acta.fecha_fin,
              actividades: acta.actividades,
            });
          }
        break;
        case 'cam':
          if( this.mostrar(acta) ){
            this.actasMostrar.push(acta);
          }
        break;
      }
    }
    if(parametros === 'all' && !this.selecAllResActiv){
      const res = this.responsablesActividadesActas.filter(res => res.check===true);
      if(res.length!==0){
        for(const a of this.actasMostrar){
          const act = a.actividades.filter(act => this.responsableActOfActas(act)===true);
          a.actividades = act;
        }        
      }
    }
  }
  //Obtención de responsables
  private obtResponsableDinamico(){
    var aux = [], aux2 = [];
    for(const acta of this.actasMostrar){
      const res = this.responsables.filter(res => res.id === acta.responsable.id);
      const existe = aux.filter(res => res.id === acta.responsable.id);
      if(existe.length === 0){
        aux.push({
          nombre: res[0].nombre,
          id: res[0].id,
          check: true,
        });
      }
      for(const resp of acta.actividades){
        const resAct = this.responsables.filter(res => res.id === resp.responsable.id);
        const existeAct = aux2.filter(res => res.id === resp.responsable.id);
        if(existeAct.length === 0){
          aux2.push({
            nombre: resAct[0].nombre,
            id: resAct[0].id,
            check: true,
          });
        }
      }
    }
    this.responsablesActas = this.ordenamientoAlf(aux);
    this.selecAllResActas = true;
    this.responsablesActividadesActas = this.ordenamientoAlf(aux2);
    this.selecAllResActiv = true;
  }
  //Mostrar sin considerar responsable
  public mostrar(acta: any){
    const per = this.perSelected(); 
    for(const acti of acta.actividades){
      if( (!acti.completada || acti.completada === null) && this.actividadIncompleta(acti) ){
        const filter = acti.history.filter(his => his.periodo === per);
        if(filter.length === 0){  
          acti.history.push({
            periodo: per,
            planeado: 100,
            ejecutado: null,
            id: 0,
          });
        }
      }
    }

    let tieneAct = false;
    for(const acti of acta.actividades){
      const filter = acti.history.filter(his => his.periodo === per);
      if(filter.length !== 0 && (!acti.completada || acti.completada === null)){
        tieneAct = true;
      }    
    }
    
    if( this.existeAnio(acta) || tieneAct ){
      return true;
    }else{
      return false;
    }
  }
  //Mostrar considerando los responsables
  public mostrarConResponsables(acta: any){
    const per = this.perSelected(); 
    for(const acti of acta.actividades){
      if( (!acti.completada || acti.completada === null) && this.actividadIncompleta(acti) ){
        const filter = acti.history.filter(his => his.periodo === per);
        if(filter.length === 0){
          acti.history.push({
            periodo: per,
            planeado: 100,
            ejecutado: null,
            id: 0,
          });
        }
      }
    }

    let tieneAct = false;
    for(const acti of acta.actividades){
      const filter = acti.history.filter(his => his.periodo === per);
      if(filter.length !== 0 && (!acti.completada || acti.completada === null)){
        tieneAct = true;
      }    
    }
    console.log("🚀 ~ tieneAct =>", tieneAct, acta)
    
    if( (this.responsableActas(acta) && this.existeAnio(acta)) || tieneAct ){
      return true;
    }else{
      return false;
    }
  }
  //Metodos de decición
  private existeAnio(acta: any){
    if(acta.fecha_ini !== undefined && acta.fecha_fin !== undefined){
      var perSelected = this.perSelected();
      var perIni = Meses.getMesesN(acta.fecha_ini.getMonth()+1) + '-' + (''+acta.fecha_ini.getFullYear()).substring(2,4);
      var perFin = Meses.getMesesN(acta.fecha_fin.getMonth()+1) + '-' + (''+acta.fecha_fin.getFullYear()).substring(2,4);
    
      return Meses.compararPer(perIni, perFin, perSelected);
    }
    return false;
  }
  private responsableActas(acta: any){
    for(const res of this.responsablesActas){
      if(res.id === acta.responsable.id && res.check){
        return true;
      }
    }
    return false;
  }
  public responsableActOfActas(act: any){
    for(const res of this.responsablesActividadesActas){
      if(res.id === act.responsable.id && res.check){
        return true;
      }
    }
    return false;
  }
  // Metodos de busqueda
  private perSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  /* Funcionamiento de los filtros */
  //Filtro de años
  public cambioAnio(per: any){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === per? mes.check = true: mes.check = false;        
        mes.periodo === per? this.Periodo = mes.periodo: '';
      }
    }
    this.dataTablaMostrar('cam');
    this.obtResponsableDinamico();
  }
  //Filtro de lider actas
  public cambioLider(res){
    res.check = !res.check;
    var resSelec = 0;
    this.responsablesActas.forEach(res => res.check?resSelec++:'');
    if(resSelec === this.responsablesActas.length){
      this.selecAllResActas = true;
    }else{
      this.selecAllResActas = false;
    }
    this.dataTablaMostrar('all');
  }
  public selecAllLideres(){
    this.responsablesActas.forEach(res => this.selecAllResActas?res.check=false:res.check=true);
    this.selecAllResActas = !this.selecAllResActas;
    if(this.selecAllResActas){
      this.dataTablaMostrar('all');
    }
  }
  //Filtro de responsable actividades
  public cambioResponsable(res){
    res.check = !res.check;
    var resSelec = 0;
    this.responsablesActividadesActas.forEach(res => res.check?resSelec++:'');
    if(resSelec === this.responsablesActividadesActas.length){
      this.selecAllResActiv = true;
    }else{
      this.selecAllResActiv = false;
    }
    this.dataTablaMostrar('all');
  }
  public selecAllResponsables(){
    this.responsablesActividadesActas.forEach(res => this.selecAllResActiv?res.check=false:res.check=true);
    this.selecAllResActiv = !this.selecAllResActiv;
    if(this.selecAllResActiv){
      this.dataTablaMostrar('all');
    }
  }
  //Vision de los tres puntos
  public tresPuntosVis(){
    var sel = 0;
    for(const res of this.responsablesActas){
      if(res.check){
        sel++;
      }
    }
    if(sel <= 1)
      return false;
    return true;
  }
  public tresPuntosVisResp(){
    var sel = 0;
    for(const res of this.responsablesActividadesActas){
      if(res.check){
        sel++;
      }
    }
    if(sel <= 1)
      return false;
    return true;
  }
  /** Borrar filtros */
  public borrarFiltro(){
    document.body.style.cursor = 'wait';

    this.Periodo = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1].periodo;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.periodo !== this.Periodo){
          mes.check = false;
        }else{
          mes.check = true;
        }
      }
    }
    
    this.responsablesActas.forEach(res => res.check = true);
    this.responsablesActividadesActas.forEach(res => res.check = true);

    this.ordenamiento = 'id';

    this.dataTablaMostrar('cam');
    this.obtResponsableDinamico();

    setTimeout(() => {
      document.body.style.cursor = 'default';        
    }, 2000 );
  }
  /** Metodo de obtención de fecha */
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  /* Eventos de tablas */
  public mostrarTodasLasActas(){
    this.actasMostrar.forEach(acta => this.mostrarAllActas?acta.check=false:acta.check=true)
    this.mostrarAllActas = !this.mostrarAllActas;
  }
  public mostrarActas(acta: any){
    acta.check = !acta.check;
    var actasAbiertas = 0;
    for(const actas of this.actasMostrar){
      if(actas.check){
        actasAbiertas++;
      }
    }
    if(actasAbiertas === this.actasMostrar.length){
      this.mostrarAllActas = true;
    }else if(actasAbiertas === 0){
      this.mostrarAllActas = false;
    }
  }
  public existAct(act: any){
    var existePeriodo = false;
    var per_sel = this.perSelected();

    if(act.fecha_inicio != undefined && act.fecha_fin != undefined){
      var per1 = Meses.getMesesN(new Date(act.fecha_inicio).getMonth()+1) + '-' + (''+new Date(act.fecha_inicio).getFullYear()).substring(2,4);
      var per2 = Meses.getMesesN(new Date(act.fecha_fin).getMonth()+1) + '-' + (''+new Date(act.fecha_fin).getFullYear()).substring(2,4);
      existePeriodo = Meses.compararPer(per1, per2, per_sel);
    }

    const per = this.perSelected(); 
    const filter = act.history.filter(his => his.periodo === per);
    if(filter.length !== 0){
      return true
    }

    return false;
  }
  public abrirArchivo(acta: any){
    this.actasService.getFileActas(acta).subscribe(response =>{
      let fileName = acta.acta;
      switch(fileName.split('.')[fileName.split('.').length-1]){
        case 'pdf':
          const mediaType = 'application/pdf';
          const blob = new Blob([response], { type: mediaType });
          const nav = (window.navigator as any);

          if (nav.msSaveOrOpenBlob)  {
            nav.msSaveOrOpenBlob(blob, fileName);
          } else {
            const a = document.createElement('a');
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            window.open(""+a.href,"_blank");

            setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
            }, 0); 
          }
        break;
        case 'png':
        case 'jpg':
          const mediaTypei = 'image/'+fileName.split('.')[fileName.split('.').length-1];
          const blobi = new Blob([response], { type: mediaTypei });
          const navi = (window.navigator as any);

          if (navi.msSaveOrOpenBlob)  {
            navi.msSaveOrOpenBlob(blobi, fileName);
          } else {
            const a = document.createElement('a');
            const url = URL.createObjectURL(blobi);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            window.open(""+a.href,"_blank");
            a.click();

            setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
            }, 0); 
          }
        break;
        default:
          const mediaTyped = 'aplication/'+fileName.split('.')[fileName.split('.').length -1];
          const blobd = new Blob([response], { type: mediaTyped });
          const navd = (window.navigator as any);

          if (navd.msSaveOrOpenBlob)  {
            navd.msSaveOrOpenBlob(blobd, fileName);
          } else {
            const a = document.createElement('a');
            const url = URL.createObjectURL(blobd);
            a.href = url;
            a.download = fileName.split('-')[fileName.split('-').length -1];
            document.body.appendChild(a);
            a.click();

            setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);  
            }, 0); 
          }
        break;
      }
    },error =>{
      this.messageError(error);
    });
  }
  public puedeValidar(responsable: any, actividad: any){
    if(responsable.email.toLowerCase() === this.dataService.currentUser.toLowerCase() && actividad.completada){
      var text = '';
      if(actividad.validacion){
        text = 'Esta seuro de quitar la validación de la actividad'
      }else{
        text = 'Esta seguro de validar la actividad'
      }
      Swal.fire({
        title: '¿'+text+'?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          actividad.validacion = !actividad.validacion;
          this.actasService.patchActividades(actividad).subscribe(response =>{
            Swal.fire({
              title: 'Actividad validada',
              text: '',
              icon: 'success',
              showConfirmButton: false,
              timer: this.dataService.tiempo
            });
          },error =>{
            this.messageError(error);
          });
        }
      });
    }else{
      var subText = ''
      if(!actividad.completada){
        subText = 'Se debe completar la actividad';
      }
      Swal.fire({
        title: 'No puedes realizar la validación',
        text: ''+subText,
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  /* Escritura en tabla */
  public obtenerDias(fIni: any, fFin: any){
    var dias = 0, f1: any = new Date(fIni), f2: any = new Date(fFin);
    var diff = Math.round((f2-f1)/(1000*60*60*24));
    switch(this.diasLab){
      case 'Lunes-Viernes':
        var sem = Math.trunc(diff/7);
        dias = diff - sem*2;
      break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(diff/7);
        dias = diff - sem;
      break;
      case 'Lunes-Domingo':
        dias = diff;
      break;
    }
    return dias+1;
  }
  //Escribir datos
  public escribirDat(historico: any, tip: string){
    var per = this.perSelected();
    if(historico){
      for(const dat of historico){
        if(per === dat.periodo){
          if(tip === 'plan'){
            return dat.planeado;
          }else if(tip === 'ejec'){
            return dat.ejecutado;
          }
        }
      }
    }
  }
  public escribirDatPro(acta: any, tip: string){
    var per = this.perSelected();
    var dias = 0, porcentajeplan = 0, porcentajeejec = 0;
    for(const act of acta.actividades){
      dias += this.obtenerDias(act.fecha_inicio,act.fecha_fin);
    }
    var suma = false;
    for(const act of acta.actividades){
      if(new Date( 20+per.split('-')[1], Meses.getMesesS(per.split('-')[0])-1 ) > new Date(act.fecha_fin)){
        suma = true;
      }
      for(const his of act.history){
        if(his.periodo === per || suma){
          
          if(suma){
            suma = false;
            var diasAct = this.obtenerDias(act.fecha_inicio,act.fecha_fin);
            var pesoAct = Number((diasAct*100/dias).toFixed(2));
            porcentajeplan += Number((100*pesoAct/100).toFixed(2));
            let perAct = Meses.obtnerPer( new Date(act.fecha_fin) )
            var ejeAct = 0;
            for(const h of act.history){
              if(h.periodo === perAct){
                ejeAct = h.ejecutado;
                break;
              }
            }
            porcentajeejec += Number((ejeAct*pesoAct/100).toFixed(2));
          }else{
            var diasAct = this.obtenerDias(act.fecha_inicio,act.fecha_fin);
            var pesoAct = Number((diasAct*100/dias).toFixed(2));
            porcentajeplan += Number((his.planeado*pesoAct/100).toFixed(2));
            porcentajeejec += Number((his.ejecutado*pesoAct/100).toFixed(2));
          }
        }
      }
    }
    if(tip === 'plan'){
      if( Number(porcentajeplan.toFixed(2)) >= 99.99 ){
        return 100;
      }
      return Number(porcentajeplan.toFixed(2));
    }
    return Number(porcentajeejec.toFixed(2));
  }
  // Avance acumulado
  public obtenerAvanceAcumPro(acta){
    var per = this.perSelected();
    var dias = 0, porcentajeplan = 0;
    for(const act of acta.actividades){
      dias += this.obtenerDias(act.fecha_inicio,act.fecha_fin);
    }
    for(const act of acta.actividades){
      let avance = this.avanceAcumulado(act, per);
      var diasAct = this.obtenerDias(act.fecha_inicio,act.fecha_fin);
      var pesoAct = Number((diasAct*100/dias).toFixed(2));
      porcentajeplan += Number((avance*pesoAct/100).toFixed(2));

    }
    return Number(porcentajeplan.toFixed(2));
  }
  public avanceAcumulado(act: any, per?: any){
    if(per === undefined){
      per = this.perSelected();
    }
    var valFin = 0;
    var res = this.actividadesFull.filter(ac => ac.id === act.id);
    if(res.length !== 0){
      var hist = res[0].history;
      hist = this.ordenamientoId(hist);
      hist = this.ordenamientoPlaneado(hist);
      for(const h of hist){
        if(per !== h.periodo){
          if(h.ejecutado !== null){
            valFin = h.ejecutado;
          }
        }else{
          if(h.ejecutado !== null){
            valFin = h.ejecutado;
          }
          break;
        }
      }
    }
    return valFin;
  }
  //Bordes
  public pintarBordPro(eje: any, plan: any){
    if(eje !== 0){
      var color = '';
      var por = eje*100/plan;
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(por >= valfin){
        por = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(por >= ab && por <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintarBord(eje: any, plan: any){
    var porcentaje = undefined;
    var color = '';
    if(eje === 0 && plan !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro';
    }else if(eje === 0 && plan !== 0){
      porcentaje = 0;
    }else if(eje !== 0 && eje !== null){
      porcentaje = eje*100/plan;
      
    }
    if(porcentaje !== undefined){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcentaje >= valfin){
        porcentaje = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcentaje >= ab && porcentaje <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  //Escritura de los adicionales
  public escribirAdicionalPro(actividades: any, i: number){
    var presTot = 0;
    var presTotN = 0;
    for(const act of actividades){
      if(act.adicionales[i] !== null && act.adicionales[i].type === 'moneda'){
        presTot += act.adicionales[i].amount;
      }
    }
    if(actividades[0].adicionales[i].type === 'moneda'){
      return this.formatearnormal(presTot);
    }else{
      return '';
    }
  }
  public escribirAdicional(adic: any){
    if(adic != undefined){
      switch(adic.type){
        case 'texto':
          return this.obtComment(adic.comments);
        case 'moneda':
          return this.formatearnormal(adic.amount);
        case 'numero':
          return this.obtCommentNumero(adic.comments);
        case 'archivo':
          var per  = this.perSelected();
          for(const file of adic.files){
            if( per === Meses.obtnerPer(this.obtFech(file.created_at))){
              return file.name.split('-')[file.name.split('-').length - 1];
            }
          }
          return '';
      }
    }
  }
  private obtCommentNumero(com : any){
    for(const comen of com){
      var per = 0;
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            per = an.anio;
          }
        }
      }
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
  private obtComment(com : any){
    for(const comen of com){
      var per =  this.perSelected();
      var percom = Meses.obtnerPer(new Date(comen.created_at));
      if(per === percom){
        return comen.comment;
      }
    }
    return '';
  }
  // Abrir adicionales
  public abirPDF(ad, event?: any){
    const per = this.perSelected();
    for(const fil of ad.files){
      if(Meses.obtnerPer(this.obtFech(fil.created_at)) === per){
        this.actasService.getFile(ad.id, fil.id).subscribe(response =>{
          switch(fil.name.split('.')[fil.name.split('.').length-1]){
            case 'pdf':
              const mediaType = 'application/pdf';
              const blob = new Blob([response], { type: mediaType });
              const nav = (window.navigator as any);

              if (nav.msSaveOrOpenBlob)  {
                nav.msSaveOrOpenBlob(blob, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blob);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            case 'png':
            case 'jpg':
              const mediaTypei = 'image/'+fil.name.split('.')[fil.name.split('.').length-1];
              const blobi = new Blob([response], { type: mediaTypei });
              const navi = (window.navigator as any);

              if (navi.msSaveOrOpenBlob)  {
                navi.msSaveOrOpenBlob(blobi, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobi);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            default:
              const mediaTyped = 'aplication/'+fil.name.split('.')[fil.name.split('.').length -1];
              const blobd = new Blob([response], { type: mediaTyped });
              const navd = (window.navigator as any);

              if (navd.msSaveOrOpenBlob)  {
                navd.msSaveOrOpenBlob(blobd, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobd);
                a.href = url;
                a.download = fil.name.split('-')[fil.name.split('-').length -1];
                document.body.appendChild(a);
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
          }
        },error =>{
          this.messageError(error);
        });
      }
    }
  }
  public editar(his: any, idAct: any){
    var perSel = '';
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSel = mes.periodo;
        }
      }
    }
    var perAct = Meses.obtnerPer(this.fechaAct);
    var perAnt = Meses.obtnerPerAnt(this.fechaAct);
    if(this.edicion){
      this.dat(his,perSel,idAct);
      return ;
    }else if(Meses.compararPer(perAnt,perAct,perSel)){
      this.dat(his,perSel,idAct)
    }else{
      Swal.fire({
        title: 'Periodo no editable',
        text: 'Solo se puede editar la información del mes actual y un mes anterior',
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
    }
    
  }
  public async dat(his: any, perSel: any, idAct: any){
    var inputValue = 0, introdujo: any, guardo = false;
    
    for(const per of his){
      if(per.periodo === perSel){
        inputValue = per.ejecutado;
      }
    }

    introdujo = await Swal.fire({
      title: 'Ingresa el dato',
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (Number(value) > 100) {
          return 'el valor no puede ser mas grande que 100';
        }
        guardo = true;
        return '';
      }
    });

    if(guardo){
      const reg = his.filter(h => h.periodo === perSel);
      if(reg.length !== 0 && reg[0].id !== 0){
        for(const per of his){
          if(per.periodo === perSel){
            if(introdujo.value !== ''){
              per.ejecutado = Number(introdujo.value);
            }else{
              per.ejecutado = null;
            }
            this.actasService.PatchHistorico(per, Number(per.id)).subscribe(response =>{
              
              Swal.fire({
                title: 'Información editada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
            },error =>{
              this.messageError(error);
            });
          }
        }
      }else if(reg.length !== 0 && reg[0].id === 0){
        this.actasService.postHistorico(reg[0], Number(idAct)).subscribe(res =>{
          reg[0].id = res.id;
          if(introdujo.value !== ''){
            reg[0].ejecutado = Number(introdujo.value);
          }else{
            reg[0].ejecutado = null;
          }
          this.actasService.PatchHistorico(reg[0], Number(reg[0].id)).subscribe(response =>{
            Swal.fire({
              title: 'Información editada',
              text: '',
              icon: 'success',
              showConfirmButton: false,
              timer: this.dataService.tiempo
            });
          },error =>{
            this.messageError(error);
          });
        });
      }
    }
  }
  public async edicionAd(adic: any, id: any, event?: any){
    var inputValue : any, introdujo: any, guardo = false;

    switch(adic.type){
      case 'texto':
        inputValue =  this.obtComment(adic.comments);
        introdujo = await Swal.fire({
          title: 'Ingrese el comentario',
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'moneda':
        inputValue =  adic.amount;
        introdujo = await Swal.fire({
          title: 'Ingrese el monto',
          text: "",
          input: 'number',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'archivo':
        inputValue =  adic.file;
        introdujo = await Swal.fire({
          title: 'Seleccione su archivo',
          text: "",
          input: 'file',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }

    if(guardo){
      switch(adic.type){
        case 'texto':
          var existe = false;
          var per =  this.perSelected();
          for(const comen of adic.comments){
            var percom = Meses.obtnerPer(new Date(comen.created_at));
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.actasService.patchComment(comen, adic.id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                this.messageError(err)
              });
            }
          }
          if(!existe){
            var date = (new Date(Meses.getMesesS(per.split('-')[0])+'-1-'+per.split('-')[1]));
            adic.comments.push({
              comment: introdujo.value,
              created_at: date.toISOString(),
            });
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString()
            }
            this.actasService.postComment(aux, adic.id).subscribe(response =>{
              this.actasService.getAdicionales().subscribe(response =>{
                this.obtenerAdicionalesActividades(response);
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  showConfirmButton: false,
                  icon: 'success',
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              this.messageError(err);
            });
          }
        break;
        case 'moneda':
          adic.amount = introdujo.value;
        break;
        case 'archivo':
          this.progressInfo = [];
          introdujo.value ? this.imageName = introdujo.value.name : this.imageName = introdujo.value.length + " archivos";
          this.selectedFiles = introdujo.value;
          var per  = this.perSelected();
          var existe = false;
          if( this.selectedFiles["size"] > obtenerTamMax.getPeso() ){
            Swal.fire({
              title: obtenerTamMax.getTexto(),
              text: '',
              showConfirmButton: false,
              icon: 'error',
              timer: this.dataService.tiempo,
              didClose: () => {
                this.edicionAd(adic, id, event);
              }
            });
            
          }else{
            if(adic.files.length !== 0){
              for(const fil of adic.files){
                if( fil.periodo === per){
                  existe = true;
                }
              }
            }
            if(!existe){
              document.getElementById('cargando_arch')['style'].display = 'block';
              const fecha = new Date(Meses.getMesesS(per.split('-')[0])+'/1/'+per.split('-')[1]);
              this.actasService.postFile(this.selectedFiles, adic.id, fecha.toISOString()).subscribe(response =>{
                this.actasService.getAdicionales().subscribe(response =>{
                  this.obtenerAdicionalesActividades(response);
                  document.getElementById('cargando_arch')['style'].display = 'none';
                  Swal.fire({
                    title: 'Información actualizada',
                    text: '',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: this.dataService.tiempo
                  });
                });
              },err =>{
                this.messageError(err)
              });
            }
          }
        break;
      }
    }else{
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  //eliminar archivos
  public deleteArchivo(){
    const per = this.perSelected();
    Swal.fire({
      title: '¿Seguro que deseas eliminar el archivo?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        for(const fil of this.adDelete.files){
          if(Meses.obtnerPer(this.obtFech(fil.created_at)) === per){
            this.actasService.deleteFile(this.adDelete.id, fil.id).subscribe(response =>{
              this.actasService.getAdicionales().subscribe(response =>{
                this.obtenerAdicionalesActividades(response);
                Swal.fire({
                  title: 'Información eliminada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            });
          }
        }
      }
    },err =>{
      this.messageError(err)
    });
  }
  // Menu click izquierdo
  public onContextMenu(event: MouseEvent, item) {
    event.preventDefault(); 
    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px'; 
    this.menuTopLeftPosition.y = event.clientY + 'px'; 

    this.adDelete = item;

    this.matMenuTrigger.openMenu();
  }
  /* Mensaje de error */
  private messageError(err){
    let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
  }
  /** Ordenamiento */
  public ordenamientoTabla(){
    switch(this.ordenamiento){
      case 'id':
        this.actasMostrar = this.ordenamientoAlf(this.actasMostrar);
        this.ordenamiento = 'asc';
      break;
      case 'asc':
        this.actasMostrar = this.ordenamientoAlfInv(this.actasMostrar);
        this.ordenamiento = 'des';
      break;
      case 'des':
        this.actasMostrar = this.ordenamientoPer(this.actasMostrar);
        this.ordenamiento = 'id';
      break;
    }
  }
  /* Exportación a excel */
  public exportarExcel(){
    var title = 'Seguimiento: Planes | ' + this.Periodo;
    var header = ["Planes / Proyectos", "Inicio", "Fin", "Líder", "Avance acumulado", "Planeado", "Ejecutado", "% cumplimiento"];
    if(this.actasMostrar[0].actividades[0].adicionales.length !== 0){
      for(var j = 0; j < this.actasMostrar[0].actividades[0].adicionales.length; j++){
        header.push( "" + this.actasMostrar[0].actividades[0].adicionales[j].name );
      }
    }

    var data = [];
    var aux = [];
    for(const acta of this.actasMostrar){
      aux.push( acta.nombre );
      var fecha = this.obtFecha(acta.fecha_ini );
      aux.push( fecha.split('-')[0]+'/'+fecha.split('-')[1]+'/20'+fecha.split('-')[2] );
      fecha = this.obtFecha(acta.fecha_fin );
      aux.push( fecha.split('-')[0]+'/'+fecha.split('-')[1]+'/20'+fecha.split('-')[2] );
      aux.push( acta.responsable.position );
      aux.push( ' ' )
      aux.push( this.escribirDatPro(acta,'plan') );
      aux.push(this.escribirDatPro(acta, 'ejec')===null? 'null':this.escribirDatPro(acta, 'ejec') );
      aux.push( ' ' )
      if(acta.actividades[0].adicionales.length !== 0){
        for(var j = 0; j < acta.actividades[0].adicionales.length; j++){
          aux.push( this.escribirAdicionalPro(acta.actividades,j) );
        }
      }
      data.push(aux);
      aux = [];
      for(const acti of acta.actividades){
        if(this.existAct(acti)){
          aux.push( '-'+acti.nombre )
          var fecha = this.obtFecha(acti.fecha_inicio );
          aux.push( fecha.split('-')[0]+'/'+fecha.split('-')[1]+'/20'+fecha.split('-')[2] );
          fecha = this.obtFecha(acti.fecha_fin );
          aux.push( fecha.split('-')[0]+'/'+fecha.split('-')[1]+'/20'+fecha.split('-')[2] );
          aux.push( acti.responsable.position );
          aux.push( this.avanceAcumulado(acti) )
          aux.push (this.escribirDat(acti.history, 'plan') );
          aux.push( this.escribirDat(acti.history, 'ejec')===null? 'null':this.escribirDat(acti.history, 'ejec') );
          aux.push( this.cumplimiento(acti, '')/100 )
          if(acti.adicionales.length !== 0){
            for(const ad of acti.adicionales){
              aux.push( this.escribirAdicional(ad) );
            }
          }
          data.push(aux);
          aux = [];
        }
      }
    }
    const NomArch = 'seguimiento_proyectos';
    this.excelService.generateExcelPlanes(title, header, data, NomArch, this.semaforo);
  }
  /** Formato de cantidades */
  public formatearnormal(num: any){
    if(num === null){
      return 0;
    }
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  public formatearConDecimales(num: any){
    num = '' + num.toFixed(2);
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if (splitRight === '.00')
      return  splitLeft;

    return  splitLeft + splitRight;
  }
  /** Metodos de ordenamiento */
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAdi(array: any){
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlfInv(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoPlaneado(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.planeado) > Number(b.planeado)) {
        return 1;
      }
      if (Number(a.planeado) < Number(b.planeado)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public anchoTabla(){
    let div = Math.trunc(this.actasMostrar.length/4)
    return (65-(div*16))+'vh'
  }

  public cumplimiento(item, clase){
    let ejec = 0, plan = 0;
    if(clase === 'plan'){
      for(const it of item.actividades){
        if(this.existAct(it)){
          ejec += this.cumplimiento(it, '');
          plan++;
        }
      }
      return Number((ejec/plan).toFixed(2));
    }else{
      ejec = this.escribirDat(item.history, 'ejec');
      plan = this.escribirDat(item.history, 'plan');
    }

    return Number((ejec*100/plan).toFixed(2));
  }
  public texto(valor: any){
    var color = '';
    if(valor != 0){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(valor >= valfin){
        valor = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(valor >= ab && valor < sem.valfin){
          color = sem.color;
          break;
        }
      }
    }
    switch(color){
      case 'verde': return 'cifraSecVerd';
      case 'azul': return 'cifraSecAzul';
      case 'amarillo': return 'cifraSecAmar';
      case 'naranja': return 'cifraSecNarj';
      case 'rojo': return 'cifraSecRojo';
      default:  return 'cifraSecGris';
    }
  }
}
