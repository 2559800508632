import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Planificacion, Perspectivas, Objetivos, Estrategias, Proyectos, Responsables, procesamiento, obtenerError } from './../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { AlineamientoService } from '../../../services/formulacion/alineamiento/alineamiento.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-alineamiento',
  templateUrl: './alineamiento.component.html',
  styleUrls: ['./alineamiento.component.css']
})
export class AlineamientoComponent implements OnInit {
  
  public planeacion: Planificacion[] = [];
  public perspectiva: Perspectivas[] = [];
  public objetivos: Objetivos[] = [];
  public responsable: Responsables[] = [];
  public estrategia = [];
  public proyecto = [];

  public diccionarioEst: any = [];
  public idPers: number = 0;
  public estratt: any; //para el edit
  public proyectt: any; //para el edit
  public idPlanificacion :any;

  public ObjSelect1: any = [];
  public liderSelect: any;
  public modal = false;
  public modal2 = false;

  /** Grupo de validación de datos */
  formEst = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputNameEdit: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  formProg = new FormGroup({ 
    inputNamePro: new FormControl('', [Validators.required]),
    inputNameProEdit: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidadProg() });


  constructor(
    private router: Router,
    public dataService: DataService,
    private alineamientoeService: AlineamientoService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) {
    this.obtData();
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param["planeacion"]);
  }

  ngOnInit(): void { }

  private ordenarPerspe(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /** Obtención de los datos */
  public obtData(){
    this.alineamientoeService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive
          });
          this.alineamientoeService.getResponsable().subscribe(response => {
            this.responsable = [];
            for(const are of response){
              if(are.institution.id === plan.institution.id){
                for(const resp of are.responsibles){
                  this.responsable.push({
                    id: resp.id,
                    nombre: resp.position,
                    //apellido_paterno: resp.first_surname,
                  });
                }
              }
            }
            this.responsable = this.ordenarPerspe(this.responsable);
          });
        }
      }
      this.alineamientoeService.getPerspectivas().subscribe(response => {
        this.persPertenece(response);
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        this.objetivos = this.ordenarPerspe(this.objetivos);
        this.alineamientoeService.getEstrategias().subscribe(response => {
          this.estPer(response);
          this.estrategia = this.ordenarPerspe(this.estrategia);
        });
        this.alineamientoeService.getProyectos().subscribe(response => {
          this.proyPer(response);
          this.proyecto = this.ordenarPerspe(this.proyecto);
        });
      });
    });
  }
  /** Clasificación en las cuator tablas */
  private persPertenece(array:any){
    var aux = [], aux2 = [];
    for(const per of array){
      if(Number(per.planning.id) === Number(this.idPlanificacion)){
        aux.push({
          id: per.id,
          nombre: per.name,
          descripcion: per.description,
          planningId: per.planning.id,
          objectives: per.objectives
        });
        for(const obj of per.objectives){
          aux2.push({
            nombre: obj.name,
            descripcion: obj.description, 
            ponderacion: obj.weighing, 
            perspectiveId: per.id, 
            id: obj.id
          });
        }
      }
    }
    aux = this.ordenarPerspe(aux);
    aux2 = this.ordenarPerspe(aux2);
    this.perspectiva = [];
    this.objetivos = [];
    this.perspectiva = aux;
    this.objetivos = aux2;
    this.diccionarioEst = [];
    for(const per of this.perspectiva){
      this.diccionarioEst.push({perspectiva: per, objetivos: per.objectives , estrategias: [], proyectos: []});
    }
  }
  private estPer(array: any){
    var aux = [], aux2 = [];
    for(const est of array){
      for(const obj of this.objetivos){
        if( est.objectives.length !== 0 && est.objectives[0].id === obj.id){
          aux.push(est);
          break;
        }
      }
    }
    this.estrategia = [];
    aux = this.ordenarPerspe(aux);
    this.estrategia = aux;
    for(const est of this.estrategia){
      const existe = this.estrategia.filter(es => est.id !== es.id && est.name.split('\t')[0] === es.name.split('\t')[0]);
      if(existe.length !== 0){
      }
    }

    for(const est of this.estrategia){
      var auxx = [];
      for(const obje of est.objectives){
        auxx.push(obje.id);
      }
      aux2.push(auxx);
    }
    var existe = null;
    for( const per of this.diccionarioEst){
      per.estrategias = [];
      for(const obj of per.objetivos){
        for(var i = 0; i < aux2.length; i++){
          if( aux2[i].includes(obj.id) ){
            per.estrategias.push(this.estrategia[i]);
          }
        }
      }    
      var aux3: any = [];
      for(var i = 0; i < aux2.length; i++){
        if(i != existe){
          aux3.push(aux2[i]);
        }else{
          aux3.push(['0']);
        }
      }
      aux2 = aux3;
      per.estrategias = this.ordenarPerspe(per.estrategias);
    }
  }
  private proyPer(array: any){
    var aux = [], aux2 = [];
    for(const proy of array){
      for(const obj of this.objetivos){
        if(proy.objectives.length !== 0 && proy.objectives[0].id === obj.id){
          aux.push(proy);
          break;
        }
      }
    }

    this.proyecto = [];
    aux = this.ordenarPerspe(aux);
    this.proyecto = aux;
    for(const proy of this.proyecto){
      var auxx = [];
      for(const obje of proy.objectives){
        auxx.push(obje.id);
      }
      aux2.push(auxx);
    }
    var existe = 0;
    for( const per of this.diccionarioEst){
      per.proyectos = [];
      for(var i = 0; i < aux2.length; i++){
        for(const obj of per.objetivos){
          if(aux2[i].includes(obj.id)){
            existe = i;
            per.proyectos.push(this.proyecto[i]);
            break;
          }
        }
      }    
      var aux3: any = [];
      for(var i = 0; i < aux2.length; i++){
        if(i != existe){
          aux3.push(aux2[i]);
        }else{
          aux3.push(['0']);
        }
      }
      aux2 = aux3;
      per.proyectos = this.ordenarPerspe(per.proyectos);
    }
  }
  public obtIni(name: string){
    let Id = '';
    let sep = name.split(' ');
    if(sep.length === 1 ){
      sep = name.split('-');
    }
    for(const pal of sep){
      if(pal !== '-'){
        let palS = pal.split("");
        Id +=  palS[0];
      }      
    }
    return Id;
  }
  /** Decision de si guarda o no */
  public alertas(aler: any, ele?: any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.guardarEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'eliminar'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar este programa?', 
        text: "Se borrará el proyecto asociado a este programa",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarele(ele);
        }
      });
    }
  }
  /** Almacenamiento de datos */
  public almacenarD(){
    var estrategi = new Estrategias();
    estrategi.name = document.getElementById('estrategia')["value"];
    estrategi.objetiveId = [];
    /*for(const obj of this.ObjSelect1){
      estrategi.objetiveId.push(Number(obj));
    }*/
    estrategi.objetiveId = [Number(this.ObjSelect1)];
    var proyec = new Proyectos();
    proyec.nombre = document.getElementById('proyecto')["value"];
    proyec.objetiveId = [];
    /*for(const obj of this.ObjSelect1){
      proyec.objetiveId.push(Number(obj));
    }*/
    proyec.objetiveId = [Number(this.ObjSelect1)];
    proyec.descripcion = document.getElementById('descripcion')["value"];
    proyec.responsableId = Number(this.liderSelect);
    proyec.ponderacion = 0;
    if(estrategi.name != '' && this.ObjSelect1 != undefined){
      this.alineamientoeService.postEstrategias(estrategi).subscribe(response => {
        this.alineamientoeService.getEstrategias().subscribe(response => {
          this.estPer(response);
          this.estrategia = this.ordenarPerspe(this.estrategia);
          var aux = this.estrategia.length-1;
          var aux2 = Number(this.estrategia[aux].id);
          proyec.programaId = [aux2];
          this.alineamientoeService.postProyectos(proyec).subscribe(response => {
            this.alineamientoeService.getProyectos().subscribe(response => {
              this.proyPer(response);
              this.proyecto = this.ordenarPerspe(this.proyecto);
              Swal.fire({
                title: 'Información agregada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
            });
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }else{
      Swal.fire({
        title: 'Ingrese toda la información',
        text: '',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Regresar'
      });
    }
    document.getElementById('estrategia')["value"] = '';
    this.ObjSelect1 = [];
    document.getElementById('proyecto')["value"] = '';
    document.getElementById('descripcion')["value"] = '';
    this.liderSelect = undefined;
    this.modal = false;
    document.getElementById('agregarInd')!.style.display = 'none';
  }
  public guardarEdit(){
    this.estratt.name = document.getElementById('estrategiaedit')["value"];
    this.estratt.objetiveId = [];
    /*for(const obj of this.ObjSelect1){
      this.estratt.objetiveId.push(Number(obj));
    }*/
    this.estratt.objetiveId = [Number(this.ObjSelect1)];
    this.proyectt.name = document.getElementById('proyectoedit')["value"];
    /*this.proyectt.objetiveId = [];
    for(const obj of this.ObjSelect1){
      this.proyectt.objetiveId.push(Number(obj))
    }*/
    this.proyectt.objetiveId = [Number(this.ObjSelect1)];

    this.proyectt.description = document.getElementById('descripcionedit')["value"]!==''?document.getElementById('descripcionedit')["value"]:" ";
    this.proyectt.responsableId = Number(this.liderSelect);
    this.alineamientoeService.patchEstrategia(this.estratt).subscribe(response =>{
      this.alineamientoeService.getEstrategias().subscribe(response => {
        this.estPer(response);
        this.estrategia = this.ordenarPerspe(this.estrategia);
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.alineamientoeService.patchProyectos(this.proyectt).subscribe(response =>{
      this.alineamientoeService.getProyectos().subscribe(response => {
        this.proyPer(response);
        this.proyecto = this.ordenarPerspe(this.proyecto);
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    document.getElementById('estrategiaedit')["value"] = '';
    this.ObjSelect1 = undefined;
    document.getElementById('proyectoedit')["value"] = '';
    document.getElementById('descripcionedit')["value"] = '';
    this.liderSelect = undefined;
    document.getElementById('editIndicador')!.style.display = 'none';
  }
  /** Comprobación de guardado */
  public puede_guardar(){
    var estrategi = new Estrategias();
    var proyec = new Proyectos();
    estrategi.name = document.getElementById('estrategia')["value"];
    estrategi.objetiveId = this.ObjSelect1;
    proyec.nombre = document.getElementById('proyecto')["value"];
    proyec.objetiveId = this.ObjSelect1;
    proyec.responsableId = Number(this.liderSelect);
    if(estrategi.name != '' && estrategi.objetiveId.length != 0 && !this.nombreExiste() &&
      proyec.nombre !='' &&  proyec.objetiveId != undefined && this.liderSelect != undefined &&
      !this.nombreExisteProg()){ 
      return '';
    }
    return 'w3-disabled';
  }
  public puede_guardarEdit(){
    var estrategi = new Estrategias();
    var proyec = new Proyectos();
    estrategi.name = document.getElementById('estrategiaedit')["value"];
    estrategi.objetiveId = this.ObjSelect1;
    proyec.nombre = document.getElementById('proyectoedit')["value"];
    proyec.objetiveId = this.ObjSelect1;
    proyec.responsableId = Number(this.liderSelect);
    if(estrategi.name != '' && estrategi.objetiveId.length != 0 && !this.nombreExiste() &&
      proyec.nombre !='' &&  proyec.objetiveId != undefined && this.liderSelect != undefined &&
      !this.nombreExisteProg()){ 
      return '';
    }
    return 'w3-disabled';
  }
  /** Metodos de escritura */
  public obtObj(obje: any){
    var objetivos = '';
    for(const objet of obje.objectives){
      for(const obj of this.objetivos){
        if(Number(objet.id) === Number(obj.id)){
          if(objetivos === ''){
            objetivos+= obj.nombre;
          }else{
            objetivos+= ', ' + obj.nombre;
          }
        }
      }
    }
    return objetivos;
  }
  /** Modal */
  //Abrir
  public open_modal(per: any){
    this.idPers = per.id;
    this.ObjSelect1 = [];
    document.getElementById('agregarInd')!.style.display = 'block';
    document.getElementById('inicio').scrollIntoView(true);
    this.modal = true;
  }
  public open_edit(est:any, per:any){
    this.estratt = est;
    this.idPers = per.id;
    this.modal2 = true;
    document.getElementById('estrategiaedit')["value"] = this.estratt.name;
    //for(const obj of this.estratt.objectives){
      this.ObjSelect1 = this.estratt.objectives[0].id;
    //}
    for(const per of this.diccionarioEst){
      for(const pro of per.proyectos){
        if(pro.strategies[0] !== undefined && this.estratt.id === pro.strategies[0].id){
          document.getElementById('proyectoedit')["value"] = pro.name;
          document.getElementById('descripcionedit')["value"] = pro.description;
          this.liderSelect = ''+pro.responsible.id;
          this.proyectt = pro;
          break;
        }
      }
    }
    document.getElementById('editIndicador')!.style.display = 'block';
    document.getElementById('inicioEditar').scrollIntoView(true);
  }
  //Cerrar
  public closeModal(){
    this.modal = false;
    this.modal2 = false;
    document.getElementById('agregarInd')!.style.display = 'none';
    document.getElementById('editIndicador')!.style.display = 'none';

    document.getElementById('estrategia')["value"] = '';
    this.ObjSelect1 = [];
    document.getElementById('proyecto')["value"] = '';
    document.getElementById('descripcion')["value"] = '';
    this.liderSelect = undefined;
  }
  /** Eliminación de datos */
  public eliminarele(ele: any){
    for(const per of this.diccionarioEst){
      for(const pro of per.proyectos){
        for(const estr of pro.strategies){
          if(ele.id === estr.id){
            this.alineamientoeService.deleteProyectos(pro).subscribe(response =>{
              this.alineamientoeService.getProyectos().subscribe(response => {
                this.proyPer(response);
                this.proyecto = this.ordenarPerspe(this.proyecto);
                Swal.fire({
                  title: 'Información eliminada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        }
      }
    }
    this.alineamientoeService.deleteEstrategias(ele).subscribe(response =>{
      this.alineamientoeService.getEstrategias().subscribe(response => {
        this.estPer(response);
        this.estrategia = this.ordenarPerspe(this.estrategia);
        Swal.fire({
          title: 'Información eliminada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
     });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Exportaciones */
  public exportar_excel(){

    let titulosPers =[]
    let headersPersp =[]
    let contenidoTabla = []
    for(let i = 0; i< this.diccionarioEst.length; i++){
      titulosPers.push(this.diccionarioEst[i].perspectiva.nombre)
      headersPersp.push([
        '#',
        'Objetivo',
        'Programas',
      ]);
      let c = []
      for(let j=0; j< this.diccionarioEst[i].estrategias.length; j++){
        c.push([
          'P'.concat((j+1).toString()),
          this.obtObj(this.diccionarioEst[i].estrategias[j]),
          this.diccionarioEst[i].estrategias[j].name,
        ])
      }
      contenidoTabla.push(c)
    }
    this.excelService.generateExcelAlineamientoEstrategicoFormulacion("Formulacion: Alineamiento estratégico", titulosPers, headersPersp, contenidoTabla, "alineamiento_estrategico");
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('estrategia')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('estrategiaedit')["value"];
      }
      const existe = this.estrategia.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() && element.id !== this.estratt.id);
        }
        return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste():  boolean  {    
    return  this.formEst.hasError('isValid');
  }
  private nombreDuplicidadProg(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('proyecto')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('proyectoedit')["value"];
      }
      const existe = this.proyecto.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() && element.id !== this.proyectt.id);
        }
        return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExisteProg():  boolean  {
    return  this.formProg.hasError('isValid');
  }
}