import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { PermisosSubmodulosGuard } from 'src/app/guards/permisos-submodulos/permisos-submodulos.guard';
import { CatalogosComponent } from './catalogos/catalogos.component';
import { AltaplanificacionesComponent } from '../formulacion/altaplanificaciones/altaplanificaciones.component';

const routes: Routes = [
  {
    path: 'app/administrador/:idplan/:planNom',
    component: WelcomeComponent,
    children: [
      { path: 'catalogo', component: CatalogosComponent, canActivate: [PermisosSubmodulosGuard]},
      { path: 'altaplanes', component: AltaplanificacionesComponent, canActivate: [PermisosSubmodulosGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministradorRoutingModule { }
