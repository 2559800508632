import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Importaciones  Angular Material */
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatDividerModule} from '@angular/material/divider';
import { MatInputModule} from '@angular/material/input';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule} from '@angular/material/radio';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule} from '@angular/material/button';
import { MatSelectModule} from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TranslateModule } from '@ngx-translate/core';
import { RolesDirective } from './../../services/directivas/roles.directive';
import { RolesNegDirective } from './../../services/directivas/roles-neg.directive';
import { GraficaLinealComponent } from 'src/app/components/grafica-lineal/grafica-lineal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import { AdministrdoresDirective } from './../../services/directivas/administrdores.directive';



@NgModule({
  declarations: [    
    RolesDirective,
    RolesNegDirective,
    GraficaLinealComponent,
    AdministrdoresDirective,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    FormsModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxChartsModule,
    TranslateModule,
    MatListModule,
    MatSliderModule,
  ],
  exports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    FormsModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxChartsModule,
    TranslateModule,
    RolesDirective,
    RolesNegDirective,
    GraficaLinealComponent,
    MatSlideToggleModule,
    MatListModule,
    MatSliderModule,
    AdministrdoresDirective,
  ]
})
export class SharedModule { }
