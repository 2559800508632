import {Component,OnInit,AfterViewInit,ElementRef,ViewChild,Directive,Input,ViewContainerRef,TemplateRef,} from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ObjetivosService } from 'src/app/services/seguimiento/objetivos/objetivos.service';
import {Meses,Perspectivas,Planificacion,Responsables,Semaforos,obtenerError,} from 'src/app/model/datos';
import { IndicadoresmetaService } from 'src/app/services/seguimiento/indicadoresmeta/indicadoresmeta.service';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { IMG } from 'src/app/services/Exportar/exportImg';
import html2canvas from 'html2canvas';
import { ProyectosestrategicosService } from 'src/app/services/seguimiento/proyectosestrategicos/proyectosestrategicos.service';
import { CuadromandoService } from 'src/app/services/seguimiento/cuadromando/cuadromando.service';
import { actividades } from '../../../services/formulacion/proyectosestrategicos/proyectosestrategicos.service';
import { ConstantesImagenes } from '../codigoimagenes';
import { obtenerLogo } from '../../../model/datos';
import { AltaplanificacionesService } from 'src/app/services/formulacion/altaplanificaciones/altaplanificaciones.service';
import { MatrizFloa2Service } from 'src/app/services/formulacion/matrizfoda2/matriz-floa2.service';
import { ReportesService } from '../../../services/reportes/reportes.service';
import * as moment from 'moment';
import { color } from 'd3';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-avanceseguimiento',
  templateUrl: './avanceseguimiento.component.html',
  styleUrls: ['./avanceseguimiento.component.css'],
})
export class AvanceseguimientoComponent implements OnInit {
  public Nombre = 'Avance de seguimiento';
  private idPlanificacion: number = 0;
  public fechaReporte = '';
  public fechaReporteIni = '';
  public porPeriodo = true;
  public numPeridos = 12;

  public separador: string = ','; // separador para los miles
  public sepDecimal: string = '.';

  aniosDisponiblesIM: any = [];
  aniosDisponiblesCM: any = [];
  perspectivesIM: any = [];
  semaforoIM: any = [];
  responsablesIM: Responsables[] = [];
  responsables2IM: any = [];
  objectivosIM: any = [];
  indicadoresIM: any = [];
  indicadoresMostrarIM: any = [];
  todasPerIM = true;
  colorScheme_balanceIM: any = {
    domain: ['#FF495C', '#FF8300', '#FFD100', '#0075C9', '#72D54A'],
  };
  treemapIM = [{ name: 'rojo', value: 5 }];
  graficas_linealesIM: any = [
    {
      name: 'ind',
      series: [
        ['feb-23', 12, 12],
        ['mar-23', 12, 12],
      ],
      check: true,
    },
  ];
  perspectivaSelecIM = '';
  indicadoresMosIM: any = [];
  subtituloIM = '';
  public tabOgraf = true;
  public selAll = true;
  gradientIM: boolean = false;
  animationsIM: boolean = true;
  anioSeleccionado: string;
  labelFormattingIM(c: any) {
    return `<h5>${c.label} </h5>`;
  }
  valueFormattingIM(c: any) {
    return `${c}`;
  }
  img_graficas = new Map<String, String>();

  // public perspectives: any = [];
  public aniosDisponibles: any = [];
  private semaforo: any = [];
  private semaforoPro: any = [];
  public objectives: any = [];
  public programas: any = [];
  public responsables: any = [];
  public responsables2: any = [];
  public responsablesAct: any = [];
  public responsablesAct2: any = [];
  public proyectos: any = [];
  private proyectosFin: any = [];
  public actividades: any = [];
  public proyectosMostrar: any = [];
  private diasLab: any;
  private fechaAct = new Date();
  private edicion: boolean;
  public perspectives: any = [];
  // public Periodo = '';
  public perspectiva = '';
  public objetivo = '';
  public programa = '';
  nombreInstitucion = { nombre: '', acronimo: '' };

  //filtros seleccionables
  public perSelect: any;
  public objSelect: any;
  public proSelect: any;

  public diccionarioPer: any[] = [];
  public avancePlan = 0;
  public indicadores: any = [];
  public objectivos: any = [];
  public indicadoresMostrar: any = [];
  public todasPer = true;
  // img_cmi: any;
  imgs_cmi: any[] = [];
  porcentaje_cmi_img: any;

  isDownloadingPdf = false;
  isDownloadingData = false;
  // incluirGraficos = false

  anioSelected = '';
  reporteGeneradoExitosamente: boolean = true;

  imprimirIntro: boolean = false;
  imprimirFoda: boolean = false;
  imprimirFodaV2: boolean = false;
  imprimirObjetivos: boolean = false;
  imprimirIndicadores: boolean = true;
  imprimirCmi: boolean = true;
  imprimirPlanOperativo: boolean = true;
  imprimirPlanOperativoActividades: boolean = false;
  imprimirGraficas: boolean = false;

  numActivid = 0;

  verFodaV1 = false;
  verFodaV2 = false;

  introduccion: string = '';

  public diccionarioFLOA1: any = [];
  public diccionarioFLOA2: any = [];
  public diccionarioFLOA1V2: any = [];
  public diccionarioFLOA2V2: any = [];
  planeacionObj: Planificacion[] = [];
  public planeacion: Planificacion[] = [];
  private planeacionFoda;
  elemento = [];
  public internos = [
    {id:1, type:'FORTALEZA', elemento:'Fortalezas'},
    {id:2, type:'DEBILIDAD', elemento:'Debilidades'},
  ];
  public externos = [
    {id:3, type:'OPORTUNIDAD', elemento:'Oportunidades'},
    {id:4, type: 'AMENAZA', elemento:'Amenazas'},
  ];
  imgFloa: any;

  todosObjetivos: boolean = true;
  persSeleccionadas = [];
  objSeleccionados = [];
  todosLideresSeleccionados = true;
  anioInicial = '';

  lideresMostrar = [];

  public perspectiva2: Perspectivas[] = [];
  public perspectivas: Perspectivas[] = [];

  nuevoEmail: string = '';

  emailsControl: any = {
    emails: [],
  };
  pdfFile: any;
  reportName = 'Reporte.pdf';
  idInstitucion ='';
  imgLogo = ''
  companyId = ''
  Periodo = ''

  public mostrarPeriodo =  true;

  public  ordenamiento = 'id';

  public mostrarTable = false;
  isLoadingObtenerDatos: boolean = false;
  isLoadingObtenerDatosIM: boolean = false;
  isLoadingObtenerDatosFoda: boolean = false;
  private verCodigo = false;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private objetivosService: ObjetivosService,
    private indicadoresMetaService: IndicadoresmetaService,
    private proyectosestrategicosService: ProyectosestrategicosService,
    private cuadromandoService: CuadromandoService,
    private altaplanificacionesService: AltaplanificacionesService,
    private matrizService: MatrizFloa2Service,
    private reportesService: ReportesService,
    private activateRouter: ActivatedRoute
  ) {
    if(dataService.modules.includes('est-matrizfloa')){
      this.verFodaV1 = true;
    }
    if(dataService.modules.includes('est-matrizderiesgosyoportunidades')){
      this.verFodaV2 = true;
    }
    var param = this.rutaActiva.snapshot.params;
    this.isDownloadingData = true;
    this.idPlanificacion = Number(param['planeacion']);
    this.isLoadingObtenerDatos = true;
    this.isLoadingObtenerDatosIM = true;
    this.isLoadingObtenerDatosFoda = true;
    this.obtenerDatos();
    this.obtenerDatosIM();
    this.obtDataFoda();
    this.idInstitucion = this.activateRouter.snapshot['_routerState'].url.split('/')[3];

    setTimeout(() => {
      this.isDownloadingData = false;
    }, 3000);
  }

  ngOnInit(): void {}


  private obtenerAdicionales(array: any) {
    for (const ind of this.indicadoresIM) {
      var aux = [];
      for (const ad of array) {
        if (ad.indicator !== null && ad.indicator.id === ind.id) {
          var fil = [];
          if (ad.files && ad.files.length !== 0) {
            for (const f of ad.files) {
              const date = this.obtFechIM(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      ind.additions = aux;
      this.isLoadingObtenerDatosIM = false;
    }
  }

  public ordenamientoAd(array: any) {
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private obtenerObjetivosReporte() {
    const fontSize = 10;

    let table: any = {
      widths: ['auto', 'auto', 'auto', 85, 'auto', 'auto', 6, 55, ],
      body: [
        [
          {
            text: 'Perspectiva',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Objetivo',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Indicador',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Responsable',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Meta',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Avance',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Rendimiento',
            style: 'tableHeader',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            colSpan: 2,
            fontSize: 11,
          },
          { text: '', fontSize: 8 },
        ],
      ],
    };

    let metaAnterior = '';
    let perspAnterior = '';
    let objAnterior = '';
    for (let i = 0; i < this.indicadoresMostrarIM.length; i++) {
      const p = this.obtPerIM(this.indicadoresMostrarIM[i].objective.idPer);
      let metaDistinta = perspAnterior === '' || perspAnterior !== p;
      let objDistinto =
        objAnterior === '' ||
        objAnterior !== this.indicadoresMostrarIM[i].objective.nombre;
      
      const border = [false, true, false, false];
      const borderColor = [
        'white',
        p === '' ? '#F5F5F5' : '#E0E0E0',
        'white',
        'white',
      ];
      let persp = {
        text: metaDistinta ? p : '',
        color: '#808080',
        border: metaDistinta
          ? [false, true, false, false]
          : [false, false, false, false],
        borderColor,
        fontSize,
      };

      let obj = {
        text: objDistinto ? this.indicadoresMostrarIM[i].objective.nombre : '',
        color: '#808080',
        border: objDistinto
          ? [false, true, false, false]
          : [false, false, false, false],
        borderColor,
        fontSize,
      };
      let meta: any = { fontSize };
      let avance: any = { fontSize };
      let rendimiento: any = { fontSize };
      let barra: any = { text: '', fontSize: 8 };

      let m = this.escribirMetaIM(
        this.anioSeleccionado,
        this.indicadoresMostrarIM[i]
      );
      let r = this.escribirRealIM(
        this.anioSeleccionado,
        this.indicadoresMostrarIM[i]
      );

      meta.text = m === '' ? '0' : m;
      if(Number(m) && Number(m) !== 0){
        meta.text = Number(m).toLocaleString('es-MX',{
          maximumFractionDigits: 1
        });
      }
      meta.color = '#808080';
      meta.border = border;
      meta.borderColor = borderColor;
      meta.alignment = 'right';
      avance.text = r === 'null' || r === '' ? '0' : r;
      if(Number(r) && Number(r) !== 0){
        avance.text = Number(r).toLocaleString('es-MX',{
          maximumFractionDigits: 1
        });
      }
      avance.color = '#808080';
      avance.border = border;
      avance.borderColor = borderColor;
      avance.alignment = 'right';
      rendimiento.text = this.rendimiento(
        this.anioSeleccionado,
        this.indicadoresMostrarIM[i]
      );
      rendimiento.color = '#808080';
      rendimiento.border = border;
      rendimiento.borderColor = borderColor;
      rendimiento.alignment = 'right';
      barra.border = border;
      barra.borderColor = borderColor;
      barra.fillColor = this.pintBorde(
        this.pintarBordeIM2(
          this.escribirRealIM(
            this.anioSeleccionado,
            this.indicadoresMostrarIM[i]
          ),
          this.escribirMetaIM(
            this.anioSeleccionado,
            this.indicadoresMostrarIM[i]
          ),
          this.indicadoresMostrarIM[i]
        )
      );

      if (meta.text !== '0') {
        table.body.push([
          persp,
          obj,
          {
            text: (this.verCodigo?'Código | ':'')+''+this.indicadoresMostrarIM[i].nombre,
            color: '#808080',
            border: border,
            borderColor,
            fontSize,
          },
          {
            text: this.obtRes(i),
            color: '#808080',
            border: border,
            borderColor,
            fontSize,
          },
          meta,
          avance,
          barra,
          rendimiento,
        ]);
        perspAnterior = p;
        objAnterior = this.indicadoresMostrarIM[i].objective.nombre;
      }
    }
    return table;
  }

  pintBorde(col) {
    let resp = '';
    switch (col) {
      case 'bordeRojo':
        resp = '#FF495C';
        break;
      case 'bordeNaranja':
        resp = '#FF8300';
        break;
      case 'bordeAmarillo':
        resp = '#FFD100';
        break;
      case 'bordeAzul':
        resp = '#0075C9';
        break;
      case 'bordeVerde':
        resp = '#72D54A';
        break;
      case 'bordeNegro':
        resp = '#000000';
        break;
      default:
        resp = '#C8C7C8';
    }

    return resp;
  }

  public obtRes(i: any) {
    var areaId = this.indicadoresMostrarIM[i].areaId;
    for (const res of this.responsablesIM) {
      if (res.id === areaId) {
        return res.nombre;
      }
    }
    return '';
  }

  public rendimiento(periodo: any, ind: any){
    var nombre = '';
    const metas = ind.metas.filter( meta => 
      Meses.getMesesN(new Date(meta.period).getMonth()+1)+'-'+(''+new Date(meta.period).getFullYear()).substring(2,4) === periodo
    );
    if (metas.length === 0) {
      return '0 %';
    }
    //const met = metas[0];
    const met = {real: metas[0].real===null?null:metas[0].real, value: metas[0].value===null?null:metas[0].value};
    if(met.real < 0 && met.value >= 0){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }

  public escribirAdicional(adic: any) {
    if (adic != undefined) {
      switch (adic.type) {
        case 'texto':
          return this.obtComment(adic.comments);
        case 'moneda':
          return this.formatearnormal(adic.amount);
        case 'archivo':
          var per = this.perSelected();
          for (const file of adic.files) {
            if (per === Meses.obtnerPer(file.created_at)) {
              return file.name.split('-')[file.name.split('-').length - 1];
            }
          }
          return '';
      }
    }
  }

  private obtComment(com: any) {
    for (const comen of com) {
      var per = this.perSelected();
      var percom = Meses.obtnerPer(new Date(comen.created_at));
      if (per === percom) {
        return comen.comment;
      }
    }
    return '';
  }

  public perSelected() {
    for (const an of this.aniosDisponiblesCM) {
      for (const mes of an.series) {
        if (mes.check) {
          return mes.periodo;
        }
      }
    }
    return '';
  }

  public formatearnormal(num: any) {
    num += '';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return splitLeft;
  }

  private obtenerDatosIM() {
    this.indicadoresMetaService.getPlaneacion().subscribe((response) => {
      this.obtenerFiltrosIM(response);
      this.indicadoresMetaService.getObjectives().subscribe((response) => {
        this.consultas();
        this.obtenerObjetivosIM(response);
        this.indicadoresMetaService.getIndicadores().subscribe((response) => {
          this.obtenerIndicadoresIM(response);
          this.objetivosService.getAdicionales().subscribe((response) => {
            this.obtenerAdicionales(response);
          });
        });
      });
    });
  }

  private consultas() {
    this.cuadromandoService.getObjectives().subscribe((response) => {
      this.diccionario(response);
      this.cuadromandoService.getIndicadores().subscribe((response) => {
        this.obtenerIndicadores(response);
      });
    });
  }

  private obtenerIndicadores(array: any) {
    var aux = [];
    for (const ind of array) {
      for (const obj of this.objectivos) {
        if (ind.objective.id === obj.id) {
          var metas = [];
          for (const met of ind.goals) {
            metas.push({
              id: met.id,
              period: met.period,
              value: met.value,
              real: met.real,
            });
          }
          aux.push({
            nombre: ind.name,
            id: ind.id,
            ponderacion: ind.weighing,
            metas: this.ordenamientoMetasIM(metas),
            tendencia: ind.tendency,
            objectiveId: ind.objective,
            fechaIni: ind.initial_date,
            fechaFin: ind.end_date,
            areaId: ind.area.id,
          });
          break;
        }
      }
    }
    this.indicadores = [];
    this.indicadores = aux;
    this.indicadores = this.ordenamientoId(this.indicadores);
    this.indicadores = this.ordenamientoObjIdCM(this.indicadores);
    this.indicadoresMostrar = [];

    /*for (const ind of this.indicadores) {
      if (this.MostrarCM(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }*/
    this.indicadoresMostrar = [].concat(this.indicadores);
  }

  public ordenamientoObjIdCM(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.objectiveId.id) > Number(b.objectiveId.id)) {
        return 1;
      }
      if (Number(a.objectiveId.id) < Number(b.objectiveId.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public MostrarCM(ind: any) {
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var metasInd = [];
    var existeMet = false;
    for (const an of this.aniosDisponiblesCM) {
      for (const mes of an.series) {
        if (mes.check) {
          perSele.push(mes.periodo);
        }
      }
    }
    for (const met of ind.metas) {
      metasInd.push(
        Meses.getMesesN(this.obtFech(met.period).getMonth() + 1) +
          '-' +
          (this.obtFech(met.period).getFullYear() + '').substring(2, 4)
      );
    }
    for (const per of metasInd) {
      for (const perS of perSele) {
        if (per === perS) {
          existeMet = true;
        }
      }
    }

    //Observar que la perspectiva este activa
    var existPer = false,
      perInd;
    for (const obj of this.objectivos) {
      if (obj.id === ind.objectiveId.id) {
        perInd = this.obtPer(obj.idPer);
      }
    }

    if (this.todasPerIM) {
      existPer = true;
    } else {
      for (const obj of this.objectivos) {
        if (obj.id === ind.objectiveId.id) {
          perInd = this.obtPer(obj.idPer);
        }
      }
      for (const per of this.perspectivesIM) {
        if (per.check && per.name === perInd) {
          existPer = true;
          break;
        }
      }
    }
    var selObj = false;

    if (!this.todosObjetivos) {
      for (const obj of this.objectives) {
        if (obj.id === ind.objectiveId.id && obj.check) {
          selObj = true;
          break;
        }
      }
    } else {
      selObj = true;
    }

    return existeMet && existPer && selObj;
  }

  private obtPer(perId: any) {
    for (const per of this.perspectivesIM) {
      if (per.id === perId) {
        return per.name;
      }
    }
  }

  private diccionario(array: any){
    var auxPer: any = [], auxO = [];
    var dicci: any = [];
    for (const per of this.perspectivesIM){
      for (const obj of array){
        if(per.id === obj.perspective.id){
          auxPer.push({
            name: obj.name,
            id: obj.id,
            weighing: obj.weighing,
            avance: 0,
          });
          auxO.push({
            nombre: obj.name,
            id: obj.id,
            idPer: obj.perspective.id,
            peso: obj.weighing,
          });
        }
      }
      dicci.push({perspective: per, objetivos: auxPer});
      auxPer = [];
    }
    this.objectivos = [];
    this.objectivos = auxO;
    this.objectivos = this.ordenamientoId(this.objectivos);
    this.diccionarioPer = dicci;

    var aux = 0;
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        aux += Number((obj.data*obj.weighing/100).toFixed(2));
      }
    }
  }

  private obtenerFiltrosIM(planeacion: any) {
    for (const plan of planeacion) {
      if (Number(plan.id) === this.idPlanificacion) {
        if (
          this.nombreInstitucion.nombre === '' ||
          this.nombreInstitucion.acronimo === ''
        ) {
          this.nombreInstitucion.nombre = plan.institution.name;
          this.nombreInstitucion.acronimo = plan.institution.acronym;
        }
        this.aniosDisponiblesIM = Meses.obtenerPeriodos(
          new Date(plan.initial_date),
          new Date(plan.end_date)
        );

        this.aniosDisponiblesCM = Meses.obtenerPeriodos(
          new Date(plan.initial_date),
          new Date(plan.end_date)
        );

        var per = this.perSelected()
        this.selPeriodosIndMetas(per, this.numPeridos)

        for (const per of plan.perspectives) {
          if(this.perspectivesIM.find((p) => p.id === per.id) === undefined){
          this.perspectivesIM.push({
            name: per.name,
            check: false,
            id: per.id,
          });
        }
        }
        this.perspectivesIM = this.ordenamientoIdIM(this.perspectivesIM);

        for (const sem of plan.signals) {
          this.semaforoIM.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }

        this.indicadoresMetaService.getAreas().subscribe((response) => {
          this.responsablesIM = [];
          for (const res of response) {
            if (res.institution.id === plan.institution.id) {
              this.lideresMostrar.push({ ...res, check: true });
              this.responsablesIM.push({
                id: res.id,
                nombre: res.name,
                check: true,
              });
            }
          }
          this.responsablesIM = this.ordenamientoIdIM(this.responsablesIM);
          this.responsables2IM = new MatTableDataSource(this.responsablesIM);
        });
      }
    }
  }

  private obtenerObjetivosIM(array: any) {
    var aux = [];
    for (const obj of array) {
      if (this.perspectivesIM.find((per) => obj.perspective.id === per.id)) {
        aux.push({
          nombre: obj.name,
          id: obj.id,
          idPer: obj.perspective.id,
        });
      }
    }
    this.objectivosIM = [];
    this.objectivosIM = aux;
    this.objectivosIM = this.ordenamientoIdIM(this.objectivosIM);
  }

  private obtenerIndicadoresIM(array: any) {
    var aux = [];
    for (const ind of array) {
      if (this.objectivosIM.find((obj) => obj.id === ind.objective.id)) {
        var metas = [];
        for (const met of ind.goals) {
          const date = this.obtFechIM(met.period);
          metas.push({
            id: met.id,
            period: date,
            value: met.value,
            real: met.real,
          });
        }
        aux.push({
          nombre: ind.name,
          id: ind.id,
          ponderacion: ind.weighing,
          metas: this.ordenamientoMetasIM(metas),
          objectiveId: ind.objective.id,
          objective: this.objectivosIM.find(
            (obj) => obj.id === ind.objective.id
          ),
          tendencia: ind.tendency,
          fechaIni: ind.initial_date,
          fechaFin: ind.end_date,
          areaId: ind.area.id,
          area: ind.area,
        });
      }
    }
    this.indicadoresIM = [];
    this.indicadoresIM = this.ordenamientoObjIdIM(this.ordenamientoIdIM(aux));
    this.indicadoresIM = this.ordenarPorPerspectivaIM(this.indicadoresIM);
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.contar_coloresIM();
    //this.obtAvance();
  }

  private obtAvance(){
    this.avancePlan = this.porcentajeObjecAcum();
    for(const per of this.diccionarioPer){
      for(const obj of per.objetivos){
        var avance = 0;
        avance = this.porcentajePersAcum(obj.id);
        obj.avance = avance;
      }
    }
  }

  private obtenerIdPerIM(idObj) {
    for (const obj of this.objectivosIM) {
      if (obj.id === idObj) {
        return obj.idPer;
      }
    }
  }
  private obtFechIM(isodate: any) {
    const fecha = isodate.split('T')[0];
    var fech = new Date(
      fecha.split('-')[1] +
        '/' +
        fecha.split('-')[2] +
        '/' +
        fecha.split('-')[0]
    );
    return fech;
  }
  public ordenamientoIdIM(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public ordenamientoMetasIM(array: any) {
    array.sort(function (a: any, b: any) {
      if ((a.period) > (b.period)) {
        return 1;
      }
      if ((a.period) < (b.period)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public ordenamientoObjIdIM(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.objectiveId) > Number(b.objectiveId)) {
        return 1;
      }
      if (Number(a.objectiveId) < Number(b.objectiveId)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private ordenarPorPerspectivaIM(array: any) {
    var aux = [];
    for (const per of this.perspectivesIM) {
      for (var i = 0; i < array.length; i++) {
        if (per.id === this.obtenerIdPerIM(array[i].objectiveId)) {
          aux.push(array[i]);
        }
      }
    }

    return aux;
  }

  public MostrarIM(ind: any) {
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var metasInd = [];
    var existeMet = false;
    for (const an of this.aniosDisponiblesIM) {
      var mes = an.series.filter((mes) => mes.check);
      if (mes.length !== 0) {
        perSele = perSele.concat(mes);
      }
    }

    for (const met of ind.metas) {
      var per =
        Meses.getMesesN(new Date(met.period).getMonth() + 1) +
        '-' +
        (new Date(met.period).getFullYear() + '').substring(2, 4);
      const perEx = perSele.find((perS) => perS.periodo === per);
      if (perEx) {
        if (met.real != null) {
          existeMet = true;
        }
        break;
      }
    }

    //Observar que la perspectiva este activa
    let existPer = false;
    let perInd;

    if (this.todasPerIM) {
      existPer = true;
    } else {
      for (const obj of this.objectives) {
        if (obj.id === ind.objectiveId) {
          perInd = this.obtPer(obj.perspective.id);
          break;
        }
      }

      for (const per of this.perspectivesIM) {
        if (per.check && per.name === perInd) {
          existPer = true;
          break;
        }
      }
    }

    //que el area este activa
    var areaAct = false;

    if (this.todosLideresSeleccionados) {
      areaAct = true;
    } else {
      for (const area of this.lideresMostrar) {
        if (area.id === ind.areaId && area.check) {
          areaAct = true;
          break;
        }
      }
    }

    let objSel = false;
    if (!this.todosObjetivos) {
      for (const obj of this.objectives) {
        if (obj.id === ind.objectiveId && obj.check) {
          objSel = true;
          break;
        }
      }
    } else {
      objSel = true;
    }

    if (existPer && areaAct && objSel) return true;
    return false;
  }

  private obtPerIM(perId: any) {
    for (const per of this.perspectivesIM) {
      if (per.id === perId) {
        return per.name;
      }
    }
  }

  private contar_coloresIM() {
    this.graficaslineales();
    var rojo = 0,
      naranja = 0,
      amarillo = 0,
      azul = 0,
      verde = 0;
    for (var i = 0; i < this.indicadoresMostrarIM.length; i++) {
      for (var j = 0; j < this.indicadoresMostrarIM[i].metas.length; j++) {
        var per = Meses.getMesesN(
          new Date(this.indicadoresMostrarIM[i].metas[j].period).getMonth() + 1
        );
        per +=
          '-' +
          (
            new Date(
              this.indicadoresMostrarIM[i].metas[j].period
            ).getFullYear() + ''
          ).substring(2, 4);
        if (this.muestraPerIM(per) && this.perAMosIM(per)) {
          var existPer = false,
            perInd;
          for (const obj of this.objectivosIM) {
            if (obj.id === this.indicadoresMostrarIM[i].objectiveId) {
              perInd = this.obtPerIM(obj.idPer);
            }
          }
          if (this.todasPerIM) {
            existPer = true;
          } else {
            for (const per of this.perspectivesIM) {
              if (per.check && per.name === perInd) {
                existPer = true;
              }
            }
          }
          if (existPer) {
            switch (
              '' +
              this.pintarBordeIM2(
                this.indicadoresMostrarIM[i].metas[j].real,
                this.indicadoresMostrarIM[i].metas[j].value,
                this.indicadoresMostrarIM[i]
              )
            ) {
              case 'bordeRojo':
                rojo++;
                break;
              case 'bordeNaranja':
                naranja++;
                break;
              case 'bordeAmarillo':
                amarillo++;
                break;
              case 'bordeAzul':
                azul++;
                break;
              case 'bordeVerde':
                verde++;
                break;
            }
          }
        }
      }
    }
    var aux = [];
    switch (this.semaforoIM.length) {
      case 3:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FFD100', '#72D54A'],
        };
        break;
      case 4:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Azul', value: azul });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FFD100', '#0075C9', '#72D54A'],
        };
        break;
      case 5:
        aux.push({ name: 'Rojo', value: rojo });
        aux.push({ name: 'Naranja', value: naranja });
        aux.push({ name: 'Amarillo', value: amarillo });
        aux.push({ name: 'Azul', value: azul });
        aux.push({ name: 'Verde', value: verde });
        this.colorScheme_balanceIM = {
          domain: ['#FF495C', '#FF8300', '#FFD100', '#0075C9', '#72D54A'],
        };
        break;
    }
    this.treemapIM = [];
    this.treemapIM = aux;
  }

  private graficaslineales() {
    this.graficas_linealesIM = [];
    var graflin: any = [],
      aux = [],
      aux2 = [];
    for (var i = 0; i < this.indicadoresIM.length; i++) {
      for (var j = 0; j < this.indicadoresIM[i].metas.length; j++) {
        var per = Meses.getMesesN(
          new Date(this.indicadoresIM[i].metas[j].period).getMonth() + 1
        );
        per +=
          '-' +
          (
            new Date(this.indicadoresIM[i].metas[j].period).getFullYear() + ''
          ).substring(2, 4);
        if (this.muestraPerIM(per)) {
          var existPer = false,
            perInd;
          for (const obj of this.objectivosIM) {
            if (obj.id === this.indicadoresIM[i].objectiveId) {
              perInd = this.obtPerIM(obj.idPer);
            }
          }
          if (this.todasPerIM) {
            existPer = true;
          } else {
            for (const per of this.perspectivesIM) {
              if (per.check && per.name === perInd) {
                existPer = true;
              }
            }
          }
          if (existPer) {
            aux.push([
              per,
              Number(this.indicadoresIM[i].metas[j].value),
              Number(this.indicadoresIM[i].metas[j].real),
            ]);
          }
        }
      }
      graflin.push(aux);
      this.graficas_linealesIM.push({
        name: this.indicadoresIM[i].nombre,
        indicadorId: this.indicadoresIM[i].id,
        series: aux,
        check: true,
      });
      aux = [];
      aux2 = [];
      graflin = [];
    }
    for (const per of this.perspectivesIM) {
      if (per.check) {
        this.perspectivaSelecIM = per.name;
        break;
      }
    }
    this.indicadoresMosIM = new MatTableDataSource(this.graficas_linealesIM);
  }

  private muestraPerIM(ind: any) {
    var perSele = [];
    var existeMet = false;
    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.check) {
          perSele.push(mes.periodo);
        }
      }
    }
    for (const perS of perSele) {
      if (ind === perS) {
        existeMet = true;
      }
    }

    if (existeMet) return true;
    return false;
  }

  private perAMosIM(perA: any) {
    var ultPerconDat = '',
      datos = 0;
    var periodos_mostrados = [];

    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.check) {
          periodos_mostrados.push(mes.periodo);
        }
      }
    }

    for (var i = periodos_mostrados.length - 1; i >= 0; i--) {
      for (const ind of this.indicadoresMostrarIM) {
        for (const met of ind.metas) {
          var peri = Meses.getMesesN(new Date(met.period).getMonth() + 1);
          peri +=
            '-' + (new Date(met.period).getFullYear() + '').substring(2, 4);
          if (met.real != '0' && peri === periodos_mostrados[i]) {
            datos++;
            break;
          }
        }
      }
      if (datos != 0) {
        ultPerconDat = periodos_mostrados[i];
        break;
      }
    }
    if (perA === ultPerconDat) return true;
    return false;
  }

  public pintarBordeIM(valor: any, meta: any, ind: any) {
    if (valor != '0' && valor != '') {
      var porcen;
      switch (ind.tendencia) {
        case '-1':
          porcen = Number(((meta * 100) / valor).toFixed(2));
          break;
        case '1':
        case '0':
          porcen = Number(((valor * 100) / meta).toFixed(2));
          break;
      }
      var valfin = 0;
      for (const sem of this.semaforoIM) {
        if (valfin < sem.valfin) {
          valfin = sem.valfin;
        }
      }
      if (porcen > valfin) {
        porcen = valfin - 1;
      }
      var col = '';
      for (const sem of this.semaforoIM) {
        var ab = sem.valIn;
        if (ab !== 0) {
          ab = ab - 0.1;
        }
        if (porcen >= ab && porcen < sem.valfin) {
          col = sem.color;
          break;
        }
      }
      switch (col) {
        case 'rojo':
          return 'bordeRojo';
        case 'naranja':
          return 'bordeNaranja';
        case 'amarillo':
          return 'bordeAmarillo';
        case 'azul':
          return 'bordeAzul';
        case 'verde':
          return 'bordeVerde';
      }
    }
    return 'bordeGris';
  }

  public pintarBordeIM2(valor: any, meta: any, ind: any) {
    const met = Number(meta),
      val = Number(valor);
    var porcen = undefined,
      col = '';
    if (
      val === 0 &&
      met !== 0 &&
      this.dataService.company_name === 'Unión Popular'
    ) {
      return 'bordeNegro';
    } else if (val === 0 && met !== 0) {
      switch (ind.tendencia) {
        case '-1':
          porcen = 1000;
          break;
        case '1':
        case '0':
          porcen = 0;
          break;
      }
    } else if (val !== 0 && !isNaN(val)) {
      switch (ind.tendencia) {
        case '-1':
          if (met === 0 && val <= 0) {
            porcen = 100;
          } else if (met === 0 && val > 0) {
            porcen = 0;
          } else if (val < 0) {
            const sem = this.semaforo.filter((sem) => sem.valfin >= 100);
            porcen = sem[0].valfin;
          } else {
            porcen = Number(((met * 100) / val).toFixed(2));
          }
          break;
        case '1':
        case '0':
          if (met === 0 && val >= 0) {
            porcen = 100;
          } else if (met === 0 && val < 0) {
            porcen = 0;
          } else if (val < 0) {
            porcen = 0;
          } else {
            porcen = Number(((val * 100) / met).toFixed(2));
          }
          break;
      }
    } else if (met === 0 && !isNaN(val)) {
      switch (ind.tendencia) {
        case '-1':
          if (met === 0 && val <= 0) {
            porcen = 100;
          } else if (met === 0 && val > 0) {
            porcen = 0;
          }
          break;
        case '1':
        case '0':
          if (met === 0 && val >= 0) {
            porcen = 100;
          } else if (met === 0 && val < 0) {
            porcen = 0;
          }
          break;
      }
    }
    if (porcen !== undefined) {
      var valfin = 0;
      for (const sem of this.semaforo) {
        if (valfin < sem.valfin) {
          valfin = sem.valfin;
        }
      }
      if (porcen >= valfin) {
        porcen = valfin - 1;
      }
      for (const sem of this.semaforo) {
        var ab = sem.valIn;
        if (ab !== 0) {
          ab = ab - 0.1;
        }
        if (porcen >= ab && porcen < sem.valfin) {
          col = sem.color;
          break;
        }
      }
      switch (col) {
        case 'rojo':
          return 'bordeRojo';
        case 'naranja':
          return 'bordeNaranja';
        case 'amarillo':
          return 'bordeAmarillo';
        case 'azul':
          return 'bordeAzul';
        case 'verde':
          return 'bordeVerde';
      }
    }
    if( Math.abs(met) != 0 && Math.abs(val) > met){
      return 'bordeVerde';

    }
    return 'bordeGris';
  }

  public cambioAnioIM(periodoSel: any) {
    var perSel = 0;
    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.check) {
          perSel++;
        }
      }
    }
    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.periodo === periodoSel) {
          if (mes.check) {
            mes.check = !mes.check;
          } else {
            if (perSel < 12) {
              mes.check = !mes.check;
            } else {
              Swal.fire({
                title: 'No se pueden seleccionar mas de doce periodos',
                text: '',
                confirmButtonColor: '#ff6b00',
                cancelButtonColor: '#B9B9BC',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
              });
            }
          }
        }
      }
    }
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.contar_coloresIM();
  }

  public numPerSelIM() {
    var num = 0;
    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.check) {
          num++;
        }
      }
    }
    if (num > 1) return true;
    return false;
  }

  public selecAllPerIM(todasPerIM) {
    this.perSelect = undefined;
    this.persSeleccionadas = [];
    this.todasPerIM = todasPerIM;
    for (const per of this.perspectivesIM) {
      per.check = false;
    }
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.obtenertitIM();
    this.contar_coloresIM();

    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });

    this.filtrarLideres();
  }

  private obtenertitIM() {
    this.subtituloIM = '';
    for (const per of this.perspectivesIM) {
      if (per.check) {
        this.subtituloIM += per.name + ' ';
      }
    }
  }

  public cambioPerIM(perSelec) {
    this.perSelect = undefined;
    perSelec.check = perSelec.check;

    if (perSelec.check) {
      this.persSeleccionadas.push(perSelec);
      this.perSelect = perSelec.id;
      this.todasPerIM = false;
    } else {
      this.persSeleccionadas = this.persSeleccionadas.filter(
        (value) => value.id !== perSelec.id
      );
      if (this.persSeleccionadas.length === 0) {
        this.todasPerIM = true;
      }
    }
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }

    this.indicadoresMostrar = [];
    for (const ind of this.indicadores) {
      if (this.MostrarCM(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }

    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });

    this.contar_coloresIM();
    this.cambioPer();
    this.filtrarLideres();
  }

  public cambioPer() {
    this.objetivo = '';
    this.programa = '';

    var auxmos = [];
    for (const pro of this.proyectosFin) {
      if (this.mostrar(pro)) {
        auxmos.push(pro);
      }
    }
  }

  public applyFilterIM(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.responsables2IM.filter = filterValue.trim().toLowerCase();
  }
  public applyFilterIndIM(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.indicadoresMosIM.filter = filterValue.trim().toLowerCase();
  }

  public selectAll() {
    this.responsablesIM.forEach((res) => {
      this.selAll ? (res.check = false) : (res.check = true);
    });
    this.selAll = !this.selAll;
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.contar_coloresIM();

    var auxmos = [];
    for (const pro of this.proyectosFin) {
      if (this.mostrar(pro)) {
        auxmos.push(pro);
      }
    }
  }

  public cambioLiderIM(lidSelec: any) {
    lidSelec.check = lidSelec.check;
    var sele = 0;
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.contar_coloresIM();
    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });
  }

  public numAreSelIM() {
    var num = 0;
    for (const res of this.responsablesIM) {
      if (res.check) {
        num++;
      }
    }
    if (num > 1) return true;
    return false;
  }

  private obtenerIndicadoresMetasReporte() {
    const bordeSuperior = [false, false, false, true];
    let table: any = {
      widths: ['*'],
      body: [],
    };
    let cont = 0;
    let tableMeses: any = {
      table: {
        widths: [],
        body: [],
      },
      layout: 'noBorders',
    };
    let tableTitulos: any = {
      table: {
        widths: [],
        body: [
          [
          ],
        ],
      },
      layout: 'noBorders',
    };

    for (const an of this.aniosDisponiblesIM) {
      for (const mes of an.series) {
        if (mes.check) {
          cont++;
        }
      }
    }

    let widths: any = [];
    let ancho = (600/cont)/2;
    if(cont >= 6){
      ancho = (600/6)/2
    }
    for (let i = 0; i < cont; i++) {
        widths.push(ancho, 1, ancho);
    }
    let impFlag = false;
    let perspAnterior =
      this.indicadoresMostrarIM.length > 0
        ? this.escribirPerspectiva(this.indicadoresMostrarIM[0], 0)
        : '';
    let objAnterior = '';
    let arrGrafs = [];
    let arrLength = this.indicadoresMostrarIM.length;
    const sinBorders = [false, false, false, false];
    let contMetasPorPersp = 0;
    let isPrintable = false;

    for (let i = 0; i < this.indicadoresMostrarIM.length; i++) {
      if (
        !this.indicadoresMostrarIM[i].metas ||
        this.indicadoresMostrarIM[i].metas.length === 0
      ) {
        continue;
      }
      isPrintable = false;

      tableTitulos = {
        table: {
          widths: [],
          body: []
        },
        layout: 'noBorders',
      };
      const objAntDistinto =
        this.obtObjIM(this.indicadoresMostrarIM[i].objectiveId) !== objAnterior;

      tableMeses = {
        table: {
          widths: [],
          body: [],
        },
        layout: {
          hLineColor: function (i) {
            return '#E0E0E0';
          },
          vLineColor: function (i, node) {
            return '#E0E0E0';
          },
        },
      };
      tableMeses.table.widths = [...widths];
      const respp = this.obtRes(i);
      let metReal: any = [
      ];
      const psp = this.escribirPerspectiva(this.indicadoresMostrarIM[i], i);
      if (psp !== perspAnterior) {
        contMetasPorPersp = 0;
      }
      let metaHeaders: any = [
      ];
      let validaMetaHeaders = perspAnterior !== psp || i === 0 || contMetasPorPersp === 0;
      let headers: any = [
      ]
      let subheaders: any = [
      ]
      let num = 0, espacio: any = [];
      for (const an of this.aniosDisponiblesIM) {
        for (const mes of an.series) {
          if (mes.check) {
            let meta = this.escribirMetaIM(
              mes.periodo,
              this.indicadoresMostrarIM[i]
            );
            if (meta !== 'null' && meta !== '') {
              isPrintable = true;
            }
          }
        }
      }
      for (const an of this.aniosDisponiblesIM) {
        for (const mes of an.series) {
          if (mes.check) {
            let meta = this.escribirMetaIM(
              mes.periodo,
              this.indicadoresMostrarIM[i]
            );
            let real = this.escribirRealIM(
              mes.periodo,
              this.indicadoresMostrarIM[i]
            );

            if (meta !== 'null' && meta !== '') {
              if (Number(meta) && Number(meta) !== 0) {
                meta = Number(meta).toLocaleString('es-MX', {
                  maximumFractionDigits: 1,

                } ) ;
              }
            }
            if (real !== 'null' && real !== '') {
              if (Number(real) && Number(real) !== 0) {
                real = Number(real).toLocaleString('es-MX', {
                  maximumFractionDigits: 1
                });
              }
            }
            
            metaHeaders.push(
              {
                text: mes.periodo,
                colSpan: 3,
                fillColor: '#eeeeee',
                color: '#808080',
                bold: true,
                alignment: 'center',
                border: [true, false, true, false],
                borderColor: ['white', '#F5F5F5', 'white', '#F5F5F5'],
                fontSize: 10,
              },
              { text: '', border: [false, false, true, false] },
              { text: '', border: [false, false, true, false] }
            );
            espacio.push(
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              },
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              },
              {
                text: ' ',
                color: '#808080',
                border: [false, false, false, false],
                fontSize: 6,
              }
            );
            headers.push(
              {
                text: 'meta',
                color: '#808080',
                border: [true, false, true, true],
                alignment: 'left',
                fontSize: 8,
                bold: true,
              },
              {
                text: 'real',
                color: '#808080',
                border: [false, false, true, true],
                alignment: 'center',
                colSpan: 2,
                fontSize: 8,
                bold: true,
              },
              { text: '', fontSize: 7 }
            );
            if (objAntDistinto) {
              subheaders.push(
                {
                  text: meta === 'null' ? '' : meta,
                  color: '#808080',
                  border: [true, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                },
                {
                  text: '',
                  fillColor:
                    this.escribirMetaIM(
                      mes.periodo,
                      this.indicadoresMostrarIM[i]
                    ) !== ''
                      ? this.pintBorde(
                          this.pintarBordeIM2(
                            this.escribirRealIM(
                              mes.periodo,
                              this.indicadoresMostrarIM[i]
                            ),
                            this.escribirMetaIM(
                              mes.periodo,
                              this.indicadoresMostrarIM[i]
                            ),
                            this.indicadoresMostrarIM[i]
                          )
                        )
                      : null,
                  fontSize: 4,
                  border: [false, true, false, true],
                },
                {
                  text:
                    real === 'null' && meta === 'null'
                      ? ''
                      : real === 'null'
                      ? '0'
                      : real,
                  color: '#808080',
                  border: [false, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                }
              );
            } else {
              subheaders.push(
                {
                  text: meta === 'null' ? '' : meta,
                  color: '#808080',
                  border: [true, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                },
                {
                  text: '',
                  fillColor:
                    this.escribirMetaIM(
                      mes.periodo,
                      this.indicadoresMostrarIM[i]
                    ) !== ''
                      ? this.pintBorde(
                          this.pintarBordeIM2(
                            this.escribirRealIM(
                              mes.periodo,
                              this.indicadoresMostrarIM[i]
                            ),
                            this.escribirMetaIM(
                              mes.periodo,
                              this.indicadoresMostrarIM[i]
                            ),
                            this.indicadoresMostrarIM[i]
                          )
                        )
                      : null,
                  fontSize: 4,
                  border: [false, true, false, true],
                },
                {
                  text:
                    real === 'null' && meta === 'null'
                      ? ''
                      : real === 'null'
                      ? '0'
                      : real,
                  color: '#808080',
                  border: [false, true, true, true],
                  alignment: 'left',
                  fontSize: 8,
                }
              );
            }

            metReal.push(
              {
                text: meta === 'null' ? '' : meta,
                color: '#808080',
                border: [true, true, true, true],
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                fillColor:
                  this.escribirMetaIM(
                    mes.periodo,
                    this.indicadoresMostrarIM[i]
                  ) !== ''
                    ? this.pintBorde(
                        this.pintarBordeIM2(
                          this.escribirRealIM(
                            mes.periodo,
                            this.indicadoresMostrarIM[i]
                          ),
                          this.escribirMetaIM(
                            mes.periodo,
                            this.indicadoresMostrarIM[i]
                          ),
                          this.indicadoresMostrarIM[i]
                        )
                      )
                    : null,
                fontSize: 4,
                border: [false, true, false, true],
              },
              {
                text:
                  real === 'null' && meta === 'null'
                    ? ''
                    : real === 'null'
                    ? '0'
                    : real,
                color: '#808080',
                border: [false, true, true, true],
                alignment: 'left',
                fontSize: 6,
              }
            );

            num++;
            if(num !== 0 && num < 12 && (num%6 === 0) && isPrintable){
              tableMeses.table.body.push(metaHeaders);
              tableMeses.table.body.push(headers);
              tableMeses.table.body.push(subheaders);
              tableMeses.table.body.push(espacio);

              objAnterior = this.obtObjIM(this.indicadoresMostrarIM[i].objectiveId);
              metaHeaders = [];
              headers = [];
              subheaders = [];
              espacio = [];
            }
          }
        }
      }

      if(cont > 6 && cont < 12){
        for(var ag = cont; ag < 12; ag++){
          metaHeaders.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          espacio.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          headers.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
          subheaders.push(
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] },
            { text: '', fontSize: 7, border: [false, false, false, false] }
          );
        }
      }

      if (isPrintable && cont > 6) {
        tableMeses.table.body.push(metaHeaders);
        tableMeses.table.body.push(headers);
        tableMeses.table.body.push(subheaders);
        tableMeses.table.body.push(espacio);
        objAnterior = this.obtObjIM(this.indicadoresMostrarIM[i].objectiveId);
      }else if (isPrintable && cont < 6) {
        tableMeses.table.body.push(metaHeaders);
        tableMeses.table.body.push(headers);
        tableMeses.table.body.push(subheaders);
        tableMeses.table.body.push(espacio);
        objAnterior = this.obtObjIM(this.indicadoresMostrarIM[i].objectiveId);
      }
      const b = perspAnterior !== psp;
      if (
        i === 0 ||
        (b && contMetasPorPersp === 0 && tableMeses.table.body.length > 0)
      ) {
        table.body.push([
          {
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: psp,
                    color: '#808080',
                    bold: true,
                    fontSize: 12,
                    margin: [0, 20, 0, 0],
                    border: sinBorders,
                  },
                ],
              ],
            },
            layout: 'noBorders',
          },
        ]);
      }
      let r: String = '';
      if (tableMeses.table.body.length > 0) {
        if(objAntDistinto){
          table.body.push([
            {
              table: {

                widths: ['*'],
                body: [
                  [
                    {
                      text:  this.obtObjIM(this.indicadoresMostrarIM[i].objectiveId)

                        ,
                      color: '#808080',
                      border: sinBorders,
                      margin: [0, 0, 0, 0],
                      bold: true,
                      fontSize: 12,
                    }
                  ],
                ],
              },
            }
          ]);
        }

        let newTable: any = {
          table: {
            widths: [220, 220,],
            body: [
                 [{
                  text: 'Indicador',
                  fillColor: '#eeeeee',
                  color: '#808080',
                  bold: true,
                  fontSize: 10,
                  margin: [5, 0, 0, 0],
                },

                {
                  text: 'Responsable',
                  fillColor: '#eeeeee',
                  color: '#808080',
                  bold: true,
                  fontSize: 10,
                  margin: [5, 0, 0, 0],
                },],
                [
                  {
                    text: (this.verCodigo?'Código | ':'')+''+this.indicadoresMostrarIM[i].nombre,
                    color: '#808080',
                    border: bordeSuperior,
                    fontSize: 10,
                    margin: [5, 0, 0, 0],
                  },
                  {
                    text: this.obtRes(i),
                    color: '#808080',
                    border: bordeSuperior,
                    fontSize: 10,
                    margin: [5, 0, 0, 0],
                  },
                ],
            ],
          },
          layout: 'noBorders',
        };
        table.body.push([newTable]);
        table.body.push([tableMeses]);

        contMetasPorPersp++;
        perspAnterior = psp;

        if (this.imprimirGraficas) {
          r = this.img_graficas.get(this.indicadoresMostrarIM[i].id);
          if (r !== undefined) {
            arrGrafs.push({ image: r, width: 300, alignment: 'center' });
          }
        }
      }

      if (!this.imprimirGraficas) continue;

      if (
        i + 1 < arrLength &&
        this.escribirPerspectiva(this.indicadoresMostrarIM[i + 1], i + 1) !==
          psp &&
        arrGrafs.length > 0
      ) {
        const [grT, grtSize] = this.printGrafsInTable(arrGrafs);
        grT.margin = [0, 40, 0, 0];
        table.body.push([grT]);
        arrGrafs = [];
      } else if (i + 1 >= arrLength && arrGrafs.length > 0) {
        const [grT, grtSize] = this.printGrafsInTable(arrGrafs);
        if (grtSize >= 2) {
        } else {
        }
        grT.margin = [0, 40, 0, 0];
        table.body.push([grT]);
        arrGrafs = [];
      }
    }
    if (table.body.length === 0) {
      table.body.push({ text: '' });
    }
    return table;
  }

  private printGrafsInTable(arrGrafs: any) {
    let respArr = {
      table: {
        widths: ['*', '*'],
        body: [],
      },
      layout: 'noBorders',
    };
    let auxArr = [];
    let cont = 1;
    let i = 0;
    for (const el of arrGrafs) {
      if (cont <= 2) {
        auxArr.push(el);
      }
      if (cont === 2) {
        respArr.table.body.push(auxArr);
        cont = 0;
        auxArr = [];
      }
      if (i + 1 >= arrGrafs.length && auxArr.length === 1) {
        auxArr.push({ text: '' });
        respArr.table.body.push(auxArr);
      }
      cont++;
      i++;
    }
    const resp: [any, number] = [respArr, respArr.table.body.length];
    return resp;
  }

  public escribirPerIM(ind: any, i: any) {
    var perid1, perid2;

    for (const obj of this.objectivosIM) {
      if (obj.id === this.indicadoresMostrarIM[i].objectiveId) {
        perid1 = obj.idPer;
        break;
      }
    }
    if (i != 0) {
      for (const obj of this.objectivosIM) {
        if (obj.id === this.indicadoresMostrarIM[i - 1].objectiveId) {
          perid2 = obj.idPer;
        }
      }
      if (perid1 != perid2) {
        return this.obtPerIM(perid1);
      }
    } else {
      return this.obtPerIM(perid1);
    }

    return '';
  }

  escribirPerspectiva(ind: any, i: any) {
    let perid1;
    for (const obj of this.objectivosIM) {
      if (obj.id === this.indicadoresMostrarIM[i].objectiveId) {
        perid1 = obj.idPer;
      }
    }
    return this.obtPerIM(perid1);
  }

  public escribirObj(ind: any, i: any) {
    if (this.indicadoresMostrarIM.length === 1) {
      return this.obtObjIM(ind.objectiveId);
    } else {
      if (i != 0) {
        if (
          Number(ind.objectiveId) !=
          Number(this.indicadoresMostrarIM[i - 1].objectiveId)
        )
          return this.obtObjIM(ind.objectiveId);
      } else if (i === 0) {
        return this.obtObjIM(ind.objectiveId);
      }
    }
    return '';
  }

  private obtObjIM(objectiveid: any) {
    for (const obj of this.objectivosIM) {
      if (obj.id === objectiveid) {
        return obj.nombre;
      }
    }
  }

  public escribirMetaIM(periodo: any, ind: any) {
    var nombre = '';
    for (const met of ind.metas) {
      nombre =
        Meses.getMesesN(new Date(met.period).getMonth() + 1) +
        '-' +
        ('' + new Date(met.period).getFullYear()).substring(2, 4);
      if (nombre === periodo) {
        return '' + met.value;
      }
    }
    return '';
  }
  public escribirRealIM(periodo: any, ind: any) {
    var nombre = '';
    for (const met of ind.metas) {
      nombre =
        Meses.getMesesN(new Date(met.period).getMonth() + 1) +
        '-' +
        ('' + new Date(met.period).getFullYear()).substring(2, 4);
      if (nombre === periodo) {
        return '' + met.real;
      }
    }
    return '';
  }

  public quitarGrafLin(pos: any) {
    var seMuestran = 0;
    for (const graf of this.graficas_linealesIM) {
      if (graf.check) {
        seMuestran++;
      }
    }
    if (seMuestran > 1) {
      this.graficas_linealesIM[pos].check = false;
    } else {
    }
  }

  public exportarImg(dat, graf) {
    var grafica: string = '';
    var name: string = '';
    grafica = 'graf' + dat;
    name = '' + graf.name;

    IMG.exportimagenes(grafica, name);
  }

  async obtenerGraficasIM() {
    for (let i = 0; i < this.graficas_linealesIM.length; i++) {
      if (
        this.graficas_linealesIM[i].series.length > 0 &&
        this.graficas_linealesIM[i].check
      ) {
        await html2canvas(
          document.querySelector(
            '#graf' + this.graficas_linealesIM[i].indicadorId
          ),
          { scale: 1 }
        ).then((canvas) => {
          this.img_graficas.set(
            this.graficas_linealesIM[i].indicadorId,
            canvas.toDataURL()
          );
        });
      }
    }
  }

  public async DescargarPDF(descargar: boolean) {
    this.isDownloadingPdf = true;
    this.reporteGeneradoExitosamente = false;
    
    try {
      if (this.imprimirGraficas) {
        await this.obtenerGraficasIM();
      }

      let tableFoda;
      if (this.imprimirFoda && this.verFodaV1) {
        await this.getFloa_img();
        tableFoda = this.matrizFodaReporte();
      }
      let tableFodaV2;
      if (this.imprimirFodaV2 && this.verFodaV2) {
        await this.getFloa_img();
        tableFodaV2 = this.matrizFodaReporteV2();
      }

      let tableObjEst;
      if (this.imprimirObjetivos) {
        tableObjEst = this.objetivosEstrategicosReporte();
      }
      let tablaObjetivosReporte = this.obtenerObjetivosReporte();
      let tablaIndicadoresMetasReporte = this.obtenerIndicadoresMetasReporte();
      let tablaProyectos = this.obtenerProyectosEstrategicos();
      let tablaSemaforos = this.obtenerTablaSem();

      let conteoProyectos = this.obtenerTablaConteo();
      let conteoActividades = this.obtenerTablaConteoActividades();

      const logo = obtenerLogo.obtLogo(this.nombreInstitucion.acronimo);
      const fechaPiePag = new Date().toLocaleDateString('es-MX', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      });
      const horaPiePag = new Date().toLocaleTimeString('es-MX');
      const currentUser = this.dataService.currentUser;
      const docDefinition = {
        images: {
          logoAleema: ConstantesImagenes.fondoblanco,
        },
        pageSize: 'FOLIO',
        footer: function (currentPage, pageCount) {
          if (currentPage == 1) {
            return null;
          }
          if(currentPage === pageCount){
            return {
              columns: [
                {
                  image: 'logoAleema',
                  width: 70,
                },

                {
                  text: `${currentUser}  |  ${fechaPiePag}  |  ${horaPiePag}`,
                  alignment: 'center',
                  width: '*',
                  color: '#808080',
                  fontSize: 10,
                },

                {
                  text: (currentPage - 1).toString(),
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },
              ],
              margin: [25, -1, 30, 0],
            };
          }else{
            return{
              text:
                {
                  text: (currentPage - 1).toString(),
                  alignment: 'right',
                  color: '#808080',
                  fontSize: 10,
                  width: 'auto',
                },margin: [10, -1, 30, 0],

            }
          }

        },
        content: [
          this.imgLogo !== ''
            ? {
                image: this.imgLogo,
                width: 220,
                margin: [0, 0, 10, 0],
                absolutePosition: { y: 60 },
                alignment: 'right',
              }
            : { text: '' },
          {
            text: 'Informe de',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 250, 0, 0],
          },
          {
            text: 'seguimiento al plan',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 5, 0, 0],

          },
          {
            text: 'estratégico',
            style: 'header',
            bold: true,
            fontSize: 30,
            color: '#0455B2',
            margin: [60, 5, 0, 0],

          },
          {
            text: this.nombreInstitucion.nombre,
            style: 'header',
            bold: true,
            fontSize: 24,
            color: '#90BC40',
            margin: [60, 45, 0, 0],
          },
          {
            text: this.fechaReporte,
            style: 'header',
            bold: true,
            fontSize: 20,
            color: '#0455B2',
            margin: [60, 45, 0, 0],
          },
          {
            text: 'fecha de corte: ' + this.anioSelected,
            fontSize: 18,
            color: '#808080',
            margin: [60, 35, 0, 0],
          },
          {
            image: 'logoAleema',
            width: 100,
            absolutePosition: { x: 450, y: 850 },
          },

          this.imprimirIntro
            ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },
                {
                  text: `Avance de seguimiento ${this.anioInicial} - ${this.anioSelected}`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  absolutePosition: { x: 50, y: 40 },
                  bold: true,
                },
                {
                  text: 'introducción',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  absolutePosition: { x: 60, y: 70 },
                },
                {
                  alignment: 'justify',
                  text: this.introduccion,
                  style: 'header',
                  fontSize: 11,
                  color: '#808080',
                  absolutePosition: { x: 90, y: 130 },
                  margin: [0, 18],
                },
              ]
            : null,

          this.imprimirFoda
            ? [
                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: [
                    'La matriz estratégica FODA, corresponde a una metodología de diagnóstico que permite analizar los principales elementos internos y externos vistos desde diferentes áreas estratégicas, ofreciendo así un diagnóstico amplio y claro de la situación actual de la misma y sobre la cual podrá establecerse los lineamientos a trabajarse en un periodo futuro y bajo una visión transparente de lo que se puede lograr.\n\n',
                    'Esta matriz se encuentra dividida en cuatro cuadrantes que definen aspectos tanto internos',
                    '-fortalezas y limitaciones- como externos -oportunidades y amenazas-. En el caso de los factores',
                    'internos, estos corresponden a aquellos que han surgido de los diferentes procesos de análisis en',
                    `las 4 perspectivas: I. ${
                      this.perspectivesIM[0].name
                    }, II. ${this.perspectivesIM[1].name}, III. ${
                      this.perspectivesIM[2].name
                    } ${
                      this.perspectivesIM[3].name
                        ? 'y IV. ' + this.perspectivesIM[3].name
                        : ''
                    }, `,
                    'dentro del modelo de Cuadro de Mando Integral aplicado.\n\n',
                    'Uno de los pasos más importantes de la metodología de cuadro de mando integral consiste',
                    'en la definición de estrategias a partir del análisis y combinación de los elementos detectados y',
                    'plasmados en la herramienta de diagnóstico del FODA:',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },
                {
                  image: this.imgFloa,
                  width: 400,
                  alignment: 'center',
                  margin: [45, 30, 0, 0],
                },
                {
                  text: [
                    `La matriz FODA resultante de los análisis para institución ${this.nombreInstitucion.nombre}, definió los siguientes factores:`,
                  ],
                  alignment: 'justify',
                  margin: [45, 30, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },

                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz FODA',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  alignment: 'left',
                  bold: true,
                },
                {
                  table: tableFoda,
                  layout: {
                    hLineColor: function (i) {
                      return '#8190B1';
                    },
                    vLineColor: function (i, node) {
                      return '#8190B1';
                    },
                  },
                },
              ]
            : null,

          this.imprimirFodaV2
            ? [
                { text: '', pageBreak: 'after' },

                {
                  text: 'Matriz de riesgos y oportunidades',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  alignment: 'left',
                  bold: true,
                },
                {
                  table: tableFodaV2,
                  layout: {
                    hLineColor: function (i) {
                      return '#8190B1';
                    },
                    vLineColor: function (i, node) {
                      return '#8190B1';
                    },
                  },
                },
              ]
            : null,
          
          this.imprimirObjetivos
            ? [
                { text: '', pageBreak: 'after' },
                {
                  text: 'Objetivos Estratégicos',
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: 'definición',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                  alignment: 'left',
                },
                {
                  text: [
                    'Estos corresponden a propósitos macro que pretende lograr la organización en el período para el cual ha formulado su desarrollo y bajo los cuales alineará los planes operativos y demás actividades. Los objetivos estratégicos se construyen a través de la sumatoria conceptual de las estrategias elaboradas y se valida su alineamiento con la filosofía corporativa. Los objetivos resultantes por perspectiva son los siguientes:\n\n',
                  ],
                  alignment: 'justify',
                  margin: [45, 15, 0, 0],
                  color: '#808080',
                  fontSize: 11
                },
                {
                  text: [
                    {
                      text: 'Perspectivas\n',
                      fontSize: 16,
                      color: '#0455B2',
                      fillColor: '#FFFFFF',
                      colSpan: 2,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  margin: [45, 15, 0, 0],

                },
                {
                  table: tableObjEst,
                  margin: [45, 15, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                  markerColor: '#808080',
                },
                ] : null,
                this.imprimirCmi ? [
                  { text: '', pageBreak: 'after' },
                {
                  text: `Objetivos estratégicos`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: 'cuadro de Mando Integral - CMI',
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },

                ...this.cmiPage(),
              ]
            : null,

          this.imprimirIndicadores
            ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },
                {
                  text: `Indicadores de gestión`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],
                  bold: true,
                },
                {
                  text: `reporte de avance de objetivos ${this.anioSeleccionado.replace('-', '. ')}`,
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  table: tablaObjetivosReporte,
                  margin: [0, 30, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                },
                { text: '', pageBreak: 'after', pageOrientation: 'landscape' },

                {
                  text: `Cumplimiento de metas por perspectiva`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],

                  bold: true,
                },
                {
                  text: `resultados por periodo | ${this.fechaReporteIni.replace(
                    '-',
                    '. '
                  )} - ${this.anioSeleccionado.replace('-', '. ')}`,
                  style: 'header',
                  fontSize: 18,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },
                {
                  table: tablaIndicadoresMetasReporte,
                  margin: [0, 30, 0, 0],
                  layout: 'noBorders',
                },
              ]
            : null,

          this.imprimirPlanOperativo
            ? [
                { text: '', pageBreak: 'after', pageOrientation: 'portrait' },

                {
                  text: `Avance de ejecución del plan operativo`,
                  style: 'header',
                  fontSize: 24,
                  color: '#0455B2',
                  margin: [15, 0, 0, 0],

                  bold: true,
                },
                {
                  text: 'proyectos',
                  style: 'header',
                  fontSize: 20,
                  color: '#ACBD3D',
                  margin: [25, 0, 0, 0],
                },

                {
                  table: tablaProyectos,
                  margin: [0, 30, 0, 0],
                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return 'white';
                    },
                  },
                },
              ]
            : null,
          [
            { text: '', pageBreak: 'after', pageOrientation: 'portrait' },

            {
              text: `Resumen de avance de POA`,
              style: 'header',
              fontSize: 24,
              color: '#0455B2',
              margin: [15, 0, 0, 0],

              bold: true,
            },
            {
              text: `${this.anioSeleccionado.replace('-', '. ')}`,
              style: 'header',
              fontSize: 20,
              color: '#ACBD3D',
              margin: [25, 0, 0, 0],
            },
            {
              text: [
                `El portafolio de proyectos corresponde a `, { text: `${this.proyectosMostrar.length}`, bold: true, fontSize: 12}, ` proyectos totales, `,
                this.imprimirPlanOperativoActividades? `cada uno con activiades asociadas, las cuales para este periodo  suman un total de `:'',
                this.imprimirPlanOperativoActividades? { text: `${this.numActivid}`, bold: true, fontSize: 12}:'',
                this.imprimirPlanOperativoActividades? ` actividades, `:'',
                'esto se encuentra distribuido en los siguientes estatus.',
              ],
              alignment: 'justify',
              margin: [45, 15, 0, 0],
              color: '#808080',
              fontSize: 11
            },
            {
              table: conteoProyectos,
              margin: [45, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            },

            this.imprimirPlanOperativoActividades?{
              table: conteoActividades,
              margin: [45, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            }:{},
            {
              table: tablaSemaforos,
              margin: [120, 30, 0, 0],
              layout: {
                hLineColor: function (i) {
                  return '#E0E0E0';
                },
                vLineColor: function (i, node) {
                  return 'white';
                },
              },
            },
          ]
        ],
      };

      this.reporteGeneradoExitosamente = true;
      this.reportName =
        'Reporte_seguimiento_Plan_Estrategico-' + this.fechaReporte + '.pdf';
      const pdf = pdfMake.createPdf(docDefinition);
      if (descargar) {
        pdf.download(this.reportName);
      }
      await new Promise<void>((resolve) => {
        pdf.getBlob((blob) => {
          this.pdfFile = blob;
          resolve();
        });
      });

      setTimeout(() => {
        this.isDownloadingPdf = false;
      }, 2000);
    } catch (error) {
      this.reporteGeneradoExitosamente = false;
      Swal.close();
      Swal.fire({ title: 'Error al generar el reporte', timer: 15000 });
    }
  }
  private obtenerTablaSem(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150, 150],
      body: [
        [
          {
            text: 'Valores',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Color',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };
    console.log("🚀 ~ this.semaforo =>", this.semaforoPro)

    const sema = this.semaforoPro.sort(function (a: any, b: any) {
      if ( a.valIn > b.valIn ) {
        return 1;
      }
      if ( a.valIn < b.valIn ) {
        return -1;
      }
      return 0;
    });

    for(const sem of sema){
      console.log("🚀 ~ sem =>", sem)
      
    }

    return table;
  }
  private obtenerTablaConteo(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150, 150, 150],
      body: [
        [
          {
            text: 'Estatus del proyecto',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Número de proyectos',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
          {
            text: 'Porcentaje de proyectos',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };
    
    let colors = [0, 0, 0, 0];
    for(const pro of this.proyectosMostrar){
      const col = this.pintarBordPro( this.escribirDatPro(pro, 'ejec'), this.escribirDatPro(pro, 'plan') );
      switch(col){
        case 'bordeAzul':
        case 'bordeVerde':
          colors[0]++;
          break;
        case 'bordeRojo':
        case 'bordeNaranja':
          colors[1]++;
          break;
        case 'bordeAmarillo':
          colors[2]++;
          break;
        case 'bordeGrey':
        case 'bordeGris':
          colors[3]++;
          break;
      }
    }
    const etiquetas = ['Cumplidos', 'No cumplido', 'En proceso', 'Sin iniciar'];
    for(var i = 0; i<4; i++){
      table.body.push([
        {
          text: ''+etiquetas[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+colors[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
        {
          text: ''+Number((colors[i]*100/this.proyectosMostrar.length).toFixed(1)) + ' %',
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
      ]);
    }

    table.body.push([
      {
        text: 'Total',
        color: '#808080',
        border: [false, true, false, true],
        fillColor: '#eeeeee',
        bold: true,
        fontSize,
      },
      {
        text: ''+this.proyectosMostrar.length,
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        fontSize,
        bold: true,
        alignment: 'right'
      },
      
      {
        text: '100 %',
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
    ]);
    return table;
  }
  private obtenerTablaConteoActividades(){
    const fontSize = 10;
    let table: any = {
      headerRows: 1,
      widths: [150,150,150],
      body: [
        [
          {
            text: 'Estatus de la actividad',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'left'
          },
          {
            text: 'Número de actividades',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
          {
            text: 'Porcentaje de actividades',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
            alignment: 'center'
          },
        ],
      ],
    };
    
    let colors = [0, 0, 0, 0];
    this.numActivid = 0;
    for(const pro of this.proyectosMostrar){
      for(const act of pro.actividades){
        if(act.editable){
          const col = this.pintarBord( this.escribirDat(act.historico, 'ejec'), this.escribirDat(act.historico, 'plan') );
          switch(col){
            case 'bordeAzul':
            case 'bordeVerde':
              colors[0]++;
              break;
            case 'bordeRojo':
            case 'bordeNaranja':
              colors[1]++;
              break;
            case 'bordeAmarillo':
              colors[2]++;
              break;
            case 'bordeGrey':
            case 'bordeGris':
              colors[3]++;
              break;
          }
          this.numActivid++;
        }
      }
    }
    const etiquetas = ['Cumplidas', 'No cumplida', 'En proceso', 'Sin iniciar'];
    for(var i = 0; i<4; i++){
      table.body.push([
        {
          text: ''+etiquetas[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+colors[i],
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
        {
          text: ''+Number((colors[i]*100/this.numActivid).toFixed(1)) + ' %',
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
          alignment: 'right'
        },
      ]);
    }

    table.body.push([
      {
        text: 'Total',
        color: '#808080',
        border: [false, true, false, true],
        fillColor: '#eeeeee',
        fontSize,
        bold: true,
      },
      {
        text: ''+this.numActivid,
        color: '#808080',
        fillColor: '#eeeeee',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
      
      {
        text: '100 %',
        fillColor: '#eeeeee',
        color: '#808080',
        border: [false, true, false, true],
        bold: true,
        fontSize,
        alignment: 'right'
      },
    ]);
    return table;
  }

  private obtenerDatos() {
    this.proyectosestrategicosService.getPlaneacion().subscribe((response) => {
      this.obtenerFiltros(response);
      this.proyectosestrategicosService.getObjectives().subscribe((response) => {
          this.obtObjectives(response);
          this.proyectosestrategicosService.getProyectos().subscribe((response) => {
              this.obtProyectos(response);
              this.proyectosestrategicosService.getActividades().subscribe((response) => {
                  this.obtActividades(response);
                  this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
                    const proyect = this.obtenerProyectosPlaneacion(response);
                    this.obtenerProyectosPeriodo(proyect);
                    this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
                      this.obtAdditions(response);
                    });
                  });
                });
            });
          this.proyectosestrategicosService.getProgramas().subscribe((response) => {
              this.obtProgramas(response);
            });
        });
    });
    this.altaplanificacionesService
      .getPlanById(this.idPlanificacion.toString())
      .subscribe((r: any) => (this.introduccion = r.report_intro ?? ''));
  }

  private obtenerProyectosPeriodo(array: any){
    array = this.ordenamientoId(array);
    var proyectosPeriodo = [], actividadesPeriodo = [];
    var auxAct = []
    for(const pro of array){
      for(const act of pro.activities){
        if(act.history.length !== 0){
          var hist = [];
          for(const his of act.history){
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: his.executed,
              id: his.id,
            });
          }
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else if(this.actividadIncompleta(act)){
          var hist = [];
          hist.push({
            periodo: this.Periodo,
            planeado: 100,
            ejecutado: null,
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else{
          var hist = [];
          hist.push({
            periodo: this.Periodo,
            planeado: '-',
            ejecutado: '-',
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });       
        }
      }
      if(auxAct.length !== 0){
        proyectosPeriodo.push({proyecto: pro, actividades: auxAct, check: false,fecha_ini:undefined, fecha_fin: undefined, planeado: [], ejecutado: []});
      }
      auxAct = [];
    }
    proyectosPeriodo = this.obtfechasProy(proyectosPeriodo);
    this.proyectosFin = proyectosPeriodo;
    this.actividades = actividadesPeriodo;
  }

  private obtenerProyectosPlaneacion(array: any){
    var aux = [];
    for(const pro of array){
      for(const prog of this.objectives){
        /*if(pro.objectives[0].id === prog.id){
          aux.push(pro);
        }*/
        if(pro.objectives.length !== 0 && pro.objectives[0].id === prog.id){
          pro.perspective = prog.perspective;
          aux.push(pro);
        }
      }
    }
    return aux;
  }

  private obtenerFiltros(planeacion: any) {
    for (const plan of planeacion) {
      if (Number(plan.id) === this.idPlanificacion) {
        if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
          //this.verCodigo = true;
        }
        
        this.companyId = plan.institution.code
        this.obtenerLogo()

        this.diasLab = plan.business_days;
        this.aniosDisponibles = Meses.obtenerPeriodos(
          new Date(plan.initial_date),
          new Date(plan.end_date)
        );
        this.Periodo = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1].periodo;

        let anioIni = 0, anioFin = 0;
        anioIni = (new Date(plan.initial_date)).getFullYear();
        anioFin = (new Date(plan.end_date)).getFullYear();
        this.fechaReporte = anioIni + ' - ' + anioFin;

        this.anioInicial =
          this.aniosDisponibles[0].series[0].periodo.charAt(0).toUpperCase() +
          this.aniosDisponibles[0].series[0].periodo
            .replace('-', '. ')
            .slice(1);
        this.anioSelected =
          (
            this.aniosDisponibles[this.aniosDisponibles.length - 1].series[
              this.aniosDisponibles[this.aniosDisponibles.length - 1].series
                .length - 1
            ].periodo as string
          )
            .charAt(0)
            .toUpperCase() +
          (
            this.aniosDisponibles[this.aniosDisponibles.length - 1].series[
              this.aniosDisponibles[this.aniosDisponibles.length - 1].series
                .length - 1
            ].periodo as string
          )
            .replace('-', '. ')
            .slice(1);
        this.anioSeleccionado =
          this.aniosDisponibles[this.aniosDisponibles.length - 1].series[
            this.aniosDisponibles[this.aniosDisponibles.length - 1].series
              .length - 1
          ].periodo;

        this.perspectives = this.ordenamientoId(this.perspectives);

        for (const sem of plan.signals) {
          this.semaforo.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }
        const sema = this.ordenamientoSem(plan.signals);
        if( Number(sema[sema.length-1].value_01) > 100){
          const valFinal = Number(sema[sema.length-1].value_01);
          for(const sem of sema){
            this.semaforoPro.push({
              valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
              valfin: Math.round((Number(sem.value_01)*100)/valFinal),
              color: sem.color[0],
            });
          }
        }else{
          for(const sem of sema){
            this.semaforoPro.push({
              valIn: Number(sem.value_00),
              valfin: Number(sem.value_01),
              color: sem.color[0],
            });
          }
        }
      }
    }
  }

  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public ordenamientoId(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private obtObjectives(array: any) {
    var aux = [];
    for (const obj of array) {
      for (const per of this.perspectivesIM) {
        if (obj.perspective.id === per.id) {
          obj.check = true;
          aux.push(obj);
        }
      }
    }
    this.objectives = [];
    this.objectives = this.ordenamientoId(aux);

  }

  private obtObjectivesPO(array: any) {
    var aux = [];
    for (const obj of array) {
      for (const per of this.perspectives) {
        if (obj.perspective.id === per.id) {
          obj.check = true;
          aux.push(obj);
        }
      }
    }
    this.objectives = [];
    this.objectives = this.ordenamientoId(aux);
  }

  private obtProyectos(array: any) {
    var aux = [];
    for (const pro of array) {
      for (const prog of this.objectives) {
        /*if(pro.objectives[0].id === prog.id){
          aux.push(pro);
        }*/
        if (pro.objectives.length !== 0 && pro.objectives[0].id === prog.id) {
          pro.perspective = prog.perspective;
          aux.push(pro);
        }
      }
    }
    this.proyectos = this.ordenamientoId(aux);
  }

  private obtActividades(array: any) {
    var aux = [];
    for (const act of array) {
      for (const pro of this.proyectos) {

        if (act.project && act.project.id === pro.id) {
          var hist = [];
          for (const his of act.history) {
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: Number(his.executed),
              id: his.id,
            });
          }
          aux.push({
            additions: [],
            end_date: act.end_date,
            id: act.id,
            initial_date: act.initial_date,
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            proyecto: pro,
          });
        }
      }
    }
    for(const act of aux){
      if(act.historico.length === 0){
        act.historico = Meses.obtenerPlaneado(new Date(act.initial_date), new Date(act.end_date), this.diasLab);
        for(const hist of act.historico){
          this.proyectosestrategicosService.postHistorico(hist, Number(act.id)).subscribe(response =>{
            hist.id = response.id;
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        }
      }
    }
    this.actividades = this.ordenamientoId(aux);
    aux = [];
    var aux2 = [];
    for (const pro of this.proyectos) {
      for (const act of this.actividades) {
        if (pro.id === act.project.id) {
          aux2.push(act);
        }
      }
      if (aux2.length) {
        aux.push({
          proyecto: pro,
          actividades: aux2,
          check: true,
          fecha_ini: undefined,
          fecha_fin: undefined,
          planeado: [],
          ejecutado: [],
        });
      }
      aux2 = [];
    }
    aux = this.obtfechasProy(aux);
    this.proyectosFin = aux;
  }

  public cambioObj(obj: any) {
    obj.check = obj.check;

    if (obj.check) {
      this.objSeleccionados.push(obj);
      this.objetivo = obj.name;
      this.objSelect = obj.id;
      this.todosObjetivos = false;
    } else {
      this.objSeleccionados = this.objSeleccionados.filter(
        (v) => v.id !== obj.id
      );
      if (this.objSeleccionados.length === 0) {
        this.todosObjetivos = true;
      }
    }

    this.indicadoresMostrar = [];
    for (const ind of this.indicadores) {
      if (this.MostrarCM(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }

    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }

    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });
    this.filtrarLideres();
  }

  selectAllObjetivos(todosobj) {
    this.todosObjetivos = todosobj;
    for (const obj of this.objectives) {
      obj.check = todosobj;
    }

    /*this.indicadoresMostrar = [];
    for (const ind of this.indicadores) {
      if (this.MostrarCM(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }*/
    this.indicadoresMostrar = [].concat(this.indicadores);

    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });
  }

  public obtfechasProy(array: any) {
    for (const pro of array) {
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for (const act of pro.proyecto.activities) {
        var fecha_inicio = new Date(act.initial_date),
          fecha_fin = new Date(act.end_date);
        if (pro.fecha_ini === undefined) {
          pro.fecha_ini = fecha_inicio;
        } else {
          if (pro.fecha_ini > fecha_inicio) {
            pro.fecha_ini = fecha_inicio;
          }
        }
        if (pro.fecha_fin === undefined) {
          pro.fecha_fin = fecha_fin;
        } else {
          if (pro.fecha_fin < fecha_fin) {
            pro.fecha_fin = fecha_fin;
          }
        }
      }
      pro.ejecutado = Meses.obtenerAvance(pro.fecha_ini, pro.fecha_fin);
      pro.planeado = Meses.obtenerPlaneado(
        pro.fecha_ini,
        pro.fecha_fin,
        this.diasLab
      );
    }

    return array;
  }

  public mostrar(pro: any){
    var perSelected = this.anSelected();
    var perIni = pro.fecha_ini.getFullYear();
    var perFin = pro.fecha_fin.getFullYear();
    
    var tieneAct = false;
    var actTiene = 0;
    for(const act of pro.actividades){
      if(act.editable){
        actTiene++;
      }
    }
    if(actTiene!==0){
      tieneAct = true;
    }
    /** verificación del periodo */
    var existPer = false;//
    if(!this.mostrarPeriodo){
      existPer = Meses.compararAnio(perIni, perFin, perSelected);
    }
    /** Vaerificar los objetivos */
    var selObj = false;
    if(this.objSelect !== undefined){
      for( const obj of pro.proyecto.objectives){
        if(obj.id === this.objSelect){
          selObj = true;
        }
      }
    }else{
      selObj = true
    }
    /** Vaerificar los programas */
    var selProg = false;
    if(this.proSelect){
      if(pro.proyecto.strategies[0].id === this.proSelect){
        selProg = true;
      }
    }else{
      selProg = true;
    }

    if(existPer && selObj && selProg && this.pertenece_perspective(pro) ){
      return true;
    }else if(tieneAct && selObj && selProg && this.pertenece_perspective(pro)){
      return true;
    }
    return false;
  }

  public texto(valor: any) {
    var color = '',
      valfin = 0;
    if (valor !== 0) {
      for (const sem of this.semaforo) {
        if (valfin < sem.valfin) {
          valfin = sem.valfin;
        }
      }
      if (valor > valfin) {
        valor = valfin - 1;
      }
      for (const sem of this.semaforo) {
        var ab = sem.valIn;
        if (ab !== 0) {
          ab = ab - 0.1;
        }
        if (valor >= ab && valor < sem.valfin) {
          color = sem.color;
          break;
        }
      }
    }

    switch (color) {
      case 'verde':
        return 'cifraSecVerd';
      case 'azul':
        return 'cifraSecAzul';
      case 'amarillo':
        return 'cifraSecAmar';
      case 'naranja':
        return 'cifraSecNarj';
      case 'rojo':
        return 'cifraSecRojo';
      default:
        return 'cifraSecGris';
    }
  }

  public escribirPerT(ind: any, i: any) {
    var perid1;
    for (const obj of this.objectivosIM) {
      if (obj.id === ind.objectiveId.id) {
        perid1 = obj.idPer;
      }
    }
    return this.obtPerIM(perid1);
  }

  private obtenerPondInd(ind: any) {
    var Nind = 0;
    for (const indi of this.indicadoresMostrarIM) {
      if (ind.objectiveId.id === indi.objectiveId.id) {
        Nind += indi.ponderacion;
      }
    }
    return (ind.ponderacion * 100) / Nind;
  }

  public porcentajePersCM(nomPer: string) {
    var persel = this.perSelected(),
      i = 0,
      metasTot = 0;
    var porcentaje = 0;
    for (const ind of this.indicadoresMostrar) {
      var per = this.escribirPerTCM(ind, i),
        pondInd = this.obtenerPondIndCM(ind);
      for (const met of ind.metas) {
        if (
          persel === Meses.obtnerPer(this.obtFech(met.period)) &&
          Number(nomPer) === Number(ind.objectiveId.id)
        ) {
          const val = this.escribirReal(persel, ind),
            met = this.escribirMeta(persel, ind);
          var porcen = undefined;
          /*if (val === 0 && met !== 0) {
            switch (ind.tendencia) {
              case '-1':
                porcen = 1000;
                break;
              case '1':
              case '0':
                porcen = 0;
                break;
            }
          } else if (val !== 0 && !isNaN(val)) {
            switch (ind.tendencia) {
              case '-1':
                if (met === 0 && val <= 0) {
                  porcen = 100;
                } else if (met === 0 && val > 0) {
                  porcen = 0;
                } else if (val < 0) {
                  const sem = this.semaforo.filter((sem) => sem.valfin >= 100);
                  porcen = sem[0].valfin;
                } else {
                  porcen = Number(((met * 100) / val).toFixed(2));
                }
                break;
              case '1':
              case '0':
                if (met === 0 && val >= 0) {
                  porcen = 100;
                } else if (met === 0 && val < 0) {
                  porcen = 0;
                } else if (val < 0) {
                  porcen = 0;
                } else {
                  porcen = Number(((val * 100) / met).toFixed(2));
                }
                break;
            }
          } else if (met === 0 && !isNaN(val)) {
            switch (ind.tendencia) {
              case '-1':
                if (met === 0 && val <= 0) {
                  porcen = 100;
                } else if (met === 0 && val > 0) {
                  porcen = 0;
                }
                break;
              case '1':
              case '0':
                if (met === 0 && val >= 0) {
                  porcen = 100;
                } else if (met === 0 && val < 0) {
                  porcen = 0;
                }
                break;
            }
          } else {
            var por = 0;
            porcentaje += 0;
            metasTot++;
          }*/
          let dat = this.rendimientoCM(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val)){
            porcen = undefined;
          }
          if (porcen !== undefined) {
            var valfin = 0;
            for (const sem of this.semaforo) {
              if (valfin < sem.valfin) {
                valfin = sem.valfin;
              }
            }
            if (porcen >= valfin) {
              porcen = valfin;
            }

            porcen = (porcen * pondInd) / 100;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }
      }
      i++;
    }
    var valfin = 0;
    for (const sem of this.semaforo) {
      if (valfin < sem.valfin) {
        valfin = sem.valfin;
      }
    }
    if (porcentaje >= valfin) {
      porcentaje = valfin;
    }
    if (metasTot !== 0)
      return Number(porcentaje.toFixed(0));
    else {
      return undefined;
    }
    return 0;
  }

  public rendimientoCM(real: any, value: any, ind){
    const met = {real: real, value: value};
    if( met.real < 0 && met.value >= 0 ){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';
        case '1':
        case '0':
          return '0 %';
      }
    }else if( met.value < 0.0 && met.real !== null ){
      switch(ind.tendencia){
        case '-1':
          if(met.value >= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.value <= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }

  private perSelectedCM() {
    for (const an of this.aniosDisponibles) {
      for (const mes of an.series) {
        if (mes.check) {
          return mes.periodo;
        }
      }
    }
    return '';
  }

  public escribirPerTCM(ind: any, i: any) {
    var perid1;
    for (const obj of this.objectivos) {
      if (obj.id === ind.objectiveId.id) {
        perid1 = obj.idPer;
      }
    }
    return this.obtPer(perid1);
  }

  public porcentajeObjecCM() {
    var persPon = 0,
      perTot = 0;

    for (const obj of this.objectivos) {
      var por = this.porcentajePersCM(obj.id),
        porcentajeObj = this.obtenerPonder(obj);
      if (por != undefined) {
        persPon += (por * porcentajeObj) / 100;
      }
    }
    var valfin = 0;
    for (const sem of this.semaforo) {
      if (valfin < sem.valfin) {
        valfin = sem.valfin;
      }
    }
    if (persPon >= valfin) {
      persPon = valfin;
    }
    return Number(persPon.toFixed(0));
  }

  private obtenerPondIndCM(ind: any) {
    var Nind = 0;
    for (const indi of this.indicadoresMostrar) {
      if (ind.objectiveId.id === indi.objectiveId.id) {
        Nind += indi.ponderacion;
      }
    }
    return (ind.ponderacion * 100) / Nind;
  }

  public escribirReal(periodo: any, ind: any) {
    //avance
    var nombre = '';
    for (const met of ind.metas) {
      nombre =
        Meses.getMesesN(this.obtFech(met.period).getMonth() + 1) +
        '-' +
        ('' + this.obtFech(met.period).getFullYear()).substring(2, 4);
      if (nombre === periodo) {
        return  met.real;
      }
    }
    return '';
  }

  public escribirMeta(periodo: any, ind: any) {
    var nombre = '';
    for (const met of ind.metas) {
      nombre =
        Meses.getMesesN(this.obtFech(met.period).getMonth() + 1) +
        '-' +
        ('' + this.obtFech(met.period).getFullYear()).substring(2, 4);
      if (nombre === periodo) {
        return met.value;
      }
    }
    return '';
  }

  private obtAdditions(array: any){
    for(const act of this.actividades){
      var aux = [];
      for(const ad of array){
        if( ad.activity !== null && ad.activity.id === act.id){
          var fil = [];  
          if(ad.files && ad.files.length !== 0){
            for(const f of ad.files){
              const date = this.obtFech(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      act.additions = aux;
      this.isLoadingObtenerDatos = false;
    }
    for(const pro of this.proyectosFin){
      for(const act of pro.actividades){
        var aux = [];
        for(const ad of array){
          if( ad.activity !== null && ad.activity.id === act.id){
            var fil = [];  
            if(ad.files && ad.files.length !== 0){
              for(const f of ad.files){
                const date = this.obtFech(f.created_at);
                fil.push({
                  id: f.id,
                  name: f.name,
                  description: f.description,
                  path: f.path,
                  created_at: date,
                  updated_at: f.updated_at,
                });
              }
            }
            aux.push({
              amount: ad.amount,
              comments: ad.comments,
              id: ad.id,
              name: ad.name,
              note: ad.note,
              type: ad.type,
              files: fil,
            });
          }
        }
        aux = this.ordenamientoAd(aux);
        act.additions = aux;
      }
    }
    this.obtenerProy('cam');
    this.obtenerResponsablesDin();
    this.mostrarTable = true;
    console.log("🚀 ~ proyectosFin =>", this.proyectosFin)
  }

  private obtenerResponsablesDin(){
    var aux = [], aux2 = [];
    for(const pro of this.proyectosMostrar){
      const res = aux.filter(res => res.id === pro.proyecto.responsible.id);
      if(res.length === 0){
        aux.push({
          id: pro.proyecto.responsible.id,
          name: pro.proyecto.responsible.position,
          check: true,
        });
      }
      for(const act of pro.actividades){
        const resAct = aux2.filter(res => res.id === act.responsible.id);
        if(resAct.length === 0){
          aux2.push({
            id: act.responsible.id,
            name: act.responsible.position,
            check: true,
          });
        }
      }
    }
    this.responsables = this.ordenamientoAlf(aux);
    this.responsables2 = new MatTableDataSource(this.responsables);
    this.responsablesAct = this.ordenamientoAlf(aux2);
    this.responsablesAct2 = new MatTableDataSource(this.responsablesAct);
  }

  private ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
        return 1;
      }
      if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private obtenerProy(zon){
    var auxmos = [];
    for(const pro of this.proyectosFin){
      switch(zon){
        case 'all':
          if(this.mostrarConResponsables(pro)){
            auxmos.push(pro)
          }
        break;
        case 'cam':
          if(this.mostrar(pro)){
            auxmos.push(pro)
          }
        break;
      }
    }
    this.proyectosMostrar = auxmos;
    this.ordenamientoNuevo();
  }

  private ordenamientoNuevo(){
    switch(this.ordenamiento){
      case 'id':
        this.proyectosMostrar = this.ordenamientoIdSort(this.proyectosMostrar);
      break;
      case 'asc':
        this.proyectosMostrar = this.ordenamientoAlfAsc(this.proyectosMostrar);
      break;
      case 'des':
        this.proyectosMostrar = this.ordenamientoAlfDes(this.proyectosMostrar);
      break;
    }
  }

  private ordenamientoAlfDes(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.proyecto.name.toLowerCase() < b.proyecto.name.toLowerCase() ) {
        return 1;
      }
      if ( a.proyecto.name.toLowerCase() > b.proyecto.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private ordenamientoAlfAsc(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.proyecto.name.toLowerCase() > b.proyecto.name.toLowerCase() ) {
        return 1;
      }
      if ( a.proyecto.name.toLowerCase() < b.proyecto.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public ordenamientoIdSort(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.proyecto.id) > Number(b.proyecto.id)) {
        return 1;
      }
      if (Number(a.proyecto.id) < Number(b.proyecto.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  private anSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return an.anio;
        }
      }
    }
    return '';
  }

  public mostrarConResponsables(pro: any){
    var perSelected = this.anSelected();
    var perIni = pro.fecha_ini.getFullYear();
    var perFin = pro.fecha_fin.getFullYear();

    /** verificación del periodo */
    var existPer = true;

    if(this.mostrarPeriodo){
      existPer = Meses.compararAnio(perIni, perFin, perSelected);
    }

    /** verificación del lider */
    var existeLid = false;
    for(const res of this.responsables){
      if(res.id === pro.proyecto.responsible.id && res.check){
        existeLid = true;
      }
    }
    /** Vaerificar los objetivos */
    var selObj = false;
    if(this.objSelect !== undefined){
      for( const obj of pro.proyecto.objectives){
        if(obj.id === this.objSelect){
          selObj = true;
        }
      }
    }else{
      selObj = true
    }
    /** Vaerificar los programas */
    var selProg = false;
    if(this.proSelect){
      if(pro.proyecto.strategies[0].id === this.proSelect){
        selProg = true;
      }
    }else{
      selProg = true;
    }


    if( existPer && existeLid && selObj && selProg && this.pertenece_perspective(pro))
      return true;
    return false;
  }

  private pertenece_perspective(pro: any){
    if(this.perSelect !== undefined){
      var obj = [];
      for(const obje of pro.proyecto.objectives){
        const om = this.objectives.filter(obb => obb.id === obje.id);
        obj = obj.concat(om);
      }
      const ex = obj.filter(obj => obj.perspective.id === this.perSelect)
      if( ex.length !== 0 ){
        return true;
      }
      return false;
    }else{
      return true;
    }
  }

  private obtFech(isodate: any) {
    const fecha = isodate.split('T')[0];
    var fech = new Date(
      fecha.split('-')[1] +
        '/' +
        fecha.split('-')[2] +
        '/' +
        fecha.split('-')[0]
    );
    return fech;
  }

  private obtProgramas(array: any) {
    var aux = [];
    for (const prog of array) {
      for (const obj of this.objectives) {
        if (prog.objectives.length > 0 && prog.objectives[0].id === obj.id) {
          aux.push({
            id: prog.id,
            name: prog.name,
            description: prog.description,
            projects: this.ordenamientoId(prog.projects),
            objectives: this.ordenamientoId(prog.objectives),
            avance: 0,
            atraso: 0,
          });
        }
      }
    }
    this.programas = [];
    this.programas = this.ordenamientoObjId(this.ordenamientoId(aux));
  }

  public ordenamientoObjId(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.objectives[0].id) > Number(b.objectives[0].id)) {
        return 1;
      }
      if (Number(a.objectives[0].id) < Number(b.objectives[0].id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  public pintarBorde(valor: any) {
    if (valor != 0) {
      var color = '',
        valfin = 0;
      for (const sem of this.semaforo) {
        if (valfin < sem.valfin) {
          valfin = sem.valfin;
        }
      }
      if (valor > valfin) {
        valor = valfin - 1;
      }
      for (const sem of this.semaforo) {
        var ab = sem.valIn;
        if (ab !== 0) {
          ab = ab - 0.1;
        }
        if (valor >= ab && valor <= sem.valfin) {
          color = sem.color;
          break;
        }
      }
      switch (color) {
        case 'verde':
          return 'bordeVerde';
        case 'azul':
          return 'bordeAzul';
        case 'amarillo':
          return 'bordeAmarillo';
        case 'naranja':
          return 'bordeNaranja';
        case 'rojo':
          return 'bordeRojo';
        default:
          return 'bordeGrey';
      }
    } else {
      return 'bordeGrey';
    }
  }

  private obtenerProyectosEstrategicos() {
    const fontSize = 10;
    let text = this.imprimirPlanOperativoActividades?' / Actividades':''
    let table: any = {
      headerRows: 1,
      widths: ['*', 90, 'auto', 5, 50, 60],
      body: [
        [
          {
            text: 'Proyecto' + text,
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Líder/Responsables',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Planeado',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
          {
            text: 'Ejecutado',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            colSpan: 2,
            fontSize: 11,
          },
          { text: '' },
          {
            text: 'Estatus',
            fillColor: '#eeeeee',
            color: '#808080',
            bold: true,
            fontSize: 11,
          },
        ],
      ],
    };
    let actAdic: any = [];

    for (const pro of this.proyectosMostrar) {
      table.body.push([
        {
          text: pro.proyecto.name,
          color: '#808080',
          border: [false, true, false, true],
          bold: true,
          fontSize,
        },
        {
          text: pro.proyecto.responsible.position,
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: this.escribirDatPro(pro, 'plan'),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: '',
          fillColor: this.pintBorde(
            this.pintarBordPro(
              this.escribirDatPro(pro, 'ejec'),
              this.escribirDatPro(pro, 'plan')
            )
          ),
          fontSize,
        },
        {
          text: this.escribirDatPro(pro, 'ejec'),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
        {
          text: ''+this.estatus(
            this.pintarBordPro(
              this.escribirDatPro(pro, 'ejec'),
              this.escribirDatPro(pro, 'plan')
            )
          ),
          color: '#808080',
          border: [false, true, false, true],
          fontSize,
        },
      ]);
      if(this.imprimirPlanOperativoActividades){
        for(const act of pro.actividades){
          if(act.editable){
            table.body.push([
              {
                text: act.name,
                color: '#808080',
                border: [false, true, false, true],
                margin: [10,0,0,0],
                fontSize,
              },
              {
                text: act.responsible.position,
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: this.escribirDat(act.historico, 'plan'),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: '',
                fillColor: this.pintBorde(
                  this.pintarBord(
                    this.escribirDat(act.historico, 'ejec'),
                    this.escribirDat(act.historico, 'plan')
                  )
                ),
                fontSize,
              },
              {
                text: this.escribirDat(act.historico, 'ejec')===null?0:this.escribirDat(act.historico, 'ejec'),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
              {
                text: ''+this.estatus(
                  this.pintarBord(
                    this.escribirDat(act.historico, 'ejec'),
                    this.escribirDat(act.historico, 'plan')
                  )
                ),
                color: '#808080',
                border: [false, true, false, true],
                fontSize,
              },
            ]);
          }
        }
      }
    }
    return table;
  }
  private estatus(col){
    switch(col){
      case 'bordeAzul':
      case 'bordeVerde':
        return 'Cumplida';
      case 'bordeRojo':
      case 'bordeNaranja':
        return 'No cumplida';
      case 'bordeAmarillo':
        return 'En proceso';
      case 'bordeGrey':
      case 'bordeGris':
        return 'Sin iniciar';
      default:
        return '';
    }

  }

  public existAct(act: any) {
    var existePeriodo = false;
    var per_sel = '';

    for (const an of this.aniosDisponibles) {
      for (const mes of an.series) {
        if (mes.check) {
          per_sel = mes.periodo;
        }
      }
    }

    if (act.initial_date != undefined && act.end_date != undefined) {
      var per1 =
        Meses.getMesesN(new Date(act.initial_date).getMonth() + 1) +
        '-' +
        ('' + new Date(act.initial_date).getFullYear()).substring(2, 4);
      var per2 =
        Meses.getMesesN(new Date(act.end_date).getMonth() + 1) +
        '-' +
        ('' + new Date(act.end_date).getFullYear()).substring(2, 4);
      existePeriodo = Meses.compararPer(per1, per2, per_sel);
    }

    return existePeriodo;
  }

  public escribirDat(pro: any, tip: string){
    var per = this.perSelected();
    for(const dat of pro){
      if(per === dat.periodo){
        if(tip === 'plan'){
          return dat.planeado;
        }else if(tip === 'ejec'){
          return dat.ejecutado;
        }
      }
    }
  }

  public pintarBord(eje: any, plan: any){
    var porcentaje = undefined;
    var color = '';
    if(eje === 0 && plan !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro';
    }else if(eje === 0 && plan !== 0){
      porcentaje = 0;
    }else if(eje !== 0 && eje !== null){
      porcentaje = eje*100/plan;
      
    }
    if(porcentaje !== undefined){
      var valfin = 0;
      for(const sem of this.semaforoPro){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcentaje >= valfin){
        porcentaje = valfin-1;
      }
      for(const sem of this.semaforoPro){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcentaje >= ab && porcentaje <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintarBordPro(eje: any, plan: any){
    if(eje !== 0){
      var color = '';
      var por = eje*100/plan;
      var valfin = 0;
      for(const sem of this.semaforoPro){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(por >= valfin){
        por = valfin-1;
      }
      for(const sem of this.semaforoPro){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(por >= ab && por <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }

  public escribir_fecha(fecha: any) {
    var f = new Date(fecha),
      fech = '';
    fech =
      '' +
      f.getDate() +
      ' ' +
      Meses.getMesesN(f.getMonth() + 1) +
      ' ' +
      ('' + f.getFullYear()).substring(2, 4);
    return fech;
  }
  public obtenerDias(fIni: any, fFin: any) {
    var dias = 0,
      f1: any = new Date(fIni),
      f2: any = new Date(fFin);
    var diff = Math.round((f2 - f1) / (1000 * 60 * 60 * 24));
    switch (this.diasLab) {
      case 'Lunes-Viernes':
        var sem = Math.trunc(diff / 7);
        dias = diff - sem * 2;
        break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(diff / 7);
        dias = diff - sem;
        break;
      case 'Lunes-Domingo':
        dias = diff;
        break;
    }
    return dias;
  }
  public escribirDatPro(pro: any, tip: string){
    var per = this.perSelected();
    var dias = 0, porcentajeplan = 0, porcentajeejec = 0;
    for(const act of pro.actividades){
      if(act.editable){
        dias += this.obtenerDias(act.initial_date,act.end_date);
      }
    }
    var suma = false;
    for(const act of pro.actividades){
      if(act.editable){
        if(new Date( 20+per.split('-')[1], Meses.getMesesS(per.split('-')[0])-1 ) > new Date(act.end_date)){
          suma = true;
        }
        for(const his of act.historico){
          if(his.periodo === per || suma){
            if(suma){
              suma = false;
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((100*pesoAct/100).toFixed(2));
              let perAct;
              if(!this.actividadIncompleta(act)){
                perAct = Meses.obtnerPer( new Date(act.end_date) );
              }else{
                perAct = this.Periodo;
              }
              var ejeAct = 0;
              for(const h of act.historico){
                if(h.periodo === perAct){
                  ejeAct = h.ejecutado;
                  break;
                }
              }
              porcentajeejec += Number((ejeAct*pesoAct/100).toFixed(2));
            }else{
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((his.planeado*pesoAct/100).toFixed(2));
              porcentajeejec += Number((his.ejecutado*pesoAct/100).toFixed(2));
            }
          }
        }
      }
    }
    if(tip === 'plan'){
      if( Number(porcentajeplan.toFixed(2)) >= 99.99 ){
        return 100;
      }
      return Number(porcentajeplan.toFixed(2));
    }
    if( Number(porcentajeejec.toFixed(2)) >= 100 ){
      return 100;
    }
    return Number(porcentajeejec.toFixed(2));
  }
  private actividadIncompleta(act: any){
    var mes = Meses.getMesesS(this.Periodo.split('-')[0])<10?'0'+Meses.getMesesS(this.Periodo.split('-')[0]):''+Meses.getMesesS(this.Periodo.split('-')[0]);
    const momentAct = moment('01-'+mes+'-20'+this.Periodo.split('-')[1], 'DD-MM-YYYY');

    var fechaFinAct;
    if((act.end_date+'').includes('Z')){
      fechaFinAct = this.obtFech(act.end_date);
    }else{
      fechaFinAct = act.end_date;
    }
    mes = (fechaFinAct.getMonth()+1)<10?'0'+(fechaFinAct.getMonth()+1):''+(fechaFinAct.getMonth()+1);
    var dia = fechaFinAct.getDate()<10?'0'+fechaFinAct.getDate():''+fechaFinAct.getDate();
    var anio = fechaFinAct.getFullYear();
    const momnetFinAct = moment(dia+'-'+mes+'-'+anio, 'DD-MM-YYYY');
    
    if(momentAct.isAfter(momnetFinAct) && act.completed !== true){
      return true;
    }
    return false;
  }

  public escribirAdicionalPro(pro: any, i: number) {
    var presTot = 0;
    for (const act of pro) {
      for (const ad of act.additions) {
        if (ad.type === 'moneda') {
          presTot += Number(ad.amount);
        }
      }
    }
    if (this.actividades[0].additions[i].type === 'moneda') {
      return this.formatearnormal(presTot);
    } else {
      return '';
    }
  }

  async imgCMI() {
    this.imgs_cmi = [];
    await html2canvas(document.getElementById('porcentaje_cmi'), {
      scale: 1,
    }).then((canvas) => {
      this.porcentaje_cmi_img = canvas.toDataURL();
    });
    for (const per of this.diccionarioPer) {
      if (per.perspective.check || this.todasPerIM) {
        await html2canvas(
          document.getElementById(
            'per-' + per.perspective.name.replaceAll(' ', '')
          ),
          { scale: 1 }
        ).then((canvas) => {
          this.imgs_cmi.push(canvas.toDataURL());
        });
      }
    }
  }

  public porcentajeObjec() {
    var persPon = 0,
      perTot = 0;

    for (const obj of this.objectivos) {
      var por = this.porcentajePersCM(obj.id),
        porcentajeObj = this.obtenerPonder(obj);
      if (por != undefined) {
        persPon += (por * porcentajeObj) / 100;
      }
    }
    var valfin = 0;
    for (const sem of this.semaforo) {
      if (valfin < sem.valfin) {
        valfin = sem.valfin;
      }
    }
    if (persPon >= valfin) {
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  private obtenerPonder(obj: any) {
    var ponderacionPer = 0;
    var i = 0,
      objList: any = [];

    for (const ind of this.indicadoresMostrar) {
      if (!objList.includes(ind.objectiveId.id)) {
        objList.push(ind.objectiveId.id);
      }
    }
    for (const obj of this.objectivos) {
      if (objList.includes(obj.id)) {
        ponderacionPer += obj.peso;
      }
    }
    return (obj.peso * 100) / ponderacionPer;
  }
 //////////
  //Porcentajes acumulados
  public porcentajeObjecAcum(){
    var persPon = 0, perTot = 0;

    var pesoFin = 0; 
    for(const obj of this.objectivos){
      var por = this.porcentajeObjAcum(obj.id), porcentajeObj = obj.peso;
      if(por != undefined){
        pesoFin += obj.peso;
        persPon += por*porcentajeObj/100;
      }
    }
    if(persPon !== 0){
      persPon = persPon*100/pesoFin;
    }
    
    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin;
      }
    }
    if(persPon >= valfin){
      persPon = valfin;
    }
    return Number((persPon).toFixed(0));
  }
  public porcentajePersAcum(nomPer: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    for(const obj of this.objectivos){
      if(Number(obj.idPer) === Number(nomPer)){
        for(const ind of this.indicadores){
          var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
          let metas = ind.metas;
          for(var i = metas.length-1; i>=0; i--){
            const peer = ind.metas[i].period;
            if(fechaPer === peer && Number(obj.id) === Number(ind.objectiveId.id)){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              var porcen = undefined;
              let dat = this.rendimientoCMI(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val)){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }
              break;
            }else if(fechaPer>peer && metas[i].real !== null && Number(obj.id) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
              const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
              var porcen = undefined;
              let dat = this.rendimientoCMI(val,met, ind).split('%');
              porcen = Number(dat[0]);
              if(isNaN(val)){
                porcen = undefined;
              }
              if( porcen !== undefined ){
                var valfin = 0;
                for(const sem of this.semaforo){
                  if(valfin < sem.valfin){
                    valfin = sem.valfin;
                  }
                }
                if(porcen >= valfin){
                  porcen = valfin;
                }
                
                porcen = porcen * pondInd/100;
                porcentaje += porcen;
                metasTot++;
              }else{
                porcen === 0;
              }
              break;
            }
          }
        }
        if(porcentaje !== 0){
          peso += porcentaje*obj.peso/100;
          pesoPer += obj.peso;
        }
        porcentaje = 0;
      }
    }
    porcentaje = peso*100/pesoPer;

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  private obtenerPondIndAcum(ind: any){
    var Nind = 0;
    for(const indi of this.indicadores){
      if( Number(ind.objectiveId.id) === Number(indi.objectiveId.id) && this.porcentajeIndAcum(indi) !== undefined ){
        Nind += indi.ponderacion;
      }
    }
    return ind.ponderacion * 100 /Nind;
  }
  private porcentajeIndAcum(ind: any){
    var persel = this.perSelected(), metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = undefined;
    for(var i = ind.metas.length-1; i>=0; i--){
      const peer = ind.metas[i].period;
      if(fechaPer === peer){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        let dat = this.rendimientoCMI(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val)){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }else{
          porcentaje === 0;
        }
        break;
      }else if(fechaPer>peer && ind.metas[i].real !== null && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
        const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
        let dat = this.rendimientoCMI(val,met, ind).split('%');
        porcentaje = Number(dat[0]);
        if(isNaN(val)){
          porcentaje = undefined;
        }
        if( porcentaje !== undefined ){
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          if(porcentaje >= valfin){
            porcentaje = valfin;
          }
        }
        break;
      }
    }
    if( porcentaje!== undefined )
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
  }
  public porcentajeObjAcum(objId: string){
    var persel = this.perSelected(), i = 0, metasTot = 0;
    let fechaPer = this.fechaMeta(persel);
    var porcentaje = 0;
    let pesoPer = 0, peso = 0;
    
    
    for(const ind of this.indicadores){
      var per = this.escribirPerT(ind,i), pondInd = this.obtenerPondIndAcum(ind);
      let metas = ind.metas;
      for(var i = metas.length-1; i>=0; i--){
        const peer = ind.metas[i].period;
        if(fechaPer === peer && Number(objId) === Number(ind.objectiveId.id)){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          var porcen = undefined;
          let dat = this.rendimientoCMI(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val)){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }
          break;
        }else if(fechaPer>peer && metas[i].real !== null && Number(objId) === Number(ind.objectiveId.id) && Number(peer.split('-')[0]) === Number(fechaPer.split('-')[0])){
          const val = this.escribirReal(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          const met = this.escribirMeta(Meses.obtnerPer(this.obtFech(ind.metas[i].period)), ind);
          var porcen = undefined;
          let dat = this.rendimientoCMI(val,met, ind).split('%');
          porcen = Number(dat[0]);
          if(isNaN(val)){
            porcen = undefined;
          }
          if( porcen !== undefined ){
            var valfin = 0;
            for(const sem of this.semaforo){
              if(valfin < sem.valfin){
                valfin = sem.valfin;
              }
            }
            if(porcen >= valfin){
              porcen = valfin;
            }
            
            porcen = porcen * pondInd/100;
            porcentaje += porcen;
            metasTot++;
          }else{
            porcen === 0;
          }
          break;
        }
      }
    }

    var valfin = 0;
    for(const sem of this.semaforo){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin;
    }    
    if(metasTot !== 0)
     return Number(porcentaje.toFixed(0));//*100/ponderacionPer
    else{
      return undefined
    }
    return 0
  }
  
  private fechaMeta(periodo){
    let fecha = new Date('01-'+Meses.getMesesNum(periodo.split('-')[0])+'-'+periodo.split('-')[1]);
    return moment(fecha).format("YYYY-DD-MM");
  }
  public rendimientoCMI(real: any, value: any, ind){
    const met = {real: real===null?null:real, value: value===null?null:value};
    if(met.real < 0 && met.value >= 0 ){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null ){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  private obtFechAcum(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }

  public anioChange(per: any, anio: any) {
    this.anioSelected =
      (per as string).charAt(0).toUpperCase() +
      (per as string).replace('-', '. ').slice(1);
    this.anioSeleccionado = per;

    for (const an of this.aniosDisponibles) {
      for (const mes of an.series) {
        mes.periodo === per ? (mes.check = true) : (mes.check = false);
        mes.periodo === per? this.Periodo = mes.periodo: '';
      }
    }

    for(let i = 0; i < this.aniosDisponiblesIM.length; i++) {
      for (let j = 0; j < this.aniosDisponiblesIM[i].series.length; j++) {
        this.aniosDisponiblesIM[i].series[j].check = false;
      }
    }

    for (const an of this.aniosDisponiblesCM) {
      for (const mes of an.series) {
        mes.periodo === per ? (mes.check = true) : (mes.check = false);
      }
    }
    this.selPeriodosIndMetas(per, this.numPeridos);

    this.indicadoresMostrar = [];
    for (const ind of this.indicadores) {
      if (this.MostrarCM(ind)) {
        this.indicadoresMostrar.push(ind);
      }
    }

    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }

    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });
    this.contar_coloresIM();
    //this.obtAvance();
  }

  public cambioPorPeriodo(){
    var per = this.perSelected();
    this.porPeriodo = !this.porPeriodo;
    this.selPeriodosIndMetas(per,this.numPeridos);
  }

  public cambioPeriodos(){
    if(Number(document.getElementById('numPer')['value']) > 12){
      document.getElementById('numPer')['value'] = 12;
    }
    if(Number(document.getElementById('numPer')['value']) < 1){
      document.getElementById('numPer')['value'] = 1;
    }
    var per = this.perSelected();
    this.numPeridos = Number(document.getElementById('numPer')['value']);
    this.selPeriodosIndMetas(per,this.numPeridos);
  }

  selPeriodosIndMetas(per: any, periodos: any) {
    if (this.porPeriodo) {
      var b = true;
      for (const an of this.aniosDisponiblesIM) {
        if(an.anio === (new Date('01-'+Meses.getMesesNum(per.split('-')[0])+'-'+per.split('-')[1])).getFullYear()){
          b = true;
        }else{
          b = false;
        }
        for (const mes of an.series) {
          if (b) {
            mes.check = true;
          } else {
            mes.check = false;
          }
          if (mes.periodo === per) {
            b = false;
          }
        }
      }
    }else{
      let b = false, sel = 0;
      for (var i=this.aniosDisponiblesIM.length-1; i>=0; i--) {
        for (var j=this.aniosDisponiblesIM[i].series.length-1; j>=0; j--) {
          if (this.aniosDisponiblesIM[i].series[j].periodo === per) {
            this.aniosDisponiblesIM[i].series[j].check = true;
            b = true;
            sel++;
          } else if(b && sel < periodos){
            this.aniosDisponiblesIM[i].series[j].check = true;
            sel++;
          }else {
            this.aniosDisponiblesIM[i].series[j].check = false;
          }
        }
      }
    }
  }

  public images_cmi() {
    let resp: any[] = [];
    resp.push({
      image: this.porcentaje_cmi_img,
      width: 500,
      alignment: 'center',
      margin: [0, 30, 0, 0],
    });
    for (const img of this.imgs_cmi) {
      resp.push({
        image: img,
        width: 500,
        alignment: 'center',
        margin: [0, 30, 0, 0],
      });
    }
    return resp;
  }

  public mostrarPers(pers: any) {
    var perSelected = 0;
    for (const per of this.diccionarioPer) {
      if (per.perspective.check) {
        perSelected++;
      }
    }
    if (this.todasPer || perSelected === 0) {
      return true;
    } else if (pers.perspective.check) {
      return true;
    }
    return false;
  }

  async generarReporte() {
    Swal.fire({
      title: 'Generando reporte',
      html: 'Por favor espere...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(undefined);
      },
    });

    await this.DescargarPDF(true);
    if (this.reporteGeneradoExitosamente) {
      Swal.close();
      Swal.fire({
        title: 'Reporte descargado exitosamente',
        text: 'Puede encontrar el archivo en la carpeta "Descargas"',
        timer: 15000,
      });
    }
  }

  cmiPage() {
    const fontSize = 10;
    const sinBorders = [false, false, false, false];
    const conBorders = [true, true, true, true];
    let table: any = {
      widths: ['*'],
      body: [],
      heights: 50,
      layout: 'noBorders',
      dontBreakRows: true,
    };
    let i = 0;
    for (const per of this.diccionarioPer) {
      if (per.perspective.check || this.todasPerIM) {
        table.body.push([
          {
            text: per.perspective.name,
            color: '#808080',
            bold: true,
            border: sinBorders,
            margin: [0, 15, 0, 0],
          },
        ]);
        for (const obj of per.objetivos) {
          let cols = [];
          const obs = this.objectives.filter((o) => o.id === obj.id);
          if (obs.length > 0 && obs[0].check) {
            const porcPers = this.porcentajeObjAcum(obj.id); //////////
            const colorBorde = this.pintBorde(this.pintarBorde(porcPers));
            cols.push({
              text: '',
              border: conBorders,
              fillColor: colorBorde,
              margin: [0, 0, 0, 5],
              fontSize: 4,
            });
            cols.push({
              text: obj.name,
              color: '#808080',
              border: conBorders,
              margin: [0, 5, 0, 5],
              fontSize,
            });
            cols.push({ text: '', border: sinBorders, fontSize: 2 });
            if (porcPers !== undefined) {
              cols.push({
                text: `${this.porcentajeObjAcum(obj.id)}%`,//////////
                color: colorBorde,
                margin: [0, 5, 0, 5],
                fontSize: 18,
                alignment: 'center',
              });
            } else {
              cols.push({ text: '', color: '#808080', margin: [0, 0, 0, 5] });
            }
            table.body.push(
              [
                {
                  table: {
                    body: [[...cols]],
                    widths: [5, 220, 5, 75],
                    heights: 40,
                  },

                  layout: {
                    hLineColor: function (i) {
                      return '#E0E0E0';
                    },
                    vLineColor: function (i, node) {
                      return '#E0E0E0';
                    },
                  },
                },
              ]
            );
          }
        }
      }
      i++;
    }
    if (table.body.length === 0) {
      table.body.push([{ text: '' }]);
    }
    let text = [
      {
        text: [
          { text: 'Avance del plan ', color: '#808080', fontSize: 16 },
          {
            text: `${this.porcentajeObjecAcum()} %`, //////////
            color: '#808080',
            bold: true,
            fontSize: 17,
          },
        ],
        margin: [30, 20, 0, 10],
      },
      {
        table: table,
        margin: [30, 10, 0, 0],
        layout: 'noBorders',
      },
    ];

    return text;
  }

  private matrizFodaReporte() {
    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos internos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };

    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t1, t2]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos externos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    // @ts-ignore
    table1.body.push([t3, t4]);
    return table1;
  }
  private matrizFodaReporteV2() {
    let t1 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t2 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
      },
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
    };
    let tab: any = [];
    tab.push([
      {
        text: this.diccionarioFLOA1V2[0].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let i = 1;
    for (const ele of this.diccionarioFLOA1V2[0].elementooo) {
      tab.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA1V2[0].internosss) + '-' + i,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#414042',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#414042',
          margin: [0, 5, 0, 0],
        },
      ]);
      i++;
    }
    let tab2: any = [];
    tab2.push([
      {
        text: this.diccionarioFLOA1V2[1].internosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#414042',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let j = 1;
    for (const ele of this.diccionarioFLOA1V2[1].elementooo) {
      tab2.push(
        [
          {
            text: this.obtCodigo(this.diccionarioFLOA1V2[1].internosss) + '-' + j,
            border: [false, false, false, false],
            margin: [15, 5, 0, 0],
            fontSize: 11,
            color: '#414042',
          },
          {
            text: ele.nombre,
            border: [false, false, false, false],
            fontSize: 11,
            color: '#414042',
            margin: [0, 5, 0, 0],
          },
        ]
      );
      j++;
    }
    t1.table.body = tab;
    t2.table.body = tab2;
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t1.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t2.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let t3 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, true, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };
    let t4 = {
      table: {
        headerRows: 1,
        widths: [45, 190],
        body: [],
        layout: 'noBorders',
      },
      border: [false, false, false, false],
      fillColor: '#eeeeee',
      layout: {
        hLineColor: function (i) {
          return '#B6CDE0';
        },
        vLineColor: function (i, node) {
          return 'white';
        },
      },
    };

    let tab3: any = [];
    tab3.push([
      {
        text: this.diccionarioFLOA2V2[0].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let k = 1;
    for (const ele of this.diccionarioFLOA2V2[0].elementooo) {
      tab3.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[0].externosss) + '-' + k,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      k++;
    }

    let tab4: any = [];
    tab4.push([
      {
        text: this.diccionarioFLOA2V2[1].externosss.elemento,
        bold: true,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'left',
        border: [false, false, false, true],
        fontSize: 12,
        margin: [15, 5, 0, 5],
        fillColor: '#eeeeee',
        color: '#474648',
      },
      {
        text: '',
        style: 'tableHeader',
        border: [false, false, false, true],
      },
    ]);
    let l = 1;
    for (const ele of this.diccionarioFLOA2V2[1].elementooo) {
      tab4.push([
        {
          text: this.obtCodigo(this.diccionarioFLOA2V2[1].externosss) + '-' + l,
          border: [false, false, false, false],
          margin: [15, 5, 0, 0],
          fontSize: 11,
          color: '#474648',
        },
        {
          text: ele.nombre,
          border: [false, false, false, false],
          fontSize: 11,
          color: '#474648',
          margin: [0, 5, 0, 0],
        },
      ]);
      l++;
    }
    t3.table.body = tab3;
    t4.table.body = tab4;
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t3.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);
    t4.table.body.push([
      { text: ' ', border: [false, false, false, false] },
      { text: '', border: [false, false, false, false] },
    ]);

    let table1 = {
      widths: ['*', '*'],
      body: [
        [
          {
            text: ' \n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            alignment: 'left',
            border: [false, false, false, false],
          },
          {
            text: '',
            fillColor: '#FFFFFF',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: 'Elementos externos\n',
            fontSize: 16,
            color: '#0455B2',
            fillColor: '#FFFFFF',
            colSpan: 2,
            alignment: 'left',
            border: [false, false, false, false],
          },
          { text: '', fillColor: '#FFFFFF' },
        ],
      ],
    };
    // @ts-ignore
    table1.body.push([t3, t4]);
    table1.body.push(
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, true],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, true],
        },
      ],
      [
        {
          text: ' \n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Elementos internos\n',
          fontSize: 16,
          color: '#0455B2',
          fillColor: '#FFFFFF',
          alignment: 'left',
          border: [false, false, true, false],
        },
        {
          text: '',
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
      ]
    );
    // @ts-ignore
    table1.body.push([t1, t2]);
    return table1;
  }

  public obtDataFoda() {
    this.matrizService.getPlanificacion(this.idPlanificacion).subscribe( respuesta => {
      this.planeacionFoda = {
        id: respuesta.id,
        nombre: respuesta.name,
        fecha_inicio: new Date(respuesta.initial_date),
        fecha_fin: new Date(respuesta.end_date),
        institucionId: respuesta.institution.id,
        isActive: respuesta.isActive
      };

      for(const per of respuesta.perspectives){
        this.perspectiva2.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: this.idPlanificacion,
          id: per.id,
        });
      }
      this.perspectiva2 = this.ordenarPerspe(this.perspectiva2);

      this.internos.forEach(per => { this.diccionarioFLOA1.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva2}); });
      this.externos.forEach(per1 => { this.diccionarioFLOA2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva2}); });

      this.internos.forEach(per => { this.diccionarioFLOA1V2.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva2}); });
      this.externos.forEach(per1 => { this.diccionarioFLOA2V2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva2}); });

      this.elemento = [];
      this.matrizService.getFLOA().subscribe( res => {
        this.getFloaV1(res);
        this.getFloaV2(res);
      });
    });
    setTimeout(() => {
      this.isLoadingObtenerDatosFoda = false;
    }, 4000);
  }
  private getFloaV1(res){
    for (const per of this.diccionarioFLOA1) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.internosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
    for (const per of this.diccionarioFLOA2) {
      per.elementooo = [];
      for (const pers of per.perspectivaaa) {
        for (const matrizf of res) {
          if (matrizf.perspectiveId === pers.id && !matrizf.name.includes('&&')) {
            if (matrizf.type === per.externosss.type) {
              per.elementooo.push({
                id: matrizf.id,
                tipo: matrizf.type,
                nombre: matrizf.name,
                perspectiveId: matrizf.perspectiveId,
              });
              per.elementooo = this.ordenarPerspe(per.elementooo);
            }
          }
        }
      }
    }
  }
  private getFloaV2(res){
    this.elemento = [];
    for(const mat of res){
      const ex = this.perspectiva2.filter(per => Number(per.id) === Number(mat.perspective.id));
      if(ex.length !== 0 && mat.name.includes('&&')){
        this.elemento.push(mat);
      }            
    }
    this.elemento = this.ordenarPerspe(this.elemento);

    for(const dicc of this.diccionarioFLOA1V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['internosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: ''
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }
      }
    }

    for(const dicc of this.diccionarioFLOA2V2){
      const elements = this.elemento.filter(mat => mat.type === dicc['externosss'].type);
      dicc.elementooo = [];
      if(elements.length !== 0){
        for(const elem of elements){
          dicc.elementooo.push({
            id: elem.id,
            tipo: elem.type,
            nombre: elem.name.split('&&')[0],
            perspectiveId: elem.perspectiveId,
            pestel: elem.name.split('&&')[1],
          });
          dicc.elementooo = this.ordenarPerspe(dicc.elementooo);
        }            
      }
    }
  }

  public obtCodigo(inter: any) {
    switch (inter.id) {
      case 1:
        return 'F';
      case 2:
        return 'D';
      case 3:
        return 'O';
      case 4:
        return 'A';
    }
    return 'no';
  }

  private ordenarPerspe(array: any) {
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  async getFloa_img() {
    await html2canvas(document.querySelector('#floa_img'), {
      scale: 2.5,
      onclone: (doc) => {
        doc.getElementById('floa_img_cont').style.display = 'block';
      },
    }).then((canvas) => {
      this.imgFloa = canvas.toDataURL();
    });
  }

  private objetivosEstrategicosReporte() {
    let table = {
      widths: ['*'],
      body: [],
    };
    let body = [];
    let i = 0;
    for (const per of this.perspectivesIM) {
      const title = {
        text: per.name,
        border: [false, false, false, true],
        color: '#808080',
        bold: true,
        fontSize: 11
      };
      if (i > 0) {
        title['margin'] = [0, 15, 0, 0];
      }
      let elem = {
        ul: [],
        border: [false, false, false, false],
        markerColor:'#4E9AC7'
      };
      for (const ele of this.objectivosIM) {
        if (ele.idPer === per.id) {
          elem.ul.push({ text: ele.nombre, color: '#808080', fontSize: 11,  });
        }
      }
      i++;
      body.push([title]);
      body.push([elem]);
    }
    table.body = body;
    return table;
  }

  selecciondarTodosLideres(todosLideresSeleccionados) {
    this.todosLideresSeleccionados = todosLideresSeleccionados;
    for (const res of this.lideresMostrar) {
      res.check = todosLideresSeleccionados;
    }
    this.indicadoresMostrarIM = [];
    for (const ind of this.indicadoresIM) {
      if (this.MostrarIM(ind)) {
        this.indicadoresMostrarIM.push(ind);
      }
    }
    this.contar_coloresIM();
    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
      });
    });
  }

  filtrarLideres() {
    this.todosLideresSeleccionados = true;
    this.lideresMostrar = [];
    let lideresFiltrados = new Map<String, Object>();
    this.indicadoresMostrarIM.forEach((element) => {
      lideresFiltrados.set(element.areaId, element.area);
    });
    lideresFiltrados.forEach((element) => {
      element['check'] = true;
      this.lideresMostrar.push(element);
    });
  }

  abrirFormEnviarEmail() {
    document.getElementById('formEnviarEmail').style.display = 'block';
  }

  cerrarFormEnviarEmail() {
    document.getElementById('formEnviarEmail').style.display = 'none';
  }

  async enviarFormEmail() {
    Swal.fire({
      title: 'Enviando Reporte',
      html: 'Por favor espere...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(undefined);
      },
    });
    this.cerrarFormEnviarEmail();

    await this.DescargarPDF(false);
    let emails = [];
    for (let i = 0; i < this.emailsControl.emails.length; i++) {
      const e = this.emailsControl.emails[i].email;

      emails.push(e);
    }
    this.reportesService
      .sendReport(
        this.idPlanificacion.toString(),
        this.pdfFile,
        emails,
        this.reportName
      ).subscribe(
        {next : ()=> {
          Swal.close();
          Swal.fire({ title: 'Reporte enviado correctamente', timer: 15000 });
        },
        error: error => {
          Swal.close();
          Swal.fire({ title: 'Error al enviar el reporte', timer: 15000 });
        }}
      );
  }

  agregarEmail() {
    this.emailsControl.emails.push({
      id: this.emailsControl.emails.length + 1,
      email: this.nuevoEmail,
    });
    this.nuevoEmail = '';
  }

  eliminar(index: number) {
    this.emailsControl.emails.splice(index, 1);
  }


  obtenerLogo(){
    const name = 'logo';
            const nomsep = name[0].split('_');
    this.reportesService.getLogo(this.companyId).subscribe(

      response =>{
        for(const doc of response){
          const name = (''+doc['filename']).split('.');
          const nomsep = name[0].split('_');
          for(const nam of nomsep){
            if( nam.toLocaleLowerCase() === 'logo' ){
              this.reportesService.getArchivosA(doc._id).subscribe(response =>{
                var base = 'data:image/'+name[name.length-1]+';base64,'+btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                this.imgLogo = base;
                const bufferArray = [{buffer: response, tipo: name[name.length-1]}];
              },err =>{
              });
              break;
            }
          }
        }

      }

    )
  }

  public perImpresion(){
    if(this.aniosDisponiblesCM.length !== 0){
      let avan = 0, contar = false;
      for(var i = this.aniosDisponiblesCM.length-1; i>=0; i--){
        for(var j = this.aniosDisponiblesCM[i].series.length-1; j>=0; j--){
          if(this.aniosDisponiblesCM[i].series[j].check){
            avan++;
            contar = true;
          }else if(contar){
            avan++;
          }
          if(avan === this.numPeridos){
            return this.aniosDisponiblesCM[i].series[j].periodo;
          }
        }
      }
      return this.aniosDisponiblesCM[0].series[0].periodo;
    }else{
      return '';
    }
  }
}

@Directive({
  selector: '[ngVar]',
})
export class VarDirective {
  @Input()
  set ngVar(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  context: any = {};

  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
