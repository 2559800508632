<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-caja-menu padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> Catálogos </span>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-row contenedorNotificaciones">
    <div class="w3-row notificacionesPos">
      <div class="w3-row  w3-titulo-contenidomenuSC">
        <span class="w3-margin-left-contenido w3-tituloZonas">Activación de notificaciones generales</span>
      </div>
      <div class="w3-row" style="margin-left: 1vw;">
        <ng-container *ngFor="let tem of templatesGenerales">
          <div class="contenedorTem textoOverflow">
            <mat-slide-toggle color="orange" [checked]="tem.check" (change)="cambioCheckBox(tem)">
              <span class="textoTem" matTooltip="{{tem.name}}">{{tem.name}}</span>
            </mat-slide-toggle>
          </div>
        </ng-container>
      </div>
      <div class="w3-row  w3-titulo-contenidomenuSC" *ngIf="siVeActas">
        <span class="w3-margin-left-contenido w3-tituloZonas">Activación de notificaciones actas</span>
      </div>
      <div class="w3-row" style="margin-left: 1vw;" *ngIf="siVeActas">
        <ng-container *ngFor="let tem of templatesActas">
          <div class="contenedorTem textoOverflow">
            <mat-slide-toggle color="orange" [checked]="tem.check" (change)="cambioCheckBox(tem)">
              <span class="textoTem" matTooltip="{{tem.name}}">{{tem.name}}</span>
            </mat-slide-toggle>
          </div>
        </ng-container>
      </div>
      <div class="w3-row">
        <ng-container *ngIf="realizoCambios">
          <div class="w3-right" style="margin-top: 1vh;">
            <div class="botonesGuardado">
              <button mat-flat-button color="success" (click)="guardarSeleccion()">
                Guardar
              </button>
            </div>
            <div class="botonesGuardado">
              <button mat-stroked-button color="success2" (click)="cancelarSeleccion()" type="reset">
                Cancelar
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="w3-rest iconoInfo" style="margin-right: 2vw;">
      <mat-icon class="w3-zindex w3-button w3-dropdown-click" (click)="modalInfo()" [ngStyle]="{'color':'gray'}">info_outline</mat-icon>
    </div>
  </div>
  <ng-container>
    <div class="contenedorCargaImagen">
      <div class="w3-row w3-white" style="margin-top: 1vh;">
        <div class="w3-rest w3-titulo-contenidomenu">
          <div class="titulo">
            <div class="w3-rest w3-titulo-contenidomenuSC padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">Logotipo</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-row informacionLogOut"> 
        <ng-container>
          <div class="containerImage">
            <br *ngIf="bufferArray.length !== 0">
            <img *ngIf="bufferArray.length !== 0" src="{{urll}}" width="100%" (click)="vizualizarImagen()" style="cursor: pointer;">
            <span *ngIf="bufferArray.length === 0" class="w3-margin-left-contenido w3-subtitulo">
              <br>
              <br>
              No se ha cargado ningún logotipo, envíe al administrador de la plataforma su logo con fondo trasparente para ser visualizado.
            </span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="contenedorTabla">
    <div class="w3-row">
      <div class="w3-row w3-white" style="margin-top: 1vh;">
        <div class="w3-half w3-titulo-contenidomenu">
          <div class="titulo">
            <div class="w3-rest w3-titulo-contenidomenuSC padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">Tablas de responsables</span>
              </div>
            </div>
          </div>
        </div>
        <!--Menú-->
        <div class="w3-rest w3-right-align_rest">
          <div class="menuexp posicionMenu">
            <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
              [matMenuTriggerFor]="menu2">more_vert</mat-icon>
          </div>
          <div class="botones">
            <div class="w3-rest" style="margin-top: 1vh; margin-right: 1vw;">
              <button (click)="abrirAgregarRes()" matTooltip="Agregar" class="colorBotonDeNuevo">
                <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                Agregar responsable
              </button>
            </div>
          </div>
          <mat-menu #menu2="matMenu">
            <button (click)="exportarExcel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
              <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar xlsx</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="w3-row tablaPos">
      <table class="tabla_cont allBordes" mat-table [dataSource]="responsables">

        <ng-container matColumnDef="posicion">
          <th class="w3-elemento-titulo-table textoOverflow borderInf" mat-header-cell *matHeaderCellDef> Puesto / Cargo </th>
          <td class="w3-table-trtdBalance anchosColumnasPuesto textoOverflow" *matCellDef="let element"> <div style="margin-left: 1vw;">{{element.name}}</div> </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th class="w3-elemento-titulo-table borderInf" mat-header-cell *matHeaderCellDef> Nombre </th>
          <td class="w3-table-trtdBalance textoOverflow anchosColumnas" *matCellDef="let element">{{obtResponsable(element.responsibles[0])}}</td>
        </ng-container>

        <ng-container matColumnDef="correo">
          <th class="w3-elemento-titulo-table borderInf" mat-header-cell *matHeaderCellDef> Correo electrónico </th>
          <td class="w3-table-trtdBalance textoOverflow anchosColumnas" *matCellDef="let element">{{obtCorreo(element.responsibles[0])}}</td>
        </ng-container>

        <ng-container matColumnDef="edicion">
          <th class="w3-elemento-titulo-table borderInf" mat-header-cell *matHeaderCellDef>  </th>
          <td class="w3-table-trtdBalance textoOverflow anchosColumnas" *matCellDef="let element"> 
            <mat-icon class="iconoMen" (click)="abrirEditarRes(element.responsibles[0], element.id)" matTooltipPosition="left" matTooltip="Editar">edit</mat-icon> 
            <mat-icon class="iconoMen" (click)="eliminarResponsable(element.responsibles[0], element)" matTooltipPosition="right" matTooltip="Eliminar">delete</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="validador" *ngIf="siVeActas">
          <th class="w3-elemento-titulo-table alinDer borderInf textoOverflow" mat-header-cell *matHeaderCellDef> Validador actas </th>
          <td class="w3-table-trtdBalance alinCen anchosColumnas" *matCellDef="let element"> 
            <mat-checkbox (change)="cambioValidator(element.responsibles[0])" [checked]="element.responsibles[0].isValidator">
            </mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers"></tr>
        <tr mat-row *matRowDef="let row; columns: headers;"></tr>
      </table>
    </div>
  </div>
  <ng-container *appAdministrdores="['Administrador']">
    <div class="contenedorLogOut">
      <div class="w3-row w3-white" style="margin-top: 1vh;">
        <div class="w3-rest w3-titulo-contenidomenu">
          <div class="titulo">
            <div class="w3-rest w3-titulo-contenidomenuSC padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">Expiración de la sesión</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w3-row informacionLogOut"> 
        <div class="w3-row" style="font-weight: 500; margin-bottom: 1vh;">
          Cierre de sesión automatico:
        </div>
        <mat-slide-toggle color="orange" [checked]="logOutAct" (change)="logOutAct=!logOutAct">
          <span *ngIf="!logOutAct" class="textoTem">Activar</span>
          <span *ngIf="logOutAct" class="textoTem">Desactivar</span>
        </mat-slide-toggle>
  
        <div class="w3-row" style="margin-top: 3vh;" [ngClass]="logOutAct?'':'w3-disabled'">
          <div class="w3-row" style="font-weight: 500; margin-bottom: 2vh;">
            Tiempo de inactividad:
          </div>
          <mat-slider
            class="example-margin"
            [max]="20"
            [min]="5"
            [step]="5"
            [(ngModel)]="logOutTime"
            [thumbLabel]="true"
            aria-labelledby="example-name-label">
          </mat-slider>
  
          <span style="font-weight: 500; margin-left: 1vw;">
            {{logOutTime}} min.
          </span>
        </div>
  
        <ng-container *ngIf="realizoCambiosLogOut()">
          <div class="w3-right" style="margin-top: 3vh; margin-bottom: 2vh;">
            <div class="botonesGuardado">
              <button mat-flat-button color="success" (click)="guardarCambiosLogOut()">
                Guardar
              </button>
            </div>
            <div class="botonesGuardado">
              <button mat-stroked-button color="success2" type="reset" (click)="cancelarCambiosLogOut()">
                Cancelar
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<div id="agregarResponsable" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido"> Nuevo responsable </span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarFormulario()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 50vh;">
      <div class="w3-container" style="height: 45vh; overflow: scroll;">
        
        <form [formGroup]="formNuevo" (ngSubmit)="alertasGuardado('nuevo')">

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Nombre: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el nombre </mat-label>
                <input type="text" matInput formControlName="nombre" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Apellidos: </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-half">
                <mat-form-field appearance="outline" style="width: 18.5vw;">
                  <mat-label> Ingrese el primer apellido </mat-label>
                  <input type="text" matInput formControlName="primerApellido" [value]="" required> 
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 18.4vw;">
                  <mat-label> Ingrese el segundo apellido </mat-label>
                  <input type="text" matInput formControlName="segundoApellido">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Correo: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el correo </mat-label>
                <input type="text" matInput formControlName="correo" required> 
                  <mat-error *ngIf="formNuevo.controls['correo'].hasError('email')">
                    Correo electrónico <strong> inválido.
                    </strong>
                  </mat-error>
                  <mat-error *ngIf="formNuevo.controls['correo'].hasError('required')">
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Puesto: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formNuevo.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el puesto </mat-label>
                <input type="text" matInput formControlName="puestoCargo" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-right">
            <div class="botonesModales" [ngClass]="puedeGuardarAgregar()">
              <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                Guardar
              </button>
            </div>
            <div class="botonesModales">
              <button mat-stroked-button color="success2" type="reset" (click)="cerrarFormulario()" style="height: 4vh;">
                Cancelar
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>

<div id="editarResponsable" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido"> Edición responsable </span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarFormulario()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 50vh;">
      <div class="w3-container" style="height: 45vh; overflow: scroll;">
        
        <form [formGroup]="formEditar" (ngSubmit)="alertasGuardado('editar')">

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Nombre: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el nombre </mat-label>
                <input type="text" matInput formControlName="nombre" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Apellidos: </span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-half">
                <mat-form-field appearance="outline" style="width: 18.5vw;">
                  <mat-label> Ingrese el primer apellido </mat-label>
                  <input type="text" matInput formControlName="primerApellido" [value]="" required> 
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 18.4vw;">
                  <mat-label> Ingrese el segundo apellido </mat-label>
                  <input type="text" matInput formControlName="segundoApellido">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Correo: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el correo </mat-label>
                <input type="text" matInput formControlName="correo" required> 
                  <mat-error *ngIf="formNuevo.controls['correo'].hasError('email')">
                    Correo electrónico <strong> inválido.
                    </strong>
                  </mat-error>
                  <mat-error *ngIf="formNuevo.controls['correo'].hasError('required')">
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Puesto: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formEditar.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 38.1vw;">
                <mat-label> Ingrese el puesto </mat-label>
                <input type="text" matInput formControlName="puestoCargo" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-right">
            <div class="botonesModales" [ngClass]="puedeGuardarEditar()">
              <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                Guardar
              </button>
            </div>
            <div class="botonesModales">
              <button mat-stroked-button color="success2" type="reset" (click)="cerrarFormulario()" style="height: 4vh;">
                Cancelar
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>