<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }}
          </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{Nombre}} </span>
      </div>
    </div>
    <div class="w3-rest">
    </div>
  </div>
</div>
<div id="rootdiv" style="z-index: 11;">

  <div id="cont_template1" class="w3-container w3-contenedor-template ">
    <div class="w3-row w3-contenedor-template " style="height: 85vh;">
      <div class="w3-row ">
        <div class="w3-container" style="height: 10vh;">
          <div class="w3-row ">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel expanded="true">
                <div style="display: flex; flex-direction: row; gap: 10px; padding-top: 20px; flex-wrap: wrap;">
                  <div class="container-menu">
                    <div class="tituloFiltros">Parámetros de fecha</div>
                    <table class="w3-table">
                      <tbody>
                        <tr>
                          <td>
                            <button class="w3-button" [matMenuTriggerFor]="menuAnios"
                              style=" display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;">
                              <span style="color: #808080;">
                                Fecha de corte
                              </span>
                              <mat-icon class="iconoMen" style="color: #808080;">expand_more</mat-icon>
                            </button>
                            <mat-menu #menuAnios="matMenu">
                              <ng-container *ngFor="let an of periodos">
                                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                                  <span>{{an.anio}}</span>
                                </button>
                                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                                  <span *ngFor="let child of an.series">
                                    <span *ngIf="!child.children || child.children.length === 0">
                                      <button mat-menu-item
                                        (click)="$event.stopPropagation(); anioChange(child.periodo, an);">
                                        <div class="w3-quarter" style="cursor: pointer;">
                                          <input [id]="'check_'+child.anio+child.mes"
                                            style="cursor: pointer; margin-top: 7px;"
                                            (change)="anioChange(child.periodo, an);" [checked]="child.check" type="checkbox"
                                            (click)="$event.stopPropagation();">
                                        </div>
                                        <div>
                                          <span style="margin-left: 5px;">{{child.mes}}</span>
                                        </div>
                                      </button>
                                    </span>
                                  </span>
                                </mat-menu>
                              </ng-container>
                            </mat-menu>
                          </td>
                          <td style="text-align: right;">
                            <div class=" " id="contenedor_btnanios">
                              <div class="chips" id="Cchip">
                                <div class="auto_ajuste" style="display: flex; justify-content: center;">
                                  <ng-container *ngFor="let anio of periodos; let i = index">
                                    <ng-container *ngFor="let mes of anio.series">
                                      <div [ngClass]="mes.check?'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                                        class="filtroMesAnioSeleccionado">
                                        <span style="margin-left: 5px; margin-right: 5px;">{{mes.periodo}}</span>
                                      </div>
                                      <br *ngIf="mes.check">
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="tituloFiltros" style="height: 10%;"></div>
                    <div class="tituloFiltros">Parámetros opcionales</div>
                    <table class="w3-table">
                      <tr>
                        <td>
                          <button class="w3-button">
                            <span style="color: #808080;">
                              Activación de periodos
                            </span>
                          </button>
                        </td>
                        <td>
                          <mat-slide-toggle [(ngModel)]="porPeriodo" labelPosition="after" (change)="cambioPorPeriodo();"></mat-slide-toggle>
                        </td>
                      </tr>
                    </table>
                    <div class="tituloFiltros" style="height: 5%;"></div>
                    <table class="w3-table">
                      <tr *ngIf="porPeriodo">
                        <td>
                          <button class="w3-button" style=" display: flex; flex-wrap: nowrap; justify-content: center; align-items: center;">
                            <span style="color: #808080;">
                              Número periodos
                            </span>
                          </button>
                        </td>
                        <td style="text-align: right;">
                          <input id="numPer" class="inputTable" (change)="cambioPeriodos();" (keyup)="cambioPeriodos();" type="number" max="12" min="1" value="{{numPeridos}}">
                        </td>
                      </tr>
                    </table>
                    <div class="tituloFiltros" style="height: 5%;"></div>
                    <table class="w3-table">
                      <tr *ngIf="porPeriodo">
                        <td>
                          <div class="chips" id="Cchip">
                            <div class="auto_ajuste" style="display: flex; justify-content: center;">
                              <div class="w3-round-btn-large grisBtn w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                                <span style="margin-left: 5px; margin-right: 5px;">{{perImpresion()}}</span>
                              </div>
                              <span style="margin-left: 5%; margin-right: 3%;"> <strong> &#8212; </strong></span>
                              <div class="w3-round-btn-large grisBtn w3-center w3-elemento-menu filtroMesAnioSeleccionado">
                                <span style="margin-left: 5px; margin-right: 5px;">{{getPeriod()}}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>

                  </div>
                  <div class="container-menu">
                    <div class="tituloFiltros">Elementos para imprimir</div>
                    <div>
                      <div class="togElement">
                        <!-- <label for="st_introduccion"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_introduccion" [(ngModel)]="imprimirIntro">
                          Introducción
                        </mat-slide-toggle>
                      </div>

                      <div class="togElement" *ngIf="verFodaV1">
                        <!-- <label for="st_foda"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_foda" [(ngModel)]="imprimirFoda">
                          FODA
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement" *ngIf="verFodaV2">
                        <!-- <label for="st_foda"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_fodav2" [(ngModel)]="imprimirFodaV2">
                          PESTEL
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_objetivos"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_objetivos" [(ngModel)]="imprimirObjetivos">
                          Objetivos
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_indicadores"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_indicadores" [(ngModel)]="imprimirIndicadores">
                          Indicadores
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_planOperativo"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_planOperativo"
                          [(ngModel)]="imprimirPlanOperativo">
                          Proyectos
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_planOperativo"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_planOperativoActividades"
                          [(ngModel)]="imprimirPlanOperativoActividades">
                          Actividades
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_cmi"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_cmi" [(ngModel)]="imprimirCmi">
                          CMI
                        </mat-slide-toggle>
                      </div>
                      <div class="togElement">
                        <!-- <label for="st_graficas"></label> -->
                        <mat-slide-toggle labelPosition="before" id="st_graficas" [(ngModel)]="imprimirGraficas">
                          Gráficas indicadores
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div class="container-menu">
                    <div class="tituloFiltros">Perspectivas</div>
                    <div class="togElement">
                      <mat-slide-toggle labelPosition="before" [(ngModel)]="todasPerIM"
                        (ngModelChange)="selecAllPerIM(todasPerIM)">Todas</mat-slide-toggle>
                    </div>
                    <div *ngIf="!todasPerIM">
                      <div *ngFor="let per of perspectivas; let i = index">
                        <div class="togElement">
                          <mat-slide-toggle labelPosition="before" [id]="'per'+per.name" [(ngModel)]="per.check"
                            (ngModelChange)="cambioPerIM(per)">{{per.name}}</mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container-menu">
                    <div class="tituloFiltros">Objetivo</div>
                    <div>
                      <div class="togElement" [ngClass]="{ 'w3-disabled': persSeleccionadas.length !== 1 }">
                        <mat-slide-toggle labelPosition="before" id="todosObj" [(ngModel)]="todosObjetivos"
                          (ngModelChange)="selectAllObjetivos(todosObjetivos)">
                          Seleccionar todo
                        </mat-slide-toggle>
                      </div>
                      <div *ngIf="!todosObjetivos">

                        <div *ngFor="let obj of objectivos">
                          <div class="togElement"
                            *ngIf="persSeleccionadas.length === 1 && persSeleccionadas[0].id === obj.idPer">
                            <mat-slide-toggle labelPosition="before" [id]="obj.nombre" [(ngModel)]="obj.check"
                              (ngModelChange)="cambioObj(obj)">
                              {{obj.nombre}}
                            </mat-slide-toggle>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="container-menu">
                    <div class="tituloFiltros">Líder</div>
                    <div class="togElement">
                      <mat-slide-toggle labelPosition="before" id="todosLideres" [(ngModel)]="todosLideresSeleccionados"
                        (ngModelChange)="selecciondarTodosLideres(todosLideresSeleccionados)">Seleccionar
                        todo</mat-slide-toggle>
                    </div>
                    <div *ngIf="!todosLideresSeleccionados">
                      <div *ngFor="let res of lideres">
                        <div class="togElement">
                          <mat-slide-toggle labelPosition="before" [id]="res.name" [(ngModel)]="res.check"
                            (ngModelChange)="cambioLiderIM(res)">{{res.name}}</mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-action-row>
                  <div style="float: right; margin-bottom: 50px;">
                    <ng-template [ngIf]="isDownloadingPdf"><span
                        style="padding-top: 15px; font-size: .6rem; color: #808080;">Descargando...</span></ng-template>
                    <ng-template [ngIf]="isDownloadingData || isLoadingObtenerDatos"><span
                        style="padding-top: 15px; font-size: .6rem; color: #808080;">Cargando
                        información...</span></ng-template>
                    <ng-template [ngIf]="!isDownloadingPdf && !isDownloadingData && !isLoadingObtenerDatos">
                      <button
                        style="display: flex; flex-wrap: wrap; height: 50px; width: 100px; align-items: center; justify-content: center; background-color: transparent; border: none; cursor: pointer;"
                        (click)="generarReporte()">
                        <span style="font-size: .6rem; color: #080707;">Descargar reporte</span>
                        <mat-icon
                          style="font-size: 3rem; color: #ED7D31; width: 3rem; height: 3rem;">cloud_download</mat-icon>
                      </button>
                    </ng-template>

                  </div>
                  <ng-template [ngIf]="!isDownloadingPdf && !isDownloadingData && !isLoadingObtenerDatos">
                    <div style="float: right; margin-bottom: 50px;">
                      <button style="display: flex; flex-wrap: wrap; height: 50px; width: 100px; background-color: transparent; border: none; cursor: pointer; justify-content: center;" (click)="abrirFormEnviarEmail()">
                        <span style="font-size: .6rem; color: #080707;">Enviar por email</span>
                        <mat-icon style="font-size: 3rem; color: #ED7D31; width: 3rem; height: 3rem;">email</mat-icon>
                      </button>
                    </div>
                  </ng-template>
                </mat-action-row>
                <mat-action-row>
                </mat-action-row>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="gr_rd" style="position: absolute; left: -1000px; bottom:0;">
  <div class="w3-row" id="cont_graf" style="opacity: 0.0;">
    <ng-container *ngFor="let graf of graficas_lineales; let i = index;">
      <ng-container *ngIf=" graf.series.length != 0 && graf.check">
        <div style="width: 500px;" [id]="'graf'+graf.indicadorId" class="w3-row contGraf">
          <div style="font-size: 1rem; margin-bottom: 20px;">{{graf.name}}</div>
          <app-grafica-lineal [DatosGraf]="graf.series"></app-grafica-lineal>
        </div>
        <div class="w3-row" style="height: 4vh;"></div>
      </ng-container>
    </ng-container>
  </div>

  <div id="floa_img_cont" style="display: none;">
    <img id="floa_img" #floa_img style="width: 500px;" src="assets/img/reportes/FODA.png" alt="">
  </div>
</div>

<div id="formEnviarEmail" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw; ">
    <form (ngSubmit)="enviarFormEmail()" autocomplete="off" #miFormulario="ngForm" class="row justify-content-center" style="padding: 10%;">
      <label class="fs-3 mb-5">Enviar reporte por email</label>
      <!-- agregar favorito -->
      <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <div class="input-group">
              <input type="text" class="form-control"
                placeholder="Agregar Email"
                [(ngModel)]="nuevoEmail"
                (keyup.enter)="agregarEmail()"
                name="nuevoJuego">
              <button class="btn" type="button" (click)="agregarEmail()" style="background-color: #4E9AC7; color: white;">
                Agregar
              </button>
            </div>
          </div>
      </div>

      <!-- Lista de juegos favoritos -->
      <div class="mb-3 row">
        <label class="col-sm-3 col-form-label">Listado de direcciones</label>
        <div class="col-sm-9">
          <div class="input-group mb-1" *ngFor="let email of emailsControl.emails; let i = index">
            <input  [(ngModel)]="email.email"
              name="email_{{ i}}" class="form-control" required>
              <button class="btn btn-outline-danger" type="button" (click)="eliminar(i)">
                Eliminar
              </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="button" class="btn float-end me-md-1" [disabled]="miFormulario.invalid"
          (click)="enviarFormEmail()" style="background-color: #ED7D31; color: white;">Enviar</button>
          <button type="button" class="btn float-end"
            (click)="cerrarFormEnviarEmail()" style="background-color: #B9B9BC; color: white;">Cancelar</button>
        </div>
      </div>
  </form>
    <div class="w3-rest">
      <mat-icon (click)="cerrarFormEnviarEmail();" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
    </div>
  </div>
</div>
