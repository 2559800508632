import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Perspectivas, Planificacion, Instituciones, procesamiento, obtenerError } from './../../../model/datos';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PerspectivasService } from '../../../services/formulacion/perspectivas/perspectivas.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
@Component({
  selector: 'app-perspectivas',
  templateUrl: './perspectivas.component.html',
  styleUrls: ['./perspectivas.component.css']
})
export class PerspectivasComponent implements OnInit {

  public instituciones: Instituciones[] = [];
  public planeacion: Planificacion[] = [];
  public perspectiva: Perspectivas[] = [];

  public idper: number = 0;
  public idPlanificacion: any;
  public objpers:any = {nombre:'', descripcion:''};
  public plani: any;
  public perpectivaa: any; //para el edit
  public modal = false;
  public modal2 = false;

  /** Grupo de validación de datos */
  formEdit = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputDescription: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  formNuevo = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputDescription: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  get fn() { return this.formNuevo.controls; }
   
  onSubmit(){
    this.onReset();
  }
  onReset(){
    this.formNuevo.reset('');
  }

  constructor(
    public dataService: DataService,
    private perspectivasService: PerspectivasService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) { 
    this.obtData();
    var param = this.rutaActiva.snapshot.params;
    this.idPlanificacion = Number(param["planeacion"]);
  }
  
  ngOnInit(): void { }

  private ordenarPerspe(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /** Obtención de los datos */
  public obtData(){
    this.perspectivasService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive
          });
        }
      }
      this.perspectivasService.getPerspectivas().subscribe(response => {
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              nombre: per.name,
              descripcion: per.description,
              planningId: per.planning.id,
              id: per.id
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
      });
    });
  }
  /** Metodos de decición de guardado */
  public alertas(aler: any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEdit();
        }
      });
    }else if(aler === 'guardar'){
      if(!this.nombreExiste()){
        Swal.fire({
          title: '¿La información es correcta?',
          text: "",
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.almacenarDatos();
          }
        });
      }else{
        Swal.fire({
          title: 'El nombre ya existe',
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      }
    }
  }
  /** Control del boton guardar para campos obligatorios */
  public puede_guardar(){
    var perspectiv = new Perspectivas();
    perspectiv.nombre = document.getElementById('nombre')["value"];
    if(perspectiv.nombre !== '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    var perspectiv = new Perspectivas();
    perspectiv.nombre = document.getElementById('nombreedit')["value"];
    if(perspectiv.nombre !== '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Almacenado de datos y recarga de estos */
  public almacenarDatos(){
    var perspectiv = new Perspectivas();
    perspectiv.nombre = document.getElementById('nombre')["value"];
    perspectiv.descripcion = document.getElementById('descripcion')["value"];
    perspectiv.planningId = this.idPlanificacion;

    this.perspectivasService.postPerspectivas(perspectiv).subscribe(response => {
      this.perspectivasService.getPerspectivas().subscribe(response => {
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              id: per.id,
              nombre: per.name,
              descripcion: per.description,
              planningId: per.planning.id,
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      })
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.cerrarForm();
  }
  public almacenarDatosEdit(){
    this.perpectivaa.nombre = document.getElementById('nombreedit')["value"];
    this.perpectivaa.descripcion = document.getElementById('descripcionedit')["value"];
    this.perspectivasService.patchPerspectivas(this.perpectivaa).subscribe(response =>{
      this.perspectivasService.getPerspectivas().subscribe(response => {
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              id: per.id,
              nombre: per.name,
              descripcion: per.description,
              planningId: per.planning.id,
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    document.getElementById('nombreedit')["value"] = '';
    document.getElementById('descripcionedit')["value"] = '';
    document.getElementById('formulario')!.style.display = 'none';
  }
  /** Apertura de los modales */
  public abrirFormAgregar(perspe:any){
    this.idper = perspe.id;
    this.modal = true;
    document.getElementById('formularionuevo')!.style.display = 'block';
  }
  public abrirForm(perspe: any){
    this.perpectivaa = perspe;
    this.idper = perspe.id;
    this.modal2 = true;
    this.formEdit.controls['inputName'].setValue(this.perpectivaa.nombre);
    this.formEdit.controls['inputDescription'].setValue(this.perpectivaa.descripcion);
    document.getElementById('formulario')!.style.display = 'block'
  }
  /** Cerrar modales */
  public cerrarForm(){
    document.getElementById('formularionuevo')!.style.display = 'none';
    document.getElementById('formulario')!.style.display='none';
    this.modal = false;
    this.modal2 = false;

    this.formNuevo.reset();
    this.formEdit.reset();
  }
  /** Exportación de archivos */
  public exportar_excel(){
    const title = 'Formulación: Perspectivas';
    const header = ["Nombre", "descripción"]
    let rows = [];
    let cols = [];
    for(var i = 0; i < this.perspectiva.length; i++){
      cols.push(this.perspectiva[i].nombre);
      cols.push(this.perspectiva[i].descripcion);
      rows.push(cols);
      cols = [];
    }
    this.excelService.generateExcelPerspectivas(title,header, rows, 'Perspectivas');
    
  }
  /** Metodo de eliminación */
  public eliminar(perspe: any){
    this.perspectivasService.deletePer(perspe).subscribe(response =>{
      this.perspectivasService.getPerspectivas().subscribe(response => {
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              nombre: per.name,
              descripcion: per.description,
              planningId: per.planning.id,
              id: per.id
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        Swal.fire({
          title: 'Información eliminada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    })
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn{
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nombre')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nombreedit')["value"];
      }
      const existe = this.perspectiva.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.perpectivaa.id );
        }
        return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formEdit.hasError('isValid');
  }
}

