import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Meses, obtenerError, obtenerTamMax } from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProyectosestrategicosService } from './../../../services/seguimiento/proyectosestrategicos/proyectosestrategicos.service';
import { ProgramasService } from '../../../services/seguimiento/programas/programas.service';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-programas',
  templateUrl: './programas.component.html',
  styleUrls: ['./programas.component.css']
})
export class ProgramasComponent implements OnInit {

  private idPlanificacion: number = 0;
  // Graficas
  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance: any = {
    domain: ['#FF495C', '#FF8300','#FFD100','#0075C9', '#72D54A']
  };
  
  public treemap = [{name: 'rojo', value: 5}];

  labelFormatting(c: any) {
    return `<h5>${(c.label)} </h5>`;
  }
  valueFormatting(c: any){
    return `${(c)}`;
  }

  // Filtros
  public todasPer = true;

  //Variables de datos
  public perspectives: any = [];
  public aniosDisponibles: any = [];
  private semaforo: any = [];
  private objectives: any = [];
  private programas: any = [];
  public programasMostrar: any = [];
  public actividades: any = [];
  private diasLab: any;
  private fechaAct = new Date();
  private edicion: boolean;
  public Periodo = '';

  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = [];
  message = '';
  imageName = "";

  /** Lectura de archivos */
  selectedFiles: FileList;
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  /** click izquierdo */

  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition =  {x: '0', y: '0'};
  private adDelete : any;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private programaService: ProgramasService,
    private excelService: ExcelService,
    private translateServise: TranslateService,
    private proyectosestrategicosService: ProyectosestrategicosService
  ) {
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param['planeacion']);
    this.obtenerDatos();
  }
  ngOnInit(): void {  }
  // Obtención de datos
  private obtenerDatos(){
    this.programaService.getPlaneacion().subscribe(response => {
      this.obtenerFiltros(response);
      this.programaService.getObjectives().subscribe(response =>{
        this.obtObjectives(response);
        this.programaService.getProgramas().subscribe(response =>{
          this.obtProgramas(response);
        });
      });
    });
  }
  private obtenerFiltros(planeacion: any){
    for(const plan of planeacion){
      if(Number(plan.id) === this.idPlanificacion){
        this.edicion = plan.isActive;
        this.diasLab = plan.business_days;
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        this.Periodo = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1].periodo;
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            check: false,
            id: per.id,
          })
        }
        this.perspectives = this.ordenamientoId(this.perspectives);
        const sema = this.ordenamientoSem(plan.signals);
        if( Number(sema[sema.length-1].value_01) > 100){
          const valFinal = Number(sema[sema.length-1].value_01); 
          for(const sem of sema){
            this.semaforo.push({
              valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
              valfin: Math.round((Number(sem.value_01)*100)/valFinal),
              color: sem.color[0],
            });
          }      
        }else{
          for(const sem of sema){
            this.semaforo.push({
              valIn: Number(sem.value_00),
              valfin: Number(sem.value_01),
              color: sem.color[0],
            });
          }
        }
      }
    }
  }
  private obtObjectives(array: any){
    var aux = [];
    for(const obj of array){
      for(const per of this.perspectives){
        if(obj.perspective.id === per.id){
          aux.push(obj);
        }
      }
    }
    this.objectives = [];
    this.objectives = this.ordenamientoId(aux);
  }
  private obtProgramas(array: any){
    var aux: any, fechaini: any = undefined, fechafin: any = undefined;
    var auxA = [], aux2 = [];
    array = this.ordenamientoId(array);
    this.programas = [];
    array = this.ordenamiento_estrategias(array);
    for(const prog of array){
      for(const obj of this.objectives){
        if(prog.objectives[0].id === obj.id){
          var proyec: any = [];
          for(const pro of prog.projects){
            for(const act of pro.activities){
              var hist = [];
              for(const his of act.history){
                hist.push({
                  periodo: his.period,
                  planeado: Number(his.planned),
                  ejecutado: his.executed,
                  id: his.id,
                });
              }

              var adAux = [];
              for(const ad of act.additions){
                var fil = [];  
                if(ad.files && ad.files.length !== 0){
                  for(const f of ad.files){
                    const date = this.obtFech(f.created_at);
                    fil.push({
                      id: f.id,
                      name: f.name,
                      description: f.description,
                      path: f.path,
                      created_at: date,
                      updated_at: f.updated_at,
                    });
                  }
                }
                adAux.push({
                  amount: ad.amount,
                  comments: ad.comments,
                  id: ad.id,
                  name: ad.name,
                  note: ad.note,
                  type: ad.type,
                  files: fil,
                });
              }
              auxA.push({
                name: act.name,
                additions: this.ordenamientoAd(adAux),
                id: act.id,
                responsible: act.responsible,
                end_date: act.end_date,
                initial_date: act.initial_date,
                historico: hist,
              });
            }
            for(const act of auxA){
              if(act.historico.length === 0){
                act.historico = Meses.obtenerPlaneado(new Date(act.initial_date), new Date(act.end_date), this.diasLab);
                for(const hist of act.historico){
                  this.programaService.postHistorico(hist, Number(act.id)).subscribe(response =>{
                    hist.id = response.id;
                  },err =>{
                    let er = obtenerError.errorObt(err['error'].message);
                    Swal.fire({
                      title: er,
                      text: '',
                      icon: 'error',
                      showConfirmButton: false,
                      timer: this.dataService.tiempo,
                    });
                  });
                }
              }
            }
            const fechas = this.obt_fechas(pro);
            if(fechaini === undefined){
              fechaini = fechas[0];
            }else if(fechaini > fechas[0]){
              fechaini = fechas[0];
            }

            if(fechafin === undefined){
              fechafin = fechas[1];
            }else if(fechafin < fechas[1]){
              fechafin = fechas[1];
            }
            if(auxA.length != 0){
              proyec.push({
                name: pro.name,
                id: pro.id,
                weighing: pro.weighing,
                historicWeighing: pro.historicWeighing, 
                description: pro.description,
                activities: this.ordenamientoId(auxA),
                responsable: pro.responsible,
                check: false,
              });
            }
            auxA = [];
          }
          
          aux = {
            id: prog.id,
            name: prog.name,
            description: prog.description,
            projects: this.ordenamientoId(proyec),
            objectives: this.ordenamientoId(prog.objectives)
          };
          proyec = [];
        }
        if(fechafin != undefined){
          this.programas.push({programa: aux, avance: '', atraso: 0, fechaini: fechaini, fechafin: fechafin});
        }
        aux = [];
        fechaini = undefined;
        fechafin = undefined;
      }      
    }
    this.programasMostrar = [];
    for(const pro of this.programas){
      if(this.Mostrar(pro)){
        this.programasMostrar.push(pro);
      }
    }
  }
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  private obt_fechas(proy: any){
    var fechaIni = undefined; 
    var fechaFin = undefined;
    for(const act of proy.activities){
      if(fechaFin === undefined){
        fechaFin = act.end_date;
      }else if(fechaFin < act.end_date){
        fechaFin = act.end_date;
      }

      if(fechaIni === undefined){
        fechaIni = act.initial_date;
      }else if(fechaIni > act.initial_date){
        fechaIni = act.initial_date;
      }
    }
    return [fechaIni, fechaFin];  
  }
  // escritura de datos
  public escribirObj(per: any){
    var objetivos = '';
    for(const objPro of per['objectives']){
      if(objetivos === ''){
        objetivos += objPro.name;
      }else{
        objetivos += ', '+objPro.name;
      }
    }
    return objetivos;
  }
  public escribirPer(prog: any, i: any){
    var per = '';
    for(const objPro of prog['objectives']){
      for(const obj of this.objectives){
        if(objPro.id === obj.id){
          per =  obj.perspective.name;
        }
      }
    }

    if(i != 0){
      var per2 = '';
      for(const objPro of this.programasMostrar[i-1].programa.objectives){
        for(const obj of this.objectives){
          if(objPro.id === obj.id){
            per2 =  obj.perspective.name;
          }
        }
      }
      if(per != per2){
        return per;
      }
    }else{
      return per;
    }

    return '';
  }
  private Mostrar(prog: any){
    var existePer = false;
    if(this.todasPer){
      existePer = true;
    }else{
      for(const objPro of prog.programa.objectives){
        for(const obj of this.objectives){
          if(objPro.id === obj.id){
            for(const per of this.perspectives){
              if(per.check && per.id === obj.perspective.id){
                existePer = true;
              }
            }
            if(existePer){
              break
            }
          }
        }
        if(existePer){
          break
        }
      }
    }
    /** periodos */
    var existePeriodo = false;
    var  per_sel = '';

    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          per_sel = mes.periodo;
        }
      }
    }

    if(prog.fechaini != undefined && prog.fechafin != undefined){
      var per1 = Meses.getMesesN(new Date(prog.fechaini).getMonth()+1) + '-' + (''+new Date(prog.fechaini).getFullYear()).substring(2,4);
      var per2 = Meses.getMesesN(new Date(prog.fechafin).getMonth()+1) + '-' + (''+new Date(prog.fechafin).getFullYear()).substring(2,4);
      existePeriodo = Meses.compararPer(per1, per2, per_sel);
    }

    if(existePer && existePeriodo)
      return true;
    return false;
  }
  public escribirDat(pro: any, tip: string){  
    var per = this.perSelected();
    for(const dat of pro){
      if(per === dat.periodo){
        if(tip === 'plan'){
          return Number(dat.planeado);
        }else if(tip === 'ejec'){
          return dat.ejecutado;
        }
      }
    }
    return 0;
  }
  public escribirDatPro(pro: any, tip: string){
    var per = this.perSelected();
    var dias = 0, porcentajeplan = 0, porcentajeejec = 0;
    for(const act of pro.activities){
      dias += this.obtenerDias(act.initial_date,act.end_date);
    }
    var suma = false;
    for(const act of pro.activities){
      if(new Date( 20+per.split('-')[1], Meses.getMesesS(per.split('-')[0])-1 ) > new Date(act.end_date)){
        suma = true;
      }
      for(const his of act.historico){
        if(his.periodo === per || suma){
          if(suma){
            suma = false;
            var diasAct = this.obtenerDias(act.initial_date,act.end_date);
            var pesoAct = Number((diasAct*100/dias).toFixed(2));
            porcentajeplan += Number((100*pesoAct/100).toFixed(2));
            let perAct = Meses.obtnerPer( new Date(act.end_date) )
            var ejeAct = 0;
            const hist = this.ordenamiendoHistorico(act.historico); 
            for(const h of hist){
              if(h.ejecutado !== null){
                ejeAct = h.ejecutado;
                break;
              }
            }
            porcentajeejec += Number((ejeAct*pesoAct/100).toFixed(2));
            break;
          }else{
            var diasAct = this.obtenerDias(act.initial_date,act.end_date);
            var pesoAct = Number((diasAct*100/dias).toFixed(2));
            porcentajeplan += Number((his.planeado*pesoAct/100).toFixed(2));
            porcentajeejec += Number((his.ejecutado*pesoAct/100).toFixed(2));
          }
        }
      }
    }
    if(tip === 'plan'){
      if( Number(porcentajeplan.toFixed(2)) >= 99.99 ){
        return 100;
      }
      return Number(porcentajeplan.toFixed(2));
    }
    if( Number(porcentajeejec.toFixed(2)) >= 99.99 ){
      return 100;
    }
    return Number(porcentajeejec.toFixed(2));
  }
  public obtenerDias(fIni: any, fFin: any){
    var dias = 0, f1: any = new Date(fIni), f2: any = new Date(fFin);
    var diff = Math.round((f2-f1)/(1000*60*60*24));
    switch(this.diasLab){
      case 'Lunes-Viernes':
        var sem = Math.trunc(diff/7);
        dias = diff - sem*2;
      break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(diff/7);
        dias = diff - sem;
      break;
      case 'Lunes-Domingo':
        dias = diff;
      break;
    }
    return dias;
  }
  public escribirAdicionalPro(pro: any, i: number){
    var presTot = 0;
    var presTotN = 0;
    for(const act of pro){
      for(const ad of act.additions){
        if(ad.type === 'moneda'){
          presTot += Number(ad.amount);
        }
      }
    }
    if(this.actividades[0].activities[0].additions[i].type === 'moneda'){
      return this.formatearnormal(presTot);
    }else {
      return '';
    }
  }
  public pintarBord(eje: any, plan: any){
    var porcentaje = undefined;
    var color = '';
    if(eje === 0 && plan !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro';
    }else if(eje === 0 && plan !== 0){
      porcentaje = 0;
    }else if(eje !== 0 && eje !== null){
      porcentaje = eje*100/plan;
      
    }
    if(porcentaje !== undefined){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcentaje >= valfin){
        porcentaje = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcentaje >= ab && porcentaje <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintarBordPro(eje: any, plan: any){
    if(eje !== 0){
      var color = '';
      var por = eje*100/plan;
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(por >= valfin){
        por = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(por >= ab && por <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public avanceProg(prog: any, tipo: string){
    var pondProyect = 0, pesoProg = 0, aportProg = 0, pesoTot = 0;
    const per = this.anSelected()+'';
    for(const pro of prog.programa.projects){
      const avanPro = this.escribirDatPro(pro, 'ejec'), planPro = this.escribirDatPro(pro, 'plan');
      var peso = 0;
      if(pro.historicWeighing !== undefined && pro.historicWeighing.length !== 0){
        for(const pon of pro.historicWeighing){
          if(per === pon.period){
            peso += pon.weighing;
          } 
        }
      }
      const pesoPro = planPro*peso/100;
      pesoTot += peso;
      pesoProg += pesoPro;
      if(pesoPro !== 0 ){
        aportProg = avanPro*pesoPro/planPro;
      }
      else{
        aportProg = 0;
      }
      pondProyect += aportProg;
    }
    if(pesoTot !== 0){
      if(tipo === 'eje'){
        return Number((pondProyect*100/pesoTot).toFixed(2));
      }else{
        if( Number((pesoProg*100/pesoTot).toFixed(2)) >= 99.99 ){
          return 100;
        }
        return Number((pesoProg*100/pesoTot).toFixed(2));
      }
    }else{
      if(tipo === 'eje')
        return Number(pondProyect.toFixed(2));
      else
        return Number(pesoProg.toFixed(2));
    }
  }
  // Metodos de busqueda
  private perSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  private anSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return an.anio;
        }
      }
    }
    return '';
  }
  // filtros
  public anioChange(per: any){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === per? mes.check = true: mes.check = false;  
        mes.periodo === per? this.Periodo = mes.periodo: '';
      }
    }

    this.programasMostrar = [];
    for(const pro of this.programas){
      if(this.Mostrar(pro)){
        this.programasMostrar.push(pro);
      }
    }
  }
  public persChange(pers: any){
    this.todasPer = false;
    for(const per of this.perspectives){
      per.id === pers.id? per.check = !per.check: per.check = per.check;
    }
    var selec = 0;    for(const per of this.perspectives){
      if(per.check){
        selec++;
      }
    }
    if(selec === (this.perspectives.length)){
      this.todasPer = true;
    }

    this.programasMostrar = [];
    for(const pro of this.programas){
      if(this.Mostrar(pro)){
        this.programasMostrar.push(pro);
      }
    }
  }
  public selecAllPer(){
    for(const per of this.perspectives){
        this.todasPer? per.check = false:per.check = true;
    }
    this.todasPer = !this.todasPer;

    if(this.todasPer){
      this.programasMostrar = [];
      for(const pro of this.programas){
        if(this.Mostrar(pro)){
          this.programasMostrar.push(pro);
        }
      }
    }
  }
  // Metodos de ordenamiento
  private ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamiendoHistorico(hist: any){
    hist.sort(function (a: any, b: any) {
      if ( Number( a.periodo.split('-')[1]+Meses.getMesesNum(a.periodo.split('-')[0]) ) < Number( b.periodo.split('-')[1]+Meses.getMesesNum(b.periodo.split('-')[0]) )) {
        return 1;
      }
      if (Number( a.periodo.split('-')[1]+Meses.getMesesNum(a.periodo.split('-')[0]) ) > Number( b.periodo.split('-')[1]+Meses.getMesesNum(b.periodo.split('-')[0]) )) {
        return -1;
      }
      return 0;
    });
    return hist;
  }
  private ordenamientoObjId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objectives[0].id) > Number(b.objectives[0].id)) {
        return 1;
      }
      if (Number(a.objectives[0].id) < Number(b.objectives[0].id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoAd(array: any){
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamiento_estrategias(array: any){
    var aux = [];
    for(const per of this.perspectives){
      for(const obj of array){
        if( Number(per.id) === this.objectivo_per(obj.objectives) ){
          aux.push(obj);
        }
      }
    }
    return aux;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private objectivo_per(obj){
    for(const object of this.objectives){
      if( obj.length !== 0 && object.id === obj[0].id){
        return Number(object.perspective.id);
      }
    }
    return 0;
  }
  //Modal
  public openModal(pro: any){
    this.actividades = pro.programa.projects;
    document.getElementById('modalAct')!.style.display = 'block';
  }
  public closeModal(){
    document.getElementById('modalAct')!.style.display = 'none';
  }
  //Rutas
  public rutas(direc: string){
    if(direc === 'avanzar')
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'proyectosest/' +this.idPlanificacion);
    else
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'indicadoresMeta/' +this.idPlanificacion);
  }
  // Metodos de escritura Modal
  public escFech(fecha: any){
    var fechaEsc = '';
    if(fecha != undefined){
      var date = new Date(fecha);
      fechaEsc = date.getDate()+' '+Meses.getMesesN(date.getMonth()+1)+' '+(''+date.getFullYear()).substring(2,4)
    }
    return fechaEsc;
  }
  public escFechPro(proy: any, dato: any){
    var fecha_ini = undefined, fecha_fin = undefined;
    for(const act of proy.activities){
      var fechaini = new Date(act.initial_date), fechafin = new Date(act.end_date);
      if(fecha_fin === undefined){
        fecha_fin = fechafin;
      }else if(fecha_fin < fechafin){
        fecha_fin = fechafin;
      }
      if(fecha_ini === undefined){
        fecha_ini = fechaini;
      }else if(fecha_ini > fechaini){
        fecha_ini = fechaini;
      }
    }

    switch(dato){
      case 'fi': return this.escFech(fecha_ini);
      case 'ff': return this.escFech(fecha_fin);
      case 'di': return this.diasTot(fecha_ini, fecha_fin);
    }

    return '';
    
  }
  public diasTot(fechini: any, fechfin: any){
    var Ndias: any;
    var fini: any = new Date(fechfin), ffin: any = new Date(fechini);
    Ndias = fini - ffin;
    Ndias = Math.round(Ndias/(1000*60*60*24));

    switch(this.diasLab){
      case 'Lunes-Viernes':
        var sem = Math.trunc(Ndias/7);
        Ndias = Ndias - sem*2;
      break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(Ndias/7);
        Ndias = Ndias - sem;
      break;
      case 'Lunes-Domingo':
        Ndias = Ndias;
      break;
    }

    return Ndias;
  }
  public escribirAdicional(adic: any){
    if(adic != undefined){
      switch(adic.type){
        case 'texto':
          return this.obtComment(adic.comments);
        case 'moneda':
          return this.formatearnormal(adic.amount);
        case 'numero':
          return this.obtCommentNumero(adic.comments);
        case 'archivo':
          var per  = this.perSelected();
          for(const file of adic.files){
            if( per === Meses.obtnerPer(file.created_at)){
              return file.name.split('-')[file.name.split('-').length - 1];
            }
          }
      }
      return '';
    }
  }
  private obtCommentNumero(com : any){
    for(const comen of com){
      var per = 0;
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            per = an.anio;
          }
        }
      }
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
  private obtComment(com : any){
    for(const comen of com){
      var per =  this.perSelected();
      var percom = Meses.obtnerPer(new Date(comen.created_at));
      if(per === percom){
        return comen.comment;
      }
    }
    return '';
  }
  public async edicionAd(adic: any, id: any, event?: any){
    var inputValue : any, introdujo: any, guardo = false;

    switch(adic.type){
      case 'texto':
        inputValue =  this.obtComment(adic.comments);
        introdujo = await Swal.fire({
          title: 'Ingrese el comentario',
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'moneda':
        inputValue =  adic.amount;
        introdujo = await Swal.fire({
          title: 'Ingrese el monto',
          text: "",
          input: 'number',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'archivo':
        inputValue =  adic.file;
        introdujo = await Swal.fire({
          title: 'Seleccione su archivo',
          text: "",
          input: 'file',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }

    if(guardo){
      switch(adic.type){
        case 'texto':
          var existe = false;
          var per =  this.perSelected();
          for(const comen of adic.comments){
            var percom = Meses.obtnerPer(new Date(comen.created_at));
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.proyectosestrategicosService.patchComment(comen, adic.id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          if(!existe){
            var date = (new Date(Meses.getMesesS(per.split('-')[0])+'-1-'+per.split('-')[1]));
            adic.comments.push({
              comment: introdujo.value,
              created_at: date.toISOString(),
            });
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString()
            }
            this.proyectosestrategicosService.postComment(aux, adic.id).subscribe(response =>{
              this.programaService.getProgramas().subscribe(response =>{
                this.obtProgramas(response);
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  showConfirmButton: false,
                  icon: 'success',
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        break;
        case 'moneda':
          adic.amount = introdujo.value;
          this.proyectosestrategicosService.patchAdicionales(adic).subscribe(response =>{
            if(!response){
              Swal.fire({
                title: 'No se pudo actualizar la información',
                text: '',
                confirmButtonColor: '#ff6b00',
                confirmButtonText: 'Aceptar'
              });
            }else{
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            }
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        break;
        case 'archivo':
          this.progressInfo = [];
          introdujo.value ? this.imageName = introdujo.value.name : this.imageName = introdujo.value.length + " archivos";
          this.selectedFiles = introdujo.value;
          var per  = this.perSelected();
          var existe = false;
          if( this.selectedFiles["size"] > obtenerTamMax.getPeso() ){
            Swal.fire({
              title: obtenerTamMax.getTexto(),
              text: '',
              showConfirmButton: false,
              icon: 'error',
              timer: this.dataService.tiempo,
              didClose: () => {
                this.edicionAd(adic, id, event);
              }
            });
            
          }else{
            if(adic.files.length !== 0){
              for(const fil of adic.files){
                if( fil.periodo === per){
                  existe = true;
                }
              }
            }
            if(!existe){
              var per = this.perSelected();
              document.getElementById('cargando_arch')['style'].display = 'block';
              const fecha = new Date(Meses.getMesesS(per.split('-')[0])+'/1/'+per.split('-')[1]);
              this.proyectosestrategicosService.postFile(this.selectedFiles, adic.id, fecha.toISOString()).subscribe(response =>{
                this.programaService.getProgramas().subscribe(response =>{
                  this.obtProgramas(response);
                  document.getElementById('cargando_arch')['style'].display = 'none';
                  Swal.fire({
                    title: 'Información actualizada',
                    text: '',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: this.dataService.tiempo
                  });
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
        break;
      }
    }else{
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public abirPDF(ad, event?: any){
    const per = this.perSelected();
    for(const fil of ad.files){
      if(Meses.obtnerPer(fil.created_at) === per){
        this.proyectosestrategicosService.getFile(ad.id, fil.id).subscribe(response =>{
          switch(fil.name.split('.')[fil.name.split('.').length-1]){
            case 'pdf':
              const mediaType = 'application/pdf';
              const blob = new Blob([response], { type: mediaType });
              const nav = (window.navigator as any);

              if (nav.msSaveOrOpenBlob)  {
                nav.msSaveOrOpenBlob(blob, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blob);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            case 'png':
            case 'jpg':
              const mediaTypei = 'image/'+fil.name.split('.')[fil.name.split('.').length-1];
              const blobi = new Blob([response], { type: mediaTypei });
              const navi = (window.navigator as any);

              if (navi.msSaveOrOpenBlob)  {
                navi.msSaveOrOpenBlob(blobi, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobi);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            default:
              const mediaTyped = 'aplication/'+fil.name.split('.')[fil.name.split('.').length -1];
              const blobd = new Blob([response], { type: mediaTyped });
              const navd = (window.navigator as any);

              if (navd.msSaveOrOpenBlob)  {
                navd.msSaveOrOpenBlob(blobd, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobd);
                a.href = url;
                a.download = fil.name.split('-')[fil.name.split('-').length -1];
                document.body.appendChild(a);
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
          }
        });
      }
    }
  }
  public edicionEj(act: any, idAct: any){
    var perAct = Meses.obtnerPer(this.fechaAct);
    var perAnt = Meses.obtnerPerAnt(this.fechaAct);
    var perSel = '';
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSel = mes.periodo;
        }
      }
    }
    if(this.edicion){
      this.datEdit(act,perSel,idAct);
      return ;
    }else if(Meses.compararPer(perAnt,perAct,perSel)){
      this.datEdit(act,perSel,idAct)
    }else{
      Swal.fire({
        title: 'Periodo no editable',
        text: 'Solo se puede editar la información del mes actual y un mes anterior',
        confirmButtonColor: '#ff6b00',
        confirmButtonText: 'Aceptar'
      });
    }
  }
  private async datEdit(act: any, perSel: any, idAct: any){
    var inputValue : any, introdujo: any, guardo = false;

    for(const per of act){
      if(per.periodo === perSel){
        inputValue = per.ejecutado;
      }
    }
    
    introdujo = await Swal.fire({
      title: 'Ingrese el dato',
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (Number(value) > 100) {
          return 'el valor no puede ser mas grande que 100';
        }
        guardo = true;
        return '';
      }
    });

    if(guardo){
      for(const per of act){
        if(per.periodo === perSel){
          if(introdujo.value !== ''){
            per.ejecutado = Number(introdujo.value);
          }else{
            per.ejecutado = null;
          }
          this.programaService.PatchHistorico(per, Number(idAct),Number(per.id)).subscribe(response =>{
          },err => {
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        }
      }
    }
  }
  public escResp(res: any){
    if(res != null){
      var nom = ''+res.position;//''+res.name+' '+res.first_surname+' '+res.second_surname;
      return nom;
    }else{
      return '';
    }
  }
  public existAct(act: any){
    var existePeriodo = false;
    var  per_sel = '';

    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          per_sel = mes.periodo;
        }
      }
    }

    if(act.initial_date != undefined && act.end_date != undefined){
      var per1 = Meses.getMesesN(new Date(act.initial_date).getMonth()+1) + '-' + (''+new Date(act.initial_date).getFullYear()).substring(2,4);
      var per2 = Meses.getMesesN(new Date(act.end_date).getMonth()+1) + '-' + (''+new Date(act.end_date).getFullYear()).substring(2,4);
      existePeriodo = Meses.compararPer(per1, per2, per_sel);
    }

    return existePeriodo;
  }
  public existPro(pro: any){
    var existePeriodo = false;

    for(const act of pro.activities){
      if(this.existAct(act)){
        existePeriodo = true;
        break;
      }
    }

    return existePeriodo;
  }
  //Funcionamiento Modal
  public oculMost(pro: any){
    pro.check = !pro.check;
  }
  //eliminar archivos
  public deleteArchivo(){
    const per = this.perSelected();
    Swal.fire({
      title: '¿Seguro que deseas eliminar el archivo?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        for(const fil of this.adDelete.files){
          if(Meses.obtnerPer(fil.created_at) === per){
            this.proyectosestrategicosService.deleteFile(this.adDelete.id, fil.id).subscribe(response =>{
              this.programaService.getProgramas().subscribe(response =>{
                this.obtProgramas(response);
                Swal.fire({
                  title: 'Información eliminada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        }
      }
    });
  }  
  //Formatear datos
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  //Exportar excel
  public exportar_excel(){
    const title = 'Seguimiento: '+ this.translateServise.instant('estrategia') +' de '+this.Periodo;
    const header = ["Perspectiva", "Objetivos", "Programas", "Planeado", "Avance"]
    var data = [];
    var aux = [];
    for(var i = 0; i < this.programasMostrar.length; i++){
      aux.push( this.escribirPer(this.programasMostrar[i].programa, i) );
      aux.push( this.escribirObj(this.programasMostrar[i].programa) );
      aux.push( this.programasMostrar[i].programa.name );
      aux.push( this.avanceProg(this.programasMostrar[i], 'plan') );
      aux.push( this.avanceProg(this.programasMostrar[i], 'eje') );
      data.push(aux);
      aux = [];
    }
    const NomArch = this.translateServise.instant('toltip');
    this.excelService.generateExcelProgramas(title, header, data, NomArch, this.semaforo);
  }
  // Menu click izquierdo
  public onContextMenu(event: MouseEvent, item) {
    event.preventDefault(); 
    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px'; 
    this.menuTopLeftPosition.y = event.clientY + 'px'; 

    this.adDelete = item;

    this.matMenuTrigger.openMenu();
  }
}
