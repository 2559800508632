<!-- an hidden div is created to set the position of appearance of the menu--> 
<div id="menuIzq" style="position: fixed;" 
[style.left]="menuTopLeftPosition.x" 
[style.top]="menuTopLeftPosition.y" 
[matMenuTriggerFor]="rightMenu">
  <!-- standard material menu --> 
  <mat-menu #rightMenu="matMenu"> 
    <ng-template matMenuContent let-item="item"> 

      <button mat-menu-item (click)="deleteArchivo()">
        <mat-icon class="iconoMenVer" [ngStyle]="{'color':'grey'}">delete</mat-icon>
        Eliminar
      </button> 
    </ng-template> 
  </mat-menu> 
</div> 

<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ "menu."+dataService.currentModule | translate }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ "menu."+dataService.currentSubmodule | translate }} </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios"> <!--  -->
              {{ "filtros.periodo" | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); anioChange(child.periodo);">
                        <div style="cursor: pointer;">
                            <mat-checkbox [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                            (change)="anioChange(child.periodo);"
                            [checked]="child.check" type="checkbox"
                            (click)="$event.stopPropagation();"
                            aria-label="First checkbox">
                            {{ 'filtros.'+child.mes | translate}}
                            </mat-checkbox>
                        </div>
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" >
                        <span style="margin-left: 5px; margin-right: 5px;">{{ 'filtros.'+mes.mes | translate }}-{{mes.anio}}</span>
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- lider -->
        <div class="w3-third padreizquierda" style="margin-right: 1vh;">
          <div class="w3-third hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer">
              {{ "filtros.lider" | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="todasPer"
                (click)="$event.stopPropagation();"
                (change)="selecAllPer();"
                aria-label="First checkbox">
                {{ "filtros.selAll" | translate }}
                </mat-checkbox>        
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPer">
                <ng-container *ngFor="let per of responsables2.filteredData">
                  <button mat-menu-item (click)="$event.stopPropagation(); persChange(per);">
                    <mat-checkbox (change)="persChange(per)"
                    [checked]="per.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    aria-label="First checkbox">
                      <span matTooltip="{{per.name}}">
                        {{per.name}}
                      </span>
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                    <ng-container *ngFor="let res of responsables; let i = index">
                    <div [ngClass]="res.check? 'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)="persChange(res);">
                      <div class="nombres textoOverflow" style="margin-left: 5px; max-width: 11vw; overflow: hidden;">
                        <span matTooltip="{{res.name}}">
                          {{res.name}}
                        </span>
                      </div> 
                      <div class="tresPuntos"><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></div>
                    </div>
                    <br *ngIf="res.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="lidSelec()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuanios2="matMenu" xPosition="before">
                <ng-container *ngFor="let res of responsables; let i = index">
                  <div [ngClass]="res.check?
                    'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)=" $event.stopPropagation(); persChange(res)">
                    <span style="margin-left: 5px;">{{res.name}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- responsable -->
        <div class="w3-third padreizquierda" style="margin-right: 1vh;">
          <div class="w3-third hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPerAct">
              {{ "filtros.respon" | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPerAct="matMenu">
              <button mat-menu-item (click)="$event.stopPropagation();">
                <mat-checkbox [checked]="todasPerAct"
                (click)="$event.stopPropagation();"
                (change)=" selecAllPerAct();"
                aria-label="First checkbox">
                {{ "filtros.selAll" | translate }}
                </mat-checkbox>         
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPerAct">
                <ng-container *ngFor="let per of responsablesAct2.filteredData">
                  <button mat-menu-item (click)="$event.stopPropagation(); persChangeAct(per);">
                      <mat-checkbox (change)="persChangeAct(per)"
                      [checked]="per.check" type="checkbox"
                      (click)="$event.stopPropagation();"
                      aria-label="First checkbox">
                        <span matTooltip="{{per.name + ' '}}">
                          {{per.name + ' '}}
                        </span> 
                      </mat-checkbox>          
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                    <ng-container *ngFor="let res of responsablesAct; let i = index">
                    <div [ngClass]="res.check? 'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)="persChangeAct(res);">
                      <div class="nombres textoOverflow" style="margin-left: 5px; max-width: 11vw; overflow: hidden;">
                        <span matTooltip="{{res.name}}">
                          {{res.name}}
                        </span>
                      </div> 
                      <div class="tresPuntos"><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></div>
                    </div>
                    <br *ngIf="res.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="lidSelec()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuaniosAct2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuaniosAct2="matMenu" xPosition="before">
                <ng-container *ngFor="let res of responsablesAct; let i = index">
                  <div [ngClass]="res.check?
                    'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)=" $event.stopPropagation(); persChangeAct(res)">
                    <span style="margin-left: 5px;">{{res.name}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right">
          <div class="w3-row">
            <button mat-icon-button (click)="borrarFiltro()"> <!--(click)="quitarFiltros()"-->
              <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                <img src="assets/img/graficos/eraser.png">
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row selectores">
      <div class="w3-row selectores">
        <mat-form-field appearance="outline" style="width: 15vw; ">
          <mat-label> {{ "seguimiento.proyectos.html1" | translate }} </mat-label>
          <mat-select id="perspectiva" [(ngModel)]="perSelect" matTooltip="{{obtTooltip('perspectiva')}}">
            <ng-container *ngFor="let per of perspectives">
              <mat-option value="{{per.id}}" matTooltip="{{per.name}}" (click)="cambioPer(per)"> {{per.name}} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w3-row selectores">
        <div class="w3-quarter">
            <mat-form-field appearance="outline" style="width: 15vw;" [ngClass]="perSelect != undefined? '':'w3-disabled'">
              <mat-label> {{ "seguimiento.proyectos.html2" | translate }} </mat-label>
              <mat-select id="objetivo" [(ngModel)]="objSelect" matTooltip="{{obtTooltip('objetivo')}}">
                <ng-container *ngFor="let obj of objectives">
                  <mat-option (click)="cambioObj(obj)" matTooltip="{{obj.name}}" value="{{obj.id}}" *ngIf="perSelect === obj.perspective.id"> {{obj.name}} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="w3-rest">
            <mat-form-field appearance="outline" style="width: 15vw;"  [ngClass]="objSelect != undefined? '':'w3-disabled'">
              <mat-label> {{ "seguimiento.proyectos.html3" | translate }} {{ 'ProyectosForm.selec' | translate}} </mat-label>
              <mat-select id="estrategia" [(ngModel)]="proSelect" matTooltip="{{obtTooltip('estrategia')}}">
                <ng-container *ngFor="let pro of programas">
                  <mat-option (click)="cambioEst(pro)" value="{{pro.id}}" matTooltip="{{pro.name}}" *ngIf="perPrograma(pro)"> {{pro.name}} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
        </div>
      </div>
    </div>
    <div class="contTablaPro">
      <div class="tablaPro">
        <div class="w3-row">
          <div class="titulo">
            <div class="w3-noventa2 w3-titulo-contenidomenuSC">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">{{ "seguimiento.proyectos.html4" | translate }}</span>
                <span class="w3-margin-left-contenido w3-tituloZonas">&bull;</span>
                <span *ngIf="perSelect !== undefined" class="w3-margin-left-contenido w3-subtitulo">{{perspectiva}}</span>
                <span *ngIf="perSelect !== undefined" class="w3-margin-left-contenido w3-subtitulo">|</span>
                <span *ngIf="objSelect !== undefined" class="w3-margin-left-contenido w3-subtitulo">{{objetivo}}</span>
                <span *ngIf="objSelect !== undefined" class="w3-margin-left-contenido w3-subtitulo">|</span>
                <span *ngIf="programa !== ''" class="w3-margin-left-contenido w3-subtitulo">{{programa}}</span>
                <span *ngIf="programa !== ''" class="w3-margin-left-contenido w3-subtitulo">|</span>
                <span class="w3-margin-left-contenido w3-subtitulo">{{ 'filtros.'+mesMos | translate }}-{{ aniMos }}</span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-right">
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon>
                <mat-menu #menutabla="matMenu">
                    <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                      <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>{{ "seguimiento.proyectos.html5" | translate }}</span>
                    </button>
                    <button (click)="exportar_excelPer()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                      <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>{{ "seguimiento.proyectos.html6" | translate }}</span>
                    </button>
                    <button class="mat-menu-item">
                      <mat-checkbox style="cursor: pointer; margin-top: 7px;"
                        (change)="cambioDeFiltrado()"
                        [checked]="mostrarPeriodo" type="checkbox"
                        (click)="$event.stopPropagation();"
                        aria-label="First checkbox">
                        {{ "seguimiento.proyectos.html7" | translate }}
                      </mat-checkbox>
                    </button>
                    <!--<button (click)="exportarExcelNuevo()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                      <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                      <span>Exportar reporte completo xlsx</span>
                    </button>-->
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <div class="tabla">
          <div *ngIf="!mostrarTable" style="height: 56vh;">
            <section>
              <table class="w3-table">
                <tr>
                  <td>
                    <div class="info"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                    <div class="info" style="margin-left: 2vw !important;"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                  <td>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                    <div class="info"></div>
                  </td>
                </tr>
              </table>
            </section>
          </div>
          <table *ngIf="mostrarTable" class="w3-table borderInf borderSup borderIzq borderDer">
            <thead>
              <th class="w3-elemento-titulo-table FijaTit1 mantenerFijaTit alingIzq contenedorSort" style="cursor: pointer;">
                <table class="w3-table">
                  <th style="max-width: 1vw; padding: 0vh;">
                    <mat-icon *ngIf="!expandirProg" (click)="oculMostAll()" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                    <mat-icon *ngIf="expandirProg" (click)="oculMostAll()" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                  </th>
                  <th (click)="ordenamientoTabla()" class="textoOverflow anchos">
                    {{ "seguimiento.proyectos.html8" | translate }}
                    <mat-icon *ngIf="ordenamiento === 'id'" class="iconoSortIni">file_upload</mat-icon>
                    <mat-icon *ngIf="ordenamiento === 'asc'" class="iconoSort">file_upload</mat-icon>
                    <mat-icon *ngIf="ordenamiento === 'des'" class="iconoSort">download</mat-icon>
                  </th>
                </table>
              </th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow fechas"> {{ "seguimiento.proyectos.export3" | translate }} </th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow fechas"> {{ "seguimiento.proyectos.export4" | translate }} </th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow fechas"> {{ "seguimiento.proyectos.export5" | translate }} </th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow alingIzq"> {{ "seguimiento.proyectos.export6" | translate }} / <span style="font-weight: lighter;">{{ "seguimiento.proyectos.export10" | translate }}</span> </th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow alingDer"> {{ "seguimiento.proyectos.export11" | translate }} %</th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow alingDer"> {{ "seguimiento.proyectos.export7" | translate }} %</th>
              <th class="w3-elemento-titulo-table FijaTit1 textoOverflow alingDer"> {{ "seguimiento.proyectos.export8" | translate }} %</th>
              <ng-container *ngIf="actividades.length !== 0">
                <ng-container *ngIf=" actividades[0].additions.length !== 0">
                  <ng-container *ngFor="let ad of actividades[0].additions">
                    <ng-container *ngIf="ad.type === 'moneda'">
                      <th class="w3-elemento-titulo-table FijaTit1 alinDer textoOverflow formAd">{{ad.name}}</th>
                    </ng-container>
                    <ng-container *ngIf="ad.type === 'archivo'">
                      <th class="w3-elemento-titulo-table FijaTit1 alinCen textoOverflow formAd">{{ad.name}}</th>
                    </ng-container>
                    <ng-container *ngIf="ad.type !== 'moneda' && ad.type !== 'archivo'">
                      <th class="w3-elemento-titulo-table FijaTit1 alingIzq textoOverflow formAd">{{ad.name}}</th>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </thead>
            <tbody *ngFor="let pro of proyectosMostrar">
              <ng-container  *ngIf="pro.actividades.length !== 0">
                <tr>
                  <td class="w3-elemento-titulo-tableGrey alingIzq mantenerFija borderInf borderSup">
                    <table class="w3-table">
                      <td style="max-width: 1vw; padding: 0vh;">
                        <mat-icon *ngIf="!pro.check" (click)="oculMost(pro)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                        <mat-icon *ngIf="pro.check" (click)="oculMost(pro)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                      </td>
                      <td class="textoOverflow anchos" matTooltip="{{pro.proyecto.name}}" matTooltipPosition="right">
                        <span style="font-weight: bold;">{{pro.proyecto.name}}</span>
                      </td>
                    </table>
                  </td>
                  <td class="w3-elemento-titulo-tableGrey w3-center textoOverflow fechas borderInf borderSup">{{escribir_fecha(pro.fecha_ini)}}</td>
                  <td class="w3-elemento-titulo-tableGrey w3-center textoOverflow fechas borderInf borderSup">{{obtenerDias(pro.fecha_ini,pro.fecha_fin)}}</td>
                  <td class="w3-elemento-titulo-tableGrey w3-center textoOverflow fechas borderInf borderSup">{{escribir_fecha(pro.fecha_fin)}}</td>
                  <td class="w3-elemento-titulo-tableGrey alingIzq borderInf borderSup textoOverflow nombreRes">
                    <span matTooltip="{{pro.proyecto.responsible.position}}">
                      {{pro.proyecto.responsible.position}}
                    </span>
                  </td>
                  <td class="w3-elemento-titulo-tableGrey alingDer borderInf borderSup"></td>
                  <td class="w3-elemento-titulo-tableGrey alingDer borderInf borderSup">{{escribirDatPro(pro,'plan')}}</td>
                  <td class="w3-elemento-titulo-tableGrey alingDer borderInf borderSup" [ngClass]="pintarBordPro(escribirDatPro(pro, 'ejec'),escribirDatPro(pro, 'plan'))">
                    {{escribirDatPro(pro,'ejec')}}</td>
                  <ng-container *ngIf="actividades">
                    <ng-container *ngFor="let ad of actividades[0].additions; let i = index">
                      <td class="w3-elemento-titulo-tableBlan borderInf borderSup alingDer formAd">{{escribirAdicionalPro(pro.actividades,i)}}</td>
                    </ng-container>
                  </ng-container>
                </tr>
                <ng-container *ngIf="pro.check">
                  <tr *ngFor="let act of pro.actividades">
                    <ng-container *ngIf="existAct(act) && resSelected(act) && (act.editable || !mostrarPeriodo)">
                      <td class="mantenerFija w3-elemento-titulo-tableBlan alingIzq">
                        <table class="w3-table">
                          <td style="min-width: 0.6vw; padding: 0vh;"></td>
                          <td matTooltip="{{act.name}}" class="textoOverflow anchos" matTooltipPosition="right">
                            <span [ngClass]="actividadRojo(act)">{{act.name}}</span>
                          </td>
                        </table>
                      </td>
                      <td class="w3-elemento-titulo-tableBlan w3-center textoOverflow fechas">{{escribir_fecha(act.initial_date)}}</td>
                      <td class="w3-elemento-titulo-tableBlan w3-center textoOverflow fechas">{{obtenerDias(act.initial_date,act.end_date)}}</td>
                      <td class="w3-elemento-titulo-tableBlan w3-center textoOverflow fechas">{{escribir_fecha(act.end_date)}}</td>
                      <td class="w3-elemento-titulo-tableBlan alingIzq textoOverflow nombreRes">
                        <span matTooltip="{{act.responsible.position}}">
                          {{act.responsible.position}}
                        </span>
                      </td>
                      <td class="w3-elemento-titulo-tableBlan alingDer">{{avanceAcumulado(act)}}</td>
                      <td class="w3-elemento-titulo-tableBlan alingDer">{{escribirDat(act.historico, 'plan')}}</td>
                      <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td *ngIf="escribirDat(act.historico, 'ejec') === 0 || escribirDat(act.historico, 'ejec') === null && act.editable" [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))"
                          class="w3-elemento-titulo-tableBlan alingDer ingDat textoAyud" (dblclick)="editar(act.historico, act.id)">
                          0
                        </td>
                        <td *ngIf="escribirDat(act.historico, 'ejec') !== 0 && escribirDat(act.historico, 'ejec') !== null && act.editable" [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))"
                        class="w3-elemento-titulo-tableBlan alingDer ingDat" (dblclick)="editar(act.historico, act.id)">
                        {{escribirDat(act.historico, 'ejec')}}
                        </td>
                        <td *ngIf="escribirDat(act.historico, 'ejec') !== 0 && escribirDat(act.historico, 'ejec') !== null && !act.editable" [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))"
                        class="w3-elemento-titulo-tableBlan alingDer">
                        {{escribirDat(act.historico, 'ejec')}}
                        </td>
                      </ng-container>
                      <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td *ngIf="escribirDat(act.historico, 'ejec') === 0 || escribirDat(act.historico, 'ejec') === null" [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))"
                          class="w3-elemento-titulo-tableBlan alingDer textoAyud">
                          0
                        </td>
                        <td *ngIf="escribirDat(act.historico, 'ejec') !== 0 && escribirDat(act.historico, 'ejec') !== null" [ngClass]="pintarBord(escribirDat(act.historico, 'ejec'),escribirDat(act.historico, 'plan'))"
                        class="w3-elemento-titulo-tableBlan alingDer">
                        {{escribirDat(act.historico, 'ejec')}}
                      </td>
                      </ng-container>
                      <ng-container *ngFor="let ad of act.additions">
                        <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                          <td *ngIf="ad.type !== 'numero' && ad.type !== 'moneda' && ad.type !== 'archivo' && act.editable" class="textoOverflow w3-elemento-titulo-tableBlan anchosad"
                            style="cursor: pointer;" (dblclick)="edicionAd(ad, act.id, $event)" matTooltip="{{escribirAdicional(ad)}}">
                            {{escribirAdicional(ad)}}
                          </td>
                          <td *ngIf="ad.type !== 'numero' && ad.type !== 'moneda' && ad.type !== 'archivo' && !act.editable" class="textoOverflow w3-elemento-titulo-tableBlan anchosad"
                          matTooltip="{{escribirAdicional(ad)}}">
                            {{escribirAdicional(ad)}}
                          </td>
                        </ng-container>
                        <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                          <td *ngIf="ad.type !== 'numero' && ad.type !== 'moneda' && ad.type !== 'archivo'" class="textoOverflow w3-elemento-titulo-tableBlan anchosad"
                            matTooltip="{{escribirAdicional(ad)}}">
                            {{escribirAdicional(ad)}}
                          </td>
                        </ng-container>
                        <td *ngIf="ad.type === 'moneda'" class="textoOverflow alinDer w3-elemento-titulo-tableBlan anchosad">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                        <td *ngIf="ad.type === 'numero'" class="textoOverflow alinIzq w3-elemento-titulo-tableBlan anchosad">
                          <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                        </td>
                        <td *ngIf="ad.type === 'archivo'" class="textoOverflow w3-elemento-titulo-tableBlan alinCen anchosad">
                          <div *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                            <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch" style="cursor: pointer;"
                            (contextmenu)="onContextMenu($event, ad)" (click)="abirPDF(ad, $event);">
                              <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                            </div>
                            <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch" style="cursor: pointer;"
                            (dblclick)="edicionAd(ad, act.id, $event)">
                              <mat-icon  matTooltip="Adjuntar archivo" class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                            </div>
                          </div>
                          <div *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                            <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch" style="cursor: pointer;"
                            (click)="abirPDF(ad, $event);">
                              <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                            </div>
                            <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch">
                              <mat-icon class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="cargando_arch" class="w3-modal">
  <div class="contenedorCargando" style="width: 25%; height: 25%;">
    <div class="iconoCarga spinner-border text-secondary"></div>
  </div>
</div>