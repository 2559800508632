<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-caja-menu padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span> 
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row">
      <div class="w3-twothird">
        <div class="menuexp">
          <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
            [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        </div>
        <div class="botones">
          <div *appRoles="['Rol_Administrador']">
            <button matTooltip="Agregar elemento" class="colorBotonDeNuevo" (click)="abrirFormAgregar()">
              <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
              Agregar elemento
            </button>
            <!--<button class="w3-button" >
              <mat-icon [ngStyle]="{'color':'gray'}" matTooltip="Agregar concepto">add_circle</mat-icon>
            </button>-->
          </div>
        </div>
        <mat-menu #menu2="matMenu">
          <ng-container *ngIf="planeacion">
          </ng-container>
          <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>Exportar xlsx</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="w3-row">
      <div *ngFor="let filo of concepto">
        <div class="w3-twothird " style="height: 30vh; margin-top: 1vh;">
          <div class="w3-row w3-white">
            <div class="w3-noventa titulo-contenidomenu">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas"> {{filo.nombre}} </span>
              </div>
            </div>
            <div class="w3-rest w3-right-align_rest">
              <div *appRoles="['Rol_Administrador']" class="w3-right hijoderechaTitle" style="z-index: 1;">
                <mat-icon class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
              </div>
              <mat-menu #menu2="matMenu">
                <div>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="abrirForm(filo)">
                    <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                    <span>Editar</span>
                  </button>
                  <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="eliminar(filo)">
                    <mat-icon [ngStyle]="{'color':'gray'}">delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </div>
              </mat-menu>
            </div>
          </div>
          <div class="w3-row w3-white" style="height: 22vh;">
            <div class="w3-margin w3-margin-bottom">
              <mat-form-field appearance="outline" style="width: 95%;">
                <textarea matInput placeholder="Descripción" style="height:12vh;" value="{{filo.descripcion}}" disabled></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="formulario" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <form [formGroup]="formEdit" (ngSubmit)="alertas('editar')">
      <div class="w3-row" style="height: 10vh;">
        <div class="w3-container">
        
          <div class="w3-col inputDisp">
            <mat-error *ngIf="formEdit.hasError('isValid')" class="error-message">
              Nombre <strong> existente.</strong>
            </mat-error>
            <mat-form-field appearance="legacy" class="input-nombres">
              <mat-label> Elemento </mat-label>
              <input type="text" matInput placeholder="Ingrese un nombre" id="nombreedit" formControlName="inputName" required>
              <mat-error>
                <span>Campo <strong> obligatorio.</strong></span>
              </mat-error>
            </mat-form-field>
          </div>
        
        </div>
        <div class="w3-rest">
          <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
        </div>
      </div>
      <div class="w3-row w3-white" style="height: 35vh;">
        <div class="w3-container" style="height: 28vh; overflow: scroll;">
  
          <div class="w3-row text-area">
            <div class="w3-col">
              <mat-form-field appearance="outline" class="input-nombres">
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Ingrese una descripción" id="descripcionedit" formControlName="inputDescription" style="height:10vh;"></textarea>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal2">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarEdit()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
  
          </div>
        </div>
      </div> 
    </form>   
  </div>
</div>

<div id="formularionuevo" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <form [formGroup]="formNuevo" (ngSubmit)="alertas('guardar')">
      <div class="w3-row" style="height: 10vh;">
        <div class="w3-container" style="overflow: scroll;">
  
          <div id="NombreForm" class="w3-col inputDisp">
            <mat-error *ngIf="formNuevo.hasError('isValid')" class="error-message">
              Nombre <strong> existente.</strong>
            </mat-error>
            <mat-form-field appearance="legacy" class="input-nombres">
              <mat-label> Elemento </mat-label>
              <input type="text" matInput placeholder="Ingrese un nombre" id="nombre" formControlName="inputName"
              (keyup)="capitalizacionTexto('nombre')" required>
              <mat-error>
                <span>Campo <strong> obligatorio.</strong></span>
              </mat-error>
            </mat-form-field>
          </div>
  
        </div>        
        <div class="w3-rest">
          <mat-icon (click)="cerrarForm();" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
        </div>
      </div>
      <div class="w3-row w3-white" style="height: 35vh;">
        <div class="w3-container" style="height: 28vh; overflow: scroll;">
  
          <div class="w3-row text-area" [formGroup]="formNuevo">
            <div class="w3-col">
              <mat-form-field appearance="outline" class="input-nombres">
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Ingrese una descripción" id="descripcion" formControlName="inputDescription" style="height:10vh;"></textarea>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <div *ngIf="modal">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardar()">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="cerrarForm();" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </form>  
  </div>
</div>