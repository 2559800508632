<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <!--FILTROS-->
    <div class="w3-rest">
      <!--AÑOS Y MES-->
      <div class="w3-noventa">

        <!--ANIOS-->
        <div class="w3-half">
          <!--ANIOS DISPONIBLES-->
          <div [ngClass]="isActivate()">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Años
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuanios="matMenu">
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                                (change)="cambioAnio(anio)"
                                (click)="$event.stopPropagation();"
                                value="{{anio.name}}" aria-label="First checkbox"
                                >
                    {{ anio.name }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <!--FIN DE ANIOS DISPONIBLES-->
          <!-- AÑO SELECCIONADO -->
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips " id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosDisponibles"
                            style="margin-left: 5px;" class="filtroMesAnioSeleccionado" >
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN AÑO SELECCIONADO -->
        </div>

        <!--MESES-->
        <div class="w3-half">
          <!--MESES DISPONIBLES-->
          <div [ngClass]="isActivate2()">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menumeses" aria-label="Example icon-button with a menu">
              Perspectivas
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menumeses="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="selectAll"
                  (change)="seleccionarTodo()"
                  (click)="$event.stopPropagation();"
                  value="Seleccionar todas" aria-label="First checkbox"
                  >
                  Seleccionar todas
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selectAll">
                <ng-container *ngFor="let per of perspectivas">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="per.check"
                      (change)="cambioPer(per)"
                      (click)="$event.stopPropagation();"
                      value="{{per.nombre}}" aria-label="First checkbox"
                      >
                      {{ per.nombre }}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <!-- FIN MES SELECCIONADO -->
        </div>
        
      </div>
      <div class="w3-rest">
        <div class="w3-right" style="margin-top: 2vh;">
          <mat-icon class="w3-zindex w3-dropdown-click" [ngStyle]="{'color':'gray'}"
            [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        </div>
        <mat-menu #menu2="matMenu">
          <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>Exportar xlsx</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row w3-white" style="margin-bottom: -1vh;">
      <div class="menuexp">
        
      </div>
    </div>
    <div *ngFor="let per of perspectivas; let i = index">
      <ng-container *ngIf="mostrarPer(per)">
        <div class="w3-row" [ngStyle]="{'height':tamTabla('cont')}" style="margin-top: 1vh;">
          <div class="w3-row w3-white">
            <div class="w3-half w3-titulo-contenidomenu">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas"> {{per.nombre}} </span>
              </div>
            </div>
            <!--Menú-->
            <div class="w3-rest w3-right-align_rest">
              <div class="botones">
                <div *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-rest" style="margin-top: 1vh;">
                  <button matTooltip="Agregar indicador" class="colorBotonDeNuevo" (click)="open_modal(per)">
                    <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                    Agregar indicador
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w3-row w3-white w3-scroll" [ngStyle]="{'height':tamTabla('tab')}">
            <table class="w3-table borderSup borderInf">
              <thead class="borderInf">
                <tr>
                  <th class="w3-elemento-titulo-table borderSup borderSup tresPun anchoObj"> Objetivo </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun nomIndT" *ngIf="verCodigo"> Código </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun nomIndT anchoInd"> Indicador </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun"> Ponderación </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun"> Área responsable </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun"> Línea base </th>
                  <th class="w3-elemento-titulo-table borderSup tresPun"> Meta global</th>
                  <th class="w3-elemento-titulo-table borderSup tresPun" style="border-right: 1px solid #B9B9BC; "> Frecuencia </th>
                  <ng-container *ngFor="let mes of meses">
                    <ng-container *ngIf="indicadores.length != 0">
                      <th class="w3-elemento-titulo-table borderSup"> {{mes.name}} </th>
                    </ng-container>
                  </ng-container>
                  <th *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup tresPun"
                   style="border-right: 1px solid #B9B9BC;"></th>
                  <ng-container *ngIf="indicadores.length != 0">
                    <ng-container *ngFor="let ad of indicadores[0].adadicionales">
                      <th style="border-right: 1px solid #B9B9BC; border-left: 1px solid #B9B9BC; padding: 0vw 0.5vw;"
                        class="w3-elemento-titulo-table borderSup adicionAdicional textoOverflow" [matMenuTriggerFor]="manuEdicionAd">
                        {{ad.name}}
                      </th>
                      <mat-menu class="w3-medium" #manuEdicionAd="matMenu">
                        <button (click)="editarAdicional(ad)" class="mat-menu-item" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                          <span>Editar</span>
                        </button>
                        <button (click)="eliminarAdicional(ad)" class="mat-menu-item" mat-menu-item>
                          <mat-icon [ngStyle]="{'color':'gray'}">delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                      </mat-menu>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="indicadores.length != 0">
                    <th *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup" style="border-right: 1px solid #B9B9BC;"> 
                      <button matTooltip="Agregar adicional" class="colorBotonDeNuevo" (click)="agregarAdicional()">
                        <mat-icon class="iconoMen">add_circle_outline</mat-icon>
                      </button>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody *ngFor="let ele of indicadores; let i = index">
                <ng-container *ngIf="ele.perspective === per.nombre">
                  <td class="w3-table-trtdBalance tresPun textoOverflow anchoObj">
                    <span matTooltip="{{escribiObj(ele.objective.name,ele,i)}}">{{escribiObj(ele.objective.name,ele,i)}}</span> 
                  </td>
                  <td class="w3-table-trtdBalance tresPun textoOverflow nomInd" *ngIf="verCodigo">
                    <span matTooltip="Código">Código</span>
                  </td>
                  <td class="w3-table-trtdBalance tresPun textoOverflow nomInd anchoInd">
                    <span matTooltip="{{ele.nombre}}">{{ele.nombre}}</span>
                  </td>
                  <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance w3-center tresPun"
                    (dblclick)="edicionPond(ele)" style="cursor: pointer;">
                    {{ele.ponderacion===null?0:ele.ponderacion}}
                  </td>
                  <td *appRolesNeg="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance w3-center tresPun">
                    {{ele.ponderacion}}
                  </td>
                  <td class="w3-table-trtdBalance textoOverflow tresPun" style="max-width: 15vw;">
                    <span matTooltip="{{ele.area.name}}">{{ele.area.name}}</span>
                  </td>
                  <td class="w3-table-trtdBalance w3-center tresPun"> {{ele.linea_base}} </td>
                  <td class="w3-table-trtdBalance w3-center tresPun"> {{ele.meta_global}} </td>
                  <td class="w3-table-trtdBalance w3-center tresPun" style="border-right: 1px solid #B9B9BC;">
                    {{ele.periodicidad}}
                  </td>
                  <ng-container *ngFor="let mes of meses">
                    <ng-container>
                      <td class="w3-table-trtdBalance w3-center"> {{escrituraMet(mes.name,ele.id, ele)}} </td>
                    </ng-container>
                  </ng-container>
                  <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance w3-center tresPun" style="border-right: 1px solid #B9B9BC;"> 
                    <button matTooltip="Agregar metas" class="colorBotonDeNuevo" (click)="ageMet(ele)">
                      <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                    </button>
                    <mat-icon class="iconoMen" (click)="open_edit(ele,per)" matTooltip="Editar indicador" >edit</mat-icon> 
                    <mat-icon class="iconoMen" (click)="alertas('eliminar',ele)" matTooltip="Eliminar indicador">delete</mat-icon> 
                  </td> 
                  <ng-container *ngFor="let ad of ele.adadicionales">
                    <ng-container *appRoles="['Rol_Administrador','Rol_Carga']">
                      <td class="w3-table-trtdBalance tresPun" style="border-right: 1px solid #B9B9BC; border-left: 1px solid #B9B9BC;"
                      [ngStyle]="ad.type !== 'numero' && ad.type !== 'moneda'?{'cursor':'normal'}:{'cursor':'pointer'}"
                      (dblclick)="ad.type !== 'numero' && ad.type !== 'moneda'?'':editar(ad,ele.id)"> 
                        <span *ngIf="ad.type === 'moneda'" class="w3-right" style="max-width: 10vw; overflow-x: hidden;">
                          {{imprimir(ad)}}
                        </span>
                        <span *ngIf="ad.type === 'numero'" class="alinIzq" style="max-width: 10vw; overflow-x: hidden;">
                          {{imprimir(ad)}}
                        </span>
                        <span *ngIf="ad.type !== 'moneda' && ad.type !== 'numero'" style="max-width: 10vw; overflow-x: hidden;"> {{imprimir(ad)}}</span>
                      </td>
                    </ng-container>
                    <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga']">
                      <td class="w3-table-trtdBalance tresPun" style="border-right: 1px solid #B9B9BC; border-left: 1px solid #B9B9BC;"
                      [ngStyle]="ad.type !== 'numero' || ad.type !== 'numero'?{'cursor':'normal'}:{'cursor':'pointer'}"> 
                        <span *ngIf="ad.type === 'moneda'" class="w3-right" style="cursor: pointer; max-width: 10vw; overflow-x: hidden;">
                          {{imprimir(ad)}}
                        </span>
                        <span *ngIf="ad.type === 'numero'" class="alinIzq" style="cursor: pointer; max-width: 10vw; overflow-x: hidden;">
                          {{imprimir(ad)}}
                        </span>
                        <span *ngIf="ad.type !== 'moneda' && ad.type !== 'numero'" style="max-width: 10vw; overflow-x: hidden;"> {{imprimir(ad)}}</span>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="indicadores.length != 0">
                    <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance" style="border-right: 1px solid #B9B9BC;"> </td>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div id="agregarInd" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Indicadores metas </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>

      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicioIndAgregar"></span>

          <form [formGroup]="formIndicador" (ngSubmit)="alertas('guardar')">

            <div class="w3-row" *ngIf="verCodigo">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Código: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina un código para identificarlo </mat-label>
                  <input type="text" id="codigo" matInput formControlName="inputCodigo">
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Indicador: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formIndicador.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina un nombre corto para identificarlo </mat-label>
                  <input type="text" id="indicador" matInput formControlName="inputNameInd" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Brevemente describa lo que busca medir </mat-label>
                  <textarea matInput id="descripcion" formControlName="inputDescription"></textarea>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Cálculo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese la fórmula de cálculo del indicador </mat-label>
                  <input type="text" id="calculo" matInput formControlName="inputCalculation" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <!--<div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Ponderación: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el valor que corresponde a la mejor práctica </mat-label>
                  <input type="number" id="ponderacion" matInput>
                </mat-form-field>
              </div>
            </div>-->
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Objetivo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Al que se encuentra asociado el indicador  </mat-label>
                  <mat-select formControlName="inputObjective" required>
                    <ng-container *ngFor="let obj of objetivos">
                      <mat-option value="{{obj.id}}" *ngIf="obj.perspectiveId === idPers" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Parámetro: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> De conocerse, inserte el valor de mejor practica </mat-label>
                  <input type="text" id="parametro" matInput formControlName="inputParam">
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Línea base: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Inserte el último valor medido para este indicador </mat-label>
                  <input type="number" id="lineaB" matInput formControlName="inputBaseLine" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha de inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Establezca la fecha en la cual empezará la medición</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker" formControlName="inputFechaIn"
                  [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha de fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Establezca la fecha en la cual finalizará la medición</mat-label>
                  <input [min]="this.formIndicador.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker2"  formControlName="inputFechaFn"
                  [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker2 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Meta global: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina el valor que espera alcanzar al término del plan </mat-label>
                  <input type="number" id="meta" matInput formControlName="inputGlobalGoal" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Periodicidad: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Con qué frecuencia se medirá el indicador </mat-label>
                  <mat-select formControlName="inputPeriodicidad" required>
                    <mat-option value="mensual">Mensual</mat-option>
                    <mat-option value="bimestral">Bimestral</mat-option>
                    <mat-option value="trimestral">Trimestral</mat-option>
                    <mat-option value="cuatrimestral">Cuatrimestral</mat-option>
                    <mat-option value="semestral">Semestral</mat-option>
                    <mat-option value="anual">Anual</mat-option>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Unidad de medida: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione la que corresponde al tipo de indicador </mat-label>
                  <mat-select formControlName="inputUnidadMed" required>
                    <ng-container *ngFor="let obj of unidades_med">
                      <mat-option value="{{obj.id}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tendencia: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> En función del comportamiento del indicador </mat-label>
                  <mat-select formControlName="inputTendencia" required>
                    <ng-container *ngFor="let ten of tenden">
                      <mat-option value="{{ten.id}}"> {{ten.name}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Asocie el área responsable del indicador </mat-label>
                  <mat-select formControlName="inputArea" required>
                    <ng-container *ngFor="let are of areas">
                      <mat-option value="{{are.id}}" matTooltip="{{are.nombre}}"> {{are.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf="modal">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puede_guardar()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="agregarMet" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-rest w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido" *ngIf="modal4"> {{indic.nombre}} </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <ng-container *ngIf="modal4">

            <div class="w3-row">
              <div class="w3-half">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre del indicador: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.nombre}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-rest">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Cálculo: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.calculo}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-half">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Ponderación: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.ponderacion}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-rest">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Objetivo: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.objective.name}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-half">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Línea base: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.linea_base}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-rest">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha inicio: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{this.indic.fecha_inicio.getMonth()+1}}/{{this.indic.fecha_inicio.getDate()}}/{{this.indic.fecha_inicio.getFullYear()}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-half">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha fin: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{this.indic.fecha_fin.getMonth()+1}}/{{this.indic.fecha_fin.getDate()}}/{{this.indic.fecha_fin.getFullYear()}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-rest">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Periodicidad: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{indic.periodicidad}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-half">
                <div class="w3-half" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tendencia: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <input type="text" value="{{tend(indic.tendencia)}}" id="indicador" matInput disabled="true">
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-rest">             
              </div>
            </div>
            
            <div  class="w3-row"> <span class="w3-tituloZonas" style="margin-left: 2vw;"> Metas </span> </div>
            <ng-container *ngFor="let met of posMet; let i = index">
              <div class="w3-half">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> {{nomMes(met.nombre)}} </span></div>
                </div>
                <div [ngClass]="ultVal(i)" class="w3-rest">
                  <mat-form-field appearance="outline">
                    <input type="number" value="{{met.descripcion}}" id='{{"meta"+i}}' matInput>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

          </ng-container>

          <div class="w3-row" *ngIf="modal4">
            <div class="w3-right">
              <div class="botonesModales" >
                <button mat-flat-button color="success" (click)="almacenarMet()" style="height: 4vh;">
                    Guardar
                  </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="editIndicador" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-rest w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle" *ngIf="modal2">
                      <span class="w3-margin-left-contenido"> {{indic.nombre}} </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicioIndEditar"></span>

          <form [formGroup]="formIndicadorEdit" (ngSubmit)="alertas('editar')">

            <div class="w3-row" *ngIf="verCodigo">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Código: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina un código para identificarlo </mat-label>
                  <input type="text" id="codigoEdit" matInput formControlName="inputCodigo">
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Indicador: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formIndicadorEdit.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina un nombre corto para identificarlo </mat-label>
                  <input type="text" id="indicadorEdit" matInput formControlName="inputNameInd" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Brevemente describa que medir </mat-label>
                  <textarea matInput id="descripcionEdit" formControlName="inputDescription"></textarea>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Cálculo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese la fórmula de cálculo del indicador </mat-label>
                  <input type="text" id="calculoEdit" matInput formControlName="inputCalculation" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <!--<div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Ponderación: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el valor que corresponde a la mejor práctica </mat-label>
                  <input type="number" id="ponderacionEdit" matInput>
                </mat-form-field>
              </div>
            </div>-->
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Objetivo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Al que se encuentre asociado el indicador  </mat-label>
                  <mat-select formControlName="inputObjective" required>
                    <ng-container *ngFor="let obj of objetivos">
                      <mat-option value="{{obj.id}}" *ngIf="obj.perspectiveId === idPers" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Parámetro: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> De conocerse, inserte el valor de mejor practica </mat-label>
                  <input type="text" id="parametroEdit" matInput formControlName="inputParam">
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Línea base: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Inserte el último valor medido para este indicador </mat-label>
                  <input type="number" id="lineaBEdit" matInput formControlName="inputBaseLine" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha de inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Establezca la fecha en la cual empezará la medición</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker3" formControlName="inputFechaIn"
                  [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker3">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker3 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha de fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Establezca la fecha en la cual finalizará la medición</mat-label>
                  <input [min]="this.formIndicadorEdit.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker4"  formControlName="inputFechaFn"
                  [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker4">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker4 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Meta global: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Defina el valor que espera alcanzar el término del plan </mat-label>
                  <input type="number" id="metaEdit" matInput formControlName="inputGlobalGoal" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Periodicidad: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Con qué frecuencia se medirá el indicador </mat-label>
                  <mat-select formControlName="inputPeriodicidad" required>
                    <mat-option value="mensual">Mensual</mat-option>
                    <mat-option value="bimestral">Bimestral</mat-option>
                    <mat-option value="trimestral">Trimestral</mat-option>
                    <mat-option value="cuatrimestral">Cuatrimestral</mat-option>
                    <mat-option value="semestral">Semestral</mat-option>
                    <mat-option value="anual">Anual</mat-option>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Unidad de medida: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione la que corresponde al tipo de indicador </mat-label>
                  <mat-select formControlName="inputUnidadMed" required>
                    <ng-container *ngFor="let obj of unidades_med">
                      <mat-option value="{{obj.id}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tendencia: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> En función del comportamiento del indicador </mat-label>
                  <mat-select formControlName="inputTendencia" required>
                    <ng-container *ngFor="let ten of tenden">
                      <mat-option value="{{ten.id}}"> {{ten.name}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Asocie el área responsable del indicador </mat-label>
                  <mat-select formControlName="inputArea" required>
                    <ng-container *ngFor="let are of areas">
                      <mat-option value="{{are.id}}" matTooltip="{{are.nombre}}"> {{are.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf="modal2">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puede_guardarEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="adicional" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Columna adicional </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="closeModal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh;">
          <ng-container>
            <div class="w3-row" style="width: 90%;" [formGroup]="formIndicador">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Adicional: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formIndicador.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese un Nombre </mat-label>
                  <input type="text" id="nomAdi" matInput formControlName="inputNameAdi" required>
                  <mat-error *ngIf="formIndicador.controls['inputNameAdi'].errors">
                    <span *ngIf="formIndicador.controls['inputNameAdi'].errors['required']">Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;" [formGroup]="formIndicador">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tipo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione uno de los tipos </mat-label>
                  <mat-select id="objPro" [(ngModel)]="tipSelect" [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="texto" matTooltip="Agrega un campo para texto que se rellena mes a mes"> Texto </mat-option>
                    <mat-option value="moneda" matTooltip="Agrega un campo para cantidades"> Moneda </mat-option>
                    <mat-option value="archivo" matTooltip="Agrega un campo para adjuntar archivos"> Archivo </mat-option>
                    <mat-option value="numero" matTooltip="Agrega un campo para texto"> Texto fijo </mat-option>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

          </ng-container>

          <div *ngIf="modal3">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarAdi()">
                <button mat-flat-button color="success" (click)="guardarAdi()" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="closeModal()"  style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
