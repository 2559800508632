import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data-service/data.service';
import { IndicadoresService } from '../services/formulacion/indicadores/indicadores.service';
import { Planificacion } from '../model/datos';
import { WelcomeService } from '../services/welcome/welcome.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent{
  public planeacion: Planificacion[] = [];
  private planeacionId = 0;
  private planeacionSelec: any = ''
  private url: any;
  private acceso = [];

  public modulosAct:any;
  constructor(
    public dataService: DataService,
    private router: Router,
    private indicadoresService: IndicadoresService,
    private activateRouter: ActivatedRoute,
    private welcomeService: WelcomeService,
    private translate: TranslateService,
    ) {
    welcomeService.getAccesos(dataService.currentUser).subscribe(response =>{
      if(response && response.access.length !== 0){
        this.acceso = [];
        for(const acces of response.access){
          this.acceso.push(''+acces);
        }
      }else{
        this.acceso = ['no encontrado'];
      }
      this.url = this.activateRouter.snapshot['url'];
      var parametros = this.activateRouter.snapshot['params'];
      if(this.url.length !== 1 && this.url.length !== 0){
        this.planeacionId = parametros['idplan'];
        this.planeacionSelec = parametros['planNom']; 
      }
      this.menu();
    });
  }
  menu(): void{
    var aux = [];
    for(const mod of this.dataService.fillerNav){
      if(mod.name != "Inicio" && mod.submodules.length > 0){
        aux.push(mod);
      }
    }
    this.modulosAct = aux;
  }
  lado(index:any){
    if(index%2 === 0){
      return 'w3-half w3-border w3-border-white w3-hover-inicio';
    }else{
      return 'w3-rest w3-border w3-border-white w3-hover-inicio';
    }
  }
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }

  private accesoPlan(id: any){
    if ( this.acceso[0] === 'all' || this.acceso.includes(id) || this.acceso[0] === 'no encontrado'){
      return true;
    }
    return false;
  }
  public async enviarA(modulo:any){
    if(modulo.name !== 'Administrador'){
      this.indicadoresService.getPlanificaciones().subscribe(response => {
        this.planeacion = [];
        for(const plan of response){
          if(this.accesoPlan(plan.institution.id)){
            this.planeacion.push({
              id: plan.id,
              nombre: plan.name,
              fecha_inicio: new Date(plan.initial_date),
              fecha_fin: new Date(plan.end_date),
              institucionId: plan.institution.id,
              isActive: plan.isActive
            });
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);
  
        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }
        if(Planeaciones.length > 0){
          Swal.fire({
            customClass: {
              confirmButton: 'swalBtnColor'
            },
            title: this.translate.instant('menu.select'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  var name = 'Alta de planes';
                  if(modulo.submodules[0].name === name){
                    this.router.navigateByUrl('/app/'+ modulo.submodules[1].route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ modulo.submodules[1].route.split('/')[1] +'/'+this.planeacionId);
                  }/*else if(modulo.module === 'Reportes' && (this.acceso[0] === 'all' || this.acceso.includes('3'))){
                    window.open("assets/documentos/informe_OSCUS.pdf","_blank");
                  }*/else{
                    this.router.navigateByUrl('/app/'+ modulo.submodules[0].route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ modulo.submodules[0].route.split('/')[1] +'/'+this.planeacionId);
                  }
                  }else{
                  resolve(this.translate.instant('menu.select'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFound'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
      });
    }else{
      this.welcomeService.getInstituciones().subscribe(response =>{
        this.planeacion = [];
        for(const inst of response){
          if(this.accesoPlan(inst.id)){
            if(this.accesoPlan(inst.id) && (inst.code === this.dataService.company_id || this.dataService.roleReal === 'Administrador')){
              this.planeacion.push({
                id: inst.id,
                nombre: inst.name,
              });
            }
          }
        }
        this.planeacion = this.ordenamientoPer(this.planeacion);

        var Planeaciones: any = [];
        for(const plan of this.planeacion){
          Planeaciones.push(''+plan.nombre);
        }

        if(Planeaciones.length > 0){
          Swal.fire({
            title: this.translate.instant('menu.selectInst'),
            input: 'select',
            inputOptions: Planeaciones,
            inputPlaceholder: this.translate.instant('menu.seleccione'),
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: this.translate.instant('swal.acep'),
            cancelButtonText: this.translate.instant('swal.cerr'),
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if(value){
                  resolve('');
                  this.planeacionId = Number(this.planeacion[Number(value)].id);
                  this.planeacionSelec = this.planeacion[Number(value)].nombre;
                  this.router.navigateByUrl('/app/'+ modulo.submodules[0].route.split('/')[0] +'/'+this.planeacionId+'/'+this.planeacionSelec+'/'+ modulo.submodules[0].route.split('/')[1]);
                }else{
                  resolve(this.translate.instant('menu.selectInst'));
                }
              })
            }
          });
        }else{
          Swal.fire({
            title: this.translate.instant('menu.noFoundInst'),
            text: '',
            confirmButtonColor: '#ff6b00',
            confirmButtonText: this.translate.instant('swal.regr')
          });
        }
      });
    }
  }
}

