import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Perspectivas,  Objetivos, Indicadores} from '../../../model/datos';
import html2canvas from 'html2canvas';
import { IMG } from '../../../services/Exportar/exportImg';
import { ExcelService } from '../../../services/Exportar/excel.service';

import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CuadromandoService } from '../../../services/formulacion/cruadromando/cuadromando.service';
@Component({
  selector: 'app-cuadromando',
  templateUrl: './cuadromando.component.html',
  styleUrls: ['./cuadromando.component.css']
})
export class CuadromandoComponent implements OnInit {

  public modal = false;

  private planeacionId: any;
  public perspectivas: Perspectivas[] = [];
  public indicadores: Indicadores[] = [];
  public perspectivaSel = '';
  public numeroPer = 0;
  public diccionarioPerspectivas: any = [];

  public mision;
  public vision;
  public nombreObj = '';
  public tarjOrTab = true;

  constructor(
    private router: Router,
    public dataService: DataService,
    private activateRouter: ActivatedRoute,
    private cuadromandoService: CuadromandoService,
    private excelService: ExcelService,
  ) {
    //@ts-ignore
    this.planeacionId = Number(this.activateRouter.snapshot.params.planeacion);
    this.obtData();
  }
  ngOnInit(): void {
  }

  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }

  private obtData(){
    this.cuadromandoService.getPerspectivas().subscribe(response => {
      this.perspectivas = [];
      var aux: Perspectivas = new Perspectivas();
      for (const per of response){
        if(Number(per.planning.id) === this.planeacionId){
          this.perspectivas.push({
            nombre: per.name,
            descripcion: per.descripcion,
            planningId: per.planning.id,
            id: per.id,
            check: true,
          });
        }
      }
      this.perspectivas = this.ordenamientoPer(this.perspectivas);
      this.perspectivas.forEach(per => { this.diccionarioPerspectivas.push({perspectiva: per, objetivos : []}); });
      this.cuadromandoService.getObjetivos().subscribe(response => {
        for(const per of this.diccionarioPerspectivas){
          for(const obj of response){
            if(obj.perspective.id === per.perspectiva.id){
              var ind = [];
              per.objetivos.push({
                nombre: obj.name,
                descripcion: obj.description, 
                ponderacion: obj.weighing, 
                perspectiveId: obj.perspective.id, 
                id: obj.id,
                indicators: ind,
              });
            }
          }
        }
        this.obtenerIndicadores();
      });
    });
    this.cuadromandoService.getPlanificaciones().subscribe(response =>{
      for(const plan of response){
        const fil = this.ordenamientoPer(plan.philosophy);
        if(plan.id === ''+this.planeacionId){
          for(var i = 0; i <= fil.length-1; i++){
            if(i === 0){
              this.mision = fil[i];
            }else if(i === 1){
              this.vision = fil[i];
            }else{
              break;
            }
          }
        } 
      }
    });
  }
  private obtenerIndicadores(){
    for(const per of this.diccionarioPerspectivas){
      for(const obj of per.objetivos){
        this.cuadromandoService.getIndicadores(obj).subscribe(response =>{
            if(response.indicators.length !== 0){
              obj.indicators = response.indicators;
            }else{
              obj.indicators.push({
                name: "",
                weighing: ""
              });
            }
        });
      }
    }
  }
  public color(indice: any){
    switch(indice){
      case 0: return 'cuadroP1';
      case 1: return 'cuadroP2';
      case 2: return 'cuadroP3';
      case 3: return 'cuadroP4';
      case 4: return 'cuadroP5';
      case 5: return 'cuadroP6';
      case 6: return 'cuadroP7';
      case 7: return 'cuadroP8';
      case 8: return 'cuadroP9';
    }
    return ''
  }

  public cambiodeTab(dir: any) {
    if(dir === 'ad'){
      this.router.navigateByUrl('/app/'+ 'formulacion/' + 'alineamiento/'+this.planeacionId);
    }else{
      this.router.navigateByUrl('/app/'+ 'formulacion/' + 'proyectosestrategicos/'+this.planeacionId);
    }
  }

  public modal_ind(obj: any, per: any, number: any){
    this.perspectivaSel = per;
    this.numeroPer = number;
    this.nombreObj = obj.nombre;
    this.modal = true;

    this.cuadromandoService.getIndicadores(obj).subscribe(response =>{
      this.indicadores = [];
      for(const ind of response.indicators){
          this.indicadores.push({
            nombre: ind.name,
          });
      }
      this.indicadores = this.ordenamientoPer(this.indicadores);
      //document.getElementById('mostrarIndicador')!.style.display = 'block';
      var html = '<div class="w3-row">'+
      '<div class="w3-row">'+
      '  <div style="text-align: justify; margin-left: 1vw; margin-right: 2vw;"> Indicadores asociados </div>'+
      '</div>'+
      '<mat-list style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">';

      for(const ind of this.indicadores){
        html += '<div mat-line style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">'+
        '<span style="color: #ff6b00">&#8226;</span>'+
        ' '+ind.nombre+
        '</div>';
      }
      html += '</mat-list>'+'</div>';

      var ancho = 'auto';
      if(this.nombreObj.length >100){
        ancho = '60%'
      }

      Swal.fire({
        title: 'Objetivo'+  this.obtLet(this.perspectivaSel)+(this.numeroPer+1)+': '+ this.nombreObj,
        html: html,
        showConfirmButton: false,
        width: ancho+'',
      });
    });
  }
  public close_Modal(){
    this.modal = false;
    document.getElementById('mostrarIndicador')!.style.display = 'none';
  }
  public obtLet(per: any){
    return ''+per.perspectiva.nombre[0];
  }

  public imprimirImg(){
    const grafica = 'img-1';
    const name = 'CMI';
    IMG.exportimagenes(grafica,name);
    /*document.getElementById("menu-exp").style.display = "none"
    html2canvas((document.getElementById("img-1")), {width: 1200, height: 750, }).then(canvas => {
      const a = document.createElement("a");
            a.href = canvas.toDataURL("image/png");
            a.download =  "CMI.png";
            a.click();
    })
    setTimeout(() => {
      document.getElementById("menu-exp").style.display = "block";

    },3000)*/
  }
  public cambioTarjetOrTable(){
    this.tarjOrTab = !this.tarjOrTab;
  }
  public bordes(i: number, j:number){
    if(i === 0 && j === 0){
      return 'borderSup';
    }
    return '';
  }
  public exportarTab(){
    const title = 'Cuadro mando integral';
    var header = ["Perspectiva", "Objetivo", "Ponderación", "Indicador", "Ponderación"];
    var data = [];
    var aux = [];
    for(const per of this.diccionarioPerspectivas){
      for(var i = 0; i <= per.objetivos.length-1; i++){
        for(var j = 0; j <= per.objetivos[i].indicators.length-1; j++){
          aux.push( i===0&&j===0?per.perspectiva.nombre:'' );
          aux.push( j===0?per.objetivos[i].nombre:'' );
          aux.push( j===0?per.objetivos[i].ponderacion:'' );
          aux.push( per.objetivos[i].indicators[j].name );
          aux.push( per.objetivos[i].indicators[j].weighing );
          data.push(aux);
          aux = [];
        }
      }
    }
    const NomArch = 'Cuadro mando integral';
    this.excelService.generateExcelCMIFormulacion(title, header, data, NomArch);
  }
}
