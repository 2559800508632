import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Objetivos{
  public id?: Number;
  public name?: String;
  public description?: String;
  public weighing?: Number;
  public perspectiveId?: Number;
  public responsibleIds?: Number[];
}
export class Responsable{
  public id?: Number;
  public position?: String;
}
@Injectable({
  providedIn: 'root'
})
export class ObjetivosService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }
  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsable(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postObjetivos(obj: any): Observable<any>{
    const objet: Objetivos = {
      name: obj.nombre,
      description: obj.descripcion,
      weighing: Number(obj.ponderacion),
      perspectiveId: Number(obj.perspectiveId),
      responsibleIds:[Number(obj.responsableId)],
    }
    const body = JSON.stringify(objet);
    const apiURL = this.apiURL+"objetivos";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchObjetivos(obj: any): Observable<any>{
    const objec: Objetivos = {
      name: obj.nombre,
      description: obj.descripcion,
      weighing: Number(obj.ponderacion),
      perspectiveId: Number(obj.perspectiveId),
      responsibleIds:[Number(obj.responsableId)],
    }
    const body = JSON.stringify(objec);
    const apiURL = this.apiURL+"objetivos/"+obj.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public patchObjetivosPon(obj: any): Observable<any>{
    const objec: Objetivos = {
      weighing: Number(obj.ponderacion),
    }
    const body = JSON.stringify(objec);
    const apiURL = this.apiURL+"objetivos/"+obj.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteObjetivos(obj: any): Observable<any>{
    const apiURL = this.apiURL+"objetivos/"+obj.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
}
