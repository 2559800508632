import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {WelcomeComponent} from './components/welcome/welcome.component';

import {AuthGuard} from './auth/auth.guard';
import { PermisosSubmodulosGuard } from './guards/permisos-submodulos/permisos-submodulos.guard'

import {ConstantesQubos} from './util/app-util';
import { InicioComponent } from './inicio/inicio.component';

const PATH = ConstantesQubos.strPATH;
const routes: Routes = [
  {
      path: '',
      redirectTo: '/' + PATH,
      pathMatch: 'full',
  },
  {
      path: PATH,
      component: WelcomeComponent,
      canActivate: [AuthGuard],
      children: [
          {
              path: '',
              canActivateChild: [AuthGuard],
              children: [
                  {path: ':idplan/:planNom', component: InicioComponent, canActivate: [PermisosSubmodulosGuard]},
                  {path: '', component: InicioComponent, canActivate: [PermisosSubmodulosGuard]},
                  {path: 'inicio', component: InicioComponent, canActivate: [PermisosSubmodulosGuard]},
              ],
          }
      ]
  },
  {
    path: 'controller',
    redirectTo: '/' + PATH,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
