import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { PermisosSubmodulosGuard } from 'src/app/guards/permisos-submodulos/permisos-submodulos.guard';
import { AltaplanificacionesComponent } from './altaplanificaciones/altaplanificaciones.component';
import { PerspectivasComponent } from './perspectivas/perspectivas.component';
import { FilosofiacorporativaComponent } from './filosofiacorporativa/filosofiacorporativa.component';
import { MatrizfloaComponent } from './matrizfloa/matrizfloa.component';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { AlineamientoComponent } from './alineamiento/alineamiento.component';
import { ProyectosestrategicosComponent } from './proyectosestrategicos/proyectosestrategicos.component';
import { CuadromandoComponent } from './cuadromando/cuadromando.component';
import { AltaactasComponent } from './altaactas/altaactas.component';
import { MatrizFodaNComponent } from './matriz-foda-n/matriz-foda-n.component';
import { Proyectos2Component } from './proyectos2/proyectos2.component'; 

const routes: Routes = [
  {
    path: 'app/formulacion/:idplan/:planNom',
    component: WelcomeComponent,
    children: [
      { path: 'altaplanes', component: AltaplanificacionesComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'perspectivas/:planeacion', component: PerspectivasComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'filosofiacorporativa/:planeacion', component: FilosofiacorporativaComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'matrizfoda/:planeacion', component: MatrizfloaComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'matrizfodaV2/:planeacion', component: MatrizFodaNComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'objetivos/:planeacion', component: ObjetivosComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'indicadores/:planeacion', component: IndicadoresComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'alineamiento/:planeacion', component: AlineamientoComponent,canActivate: [PermisosSubmodulosGuard]},
      { path: 'proyectosestrategicos/:planeacion', component: ProyectosestrategicosComponent ,canActivate: [PermisosSubmodulosGuard]},
      { path: 'cmi/:planeacion', component: CuadromandoComponent ,canActivate: [PermisosSubmodulosGuard]},
      { path: 'altaProyectos2/:planeacion', component: Proyectos2Component ,canActivate: [PermisosSubmodulosGuard]},
      { path: 'altaactas/:planeacion', component: AltaactasComponent ,canActivate: [PermisosSubmodulosGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormulacionRoutingModule { }
