import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CatalogoService } from '../../../services/administrador/catalogo/catalogo.service';
import { obtenerError, procesamiento } from './../../../model/datos';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import Swal from 'sweetalert2';
import { ExcelService } from 'src/app/services/Exportar/excel.service';

@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.css']
})
export class CatalogosComponent implements OnInit {

  private idInstitucion = 0;

  public responsables = [];
  public headers = ['posicion','nombre','correo','edicion','validador'];
  public responsableEdicion = [];
  public idArea;
  public templatesGenerales = [];
  public templatesActas = [];
  public templatesGeneralesOrden = ['Indicadores', 'Tareas de proyectos', 'Reportes seguimiento'];
  public templatesActasOrden = ['Validación de cumplimiento de actividad', 'Tareas de actas', 'Envio de acta al equipo'];
  public templatesInstitucion = [];
  public cambios = [];
  public realizoCambios = false;
  public siVeActas = false;
  private html;
  public nombreImg = '';

  private agrgearRes = false;
  private editarRes = false;

  public logOutAct = false;
  public logOutTime = 5;
  private logOutActInst = false;
  private logOutTimeInst = 5;
  private idSettings; 
  private institutionCode = '';

  public urll = '';
  public bufferArray = [];
  
  public textoMostrar = 'Texto informativo';

  public formNuevo = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    primerApellido: new FormControl('', [Validators.required]),
    segundoApellido: new FormControl('', []),
    correo: new FormControl('', [Validators.required, Validators.email]),
    puestoCargo: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });
  public formEditar = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    primerApellido: new FormControl('', [Validators.required]),
    segundoApellido: new FormControl('', []),
    correo: new FormControl('', [Validators.required, Validators.email]),
    puestoCargo: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });
   
  constructor(
    private router: Router,
    public dataService: DataService,
    private activateRouter: ActivatedRoute,
    private catalogoService: CatalogoService,
    private excelService: ExcelService,
  ) { 
    this.idInstitucion = this.activateRouter.snapshot['_routerState'].url.split('/')[3];
    this.siVeActas = dataService.veActas;
    if(!this.siVeActas){
      this.headers = ['posicion','nombre','correo','edicion'];
    }
    this.obtData();
  }

  ngOnInit(): void {
  }

  private obtData(){
    this.catalogoService.getAreas().subscribe(response =>{
      this.responsables = [];
      for(const area of response){
        if(area.institution.id === this.idInstitucion){
          this.responsables.push(area);
        }
      }
    });
    this.catalogoService.getInstitucion(this.idInstitucion).subscribe(response =>{
      if(response){
        
        if(response.settings.length === 0){
          this.catalogoService.postSettings(this.idInstitucion).subscribe(response=>{
            this.obtData;
          });
        }else{
          this.logOutAct = response.settings[0]['name']==='false'?false:true;
          this.logOutActInst = response.settings[0]['name']==='false'?false:true;
          this.logOutTime = Number(response.settings[0]['value']);
          this.logOutTimeInst = Number(response.settings[0]['value']);
          this.idSettings = response.settings[0]['id'];
          this.templatesInstitucion = [];
          for(const tem of response.templates){
            this.templatesInstitucion.push(tem.id);
          }
          this.catalogoService.getTemplates().subscribe(response =>{
            this.obtTemplates(response);        
          });
        }
        this.institutionCode = response.code;
        this.catalogoService.getArchivosAdm(this.institutionCode).subscribe(response =>{
          for(const doc of response){
            const name = (''+doc['filename']).split('.');
            const nomsep = name[0].split('_'); 
            for(const nam of nomsep){
              if( nam.toLocaleLowerCase() === 'logo' ){
                this.catalogoService.getArchivosA(doc._id).subscribe(response =>{
                  var base = 'data:image/'+name[name.length-1]+';base64,'+btoa(new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                  this.urll = base;
                  this.bufferArray = [{buffer: response, tipo: name[name.length-1]}];
                },err =>{
                  this.messageError(err);
                });
                break;
              }
            }
          }
        },err =>{
          this.messageError(err);
        });
      }
    });
  }
  /** Obtener datos */
  public obtResponsable(res: any){
    let responsable = res.name+' '+res.first_surname+' '+res.second_surname;
    return responsable;
  }
  public obtCorreo(res: any){
    let correo = res.email+' ';
    return correo;
  }
  private obtTemplates(response: any){
    const Gen = response.filter(tem => tem.name==='Tareas de proyectos'||tem.name==='Indicadores'||tem.name==='Reportes seguimiento');
    const Act = response.filter(tem => tem.name==='Tareas de actas'||tem.name==='Validación de cumplimiento de actividad'||tem.name==='Envio de acta al equipo');
    this.templatesGenerales = [];
    this.templatesActas = [];
    for(const tem of this.templatesGeneralesOrden){
      const aux = Gen.filter(t => t.name === tem);
      this.templatesGenerales.push({
        id: aux[0].id,
        name: aux[0].name,
        check: this.existeTemplate(aux[0].id),
      });
    }
    for(const tem of this.templatesActasOrden){
      const aux = Act.filter(t => t.name === tem);
      this.templatesActas.push({
        id: aux[0].id,
        name: aux[0].name,
        check: this.existeTemplate(aux[0].id),
      });
    }
  }
  /* Validador */
  public cambioValidator(res){
    res.isValidator = !res.isValidator; 
    this.catalogoService.patchResponsableValidador(res).subscribe(response =>{
      Swal.fire({
        title: 'Información actualizada',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    },error =>{
      this.messageError(error);
    });
  }
  /** Modales */
  public abrirAgregarRes(){
    document.getElementById('agregarResponsable')!.style.display = 'block';
    this.agrgearRes = true;
  }
  public abrirEditarRes(res: any, areaId: any){
    this.responsableEdicion = res;
    this.idArea = areaId;
    this.formEditar.controls['nombre'].setValue(res.name);
    this.formEditar.controls['primerApellido'].setValue(res.first_surname !== ' ' ? res.first_surname:'');
    this.formEditar.controls['segundoApellido'].setValue(res.second_surname !== ' ' ? res.second_surname:'');
    this.formEditar.controls['correo'].setValue(res.email);
    this.formEditar.controls['puestoCargo'].setValue(res.position);
    document.getElementById('editarResponsable')!.style.display = 'block';
    this.editarRes = true;
  }
  public cerrarFormulario(){
    this.agrgearRes = false;
    this.editarRes = false;
    this.formNuevo.reset();
    this.formEditar.reset();
    document.getElementById('editarResponsable')!.style.display = 'none';
    document.getElementById('agregarResponsable')!.style.display = 'none';
  }
  /** Mecanismo de desición de guardado */
  public alertasGuardado(guardar: any){
    switch(guardar){
      case 'nuevo':
        if(this.formNuevo.invalid){
          Swal.fire({
            title: 'Hay información faltante',
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        }else{
          Swal.fire({
            title: '¿La información es correcta?',
            text: "",
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.guardarNuevo();
            }
          });
        }
      break;
      case 'editar':
        if(this.formEditar.invalid){
          Swal.fire({
            title: 'Hay información faltante',
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        }else{
          Swal.fire({
            title: '¿La información es correcta?',
            text: "",
            showCancelButton: true,
            confirmButtonColor: '#ff6b00',
            cancelButtonColor: '#B9B9BC',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.guardarEditar();
            }
          });
        }
      break; 
    }
  }
  /** Guardado de datos */
  public guardarNuevo(){
    const area = {
      name: this.formNuevo.value.puestoCargo,
      institutionId: Number(this.idInstitucion)
    };
    this.catalogoService.postArea(area).subscribe(response =>{
      const responsable = {
        name: this.formNuevo.value.nombre,
        first_surname: this.formNuevo.value.primerApellido,
        second_surname: this.formNuevo.value.segundoApellido !== null && this.formNuevo.value.segundoApellido !== ''? this.formNuevo.value.segundoApellido:' ',
        email: this.formNuevo.value.correo,
        position:this.formNuevo.value.puestoCargo,
        areaId: Number(response.id),
      };
      this.catalogoService.postResponsable(responsable).subscribe(response =>{
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
        this.obtData();
        this.cerrarFormulario();
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });     
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  public guardarEditar(){
    const area = {
      name: this.formEditar.value.puestoCargo,
      institutionId: Number(this.idInstitucion),
      id: this.idArea,
    };
    this.catalogoService.patchArea(area).subscribe(response =>{
      const responsable = {
        name: this.formEditar.value.nombre,
        first_surname: this.formEditar.value.primerApellido,
        second_surname: this.formEditar.value.segundoApellido !== null && this.formEditar.value.segundoApellido !== ''? this.formEditar.value.segundoApellido:' ',
        email: this.formEditar.value.correo,
        position:this.formEditar.value.puestoCargo,
        areaId: Number(this.idArea),
        id: this.responsableEdicion['id'],
      };
      this.catalogoService.patchResponsable(responsable).subscribe(response =>{
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
        this.obtData();
        this.cerrarFormulario();
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });     
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** validaciones de guardado */
  public puedeGuardarAgregar(){
    if( this.formNuevo.invalid ){
      return 'w3-disabled';
    }
    return '';
  }
  public puedeGuardarEditar(){
    if( this.formEditar.invalid ){
      return 'w3-disabled';
    }
    return '';
  }
  /** Eliminación datos */
  public eliminarResponsable(res: any, area: any){
    Swal.fire({
      title: '¿Desea borrrar el responsable?',
      text: "La información no se podra recuperar",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(area.indicators.length === 0){
          this.catalogoService.deleteResponsable(res).subscribe(response =>{
            this.catalogoService.deleteArea(area).subscribe(response =>{
              Swal.fire({
                title: 'Información eliminada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
              this.obtData();
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        }else{
          Swal.fire({
            title: 'No se puede eliminar, cuenta con información asociada',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#ff6b00',
            confirmButtonText: 'Aceptar',
          });
        }
      }
    });
  }
  /** Exportar excel */
  public exportarExcel(){
    const title = 'Catálogo responsables';
    const headers = ['Puesto / Cargo', 'Nombre', 'Correo electrónico'];
    let columnas = [];
    let arreglo = [];
    for(const res of this.responsables){
      columnas.push(res.name);
      columnas.push(res.responsibles[0].name + ' ' + res.responsibles[0].first_surname + ' ' + res.responsibles[0].second_surname);
      columnas.push(res.responsibles[0].email);
      arreglo.push(columnas);
      columnas = [];
    }
    this.excelService.generateExcelResponsables(title,headers, arreglo, 'Perspectivas');
  }
  /* activación desactivación templates */
  public cambioCheckBox(click){
    click.check = !click.check;
    var normal = 0, mas = 0;
    for(const tem of this.templatesGenerales){
      const existe = this.templatesInstitucion.filter(temp => temp === tem.id);
      if(tem.check && existe.length !== 0){
        normal++;
      }else if(tem.check){
        mas++;
      }
    }
    for(const tem of this.templatesActas){
      const existe = this.templatesInstitucion.filter(temp => temp === tem.id);
      if(tem.check && existe.length !== 0){
        normal++;
      }else if(tem.check){
        mas++;
      }
    }

    if(normal === this.templatesInstitucion.length && mas === 0){
      this.realizoCambios = false;
    }else{
      this.realizoCambios = true;
    }
  }
  public existeTemplate(idTemplate): boolean{
    const existe = this.templatesInstitucion.filter(tem => tem === idTemplate);
    if(existe.length !== 0){
      return true;
    }
    return false;
  }
  public cancelarSeleccion(){
    for(const tem of this.templatesGenerales){
      tem.check = this.existeTemplate(tem.id);
    }
    for(const tem of this.templatesActas){
      tem.check = this.existeTemplate(tem.id);
    }
    this.realizoCambios = false;
  }
  public guardarSeleccion(){
    this.cambios = [];
    for(const tem of this.templatesGenerales){
      if(tem.check){
        this.cambios.push(tem.id);
      }
    }
    for(const tem of this.templatesActas){
      if(tem.check){
        this.cambios.push(tem.id);
      }
    }
    this.catalogoService.patchInstitucion(this.idInstitucion, this.cambios).subscribe(response =>{
      this.catalogoService.getInstitucion(this.idInstitucion).subscribe(response =>{
        if(response){
          this.templatesInstitucion = [];
          for(const tem of response.templates){
            this.templatesInstitucion.push(tem.id);
          }
          this.cancelarSeleccion();
          this.realizoCambios = false;
          Swal.fire({
            title: 'Notificaciones actualizadas',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        }
      });
    },error =>{
      this.messageError(error);
    });
  }
  /* Cambios en el log out */
  public realizoCambiosLogOut(){
    if(this.logOutAct !== this.logOutActInst || this.logOutTime !== this.logOutTimeInst){
      return true;
    }
    return false;
  }
  public guardarCambiosLogOut(){
    this.catalogoService.patchSettings(this.logOutAct, this.logOutTime, this.idSettings, this.idInstitucion).subscribe(response =>{
      this.catalogoService.getInstitucion(this.idInstitucion).subscribe(response =>{
        this.logOutAct = response.settings[0]['name']==='false'?false:true;
        this.logOutActInst = response.settings[0]['name']==='false'?false:true;
        this.logOutTime = Number(response.settings[0]['value']);
        this.logOutTimeInst = Number(response.settings[0]['value']);
        this.idSettings = response.settings[0]['id'];
        Swal.fire({
          title: 'Log out actualizado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
          willClose: () =>{
            window.location.reload();
          }
        });
      });
    });
  }
  public cancelarCambiosLogOut(){
    this.logOutAct = this.logOutActInst;
    this.logOutTime = this.logOutTimeInst;
  }
  /* Mensaje de error */
  private messageError(err){
    let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
  }
  /* modal info  */
  public modalInfo(){
    var actas = '';
    if(this.siVeActas){
      actas = '<mat-list style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">'+
      '  <div mat-line>'+
      '    &#8226;'+
      '    <span style="font-weight: 500;">Tareas actas:</span> Notifica según la fecha de inicio y final de la tarea '+
      '    asociada a un acta.'+
      '  </div>'+
      '  <br>'+
      '  <div mat-line>'+
      '    &#8226;'+
      '    <span style="font-weight: 500;">Validación de cumplimiento de actividad:</span> Envía notificación de actividades '+
      '    con un cumplimiento del 100%.'+
      '  </div>'+
      '  <br>'+
      '  <div mat-line>'+
      '    &#8226;'+
      '    <span style="font-weight: 500;">Envió de acta al equipo:</span> Al dar click en el icono de enviar se envía '+
      '    el archivo adjunto al dar de alta el acta con copia a todo el equipo '+
      '    que intervenga en las actividades.'+
      '  </div>'+
      '</mat-list>';
    }
    this.html = '<div id="informacion">'+
    '<div class="alinIzq" style="width: 35vw; text-align: justify;">'+
    'Al encender las notificaciones, la plataforma enviará a travez de correo electrónico el cumplimiento de lo que corresponda.'+
    '<br>'+
    '<mat-list style="text-align: justify; margin-left: 2vw; margin-right: 2vw;">'+
    '  <div mat-line>'+
    '    &#8226;'+
    '    <span style="font-weight: 500;">Indicadores:</span> Está notificación se enviará al responsable del '+
    '    indicador mencionando que debe dar seguimiento.'+
    '  </div>'+
    '  <br>'+
    '  <div mat-line>'+
    '   &#8226;'+
    '    <span style="font-weight: 500;">Tareas de proyectos:</span> notificaciones enviadas a los responsables '+
    '    de cumplir tareas en las fechas acordadas para cada tarea '+
    '    asociada a los proyectos.'+
    '  </div>'+
    '  <br>'+
    '  <div mat-line>'+
    '    &#8226;'+
    '    <span style="font-weight: 500;">Reportes seguimiento:</span> se notifica a una lista de correos que '+
    '    debe introducir un responsable, que está disponible el reporte '+
    '    general de avances, mismo que podrán consultar y descargar.'+
    '  </div>'+
    '</mat-list>'+
    actas +
    '</div>'+
    '</div>';
    Swal.fire({
      title: 'Información',
      html: this.html,
      showConfirmButton: false,
      width: 'auto',
    });
  }
  /** vizualizar imagen */
  public vizualizarImagen(){
    if(this.bufferArray.length !== 0){
      const mediaTypei = 'image/'+this.bufferArray[0].tipo;
      const blobi = new Blob([this.bufferArray[0].buffer], { type: mediaTypei });
      const url = URL.createObjectURL(blobi); 
      Swal.fire({
        imageUrl: url,
        imageAlt: 'Logo de la institución',
        showConfirmButton: false,
      });
    }
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.agrgearRes){
        nombre = this.formNuevo.value.puestoCargo;
      }else if(this.editarRes){
        nombre = this.formEditar.value.puestoCargo;
      }
      if(this.agrgearRes){
        const existe = this.responsables.find(res => procesamiento.procesamiento_Texto(res.name).toLowerCase() === nombre.toLowerCase())
        if(existe) return {isValid:true};
      }else if(this.editarRes){
        const existe = this.responsables.find(res => 
          procesamiento.procesamiento_Texto(res.name).toLowerCase() === nombre.toLowerCase() && res.id !== this.idArea
        );
        if(existe) return {isValid:true};
      }      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formNuevo.hasError('isValid');
  }
  public nombreEditExiste(): boolean{    
    return  this.formEditar.hasError('isValid');
  }
}
