<div class="w3-container w3-contenedor-template" style="height: 7vh; background-color: #F9F9F9;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }}
          </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{nombre}} </span>
      </div>
    </div>

  </div>
</div>

<div style="display: flex; flex-wrap: nowrap; width: 100%; padding: 5px;">
  <div style="width: 300px; padding: 0 24px;">
    <div style="padding: 10px 0;"><span>Elementos para imprimir: </span></div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_introduccion" [(ngModel)]="imprimirIntro">Introducción</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_metodologia" [(ngModel)]="imprimirMetodologia">Metodología</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_filolosofia" [(ngModel)]="imprimirFilosofia">Filosofía</mat-slide-toggle>
    </div>
    <div class="togElement" *ngIf="verFodaV1">
      <mat-slide-toggle labelPosition="before" id="st_foda" [(ngModel)]="imprimirFoda">FODA</mat-slide-toggle>
    </div>
    <div class="togElement" *ngIf="verFodaV2">
      <mat-slide-toggle labelPosition="before" id="st_fodav2" [(ngModel)]="imprimirFodaV2">PESTEL</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_objetivos" [(ngModel)]="imprimirObjetivos">Objetivos</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_indicadores" [(ngModel)]="imprimirIndicadores">Indicadores</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_cmi" [(ngModel)]="imprimirCmi">CMI</mat-slide-toggle>
    </div>
    <div class="togElement">
      <mat-slide-toggle labelPosition="before" id="st_planOperativo" [(ngModel)]="imprimirPlanOperativo">Plan operativo</mat-slide-toggle>
    </div>
  </div>
  <div style="width: 75vw;">
    <div id="cont_template1" class="w3-container w3-contenedor-template " style="height: 85vh;">
      <div class="w3-row w3-contenedor-template " style="height: 41vh;">
        <div class="w3-row ">
          <div class="w3-container" style="height: 10vh;">
            <div class="w3-row ">
              <mat-accordion class="example-headers-align">
                <mat-expansion-panel hideToggle expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="color: #808080;">
                      Introducción
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                      Introducción
                      <mat-icon>account_circle</mat-icon>
                    </mat-panel-description> -->
                  </mat-expansion-panel-header>
    
                  <mat-form-field class="w3-input" style="border: none;">
                    <!-- <mat-label>Introducción</mat-label> -->
                    <textarea #txt_introduccion id="txt_introduccion" matInput cols="40" rows="20" [placeholder]="txt_introduccion.value === '' ? 'Escribe una introducción breve de tu institución' : ''" ></textarea>
                  </mat-form-field>
                  
                  
                  <mat-action-row>
                    <!-- <div class="w3-row w3-border-top w3-border-expander"> -->
                      <button (click)="guardarIntroPlanificacion()" mat-button style="font-size: .8rem; float: right; background-color: #ED7D31; color: white;">Guardar introducción</button>
      
                    <!-- </div> -->

                  </mat-action-row>
                </mat-expansion-panel>
                <div style="float: right;">
                  <ng-template [ngIf]="isDownloadingPdf"><div style="padding-top: 15px; font-size: .6rem; color: #808080;">Por favor espere...</div></ng-template>
    
                  <ng-template [ngIf]="!isDownloadingPdf">
                    <button
                      style="display: flex; flex-wrap: wrap; height: 50px; width: 100px; align-items: center; justify-content: center; background-color: transparent; border: none; cursor: pointer;"
                      (click)="generarReporte()">
                      <span style="font-size: .6rem; color: #808080;">Descargar reporte</span>
                      <mat-icon  style="font-size: 3rem; color: #ED7D31;">cloud_download</mat-icon>
                    </button>
                  </ng-template>
    
                </div>
                <div id="floa_img_cont" style="display: none;">
                  <img id="floa_img" #floa_img style="width: 500px;" src="assets/img/reportes/FODA.png" alt="">
                </div>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="cmi_img_container" style="display: none; width: 800px;">
  <div #cmi_img id="cmi_img" class="w3-row" style="background-color: white; justify-content: center; width: 800px;">
    <ng-container *ngFor="let per of perspectivas; let i = index">
      <div class="imagenPer" style="padding: 20px;">
        <img src="{{iconoPerspectivas(i, per)}}" style="max-width: 140px;">
        <div style="text-align: center; color: #114C98; font-size: 1.2rem; padding: 25px 0; max-width: 140px;">{{per.nombre ?? ''}}</div>
      </div>
    </ng-container>
  </div>
</div>
