import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Metas{
  public indicatorId?: Number;
  public period?: Date;
  public value?: Number;
  public real?: Number;
}

export class comment{
  comment?: string;
  created_at?: String;
}

export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 
@Injectable({
  providedIn: 'root'
})
export class ObjetivosService {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
  .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }
  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getSemaforos(): Observable<any>{
    const apiURL = this.apiURL+"semaforo";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getIndicadores(): Observable<any>{
    const apiURL = this.apiURL+"indicador";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAdicionales(): Observable<any>{
    const apiURL = this.apiURL+"adicional";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAreas(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

  public patchMetas(meta: any): Observable<any>{
    const met: Metas = {
      real: meta.real,
    }
    const body = JSON.stringify(met);
    const apiURL = this.apiURL+"meta/"+Number(meta.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public patchAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      amount: Number(adicional.amount),
      files: adicional.files,
      note: adicional.note,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public postFile(fil: any, idAd: number, fecha: any): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', fil);
    formData.append('created_at', fecha);

    const apiURL = this.apiURL+"adicional/"+idAd+"/file";
    return this.http.post(`${apiURL}`, formData, {headers: this.headersAr});
  }
  public getFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+idAd+"/file/"+idFil;
    return this.http.get(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
  public deleteFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+idAd+"/file/"+idFil;
    return this.http.delete(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
}