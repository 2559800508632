<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ "menu."+dataService.currentModule | translate }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ "menu."+dataService.currentSubmodule | translate }} </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios">
              {{ "filtros.periodo" | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); anioChange(child.periodo);">
                        <div style="cursor: pointer;">
                            <mat-checkbox [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                            (change)="anioChange(child.periodo);"
                            [checked]="child.check" type="checkbox"
                            (click)="$event.stopPropagation();"
                            aria-label="First checkbox">
                            {{ 'filtros.'+child.mes | translate}}
                            </mat-checkbox>
                        </div>
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" >
                        <span style="margin-left: 5px; margin-right: 5px;">{{ 'filtros.'+mes.mes | translate }}-{{mes.anio}}</span>
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">

</div>
