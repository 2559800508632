import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Meses, obtenerError, obtenerTamMax } from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProyectosestrategicosService } from './../../../services/seguimiento/proyectosestrategicos/proyectosestrategicos.service';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

function wait(espera_segundos) {
  var espera = espera_segundos * 1000
  const tiempo_inicio = Date.now();
  let tiempo_actual= null;
  do {
    tiempo_actual= Date.now();
  } while (tiempo_actual - tiempo_inicio < espera);
}
@Component({
  selector: 'app-proyectosestrategicos',
  templateUrl: './proyectosestrategicos.component.html',
  styleUrls: ['./proyectosestrategicos.component.css']
})
export class ProyectosestrategicosComponent implements OnInit {

  private idPlanificacion: number = 0;

  //Variables de datos
  public perspectives: any = [];
  public aniosDisponibles: any = [];
  public aniosDisponiblesTot: any = [];
  private semaforo: any = [];
  public objectives: any = [];
  public programas: any = [];
  public responsables: any = [];
  public responsables2: any = [];
  public responsablesAct: any = [];
  public responsablesAct2: any = [];
  public proyectos: any = [];
  private proyectosFin: any = [];
  public actividades: any = [];
  public actividadesFull: any = [];
  public proyectosMostrar: any = [];
  private diasLab: any;
  private fechaAct = new Date();
  private edicion: boolean;
  public Periodo = '';
  public mesMos = '';
  public aniMos = '';
  public perspectiva = '';
  public objetivo = '';
  public programa = '';
  public expandirProg = false;

  //filtros seleccionables
  public perSelect: any;
  public objSelect: any;
  public proSelect: any;
  public mostrarTable = false;
  public mostrarPeriodo =  true;

  // Filtros
  public todasPer = true;
  public todasPerAct = true;

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

/** Lectura de archivos */
  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = [];
  message = '';
  imageName = "";

  public  ordenamiento = 'id';

  /** click izquierdo */
  

  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition =  {x: '0', y: '0'};
  private adDelete : any;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private proyectosestrategicosService: ProyectosestrategicosService,
    private excelService: ExcelService,
    private translate: TranslateService,
    ) {
    var param = this.rutaActiva.snapshot.params;
    this.idPlanificacion = Number(param['planeacion']);
    this.obtenerDatos();
  }

  ngOnInit(): void {
  }
  private cambioANull(){
    for(const act of this.actividadesFull){
      const fechafin = this.obtFech(act.end_date);
      if( fechafin.getFullYear() === 2024 && fechafin.getMonth() === 0 && fechafin.getDay() === 1 && Number(act.id) !== 670){
        const hisE = act.historico.filter(h => h.periodo === 'ene-24')[0];
        const hisC = act.historico.filter(h => h.periodo === 'dic-23')[0];
        act.end_date = new Date('12/31/2023');
        hisC.planeado = 100;
        /*this.proyectosestrategicosService.DeleteHistorico('',act.id,hisE.id).subscribe(response =>{
          this.proyectosestrategicosService.PatchHistoricoPlaneado(hisC, act.id, hisC.id).subscribe(response =>{
            this.proyectosestrategicosService.patchActividades(act).subscribe(response =>{
            
            });
          });
        });*/
        //wait(0.2); // Parche para que furule todo;
      }
    }
    
  }
  private arreglos(aux){
    for(const act of aux){
      const historico = Meses.obtenerPlaneado(this.obtFech(act.initial_date), this.obtFech(act.end_date), this.diasLab);
      if(historico.length !== act.historico.length){
        for(const his of historico){
            const existe = act.historico.filter(h => h.periodo === his.periodo);
          if(existe.length===0){
            //console.log("🚀 ~ act =>", act, his)
            /*this.proyectosestrategicosService.postHistorico(his, Number(act.id)).subscribe(response =>{
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
            wait(0.3)*/
          }else if(existe.length > 1){
            existe.sort(function (a: any, b: any) {
              if (Number(a.id) > Number(b.id)) {
                return 1;
              }
              if (Number(a.id) < Number(b.id)) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
            for(var i=0; i<existe.length; i++){
              if(i === 0){
              }else{
                /*this.proyectosestrategicosService.DeleteHistorico(existe[i], act.id, existe[i].id).subscribe(response=>{

                });*/
              }
            }
          }
        }
        for(const hist of act.historico){
          const esta = historico.filter(h => h.periodo === hist.periodo);
          if(esta.length === 0){
            if(hist.ejecutado===null){
              /*this.proyectosestrategicosService.DeleteHistorico(hist, act.id, hist.id).subscribe(response=>{
              });*/
            }  
          }
        }
      }
    }
  }
  // Obtención de datos
  private obtenerDatos(){
    this.proyectosestrategicosService.getPlaneacion().subscribe(response => {
      this.obtenerFiltros(response);
      this.proyectosestrategicosService.getObjectives().subscribe(response =>{
        this.obtObjectives(response);
        this.proyectosestrategicosService.getProyectos().subscribe(response =>{
          this.obtProyectos(response);
          this.proyectosestrategicosService.getActividades().subscribe(response =>{
            this.obtActividades(response);
            this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
              const proyect = this.obtenerProyectosPlaneacion(response);
              this.obtenerProyectosPeriodo(proyect);
              this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
                this.obtAdditions(response);
              });
            });
          });
        });
        this.proyectosestrategicosService.getProgramas().subscribe(response =>{
          this.obtProgramas(response);
        });
      });
    });
  }
  private obtenerProyectosPlaneacion(array: any){
    var aux = [];
    for(const pro of array){
      for(const prog of this.objectives){
        /*if(pro.objectives[0].id === prog.id){
          aux.push(pro);
        }*/
        if(pro.objectives.length !== 0 && pro.objectives[0].id === prog.id){
          aux.push(pro);
        }
      }
    }
    return aux;
  }
  private obtenerProyectosPeriodo(array: any){
    array = this.ordenamientoId(array);
    var proyectosPeriodo = [], actividadesPeriodo = [];
    var auxAct = []
    for(const pro of array){
      for(const act of pro.activities){
        if(act.history.length !== 0){
          var hist = [];
          for(const his of act.history){
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: his.executed,
              id: his.id,
            });
          }
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else if(this.actividadIncompleta(act)){
          var hist = [];
          hist.push({
            periodo: this.Periodo,
            planeado: 100,
            ejecutado: null,
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: true,
          });
        }else{
          var hist = [];
          hist.push({
            periodo: this.Periodo,
            planeado: '-',
            ejecutado: '-',
            id: 0,
          });
          auxAct.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });    
          actividadesPeriodo.push({
            additions: [],
            end_date: this.obtFech(act.end_date),
            id: act.id,
            initial_date: this.obtFech(act.initial_date),
            name: act.name,
            project: pro,
            responsible: act.responsible,
            historico: hist,
            editable: false,
          });       
        }
      }
      if(auxAct.length !== 0){
        proyectosPeriodo.push({proyecto: pro, actividades: auxAct, check: false,fecha_ini:undefined, fecha_fin: undefined, planeado: [], ejecutado: []});
      }
      auxAct = [];
    }
    proyectosPeriodo = this.obtfechasProy(proyectosPeriodo);
    this.proyectosFin = proyectosPeriodo;
    this.actividades = actividadesPeriodo;
    //this.cambioANull();
  }
  private obtenerFiltros(planeacion: any){
    for(const plan of planeacion){
      if(Number(plan.id) === this.idPlanificacion){
        this.edicion = plan.isActive;
        this.diasLab = plan.business_days;
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        this.aniosDisponiblesTot = Meses.obtenerPeriodosFull(new Date(plan.initial_date), new Date(plan.end_date));
        var per = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1]; 
        this.Periodo = per.periodo;
        this.mesMos = per.mes;
        this.aniMos = per.anio;
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            check: false,
            id: per.id,
          })
        }
        this.perspectives = this.ordenamientoId(this.perspectives);
        const sema = this.ordenamientoSem(plan.signals);
        if( Number(sema[sema.length-1].value_01) > 100){
          const valFinal = Number(sema[sema.length-1].value_01); 
          for(const sem of sema){
            this.semaforo.push({
              valIn: Math.round((Number(sem.value_00)*100)/valFinal) === 0? Math.round((Number(sem.value_00)*100)/valFinal):Math.round((Number(sem.value_00)*100)/valFinal)+0.1,
              valfin: Math.round((Number(sem.value_01)*100)/valFinal),
              color: sem.color[0],
            });
          }      
        }else{
          for(const sem of sema){
            this.semaforo.push({
              valIn: Number(sem.value_00),
              valfin: Number(sem.value_01),
              color: sem.color[0],
            });
          }
        }
      }
    }
  }
  private obtenerResponsablesDin(){
    var aux = [], aux2 = []; 
    for(const pro of this.proyectosMostrar){
      const res = aux.filter(res => res.id === pro.proyecto.responsible.id);
      if(res.length === 0){
        aux.push({
          id: pro.proyecto.responsible.id,
          name: pro.proyecto.responsible.position,
          check: true,
        });
      }
      for(const act of pro.actividades){
        const resAct = aux2.filter(res => res.id === act.responsible.id);
        if(resAct.length === 0){
          if(this.dataService.filtradoIndicadores){
            if(act.responsible.email === this.dataService.currentUser){
              aux2.push({
                id: act.responsible.id,
                name: act.responsible.position,
                check: true,
              });
            }
          }else{
            aux2.push({
              id: act.responsible.id,
              name: act.responsible.position,
              check: true,
            });
          }
        }
      }
    }
    this.responsables = this.ordenamientoAlf(aux);
    this.responsables2 = new MatTableDataSource(this.responsables);
    this.responsablesAct = this.ordenamientoAlf(aux2);
    this.responsablesAct2 = new MatTableDataSource(this.responsablesAct);
  }
  private obtObjectives(array: any){
    var aux = [];
    for(const obj of array){
      for(const per of this.perspectives){
        if(obj.perspective.id === per.id){
          aux.push(obj);
        }
      }
    }
    this.objectives = [];
    this.objectives = this.ordenamientoId(aux);
  }
  private obtProgramas(array: any){
    var aux = [];
    for(const prog of array){
      for(const obj of this.objectives){
        if(prog.objectives.length !== 0 && prog.objectives[0].id === obj.id){
          aux.push({
            id: prog.id,
            name: prog.name,
            description: prog.description,
            projects: this.ordenamientoId(prog.projects),
            objectives: this.ordenamientoId(prog.objectives),
            avance: 0,
            atraso: 0,
          });
        }
      }      
    }
    this.programas = [];
    this.programas = this.ordenamientoObjId(this.ordenamientoId(aux));
  }
  private obtProyectos(array: any) {
    var aux = [];
    for(const pro of array){
      for(const prog of this.objectives){
        /*if(pro.objectives.length === 0){
        }*/
        if(pro.objectives.length !== 0 && pro.objectives[0].id === prog.id){
          aux.push(pro);
        }
      }
    }
    this.proyectos = this.ordenamientoId(aux);
  }
  private obtActividades(array: any){
    var aux = [];
    for(const act of array){
      for(const pro of this.proyectos){
        if(act.project && act.project.id === pro.id){
          var hist = [];
          for(const his of act.history){
            hist.push({
              periodo: his.period,
              planeado: Number(his.planned),
              ejecutado: his.executed,
              id: his.id,
            });
          }
          aux.push({
            additions: [],
            end_date: act.end_date,
            id: act.id,
            initial_date: act.initial_date,
            name: act.name,
            project: act.project,
            responsible: act.responsible,
            historico: hist,
          });
        }
      }
    }
    //this.arreglos(aux);
    for(const act of aux){
      if(act.historico.length === 0){
        act.historico = Meses.obtenerPlaneado(this.obtFech(act.initial_date), this.obtFech(act.end_date), this.diasLab);
        for(const hist of act.historico){
          this.proyectosestrategicosService.postHistorico(hist, Number(act.id)).subscribe(response =>{
            hist.id = response.id;
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
          wait(0.2); // Parche para que furule todo;
        }
      }
    }
    this.actividadesFull = this.ordenamientoId(aux);
    aux = [];
    var aux2 = [];
    for(const pro of this.proyectos){
      for(const act of this.actividades){
        if(pro.id === act.project.id){
          aux2.push(act);
        }
      }
      if(aux2.length){
        aux.push({proyecto: pro, actividades: aux2, check: false,fecha_ini:undefined, fecha_fin: undefined, planeado: [], ejecutado: []});
      }
      aux2 = [];
    }
    aux = this.obtfechasProy(aux);
    //this.proyectosFin = aux;
  }
  private obtAdditions(array: any){
    for(const act of this.actividades){
      var aux = [];
      for(const ad of array){
        if( ad.activity !== null && ad.activity.id === act.id){
          var fil = [];  
          if(ad.files && ad.files.length !== 0){
            for(const f of ad.files){
              const date = this.obtFech(f.created_at);
              fil.push({
                id: f.id,
                name: f.name,
                description: f.description,
                path: f.path,
                created_at: date,
                updated_at: f.updated_at,
              });
            }
          }
          aux.push({
            amount: ad.amount,
            comments: ad.comments,
            id: ad.id,
            name: ad.name,
            note: ad.note,
            type: ad.type,
            files: fil,
          });
        }
      }
      aux = this.ordenamientoAd(aux);
      act.additions = aux;
    }
    for(const pro of this.proyectosFin){
      for(const act of pro.actividades){
        var aux = [];
        for(const ad of array){
          if( ad.activity !== null && ad.activity.id === act.id){
            var fil = [];  
            if(ad.files && ad.files.length !== 0){
              for(const f of ad.files){
                const date = this.obtFech(f.created_at);
                fil.push({
                  id: f.id,
                  name: f.name,
                  description: f.description,
                  path: f.path,
                  created_at: date,
                  updated_at: f.updated_at,
                });
              }
            }
            aux.push({
              amount: ad.amount,
              comments: ad.comments,
              id: ad.id,
              name: ad.name,
              note: ad.note,
              type: ad.type,
              files: fil,
            });
          }
        }
        aux = this.ordenamientoAd(aux);
        act.additions = aux;
      }
    }
    this.obtenerProy('cam');
    this.obtenerResponsablesDin();
    if(this.dataService.filtradoIndicadores){
      this.obtenerProy('all');
      this.obtenerResponsablesDin();
    }
    this.mostrarTable = true;
  }
  public obtfechasProy(array: any){
    for(const pro of array){
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for(const act of pro.proyecto.activities){
        var fecha_inicio = this.obtFech(act.initial_date), fecha_fin = this.obtFech(act.end_date)
        if(pro.fecha_ini === undefined){
          pro.fecha_ini = fecha_inicio;
        }else{          
          if(pro.fecha_ini > fecha_inicio){
            pro.fecha_ini = fecha_inicio;
          }
        }
        if(pro.fecha_fin === undefined){
          pro.fecha_fin = fecha_fin;
        }else{
          if(pro.fecha_fin < fecha_fin){
            pro.fecha_fin = fecha_fin;
          }
        }
      }
      pro.ejecutado = Meses.obtenerAvance(pro.fecha_ini, pro.fecha_fin);
      pro.planeado = Meses.obtenerPlaneado(pro.fecha_ini, pro.fecha_fin,this.diasLab);
    }

    return array;
  }
  /* Revisión de proyectos con cumplimiento o no */
  private actividadIncompleta(act: any){
    var mes = Meses.getMesesS(this.Periodo.split('-')[0])<10?'0'+Meses.getMesesS(this.Periodo.split('-')[0]):''+Meses.getMesesS(this.Periodo.split('-')[0]);
    const momentAct = moment('01-'+mes+'-20'+this.Periodo.split('-')[1], 'DD-MM-YYYY');

    var fechaFinAct;
    if((act.end_date+'').includes('Z')){
      fechaFinAct = this.obtFech(act.end_date);
    }else{
      fechaFinAct = act.end_date;
    }
    mes = (fechaFinAct.getMonth()+1)<10?'0'+(fechaFinAct.getMonth()+1):''+(fechaFinAct.getMonth()+1);
    var dia = fechaFinAct.getDate()<10?'0'+fechaFinAct.getDate():''+fechaFinAct.getDate();
    var anio = fechaFinAct.getFullYear();
    const momnetFinAct = moment(dia+'-'+mes+'-'+anio, 'DD-MM-YYYY');
    
    if(momentAct.isAfter(momnetFinAct) && act.completed !== true){
      return true;
    }
    return false;
  }
  /* Pintar bordes */
  public pintarBord(eje: any, plan: any){
    var porcentaje = undefined;
    var color = '';
    if(eje === 0 && plan !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro';
    }else if(eje === 0 && plan !== 0){
      porcentaje = 0;
    }else if(eje !== 0 && eje !== null){
      porcentaje = eje*100/plan;
      
    }
    if(porcentaje !== undefined){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcentaje >= valfin){
        porcentaje = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcentaje >= ab && porcentaje <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  public pintarBordPro(eje: any, plan: any){
    if(eje !== 0){
      var color = '';
      var por = eje*100/plan;
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(por >= valfin){
        por = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(por >= ab && por <= sem.valfin){
          color = sem.color;
          break;
        }
      }
      switch(color){
        case 'verde': return 'bordeVerde';
        case 'azul': return 'bordeAzul';
        case 'amarillo': return 'bordeAmarillo';
        case 'naranja': return 'bordeNaranja';
        case 'rojo': return 'bordeRojo';
        default:  return 'bordeGrey';
      }
    }
    return 'bordeGris'
  }
  /* Fechas */
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  /* Filtros */
  public anioChange(per: any){
    //document.body.style.cursor = 'wait';
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        mes.periodo === per? mes.check = true: mes.check = false;        
        mes.periodo === per? this.Periodo = mes.periodo: '';
        mes.periodo === per? this.mesMos = mes.mes: '';
        mes.periodo === per? this.aniMos = mes.anio : '';
      }
    }
    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
        //document.body.style.cursor = 'default';
      });
    });
    //this.obtenerProy('cam');
    //this.obtenerResponsablesDin();
  }
  public persChange(pers: any){
    this.todasPer = false;
    var selec = 0;
    for(const per of this.responsables){
      if(per.id === pers.id){
        per.check = !per.check;
      }
    }
    for(const per of this.responsables){
      if(per.check){
        selec++;
      }
    }
    if(selec === (this.responsables.length)){
      this.todasPer = true;
    }

    this.obtenerProy('all');
  }
  public persChangeAct(pers: any){
    this.todasPerAct = false;
    var selec = 0;
    for(const per of this.responsablesAct){
      if(per.id === pers.id){
        per.check = !per.check;
      }
    }
    for(const per of this.responsablesAct){
      if(per.check){
        selec++;
      }
    }
    if(selec === (this.responsablesAct.length)){
      this.todasPerAct = true;
    }
  }
  public selecAllPer(){
    for(const per of this.responsables){
      if(this.todasPer){
        per.check = false;
      }else{
        per.check = true;
      }
    }
    this.todasPer = !this.todasPer;
    if(this.todasPer){
      this.obtenerProy('all');
    }
  }
  public selecAllPerAct(){
    for(const per of this.responsablesAct){
      if(this.todasPerAct){
        per.check = false;
      }else{
        per.check = true;
      }
    }
    this.todasPerAct = !this.todasPerAct;
  }
  public lidSelec(){
    var selec = 0;
    for(const res of this.responsables){
      if(res.check){
        selec++;
      }
    }
    if(selec > 1)
      return true;
    return false;
  }
  public perPrograma(pro: any){
    for(const objPro of pro.objectives){
      if(this.objSelect === objPro.id){
        return true;
      }
    }
    return false;
  }
  public cambioPer(per: any){
    this.objSelect = undefined;
    this.proSelect = undefined;

    this.perspectiva = per.name;
    this.objetivo = '';
    this.programa = '';

    this.obtenerProy('cam');
    this.obtenerResponsablesDin();
  }
  public cambioObj(obj: any){
    this.proSelect = undefined;

    this.objetivo = obj.name;
    this.programa = '';

    this.obtenerProy('cam');
    this.obtenerResponsablesDin();
  }
  public cambioEst(est: any){
    this.programa = est.name;

    this.obtenerProy('cam');
    this.obtenerResponsablesDin();
  }
  /* Obtención de proyectos */
  private obtenerProy(zon){
    var auxmos = [];
    for(const pro of this.proyectosFin){
      switch(zon){
        case 'all':
          if(this.mostrarConResponsables(pro)){
            auxmos.push(pro)
          }
        break;
        case 'cam':
          if(this.mostrar(pro)){
            auxmos.push(pro)
          }
        break;
      }
    }
    this.proyectosMostrar = auxmos;
    this.ordenamientoNuevo();
  }
  public mostrar(pro: any){
    var perSelected = this.anSelected();
    var perIni = pro.fecha_ini.getFullYear();
    var perFin = pro.fecha_fin.getFullYear();
    
    var tieneAct = false;
    var actTiene = 0;
    for(const act of pro.actividades){
      if(act.editable){
        actTiene++;
      }
    }
    if(actTiene!==0){
      tieneAct = true;
    }
    /** verificación del periodo */
    var existPer = false;//
    if(!this.mostrarPeriodo){
      existPer = Meses.compararAnio(perIni, perFin, perSelected);
    }
    /** Vaerificar los objetivos */
    var selObj = false;
    if(this.objSelect !== undefined){
      for( const obj of pro.proyecto.objectives){
        if(obj.id === this.objSelect){
          selObj = true;
        }
      }
    }else{
      selObj = true
    }
    /** Vaerificar los programas */
    var selProg = false;
    if(this.proSelect){
      if(pro.proyecto.strategies[0].id === this.proSelect){
        selProg = true;
      }
    }else{
      selProg = true;
    }

    if(existPer && selObj && selProg && this.pertenece_perspective(pro) ){
      return true;
    }else if(tieneAct && selObj && selProg && this.pertenece_perspective(pro)){
      return true;
    }
    return false;
  }
  public mostrarConResponsables(pro: any){
    var perSelected = this.anSelected();
    var perIni = pro.fecha_ini.getFullYear();
    var perFin = pro.fecha_fin.getFullYear();
    
    var tieneAct = false;
    var actTiene = 0;
    for(const act of pro.actividades){
      if(act.editable){
        actTiene++;
      }
    }
    if(actTiene!==0){
      tieneAct = true;
    }

    /** verificación del periodo */
    var existPer = false;//

    if(!this.mostrarPeriodo){
      existPer = Meses.compararAnio(perIni, perFin, perSelected);
    }
    
    /** verificación del lider */
    var existeLid = false;
    for(const res of this.responsables){
      if(res.id === pro.proyecto.responsible.id && res.check){
        existeLid = true;
      }
    }
    /** Vaerificar los objetivos */
    var selObj = false;
    if(this.objSelect !== undefined){
      for( const obj of pro.proyecto.objectives){
        if(obj.id === this.objSelect){
          selObj = true;
        }
      }
    }else{
      selObj = true
    }
    /** Vaerificar los programas */
    var selProg = false;
    if(this.proSelect){
      if(pro.proyecto.strategies[0].id === this.proSelect){
        selProg = true;
      }
    }else{
      selProg = true;
    }

    /* Verificar si tiene actividades */
    var tieneActividades = true, acc = 0;
    for(const act of pro.actividades){
      if(this.existAct(act) && this.resSelected(act) && (act.editable || !this.mostrarPeriodo)){
        acc++;
      }
    }
    if(acc===0){
      tieneActividades = false
    }


    if(tieneActividades && existPer && selObj && existeLid && selProg && this.pertenece_perspective(pro) ){
      return true;
    }else if(tieneActividades && tieneAct && selObj && existeLid && selProg && this.pertenece_perspective(pro)){
      return true;
    }
    return false;
  }
  /* Pertenece a la perspectiva */
  private pertenece_perspective(pro: any){
    if(this.perSelect !== undefined){
      var obj = [];
      for(const obje of pro.proyecto.objectives){
        const om = this.objectives.filter(obb => obb.id === obje.id);
        obj = obj.concat(om);
      }
      const ex = obj.filter(obj => obj.perspective.id === this.perSelect)
      if( ex.length !== 0 ){
        return true;
      }
      return false;
    }else{
      return true;
    }
  }
  /* Abrir y cerrar los proyectos */
  public oculMost(pro: any){
    pro.check = !pro.check;
    var check = 0;
    for(const pro of this.proyectosMostrar){
      if(pro.check){
        check++;
      }
    }
    if(check === this.proyectosMostrar.length){
      this.expandirProg = true;
    }else if(check === 0){
      this.expandirProg = false;
    }
  }
  public oculMostAll(){
    for(const pro of this.proyectosMostrar){
      if(this.expandirProg){
        pro.check = false;
      }else{
        pro.check = true;
      }
    }
    this.expandirProg = !this.expandirProg;
  }
  public existAct(act: any){
    var existePeriodo = true;//false;//
    var  per_sel = '';

    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          per_sel = mes.periodo;
        }
      }
    }

    if(act.initial_date != undefined && act.end_date != undefined){
      var per1 = Meses.getMesesN(new Date(act.initial_date).getMonth()+1) + '-' + (''+new Date(act.initial_date).getFullYear()).substring(2,4);
      var per2 = Meses.getMesesN(new Date(act.end_date).getMonth()+1) + '-' + (''+new Date(act.end_date).getFullYear()).substring(2,4);
      //existePeriodo = Meses.compararPer(per1, per2, per_sel);
    }

    return existePeriodo;
  }
  public resSelected(act){
    var existeLid = false;
    for(const res of this.responsablesAct){
      if(res.id === act.responsible.id && res.check){
        existeLid = true;
      }
    }
    return existeLid;
  }
  //Escritura de datos
  public escribir_fecha(fecha: any){
    var f = new Date(fecha), fech = '';
    fech = '' + f.getDate() + ' ' + this.translate.instant('filtros.'+Meses.getMesesN(f.getMonth()+1)) + ' ' + (''+f.getFullYear()).substring(2,4);
    return fech;
  }
  public obtenerDias(fIni: any, fFin: any){
    var dias = 0, f1: any = new Date(fIni), f2: any = new Date(fFin);
    var diff = Math.round((f2-f1)/(1000*60*60*24));
    if(diff === 0){
      diff = 1;
    }
    switch(this.diasLab){
      case 'Lunes-Viernes':
        var sem = Math.trunc(diff/7);
        dias = diff - sem*2;
      break;
      case 'Lunes-Sabado':
        var sem = Math.trunc(diff/7);
        dias = diff - sem;
      break;
      case 'Lunes-Domingo':
        dias = diff;
      break;
    }
    return dias;
  }
  public escribirDat(pro: any, tip: string){
    var per = this.perSelected();
    for(const dat of pro){
      if(per === dat.periodo){
        if(tip === 'plan'){
          return dat.planeado;
        }else if(tip === 'ejec'){
          return dat.ejecutado;
        }
      }
    }
  }
  public escribirDatPro(pro: any, tip: string){
    var per = this.perSelected();
    var dias = 0, porcentajeplan = 0, porcentajeejec = 0;
    for(const act of pro.actividades){
      if(act.editable){
        dias += this.obtenerDias(act.initial_date,act.end_date);
      }
    }
    var suma = false;
    for(const act of pro.actividades){
      if(act.editable){
        if(new Date( 20+per.split('-')[1], Meses.getMesesS(per.split('-')[0])-1 ) > new Date(act.end_date)){
          suma = true;
        }
        for(const his of act.historico){
          if(his.periodo === per || suma){
            if(suma){
              suma = false;
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((100*pesoAct/100).toFixed(2));
              let perAct;
              if(!this.actividadIncompleta(act)){
                perAct = Meses.obtnerPer( new Date(act.end_date) );
              }else{
                perAct = this.Periodo;
              }
              var ejeAct = 0;
              for(const h of act.historico){
                if(h.periodo === perAct){
                  ejeAct = h.ejecutado;
                  break;
                }
              }
              porcentajeejec += Number((ejeAct*pesoAct/100).toFixed(2));
            }else{
              var diasAct = this.obtenerDias(act.initial_date,act.end_date);
              var pesoAct = Number((diasAct*100/dias).toFixed(2));
              porcentajeplan += Number((his.planeado*pesoAct/100).toFixed(2));
              porcentajeejec += Number((his.ejecutado*pesoAct/100).toFixed(2));
            }
          }
        }
      }
    }
    if(tip === 'plan'){
      if( Number(porcentajeplan.toFixed(2)) >= 99.99 ){
        return 100;
      }
      return Number(porcentajeplan.toFixed(2));
    }
    if( Number(porcentajeejec.toFixed(2)) >= 100 ){
      return 100;
    }
    return Number(porcentajeejec.toFixed(2));
  }
  public editar(his: any, idAct: any){
    var perSel = '';
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSel = mes.periodo;
        }
      }
    }
    var perAct = Meses.obtnerPer(this.fechaAct);
    var perAnt = Meses.obtnerPerAnt(this.fechaAct);
    if(this.edicion){
      this.dat(his,perSel,idAct);
      return ;
    }else if(Meses.compararPer(perAnt,perAct,perSel)){
      this.dat(his,perSel,idAct)
    }else{
      Swal.fire({
        title: ''+this.translate.instant('seguimiento.proyectos.swal2'),
        text: ''+this.translate.instant('seguimiento.proyectos.swal3'),
        confirmButtonColor: '#ff6b00',
        confirmButtonText: ''+this.translate.instant('swal.acep')
      });
    }
    
  }
  public async dat(his: any, perSel: any, idAct: any){
    var inputValue = 0, introdujo: any, guardo = false;
    
    for(const per of his){
      if(per.periodo === perSel){
        inputValue = per.ejecutado;
      }
    }

    introdujo = await Swal.fire({
      title: ''+this.translate.instant('seguimiento.proyectos.swal4'),
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (Number(value) > 100) {
          return ''+this.translate.instant('seguimiento.proyectos.swal5');
        }
        guardo = true;
        return '';
      }
    });

    if(guardo && his[0].id !== 0){
      for(const per of his){
        if(per.periodo === perSel){
          if(introdujo.value !== ''){
            per.ejecutado = Number(introdujo.value);
          }else{
            per.ejecutado = null;
          }
          this.proyectosestrategicosService.PatchHistorico(per, Number(idAct),Number(per.id)).subscribe(response =>{
            this.proyectosestrategicosService.getActividadesId(Number(idAct)).subscribe(response =>{
              var aux = [];
              for(var act of this.actividadesFull){
                if(Number(act.id) === Number(idAct)){
                  var hist = [];
                  for(const his of response.history){
                    hist.push({
                      periodo: his.period,
                      planeado: Number(his.planned),
                      ejecutado: his.executed,
                      id: his.id,
                    });
                  }
                  aux.push({
                    additions: [],
                    end_date: act.end_date,
                    id: act.id,
                    initial_date: act.initial_date,
                    name: act.name,
                    project: act.project,
                    responsible: act.responsible,
                    historico: hist,
                  });
                }else{
                  aux.push(act);
                }
              }
              this.actividadesFull = aux;
            });
            Swal.fire({
              title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
              text: '',
              icon: 'success',
              showConfirmButton: false,
              timer: this.dataService.tiempo
            });
          }, error =>{
            Swal.fire({
              title: ''+this.translate.instant('seguimiento.proyectos.swal1'),
              text: '',
              confirmButtonColor: '#ff6b00',
              confirmButtonText: ''+this.translate.instant('swal.acep')
            });
          });
        }
      }
    }else if(guardo && his[0].id === 0){
      his[0].ejecutado = Number(introdujo.value);
      var hist = his[0];
      this.proyectosestrategicosService.postHistorico(hist, Number(idAct)).subscribe(response =>{
        this.proyectosestrategicosService.getActividadesId(Number(idAct)).subscribe(response =>{
          var aux = [];
          for(var act of this.actividadesFull){
            if(Number(act.id) === Number(idAct)){
              var hist = [];
              for(const his of response.history){
                hist.push({
                  periodo: his.period,
                  planeado: Number(his.planned),
                  ejecutado: his.executed,
                  id: his.id,
                });
              }
              aux.push({
                additions: [],
                end_date: act.end_date,
                id: act.id,
                initial_date: act.initial_date,
                name: act.name,
                project: act.project,
                responsible: act.responsible,
                historico: hist,
              });
            }else{
              aux.push(act);
            }
          }
          this.actividadesFull = aux;
        });
        his[0].id = response.id;
        Swal.fire({
          title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      })
    }
  }
  public escribirAdicional(adic: any){
    if(adic != undefined){
      switch(adic.type){
        case 'texto':
          return this.obtComment(adic.comments);
        case 'moneda':
          return this.formatearnormal(adic.amount);
        case 'numero':
          return this.obtCommentNumero(adic.comments);
        case 'archivo':
          var per  = this.perSelected();
          for(const file of adic.files){
            if( per === Meses.obtnerPer(file.created_at)){
              return file.name.split('-')[file.name.split('-').length - 1];
            }
          }
          return '';
      }
    }
  }
  private obtCommentNumero(com : any){
    for(const comen of com){
      var per = 0;
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            per = an.anio;
          }
        }
      }
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
  private obtComment(com : any){
    for(const comen of com){
      var per =  this.perSelected();
      var percom = Meses.obtnerPer(new Date(comen.created_at));
      if(per === percom){
        return comen.comment;
      }
    }
    return '';
  }
  public async edicionAd(adic: any, id: any, event?: any){
    var inputValue : any, introdujo: any, guardo = false;

    switch(adic.type){
      case 'texto':
        inputValue =  this.obtComment(adic.comments);
        introdujo = await Swal.fire({
          title: ''+this.translate.instant('seguimiento.proyectos.swal7'),
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: ''+this.translate.instant('swal.acep'),
          cancelButtonText: ''+this.translate.instant('swal.cerr'),
          inputValidator: (value) => {
            if (!value) {
              return ''+this.translate.instant('seguimiento.proyectos.swal8');
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'moneda':
        inputValue =  adic.amount;
        introdujo = await Swal.fire({
          title: ''+this.translate.instant('seguimiento.proyectos.swal9'),
          text: "",
          input: 'number',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: ''+this.translate.instant('swal.acep'),
          cancelButtonText: ''+this.translate.instant('swal.cerr'),
          inputValidator: (value) => {
            if (!value) {
              return ''+this.translate.instant('seguimiento.proyectos.swal8');
            }
            guardo = true;
            return '';
          }
        });
      break;
      case 'archivo':
        inputValue =  adic.file;
        introdujo = await Swal.fire({
          title: ''+this.translate.instant('seguimiento.proyectos.swal10'),
          text: "",
          input: 'file',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: ''+this.translate.instant('swal.acep'),
          cancelButtonText: ''+this.translate.instant('swal.cerr'),
          inputValidator: (value) => {
            if (!value) {
              return 'Ingerese un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }

    if(guardo){
      switch(adic.type){
        case 'texto':
          var existe = false;
          var per =  this.perSelected();
          for(const comen of adic.comments){
            var percom = Meses.obtnerPer(new Date(comen.created_at));
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.proyectosestrategicosService.patchComment(comen, adic.id).subscribe(response =>{
                Swal.fire({
                  title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          if(!existe){
            var date = (new Date(Meses.getMesesS(per.split('-')[0])+'-1-'+per.split('-')[1]));
            adic.comments.push({
              comment: introdujo.value,
              created_at: date.toISOString(),
            });
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString()
            }
            this.proyectosestrategicosService.postComment(aux, adic.id).subscribe(response =>{
              this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
                this.obtAdditions(response);
                Swal.fire({
                  title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
                  text: '',
                  showConfirmButton: false,
                  icon: 'success',
                  timer: this.dataService.tiempo
                });
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        break;
        case 'moneda':
          adic.amount = introdujo.value;
          this.proyectosestrategicosService.patchAdicionales(adic).subscribe(response =>{
            if(!response){
              Swal.fire({
                title: ''+this.translate.instant('seguimiento.proyectos.swal1'),
                text: '',
                confirmButtonColor: '#ff6b00',
                confirmButtonText: ''+this.translate.instant('swal.acep')
              });
            }else{
              Swal.fire({
                title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            }
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        break;
        case 'archivo':
          this.progressInfo = [];
          introdujo.value ? this.imageName = introdujo.value.name : this.imageName = introdujo.value.length + " archivos";
          this.selectedFiles = introdujo.value;
          var per  = this.perSelected();
          var existe = false;
          if( this.selectedFiles["size"] > obtenerTamMax.getPeso() ){
            Swal.fire({
              title: obtenerTamMax.getTexto(),
              text: '',
              showConfirmButton: false,
              icon: 'error',
              timer: this.dataService.tiempo,
              didClose: () => {
                this.edicionAd(adic, id, event);
              }
            });
            
          }else{
            if(adic.files.length !== 0){
              for(const fil of adic.files){
                if( fil.periodo === per){
                  existe = true;
                }
              }
            }
            if(!existe){
              var per = this.perSelected();
              document.getElementById('cargando_arch')['style'].display = 'block';
              const fecha = new Date(Meses.getMesesS(per.split('-')[0])+'/1/'+per.split('-')[1]);
              this.proyectosestrategicosService.postFile(this.selectedFiles, adic.id, fecha.toISOString()).subscribe(response =>{
                this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
                  this.obtAdditions(response);
                  document.getElementById('cargando_arch')['style'].display = 'none';
                  Swal.fire({
                    title: ''+this.translate.instant('seguimiento.proyectos.swal6'),
                    text: '',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: this.dataService.tiempo
                  });
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
        break;
      }
    }else{
      Swal.fire({
        title: ''+this.translate.instant('seguimiento.proyectos.swal11'),
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public abirPDF(ad, event?: any){
    const per = this.perSelected();
    for(const fil of ad.files){
      if(Meses.obtnerPer(fil.created_at) === per){
        this.proyectosestrategicosService.getFile(ad.id, fil.id).subscribe(response =>{
          switch(fil.name.split('.')[fil.name.split('.').length-1]){
            case 'pdf':
              const mediaType = 'application/pdf';
              const blob = new Blob([response], { type: mediaType });
              const nav = (window.navigator as any);

              if (nav.msSaveOrOpenBlob)  {
                nav.msSaveOrOpenBlob(blob, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blob);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            case 'png':
            case 'jpg':
              const mediaTypei = 'image/'+fil.name.split('.')[fil.name.split('.').length-1];
              const blobi = new Blob([response], { type: mediaTypei });
              const navi = (window.navigator as any);

              if (navi.msSaveOrOpenBlob)  {
                navi.msSaveOrOpenBlob(blobi, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobi);
                a.href = url;
                a.download = fil.name;
                document.body.appendChild(a);
                window.open(""+a.href,"_blank");
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
            default:
              const mediaTyped = 'aplication/'+fil.name.split('.')[fil.name.split('.').length -1];
              const blobd = new Blob([response], { type: mediaTyped });
              const navd = (window.navigator as any);

              if (navd.msSaveOrOpenBlob)  {
                navd.msSaveOrOpenBlob(blobd, fil.name);
              } else {
                const a = document.createElement('a');
                const url = URL.createObjectURL(blobd);
                a.href = url;
                a.download = fil.name.split('-')[fil.name.split('-').length -1];
                document.body.appendChild(a);
                a.click();

                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);  
                }, 0); 
              }
            break;
          }
        });
      }
    }
  }
  public escribirAdicionalPro(pro: any, i: number){
    var presTot = 0;
    var presTotN = 0;
    for(const act of pro){
      for(const ad of act.additions){
        if(ad.type === 'moneda'){
          presTot += Number(ad.amount);
        }
      }
    }
    if(this.actividades[0].additions[i].type === 'moneda'){
      return this.formatearnormal(presTot);
    }else{
      return '';
    }
  }
  //eliminar archivos
  public deleteArchivo(){
    const per = this.perSelected();
    Swal.fire({
      title: ''+this.translate.instant('seguimiento.proyectos.swal12'),
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: ''+this.translate.instant('swal.acep'),
      cancelButtonText: ''+this.translate.instant('swal.cerr')
    }).then((result) => {
      if (result.isConfirmed) {
        for(const fil of this.adDelete.files){
          if(Meses.obtnerPer(fil.created_at) === per){
            this.proyectosestrategicosService.deleteFile(this.adDelete.id, fil.id).subscribe(response =>{
              this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
                this.obtAdditions(response);
                Swal.fire({
                  title: ''+this.translate.instant('seguimiento.proyectos.swal13'),
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            });
          }
        }
      }
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }  
  // Metodos de busqueda
  private perSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return mes.periodo;
        }
      }
    }
    return '';
  }
  private anSelected(){
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          return an.anio;
        }
      }
    }
    return '';
  }
  // Metodos de ordenamiento
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoIdSort(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.proyecto.id) > Number(b.proyecto.id)) {
        return 1;
      }
      if (Number(a.proyecto.id) < Number(b.proyecto.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  private ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
        return 1;
      }
      if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlfAsc(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.proyecto.name.toLowerCase() > b.proyecto.name.toLowerCase() ) {
        return 1;
      }
      if ( a.proyecto.name.toLowerCase() < b.proyecto.name.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlfDes(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.proyecto.name.toLowerCase() < b.proyecto.name.toLowerCase() ) {
        return 1;
      }
      if ( a.proyecto.name.toLowerCase() > b.proyecto.name.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  public ordenamientoObjId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objectives[0].id) > Number(b.objectives[0].id)) {
        return 1;
      }
      if (Number(a.objectives[0].id) < Number(b.objectives[0].id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoAd(array: any){
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoProyec(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.proyecto.objectives[0].id) > Number(b.proyecto.objectives[0].id)) {
        return 1;
      }
      if (Number(a.proyecto.objectives[0].id) < Number(b.proyecto.objectives[0].id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoPlaneado(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.planeado) > Number(b.planeado)) {
        return 1;
      }
      if (Number(a.planeado) < Number(b.planeado)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  //Rutas
  public rutas(direc: string){
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'programas/' +this.idPlanificacion);
  }
  //Formatear datos
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  // Avance acumulado
  public avanceAcumulado(act: any){
    var valFin = 0;
    var res = this.actividadesFull.filter(ac => ac.id === act.id);
    if(res.length !== 0){
      var hist = res[0].historico;
      hist = this.ordenamientoId(hist);
      hist = this.ordenamientoPlaneado(hist);
      for(const h of hist){
        if(act.historico[0].periodo !== h.periodo){
          if(h.ejecutado !== null){
            valFin = h.ejecutado;
          }
        }else{
          if(h.ejecutado !== null){
            valFin = h.ejecutado;
          }
          break;
        }
      }
    }
    return valFin;
  }
  // Busqueda
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.responsables2.filter = filterValue.trim().toLowerCase();
  }
  public applyFilterAct(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.responsablesAct2.filter = filterValue.trim().toLowerCase();
  }
  //Exportar excel
  public exportar_excel(){
    if(this.programa !== ''){
      var title = this.translate.instant('seguimiento.proyectos.export1')+' | ' + this.perspectiva + ' | ' + this.objetivo + ' | ' + this.programa + ' | ' + this.Periodo;
    }else if(this.objetivo !== ''){
      var title = this.translate.instant('seguimiento.proyectos.export1')+' | ' + this.perspectiva + ' | ' + this.objetivo + ' | ' + this.Periodo;
    }else if(this.perspectiva !== ''){
      var title = this.translate.instant('seguimiento.proyectos.export1')+' | ' + this.perspectiva + ' | ' + this.Periodo;
    }else{
      var title = this.translate.instant('seguimiento.proyectos.export1')+' | ' + this.Periodo;
    }
    var header = [""+this.translate.instant('seguimiento.indicadores.titTab1'), ""+this.translate.instant('seguimiento.indicadores.titTab2'), 
    ""+this.translate.instant('seguimiento.proyectos.export2'), 
    ""+this.translate.instant('seguimiento.proyectos.export3'), ""+this.translate.instant('seguimiento.proyectos.export4'),
    ""+this.translate.instant('seguimiento.proyectos.export5'), ""+this.translate.instant('seguimiento.proyectos.export6'),
    ""+this.translate.instant('seguimiento.proyectos.export7'), ""+this.translate.instant('seguimiento.proyectos.export8')];
    for(var j = 0; j < this.actividades[0].additions.length; j++){
      header.push( "" + this.actividades[0].additions[j].name );
    }
    var data = [];
    var aux = [];
    for(var i = 0; i < this.proyectosMostrar.length; i++){
      const ob = this.objectives.filter(o => o.id === this.proyectosMostrar[i].proyecto.objectives[0].id)[0]
      aux.push(ob.perspective.name);
      aux.push(ob.name);
      aux.push( this.proyectosMostrar[i].proyecto.name );
      var fecha = this.escribir_fecha(this.proyectosMostrar[i].fecha_ini);
      aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
      aux.push( this.obtenerDias(this.proyectosMostrar[i].fecha_ini,this.proyectosMostrar[i].fecha_fin) );
      fecha = this.escribir_fecha(this.proyectosMostrar[i].fecha_fin);
      aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
      aux.push( this.proyectosMostrar[i].proyecto.responsible.position );
      aux.push( this.escribirDatPro(this.proyectosMostrar[i],'plan') );
      aux.push( this.escribirDatPro(this.proyectosMostrar[i],'eje') );
      for(var j = 0; j < this.actividades[0].additions.length; j++){
        aux.push( this.escribirAdicionalPro(this.proyectosMostrar[i].actividades,j) );
      }
      data.push(aux);
      aux = [];
      for(const act of this.proyectosMostrar[i].actividades){
        if(this.existAct(act)){
          const ob2 = this.objectives.filter(o => o.id === this.proyectosMostrar[i].proyecto.objectives[0].id)[0]
          aux.push(ob2.perspective.name);
          aux.push(ob2.name);
          aux.push( '-'+act.name );
          fecha = this.escribir_fecha(act.initial_date);
          aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
          aux.push( this.obtenerDias(act.initial_date,act.end_date) );
          fecha = this.escribir_fecha(act.end_date);
          aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
          aux.push( act.responsible.position );
          aux.push( this.escribirDat(act.historico, 'plan')==='-'? '0.0':this.escribirDat(act.historico, 'plan'));
          aux.push( (this.escribirDat(act.historico, 'ejec')===null || this.escribirDat(act.historico, 'ejec')==='-')? 'null':this.escribirDat(act.historico, 'ejec') );
          for(const ad of act.additions){
            aux.push( this.escribirAdicional(ad) );
          }
          data.push(aux);
          aux = [];
        }
      }

    }
    const NomArch = ''+this.translate.instant('seguimiento.proyectos.export9');
    this.excelService.generateExcelProyectos(title, header, data, NomArch, this.semaforo);
  }
  public async exportar_excelPer(){
    var años = [];
    var selecciono = false, anio = '';

    this.aniosDisponiblesTot.forEach(an => años.push(an.anio));

    await Swal.fire({
      title: ''+this.translate.instant('seguimiento.proyectos.swal14'),
      input: 'select',
      inputOptions: años,
      inputPlaceholder: 'Seleccione',
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: ''+this.translate.instant('swal.acep'),
      cancelButtonText: ''+this.translate.instant('swal.cerr'),
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if(value){
            anio = años[value];
            selecciono = true;
            Swal.close();
          }else{
            resolve(''+this.translate.instant('seguimiento.proyectos.swal14'));
          }
        })
      },
    });
    if(selecciono){
      var activities = [], proyects = [];
      for(const act of this.actividadesFull){
        let fecFin = this.obtFech(act.end_date), fecIn = this.obtFech(act.initial_date);
        if( fecIn.getFullYear() === Number(anio) || fecFin.getFullYear() === Number(anio)){
          let idPro = Number(act.project.id), namePro = act.project.name;
          let existe = proyects.filter(pro => pro.id === idPro);
          if(existe.length === 0){
            proyects.push({
              id: idPro,
              name: namePro,
            })
          }
          activities.push({
            nombre: act.name,
            proyectoId: idPro,
            responsable: act.responsible,
            historico: act.historico,
            id: act.id,
            fechaIni: fecIn,
            fechaFin: fecFin
          });
        }
      }
      for(const pro of proyects){
        let actividades = activities.filter(act => pro.id === act.proyectoId);
        pro.actividades = actividades;        
      }
      var aux = [], data = [];
      var header = [""+this.translate.instant('seguimiento.indicadores.titTab1'), ""+this.translate.instant('seguimiento.indicadores.titTab2'), 
      ""+this.translate.instant('seguimiento.proyectos.export2'),
      ""+this.translate.instant('seguimiento.proyectos.export3'), ""+this.translate.instant('seguimiento.proyectos.export4'),
      ""+this.translate.instant('seguimiento.proyectos.export5'), ""+this.translate.instant('seguimiento.proyectos.export10'),
      this.translate.instant('seguimiento.proyectos.export11')+" %"];
      var title = this.translate.instant('seguimiento.proyectos.export12')+' | ' + anio + ' |  '+this.translate.instant('seguimiento.proyectos.export11');
      for(const pro of proyects){
        const pr = this.proyectos.filter( p => Number(p.id) === Number(pro.id))[0]
        const ob = this.objectives.filter(o => o.id === pr.objectives[0].id)[0]
        aux.push(ob.perspective.name);
        aux.push(ob.name);
        aux.push(pro.name);
        aux.push('');
        aux.push('');
        aux.push('');
        aux.push('');
        aux.push('');
        data.push(aux);
        aux = [];
        for(const act of pro.actividades){
          const pr2 = this.proyectos.filter( p => Number(p.id) === Number(pro.id))[0]
          const ob2 = this.objectives.filter(o => o.id === pr2.objectives[0].id)[0]
          aux.push(ob2.perspective.name);
          aux.push(ob2.name);
          let avan = this.avanceAcumuladoExport(act, 'dic-'+(anio+'').substring(2))
          aux.push('-'+act.nombre);
          var fecha = this.escribir_fecha(act.fechaIni);
          aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
          aux.push( this.obtenerDias(act.fechaIni,act.fechaFin) );
          fecha = this.escribir_fecha(act.fechaFin);
          aux.push( fecha.split(' ')[0]+'/'+fecha.split(' ')[1]+'/20'+fecha.split(' ')[2] );
          aux.push( act.responsable.position );
          aux.push(avan);
          data.push(aux);
          aux = [];
        }
        aux.push('');
        aux.push('');
        aux.push('-');
        aux.push('');
        aux.push('');
        aux.push('');
        aux.push(''); 
        aux.push('');
        data.push(aux);
        aux = [];
      }
      const NomArch = ''+this.translate.instant('seguimiento.proyectos.export9');
      this.excelService.generateExcelProyectos(title, header, data, NomArch, this.semaforo);
    }
  }
  public avanceAcumuladoExport(act: any, per: any){
    var valFin = 0;
    var hist = act.historico;
    hist = this.ordenamientoId(hist);
    hist = this.ordenamientoPlaneado(hist);
    for(const h of hist){
      if(per !== h.periodo){
        if(h.ejecutado !== null){
          valFin = h.ejecutado;
        }
      }else{
        if(h.ejecutado !== null){
          valFin = h.ejecutado;
        }
        break;
      }
    }
    return valFin;
  }
  public exportarExcelNuevo(){
    var newArray = [].concat(this.proyectosMostrar);
    newArray = this.ordenamientoProyec(newArray);

    var header = [""+this.translate.instant('seguimiento.proyectos.export2'),
    ""+this.translate.instant('seguimiento.proyectos.export3'), ""+this.translate.instant('seguimiento.proyectos.export4'),
    ""+this.translate.instant('seguimiento.proyectos.export5'), ""+this.translate.instant('seguimiento.proyectos.export6'),
    ""+this.translate.instant('seguimiento.proyectos.export7'), ""+this.translate.instant('seguimiento.proyectos.export8')];
    for(var j = 0; j < this.actividades[0].additions.length; j++){
      header.push( "" + this.actividades[0].additions[j].name );
    }
    var newObjectives = [].concat(this.objectives);
    newObjectives = this.ordenamientoExportacionPer(newObjectives);
    var arrayExportar = [], index = 0;
    for(const obj of newObjectives){  
      const existe = arrayExportar.filter(per => per.perspectiva.name === obj.perspective.name  );
      if(existe.length === 0){
        const proyectos = newArray.filter(pro => Number(pro.proyecto.objectives[0].id) === Number(obj.id));
        if(proyectos.length !== 0){
          const objec = [{ 
            objetivo: obj,
            proyectos: proyectos,
          }]; 
          arrayExportar.push({
            perspectiva: obj.perspective,
            objetivos: objec,
          });
          index++;
        }
      }
      if(existe.length !== 0){
        const proyectos = newArray.filter(pro => Number(pro.proyecto.objectives[0].id) === Number(obj.id));
        if(proyectos.length !== 0){
          const objec = { 
            objetivo: obj,
            proyectos: proyectos,
          }; 
          arrayExportar[index-1].objetivos.push(objec)
        }
      }
    }
    var aux = [], aux2 = [];
    for(const per of arrayExportar){
      aux2.push(per.perspectiva.name,'','','','','','');
      aux.push(aux2);
      aux2 = [];
      for(const obj of per.objetivos){
        aux2.push( obj.objetivo['name'],'','','','','','');
        aux.push(aux2);
        aux2 = [];
        for(const pro of obj.proyectos){
          aux2.push( 
            pro.proyecto.name,
            this.escribir_fecha(pro.fecha_ini),
            this.obtenerDias(pro.fecha_ini,pro.fecha_fin),
            this.escribir_fecha(pro.fecha_fin),
            pro.proyecto.responsible.position,
            this.escribirDatPro(pro,'plan'),
            this.escribirDatPro(pro,'ejec'),
          );
          aux.push(aux2);
          aux2 = [];
          for(const act of pro.actividades){
            aux2.push( 
              act.name,
              this.escribir_fecha(act.initial_date),
              this.obtenerDias(act.initial_date,act.end_date),
              this.escribir_fecha(act.end_date),
              act.responsible.position,
              this.escribirDat(act.historico, 'plan'),
              this.escribirDat(act.historico, 'ejec'),
            );
            aux.push(aux2);
            aux2 = [];
          }
        }
      }
    }
  }
  private ordenamientoExportacionPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.perspective.id) > Number(b.perspective.id)) {
        return 1;
      }
      if (Number(a.perspective.id) < Number(b.perspective.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public actividadRojo(act: any){
    var valFin = 0, perfin = Meses.obtnerPer(act.end_date), res = this.actividadesFull.filter(ac => ac.id === act.id);
    var f = new Date('01/'+Meses.getMesesS(act.historico[0].periodo.split('-')[0])+'/'+act.historico[0].periodo.split('-')[1]);
    var fRevMes = f.getMonth(), fRevAn = f.getFullYear(), fFinMes = act.end_date.getMonth(), fFinAn = act.end_date.getFullYear();
    if((fRevMes > fFinMes && fFinAn === fRevAn) || fFinAn < fRevAn){
      if(res.length !== 0){
        var hist = res[0].historico;
        hist = this.ordenamientoId(hist);
        hist = this.ordenamientoPlaneado(hist);
        for(const h of hist){
          if(perfin !== h.periodo){
            if(h.ejecutado !== null){
              valFin = h.ejecutado;
            }
          }else{
            if(h.ejecutado !== null){
              valFin = h.ejecutado;
            }
            break;
          }
        }
      }
      let avan = this.avanceAcumuladoExport(res[0], act.historico[0].periodo)

      if(valFin === 0 && avan === 0){
        return 'colorRed';
      }
    }
    return '';
  }
  // Menu click izquierdo
  public onContextMenu(event: MouseEvent, item) {
    event.preventDefault(); 
    // we record the mouse position in our object 
    this.menuTopLeftPosition.x = event.clientX + 'px'; 
    this.menuTopLeftPosition.y = event.clientY + 'px'; 

    this.adDelete = item;

    this.matMenuTrigger.openMenu();
  }
  /** Ordenamiento */
  public ordenamientoTabla(){
    switch(this.ordenamiento){
      case 'id':
        this.proyectosMostrar = this.ordenamientoAlfAsc(this.proyectosMostrar);
        this.ordenamiento = 'asc';
      break;
      case 'asc':
        this.proyectosMostrar = this.ordenamientoAlfDes(this.proyectosMostrar);
        this.ordenamiento = 'des';
      break;
      case 'des':
        this.proyectosMostrar = this.ordenamientoIdSort(this.proyectosMostrar);
        this.ordenamiento = 'id';
      break;
    }
  }
  private ordenamientoNuevo(){
    switch(this.ordenamiento){
      case 'id':
        this.proyectosMostrar = this.ordenamientoIdSort(this.proyectosMostrar);
      break;
      case 'asc':
        this.proyectosMostrar = this.ordenamientoAlfAsc(this.proyectosMostrar);
      break;
      case 'des':
        this.proyectosMostrar = this.ordenamientoAlfDes(this.proyectosMostrar);
      break;
    }
  }
  /** Borrar filtros */
  public borrarFiltro(){
    document.body.style.cursor = 'wait';

    this.perSelect = undefined;
    this.objSelect = undefined;
    this.proSelect = undefined;

    this.todasPer = true;
    this.responsables.forEach(res => res.check = true);
    this.todasPerAct = true;
    this.responsablesAct.forEach(res => res.check = true);
    var per = this.aniosDisponibles[this.aniosDisponibles.length-1].series[this.aniosDisponibles[this.aniosDisponibles.length-1].series.length-1]; 
    this.Periodo = per.periodo;
    this.mesMos = per.mes;
    this.aniMos = per.anio;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.periodo !== this.Periodo){
          mes.check = false;
        }else{
          mes.check = true;
        }
      }
    }

    this.proyectosMostrar = [];
    this.proyectosestrategicosService.getProyectosPeriodo(this.Periodo).subscribe(response =>{
      const proyect = this.obtenerProyectosPlaneacion(response);
      this.obtenerProyectosPeriodo(proyect);
      this.proyectosestrategicosService.getAdicionales().subscribe(response =>{
        this.obtAdditions(response);
        this.obtenerProy('cam');
        this.obtenerResponsablesDin();
        if(this.dataService.filtradoIndicadores){
          this.obtenerProy('all');
          this.obtenerResponsablesDin();
        }
      });
    });
    setTimeout(() => {
      document.body.style.cursor = 'default';
    }, 1000 );
  }
  /** Metodo de escritura de los matselect */
  public obtTooltip(idMat: String): String {
    switch(idMat){
      case 'perspectiva':
        const per = this.perspectives.find( dat => { return dat.id === this.perSelect })
        if(per){
          return per.name;
        }
      break;
      case 'objetivo':
        const obj = this.objectives.find( dat => { return dat.id === this.objSelect })
        if(obj){
          return obj.name;
        }
      break;
      case 'estrategia':
        const est = this.programas.find( dat => { return dat.id === this.proSelect })
        if(est){
          return est.name;
        }
      break;
    }
    return '';
  }
  /* Filtro de actividades del año del filtro */
  public cambioDeFiltrado(){
    this.mostrarPeriodo = !this.mostrarPeriodo;
    if(this.todasPer)
      this.obtenerProy('cam');
    else
      this.obtenerProy('all');
  }
}
