<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ 'menu.'+dataService.currentModule | translate }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ 'menu.'+dataService.currentSubmodule | translate }} </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios"> <!--  -->
              {{ 'filtros.periodo' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); cambioAnio(child.periodo)">
                        <mat-checkbox (change)="cambioAnio(child.periodo)"
                        [checked]="child.check" type="checkbox"
                        (click)="$event.stopPropagation();"
                        aria-label="First checkbox">
                        {{ 'filtros.'+child.mes | translate}}
                        </mat-checkbox>
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" (click)="cambioAnio(mes.periodo);">
                        <span style="margin-left: 5px;">{{ 'filtros.'+mes.mes | translate }}-{{mes.anio}}</span> 
                        <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div  *ngIf="numPerSel()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuanios2="matMenu" xPosition="before">
                <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                  <ng-container *ngFor="let mes of anio.series">
                    <div [ngClass]="mes.check?
                      'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado" (click)="cambioAnio(mes.periodo); $event.stopPropagation();">
                      <span style="margin-left: 5px;">{{ 'filtros.'+mes.mes | translate }}-{{mes.anio}}</span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                    </div>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </div>
          </div>

        </div>

        <!-- Perspectivas -->
        <div *ngIf="!dataService.filtradoIndicadores" class="w3-quarter_e padreizquierda" style="margin-right: 1vh;">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer">
              {{ 'filtros.perspectiva' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <div [ngClass]="tabOgraf? '':'w3-disabled' ">
                <button mat-menu-item (click)="$event.stopPropagation(); selecAllPer()">
                  <mat-checkbox [ngClass]="tabOgraf? '':'w3-disabled' " (change)="selecAllPer()"
                  [checked]="todasPer" type="checkbox"
                  (click)="$event.stopPropagation();"
                  aria-label="First checkbox">
                  {{ 'filtros.selAll' | translate }}
                  </mat-checkbox>
                </button>
              </div>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPer">
                <ng-container *ngFor="let per of perspectives">
                  <button mat-menu-item (click)="$event.stopPropagation(); cambioPer(per)">
                    <mat-checkbox [checked]="per.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    (change)="cambioPer(per)"
                    aria-label="First checkbox">
                      {{per.name}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
        </div>

        <!-- Lider -->
        <div class="w3-rest padreizquierda" style="margin-right: 1vh;">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuLid">
              {{ 'filtros.respon' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuLid="matMenu">
              <button mat-menu-item (click)="$event.stopPropagation(); selectAll()">
                <mat-checkbox [checked]="selAll" type="checkbox"
                (click)="$event.stopPropagation();"
                (change)="selectAll()"
                aria-label="First checkbox">
                {{ 'filtros.selAll' | translate }}
                </mat-checkbox>           
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selAll">
                <ng-container *ngFor="let res of responsables2.filteredData">
                  <button mat-menu-item (click)="$event.stopPropagation(); cambioLider(res)">
                    <mat-checkbox [checked]="res.check" type="checkbox"
                    (click)="$event.stopPropagation();"
                    (change)="cambioLider(res)"
                    aria-label="First checkbox">
                      <span matTooltip=" {{res.nombre}} ">{{res.nombre}}</span> 
                    </mat-checkbox>        
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                <ng-container *ngFor="let res of responsables">
                  <div [ngClass]="res.check? 'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)="cambioLider(res)">
                    <div class="nombres textoOverflow" style="margin-left: 5px; max-width: 11vw; overflow: hidden;">
                      <span matTooltip="{{res.nombre + ' ' }}">{{res.nombre + ' ' }}</span>
                    </div> 
                    <div class="tresPuntos"><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></div>
                  </div>
                  <br *ngIf="res.check">
                </ng-container>
                </div>
              </div>
              <div *ngIf="numAreSel()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuLid2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuLid2="matMenu" xPosition="before">
                <ng-container *ngFor="let res of responsables">
                  <div [ngClass]="res.check?
                    'w3-round-btn-large w3-gris-btn w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado" (click)="$event.stopPropagation(); cambioLider(res)">
                    <span style="margin-left: 5px;">{{res.nombre + ' ' }}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                  </div>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row tableroInd">
      <div class="w3-row" *ngIf="tabOgraf && indicadoresMostrar.length != 0">
        <div class="w3-row w3-white">
          <div class="titulo">
            <div class="w3-noventa2 w3-titulo-contenidomenuSC">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">{{ 'seguimiento.indicadores.tituloZon1' | translate }}</span>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-right">
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon>
                <mat-menu #menutabla="matMenu">
                  <div [ngClass]="todasPer? 'w3-disabled':''">
                    <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="cambiarGraf()">
                      <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                      <span>{{ 'seguimiento.indicadores.verGraf' | translate }}</span>
                    </button>
                  </div>
                  <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                    <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                    <span>{{ 'seguimiento.indicadores.expor' | translate }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="tablaArm w3-scroll">
            <div class="w3-row">
              <table class="w3-table borderInf borderDer">
                <thead>
                  <tr>
                    <th class="mantenerFijaTitTop1"></th>
                    <th class="mantenerFijaTitTop1"></th>
                    <th *ngIf="verCodigo" class="mantenerFijaTitTop1"></th>
                    <th class="mantenerFijaTit mantenerFijaTitTop1 anchos"></th>
                    <ng-container *ngFor="let an of aniosDisponibles">
                      <ng-container *ngFor="let mes of an.series">
                        <ng-container *ngIf=" mes.check">
                          <th class="w3-elemento-titulo-table alinCen borderIzqB borderInfB mantenerFijaTitTopMes1" [height]="'3.5vh'" [colSpan]="2">
                            {{mes.periodo}}
                          </th>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tr>
                  <tr class="borderIzq">
                    <th class="w3-elemento-titulo-table alinIzaq mantenerFijaTitTop textoOverflow anchos borderSup">{{ 'seguimiento.indicadores.titTab1' | translate }}</th>
                    <th class="w3-elemento-titulo-table alinIzaq mantenerFijaTitTop textoOverflow anchos borderSup">{{ 'seguimiento.indicadores.titTab2' | translate }}</th>
                    <th *ngIf="verCodigo" class="w3-elemento-titulo-table alinIzaq mantenerFijaTitTop textoOverflow anchosCodigo borderSup">{{ 'seguimiento.indicadores.titTab9' | translate }}</th>
                    <th class="w3-elemento-titulo-table alinIzaq mantenerFijaTitTop textoOverflow anchos borderSup mantenerFija">{{ 'seguimiento.indicadores.titTab3' | translate }}</th>
                    <ng-container *ngFor="let an of aniosDisponibles">
                      <ng-container *ngFor="let mes of an.series">
                        <ng-container *ngIf=" mes.check">
                          <th class="tresPun w3-elemento-titulo-tablePer mantenerFijaTitTopMes alinCen borderSup" style="font-weight: normal;">{{ 'seguimiento.indicadores.titTab4' | translate }}</th>
                          <th class="tresPun w3-elemento-titulo-tablePer mantenerFijaTitTopMes alinCen borderSup" style="font-weight: normal;">{{ 'seguimiento.indicadores.titTab5' | translate }}</th>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tr>
                </thead>
                <tbody class="borderIzq" *ngFor="let ind of indicadoresMostrar; let i = index">
                  <ng-container>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos">
                      <span matTooltip="{{escribirPer(ind,i)}}">{{escribirPer(ind,i)}}</span>
                    </td>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos">
                      <span matTooltip="{{escribirObj(ind,i)}}">{{escribirObj(ind,i)}}</span>
                    </td>
                    <td *ngIf="verCodigo" [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchosCodigo">
                      <span matTooltip="Código">Código</span>
                    </td>
                    <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" class="textoOverflow w3-elemento-titulo-tableBlan anchos mantenerFija">
                      <span matTooltip="{{ind.nombre}}">{{ind.nombre}}</span>
                    </td>
                    <ng-container *ngFor="let an of aniosDisponibles">
                      <ng-container *ngFor="let mes of an.series">
                        <ng-container *ngIf=" mes.check">
                          <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" style="padding: 0vh;">
                            <div *ngIf="escribirMeta(mes.periodo, ind) !== ''" class="w3-center w3-elemento-titulo-table borderInfB">
                              <span style="margin-left: 8px; margin-right: 8px;">{{escribirMeta(mes.periodo, ind)}}</span>
                            </div>
                            <div *ngIf="escribirMeta(mes.periodo, ind) === ''" class="w3-center w3-elemento-titulo-tableSd borderInfB">
                              <br>
                            </div>
                          </td>
                          <td [ngClass]="escribirPer(ind,i) != ''? 'borderSup':''" style="padding: 0vh;">
                            <div *ngIf="escribirMeta(mes.periodo, ind) === ''" class="w3-rest w3-center w3-elemento-titulo-tableBlan borderInfB"
                            style="height: 3.5vh;"></div>
                            <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirMeta(mes.periodo, ind) != ''" class="w3-rest borderInfB w3-center w3-elemento-titulo-tableBlan"
                              [ngClass]="pintarBorde(escribirReal(mes.periodo, ind,'s'),escribirMeta(mes.periodo, ind,'s'), rendimiento(mes.periodo, ind))" (dblclick)="editar(mes.periodo,ind)"
                              style="cursor: pointer;">
                                <span *ngIf="escribirReal(mes.periodo, ind) !== '0' && escribirReal(mes.periodo, ind) !== 'null'"
                                style="margin-left: 8px; margin-right: 8px;">
                                  {{escribirReal(mes.periodo, ind)}}
                                </span> 
                                <span *ngIf="escribirReal(mes.periodo, ind) === '0' || escribirReal(mes.periodo, ind) === 'null'" class="textoAyud"
                                style="margin-left: 8px; margin-right: 8px;">
                                  0
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirMeta(mes.periodo, ind) != ''" class="w3-rest borderInfB w3-center w3-elemento-titulo-tableBlan"
                              [ngClass]="pintarBorde(escribirReal(mes.periodo, ind,'s'),escribirMeta(mes.periodo, ind,'s'), rendimiento(mes.periodo, ind))">
                              <span *ngIf="escribirReal(mes.periodo, ind) !== '0' && escribirReal(mes.periodo, ind) !== 'null'"
                              style="margin-left: 8px; margin-right: 8px;">
                                {{escribirReal(mes.periodo, ind)}}
                              </span> 
                              <span *ngIf="escribirReal(mes.periodo, ind) === '0' || escribirReal(mes.periodo, ind) === 'null'" class="textoAyud"
                              style="margin-left: 8px; margin-right: 8px;">
                                0
                              </span>
                              </div>
                            </ng-container>
                          </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w3-row" style="height: 1vh;"></div>
        </div>
      </div>
      <div class="w3-row" *ngIf="!tabOgraf && indicadoresMostrar.length != 0">
        <div class="w3-row filtroDeGraf">
          <div class="titulo">
            <div class="w3-half w3-titulo-contenidomenuSC padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido w3-tituloZonas">{{ 'seguimiento.indicadores.tituloZon2' | translate }}</span>
                <span class="w3-punto-subtitulo w3-margin-left-filter">&bull;</span>
                <span class=" w3-margin-left-filter w3-subtitulo"> {{perspectivaSelec}} </span>
              </div>
            </div>
            <div class="w3-rest padreizquierda" style="margin-right: 1vh;">
              <div class="w3-half">
                <div class="hijoizquierdaTitle">
                  <button class="w3-button"  [matMenuTriggerFor]="menuPer">
                    {{ 'seguimiento.indicadores.ind' | translate }}
                    <mat-icon class="iconoMen">expand_more</mat-icon>
                  </button>
                  <mat-menu class="w3-medium" #menuPer="matMenu">
                    <!--<ng-container>
                      <div class="busqueda">
                        <mat-form-field appearance="standard" class="inputBus" (click)="$event.stopPropagation();">
                          <mat-label>Buscar</mat-label>
                          <mat-icon matSuffix style="color: gray;">search</mat-icon>
                          <input matInput (keyup)="applyFilterInd($event)" type="text" autocomplete="on">
                        </mat-form-field>
                      </div>
                    </ng-container>-->
                    <ng-container *ngFor="let graf of indicadoresMos.filteredData">
                      <ng-container *ngIf="graf.series.length !== 0">
                        <button mat-menu-item (click)="$event.stopPropagation(); selecGrafLin(graf)">
                          <div class="w3-quince">
                            <input style="cursor: pointer; margin-top: 7px;"
                              [checked]="graf.check" type="checkbox"
                              (click)="$event.stopPropagation();"
                              (change)="selecGrafLin(graf)">
                          </div>
                          <div class="w3-rest textoOverflow">
                            <span style="margin-left: 5px;">{{graf.name}}</span> 
                          </div>           
                        </button>
                      </ng-container>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
              <div class="w3-rest w3-right">
                <button class="mat-menu-item" matTooltip="{{ 'seguimiento.indicadores.tooltip1' | translate }}" mat-menu-item class="w3-caja-menu" (click)="cambiarGraf()">
                  <mat-icon [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w3-row">
          <ng-container *ngFor="let graf of graficas_lineales; let i = index;">
            <ng-container *ngIf=" graf.series.length != 0 && graf.check">
              <div [id]="'graf'+i" class="grafLin w3-white">
                <div class="titulo">
                  <div class="w3-noventa2 w3-titulo-contenidomenuSC padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido w3-tituloZonas">{{graf.name}}</span>
                    </div>
                  </div>
                  <div class="w3-rest">
                    <div class="w3-right">
                      <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon> 
                      <mat-menu #menutabla="matMenu">
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="quitarGrafLin(i)">
                          <mat-icon [ngStyle]="{'color':'gray'}">close</mat-icon>
                          <span>{{ 'seguimiento.indicadores.option1' | translate }}</span>
                        </button>
                        <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="exportarImg(i,graf)">
                          <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
                          <span>{{ 'seguimiento.indicadores.option2' | translate }}</span>
                        </button>
                        <!--<button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="exportarAll()">
                          <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
                          <span>Exportar todo</span>
                        </button>-->
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="w3-row contGraf">
                  <app-grafica-lineal [DatosGraf]="graf.series"></app-grafica-lineal>
                </div>
                <div class="w3-row" style="height: 4vh;"></div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tabOgraf" class="w3-container w3-contenedor-template">
    <div class="w3-row tableroGraf w3-white">
      <div class="titulo">
        <div class="w3-noventa2 w3-titulo-contenidomenuSC padreizquierda">
          <div class="hijoizquierdaTitle">
            <span class="w3-margin-left-contenido w3-tituloZonas">{{ 'seguimiento.indicadores.tituloZon3' | translate }}</span>
            <span class="w3-punto-subtitulo w3-margin-left-filter" *ngIf="subtitulo != ''">&bull;</span>
            <span class="w3-margin-left-filter w3-subtitulo">{{subtitulo}}</span>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-right">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menuMapa">more_vert</mat-icon>
            <mat-menu #menuMapa="matMenu">
              <button class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                <mat-icon [ngStyle]="{'color':'gray'}">assessment</mat-icon>
                <span>{{ 'seguimiento.indicadores.option3' | translate }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="w3-row contGraf" *ngIf="treemap.length != 0">
        <ngx-charts-tree-map class="w3-col w3-border w3-border-white" style="width: 96%; margin-top: -10px;"
          [scheme]="colorScheme_balance"
          [results]="treemap"
          [gradient]="gradient"
          [animations]="animations"
          [labelFormatting]="labelFormatting"
        >
        </ngx-charts-tree-map>
      </div>
    </div>
  </div>
</div>