import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IndicadoresmetaService } from './../../../services/seguimiento/indicadoresmeta/indicadoresmeta.service';
import { Responsables, Meses, obtenerError } from '../../../model/datos';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from '../../../services/Exportar/excel.service';
import { IMG } from '../../../services/Exportar/exportImg';
import html2canvas from 'html2canvas';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-indicadoresmeta',
  templateUrl: './indicadoresmeta.component.html',
  styleUrls: ['./indicadoresmeta.component.css']
})
export class IndicadoresmetaComponent implements OnInit {

  private idPlanificacion: number = 0;
  // Variables de graficas
  public showXAxis = true;
  public showYAxis = true;
  public xAxisLabel = ''+this.translate.instant('seguimiento.indicadores.label1');
  public yAxisLabel = ''+this.translate.instant('seguimiento.indicadores.label2');
  public colorScheme: any ={
    domain: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
             '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
             '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  public posicion: any = 'below';

  options = {
    hAxis: {
      title: 'Periodo',
      textStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
      titleTextStyle: {
        color: '#000',
        fontSize: 14,
        fontName: 'Roboto',
        bold: true,
        italic: false
      },
    },
    vAxis:{
      title: '',
      gridlines: { display: false,color: 'transparent'},
      textStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
      titleTextStyle: {
        color: '#000',
        fontSize: 12,
        fontName: 'Roboto',
        bold: false,
        italic: false
      },
    },
    legend: { position: 'bottom' },
    chartArea: {left: '10%', top: '10%', width: '85%', heigth: '80%' },
    pointSize:5,
    colors: ['#003772','#79B2D1','#619E44','#EAAA39','#E27531','#B53755','#004A97','#97CCE3','#80B266',
    '#E5B165','#E08653','#D3728D','#347FB7','#B8DCEC','#B2DD99','#E5C677','#F7BC9F','#E897B2',
    '#4E9AC7','#CAEFFC','#CDE2BF','#F4E4C3','#F4CEBD','#FCCDE0'],
  };
  columnNames = [""+this.translate.instant('seguimiento.indicadores.export1'),""+this.translate.instant('seguimiento.indicadores.export2'),""+this.translate.instant('seguimiento.indicadores.export3')];
  type: any = 'LineChart';
  // Variables de filtros
  public aniosDisponibles: any = [];
  public perspectives: any = [];
  public objectivos: any = [];
  public indicadores: any = [];
  public indicadoresMostrar: any = [];
  private semaforo: any = [];
  public responsables: Responsables[] = [];
  public responsables2: any = [] // MatTableDataSource<Responsables> = [];
  public indicadoresMos: any = [];
  public selAll = true;
  public todasPer = true;
  public titulo = ''+this.translate.instant('seguimiento.indicadores.ind');
  public subtitulo = '';
  public valreal = 25;
  public treemap = [{name: 'rojo', value: 5}];
  public graficas_lineales: any = [{name: 'ind', series: [['feb-23',12,12],['mar-23',12,12]], check: true}];
  public tabOgraf = true; //--------------------------------------------------------------------------------------------------->
  private fechaAct = new Date();
  private edicion: boolean;
  public perspectivaSelec = '';

  gradient: boolean = false;
  animations: boolean = true;

  colorScheme_balance: any = {
    domain: ['#FF495C', '#FF8300','#FFD100','#0075C9', '#72D54A']
  };
  //public colorScheme: string = FuncionesColores.setpaleta_azul('paleta_azul');

  labelFormatting(c: any) {
    return `<span> </span>`;
  }
  valueFormatting(c: any){
    return '';
  }
  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales
  public verCodigo = false;

  constructor(
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private indicadoresMetaService: IndicadoresmetaService,
    private excelService: ExcelService,
    private translate: TranslateService,
  ) {
    var param = this.rutaActiva.snapshot.params;
    this.idPlanificacion = Number(param['planeacion']);
    this.obtenerDatos();
  }

  ngOnInit(): void {}
  /** Metodo de escritura de cantidades */
  public formatearnormal(num: any){
    num = Number(Number(num).toFixed(2));
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if(splitRight === '.00' || splitRight === '.0')
      return  splitLeft;
    return  splitLeft+splitRight;
  }
  private obtenerDatos(){
    this.indicadoresMetaService.getPlaneacion().subscribe( response =>{
      this.obtenerFiltros(response);
      this.indicadoresMetaService.getObjectives().subscribe( response =>{
        this.obtenerObjetivos(response);
        this.indicadoresMetaService.getIndicadores().subscribe( response =>{
          this.obtenerIndicadores(response);
        });
      });
    });
  }
  private obtenerFiltros(planeacion: any){
    for(const plan of planeacion){
      if(Number(plan.id) === this.idPlanificacion){
        if(plan.institution.acronym === 'IDemo' || plan.institution.acronym === 'Bienestar'){
          //this.verCodigo = true;
        }
        this.edicion = plan.isActive;
        this.aniosDisponibles = Meses.obtenerPeriodos(new Date(plan.initial_date), new Date(plan.end_date));
        var periodosSeleccionados = 0;
        for( var i = this.aniosDisponibles.length-1; i >= 0; i--){
          for( var j = this.aniosDisponibles[i].series.length-1; j >= 0; j--){
            if(periodosSeleccionados < 12){
              this.aniosDisponibles[i].series[j].check = true;
              periodosSeleccionados++;
            }
          }
          if(periodosSeleccionados === 12){
            break;
          }
        }
        for(const per of plan.perspectives){
          this.perspectives.push({
            name: per.name,
            check: false,
            id: per.id,
          });
        }
        this.perspectives = this.ordenamientoId(this.perspectives);

        const sema = this.ordenamientoSem(plan.signals);
        for(const sem of sema){
          this.semaforo.push({
            valIn: Number(sem.value_00),
            valfin: Number(sem.value_01),
            color: sem.color[0],
          });
        }

        this.indicadoresMetaService.getAreas().subscribe(response => {
          this.responsables = [];
          for(const res of response){
            if(res.institution.id === plan.institution.id){
              if(this.dataService.filtradoIndicadores){
                if(res.responsibles[0].email === this.dataService.currentUser){
                  this.responsables.push({
                    id: res.id,
                    nombre: res.name,
                    check: true,
                  });
                }
              }else{
                this.responsables.push({
                  id: res.id,
                  nombre: res.name,
                  check: true,
                });
              }
            }
          }
          this.responsables = this.ordenamientoAlf(this.responsables);
          this.responsables2 = new MatTableDataSource(this.responsables);
        });
      }
    }
  }
  private obtenerObjetivos(array: any){
    var aux = [];
    for(const obj of array){
      if( this.perspectives.find(per => obj.perspective.id === per.id) ){
        aux.push({
          nombre: obj.name,
          id: obj.id,
          idPer: obj.perspective.id,
        });
      }
    }
    this.objectivos = [];
    this.objectivos = aux;
    this.objectivos = this.ordenamientoId(this.objectivos);
  }
  private obtenerIndicadores(array: any){
    var aux = [];
    for(const ind of array){
      if( this.objectivos.find(obj => obj.id === ind.objective.id) ){
        var metas = [];
        for(const met of ind.goals){
          const date = this.obtFech(met.period);
          metas.push({
            id: met.id,
            period: date,
            value: met.value,
            real: met.real,
          });
        }
        aux.push({
          nombre: ind.name,
          id: ind.id,
          ponderacion: ind.weighing,
          metas: this.ordenamientoMetas(metas),
          objectiveId: ind.objective.id,
          tendencia: ind.tendency,
          fechaIni: ind.initial_date,
          fechaFin: ind.end_date,
          areaId: ind.area.id,
        });
      }
    }
    this.indicadores = [];
    this.indicadores = this.ordenamientoObjId( this.ordenamientoId(aux));
    this.indicadores = this.ordenarPorPerspectiva(this.indicadores);
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.contar_colores();
  }
  private ordenarPorPerspectiva(array: any){
    var aux = [];
    for(const per of this.perspectives){
      for(var i = 0; i < array.length; i++){
        if( per.id === this.obtenerIdPer(array[i].objectiveId) ){
          aux.push(array[i]);
        }
      }
    }
    return aux;
  }
  private obtenerIdPer(idObj){
    for(const obj of this.objectivos){
      if(obj.id === idObj){
        return obj.idPer;
      }
    }
  }
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  // Cambios en filtros
  public cambioAnio(periodoSel: any){
    var perSel = 0;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSel++;
        }
      }
    }
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.periodo === periodoSel){
          if(mes.check){
            mes.check = !mes.check;
          }else{
            if(perSel <12){
              mes.check = !mes.check;
            }else{
              Swal.fire({
                title: ''+this.translate.instant('seguimiento.indicadores.swal1'),
                text: '',
                confirmButtonColor: '#ff6b00',
                confirmButtonText: ''+this.translate.instant('swal.acep')
              });
            }
          }
        }
      }
    }
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.contar_colores();
  }
  public cambioPer(perSelec: any){
    for(const per of this.perspectives){
      if(per.id === perSelec.id){
        per.check = true;
      }
      else{
        per.check = false;
      }
    }
    this.todasPer = false;
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.contar_colores();
  }
  public selecAllPer(){
    this.todasPer = !this.todasPer;
    for(const per of this.perspectives){
      per.check = false;
    }
    if(this.todasPer){
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
      this.contar_colores();
    }
  }
  public cambioLider(lidSelec: any){
    var sele = 0;
    for(const res of this.responsables){
      if(res.id === lidSelec.id){
        res.check = !res.check;
      }
      if(res.check){
        sele++;
      }
    }
    if(sele != this.responsables.length)
      this.selAll = false;
    else
      this.selAll = true;
    
    this.indicadoresMostrar = [];
    for(const ind of this.indicadores){
      if(this.Mostrar(ind)){
        this.indicadoresMostrar.push(ind);
      }
    }
    this.contar_colores();
  }
  public selectAll(){
    this.responsables.forEach(res =>{ this.selAll? res.check = false: res.check = true });
    this.selAll = !this.selAll;
    
    if(this.selAll){
      this.indicadoresMostrar = [];
      for(const ind of this.indicadores){
        if(this.Mostrar(ind)){
          this.indicadoresMostrar.push(ind);
        }
      }
      this.contar_colores();
    }
  }
  private obtenertit(){
    this.subtitulo = '';
    for(const per of this.perspectives){
      if(per.check){
        this.subtitulo += per.name+' '  
      }
    }
  }
  // Condicionales de filtros
  public numPerSel(){
    var num = 0;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          num++;
        }
      }
    }
    if( num > 1 )
      return true;
    return false;
  }
  public numAreSel(){
    var num = 0;
    for(const res of this.responsables){
        if(res.check){
          num++;
        }
    }
    if( num > 1 )
      return true;
    return false;
  }
  public saltos(mesAct: any){
    var num = 0;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          num++;
        }
        if(mes.periodo === mesAct.periodo){
          break;
        }
      }
    }
    if(num % 2 === 0 )
      return true;
    return false;
  }
  // Metodos de ordenamiento
  public ordenamientoId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoObjId(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.objectiveId) > Number(b.objectiveId)) {
        return 1;
      }
      if (Number(a.objectiveId) < Number(b.objectiveId)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoMetas(array: any){
    array.sort(function (a: any, b: any) {
      if ((a.period) > (b.period)) {
        return 1;
      }
      if ((a.period) < (b.period)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ((a.nombre) > (b.nombre)) {
        return 1;
      }
      if ((a.nombre) < (b.nombre)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  public ordenamientoSem(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.value_00) > Number(b.value_00)) {
        return 1;
      }
      if (Number(a.value_00) < Number(b.value_00)) {
        return -1;
      }
      return 0;
    });
    return array;
  }
  // metodos de escritura tabla
  public escribirObj(ind: any, i: any){
    if(this.indicadoresMostrar.length === 1){
      return this.obtObj(ind.objectiveId);
    }else{
      if(i!=0){
        if(Number(ind.objectiveId) != Number(this.indicadoresMostrar[i-1].objectiveId))
          return this.obtObj(ind.objectiveId);
      }else if(i === 0){
        return this.obtObj(ind.objectiveId);
      }
    }
    return '';
  }
  public escribirPer(ind: any, i: any){
    var perid1, perid2;
    for(const obj of this.objectivos){
      if(obj.id === this.indicadoresMostrar[i].objectiveId){
        perid1 = obj.idPer;
      }
    }
    if(i != 0){
      for(const obj of this.objectivos){
        if(obj.id === this.indicadoresMostrar[i-1].objectiveId){
          perid2 = obj.idPer;
        }
      }
      if(perid1 != perid2){
        return this.obtPer(perid1);
      }
    }else{
      return this.obtPer(perid1);
    }
    
    return '';
  }
  public escribirMeta(periodo: any, ind: any, sinfor?: any){
    if(sinfor==='ss')
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.value);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.value;
      }
    }
    return ''
  }
  public escribirReal(periodo: any, ind: any, sinfor?: any){
    var nombre = '';
    for(const met of ind.metas){
      nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
      if(nombre === periodo && sinfor === undefined){
        return ''+this.formatearnormal(met.real);
      }else if(nombre === periodo && sinfor !== undefined){
        return ''+met.real;
      }
    }
    return ''
  }
  public pintarBorde(valor: any, meta: any, ind: any) {
    const met = Number(meta), val = Number(valor);
    var porcen = undefined, col = '';
    if(val === 0 && met !== 0 && this.dataService.company_name === 'Unión Popular'){//'Raiffeisen Latina' ){//
      return 'bordeNegro'
    }
    let dat = ind.split('%');
    porcen = Number(dat[0]);
    if(isNaN(val)){
      porcen = undefined;
    }
    if( porcen !== undefined ){
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin
        }
      }
      if(porcen >= valfin){
        porcen = valfin-1;
      }
      for(const sem of this.semaforo){
        var ab = sem.valIn;
        if(ab !== 0){
          ab = ab-0.1;
        }
        if(porcen >= ab && porcen <= sem.valfin){
          col = sem.color;
          break;
        }
      }
      switch(col){
        case 'rojo': return 'bordeRojo';
        case 'naranja': return 'bordeNaranja';
        case 'amarillo': return 'bordeAmarillo';
        case 'azul': return 'bordeAzul';
        case 'verde': return 'bordeVerde';
      }
    }
    return 'bordeGris';
  }
  public rendimiento(periodo: any, ind: any){
    var nombre = '';
    const metas = ind.metas.filter( meta => 
      Meses.getMesesN(new Date(meta.period).getMonth()+1)+'-'+(''+new Date(meta.period).getFullYear()).substring(2,4) === periodo
    );
    //const met = metas[0];
    const met = {real: metas[0].real===null?null:metas[0].real, value: metas[0].value===null?null:metas[0].value};
    
    if(met.real < 0 && met.value >= 0){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin + ' %';          
        case '1':
        case '0':
          return '0 %';
      }
    }else if(met.value < 0.0 && met.real !== null){
      switch(ind.tendencia){
        case '-1':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return 0 + ' %';
          }else if(met.value <= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value > met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
        case '1':
        case '0':
          if(met.real > 0){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value >= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin + ' %';
          }else if(met.value < met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1))  + ' %';
          }
          return '0 %';
      }
    }else if(met.value !== 0 && met.real !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value !== 0 && met.real === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1)) + ' %'
    }else if(met.value === 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real <= 0){
            return 100+' %';
          }else if(met.real > 0 ){
            return 0+' %';
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0+' %';
          }else if( met.real >= 0){
            return 100+' %';
          }else if(met.real < 0 ){
            return 0+' %';
          }
        break;
      }
    }else if(met.value === 0 && met.real === null){
      switch(ind.tendencia){
        case '-1':
          return 0+' %';
        case '1':
        case '0':
          return 0+' %';
      }
    }else if(met.value !== 0 && met.real === null){
      return '0 %';
    }
    return '';
  }
  private porcentajeMet(periodo: any, ind: any){
    var nombre = '';
    const metas = ind.metas.filter( meta => 
      Meses.getMesesN(new Date(meta.period).getMonth()+1)+'-'+(''+new Date(meta.period).getFullYear()).substring(2,4) === periodo
    );
    const met = metas[0];
    if(met === undefined){
      return null
    }
    if(met.real === null){
      return null;
    }
    if( Number(met.real) < 0 && Number(met.value) >= 0 ){
      switch(ind.tendencia){
        case '-1':
          const sem = this.semaforo.filter( sem => sem.valfin >= 100);
          return sem[0].valfin;          
        case '1':
        case '0':
          return 0;
      }
    }else if( Number(met.value) < 0.0 && Number(met.real) !== null ){
      switch(ind.tendencia){
        case '-1':
          if(met.value >= met.real ){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin;
          }else if(met.value < met.real){
            rendimiento = Number(((met.real*100)/met.value).toFixed(1));
            if(rendimiento < 0){ rendimiento = 0;}
            return Number((rendimiento).toFixed(1)) ;
          }
          return 0;
        case '1':
        case '0':
          if(met.value <= met.real){
            const sem = this.semaforo.filter( sem => sem.valfin >= 100);
            return sem[0].valfin;
          }else if(met.value > met.real){
            rendimiento = Number(((met.value*100)/met.real).toFixed(1));
            return Number((rendimiento).toFixed(1)) ;
          }
          return 0;
      }
    }else if(Number(met.value) !== 0 && Number(met.real) !== 0 && met.real !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          rendimiento = Number(((met.value*100)/met.real).toFixed(1));
        break;
        case '1':
        case '0':
          rendimiento = Number(((met.real*100)/met.value).toFixed(1));
        break;
      }
      var valfin = 0;
      for(const sem of this.semaforo){
        if(valfin < sem.valfin){
          valfin = sem.valfin;
        }
      }
      if(rendimiento >= valfin){
        rendimiento = valfin;
      }
      return Number((rendimiento).toFixed(1))
    }else if(Number(met.value) !== 0 && Number(met.real) === 0){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          var valfin = 0;
          for(const sem of this.semaforo){
            if(valfin < sem.valfin){
              valfin = sem.valfin;
            }
          }
          rendimiento = valfin;
        break;
        case '1':
        case '0':
          rendimiento = 0;
        break;
      }
      return Number((rendimiento).toFixed(1))
    }else if(Number(met.value) === 0 && Number(met.real) !== null){
      var rendimiento = 0;
      switch(ind.tendencia){
        case '-1':
          if(met.real === null ){
            return 0;
          }else if( met.real <= 0){
            return 100;
          }else if(met.real > 0 ){
            return 0;
          }
        break;
        case '1':
        case '0':
          if(met.real === null ){
            return 0;
          }else if( met.real >= 0){
            return 100;
          }else if(met.real < 0 ){
            return 0;
          }
        break;
      }
    }else if(Number(met.value) === 0 && Number(met.real) === null){
      switch(ind.tendencia){
        case '-1':
          return 0;
        case '1':
        case '0':
          return 0;
      }
    }else if(Number(met.value) !== 0 && Number(met.real) === null){
      return 0;
    }
    return '';
  }
  public Mostrar(ind: any){
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var metasInd = [];
    var existeMet = false;
    for(const an of this.aniosDisponibles){
      var mes = an.series.filter(mes => mes.check);
      if( mes.length !== 0 ){
        perSele = perSele.concat(mes);
      }
    }
    for(const met of ind.metas){
      var per = (Meses.getMesesN(new Date(met.period).getMonth()+1) + '-' + (new Date(met.period).getFullYear()+'').substring(2,4));
      if( perSele.find(perS => perS.periodo === per) ){
        existeMet = true;
        break;
      }
    }

    //Observar que la perspectiva este activa
    var existPer = false, perInd;
    for(const obj of this.objectivos){
      if(obj.id === ind.objectiveId){
        perInd = this.obtPer(obj.idPer);
      }
    }

    if(this.todasPer){
      existPer = true;
    }else{
      for(const per of this.perspectives){
        if(per.check && per.name === perInd){
          existPer = true;
        }
      }
    }

    //que el area este activa
    var areaAct = false;

    for(const area of this.responsables){
      if(area.id === ind.areaId && area.check){
        areaAct = true;
        break;
      }
    }
    
    if(existeMet && existPer && areaAct)
      return true;
    return false;
  }
  public editar(periodo: any, ind: any){
    var perAct = Meses.obtnerPer(this.fechaAct);
    var perAnt = Meses.obtnerPerAnt(this.fechaAct);    
    if(this.edicion){
      this.dat(periodo, ind);
      return ;
    }
    if(Meses.compararPer(perAnt,perAct,periodo)){
      this.dat(periodo, ind)
    }else{
      Swal.fire({
        title: ''+this.translate.instant('seguimiento.indicadores.swal2'),
        text: ''+this.translate.instant('seguimiento.indicadores.swal3'),
        confirmButtonColor: '#ff6b00',
        confirmButtonText: ''+this.translate.instant('swal.acep')
      });
    }
  }
  private async dat(periodo: any, ind: any){
    var inputValue: any, introdujo: any, guardo = false;
      var nombre = '';
      for(const met of ind.metas){
        nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
        if(nombre === periodo){
          inputValue = Number(met.real)
        }
      }
      
      introdujo = await Swal.fire({
        title: ''+this.translate.instant('seguimiento.indicadores.swal4'),
        text: "",
        input: 'number',
        inputValue: inputValue,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: ''+this.translate.instant('swal.acep'),
        cancelButtonText: ''+this.translate.instant('swal.cerr'),
        showCancelButton: true,
        inputValidator: (value) => {
          guardo = true;
          return '';
        }
      });

      if(guardo){
        for(const indd of this.indicadores){
          if(Number(indd.id ) === Number(ind.id)){
            for(const met of indd.metas){
              nombre =  Meses.getMesesN(new Date(met.period).getMonth()+1)+'-'+(''+new Date(met.period).getFullYear()).substring(2,4);
              if(nombre === periodo){
                if(introdujo.value !== ''){
                  met.real = Number(introdujo.value);
                }else{
                  met.real = null;
                }
                this.indicadoresMetaService.patchMetas(met).subscribe(response =>{
                  this.indicadoresMetaService.getIndicadores().subscribe( response =>{
                    this.obtenerIndicadores(response);
                    this.contar_colores();
                    Swal.fire({
                      title: ''+this.translate.instant('seguimiento.indicadores.swal5'),
                      text: '',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: this.dataService.tiempo
                    });
                  });
                },err =>{
                  let er = obtenerError.errorObt(err['error'].message);
                  Swal.fire({
                    title: er,
                    text: '',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo,
                  });
                });
              }
            }
          }
        }
      }
  }
  // obtencion de nombres
  private obtObj(objectiveid: any){
    for(const obj of this.objectivos){
      if(obj.id === objectiveid){
        return obj.nombre;
      }
    }
  }
  private obtPer(perId: any){
    for(const per of this.perspectives){
      if(per.id === perId){
        return per.name
      }
    }
  }
  // graficas
  private contar_colores(){
    this.graficaslineales();
    var rojo = 0, naranja = 0, amarillo = 0, azul = 0, verde = 0;
    for(var i = 0; i< this.indicadoresMostrar.length; i++){
      for(var j = 0; j< this.indicadoresMostrar[i].metas.length; j++){
        var per = Meses.getMesesN(new Date(this.indicadoresMostrar[i].metas[j].period).getMonth()+1);
        per += '-' + (new Date(this.indicadoresMostrar[i].metas[j].period).getFullYear()+'').substring(2,4);
        if(this.muestraPer(per) && this.perAMos(per)){
          this.subtitulo = per;
          var existPer = false, perInd;
          for(const obj of this.objectivos){
            if(obj.id === this.indicadoresMostrar[i].objectiveId){
              perInd = this.obtPer(obj.idPer);
            }
          }
          if(this.todasPer){
            existPer = true;
          }else{
            for(const per of this.perspectives){
              if(per.check && per.name === perInd){
                existPer = true;
              }
            }
          }
          if(existPer){
            let real = this.escribirReal(per, this.indicadoresMostrar[i],'s');
            let value = this.escribirMeta(per, this.indicadoresMostrar[i],'s');
            switch(''+this.pintarBorde(real,value, this.rendimiento(per, this.indicadoresMostrar[i]))){
              case 'bordeRojo': rojo++; break;
              case 'bordeNaranja': naranja++; break;
              case 'bordeAmarillo': amarillo++; break;
              case 'bordeAzul': azul++; break;
              case 'bordeVerde': verde++; break;
            }
          }
        }
      }
    }
    var aux = [];
    aux.push({name: ''+this.translate.instant('seguimiento.indicadores.color1'), value: rojo});
    aux.push({name: ''+this.translate.instant('seguimiento.indicadores.color2'), value: naranja});
    aux.push({name: ''+this.translate.instant('seguimiento.indicadores.color3'), value: amarillo});
    aux.push({name: ''+this.translate.instant('seguimiento.indicadores.color4'), value: azul});
    aux.push({name: ''+this.translate.instant('seguimiento.indicadores.color5'), value: verde});
    this.colorScheme_balance= {
      domain: ['#FF495C','#FF8300','#FFD100','#0075C9','#72D54A']
    };
    this.treemap = [];
    this.treemap = aux;
  }
  private muestraPer(ind: any){
    // Observar que exista al menos una meta en los periodos
    var perSele = [];
    var existeMet = false;
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          perSele.push(mes.periodo);
        }
      }
    }
    for(const perS of perSele){
      if(ind === perS){
        existeMet = true;
      }
    }
    
    if(existeMet )
      return true;
    return false;
  }
  private perAMos(perA: any){
    var ultPerconDat = '', datos = 0;
    var periodos_mostrados = [];

    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          periodos_mostrados.push(mes.periodo);
        }
      }
    }

    for(var i = periodos_mostrados.length-1; i >= 0; i--){
      for(const ind of this.indicadoresMostrar){
        for(const met of ind.metas){
          var peri = Meses.getMesesN(new Date(met.period).getMonth()+1);
          peri += '-' + (new Date(met.period).getFullYear()+'').substring(2,4);
          if( (met.real !== 0 && met.real !== null || (met.real === 0 && met.value === 0)) && peri === periodos_mostrados[i]){
            datos++;
            break;
          }
        }
      }
      if(datos != 0){
        ultPerconDat = periodos_mostrados[i];
        break;
      }
    }
    if(perA === ultPerconDat)
      return true;
    return false;
  }
  private graficaslineales(){
    this.graficas_lineales = [];
    var graflin: any = [],  aux = [], aux2 = [];
    for(var i = 0; i< this.indicadores.length; i++){
      for(var j = 0; j< this.indicadores[i].metas.length; j++){
        var per = Meses.getMesesN(new Date(this.indicadores[i].metas[j].period).getMonth()+1);
        per += '-' + (new Date(this.indicadores[i].metas[j].period).getFullYear()+'').substring(2,4);
        if(this.muestraPer(per)){
          var existPer = false, perInd;
          for(const obj of this.objectivos){
            if(obj.id === this.indicadores[i].objectiveId){
              perInd = this.obtPer(obj.idPer);
            }
          }
          if(this.todasPer){
            existPer = true;
          }else{
            for(const per of this.perspectives){
              if(per.check && per.name === perInd){
                existPer = true;
              }
            }
          }
          if(existPer){
            aux.push([per, Number(this.indicadores[i].metas[j].value), Number(this.indicadores[i].metas[j].real)]);
          }
        }
      }
      graflin.push(aux)
      this.graficas_lineales.push({name: this.indicadores[i].nombre, series: aux, check: true});
      aux = [];
      aux2 = [];
      graflin = [];
    }
    for(const per of this.perspectives){
      if(per.check){
        this.perspectivaSelec = per.name;
        break;
      }
    }
    this.indicadoresMos = new MatTableDataSource(this.graficas_lineales);
  }
  public cambiarGraf(){
    this.tabOgraf = !this.tabOgraf;
  }
  public quitarGrafLin(pos: any){
    var seMuestran = 0;
    for( const graf of this.graficas_lineales ){
      if(graf.check){
        seMuestran ++;
      }
    }
    if(seMuestran > 1){
      this.graficas_lineales[pos].check = false;
    }else{

    }
  }
  public selecGrafLin(graf: any){
    graf.check = !graf.check;
  }
  public obtRes(i: any){
    var areaId= this.indicadoresMostrar[i].areaId;
    for(const res of this.responsables){
      if(res.id === areaId){
        return res.nombre;
      }
    }
    return '';
  }
  //Rutas
  public rutas(direc: string){
    if(direc === 'avanzar')
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'programas/' +this.idPlanificacion);
    else
      this.router.navigateByUrl('/app/'+ 'seguimiento/' + 'objetivos/' +this.idPlanificacion);
  }
  // Busqueda
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.responsables2.filter = filterValue.trim().toLowerCase();
  }
  public applyFilterInd(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.indicadoresMos.filter = filterValue.trim().toLowerCase();
  }
  //Exportación excel
  public exportar_excel(){
    const title = ''+this.translate.instant('seguimiento.indicadores.export4');
    var header = [""+this.translate.instant('seguimiento.indicadores.titTab1'), ""+this.translate.instant('seguimiento.indicadores.titTab2'), ""+this.translate.instant('seguimiento.indicadores.titTab3'), ""+this.translate.instant('seguimiento.indicadores.titTab6'), ""+this.translate.instant('seguimiento.indicadores.titTab7')];
    var header2 = ["", "", "", "", ""];
    for(const an of this.aniosDisponibles){
      for(const mes of an.series){
        if(mes.check){
          header.push(""+this.translate.instant('seguimiento.indicadores.titTab4'));
          header.push(""+this.translate.instant('seguimiento.indicadores.titTab8'));
          header2.push(""+mes.periodo);
          header2.push("");
        }
      }
    }
    var data = [];
    var aux = [];
    for(var i = 0; i < this.indicadoresMostrar.length; i++){
      aux.push( this.escribirPer(this.indicadoresMostrar[i], i) );
      aux.push( this.escribirObj(this.indicadoresMostrar[i], i) );
      aux.push( (this.verCodigo?'Código | ':'') +''+this.indicadoresMostrar[i].nombre );
      aux.push( this.indicadoresMostrar[i].ponderacion );
      aux.push( this.obtRes(i));
      for(const an of this.aniosDisponibles){
        for(const mes of an.series){
          if(mes.check){
            var real = ((this.escribirReal(mes.periodo, this.indicadoresMostrar[i],'ss')));
            aux.push( this.escribirMeta(mes.periodo, this.indicadoresMostrar[i],'ss'));
            if(real === ''){
              aux.push( ['',null] );
            }else{
              aux.push( real==='null'? [0,null]: [real, this.porcentajeMet(mes.periodo, this.indicadoresMostrar[i])] );
            }
          }
        }
      }
      data.push(aux);
      aux = [];
    }
    const NomArch = ''+this.translate.instant('seguimiento.indicadores.export5');
    this.excelService.generateExcelIndicadores(title, header, header2, data, NomArch, this.semaforo);
  }
  public exportarImg(dat,graf){
    var grafica: string = "";
    var name: string = "";
    grafica = 'graf'+dat;
    name = ''+graf.name;

    IMG.exportimagenes(grafica,name);
  }
  public async exportarAll(){
    this.exportando();
    var grafica: string = "";
    var name: string = "";
    var dat = 0, imagenes = [];
    for(const graf of this.graficas_lineales){
      grafica = 'graf'+dat;
      name = ''+graf.name;
    
      if(graf.check){
        const chart = document.getElementById(grafica);
        await html2canvas(chart, {
            scale: 2,
            backgroundColor: 'white',
            logging: false,
        }).then((canvas) => {
          const chartData = canvas.toDataURL('image/PNG');
          imagenes.push(chartData);
        }).catch(error => {});
      }
    
      dat ++;
    }
    var bufferArray = [];
    for(const img of imagenes){
      const response = await fetch(img);
      const buffer = await response.arrayBuffer();
      bufferArray.push(buffer);
    }
    const NomArch = 'Inidicadores_metas';
    const title = 'Seguimiento: Indicadores-Metas';
    this.excelService.generateExcelImagenes(title,bufferArray,NomArch);
    Swal.close();
    this.finalizacionExportacion();
  }
  private exportando(){
    Swal.fire({
      title: ''+this.translate.instant('seguimiento.indicadores.swal6'),
      html: ''+this.translate.instant('seguimiento.indicadores.swal7'),
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });
  }
  private finalizacionExportacion(){
    Swal.fire({
      title: ''+this.translate.instant('seguimiento.indicadores.swal8'),
      icon: 'success',
      html: "",
      showConfirmButton: false,
      timer: this.dataService.tiempo
    });
  }
}
