import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class historico{
  period?: string;
  planned?: number;
  executed?: number;
  activityId?: Number;
}
export class comment{
  additionalId?: Number
  comment?: string;
  created_at?: String;
}
export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
}
export class actividades{
  public name?: string;
  public initial_date?: Date;
  public end_date?: Date;
  public minuteId?: number;
  public responsibleId?: number;
  public validated?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ActasService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
  .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlaneacion(): Observable< any >{
    const apiURL = this.apiURL+"planificacion/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  /* Actas */
  public getActas(): Observable< any >{
    const apiURL = this.apiURL+"actas/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getFileActas(acta): Observable< any >{
    const apiURL = this.apiURL+"actas/"+acta.id+"/file";
    return this.http.get(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
  /* Actividades actas */
  public getActividadesActas(): Observable< any >{
    const apiURL = this.apiURL+"acta/actividades";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public patchActividades(act: any): Observable<any>{
    const acti: actividades = {
      validated: act.validacion,
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"acta/actividades/"+act.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  /* actas periodo */
  public getProyectosPeriodo(periodo: string): Observable< any >{
    const apiURL = this.apiURL+"proyectos?period="+periodo;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  /* Adicionales */
  public getAdicionales(): Observable< any >{
    const apiURL = this.apiURL+"acta/actividad/adicionales";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  /* Responsables */
  public getResponsables(): Observable< any >{
    const apiURL = this.apiURL+"area/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  /* Historico */
  public postHistorico(his: any, idAct: number): Observable<any>{
    const hist: historico = {
      activityId: idAct,
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"acta/actividad/historic";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public PatchHistorico(his: any, idHis: number): Observable<any>{
    const hist: historico = {
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"acta/actividad/historic/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public PatchHistoricoNull(his: any, idAct: number, idHis: number): Observable<any>{
    const hist: historico = {
      period: his.period,
      planned: Number(his.planned),
      executed: his.executed,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  /* Adicionales archivos */
  public postFile(fil: any, idAd: number, fecha: any): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('additionalId', ''+idAd);
    formData.append('file', fil);
    formData.append('created_at', fecha);

    const apiURL = this.apiURL+"acta/actividad/adicional/adjuntos";
    return this.http.post(`${apiURL}`, formData, {headers: this.headersAr});
  }
  public getFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"acta/actividad/adicional/adjuntos/"+idFil;
    return this.http.get(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
  public deleteFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"acta/actividad/adicional/adjuntos/"+idFil;
    return this.http.delete(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
  /* Adicionales comentarios */
  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      additionalId: Number(idAct),
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"acta/actividad/adicional/comentarios";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"acta/actividad/adicional/comentarios/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
