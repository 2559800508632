import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  private apiURL = environment.apiDevURL_institucion;
  private apiURLadm = 'https://api-adminclient.rflatina.com/rfl/api/v1/';

  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
  .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));


  constructor(private http: HttpClient) { }

  sendReport(planificacionId: string, reporte: any, emails: any, reportName: string): Observable<any>{

    const apiUrl = this.apiURL+`planificacion/${planificacionId}/enviar-reporte`;
    
    const formData: FormData = new FormData();
    formData.append('file', reporte);
    formData.append('emails', emails);
    formData.append('report_name', reportName);
    return this.http.post(apiUrl, formData, {headers: this.headersAr});
  }

  public getLogo(idInst): Observable<any>{
    const apiURL = this.apiURLadm+"files?id_company="+idInst+"&folder=expediente ";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getArchivosA(idFile): Observable<any>{
    const apiURL = this.apiURLadm+"files/"+idFile;
    return this.http.get(`${apiURL}`, {headers: this.headersAr, 'responseType'  : 'arraybuffer' as 'json'});
  }

  public getPlan(idPlan): Observable<any>{
    const apiURL = this.apiURL+"planificacion/"+idPlan;
    return this.http.get(`${apiURL}`, {headers: this.headersAr}) 
  }

  public getAreas(): Observable< any >{
    const apiURL = this.apiURL+"area/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getObjectives(): Observable< any >{
    const apiURL = this.apiURL+"objetivos/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getIndicadores(): Observable< any >{
    const apiURL = this.apiURL+"indicador/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getAdicionales(): Observable<any>{
    const apiURL = this.apiURL+"adicional";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getProgramas(): Observable< any >{
    const apiURL = this.apiURL+"estrategia/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getProyectos(): Observable< any >{
    const apiURL = this.apiURL+"proyectos/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getActividades(): Observable< any >{
    const apiURL = this.apiURL+"actividad/";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getProyectosPeriodo(periodo: string): Observable< any >{
    const apiURL = this.apiURL+"proyectos?period="+periodo;
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }

  public getFLOA(): Observable<any>{
    const apiURL = this.apiURL+"matriz";
    return this.http.get(`${apiURL}`, {headers: this.headersAr});
  }
}
