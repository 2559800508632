import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { AltaactasService } from './../../../services/formulacion/altaactas/altaactas.service';
import { ActasService } from './../../../services/seguimiento/actas/actas.service';
import { Responsables, procesamiento, obtenerError, obtenerTamMax, alertas, Meses } from '../../../model/datos';

function wait(espera_segundos) {
  var espera = espera_segundos * 1000
  const tiempo_inicio = Date.now();
  let tiempo_actual= null;
  do {
    tiempo_actual= Date.now();
  } while (tiempo_actual - tiempo_inicio < espera);
}
@Component({
  selector: 'app-proyectos2',
  templateUrl: './proyectos2.component.html',
  styleUrls: ['./proyectos2.component.css']
})
export class Proyectos2Component implements OnInit {

  
  /* Variables de Url */
  private idPlaneacion = 0;

  /* Variables de los filtros */
  public aniosDisponibles = [];
  public responsables: Responsables[] =[];
  public responsablesActas: Responsables[] = [];
  public responsablesActividadesActas: Responsables[] = [];
  public selecAllResActas = true;
  public selecAllResActiv = true;

  /* Variables de funcionamiento de tablas */
  public mostabla = false;
  public skeleton = new Array(15);
  public skeletonColums = new Array(6);
  public Dias = [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}];
  public meses = [{name: 'ene', value: this.Dias},{name: 'feb', value: this.Dias},{name: 'mar', value: this.Dias},{name: 'abr', value: this.Dias},
  {name: 'may', value: this.Dias},{name: 'jun', value: this.Dias},{name: 'jul', value: this.Dias},{name: 'ago', value: this.Dias},
  {name: 'sep', value: this.Dias},{name: 'oct', value: this.Dias},{name: 'nov', value: this.Dias},{name: 'dic', value: this.Dias}];
  public mostrarAllActas = false;
  public adicionales = [];
  public  ordenamiento = 'id';

  /* Variables de datos tablas */
  public actas = [];
  public actasMostrar = [];
  public actividadesActas = [];

  /* Variables para el formateo de cantidades */
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  /* Variables para los datePiker */
  public myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };
  public minDate = new Date(2022, 0, 1);
  public maxDate = new Date(2022, 11, 31);
  /* Variables de los modales */
  public modalAgregarActa = false;
  public modalEditarActa = false;
  public modalAgregarActividad = false;
  public modalEditarActividad = false;
  public modalAgregarAdicional = false;
  /* Variables de agregado de información */
  public edicionActa: any;
  private actaId: any;
  public edicionActividad: any;
  public nombreArchivo = '';
  public editarArch = false;
  private totalAct = 0;
  public diasLab = '';
  /** Grupo de validación de datos */
  formActas = new FormGroup({ 
    inputNameActa: new FormControl('', [Validators.required]),
    inputDescripcion: new FormControl(''),
    inputLider: new FormControl('', [Validators.required]),    
    inputArchivo: new FormControl(''),    
  },{ validators: this.nombreDuplicidad() });
  formActasEdit = new FormGroup({ 
    inputNameActa: new FormControl('', [Validators.required]),
    inputDescripcion: new FormControl(''),
    inputLider: new FormControl('', [Validators.required]),    
    inputArchivo: new FormControl(''), 
    
  },{ validators: this.nombreDuplicidad() });

  formActividad = new FormGroup({ 
    inputNameAct: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),
  });
  formActividadEdit = new FormGroup({ 
    inputNameAct: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),

  });

  formAdicional = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputType: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidadAdi() });


  constructor(
    private router: Router,
    public dataService: DataService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
    private altaActasService: AltaactasService,
    private actasService: ActasService,
  ) {
    var param = this.rutaActiva.snapshot.params
    this.idPlaneacion = Number(param["planeacion"]);
    this.obtenerDatos();
  }

  ngOnInit(): void {
  }

  /* Consultas de inicio */
  private obtenerDatos(){
    this.altaActasService.getPlanificaciones().subscribe(response =>{
      this.obtenerFiltros(response);
      this.altaActasService.getActas().subscribe(response => {
        this.obtenerActas(response);
        this.altaActasService.getActividadesActas().subscribe(response => {
          this.obtenerActividadesActas(response);
        });
      });
    });
  }
  private obtenerActasNuevamente(texto){
    this.altaActasService.getActas().subscribe(response => {
      this.obtenerActas(response);
      this.altaActasService.getActividadesActas().subscribe(response => {
        this.obtenerActividadesActas(response);
        Swal.fire({
          title: ''+texto,
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
        this.close_modal();
      });
    });
  }
  private obtenerActividadesNuevamente(texto){
    this.altaActasService.getActividadesActas().subscribe(response => {
      this.obtenerActividadesActas(response);
      Swal.fire({
        title: ''+texto,
        text: '',
        icon: 'success',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
      this.close_modal();
    });
  }
  /* Extracción de datos */
  // Filtros
  private async obtenerFiltros(array: any){
    const plan = array.filter(plan => plan.id === this.idPlaneacion+'');
    if(plan.length !== 0){
      const planeacion = plan[0];
      const fechIni = this.obtFech(planeacion.initial_date);
      const fechFin = this.obtFech(planeacion.end_date);
      const fechAct = new Date();

      await this.altaActasService.getResponsable().subscribe(response => {
        this.responsables = [];
        var aux = [];
        for(const are of response){
          if(are.institution.id === planeacion.institution.id){
            for(const res of are.responsibles){
              aux.push({
                nombre: res.position,
                id: res.id,
                check: true,
                isValidator: res.isValidator,
              });
            }
          }
        }
        this.responsables = this.ordenamientoAlf(aux);
      });
      this.diasLab = planeacion.business_days;
      switch(planeacion.business_days){
        case 'Lunes-Viernes':
          this.myFilter = (d: Date | null): boolean => {
            const day = (d || new Date()).getDay();
            return day !== 0 && day !== 6;
          };
        break;
        case 'Lunes-Sabado':
          this.myFilter = (d: Date | null): boolean => {
            const day = (d || new Date()).getDay();
            return day !== 0;
          };
        break;
        case 'Lunes-Domingo':
          this.myFilter = (d: Date | null): boolean => {
            const day = (d || new Date()).getDay();
            return true;
          };
        break;
      }
      this.aniosDisponibles = [];
      for(var i = fechIni.getFullYear(); i <= fechFin.getFullYear(); i++){
        this.aniosDisponibles.push({
          name: ''+i,
          check: i===fechAct.getFullYear()?true:false,
        });
      }
      
      this.minDate = fechIni;
      this.maxDate = fechFin;

    }
  }
  // Actas
  private obtenerActas(array: any){
    var auxiliar = [];
    for(const acta of array){
      if(Number(acta.plan.id) === this.idPlaneacion){//resExiste.length !== 0 && 
        auxiliar.push({
          nombre: acta.name,
          descripcion: acta.description,
          responsable: acta.validator,
          acta: acta.attachment,
          id: acta.id,
          check: true,
          fecha_ini: undefined,
          fecha_fin: undefined,
          actividades: [],   
        });
      }
    }
    this.actas = auxiliar;
    this.actas = this.ordenamientoPer(this.actas);
  }
  //Actividades de las actas
  private obtenerActividadesActas(array){
    for(const actas of this.actas){
      const actividades = array.filter(actaId => actaId.minute.id === actas.id);
      if(actividades.length !== 0){
        var auxiliar = [];
        for(const acti of actividades){
          auxiliar.push({
            nombre: acti.name,
            fecha_inicio: this.obtFech(acti.initial_date),
            fecha_fin: this.obtFech(acti.end_date),
            projectId: acti.minute.id,
            responsable: acti.responsible,
            id: acti.id,
            adicionales: this.ordenamientoAdi(acti.additions),
            history: acti.history,
          });
          this.totalAct++;
        }
        actas.actividades = auxiliar;
      }
    }
    this.obtenerfechasActas();
    if(this.actas.length !== 0 && this.actas[0].actividades.length !== 0 && this.actas[0].actividades[0].adicionales.length !== 0 ){
      this.adicionales = this.actas[0].actividades[0].adicionales;
    }
    this.dataTablaMostrar('cam');
    this.obtResponsableDinamico();
    this.mostabla = true;
  }  
  //Fechas de proyectos
  public obtenerfechasActas(){
    for(const pro of this.actas){
      for(const act of pro.actividades){
        if(pro.fecha_ini === undefined){
          pro.fecha_ini = act.fecha_inicio;
        }else{
          if(pro.fecha_ini > act.fecha_inicio){
            pro.fecha_ini = act.fecha_inicio;
          }
        }
        if(pro.fecha_fin === undefined){
          pro.fecha_fin = act.fecha_fin;
        }else{
          if(pro.fecha_fin < act.fecha_fin){
            pro.fecha_fin = act.fecha_fin;
          }
        }
      }
    }
  }
  /* Obtención de datos a mostrar */
  public dataTablaMostrar(parametros: any){
    var actasTot = [].concat(this.actas);    
    this.actasMostrar = [];
    for(const acta of actasTot){
      switch(parametros){
        case 'all':
          if( this.mostrarConResponsables(acta) ){
            this.actasMostrar.push({
              nombre: acta.nombre,
              descripcion: acta.descripcion,
              responsable: acta.responsable,
              acta: acta.acta,
              id: acta.id,
              check: acta.check,
              fecha_ini: acta.fecha_ini,
              fecha_fin: acta.fecha_fin,
              actividades: acta.actividades,
            });
          }
        break;
        case 'cam':
          if( this.mostrar(acta) ){
            this.actasMostrar.push(acta);
          }
        break;
      }
    }
    if(parametros === 'all' && !this.selecAllResActiv){
      const res = this.responsablesActividadesActas.filter(res => res.check===true);
      if(res.length!==0){
        for(const a of this.actasMostrar){
          const act = a.actividades.filter(act => this.responsableActOfActas(act)===true);
          a.actividades = act;
        }        
      }
    }
  }
  //Obtención de responsables
  private obtResponsableDinamico(){
    var aux = [], aux2 = [];
    for(const acta of this.actasMostrar){
      const res = this.responsables.filter(res => res.id === acta.responsable.id);
      const existe = aux.filter(res => res.id === acta.responsable.id);
      if(existe.length === 0){
        aux.push({
          nombre: res[0].nombre,
          id: res[0].id,
          check: true,
        });
      }
      for(const resp of acta.actividades){
        const resAct = this.responsables.filter(res => res.id === resp.responsable.id);
        const existeAct = aux2.filter(res => res.id === resp.responsable.id);
        if(existeAct.length === 0){
          aux2.push({
            nombre: resAct[0].nombre,
            id: resAct[0].id,
            check: true,
          });
        }
      }
    }
    this.responsablesActas = this.ordenamientoAlf(aux);
    this.selecAllResActas = true;
    this.responsablesActividadesActas = this.ordenamientoAlf(aux2);
    this.selecAllResActiv = true;
  }
  //Mostrar sin considerar responsable
  public mostrar(acta: any){
    if( this.existeAnio(acta) ){
      return true;
    }else{
      return false;
    }
  }
  //Mostrar considerando los responsables
  public mostrarConResponsables(acta: any){
    if( this.responsableActas(acta) && this.existeAnio(acta) ){
      return true;
    }else{
      return false;
    }
  }
  //Metodos de decición
  private existeAnio(acta: any){
    if(acta.fecha_ini !== undefined && acta.fecha_ini !== undefined){
      var fecha_ini = acta.fecha_ini.getFullYear(), fecha_fin = acta.fecha_fin.getFullYear();
      for(const an of this.aniosDisponibles){
        if(an.check === true && (Number(an.name) >= fecha_ini && Number(an.name) <= fecha_fin) ){
          return true;
        }
      }
      return false;
    }else{
      return true;
    }
  }
  private responsableActas(acta: any){
    for(const res of this.responsablesActas){
      if(res.id === acta.responsable.id && res.check){
        return true;
      }
    }
    return false;
  }
  public responsableActOfActas(act: any){
    for(const res of this.responsablesActividadesActas){
      if(res.id === act.responsable.id && res.check){
        return true;
      }
    }
    return false;
  }
  /* Eventos de tablas */
  public mostrarTodasLasActas(){
    this.actasMostrar.forEach(acta => this.mostrarAllActas?acta.check=false:acta.check=true)
    this.mostrarAllActas = !this.mostrarAllActas;
  }
  public mostrarActas(acta: any){
    acta.check = !acta.check;
    var actasAbiertas = 0;
    for(const actas of this.actasMostrar){
      if(actas.check){
        actasAbiertas++;
      }
    }
    if(actasAbiertas === this.actasMostrar.length){
      this.mostrarAllActas = true;
    }else if(actasAbiertas === 0){
      this.mostrarAllActas = false;
    }
  }
  /* Metodos de escritura en tabla */
  public obtComment(com : any){
    for(const comen of com){
      var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      }
    }
    return '';
  }
  //Decición de edición de los adicionales
  public edicionPro(actas: any, adicionalId: any){
    if(actas.actividades.length !== 0){
      for(const act of actas.actividades){
        if(act.adicionales[adicionalId].type === 'moneda'){
          return true;
        }
      }
    }
    return false;    
  }
  //Mostrar los adicionales
  public imprimirPro(acta: any, adicionalId: any){
    var tot = 0;
    var totN = 0;
    var tipo = '';
    for(const act of acta.actividades){
      switch(act.adicionales[adicionalId].type){
        case 'moneda':
          tipo = act.adicionales[adicionalId].type;
          tot += Number(act.adicionales[adicionalId].amount)
        break;
      }
    }
    if(tipo === 'moneda'){
      return this.formatearnormal(tot);
    }
    return ''
  }
  /** Obtención de los datos */
  //Obtención de fechas
  public obtFecha(fec: any){
    var fecha =  '';
    if(fec != undefined){
      switch(fec.getDate()){
        case 1: fecha+='01-'; break;
        case 2: fecha+='02-'; break;
        case 3: fecha+='03-'; break;
        case 4: fecha+='04-'; break;
        case 5: fecha+='05-'; break;
        case 6: fecha+='06-'; break;
        case 7: fecha+='07-'; break;
        case 8: fecha+='08-'; break;
        case 9: fecha+='09-'; break;
        default: fecha+= fec.getDate()+'-'; break;
      }
      switch((fec.getMonth()+1)){
        case 1: fecha+='01'; break;
        case 2: fecha+='02'; break;
        case 3: fecha+='03'; break;
        case 4: fecha+='04'; break;
        case 5: fecha+='05'; break;
        case 6: fecha+='06'; break;
        case 7: fecha+='07'; break;
        case 8: fecha+='08'; break;
        case 9: fecha+='09'; break;
        default: fecha+= (fec.getMonth()+1)+''; break;
      }
      fecha +='-'+fec.getFullYear();
    }
    return fecha;
  }
  //Obtención de los dias
  public obtDias(ini: any, fin: any){
    if(ini != undefined && fin != undefined){
      if( (fin - ini) === 0){
        return 1;
      }
      var dias = (fin-ini)/(1000*60*60*24);
      var semanas = Math.trunc(dias/7);
      dias = dias - (semanas*2);
      return Math.round(dias)
    }
    return '';
  }
  //Metodos del gantt
  public pintarGantAct(act: any, dia:any, mes: any){
    var an = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        an = anio.name;
      }
    }
    var fecha = new Date((mes+1)+'/'+((dia*2)-1)+'/'+an);
    if((mes+1) === 2 && dia === 15){
      fecha = new Date((mes+1)+'/'+((14*2)-1)+'/'+an);
    }
    if(fecha <= act.fecha_fin && fecha>=act.fecha_inicio){
      if(dia === 15)
        return 'w3-orange-gantt w3-rest'
      return 'w3-orange-gantt w3-31'
    }
    if(dia === 15)
      return 'w3-white w3-rest'
    return 'w3-white w3-31'
  }
  public pintarGantPro(act: any, dia:any, mes: any){
    var an = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        an = anio.name;
      }
    }
    var fecha = new Date((mes+1)+'/'+((dia*2)-1)+'/'+an);
    if((mes+1) === 2 && dia === 15){
      fecha = new Date((mes+1)+'/'+((14*2)-1)+'/'+an);
    }
    if(fecha <= act.fecha_fin && fecha>=act.fecha_ini){
      if(dia === 15){
        return 'w3-black-blue w3-rest';
      }
      return 'w3-black-blue w3-31';
    }
    if(dia === 15){
      return 'w3-white w3-rest';
    }
    return 'w3-white w3-31';
  }
  /** Metodo de obtención de fecha */
  private obtFech(isodate: any){
    const fecha = isodate.split('T')[0];
    var fech = new Date(fecha.split('-')[1]+'/'+fecha.split('-')[2]+'/'+fecha.split('-')[0]);
    return fech;
  }
  /* Funcionamiento de los filtros */
  //Filtro de años
  public cambioAnio(an: any){
    for(const anio of this.aniosDisponibles){
      an.name === anio.name? anio.check=true:anio.check=false;
    }
    this.dataTablaMostrar('cam');
    this.obtResponsableDinamico();
  }
  //Filtro de lider actas
  public cambioLider(res){
    res.check = !res.check;
    var resSelec = 0;
    this.responsablesActas.forEach(res => res.check?resSelec++:'');
    if(resSelec === this.responsablesActas.length){
      this.selecAllResActas = true;
    }else{
      this.selecAllResActas = false;
    }
    this.dataTablaMostrar('all');
  }
  public selecAllLideres(){
    this.responsablesActas.forEach(res => this.selecAllResActas?res.check=false:res.check=true);
    this.selecAllResActas = !this.selecAllResActas;
    if(this.selecAllResActas){
      this.dataTablaMostrar('all');
    }
  }
  //Filtro de responsable actividades
  public cambioResponsable(res){
    res.check = !res.check;
    var resSelec = 0;
    this.responsablesActividadesActas.forEach(res => res.check?resSelec++:'');
    if(resSelec === this.responsablesActividadesActas.length){
      this.selecAllResActiv = true;
    }else{
      this.selecAllResActiv = false;
    }
    this.dataTablaMostrar('all');
  }
  public selecAllResponsables(){
    this.responsablesActividadesActas.forEach(res => this.selecAllResActiv?res.check=false:res.check=true);
    this.selecAllResActiv = !this.selecAllResActiv;
    if(this.selecAllResActiv){
      this.dataTablaMostrar('all');
    }
  }
  //Vision de los tres puntos
  public tresPuntosVis(){
    var sel = 0;
    for(const res of this.responsablesActas){
      if(res.check){
        sel++;
      }
    }
    if(sel <= 1)
      return false;
    return true;
  }
  public tresPuntosVisResp(){
    var sel = 0;
    for(const res of this.responsablesActividadesActas){
      if(res.check){
        sel++;
      }
    }
    if(sel <= 1)
      return false;
    return true;
  }
  /** Borrar filtros */
  public borrarFiltro(){
    document.body.style.cursor = 'wait';

    const date = new Date();
    this.aniosDisponibles.forEach(an => { date.getFullYear()+'' === an.name? an.check = true:an.check = false });
    
    this.responsablesActas.forEach(res => res.check = true);
    this.responsablesActividadesActas.forEach(res => res.check = true);

    this.ordenamiento = 'id';

    this.dataTablaMostrar('cam');
    this.obtResponsableDinamico();

    setTimeout(() => {
      document.body.style.cursor = 'default';        
    }, 2000 );
  }
  /** Ordenamiento */
  public ordenamientoTabla(){
    switch(this.ordenamiento){
      case 'id':
        this.actasMostrar = this.ordenamientoAlf(this.actasMostrar);
        this.ordenamiento = 'asc';
      break;
      case 'asc':
        this.actasMostrar = this.ordenamientoAlfInv(this.actasMostrar);
        this.ordenamiento = 'des';
      break;
      case 'des':
        this.actasMostrar = this.ordenamientoPer(this.actasMostrar);
        this.ordenamiento = 'id';
      break;
    }
  }
  /* Envio de actas */
  public envioActa(acta: any){
    var responsibleIds = [], responsibleIdsValidador = [];
    responsibleIdsValidador.push(Number(acta.responsable.id));
    for(const activ of acta.actividades){
      const existe = responsibleIds.filter(id => Number(id) === Number(activ.responsable.id));
      const existeEnValidador = responsibleIdsValidador.filter(id => Number(id) === Number(activ.responsable.id));
      if(existe.length === 0 && existeEnValidador.length === 0){
        responsibleIds.push(Number(activ.responsable.id));
      }
    }
    this.altaActasService.envioActas(responsibleIds, acta.id).subscribe(response=>{
      Swal.fire({
        title: 'Acta enviada',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }, error =>{
      this.messageError(error);
    });
  }
  /* /////////////////////////////////// */
  /* Metodos de los Modales */
  /* actas nuevas */
  public abrirGuardarActas(){
    this.modalAgregarActa = true;
    document.getElementById('agregarActa')['style'].display = 'block';
  }
  public guardarActa(){
    let nombre = this.formActas.controls['inputNameActa'].value;
    let descripcion = this.formActas.controls['inputDescripcion'].value;
    let responsableId = this.formActas.controls['inputLider'].value;
    let documento = document.getElementById('archivoActa')['files'][0];

    var acta = {
      nombre: nombre,
      descripcion: descripcion,
      responsableId: responsableId,
      archivo: documento,
      planId: this.idPlaneacion,
    };

    Swal.fire({
      title: '¿Toda la información es correcta?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        alertas.swalEspera('Cargando información');
        this.altaActasService.postActas(acta).subscribe(response =>{
          Swal.close();
          this.obtenerActasNuevamente('Información agregada');
        }, error =>{
          Swal.close();
          this.messageError(error);
        });
      }
    });
  }
  public puedeGuardarActas(){
    let nombre = this.formActas.controls['inputNameActa'].value;
    let responsableId = this.formActas.controls['inputLider'].value;

    if(nombre !== '' && responsableId !== '' && !this.nombreExiste()){
      return '';
    }
    return 'w3-disabled';
  }
  /* actas editar */
  public async abrirGuardarActasEdit(acta: any){
    this.edicionActa = acta;
    this.formActasEdit.controls['inputNameActa'].setValue(this.edicionActa.nombre);
    this.formActasEdit.controls['inputDescripcion'].setValue(this.edicionActa.descripcion);
    this.formActasEdit.controls['inputLider'].setValue(this.edicionActa.responsable.id);
    if(this.edicionActa.acta !== null){
      this.nombreArchivo = this.edicionActa.acta.split('-')[this.edicionActa.acta.split('-').length-1];
    }

    this.modalEditarActa = true;
    document.getElementById('editarActa')['style'].display = 'block';
  }
  public editarArchivoActa(){
    this.editarArch = !this.editarArch;
  }
  public async guardarActaEdit(){
    let nombre = this.formActasEdit.controls['inputNameActa'].value;
    let descripcion = this.formActasEdit.controls['inputDescripcion'].value;
    let responsableId = this.formActasEdit.controls['inputLider'].value;
    let documento;

    if(this.editarArch){
      documento = document.getElementById('archivoActaEdit')['files'][0];
      if( documento !== undefined && documento["size"] > obtenerTamMax.getPeso() ){
         Swal.fire({
          title: obtenerTamMax.getTexto(),
          text: '',
          showConfirmButton: false,
          icon: 'error',
          timer: this.dataService.tiempo,
          didClose: () => {
          }
        });
        return;
      }
    }
    var acta = {
      nombre: nombre,
      descripcion: descripcion,
      responsableId: responsableId,
      archivo: documento,
      id: this.edicionActa.id,
      planId: this.idPlaneacion,
    };

    Swal.fire({
      title: '¿Toda la información es correcta?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(acta.archivo !== undefined && this.editarArch){
          alertas.swalEspera('Cargando archivos');
          this.altaActasService.patchActasfile(acta).subscribe(response =>{
            this.altaActasService.patchActas(acta).subscribe(response =>{
              Swal.close();
              this.obtenerActasNuevamente('Información editada');
            }, error =>{
              Swal.close();
              this.messageError(error);
            });
          }, error =>{
            Swal.close();
            this.messageError(error);
          });
          this.editarArch = false;
        }else{
          alertas.swalEspera('Actualizando información');
          this.altaActasService.patchActas(acta).subscribe(response =>{
            Swal.close();
            this.obtenerActasNuevamente('Información editada');
          }, error =>{
            Swal.close();
            this.messageError(error);
          });
          this.editarArch = false;
        }
      }
    });
    return;
  }
  public puedeGuardarActasEdit(){
    let nombre = this.formActasEdit.controls['inputNameActa'].value;
    let responsableId = this.formActasEdit.controls['inputLider'].value;

    if(nombre !== '' && responsableId !== '' && !this.nombreExiste()){
      return '';
    }
    return 'w3-disabled';
  }
  /* actas eliminar */
  public eliminarActa(acta){
    var text = '';
    if(acta.actividades.length !== 0){
      text = 'Se borrarán los proyectos asociadas a este plan'
    }
    Swal.fire({
      title: '¿Seguro que deseas eliminar este plan?',
      text: ""+text,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        alertas.swalEspera('Eliminando información');
        if(acta.actividades.length !== 0){
          for(const actividad of acta.actividades){
            this.eliminarActividadSinPreguntar(actividad);
          }
        }
        this.altaActasService.deleteActas(acta).subscribe(response =>{
          Swal.close();
          this.obtenerActasNuevamente('Información eliminada');
        }, error =>{
          Swal.close();
          this.messageError(error);
        });
      }
    });
  }
  /* actividades agregar */
  public abrirGuardarActividades(actas){
    this.actaId = actas.id;
    this.modalAgregarActividad = true;
    document.getElementById('agregarActividad')['style'].display = 'block';
  }
  public guardarActividades(){
    let nombre = this.formActividad.controls['inputNameAct'].value;
    let fechaIni = this.formActividad.controls['inputFechaIn'].value;
    let fechaFin = this.formActividad.controls['inputFechaFn'].value;
    let responsableId = this.formActividad.controls['inputLider'].value;

    var actividad = {
      nombre: nombre,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      responsableId: responsableId,
      actaId: this.actaId,
    };

    Swal.fire({
      title: '¿Toda la información es correcta?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.altaActasService.postActividades(actividad).subscribe(response =>{
          const acti = response;
          var adAlta = 0;
          var hist = [];
          hist = Meses.obtenerPlaneado(new Date(acti.initial_date), new Date(acti.end_date), this.diasLab);
          var i=0;
          for(const his of hist){
            this.actasService.postHistorico(his, Number(acti.id)).subscribe(response =>{
              i++;
              if(i===hist.length && this.adicionales.length !== 0){
                var act = acti.id
                var adAltas = 0;
                for(const ad of this.adicionales){
                  var adicional = {
                    activityId: act,
                    name: ad.name,
                    type: ad.type,
                  }
                  this.altaActasService.postAdicionales(adicional).subscribe(response =>{
                    adAltas++;
                    if(adAltas === this.adicionales.length){
                      this.obtenerActividadesNuevamente('Información agregada');
                    }     
                  });
                }
              }else if(i===hist.length){
                this.obtenerActividadesNuevamente('Información agregada');
              }
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
            wait(0.25); // Parche para que furule todo;
          }
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      }
    });
  }
  public puedeGuardarActividades(){
    let nombre = this.formActividad.controls['inputNameAct'].value;
    let fechaIni = this.formActividad.controls['inputFechaIn'].value;
    let fechaFin = this.formActividad.controls['inputFechaFn'].value;
    let responsableId = this.formActividad.controls['inputLider'].value;

    if(nombre !== '' && fechaIni !== '' && fechaFin !== '' && responsableId !== '' && this.formActividad.controls['inputFechaFn'].status === 'VALID'){
      return '';
    }
    return 'w3-disabled';
  }
  /* actividades editar */
  public abrirGuardarActividadesEdit(activiadad: any){
    this.edicionActividad = activiadad;
    this.formActividadEdit.controls['inputNameAct'].setValue(this.edicionActividad.nombre);
    this.formActividadEdit.controls['inputFechaIn'].setValue(this.edicionActividad.fecha_inicio);
    this.formActividadEdit.controls['inputFechaFn'].setValue(this.edicionActividad.fecha_fin);
    this.formActividadEdit.controls['inputLider'].setValue(this.edicionActividad.responsable.id);
    
    this.modalEditarActividad = true;
    document.getElementById('editarActividad')['style'].display = 'block';
  }
  public guardarActividadesEdit(){
    let nombre = this.formActividadEdit.controls['inputNameAct'].value;
    let fechaIni = this.formActividadEdit.controls['inputFechaIn'].value;
    let fechaFin = this.formActividadEdit.controls['inputFechaFn'].value;
    let responsableId = this.formActividadEdit.controls['inputLider'].value;

    var actividad = {
      nombre: nombre,
      fechaIni: fechaIni,
      fechaFin: fechaFin,
      responsableId: responsableId,
      id: this.edicionActividad.id,
    };

    var text = '';
    if(this.edicionActividad.history.length !== 0 && this.comprobacionCambioFecha() ){
      text = 'Se borraran los avances ingresados';
    }

    Swal.fire({
      title: '¿Toda la información es correcta?',
      text: ""+text,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.edicionActividad.history.length !== 0 && this.comprobacionCambioFecha() ){
          for(const his of this.edicionActividad.history){
            this.altaActasService.deleteHistory(his).subscribe(response =>{
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        }
        this.altaActasService.patchActividades(actividad).subscribe(response =>{
          this.obtenerActividadesNuevamente('Información agregada');
        },error =>{
          this.messageError(error);
        });
      }
    });
  }
  public puedeGuardarActividadesEdit(){
    let nombre = this.formActividadEdit.controls['inputNameAct'].value;
    let fechaIni = this.formActividadEdit.controls['inputFechaIn'].value;
    let fechaFin = this.formActividadEdit.controls['inputFechaFn'].value;
    let responsableId = this.formActividadEdit.controls['inputLider'].value;

    if(nombre !== '' && fechaIni !== '' && fechaFin !== '' && responsableId !== '' && this.formActividadEdit.controls['inputFechaFn'].status === 'VALID'){
      return '';
    }
    return 'w3-disabled';
  }
  /* Eliminación de actividades */
  public eliminarActividad(actividad){
    Swal.fire({
      title: '¿Seguro que deseas eliminar este proyecto?',
      text: "",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.altaActasService.deleteActividades(actividad).subscribe(response =>{
          this.obtenerActividadesNuevamente('Información eliminada');
        }, error =>{
          this.messageError(error);
        });
      }
    });
  }
  public eliminarActividadSinPreguntar(actividad){
    this.altaActasService.deleteActividades(actividad).subscribe(response =>{
    }, error =>{
      this.messageError(error);
    });
  }
  /* adicionales agregar */
  public agregarAdicional(){
    this.modalAgregarAdicional = true;
    document.getElementById('agregarAdicional')!.style.display = 'block';
  }
  public async guardarAdi(){
    var name =  this.formAdicional.controls['inputName'].value;
    var type =  this.formAdicional.controls['inputType'].value;
    
    var actAltas = 0;
    this.totalAct = this.actas.length;   

    alertas.swalEspera('Agregando adicional');
    for(const acta of this.actas){
      for(const act of acta.actividades){
        var adicional = {
          activityId: act.id,
          name: name,
          type: type,
        }
        this.altaActasService.postAdicionales(adicional).subscribe(response =>{
          actAltas++;
          if(actAltas === this.totalAct){
            Swal.close();
            this.obtenerActividadesNuevamente('Adicional agregado');
          }     
        });
      }
    }
  }
  public puedeGuardarAdi(){
    let nombre =  this.formAdicional.controls['inputName'].value;
    let tipo =  this.formAdicional.controls['inputType'].value;
    if(nombre != '' && tipo != '' && !this.nombreExisteAdi()){
      return '';
    }
    return 'w3-disabled';
  }
  /* edición adicional */
  public async edicionAdicional(actividad: any, idAdicional: any){
    var inputValue: any, introdujo: any, guardo = false;
    inputValue  = actividad.adicionales[idAdicional].amount;

    introdujo = await Swal.fire({
      title: 'Ingresa el monto',
      text: "",
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Ingeresa un dato valido';
        }
        guardo = true;
        return '';
      }
    });
    
    if(guardo){
      actividad.adicionales[idAdicional].amount = introdujo["value"];
      this.altaActasService.patchAdicionales(actividad.adicionales[idAdicional],actividad.id).subscribe(response =>{
        this.obtenerActividadesNuevamente('Información actualizada');
      },err =>{
        this.messageError(err);
      });
    }else {
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public async edicionAdicionalN(actividad: any, idAdicional: any){
    var inputValue: any, introdujo: any, guardo = false;
    inputValue  = this.obtComment(actividad.adicionales[idAdicional].comments);

    introdujo = await Swal.fire({
      title: 'Ingresa el valor',
      text: "",
      input: 'text',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Ingeresa un dato valido';
        }
        guardo = true;
        return '';
      }
    });
    
    if(guardo){
      var existe = false;
          var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
          for(const comen of actividad.adicionales[idAdicional].comments){
            var percom = new Date(comen.created_at).getFullYear();
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.altaActasService.patchComment(comen, actividad.adicionales[idAdicional].id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                this.messageError(err)
              });
            }
          }
          if(!existe){
            var date = new Date('1-1-'+Number(this.aniosDisponibles.filter(an => an.check)[0].name));
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString(),
              id: 0,
            }
            this.altaActasService.postComment(aux, actividad.adicionales[idAdicional].id).subscribe(response =>{
              aux.id = response.id;
              actividad.adicionales[idAdicional].comments.push(aux);
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            },err =>{
              this.messageError(err);
            });
          }
    }else {
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  /*  */
  private comprobacionCambioFecha(){
    let fecIn = new Date(this.formActividadEdit.controls['inputFechaIn'].value);
    let fecFn = new Date(this.formActividadEdit.controls['inputFechaFn'].value);
    let fecInAct = this.edicionActividad.fecha_inicio;
    let fecFnAct = this.edicionActividad.fecha_fin;
    if(fecIn.getFullYear() !== fecInAct.getFullYear() || fecIn.getMonth() !== fecInAct.getMonth() || fecIn.getDate() !== fecInAct.getDate()){
      return true;
    }
    if(fecFn.getFullYear() !== fecFnAct.getFullYear() || fecFn.getMonth() !== fecFnAct.getMonth() || fecFn.getDate() !== fecFnAct.getDate()){
      return true;
    }
    return false
  }
  /* Mensaje de error */
  private messageError(err){
    let er = obtenerError.errorObt(err['error'].message);
    Swal.fire({
      title: er,
      text: '',
      icon: 'error',
      showConfirmButton: false,
      timer: this.dataService.tiempo,
    });
  }
  /* Cerrar modales */
  public close_modal(){
    this.modalAgregarActa = false;
    this.modalEditarActa = false;
    this.modalAgregarActividad = false;
    this.modalEditarActividad = false;
    this.modalAgregarAdicional = false;

    this.editarArch = false;

    document.getElementById('agregarActa')!.style.display = 'none';
    document.getElementById('editarActa')!.style.display = 'none';
    document.getElementById('agregarActividad')!.style.display = 'none';
    document.getElementById('editarActividad')!.style.display = 'none';
    document.getElementById('agregarAdicional')!.style.display = 'none';

    this.formActas.reset();
    this.formActasEdit.reset();
    this.formActividad.reset();
    this.formActividadEdit.reset();
    this.formAdicional.reset();
  }
  /* Exportación */
  public exportarExcel(){
    let headers = [];
    let proyectos = [];
    let actividades = [];
    if (this.mostabla) {
      let mess = [];
      for(const m of this.meses){
        mess.push(m.name);
      }
      let actAdic = [];
      if(this.actasMostrar[0].actividades[0].adicionales.length > 0){
        for(const aa of this.actasMostrar[0].actividades[0].adicionales){
          actAdic.push(aa.name);
        }
      }
      headers.push(['Planes','Líder/Responsable','Inicio','Días','Fin',...actAdic]);

      for(const acta of this.actasMostrar){
          let adP = [];
          let acts = [];
          if (acta.actividades.length > 0) {         
            for (let l = 0; l < acta.actividades[0].adicionales?.length!; l++) {
              if (this.edicionPro(acta, l)) {
                adP.push(this.imprimirPro(acta, l));
              } else {
                adP.push('');
              }
            } 
          }

          proyectos.push([
            acta.nombre,
            acta.responsable.position,
            this.obtFecha(acta.fecha_ini),
            this.obtDias(acta.fecha_ini, acta.fecha_fin),
            this.obtFecha(acta.fecha_fin),
            ...adP
          ])
          let adAct = [];

          for(const acti of acta.actividades){
            let a = []
            let aux = 0;
            if(acti.adicionales.length !== 0){
              for(var j = 0; j < acti.adicionales.length; j++){
                a.push(acti.adicionales[j].type==='moneda'?this.formatearnormal(acti.adicionales[j].amount):'')
                aux++;
              }
            }
            adAct.push(a)
            acts.push([
              '   '.concat(acti.nombre),
              acti.responsable.position,
              this.obtFecha(acti.fecha_inicio),
              this.obtDias(acti.fecha_inicio, acti.fecha_fin),
              this.obtFecha(acti.fecha_fin),
              ...a
            ])
          }
          actividades.push(acts);

      }
      let filtros = []
      this.excelService.generateExcelActasFormulacion("Formulación: Alta planes", headers, proyectos, actividades,  filtros, "alta_planes");
    }
  }
  /** Formato de cantidades */
  public formatearnormal(num: any){
    if(num === null){
      return 0;
    }
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  public formatearConDecimales(num: any){
    num = '' + num.toFixed(2);
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if (splitRight === '.00')
      return  splitLeft;

    return  splitLeft + splitRight;
  }
  /** Metodos de ordenamiento */
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAdi(array: any){
    if(array && array.length!==0){
      array.sort(function (a: any, b: any) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      return array;
    }else{
      return [];
    }
  }
  private ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlfInv(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modalAgregarActa){
        nombre = document.getElementById('nomActa')["value"];
      }else if(this.modalEditarActa){
        nombre = document.getElementById('nomActaEdit')["value"];
      }
      const existe = this.actas.find( element => {
        if(this.modalEditarActa){
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.edicionActa.id);
        }
        return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};
      return null;
    };
  }
  public nombreExiste():  boolean  {    
    return  this.formActas.hasError('isValid');
  }
  private nombreDuplicidadAdi(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modalAgregarAdicional){
        nombre = document.getElementById('adicionalNombre')["value"];
      }
      if(this.actas.length !== 0){
        for(const acta of this.actas){
          if(acta.actividades.length !== 0 && acta.actividades[0].adicionales.length !== 0){
            const existe = acta.actividades[0].adicionales.find( element => {
              return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() );
            });
            if(existe) return {isValid:true};
            else{break;}
          }
        }
      }
      
      return null;
    };
  }
  public nombreExisteAdi():  boolean  {    
    return  this.formAdicional.hasError('isValid');
  }
  /* Edición y eliminación de adicionales */
  public async editarAdicional(adi: any){
    var guardo = false;
    var introdujo = await Swal.fire({
      title: 'Ingrese el nombre del adicional',
      text: "",
      input: 'text',
      inputValue: adi.name,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: (value) => {
        guardo = true;
        return '';
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Editando adicional');
      for(const acta of this.actas){
        for(const acti of acta.actividades){
          const adEdit = acti.adicionales.filter(ad => ad.name === name && ad.type === adi.type);
          if(adEdit.length !== 0){
            adEdit[0].name = introdujo.value;
            this.altaActasService.patchAdicionalesNombre(adEdit[0]).subscribe(response =>{
              edit++;
              if(edit === this.actas.length){
                this.altaActasService.getActividadesActas().subscribe(response => {
                  Swal.close();
                  this.obtenerActividadesNuevamente(response);
                });
              }
            }, error =>{
              alertas.messageError(error, this.dataService.tiempo);
            });
          }
        }
      }
    }
  }
  public async eliminarAdicional(adi: any){
    var guardo = false;
    await Swal.fire({
      title: '¿Esta seguro de eliminar este adicional?',
      text: "Esta acción borrara permanente la información contenida en el adicional.",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        guardo = true;
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Eliminando adicional');
      for(const acta of this.actas){
        for(const acti of acta.actividades){
          const adEdit = acti.adicionales.filter(ad => ad.name === name && ad.type === adi.type);
          if(adEdit.length !== 0){
            this.altaActasService.deleteAdicional(adEdit[0]).subscribe(response =>{
              edit++;
              if(edit === this.actas.length){
                Swal.close();
                this.obtenerActividadesNuevamente('Adicional eliminado');
              }
            }, error =>{
              alertas.messageError(error, this.dataService.tiempo);
            });
          }
        }
      }
    }
  }
}
