import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Filosofia_Corporativa, Planificacion, Instituciones, procesamiento, obtenerError } from './../../../model/datos';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FilosofiacorporativaService } from '../../../services/formulacion/filosofiacorporativa/filosofiacorporativa.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
@Component({
  selector: 'app-filosofiacorporativa',
  templateUrl: './filosofiacorporativa.component.html',
  styleUrls: ['./filosofiacorporativa.component.css']
})
export class FilosofiacorporativaComponent implements OnInit {

  public instituciones: Instituciones[] = [];
  public planeacion: Planificacion[] = [];
  public concepto: Filosofia_Corporativa[] = [];

  public idconc: number = 0;
  public idPlanificacion: any;
  public objfilo:any = {nombre:'', descripcion:''};
  public filosofia: any; //para el edit
  public modal = false;
  public modal2 = false;

  /** Grupo de validación de datos */
  formNuevo = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputDescription: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  formEdit = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputDescription: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  constructor(
    public dataService: DataService,
    private filosofiaService: FilosofiacorporativaService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) { 
    this.obtData(); 
    var param = this.rutaActiva.snapshot.params;
    this.idPlanificacion = Number(param["planeacion"]);
  }

  ngOnInit(): void { }

  private ordenarConceptos(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /** Obtención de los datos */
  public obtData(){
    this.filosofiaService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive
          });
        }
      }
      this.filosofiaService.getFilosofia().subscribe(response => {
        this.obtFilosofia(response);
      });
    });
  }
  private obtFilosofia(response: any){
    this.concepto = [];
    for (const per of response){
      if(Number(per.planning.id) === this.idPlanificacion){
        this.concepto.push({
          nombre: per.name,
          descripcion: per.description,
          planningId: per.planning.id,
          id: per.id
        });
      }
    }
    if(this.concepto.length === 0){
      //crear misión, visión, principios
      var conceptodef1 = new Filosofia_Corporativa();
      var conceptodef2 = new Filosofia_Corporativa();
      var conceptodef3 = new Filosofia_Corporativa();
      conceptodef1.planningId = this.idPlanificacion;
      conceptodef1.nombre = 'Misión';
      conceptodef1.descripcion = 'Misión';
      conceptodef2.planningId = this.idPlanificacion;
      conceptodef2.nombre = 'Visión';
      conceptodef2.descripcion = 'Visión';
      conceptodef3.planningId = this.idPlanificacion;
      conceptodef3.nombre = 'Principios';
      conceptodef3.descripcion = 'Principios';
      this.filosofiaService.postfilosofia(conceptodef1).subscribe(response =>{
        this.filosofiaService.postfilosofia(conceptodef2).subscribe(response => {
          this.filosofiaService.postfilosofia(conceptodef3).subscribe(response => {
            this.filosofiaService.getFilosofia().subscribe( response => {
              this.concepto = [];
              for (const fil of response){
                if(Number(fil.planningId) === this.idPlanificacion){
                  this.concepto.push({
                    nombre: fil.name,
                    descripcion: fil.description,
                    planningId: fil.planningId,
                    id: fil.id
                  });
                }
              }
            });
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      })
    }
    this.concepto = this.ordenarConceptos(this.concepto);
  }
  /** Comprobación antes del guardado */
  public alertas(aler: any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatos();
        }
      });
    }
  }
  /** Almacenamiento de datos */
  public almacenarDatos(){
    var concept = new Filosofia_Corporativa();
    concept.nombre = document.getElementById('nombre')["value"];
    concept.descripcion = document.getElementById('descripcion')["value"];
    concept.planningId = this.idPlanificacion;
    this.filosofiaService.postfilosofia(concept).subscribe(response => {
      this.filosofiaService.getFilosofia().subscribe(response => {
        this.concepto = [];
        for(const con of response){
          if(Number(con.planningId) === this.idPlanificacion){
            this.concepto.push({
              id: con.id,
              nombre: con.name,
              descripcion: con.description,
              planningId: con.planningId,
            })
          }
        }
        this.concepto = this.ordenarConceptos(this.concepto);
        Swal.fire({
          title: 'Elemento agregado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      })
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.cerrarForm();
  }
  public almacenarDatosEdit(){
    this.filosofia.nombre = document.getElementById('nombreedit')["value"];
    this.filosofia.descripcion = document.getElementById('descripcionedit')["value"];
    this.filosofiaService.patchfilosofia(this.filosofia).subscribe(response =>{
      this.filosofiaService.getFilosofia().subscribe(response => {
        this.concepto = [];
        for(const con of response){
          if(Number(con.planningId) === this.idPlanificacion){
            this.concepto.push({
              id: con.id,
              nombre: con.name,
              descripcion: con.description,
              planningId: con.planningId,
            })
          }
        }
        this.concepto = this.ordenarConceptos(this.concepto);
        Swal.fire({
          title: 'Elemento actualizado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    document.getElementById('nombreedit')["value"] = '';
    document.getElementById('descripcionedit')["value"] = '';
    document.getElementById('formulario')!.style.display = 'none';
  }
  /** Metodos de guardado */
  public puede_guardar(){
    var concept = new Filosofia_Corporativa();
    concept.nombre = document.getElementById('nombre')["value"];
    concept.descripcion = document.getElementById('descripcion')["value"];
    if(concept.nombre != '' && concept.descripcion != '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    var concept = new Filosofia_Corporativa();
    concept.nombre = document.getElementById('nombreedit')["value"];
    concept.descripcion = document.getElementById('descripcionedit')["value"];
    if(concept.nombre != '' && concept.descripcion != '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Eliminación de datos */
  public eliminar(filos: any){
    this.filosofiaService.deletefil(filos).subscribe(response =>{
      this.filosofiaService.getFilosofia().subscribe(response => {
        this.obtFilosofia(response);
        Swal.fire({
          title: 'Elemento eliminado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Modal */
  // Abrir
  public abrirFormAgregar(){
    this.modal = true;
    document.getElementById('formularionuevo')!.style.display = 'block';
  }
  public abrirForm(filo: any){
    this.filosofia = filo;
    this.idconc = filo.id;
    this.modal2 = true;
    this.formEdit.controls['inputName'].setValue(this.filosofia.nombre);
    this.formEdit.controls['inputDescription'].setValue(this.filosofia.descripcion);

    document.getElementById('formulario')!.style.display = 'block'
  }
  //Cerrar
  public cerrarForm(){
    document.getElementById('formularionuevo')!.style.display = 'none';
    document.getElementById('formulario')!.style.display='none';
    this.modal2 = false;
    this.modal = false;
    this.formNuevo.reset();
    this.formEdit.reset();
  }
  /** Exportación */
  public exportar_excel(){
    const title = 'Formulación: Filosofía corporativa';
    const header = ["Nombre", "Descripción"];
    let rows = [];
    let cols = [];
    for(var i = 0; i < this.concepto.length; i++){
      cols.push(this.concepto[i].nombre);
      cols.push(this.concepto[i].descripcion);
      rows.push(cols);
      cols = [];
    }

    this.excelService.generateExcelFilosofiaCorp(title, header, rows, "Filosofia_corporativas");
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nombre')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nombreedit')["value"];
      }
      const existe = this.concepto.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.filosofia.id );
        }
        return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formNuevo.hasError('isValid');
  }
  /** Capitalización del texto */
  public capitalizacionTexto(id){
    const input = document.getElementById(id);
    var palabra = input['value'];
    if(!input['value']) return;
    var mayuscula = palabra.substring(0,1).toUpperCase();
    if (palabra.length > 0) {
      var minuscula = palabra.substring(1).toLowerCase();
    }
    input['value'] = mayuscula.concat(minuscula);
  }
}