import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class historico{
  period?: string;
  planned?: number;
  executed?: number;
}

export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 
@Injectable({
  providedIn: 'root'
})
export class ProgramasService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlaneacion(): Observable< any >{
    const apiURL = this.apiURL+"planificacion/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAreas(): Observable< any >{
    const apiURL = this.apiURL+"area/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjectives(): Observable< any >{
    const apiURL = this.apiURL+"objetivos/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProgramas(): Observable< any >{
    const apiURL = this.apiURL+"estrategia/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectos(): Observable< any >{
    const apiURL = this.apiURL+"proyectos/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectosId(id): Observable< any >{
    const apiURL = this.apiURL+"proyectos/"+id;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  
  public patchAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      amount: Number(adicional.amount),
      files: adicional.files,
      note: adicional.note,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public postHistorico(his: any, idAct: number): Observable<any>{
    const hist: historico = {
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public PatchHistorico(his: any, idAct: number, idHis: number): Observable<any>{
    const hist: historico = {
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
