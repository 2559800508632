import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data-service/data.service';
import { Instituciones, Perspectiva, Planificacion, Objetivos, Proyectos, Actividades, Responsables, Adicionales, procesamiento, obtenerError, alertas, Meses} from '../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectosestrategicosService } from '../../../services/formulacion/proyectosestrategicos/proyectosestrategicos.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

function wait(espera_segundos) {
  var espera = espera_segundos * 1000
  const tiempo_inicio = Date.now();
  let tiempo_actual= null;
  do {
    tiempo_actual= Date.now();
  } while (tiempo_actual - tiempo_inicio < espera);
}

@Component({
  selector: 'app-proyectosestrategicos',
  templateUrl: './proyectosestrategicos.component.html',
  styleUrls: ['./proyectosestrategicos.component.css']
})
export class ProyectosestrategicosComponent implements OnInit {
  // declaración de variables
  public aniosDisponibles = [ {name: '2022', check: true}, {name: '2023', check: false},]
  public meses = [{name: 'ene', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'feb', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]}, 
  {name: 'mar', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'abr', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'may', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'jun', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'jul', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15},]},
  {name: 'ago', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'sep', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'oct', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'nov', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]},
  {name: 'dic', value: [{name: 1},{name: 2},{name: 3},{name: 4},{name: 5},{name: 6},{name: 7},{name: 8},{name: 9},{name: 10},
    {name: 11},{name: 12},{name: 13},{name: 14},{name: 15}]}];
  public checks = [{check: false}];
  public adicional: Adicionales[] = [];
  // declaración de variables
  public instituciones: Instituciones[] = [];
  public planeaciones: Planificacion[] = [];
  public perspectivas: Perspectiva[] = [];
  public objetivos: Objetivos[] = [];
  public estrategias: any[] = [];
  public proyectos = [];
  public proyectosTot = [];
  public responsables: Responsables[] =[];
  public responsables2: Responsables[] =[];
  public responsablesAct: Responsables[] =[];

  public actividades: Actividades[] = [];

  public perSelect: any;
  public objSelect: any;
  public progSelect: any;
  public estSelect: any;
  public resSelect: any;
  public resSelectAct: any;
  public tipSelect: any;
  public selecAll = true;
  public selecAllAct = true;

  public proyectoId: any;
  public modal = false;
  public modal2 = false;
  public modal3 = false;
  public modal4 = false;
  public modal5 = false;
  public actEdit: any;
  public proEdit: any;
  public mostabla = false;

  minDate = new Date(2022, 0, 1);
  maxDate = new Date(2022, 11, 31);
  private diasLab: any;

  // **** Variables para el formateo de numeros
  public separador:string =  ","; // separador para los miles
  public sepDecimal:string = '.'; // separador para los decimales

  public diccionarioAdicionales: any = [];
  public expandirProg = false;

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  public ObjecSelect: any;
  private adicionalesPost = 0;
  
  public idPlanificacion: any;
  public  ordenamiento = 'id';

  /** Grupo de validación de datos */
  formPro = new FormGroup({ 
    inputNamePro: new FormControl('', [Validators.required]),
    inputDescripcion: new FormControl(''),
    inputEstrategia: new FormControl(''),
    inputObjetivo: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),    
  },{ validators: this.nombreDuplicidad() });
  formProEdit = new FormGroup({ 
    inputNamePro: new FormControl('', [Validators.required]),
    inputDescripcion: new FormControl(''),
    inputEstrategia: new FormControl(''),
    inputObjetivo: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),
    
  },{ validators: this.nombreDuplicidad() });

  formAct = new FormGroup({ 
    inputNameAct: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),
  });
  formActEdit = new FormGroup({ 
    inputNameAct: new FormControl('', [Validators.required]),
    inputFechaIn: new FormControl('', [Validators.required]),
    inputFechaFn: new FormControl('', [Validators.required]),
    inputLider: new FormControl('', [Validators.required]),

  });

  formAdi = new FormGroup({ 
    inputNameAdi: new FormControl('', [Validators.required]),
    inputNameAdiEdit: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidadAdi() });

  //constructor de la clase
  constructor(
    private router: Router,
    public dataService: DataService,
    private proyextosestrategicoService: ProyectosestrategicosService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
    private translate: TranslateService
  ) { 
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param["planeacion"]);
    this.obtDat();
  }
  
  ngOnInit(): void {
    
  }
  /** Formateo de datos */
  public formatearnormal(num: any){
    num +='';
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    return  splitLeft;
  }
  public formatearnormalDec(num: any){
    num = '' + num.toFixed(2);
    var splitStr = num.split('.');
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
    }
    if (splitRight === '.00')
      return  splitLeft;

    return  splitLeft + splitRight;
  }
  /** Metodos de ordenamiento */
  private ordenamientoPer(array: any){
    array.sort(function (a: any, b: any) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      }
      if (Number(a.id) < Number(b.id)) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAdi(array: any){
    array.sort(function (a: any, b: any) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlf(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  private ordenamientoAlfInv(array: any){
    array.sort(function (a: any, b: any) {
      if ( a.nombre.toLowerCase() < b.nombre.toLowerCase() ) {
        return 1;
      }
      if ( a.nombre.toLowerCase() > b.nombre.toLowerCase() ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return array;
  }
  //** Obtención de los datos */
  private obtDat(){
    this.proyextosestrategicoService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          const fechaini = new Date(plan.initial_date);
          const fechafin = new Date(plan.end_date);
          this.diasLab = plan.business_days;
          switch(plan.business_days){
            case 'Lunes-Viernes':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return day !== 0 && day !== 6;
              };
            break;
            case 'Lunes-Sabado':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return day !== 0;
              };
            break;
            case 'Lunes-Domingo':
              this.myFilter = (d: Date | null): boolean => {
                const day = (d || new Date()).getDay();
                // Prevent Saturday and Sunday from being selected.
                return true;
              };
            break;
          }
          this.aniosDisponibles = [];
          for(var i = fechaini.getFullYear(); i <= fechafin.getFullYear(); i++){
            this.aniosDisponibles.push({
              name: ''+i,
              check: false,
            });
          }
          var today = new Date().getFullYear(), existe = false;
          for(const an of this.aniosDisponibles){
            if(an.name === ''+today){
              an.check = true;
              existe = true;
              break;
            }
          }
          if(!existe){
            this.aniosDisponibles[this.aniosDisponibles.length-1].check = true;
          }
          this.minDate = fechaini;
          this.maxDate = fechafin;

          this.proyextosestrategicoService.getResponsable().subscribe(response => {
            this.responsables = [];
              for(const are of response){
                if(are.institution.id === plan.institution.id){
                  for(const res of are.responsibles){
                    this.responsables.push({
                      nombre: res.position,
                      id: res.id,
                      check: true,
                    });
                  }
                }
              }
              this.responsables = this.ordenamientoAlf(this.responsables);
          });
        }
      }
    })
    this.proyextosestrategicoService.getPerspectivas().subscribe(response => {
      this.perPers(response);
      this.perspectivas =  this.ordenamientoPer(this.perspectivas);
      this.objetivos = this.ordenamientoPer(this.objetivos);
      this.proyextosestrategicoService.getProyectos().subscribe(response => {
        this.perProyec(response);
        this.proyectos = this.ordenamientoPer(this.proyectos);
        this.proyextosestrategicoService.getActividades().subscribe(response => {
          this.perActiv(response);
          this.mostabla = true;
        });
      });
      this.proyextosestrategicoService.getEstrategias().subscribe(response => {
        this.estrategias = [];
        for(const est of response){
          for(const obj of this.objetivos){
            if(est.objectives.length !== 0 && obj.id === est.objectives[0].id){
              this.estrategias.push({
                name: est.name,
                description: est.description,
                objetiveId: est.objectives, 
                id: est.id
              });
            }
          }
        }
        this.estrategias = this.ordenamientoPer(this.estrategias);
      });
    });
  }
  /* */
  /** Clasificación de los datos */
  public perPers(array: any){
    var aux = [], aux2 = [];
    for(const per of array){
      if(Number(per.planning.id) === Number(this.idPlanificacion)){        
        aux.push({
          nombre: per.name,
          descripcion: per.descripcion,
          planningId: per.planning.id,
          id: per.id,
          check: true,
        });
        for(const obj of per.objectives){
          aux2.push({
            nombre: obj.name,
            descripcion: obj.description, 
            ponderacion: obj.weighing, 
            perspectiveId: per.id, 
            id: obj.id
          })
        }
      }
    }
    this.perspectivas = [];
    this.objetivos = [];
    this.perspectivas = aux;
    this.objetivos = aux2;
  }
  public perProyec(array: any){
    var aux = [];
    this.checks = [];
    for(const pro of array){
      for( const ob of this.objetivos){        
        if(pro.objectives.length !== 0 && pro.objectives[0].id === ob.id){
          this.crearHistoric(pro);

          var objectiveid = [];
          for(const objid of pro.objectives){
            objectiveid.push(objid.id)
          }
          var estrategiaId: any = [];
          for(const estId of pro.strategies){
            estrategiaId.push(estId.id)
          }
          this.checks.push({check: false});
          aux.push({
            nombre: pro.name,
            ponderacion: pro.historicWeighing,
            objetiveId: objectiveid, 
            programasId: estrategiaId,
            responsableId: pro.responsible.id,
            id: pro.id,
            descripcion: pro.description,
            actividades: [],
            check: false,
          });
          break;
        }
      }
    }
    this.proyectos = [];
    this.proyectos = aux;
  }
  private crearHistoric(pro: any){
    if(pro.historicWeighing.length === 0 && pro.projectWeighing.length !== 0 && pro.activities.length !== 0){
      const peso = pro.projectWeighing[0].projectWeighing;

      var fecha_ini = undefined;
      for(const act of pro.activities){
        if(fecha_ini === undefined){
          fecha_ini = new Date(act.initial_date);
        }else{
          if(fecha_ini > new Date(act.initial_date)){
            fecha_ini = new Date(act.initial_date);
          }
        }
      }
      var anio = fecha_ini.getFullYear();

      this.proyextosestrategicoService.postHistoricoPeso(peso, pro.id,anio+'').subscribe(response =>{

      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
  }
  public async perActiv(array: any){
    var aux = [];
    for(const act of array){
      for(const pro of this.proyectos){
        if(pro.id === act.project.id){
          aux.push({
            nombre: act.name,
            fecha_inicio: new Date(act.initial_date),
            fecha_fin: new Date(act.end_date), 
            projectId: act.project.id, 
            responsableId: act.responsible.id,
            id: act.id,
            adicionales: this.ordenamientoAdi(act.additions),
            history: act.history,
          });
        }
      }
    }
    this.actividades = [];
    this.actividades = aux;
    this.actividades = this.ordenamientoPer(this.actividades);
    
    var actAdExist = [];
    for(const act of this.actividades){
      for(const adic of act.adicionales){
        const existe = actAdExist.filter(ad => ad.name === adic.name);
        if(existe.length !== 0){
          this.proyextosestrategicoService.deleteAdicional(adic).subscribe(response=>{

          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }else{
          actAdExist.push(adic);
        }
      }
      actAdExist = [];
    }

    if(this.actividades[0].adicionales.length !== 0){
      for(const act of this.actividades){
        if(act.adicionales.length === 0){
          await this.altaAdicionales(act);   
        }
      }
    }
    for(const pro of this.proyectos){
      const act = this.actividades.filter(act => act.projectId === pro.id );
      pro.actividades = act;
    }
    
    this.proyectosTot = [].concat(this.proyectos);
    this.obtfechasProy();
    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public async perActivAct(array: any){
    var aux = [];
    for(const act of array){
      for(const pro of this.proyectosTot){
        if(pro.id === act.project.id){
          aux.push({
            nombre: act.name,
            fecha_inicio: new Date(act.initial_date),
            fecha_fin: new Date(act.end_date), 
            projectId: act.project.id, 
            responsableId: act.responsible.id,
            id: act.id,
            adicionales: this.ordenamientoAdi(act.additions),
            history: act.history,
          });
        }
      }
    }
    this.actividades = [];
    this.actividades = aux;
    this.actividades = this.ordenamientoPer(this.actividades);
    
    var actAdExist = [];
    for(const act of this.actividades){
      for(const adic of act.adicionales){
        const existe = actAdExist.filter(ad => ad.name === adic.name);
        if(existe.length !== 0){
          this.proyextosestrategicoService.deleteAdicional(adic).subscribe(response=>{

          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }else{
          actAdExist.push(adic);
        }
      }
      actAdExist = [];
    }

    if(this.actividades[0].adicionales.length !== 0){
      for(const act of this.actividades){
        if(act.adicionales.length === 0){
          await this.altaAdicionales(act);   
        }
      }
    }
    for(const pro of this.proyectosTot){
      const act = this.actividades.filter(act => act.projectId === pro.id );
      pro.actividades = act;
    }
    
    this.proyectosTot = [].concat(this.proyectosTot);
    this.obtfechasProy();
    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public async altaAdicionales(act){
    for(const ad of this.actividades[0].adicionales){
      var adic = new Adicionales();
      adic.nombre = ad.name;
      adic.tipo = ad.type;
      adic.actividadId = act.id;
      adic.nota = '';
      adic.archivos = '';
      adic.monto = 0;

      await this.proyextosestrategicoService.postAdicionales(adic).subscribe(response =>{
        this.proyextosestrategicoService.getActividades().subscribe(response => {
          this.perActiv(response);
          this.actividades = this.ordenamientoPer(this.actividades);
          this.obtfechasProy();
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
  }
  public obtfechasProy(){
    for(const pro of this.proyectos){
      pro.fecha_fin = undefined;
      pro.fecha_ini = undefined;
      for(const act of this.actividades){
        if(pro.id === act.projectId){
          if(pro.fecha_ini === undefined){
            pro.fecha_ini = act.fecha_inicio;
          }else{
            if(pro.fecha_ini > act.fecha_inicio){
              pro.fecha_ini = act.fecha_inicio;
            }
          }
          if(pro.fecha_fin === undefined){
            pro.fecha_fin = act.fecha_fin;
          }else{
            if(pro.fecha_fin < act.fecha_fin){
              pro.fecha_fin = act.fecha_fin;
            }
          }
        }
      }
    }
  }
  /** Obtención de los datos */
  public obtFecha(fec: any){
    var fecha =  '';
    if(fec != undefined){
      switch(fec.getDate()){
        case 1: fecha+='01-'; break;
        case 2: fecha+='02-'; break;
        case 3: fecha+='03-'; break;
        case 4: fecha+='04-'; break;
        case 5: fecha+='05-'; break;
        case 6: fecha+='06-'; break;
        case 7: fecha+='07-'; break;
        case 8: fecha+='08-'; break;
        case 9: fecha+='09-'; break;
        default: fecha+= fec.getDate()+'-'; break;
      }
      switch((fec.getMonth()+1)){
        case 1: fecha+='01'; break;
        case 2: fecha+='02'; break;
        case 3: fecha+='03'; break;
        case 4: fecha+='04'; break;
        case 5: fecha+='05'; break;
        case 6: fecha+='06'; break;
        case 7: fecha+='07'; break;
        case 8: fecha+='08'; break;
        case 9: fecha+='09'; break;
        default: fecha+= (fec.getMonth()+1)+''; break;
      }
      fecha +='-'+fec.getFullYear();
    }
    return fecha;
  }
  public obtDias(ini: any, fin: any){
    if(ini != undefined && fin != undefined){
      if( (fin - ini) === 0){
        return 1;
      }
      var dias = (fin-ini)/(1000*60*60*24);
      var semanas = Math.trunc(dias/7);
      dias = dias - (semanas*2);
      return Math.round(dias)
    }
    return '';
  }
  /** Metodos que deciden que pertenece a los datos */
  private pertenece(pro: any){
    if(this.objSelect !== undefined){
      for(const obj of pro.objetiveId){
        if((obj) === this.objSelect){
          return true;
        }
      }
      return false;
    }else{
      return true;
    }
  }
  private perteneceProg(pro: any){
    if(this.estSelect != undefined){
      for(const obj of pro.programasId){
        if(obj === this.estSelect){
          return true;
        }
      }
      return false;
    }else{
      return true;
    }
  }
  private pertenece_perspective(pro: any){
    var obj = [];
    for(const obje of pro.objetiveId){
      const om = this.objetivos.filter(obb => obb.id === obje);
      obj = obj.concat(om);
    }
    const ex = obj.filter(obj => obj.perspectiveId === this.perSelect)
    if( ex.length !== 0 ){
      return true;
    }
    return false;
  }
  public programa_pertenece(ObjSel: any, pro: any){
    for(const obj of pro.objetiveId){
      if(obj.id === ObjSel){
        return true;
      }
    }
    return false;
  }
  public obtres(res: any){
    for(const re of this.responsables){
      if(re.id === res){
        return re.nombre;
      }
    }
    return '';
  }
  /** Modales */
  public agregarPro(){
    this.modal = true;
    //this.ObjecSelect = [this.objSelect];
    document.getElementById('agregarPro')!.style.display = 'block';
    document.getElementById('inicioPro').scrollIntoView(true);
  }
  public editarPro(pro: any){
    this.proEdit = pro;
    this.formProEdit.controls['inputNamePro'].setValue(pro.nombre);
    this.formProEdit.controls['inputDescripcion'].setValue(pro.descripcion);
    if(pro.programasId[0] !== undefined){
      this.formProEdit.controls['inputEstrategia'].setValue(pro.programasId[0]);
    }
    this.formProEdit.controls['inputObjetivo'].setValue(pro.objetiveId[0]);
    this.formProEdit.controls['inputLider'].setValue(pro.responsableId);

    this.modal2 = true;
    document.getElementById('editPro')!.style.display = 'block';
    document.getElementById('inicioProEdit').scrollIntoView(true);
  }
  public agragarActividades(idPro: any){
    this.modal3 = true;
    this.proyectoId = idPro;
    document.getElementById('agregarAct')!.style.display = 'block';
    document.getElementById('inicioAct').scrollIntoView(true);
  }
  public editActiv(actividad: any, idPro: any){
    this.actEdit = actividad;
    this.proyectoId = idPro;
    this.modal4 = true;
    this.formActEdit.controls['inputNameAct'].setValue(this.actEdit.nombre);
    this.formActEdit.controls['inputFechaIn'].setValue(this.actEdit.fecha_inicio);
    this.formActEdit.controls['inputFechaFn'].setValue(this.actEdit.fecha_fin);
    this.formActEdit.controls['inputLider'].setValue(this.actEdit.responsableId);
    document.getElementById('editarAct')!.style.display = 'block';
    document.getElementById('inicioActEdit').scrollIntoView(true);
  }
  public agregarAdicional(){
    this.modal5 = true;
    document.getElementById('adicional')!.style.display = 'block';
  }
  /** Almacenamiento de datos */
  public async guardarAct(){
    var actividad = new Actividades();
    actividad.nombre = this.formAct.controls['inputNameAct'].value;
    actividad.fecha_inicio = new Date(this.formAct.controls['inputFechaIn'].value);
    actividad.fecha_fin = new Date(this.formAct.controls['inputFechaFn'].value);
    actividad.projectId = this.proyectoId;
    actividad.responsableId = this.formAct.controls['inputLider'].value;

    Swal.fire({
      title: this.translate.instant('swal.agreAct'),
      html: this.translate.instant('swal.espe'),
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });

    this.proyextosestrategicoService.postActividades(actividad).subscribe(response =>{
      const actAlta = response;
      var adAlta = 0;



      actividad.historico = Meses.obtenerPlaneado(actividad.fecha_inicio, actividad.fecha_fin, this.diasLab);
      var i = 0;
      for(const hist of actividad.historico){
        this.proyextosestrategicoService.postHistorico(hist, Number(actAlta.id)).subscribe(response =>{
          i++;
          if(i === actividad.historico.length){
            if(this.actividades[0].adicionales.length !== 0){
              for(const ad of this.actividades[0].adicionales){
                var adic = new Adicionales();
                adic.nombre = ad.name;
                adic.tipo = ad.type;
                adic.actividadId = Number(actAlta.id);
                adic.nota = '';
                adic.archivos = '';
                adic.monto = 0;
      
                this.proyextosestrategicoService.postAdicionales(adic).subscribe(response =>{
                  adAlta++;
                  if(adAlta === this.actividades[0].adicionales.length){
                    this.proyextosestrategicoService.getActividades().subscribe(response => {
                      this.perActivAct(response);
                      this.actividades = this.ordenamientoPer(this.actividades);
                      this.obtfechasProy();
                      Swal.fire({
                        title: 'Información agregada',
                        text: '',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: this.dataService.tiempo
                      });
                    });
                  }
                },err =>{
                  let er = obtenerError.errorObt(err['error'].message);
                  Swal.fire({
                    title: er,
                    text: '',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo,
                  });
                });
              } 
            }else{    
              this.proyextosestrategicoService.getActividades().subscribe(response => {
                this.perActivAct(response);
                this.actividades = this.ordenamientoPer(this.actividades);
                this.obtfechasProy();
                Swal.fire({
                  title: 'Información agregada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          }
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
        wait(0.25); // Parche para que furule todo;
      }
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.close_modal();
  }
  public guardarEdit(){
    if(this.actEdit.history.length !== 0 && this.comprobacionCambioFecha() ){
      for(const his of this.actEdit.history){
        this.proyextosestrategicoService.deleteHistory(this.actEdit, his).subscribe(response =>{

        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      }
      wait(0.4);
    }
    this.actEdit.nombre = this.formActEdit.controls['inputNameAct'].value;
    this.actEdit.fecha_inicio = new Date(this.formActEdit.controls['inputFechaIn'].value);
    this.actEdit.fecha_fin = new Date(this.formActEdit.controls['inputFechaFn'].value);
    this.actEdit.responsableId = this.formActEdit.controls['inputLider'].value;

    this.proyextosestrategicoService.patchActividades(this.actEdit).subscribe(response =>{
      if(this.comprobacionCambioFecha()){
        const his = Meses.obtenerPlaneado(this.actEdit.fecha_inicio, this.actEdit.fecha_fin, this.diasLab);
        var i = 0;
        for(const hist of his){
          this.proyextosestrategicoService.postHistorico(hist, Number(this.actEdit.id)).subscribe(response =>{
            i++;
            if(i===his.length){
              this.proyextosestrategicoService.getActividades().subscribe(response => {
                this.perActivAct(response);
                this.actividades = this.ordenamientoPer(this.actividades);
                this.obtfechasProy();
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
          wait(0.2); // Parche para que furule todo;
        }
      }else{
        this.proyextosestrategicoService.getActividades().subscribe(response => {
          this.perActivAct(response);
          this.actividades = this.ordenamientoPer(this.actividades);
          this.obtfechasProy();
          Swal.fire({
            title: 'Información actualizada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      }      
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.close_modal();
  }
  public guardarPro(){
    var proyecto = new Proyectos();
    proyecto.nombre = this.formPro.controls['inputNamePro'].value;
    proyecto.descripcion = document.getElementById('desPro')["value"];
    proyecto.objetiveId = [];
    proyecto.programaId = [];
    proyecto.objetiveId = [Number(this.formPro.controls['inputObjetivo'].value)];
    if(this.formPro.controls['inputEstrategia'].value !== '' && this.formPro.controls['inputEstrategia'].value !== null){
      proyecto.programaId.push(Number(this.formPro.controls['inputEstrategia'].value));
    }
    proyecto.responsableId = this.formPro.controls['inputLider'].value;

    this.proyextosestrategicoService.postProyectos(proyecto).subscribe(response =>{
      this.proyextosestrategicoService.getProyectos().subscribe(response => {
        this.perProyec(response);
        this.proyectos = this.ordenamientoPer(this.proyectos);
        for(const pro of this.proyectos){
          const act = this.actividades.filter(act => act.projectId === pro.id );
          pro.actividades = act;
        }
        this.proyectosTot = [].concat(this.proyectos);
        this.obtfechasProy();
        this.obtDataTabla('cam');
        this.obtResponsableDinamico();
        Swal.fire({
          title: 'Información agregada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.close_modal();
  }
  public guardarEditPro(){
    this.proEdit.nombre = this.formProEdit.controls['inputNamePro'].value;
    this.proEdit.descripcion = this.formProEdit.controls['inputDescripcion'].value;
    this.proEdit.ponderacion = 0;
    this.proEdit.programasId = [];
    if(this.formProEdit.controls['inputEstrategia'].value !== '' && this.formProEdit.controls['inputEstrategia'].value !== null){
      this.proEdit.programasId.push(Number(this.formProEdit.controls['inputEstrategia'].value));
    }
    this.proEdit.objetiveId = [];
    this.proEdit.objetiveId = [Number(this.formProEdit.controls['inputObjetivo'].value)];
    this.proEdit.responsableId = this.formProEdit.controls['inputLider'].value;

    this.proyextosestrategicoService.patchProyectos(this.proEdit).subscribe(response =>{
      this.proyextosestrategicoService.getProyectos().subscribe(response => {
        this.perProyec(response);
        this.proyectos = this.ordenamientoPer(this.proyectos);
        for(const pro of this.proyectos){
          const act = this.actividades.filter(act => act.projectId === pro.id );
          pro.actividades = act;
        }
        this.proyectosTot = [].concat(this.proyectos);
        this.obtfechasProy();
        this.obtDataTabla('cam');
        this.obtResponsableDinamico();
      });
      Swal.fire({
        title: 'Información actualizada',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.resSelect = undefined;
    this.ObjecSelect = undefined;
    this.progSelect = undefined;
    this.close_modal();
  }
  public async guardarAdi(){
    var name = document.getElementById('nomAdi')["value"];
    var tipo = this.tipSelect;
    var adidionales = [];
    this.adicionalesPost = 0;
    alertas.swalEspera('Agregando adicional');
    for(const pro of this.actividades){
      var adic = new Adicionales();
      adic.nombre = name;
      adic.tipo = tipo;
      adic.actividadId = pro.id;
      switch(tipo){
        case 'texto': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'moneda': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'numero': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
        case 'archivo': adic.nota = ''; adic.archivos = ''; adic.monto = 0; break;
      }
      adidionales.push(adic);
      await this.postAdicionales(adic);
    }
    this.diccionarioAdicionales.push({
      nombre: name,
      tipo: tipo,
      adicionales: adidionales,
    })
    this.close_modal();
  }
  /* Funciones de alta */
  private postAdicionales(adic: any){
    this.proyextosestrategicoService.postAdicionales(adic).subscribe(response =>{
      this.adicionalesPost++;
      if(this.adicionalesPost === this.actividades.length){
        this.proyextosestrategicoService.getActividades().subscribe(response => {
          this.perActiv(response);
          Swal.close();
          this.actividades = this.ordenamientoPer(this.actividades);
          this.obtfechasProy();
          Swal.fire({
            title: 'Información agregada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      }
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Metodos de edición de datos */
  
  // Obtener comentarios
  private obtComment(com : any){
    for(const comen of com){
      var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
      var percom = new Date(comen.created_at).getFullYear();
      if(per === percom){
        return comen.comment;
      } 
    }
    return '';
  }
  public async editar(actid: any, adicId: any){
    var inputValue: any, introdujo: any, guardo = false;
    switch(this.actividades[actid].adicionales[adicId].type){
      case 'texto':
        inputValue = this.actividades[actid].adicionales[adicId].note; 
      break;
      case 'moneda': 
        inputValue  = this.actividades[actid].adicionales[adicId].amount; 
      break;
      case 'numero': 
        inputValue  = this.obtComment(this.actividades[actid].adicionales[adicId].comments); 
      break;
      case 'archivo':
        inputValue  = this.actividades[actid].adicionales[adicId].files; 
      break;
    }
    switch(this.actividades[actid].adicionales[adicId].type){
      case 'moneda':introdujo = await Swal.fire({
        title: 'Ingresa el valor',
        text: "",
        input: 'number',
        inputValue: inputValue,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        inputValidator: (value) => {
          if (!value) {
            return 'Ingeresa un dato valido';
          }
          guardo = true;
          return '';
        }
      });
    break;
      case 'numero':
        introdujo = await Swal.fire({
          title: 'Ingresa el valor',
          text: "",
          input: 'text',
          inputValue: inputValue,
          showCancelButton: true,
          confirmButtonColor: '#ff6b00',
          cancelButtonColor: '#B9B9BC',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          inputValidator: (value) => {
            if (!value) {
              return 'Ingeresa un dato valido';
            }
            guardo = true;
            return '';
          }
        });
      break;
    }
    if(guardo){
      switch(this.actividades[actid].adicionales[adicId].type){
        case 'texto': 
          this.actividades[actid].adicionales[adicId].note = introdujo["value"]; break;
        case 'moneda':
          this.actividades[actid].adicionales[adicId].amount = introdujo["value"];
          this.proyextosestrategicoService.patchAdicionales(this.actividades[actid].adicionales[adicId],this.actividades[actid].id).subscribe(response =>{
            this.proyextosestrategicoService.getActividades().subscribe(response => {
              this.perActiv(response);
              this.actividades = this.ordenamientoPer(this.actividades);
              this.obtfechasProy();
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                icon: 'success',
                showConfirmButton: false,
                timer: this.dataService.tiempo
              });
            });
          },err =>{
            let er = obtenerError.errorObt(err['error'].message);
            Swal.fire({
              title: er,
              text: '',
              icon: 'error',
              showConfirmButton: false,
              timer: this.dataService.tiempo,
            });
          });
        break;
        case 'numero':
          this.actividades[actid].adicionales[adicId].note = introdujo.value;
          var existe = false;
          var per =  Number(this.aniosDisponibles.filter(an => an.check)[0].name);
          for(const comen of this.actividades[actid].adicionales[adicId].comments){
            var percom = new Date(comen.created_at).getFullYear();
            if(per === percom){
              comen.comment = introdujo.value;
              existe = true;
              this.proyextosestrategicoService.patchComment(comen, this.actividades[actid].adicionales[adicId].id).subscribe(response =>{
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              },err =>{
                let er = obtenerError.errorObt(err['error'].message);
                Swal.fire({
                  title: er,
                  text: '',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo,
                });
              });
            }
          }
          if(!existe){
            var date = new Date('1'+'-1-'+Number(this.aniosDisponibles.filter(an => an.check)[0].name));
            var aux = {
              comment: introdujo.value,
              created_at: date.toISOString(),
              id: 0,
            }
            this.proyextosestrategicoService.postComment(aux, this.actividades[actid].adicionales[adicId].id).subscribe(response =>{
              aux.id = response.id;
              this.actividades[actid].adicionales[adicId].comments.push(aux);
              Swal.fire({
                title: 'Información actualizada',
                text: '',
                showConfirmButton: false,
                icon: 'success',
                timer: this.dataService.tiempo
              });
            },err =>{
              let er = obtenerError.errorObt(err['error'].message);
              Swal.fire({
                title: er,
                text: '',
                icon: 'error',
                showConfirmButton: false,
                timer: this.dataService.tiempo,
              });
            });
          }
        break;
        case 'archivo':
          this.actividades[actid].adicionales[adicId].files = introdujo["value"]; break;
      }
    }else {
      Swal.fire({
        title: 'Operación cancelada',
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo
      });
    }
  }
  public async editarTit(adicId: any){
    var inputValue: any, introdujo: any, guardo = false;
    inputValue = this.actividades[0].adicionales[adicId].name

    introdujo = await Swal.fire({
      title: 'Ingrese el nombre del adicional',
      text: "",
      input: 'text',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Ingeresa un nombre valido';
        }
        guardo = true;
        return '';
      }
    });
    if(guardo){
      for(const act of this.actividades){
        act.adicionales[adicId].name = introdujo["value"];
        this.proyextosestrategicoService.patchAdicionales(act.adicionales[adicId],act.id).subscribe(response =>{
          this.proyextosestrategicoService.getActividades().subscribe(response => {
            this.perActiv(response);
            this.actividades = this.ordenamientoPer(this.actividades);
            this.obtfechasProy();
            Swal.fire({
              title: 'Información actualizada',
              text: '',
              icon: 'success',
              showConfirmButton: false,
              timer: this.dataService.tiempo
            });
          });
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });

      }
    }
  }
  /** Metodo de decición de guardado */
  public puedeGuardarAdi(){
    var adic = new Adicionales();
    adic.nombre = document.getElementById('nomAdi')["value"];
    adic.tipo = this.tipSelect;
    if(adic.nombre != '' && adic.tipo != undefined && !this.nombreExisteAdi()){
      return '';
    }
    return 'w3-disabled';
  }
  public puedeGuardar(){
    let nombre = this.formAct.controls['inputNameAct'].value;
    let fechain = this.formAct.controls['inputFechaIn'].value;
    let fechafn = this.formAct.controls['inputFechaFn'].value;
    let responsable = this.formAct.controls['inputLider'].value;
    if(nombre !== '' && fechain !== '' && fechafn !== '' && responsable !== '' && this.formAct.controls['inputFechaFn'].status === 'VALID'){
      return '';
    }
    return 'w3-disabled';
  }
  public puedeGuardarEdit(){
    let nombre = this.formActEdit.controls['inputNameAct'].value;
    let fechain = this.formActEdit.controls['inputFechaIn'].value;
    let fechafn = this.formActEdit.controls['inputFechaFn'].value;
    let responsable = this.formActEdit.controls['inputLider'].value;
    if(nombre !== '' && fechain !== '' && fechafn !== '' && responsable !== '' && this.formActEdit.controls['inputFechaFn'].status === 'VALID'){
      return '';
    }
    return 'w3-disabled';
  }
  public puedeguardarPro(){
    let nombre = this.formPro.controls['inputNamePro'].value;
    let objetiveId = this.formPro.controls['inputObjetivo'].value;
    let responsableId = this.formPro.controls['inputLider'].value;

    if(nombre !== '' &&  objetiveId !== ''  && responsableId != '' && !this.nombreExiste()){
      return '';
    }
    return 'w3-disabled';
  }
  public puedeguardarProEdit(){
    let nombre = this.formProEdit.controls['inputNamePro'].value;
    let objetiveId = this.formProEdit.controls['inputObjetivo'].value;
    let responsableId = this.formProEdit.controls['inputLider'].value;

    if(nombre !== '' &&  objetiveId !== ''  && responsableId != '' && !this.nombreExiste()){
      return '';
    }
    return 'w3-disabled';
  }
  /** Escribir ponderación de los objetivos */
  public escribirPond(pro: any){
    const an = this.aniosDisponibles.filter(an => an.check === true);
    for(const pon of pro.ponderacion){
      if(pon.period === an[0].name){
        return pon.weighing;
      }
    }
    return null;
  }
  public campoPon(pro: any){
    const an = this.aniosDisponibles.filter(an => an.check === true);
    for(const pon of pro.ponderacion){
      if(pon.period === an[0].name){
        return pon;
      }
    }
    return null;
  }
  /** Cerrar modales */
  public close_modal(){
    this.modal = false;
    this.modal2 = false;
    this.modal3 = false;
    this.modal4 = false;
    this.modal5 = false;

    document.getElementById('agregarAct')!.style.display = 'none';
    document.getElementById('editarAct')!.style.display = 'none';
    document.getElementById('agregarPro')!.style.display = 'none';
    document.getElementById('editPro')!.style.display = 'none';
    document.getElementById('adicional')!.style.display = 'none';

    this.formPro.reset();
    this.formProEdit.reset();
    this.formAct.reset();
  }
  /** Observar las actividades */
  public mosOcul(i: any){
    this.proyectos[i].check = !this.proyectos[i].check;
    var check = 0;
    for(const pro of this.proyectos){
      if(pro.check){
        check++;
      }
    }
    if(check === this.proyectos.length){
      this.expandirProg = true;
    }else if(check === 0){
      this.expandirProg = false;
    }
  }
  public mosOculAll(){
    for(const pro of this.proyectos){
      if(this.expandirProg){
        pro.check = false;
      }else{
        pro.check = true;
      }
    }
    this.expandirProg = !this.expandirProg;
  }
  /** comprobación de la fecha */
  private comprobacionCambioFecha(){
    let fecIn = new Date(this.formActEdit.controls['inputFechaIn'].value);
    let fecFn = new Date(this.formActEdit.controls['inputFechaFn'].value);
    let fecInAct = this.actEdit.fecha_inicio;
    let fecFnAct = this.actEdit.fecha_fin;
    if(fecIn.getFullYear() !== fecInAct.getFullYear() || fecIn.getMonth() !== fecInAct.getMonth() || fecIn.getDate() !== fecInAct.getDate()){
      return true;
    }
    if(fecFn.getFullYear() !== fecFnAct.getFullYear() || fecFn.getMonth() !== fecFnAct.getMonth() || fecFn.getDate() !== fecFnAct.getDate()){
      return true;
    }
    return false
  }
  /** Metodo de decición de guardado */
  private comprobacionInformacion(historico){
    var tieneInfo = false;
    for(const his of historico){
      if(his.executed !== null){
        tieneInfo = true;
      }
    }
  return tieneInfo;
  }
  public alertas(aler: any, act?:any){
    var text = '';
    if(aler === 'editar'){
      if(this.actEdit.history.length !== 0 && this.comprobacionCambioFecha() && this.comprobacionInformacion(this.actEdit.history)){
        text = 'Se borraran los avances ingresados';
      }
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: ""+text,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.guardarEdit();
        }
      });
    }else if(aler === 'eliminar'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar esta actividad?',
        text: ""+text,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminar(act);
        }
      });
    }else if(aler === 'eliminarPro'){
      var activ = 0;
      for(const acti of this.actividades){
        if(Number(acti.projectId) === Number(act.id)){
          activ++;    
        }
      }
      if(activ != 0){
        text = 'Se borrarán las actividades asociadas a este proyecto'
      }
      Swal.fire({
        title: '¿Seguro que deseas eliminar este proyecto?',
        text: ""+text,
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if(activ != 0){
            for(const acti of this.actividades){
              if(Number(acti.projectId) === Number(act.id)){
                this.proyextosestrategicoService.deleteActividades(acti).subscribe(response=>{
                },err =>{
                  let er = obtenerError.errorObt(err['error'].message);
                  Swal.fire({
                    title: er,
                    text: '',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: this.dataService.tiempo,
                  });
                });
              }              
            }
          }
          this.eliminar_pro(act);
        }
      });
    }
  }
  /** Metodos del Gantt */
  public pintarGantAct(act: any, dia:any, mes: any){
    var an = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        an = anio.name;
      }
    }
    var fecha = new Date((mes+1)+'/'+((dia*2)-1)+'/'+an);
    if((mes+1) === 2 && dia === 15){
      fecha = new Date((mes+1)+'/'+((14*2)-1)+'/'+an);
    }
    if(fecha <= act.fecha_fin && fecha>=act.fecha_inicio){
      if(dia === 15)
        return 'w3-orange-gantt w3-rest'
      return 'w3-orange-gantt w3-31'
    }
    if(dia === 15)
      return 'w3-white w3-rest'
    return 'w3-white w3-31'
  }
  public pintarGantPro(act: any, dia:any, mes: any){
    var an = '';
    for(const anio of this.aniosDisponibles){
      if(anio.check){
        an = anio.name;
      }
    }
    var fecha = new Date((mes+1)+'/'+((dia*2)-1)+'/'+an);
    if((mes+1) === 2 && dia === 15){
      fecha = new Date((mes+1)+'/'+((14*2)-1)+'/'+an);
    }
    if(fecha <= act.fecha_fin && fecha>=act.fecha_ini){
      if(dia === 15)
        return 'w3-black-blue w3-rest'
      return 'w3-black-blue w3-31'
    }
    if(dia === 15)
      return 'w3-white w3-rest'
    return 'w3-white w3-31'
  }
  public tresPuntosVis(){
    var sel = 0;
    for(const res of this.responsables){
      if(res.check){
        sel++;
      }
    }
    if(sel <= 1)
      return false;
    return true;
  }
  public saltoD(indice: any){
    var sum = 0;
    for(var i =0; i<=indice; i++){
      if(this.responsables[i].check){
        sum++;
      }
    }
    if(sum%2 === 0)
      return true;
    return false;
  }
  /** Eliminación de datos */
  public eliminar(act: any){
    Swal.fire({
      title: this.translate.instant('swal.elimInf'),
      html: this.translate.instant('swal.espe'),
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(undefined)
      },
    });
    this.proyextosestrategicoService.deleteActividades(act).subscribe(response=>{
      this.proyextosestrategicoService.getActividades().subscribe(response => {
        this.perActivAct(response);
        this.actividades = this.ordenamientoPer(this.actividades);
        this.obtfechasProy();
        Swal.fire({
          title: 'Información eliminada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  public eliminar_pro(pro: any){
    this.mostabla = false;
    this.proyextosestrategicoService.deleteProyectos(pro).subscribe(response=>{
      this.proyextosestrategicoService.getProyectos().subscribe(response => {
        this.perProyec(response);
        this.proyectos = this.ordenamientoPer(this.proyectos);
        this.proyextosestrategicoService.getActividades().subscribe(response => {
          this.perActiv(response);
          this.mostabla = true;
          Swal.fire({
            title: 'Información eliminada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      }); 
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Mostrar los adicionales */
  public imprimir(actid: any, adicId: any){
    switch(this.actividades[actid].adicionales[adicId].type){
      case 'texto':
        return '';
      case 'moneda':
        return this.formatearnormal(this.actividades[actid].adicionales[adicId].amount);
      case 'numero':
        return this.obtComment(this.actividades[actid].adicionales[adicId].comments);
      case 'archivo':
        return '';
    }
    return '';
  }
  public imprimirPro(pro: any, idAd: any){
    var tot = 0;
    var tipo = '';
    for(const act of this.actividades){
      if(act.projectId === pro.id){
        switch(act.adicionales[idAd].type){
          case 'moneda':
            tipo = 'moneda';
            tot += Number(act.adicionales[idAd].amount)
          break;
        }
      }
    }
      return this.formatearnormal(tot);
  }
  /** Decición de edición de los adicionales */
  public edicionPro(actid: any, adicId: any, pro: any){
    var tieneAct = 0;
    var actId = 0, i = 0;
    for(const act of this.actividades){
      if(act.projectId === pro.id){
        tieneAct++;
        actId = i;
      }
      i++;
    }
    if(tieneAct != 0){
      if(this.actividades[actId].adicionales[adicId].type === 'moneda')
        return true;
    }
    return false;
  }
  public edicion(actid: any, adicId: any, pro: any){
    var tieneAct = 0;
    for(const act of this.actividades){
      if(act.projectId === pro.id){
        tieneAct++;
      }
    }
    if(tieneAct != 0){
      if(this.actividades[actid].adicionales[adicId].type === 'moneda' || this.actividades[actid].adicionales[adicId].type === 'numero')
        return true;
    }
    return false;
  }
  /** Filtros */
  public cambioPer(){
    this.objSelect = undefined;
    this.estSelect = undefined;
    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public cambioObj(){
    this.estSelect = undefined;
    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public cambioEstra(){
    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public mostrarObj(obj: any){
    if(this.formPro.controls['inputEstrategia'].value !== '' && this.modal){
      if(this.formPro.controls['inputEstrategia'].value === null){
        return true;
      }
      for(const prog of this.estrategias){
        for(const est of prog.objetiveId){
          if(est.id === obj.id && prog.id === this.formPro.controls['inputEstrategia'].value){            
            return true;
          }
        }
      }
    }
    if(this.formProEdit.controls['inputEstrategia'].value !== '' && this.modal2){
      if(this.formProEdit.controls['inputEstrategia'].value === null){
        return true;
      }
      for(const prog of this.estrategias){
        for(const est of prog.objetiveId){
          if(est.id === obj.id && prog.id === this.formProEdit.controls['inputEstrategia'].value){            
            return true;
          }
        }
      }
    }
    if((this.formPro.controls['inputEstrategia'].value === '' && this.modal) || (this.formProEdit.controls['inputEstrategia'].value === '' && this.modal2)){
      return true;
    }
    return false;
  }
  private existeAnio(pro: any){
    if(pro.fecha_ini !== undefined && pro.fecha_ini !== undefined){
      var fecha_ini = pro.fecha_ini.getFullYear(), fecha_fin = pro.fecha_fin.getFullYear();
      for(const an of this.aniosDisponibles){
        if(an.check === true && (Number(an.name) === fecha_ini || Number(an.name) === fecha_fin || (Number(an.name) > fecha_ini && Number(an.name) < fecha_fin) ) ){
          return true;
        }
      }
      return false;
    }else{
      return true;
    }
  }
  private responsableAct(pro: any){
    for(const res of this.responsables2){
      if(res.id === pro.responsableId && res.check){
        return true;
      }
    }
    return false;
  }
  public responsableActOfAct(act: any){
    for(const res of this.responsablesAct){
      if(res.id === act.responsableId && res.check){
        return true;
      }
    }
    return false;
  }
  public cambioAnio(anio: any) {
    this.aniosDisponibles.forEach(an => { an.name === anio.name? an.check=true:an.check=false });

    this.obtDataTabla('cam');
    this.obtResponsableDinamico();
  }
  public cambioLider(lider?: any){
    if(lider !== undefined){
      this.responsables2.forEach(res =>{ res.nombre === lider.nombre? res.check = !res.check: res.check = res.check});
      var all = 0;
      this.responsables2.forEach(res =>{ res.check? all++ : all = all });
      if(all === this.responsables2.length){
        this.selecAll = true
      }else{
        this.selecAll = false;
      }
    }else{
      if(this.selecAll){
        this.responsables2.forEach(res =>{ res.check = false});
      }else{
        this.responsables2.forEach(res =>{ res.check = true});
      }
      this.selecAll = !this.selecAll
      
    }
    if( lider !== undefined || this.selecAll){
      this.obtDataTabla('all');
    }
  }
  public cambioLiderAct(lider?: any){
    if(lider != undefined){
      this.responsablesAct.forEach(res =>{ res.nombre === lider.nombre? res.check = !res.check: res.check = res.check});
      var all = 0;
      this.responsablesAct.forEach(res =>{ res.check? all++ : all = all });
      if(all === this.responsablesAct.length){
        this.selecAllAct = true
      }else{
        this.selecAllAct = false;
      }
    }else{
      if(this.selecAllAct){
        this.responsablesAct.forEach(res =>{ res.check = false});
      }else{
        this.responsablesAct.forEach(res =>{ res.check = true});
      }
      this.selecAllAct = !this.selecAllAct
    }
  }
  public mostrar(pro: any){
    if(this.perSelect !== undefined){
      if( this.existeAnio(pro) && this.pertenece_perspective(pro) && this.perteneceProg(pro) ){
        return this.pertenece(pro);
      }else{
        return false;
      }
    }else{
      if( this.existeAnio(pro) ){
        return this.pertenece(pro);
      }else{
        return false;
      }
    }
  }
  public mostrarConResponsables(pro: any){
    if(this.perSelect !== undefined){
      if( this.responsableAct(pro) && this.existeAnio(pro) && this.pertenece_perspective(pro) && this.perteneceProg(pro) ){
        return this.pertenece(pro);
      }else{
        return false;
      }
    }else{
      if( this.responsableAct(pro) && this.existeAnio(pro) ){
        return this.pertenece(pro);
      }else{
        return false;
      }
    }
  }
  public mostrarAct(act){
    var fecha_ini = act.fecha_inicio.getFullYear(), fecha_fin = act.fecha_fin.getFullYear();
      for(const an of this.aniosDisponibles){
        if(an.check === true && (Number(an.name) === fecha_ini || Number(an.name) === fecha_fin || (Number(an.name) > fecha_ini && Number(an.name) < fecha_fin) ) ){
          return true;
        }
      }
    return false;
  }
  /** Edición en tabla */
  public async edicionPond(pro: any){
    var inputValue = 0, introdujo: any, guardo = false, texto = '';
    const objPond = this.campoPon(pro);

    if(objPond === null){
      inputValue = 0;
    }else{
      inputValue = objPond.weighing; 
    }

    introdujo = await Swal.fire({
      title: 'Ingrese la ponderación',
      text: ""+texto,
      input: 'number',
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Ingerese un dato valido';
        }
        guardo = true;
        return '';
      }
    });

    if(guardo){
      const valor = Number(introdujo.value);
      if(objPond !== null){
        objPond.weighing = valor;
        this.proyextosestrategicoService.patchHistoricoPeso(valor, pro.id, objPond.id).subscribe(response =>{
          Swal.fire({
            title: 'Información actualizada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      }else{
        const peso = Number(introdujo.value);
        const fecha = this.aniosDisponibles.filter(an => an.check);
        this.proyextosestrategicoService.postHistoricoPeso(peso, pro.id,fecha[0].name+'').subscribe(response =>{
          pro.ponderacion.push(response);
          Swal.fire({
            title: 'Información actualizada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      }
    }
  }
  /** Exportación */
  public exportar_excel(){
    let headers = [];
    let proyectos = [];
    let actividades = [];
    if (this.mostabla) {
      let mess = [];
      for(const m of this.meses){
        mess.push(m.name);
      }
      let actAdic = [];
      if(this.actividades.length > 0){
        for(const aa of this.actividades[0].adicionales!){
          actAdic.push(aa.name);
        }
      }
      headers.push([
        'Proyecto',
        'Líder/Responsable',
        'Ponderación',
        'Inicio',
        'Días', 
        'Fin',
        ...actAdic
      ]);

      for(let j = 0; j < this.proyectos.length; j++){
        if(this.pertenece(this.proyectos[j]) && this.existeAnio(this.proyectos[j]) && this.responsableAct(this.proyectos[j]) && this.perteneceProg(this.proyectos[j])){
          let adP = [];
          let acts = [];
          if (this.actividades.length > 0) {         
            for (let l = 0; l < this.actividades[0].adicionales?.length!; l++) {
              if (this.edicionPro(j, l, this.proyectos[j])) {
                adP.push(this.imprimirPro(this.proyectos[j], l));
              } else {
                adP.push('');
              }
            } 
          }

          proyectos.push([
            this.proyectos[j].nombre,
            this.obtres(this.proyectos[j].responsableId),
            this.escribirPond(this.proyectos[j])!==null?this.formatearnormalDec(this.escribirPond(this.proyectos[j])):0,
            this.obtFecha(this.proyectos[j].fecha_ini),
            this.obtDias(this.proyectos[j].fecha_ini, this.proyectos[j].fecha_fin),
            this.obtFecha(this.proyectos[j].fecha_fin),
            ...adP
          ])
          let adAct = [];

          for(let i = 0; i < this.actividades.length; i++){
            let a = []
            if(this.proyectos[j].id === this.actividades[i].projectId){
              let aux = 0;
                  for(const act of this.actividades[i].adicionales!){
                    a.push(this.imprimir(i,aux) ?? '')
                    aux++;
                  }
                  adAct.push(a)
              acts.push([
                '   '.concat(this.actividades[i].nombre),
                this.obtres(this.actividades[i].responsableId),
                '',
                this.obtFecha(this.actividades[i].fecha_inicio),
                this.obtDias(this.actividades[i].fecha_inicio, this.actividades[i].fecha_fin),
                this.obtFecha(this.actividades[i].fecha_fin),
                ...a
              ])
            }
          }
          actividades.push(acts);
        }
      }
      let filtros = []
      if(this.perSelect){
        filtros.push(['Perspectiva:',this.perspectivas[this.perSelect].nombre]);
      }
      if(this.objSelect){
        filtros.push(['Objetivo:',this.objetivos[this.objSelect].nombre]);
      }
      filtros.push(this.estSelect ? ['Estrategia:',this.estrategias[this.estSelect].name] : []);
      this.excelService.generateExcelProyectosEstrategicosFormulacion("Formulación: Proyectos Estratégicos", headers, proyectos, actividades,  filtros, "proyectos_estrategicos");
    }
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nomPro')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nomProEdit')["value"];
      }
      const existe = this.proyectosTot.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.proEdit.id);
        }
        return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste():  boolean  {    
    return  this.formPro.hasError('isValid');
  }
  private nombreDuplicidadAdi(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal5){
        nombre = document.getElementById('nomAdi')["value"];
      }else if(this.modal4){
        nombre = document.getElementById('actividadEdit')["value"];
      }
      if(this.actividades.length !== 0){
        const existe = this.actividades[0].adicionales.find( element => {
          if(this.modal4){
            return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() && element.id !== this.actEdit.id);
          }
          return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() );
        });
        if(existe) return {isValid:true};
      }      
      
      return null;
    };
  }
  public nombreExisteAdi():  boolean  {    
    return  this.formAdi.hasError('isValid');
  }
  /** Metodo de escritura de los matselect */
  public obtTooltip(idMat: String): String {
    switch(idMat){
      case 'perspectiva':
        const per = this.perspectivas.find( dat => { return dat.id === this.perSelect })
        if(per){
          return per.nombre;
        }
      break;
      case 'objetivo':
        const obj = this.objetivos.find( dat => { return dat.id === this.objSelect })
        if(obj){
          return obj.nombre;
        }
      break;
      case 'estrategia':
        const est = this.estrategias.find( dat => { return dat.id === this.estSelect })
        if(est){
          return est.name;
        }
      break;
    }
    return '';
  }
  /** Borrar filtros */
  public borrarFiltro(){
    document.body.style.cursor = 'wait';

    const date = new Date();
    this.aniosDisponibles.forEach(an => { date.getFullYear()+'' === an.name? an.check = true:an.check = false });
    
    this.responsables.forEach(res => res.check = true);
    this.responsablesAct.forEach(res => res.check = true);

    this.perSelect = undefined;
    this.objSelect = undefined;
    this.estSelect = undefined;

    this.ordenamiento = 'id';

    this.obtDataTabla('cam');
    this.obtResponsableDinamico();

    setTimeout(() => {
      document.body.style.cursor = 'default';        
    }, 2000 );
  }
  /** Obtención de datos */
  private obtResponsableDinamico(){
    var aux = [], aux2 = [];
    for(const pro of this.proyectos){
      const res = this.responsables.filter(res => Number(res.id) === Number(pro.responsableId));
      const existe = aux.filter(res => res.id === pro.responsableId);
      console.log("🚀 ~ existeAct =>", res, pro, this.responsables)
          if(existe.length === 0){
        aux.push({
          nombre: res[0].nombre,
          id: res[0].id,
          check: true,
        });
      }
      for(const resp of pro.actividades){
        const resAct = this.responsables.filter(res => Number(res.id) === Number(resp.responsableId));
        const existeAct = aux2.filter(res => Number(res.id) === Number(resp.responsableId));
        if(existeAct.length === 0){
          console.log("🚀 ~ existeAct =>", resAct, resp, this.responsables, resAct[0].nombre)
          aux2.push({
            nombre: resAct[0].nombre,
            id: resAct[0].id,
            check: true,
          });
        }
      }
    }
    this.responsables2 = this.ordenamientoAlf(aux);
    this.responsablesAct = this.ordenamientoAlf(aux2);
  }
  private obtDataTabla(tipo: string){
    this.proyectos = [];
    for(const pro of this.proyectosTot){
      switch(tipo){
        case 'all':
          if( this.mostrarConResponsables(pro) ){
            this.proyectos.push(pro);
          }
        break;
        case 'cam':
          if( this.mostrar(pro) ){
            this.proyectos.push(pro);
          }
        break;
      }
    }
  }
  /** Ordenamiento */
  public ordenamientoTabla(){
    switch(this.ordenamiento){
      case 'id':
        this.proyectos = this.ordenamientoAlf(this.proyectos);
        this.ordenamiento = 'asc';
      break;
      case 'asc':
        this.proyectos = this.ordenamientoAlfInv(this.proyectos);
        this.ordenamiento = 'des';
      break;
      case 'des':
        this.proyectos = this.ordenamientoPer(this.proyectos);
        this.ordenamiento = 'id';
      break;
    }
  }
  public ordenamientoTablaAct(){
    switch(this.ordenamiento){
      case 'id':
        this.proyectos = this.ordenamientoPer(this.proyectos);
      break;
      case 'asc':
        this.proyectos = this.ordenamientoAlf(this.proyectos);
      break;
      case 'des':
        this.proyectos = this.ordenamientoAlfInv(this.proyectos);
      break;
    }
  }
  /** formato date */
  getDateFormatString(): string {
    return 'DD/MM/YYYY';
  }
  /* Edición y eliminación de adicionales */
  public async editarAdicional(adi: any){
    var guardo = false;
    var introdujo = await Swal.fire({
      title: 'Ingrese el nombre del adicional',
      text: "",
      input: 'text',
      inputValue: adi.name,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: (value) => {
          guardo = true;
          return '';
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Editando adicional');
      for(const act of this.actividades){
        const adEdit = act.adicionales.filter(ad => ad.name === name && ad.type === adi.type);
        if(adEdit.length !== 0){
          adEdit[0].name = introdujo.value;
          this.proyextosestrategicoService.patchAdicionalesNombre(adEdit[0]).subscribe(response =>{
            edit++;
            if(edit === this.actividades.length){
              this.proyextosestrategicoService.getActividades().subscribe(response => {
                this.perActiv(response);
                Swal.close();
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }
      }
    }
  }
  public async eliminarAdicional(adi: any){
    var guardo = false;
    await Swal.fire({
      title: '¿Esta seguro de eliminar este adicional?',
      text: "Esta acción borrara permanente la información contenida en el adicional.",
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        guardo = true;
      }
    });

    if(guardo){
      var edit = 0;
      const name = adi.name;
      alertas.swalEspera('Eliminando adicional');
      for(const ind of this.actividades){
        const adEdit = ind.adicionales.filter(ad => ad.name === name && ad.type === adi.type);
        if(adEdit.length !== 0){
          this.proyextosestrategicoService.deleteAdicional(adEdit[0]).subscribe(response =>{
            edit++;
            if(edit === this.actividades.length){
              this.proyextosestrategicoService.getActividades().subscribe(response => {
                this.perActiv(response);
                Swal.close();
                Swal.fire({
                  title: 'Información actualizada',
                  text: '',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: this.dataService.tiempo
                });
              });
            }
          }, error =>{
            alertas.messageError(error, this.dataService.tiempo);
          });
        }
      }
    }
  }
  /* Validaciones */
  public async validarPro(){
    
    var noPond = '', proyec = 0, pondT = 0, pondD = 0;
    let ponTot = 0;
    var años = [];

    this.aniosDisponibles.forEach(an => años.push(an.name));
    var anio = '';
    var selecciono = false;

    await Swal.fire({
      title: 'Seleccione una año para validar',
      input: 'select',
      inputOptions: {
        años
      },
      inputPlaceholder: 'Seleccione',
      showCancelButton: true,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if(value){
            anio = años[value];
            selecciono = true;
            Swal.close();
          }else{
            resolve('Selecciona un año');
          }
        })
      },
    });

    if(selecciono){
      for(const obj of this.objetivos){
        for(const pro of this.proyectosTot){
          if (Number(pro.objetiveId[0]) === Number(obj.id) && this.existeAnioValidacion(pro, anio)){
            pondD++;
            for(const pon of pro.ponderacion){
              if(pon.period === anio+'' && pon.weighing !== 0){
                ponTot = Number( ( ponTot + pon.weighing ).toFixed(2) );
                proyec++;
                pondT++;
              }
            }
          }
        }
        if(pondD !== pondT){
          noPond += this.formatearTexto(obj.nombre,undefined,this.translate.instant('swal.proSi'));
        }else if( (proyec != 0 && ponTot !== 100)){
          noPond += this.formatearTexto(obj.nombre, ponTot);
        }
        pondD = 0;
        pondT = 0;
        ponTot = 0;
        proyec = 0;
      }  
      if(noPond != ''){
        let title = this.translate.instant('swal.objNo');
        this.finalizacionValidacion(title, noPond);
      }else{
        Swal.close();
        this.finalizacionCorrecta();
      }
    }
  }
  private existeAnioValidacion(pro: any, anio){
    if(pro.fecha_ini !== undefined && pro.fecha_ini !== undefined){
      var fecha_ini = pro.fecha_ini.getFullYear(), fecha_fin = pro.fecha_fin.getFullYear();
      if((Number(anio) === fecha_ini || Number(anio) === fecha_fin) ){
        return true;
      }
      return false;
    }else{
      return false;
    }
  }
  /** Formateo de datos */
  private formatearTexto(texto: string, pond?: number, texto2?: string){
    var linea = '';
    if(pond !== undefined){
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+'</span><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt"> - </span> <span>'+ this.translate.instant('swal.ponTot') + pond+"%</span></div>"; 
    }else if(texto2 !== undefined){
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+'</span><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt"> - </span> <span>'+texto2+"</span></div>"; 
    }else{
      linea += '<div style="text-align: left;"><span style="color: #4E9AC7;font-weight: 500;font-size: 17pt">&#8226;</span> <span>'+texto+"</span></div>"
    }
    return linea;
  }
  private finalizacionValidacion(title, texto){
    texto += '<br/><div style="text-aling: center;font-weight: 500;"></div>';
    Swal.fire({
      title: ''+title,
      html: ""+texto,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    });
  }
  private finalizacionCorrecta(){
    Swal.fire({
      title: 'Ponderaciones correctas',
      text: '',
      icon: 'success',
      showConfirmButton: false,
      timer: this.dataService.tiempo,
    });
  }
  /** Capitalización del texto */
  public capitalizacionTexto(id){
    const input = document.getElementById(id);
    var palabra = input['value'];
    if(!input['value']) return;
    var mayuscula = palabra.substring(0,1).toUpperCase();
    if (palabra.length > 0) {
      var minuscula = palabra.substring(1).toLowerCase();
    }
    input['value'] = mayuscula.concat(minuscula);
  }
}
