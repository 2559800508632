<!-- an hidden div is created to set the position of appearance of the menu--> 
<div id="menuIzq" style="position: fixed;" 
[style.left]="menuTopLeftPosition.x" 
[style.top]="menuTopLeftPosition.y" 
[matMenuTriggerFor]="rightMenu">
  <!-- standard material menu --> 
  <mat-menu #rightMenu="matMenu"> 
    <ng-template matMenuContent let-item="item"> 
      <button mat-menu-item (click)="deleteArchivo()">
        <mat-icon class="iconoMenVer" [ngStyle]="{'color':'grey'}">delete</mat-icon>
        Eliminar
      </button> 
    </ng-template> 
  </mat-menu> 
</div> 

<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <div class="w3-rest">
      <div class="w3-noventa2" style="width: 100%;">
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios"> <!--  -->
              Períodos
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); cambioAnio(child.periodo);">
                        <div class="w3-quarter ingDat">
                          <mat-checkbox [checked]="child.check" name="{{child.nombre}}"
                          (change)="cambioAnio(child.periodo);"
                          (click)="$event.stopPropagation();"
                          value="{{child.nombre}}" aria-label="First checkbox">
                            {{ child.nombre }}
                          </mat-checkbox>
                        </div>
                        <div class="w3-rest">
                          <span style="margin-left: 5px;">{{child.mes}}</span> 
                        </div>           
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" >
                        <span style="margin-left: 5px; margin-right: 5px;">{{mes.periodo}}</span>
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- lideres -->
        <div class="w3-third">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menulider" aria-label="Example icon-button with a menu">
              Líder
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menulider="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="selecAllResActas"
                (change)="selecAllLideres()"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox"> <!--  -->
                  Seleccionar todos
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selecAllResActas">
                <ng-container *ngFor="let anio of responsablesActas">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                    (change)="cambioLider(anio)"
                    (click)="$event.stopPropagation();"
                    value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                      {{ anio.nombre }}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda titulochipsr">
            <div class="w3-row padreizquierda containerChips">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of responsablesActas; let i = index">
                    <div [ngClass]="anio.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioLider(anio)">
                      <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                        {{anio.nombre}}
                      </span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                    </div>
                    <br *ngIf="anio.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="tresPuntosVis()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuanios2="matMenu" xPosition="before">
                <div *ngFor="let anio of responsablesActas"
                    [ngClass]="anio.check?
                         'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado w3-border" (click)="cambioLider(anio); $event.stopPropagation();">
                    <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- responsables -->
        <div class="w3-third">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuliderres" aria-label="Example icon-button with a menu">
              Responsable
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuliderres="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="selecAllResActiv"
                (change)="selecAllResponsables()"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox"> <!--  -->
                  Seleccionar todos
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selecAllResActiv">
                <ng-container *ngFor="let anio of responsablesActividadesActas">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                    (change)="cambioResponsable(anio)"
                    (click)="$event.stopPropagation();"
                    value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                      {{ anio.nombre}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochipsr">
            <div class="w3-row padreizquierda containerChips">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of responsablesActividadesActas; let i = index">
                    <div [ngClass]="anio.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioResponsable(anio)">
                      <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                        {{anio.nombre}}
                      </span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                    </div>
                    <br *ngIf="anio.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="tresPuntosVisResp()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menures">more_horiz</mat-icon>
              </div>
              <mat-menu #menures="matMenu" xPosition="before">
                <div *ngFor="let anio of responsablesActividadesActas"
                    [ngClass]="anio.check?
                         'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado w3-border" (click)="cambioResponsable(anio); $event.stopPropagation();">
                    <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right">
          <div class="w3-row">
            <button mat-icon-button (click)="borrarFiltro()"> <!--(click)="quitarFiltros()"-->
              <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                <img src="assets/img/graficos/eraser.png">
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row w3-white" style="height: 85vh;">
      <div class="w3-row w3-subtitulo botonDeNuevo">
        <div class="menuexp">
          <div class="w3-right" style="margin-top: 0.3vh;">
            <mat-icon class=" w3-zindex w3-dropdown-click" [ngStyle]="{'color':'gray'}"
              [matMenuTriggerFor]="menu2">more_vert</mat-icon>
          </div>
          <mat-menu #menu2="matMenu">
            
            <button (click)="exportarExcel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
              <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar xlsx</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="w3-row" style="height: 79vh; width: 98%; margin-left: 1%;">
        <div *ngIf="!mostabla">
          <section>
            <table class="w3-table" style="width: 99%;">
              <tr>
                <ng-container *ngFor="let m of skeletonColums">
                  <td>
                    <div class="info"></div>
                    <ng-container *ngFor="let n of skeleton">
                      <div class="info" style="margin-left: 3vw !important;"></div>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </table>
          </section>
        </div>
        <div *ngIf="mostabla" style="overflow: scroll;">
          <table class="w3-table borderIzq borderInf">
            <thead>
              <tr>
                <th class="w3-elemento-titulo-table borderSup enbezados" style="max-width: 3vw; min-width: 3vw;">
                  <mat-icon *ngIf="mostrarAllActas" (click)="mostrarTodasLasActas()" class="iconoMen w3-button" matTooltip="Ocultar todo" matTooltipPosition="left" >expand_less</mat-icon>
                  <mat-icon *ngIf="!mostrarAllActas" (click)="mostrarTodasLasActas()"  class="iconoMen w3-button" matTooltip="Mostrar todo" matTooltipPosition="left" >expand_more</mat-icon>
                </th>
                <th (click)="ordenamientoTabla()" class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnasTit enbezadosPro contenedorSort textoOverflow anchos ingDat" style="width: 15vw;">
                  Actas
                  <mat-icon *ngIf="ordenamiento === 'id'" class="iconoSortIni">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'asc'" class="iconoSort">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'des'" class="iconoSort">download</mat-icon>
                </th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun alinCen">Ver acta</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen" >Inicio</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen" >Fin</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnasRes">Lider / Responsable</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen"> Planeado </th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen borderDer"> Ejecutado </th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen borderDer"> Validación </th>
                <ng-container *ngIf="actasMostrar.length != 0">
                  <ng-container *ngFor="let ad of adicionales; let i = index">
                    <th class="w3-elemento-titulo-table borderSup tresPun enbezados borderDer alinCen anchosColumnas" style="padding: 0vw 0.5vw;">
                      {{ad.name}}
                    </th>
                  </ng-container>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let actas of actasMostrar">
                <tr>
                  <td class="w3-table-trtdBalance borderSup" style="max-width: 3vw; min-width: 3vw;">
                        <mat-icon *ngIf="actas.check" (click)="mostrarActas(actas)" class="w3-margin-right-contenido iconoMen w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                        <mat-icon *ngIf="!actas.check" (click)="mostrarActas(actas)" class="w3-margin-right-contenido iconoMen w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                  </td>
                  <td class="w3-table-trtdBalance mantenerFija borderSup textoOverflow" style="font-weight: bold;"><span matTooltip="{{actas.nombre}}">{{actas.nombre}}</span></td>
                  <td class="w3-table-trtdBalance borderSup alinCen">
                    <div class="iconoArch">
                      <mat-icon *ngIf="actas.acta !== null" class="icono ingDat" matTooltip="{{actas.acta.split('-')[actas.acta.split('-').length-1]}}" (click)="abrirArchivo(actas)" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                      <mat-icon *ngIf="actas.acta === null" class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                    </div>
                  </td>
                  <td class="w3-table-trtdBalance borderSup alinCen tresPun fechas">{{obtFecha(actas.fecha_ini)}}</td>
                  <td class="w3-table-trtdBalance borderSup alinCen tresPun fechas">{{obtFecha(actas.fecha_fin)}}</td>
                  <td class="w3-table-trtdBalance borderSup">{{actas.responsable.position}}</td>
                  <td class="w3-table-trtdBalance borderSup alinDer">{{escribirDatPro(actas,'plan')}}</td>
                  <td class="w3-table-trtdBalance borderSup alinDer borderDer" [ngClass]="pintarBordPro(escribirDatPro(actas, 'ejec'),escribirDatPro(actas, 'plan'))">{{escribirDatPro(actas,'ejec')}}</td>
                  <td class="w3-table-trtdBalance borderSup tresPun anchosColumnas alinCen borderDer"> <br> </td>
                  <ng-container *ngIf="actas.actividades.length !== 0">
                    <ng-container *ngFor="let ad of actas.actividades[0].adicionales; let i = index;">
                      <td class="w3-table-trtdBalance textoOverflow tresPun borderSup alinDer"
                      style="border-right: 1px solid #B9B9BC; min-width: 5vw; max-width: 20vw;">
                        {{escribirAdicionalPro(actas.actividades,i)}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="actas.actividades.length === 0">
                    <ng-container *ngFor="let ad of adicionales; let i = index;">
                      <td class="w3-table-trtdBalance textoOverflow tresPun borderSup alinDer"
                      style="border-right: 1px solid #B9B9BC; min-width: 5vw; max-width: 20vw;">
                        <br>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
                <ng-container *ngIf="actas.check">
                  <tr *ngFor="let act of actas.actividades;">
                    <ng-container *ngIf="existAct(act)">
                      <td class="w3-table-trtdBalance" style="max-width: 3vw; min-width: 3vw;"><br></td>
                      <td class="w3-table-trtdBalance mantenerFija anchosColumnas textoOverflow" matTooltip="{{act.nombre}}"><span>{{act.nombre}}</span></td>
                      <td class="w3-table-trtdBalance"><br></td>
                      <td class="w3-table-trtdBalance alinCen tresPun fechas">{{obtFecha(act.fecha_inicio)}}</td>
                      <td class="w3-table-trtdBalance alinCen tresPun fechas">{{obtFecha(act.fecha_fin)}}</td>
                      <td class="w3-table-trtdBalance textoOverflow">{{act.responsable.position}}</td>
                      <td class="w3-table-trtdBalance alinDer">{{escribirDat(act.history, 'plan')}}</td>
                      <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td *ngIf="escribirDat(act.history, 'ejec') === 0 || escribirDat(act.history, 'ejec') === null" [ngClass]="pintarBord(escribirDat(act.history, 'ejec'),escribirDat(act.history, 'plan'))"
                          class="w3-elemento-titulo-tableBlan alinDer borderDer ingDat textoAyud" (dblclick)="editar(act.history, act.id)"><!---->
                          0
                        </td>
                        <td *ngIf="escribirDat(act.history, 'ejec') !== 0 && escribirDat(act.history, 'ejec') !== null" [ngClass]="pintarBord(escribirDat(act.history, 'ejec'),escribirDat(act.history, 'plan'))"
                        class="w3-elemento-titulo-tableBlan alinDer borderDer ingDat" (dblclick)="editar(act.history, act.id)"> <!---->
                        {{escribirDat(act.history, 'ejec')}}
                      </td>
                      </ng-container>
                      <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                        <td *ngIf="escribirDat(act.history, 'ejec') === 0 || escribirDat(act.history, 'ejec') === null" [ngClass]="pintarBord(escribirDat(act.history, 'ejec'),escribirDat(act.history, 'plan'))"
                          class="w3-elemento-titulo-tableBlan alinDer borderDer textoAyud">
                          0
                        </td>
                        <td *ngIf="escribirDat(act.history, 'ejec') !== 0 && escribirDat(act.history, 'ejec') !== null" [ngClass]="pintarBord(escribirDat(act.history, 'ejec'),escribirDat(act.history, 'plan'))"
                        class="w3-elemento-titulo-tableBlan alinDer borderDer">
                        {{escribirDat(act.history, 'ejec')}}
                      </td>
                      </ng-container>
                      <td class="w3-table-trtdBalance tresPun anchosColumnas alinCen borderDer">
                        <div class="iconoArch" *ngIf="escribirDat(act.history, 'ejec') === 100">
                          <mat-icon *ngIf="act.validacion !== null && act.validacion !== false" (click)="puedeValidar(actas.responsable, act)" class="icono ingDat" [ngStyle]="{'color':'#72D54A'}">done</mat-icon>
                          <mat-icon *ngIf="act.validacion === null || act.validacion === false" (click)="puedeValidar(actas.responsable, act)" class="icono ingDat" [ngStyle]="{'color':'#C8C7C8'}">done</mat-icon>
                        </div>
                        <br *ngIf="escribirDat(act.history, 'ejec') !== 100">
                      </td>
                      <ng-container *ngIf="act.adicionales.length !== 0">
                        <ng-container *ngFor="let ad of act.adicionales">
                          <ng-container *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                            <td *ngIf="ad.type !== 'moneda' && ad.type !== 'archivo' && ad.type !== 'numero'" class="textoOverflow w3-elemento-titulo-tableBlan borderDer anchosad ingDat"
                              matTooltip="{{escribirAdicional(ad)}}" (dblclick)="edicionAd(ad, act.id, $event)"> <!---->
                              {{escribirAdicional(ad)}}
                            </td>
                          </ng-container>
                          <ng-container *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                            <td *ngIf="ad.type !== 'moneda' && ad.type !== 'archivo' && ad.type !== 'numero'" class="textoOverflow w3-elemento-titulo-tableBlan borderDer anchosad"
                              matTooltip="{{escribirAdicional(ad)}}">
                              {{escribirAdicional(ad)}}
                            </td>
                          </ng-container>
                          <td *ngIf="ad.type === 'moneda'" class="textoOverflow alinDer w3-elemento-titulo-tableBlan borderDer anchosad">
                            <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                          </td>
                          <td *ngIf="ad.type === 'numero'" class="textoOverflow alinIzq w3-elemento-titulo-tableBlan borderDer anchosad">
                            <span matTooltip="{{escribirAdicional(ad)}}">{{escribirAdicional(ad)}}</span>
                          </td>
                          <td *ngIf="ad.type === 'archivo'" class="textoOverflow w3-elemento-titulo-tableBlan borderDer alinCen anchosad">
                            <div *appRoles="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch ingDat"
                              (click)="abirPDF(ad, $event);" (contextmenu)="onContextMenu($event, ad)"> <!---->
                                <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                              </div>
                              <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch ingDat"
                              (dblclick)="edicionAd(ad, act.id, $event)"> <!---->
                                <mat-icon  matTooltip="Adjuntar archivo" class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                              </div>
                            </div>
                            <div *appRolesNeg="['Rol_Administrador','Rol_Carga','Rol_Seguimiento']">
                              <div *ngIf="escribirAdicional(ad) !== ''" class="iconoArch ingDat"
                              (click)="abirPDF(ad, $event);"> <!---->
                                <mat-icon matTooltip="{{escribirAdicional(ad)}}" class="icono" [ngStyle]="{'color':'#4E9AC7'}">attach_file</mat-icon>
                              </div>
                              <div *ngIf="escribirAdicional(ad) === ''" class="iconoArch">
                                <mat-icon class="icono" [ngStyle]="{'color':'#C8C7C8'}">attach_file</mat-icon>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="cargando_arch" class="w3-modal">
  <div class="contenedorCargando" style="width: 25%; height: 25%;">
    <div class="iconoCarga spinner-border text-secondary"></div>
  </div>
</div>