import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReportesRoutingModule } from './reportes-routing.module';
import { AvanceseguimientoComponent, VarDirective } from './avanceseguimiento/avanceseguimiento.component';
import { PlanestrategicoComponent } from './planestrategico/planestrategico.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AvanceSegimientoFinComponent } from './avance-segimiento-fin/avance-segimiento-fin.component';


@NgModule({
  declarations: [
    AvanceseguimientoComponent,
    PlanestrategicoComponent,
    VarDirective,
    AvanceSegimientoFinComponent
  ],
  imports: [
    CommonModule,
    ReportesRoutingModule,
    SharedModule,
    FormsModule,  HttpClientModule, AngularEditorModule,
    MatSlideToggleModule
  ],  
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
}
  )
export class ReportesModule { }
