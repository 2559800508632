import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class historico{
  period?: string;
  planned?: number;
  executed?: number;
}

export class comment{
  comment?: string;
  created_at?: String;
}

export class Adicionales{
  activityId?: Number;
  name?: string;
  amount?: Number;
  files?: string;
  note?: string;
  type?: string;
} 

export class actividades{
  public name?: string;
  public initial_date?: Date;
  public end_date?: Date;
  public projectId?: number;
  public responsibleId?: number;
}
@Injectable({
  providedIn: 'root'
})
export class ProyectosestrategicosService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));
  
  private headersAr = new HttpHeaders().append('enctype', 'multipart/form-data')
  .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlaneacion(): Observable< any >{
    const apiURL = this.apiURL+"planificacion/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsables(): Observable< any >{
    const apiURL = this.apiURL+"area/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjectives(): Observable< any >{
    const apiURL = this.apiURL+"objetivos/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProgramas(): Observable< any >{
    const apiURL = this.apiURL+"estrategia/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectos(): Observable< any >{
    const apiURL = this.apiURL+"proyectos/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getActividades(): Observable< any >{
    const apiURL = this.apiURL+"actividad/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getActividadesId(id: any): Observable< any >{
    const apiURL = this.apiURL+"actividad/"+id;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectosPeriodo(periodo: string): Observable< any >{
    const apiURL = this.apiURL+"proyectos?period="+periodo;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getAdicionales(): Observable< any >{
    const apiURL = this.apiURL+"adicional/";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

  public postComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
      created_at: com.created_at,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchComment(com: any, idAct: number): Observable<any>{
    const coment: comment = {
      comment: com.comment,
    }
    const body = JSON.stringify(coment);
    const apiURL = this.apiURL+"adicional/"+idAct+"/comments/"+Number(com.id);
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }


  public patchAdicionales(adicional: any): Observable<any>{
    const ad: Adicionales = {
      amount: Number(adicional.amount),
      files: adicional.files,
      note: adicional.note,
      type: adicional.type,
    }
    const body = JSON.stringify(ad);
    const apiURL = this.apiURL+"adicional/"+adicional.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public postHistorico(his: any, idAct: number): Observable<any>{
    const hist: historico = {
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public PatchHistorico(his: any, idAct: number, idHis: number): Observable<any>{
    const hist: historico = {
      period: his.periodo,
      planned: Number(his.planeado),
      executed: his.ejecutado,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public PatchHistoricoNull(his: any, idAct: number, idHis: number): Observable<any>{
    const hist: historico = {
      period: his.period,
      planned: Number(his.planned),
      executed: his.executed,
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }

  public postFile(fil: any, idAd: number, fecha: any): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', fil);
    formData.append('created_at', fecha);

    const apiURL = this.apiURL+"adicional/"+idAd+"/file";
    return this.http.post(`${apiURL}`, formData, {headers: this.headersAr});
  }
  public getFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+idAd+"/file/"+idFil;
    return this.http.get(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }
  public deleteFile(idAd: any, idFil: any): Observable<any>{
    const apiURL = this.apiURL+"adicional/"+idAd+"/file/"+idFil;
    return this.http.delete(`${apiURL}`, {headers: this.headers, 'responseType'  : 'arraybuffer' as 'json'});
  }



  public PatchHistoricoPlaneado(his: any, idAct: number, idHis: number): Observable<any>{
    const hist: historico = {
      planned: Number(his.planeado),
    }
    const body = JSON.stringify(hist);
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public DeleteHistorico(his: any, idAct: number, idHis: number): Observable<any>{
    const apiURL = this.apiURL+"actividad/"+idAct+"/history/"+idHis;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public patchActividades(act: any): Observable<any>{
    const acti: actividades = {
      end_date: act.end_date.toISOString(),
    }
    const body = JSON.stringify(acti);
    const apiURL = this.apiURL+"actividad/"+act.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
}
