<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!--TITULO DEL SUBMODULO-->
    <div class="w3-caja-menu padreizquierda">
      <div class="w3-row hijoizquierda">
        <div class="w3-noventa">
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
          <span class="w3-text-deep-orange">&bull;</span>
          <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
        </div>
        <div class="w3-rest">
          <div class="w3-right">
            <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" style="margin-right: 2vw;"
              [matMenuTriggerFor]="menu2">more_vert</mat-icon>
          </div>
          <mat-menu #menu2="matMenu">
            <ng-container *ngIf="planeacion">
            </ng-container>
            <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
              <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar xlsx</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row">
      <div *ngFor="let per of diccionarioEst; let i = index">
        <div class="w3-half_e" style="height: 39vh;">
          <div class="w3-row w3-margin-left-caja" style="height: 36vh;">
            <div class="w3-row w3-white">
              <div class="w3-noventa titulo-contenidomenu">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido w3-tituloZonas"> {{per.perspectiva.nombre}} </span>
                </div>
              </div>
              <div class="w3-rest w3-right-align_rest" *ngIf="i === 1">
                
              </div>
            </div>
            <div class="w3-row w3-white" style="height: 32vh;">
              <div class="w3-row w3-scroll" style="height: 30vh; margin-left: 1vw; margin-right: 1vw;">
                <table class="w3-table w3-border" id="tableSticky">
                  <thead>
                    <tr>
                      <th class="elemento-titulo-table borderInf tresPun encabezado" style="width: 3vw;"> # </th>
                      <th class="elemento-titulo-table borderInf tresPun encabezado" style="width: 12vw;"> Objetivo </th>
                      <th class="elemento-titulo-table borderInf tresPun alinIzq encabezado">  {{ 'estrategia' | translate }} </th>
                      <th *appRoles="['Rol_Administrador','Rol_Carga']" class="elemento-titulo-table borderInf tresPun alinCen encabezado" style="width: 3.5vw;">
                        <button matTooltip="Agregar {{ 'AlineamientoForm.add' | translate }}" class="colorBotonDeNuevo" (click)="open_modal(per.perspectiva)">
                          <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                          <!--Agregar {{ 'AlineamientoForm.add' | translate }}-->
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let ele of per.estrategias; let i = index;">
                    <ng-container>
                      <td class="w3-table-trtdBalance tresPun"> {{ "etiqueta" | translate }}{{i+1}} </td>
                      <td class="w3-table-trtdBalance tresPun textoOverflow" style="max-width: 10vw; overflow: hidden;"> <span matTooltip="{{obtObj(ele)}}">
                        {{obtObj(ele)}}</span>
                      </td>
                      <td class="w3-table-trtdBalance alinIzq tresPun textoOverflow" style="max-width: 20vw; overflow: hidden;">
                        <span style="margin-left: 1vw;" matTooltip="{{ele.name}}">{{ele.name}}</span>
                      </td>
                      <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance tresPun alinCen"> 
                        <mat-icon class="iconoMen w3-button" matTooltip="Editar" (click)="open_edit(ele,per.perspectiva)">edit</mat-icon> 
                        <mat-icon class="iconoMen w3-button" matTooltip="Eliminar" (click)="alertas('eliminar',ele)">delete</mat-icon>
                      </td>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="agregarInd" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda" style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Alineamiento estratégico | 
                        <ng-container *ngFor="let per of diccionarioEst">
                          <ng-container *ngIf="per.perspectiva.id === idPers">
                            <span class="w3-subtituloA"> {{per.perspectiva.nombre}} </span>
                          </ng-container>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicio"></span>


          <div class="w3-row" [formGroup]="formEst">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> <span>{{ 'estrategia' | translate }}</span>: </span></div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formEst.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" class="inputDisp">
                <mat-label> Ingrese un nombre corto para <span>{{ 'ingrese' | translate }}</span> </mat-label>
                <input type="text" id="estrategia" matInput formControlName="inputName" required>
                <mat-error *ngIf="formEst.controls['inputName'].errors">
                  <span *ngIf="formEst.controls['inputName'].errors['required']">Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formEst">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Alineamiento: </span>
              </div>
            </div>
            <div class="w3-rest">              
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Seleccione el/los objetivos </mat-label>
                <mat-select [(ngModel)]="ObjSelect1" required [ngModelOptions]="{standalone: true}">
                  <ng-container *ngFor="let obj of objetivos">
                    <mat-option [value]="obj.id" *ngIf="obj.perspectiveId === idPers" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="linea" style="width: 80%;"></div>

          <div class="w3-row">
            <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido"> Proyecto asociado <span>{{ 'asociado' | translate }}</span> </span>
              </div>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formProg">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formProg.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Ingrese el nombre del proyecto </mat-label>
                <input type="text" id="proyecto" matInput formControlName="inputNamePro" required>
                <mat-error *ngIf="formProg.controls['inputNamePro'].errors">
                  <span *ngIf="formProg.controls['inputNamePro'].errors['required']">Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Ingrese una descripción </mat-label>
                <textarea matInput id="descripcion"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formProg">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Líder: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Seleccione un líder de proyecto </mat-label>
                <mat-select id="lider" [(ngModel)]="liderSelect" required [ngModelOptions]="{standalone: true}">
                  <ng-container *ngFor="let lider of responsable">
                    <mat-option value="{{lider.id}}" matTooltip="{{lider.nombre}}"> {{lider.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="modal">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardar()">
                <button mat-flat-button color="success" (click)="alertas('guardar')" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="editIndicador" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Alineamiento estratégico </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <span id="inicioEditar"></span>


          <div class="w3-row" [formGroup]="formEst">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> <span>{{ 'estrategia' | translate }}</span>: </span></div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formEst.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Ingrese un nombre corto para <span>{{ 'ingrese' | translate }}</span> </mat-label>
                <input type="text" id="estrategiaedit" matInput formControlName="inputNameEdit" required>
                <mat-error *ngIf="formEst.controls['inputNameEdit'].errors">
                  <span *ngIf="formEst.controls['inputNameEdit'].errors['required']">Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formEst">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Alineamiento: </span>
              </div>
            </div>
            <div class="w3-rest">              
              <mat-form-field floatPlaceholder="never" shouldPlaceholderFloat="false" appearance="outline" style="width: 25vw;">
                <mat-label> Seleccione el/los objetivos </mat-label>
                <mat-select [(ngModel)]="ObjSelect1"  [ngModelOptions]="{standalone: true}" required>
                  <ng-container *ngFor="let obj of objetivos">
                    <mat-option value="{{obj.id}}" *ngIf="obj.perspectiveId === idPers" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="linea" style="width: 80%;"></div>

          <div class="w3-row">
            <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
              <div class="hijoizquierdaTitle">
                <span class="w3-margin-left-contenido"> Proyecto asociado <span>{{ 'asociado' | translate }}</span> </span>
              </div>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formProg">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
            </div>
            <div class="w3-rest">
              <mat-error *ngIf="formProg.hasError('isValid')" class="error-message">
                Nombre <strong> existente.</strong>
              </mat-error>
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Ingrese el nombre del proyecto </mat-label>
                <input type="text" id="proyectoedit" matInput formControlName="inputNameProEdit" required>
                <mat-error *ngIf="formProg.controls['inputNameProEdit'].errors">
                  <span *ngIf="formProg.controls['inputNameProEdit'].errors['required']">Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Ingrese una descripción </mat-label>
                <textarea matInput id="descripcionedit" required></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row" [formGroup]="formProg">
            <div class="w3-quarter" style="margin-top: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Líder: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 25vw;">
                <mat-label> Seleccione un líder de proyecto </mat-label>
                <mat-select id="lidere" [(ngModel)]="liderSelect"  [ngModelOptions]="{standalone: true}" required>
                  <ng-container *ngFor="let lider of responsable">
                    <mat-option value="{{lider.id}}" matTooltip="{{lider.nombre}}"> {{lider.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  <span>Campo <strong> obligatorio.</strong></span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="modal2">
            <div class="w3-right">
              <div class="botonesModales" [ngClass]="puede_guardarEdit()">
                <button mat-flat-button color="success" (click)="alertas('editar')" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>