import { Injectable } from '@angular/core';
import { DataService } from './../data-service/data.service';
import * as ExcelJS from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(
    private dataService: DataService,
  ) {}

  private titulos(hojaTrabajo: ExcelJS.Worksheet, encab: string[]){
    let header = hojaTrabajo.addRow(encab);

    header.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      if(number <= 3 || number > 6){
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left',
        };
      }else if(number >= 4 && number <=6){
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
        };
      }
    });
  }
  /** Seguimiento */
  public async generateExcelImagenes(tit: string,imagenes: any[],nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    var d = 1; 
    for(const img of imagenes){
      let imageId1 = libroExcel.addImage({
        buffer: img,
        extension: 'png',
      });
      if(d%2 !== 0){
        hojaTrabajo.addImage(imageId1, {
          tl: { col: 0, row: 8*d-3 },
          ext: { width: 600, height: 300 }
        });
      }else{
        hojaTrabajo.addImage(imageId1, {
          tl: { col: 4, row: 8*(d-1)-3 },
          ext: { width: 600, height: 300 }
        });
      }
      d++;
    }

    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);

    hojaTrabajo.getColumn(1).width = 30;
    hojaTrabajo.getColumn(2).width = 30;
    hojaTrabajo.getColumn(3).width = 30;
    hojaTrabajo.getColumn(4).width = 5;
    hojaTrabajo.getColumn(5).width = 30;
    hojaTrabajo.getColumn(6).width = 30;
    hojaTrabajo.getColumn(7).width = 30;
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelObjetivos2(tit: string,encab: string[],data: any,nombre: string,semaforos: any, avancePlan: number) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    hojaTrabajo.addRow([]);
    let row = hojaTrabajo.addRow(['Avance del plan:','','','','',avancePlan])
    var ad = '';
    row.eachCell((cell, number) => {
      ad = cell.address[cell.address.length-1];
      if(number === 6){
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 14,
          bold: true,
          color: { argb: '00000000' },
        };
        cell.numFmt = '0.00%';
        cell.border = {
          left: {
            style: 'thick',
            color: { argb: this.semaforo(semaforos, avancePlan * 100) },
          },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004490B8' },//this.semaforo(semaforos, avancePlan * 100)
          bgColor: { argb: '00FFFFFF' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
        };
      }else{
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 14,
          bold: true,
          color: { argb: 'FFFFFFFF' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004490B8' },
          bgColor: { argb: '00000000' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right',
        };
      }
    });
    let merg = 'A'+ad+':E'+ad;
    hojaTrabajo.mergeCells(merg);
    
    const datos = data;
    // Formateo de los datos
    for (const d of datos) {
      if(d[0] === ''){
        hojaTrabajo.addRow([]);
      }
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if(d[2] === ''){
          if(d[1] === ''){
            if(number === 6){
              this.titulos(hojaTrabajo, encab);
              cell.font = {
                name: 'Arial Narrow',
                family: 4,
                bold: true,
                size: 12,
                color: { argb: '00000000' },
              };
              cell.numFmt = '0.00%';
              cell.border = {
                left: {
                  style: 'thick',
                  color: { argb: this.semaforo(semaforos, d[number - 1] * 100) },
                },
              };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'EEEEEEEE' },//this.semaforo(semaforos, d[number - 1] * 100)
                bgColor: { argb: '00FFFFFF' },
              };
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              };
            }else{
              cell.font = {
                name: 'Arial Narrow',
                family: 4,
                size: 12,
                bold: true,
                color: { argb: '00A6A6A6' },
              };
        
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'EEEEEEEE' },
                bgColor: { argb: '00FFFFFF' },
              };
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }
          }else{
            cell.font = {
              name: 'Arial Narrow',
              family: 4,
              size: 12,
              bold: true,
              color: { argb: 'FFFFFFFF' },
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '004EA5D3' },
              bgColor: { argb: '00000000' },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
            if(number === 6){
              cell.font = {
                name: 'Arial Narrow',
                family: 4,
                bold: true,
                size: 12,
                color: { argb: '00000000' },
              };
              cell.numFmt = '0.00%';
              cell.border = {
                left: {
                  style: 'thick',
                  color: { argb: this.semaforo(semaforos, d[number - 1] * 100) },
                },
              };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '004EA5D3' },//this.semaforo(semaforos, d[number - 1] * 100)
                bgColor: { argb: '00FFFFFF' },
              };
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              };
            }
          }
        }else{
          if(number === 6){
            cell.font = {
              name: 'Arial Narrow',
              family: 4,
              size: 11,
              bold: true,
              color: { argb: '00000000' },
            };
            cell.numFmt = '0.00%';
            cell.border = {
              left: {
                style: 'thick',
                color: { argb: this.semaforo(semaforos, d[number - 1] * 100) },
              },
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },//this.semaforo(semaforos, d[number - 1] * 100)
              bgColor: { argb: '00FFFFFF' },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
            };
          }else if (number === 2 || number === 4 || number === 5) {
            cell.font = {
              name: 'Arial Narrow',
              family: 4,
              size: 11,
              color: { argb: 'FF000000' },
            };
            if(number === 2){
              cell.numFmt = '#,##0';
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }else{
              cell.numFmt = '#,##0.00';
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              };
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: 'FF000000' },
            };
          }else{
            cell.font = {
              name: 'Arial Narrow',
              family: 4,
              size: 11,
              color: { argb: 'FF000000' },
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: 'FF000000' },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
          }
        }
      });
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });

    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);

    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelIndicadores(tit: string,encab: string[],encab2: string[],data: any,nombre: string,semaforos: any) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado1 = encab2;
    let NuevaLinea1 = hojaTrabajo.addRow(encabezado1);
    // Cell Style : Fill and Border
    var cel1 = ''; 
    NuevaLinea1.eachCell((cell, number) => {
      if(cell.value !== ''){
        cel1 = cell.address;
      }else if(cel1 !== '' && cell.value === ''){
        hojaTrabajo.mergeCells(cel1+':'+cell.address);
      }
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of data) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if (number % 2 !== 0 && number > 5) {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: '00000000' },
          };
          if (d[number - 1][0] !== '') {
            cell.numFmt = '#,##0.0#';
            if(d[number - 1][0] === null){
              d[number - 1] = 0;
            }
            d[number - 1] = Number(d[number - 1]);            
            let porcentaje = 0; 
            if( cell.value[0] === 'null' ){
              porcentaje = cell.value[1];
              cell.value = 0;
            }else{
              porcentaje = cell.value[1];
              cell.value = Number(cell.value[0]);
            }
            cell.border = {
              left: {
                style: 'thick',
                color: {
                  argb: this.semaforo(semaforos,porcentaje),
                },
              },
            };
          }else{
            cell.value = cell.value[0]
          }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number >= 6) {
          if (d[number - 1] != '') {
            cell.numFmt = '#,##0.0#';
            cell.value = Number(cell.value);
            d[number - 1] = Number(d[number - 1]);
          }
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: 'FF000000' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00E0E0E0' },
            bgColor: { argb: '00FFFFFF' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: 'FF000000' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: 'FF000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelProgramas(tit: string,encab: string[],data: any,nombre: string,semaforos: any) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of data) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if (number === 5) {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: '00000000' },
          };
          cell.numFmt = '#,##0.0#';
          cell.border = {
            left: {
              style: 'thick',
              color: {
                argb: this.semaforo(
                  semaforos,
                  (d[number - 1] * 100) / d[number - 2]
                ),
              },
            },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00FFFFFF' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 4) {
          cell.numFmt = '#,##0.0#';
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: 'FF000000' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: 'FF000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 6) {
          cell.numFmt = '#,##0';
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: 'FF000000' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: 'FF000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            color: { argb: 'FF000000' },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: 'FF000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelProyectos(tit: string,encab: string[],datos: any,nombre: string,semaforos: any) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if (number === 9) {
          cell.numFmt = '#,##0.0#';
          cell.border = {
            left: {
              style: 'thick',
              color: {
                argb: this.semaforo(
                  semaforos,
                  (d[number - 1] * 100) / d[number - 2]
                ),
              },
            },
          };
          if(d[number - 1] === 'null'){
            cell.value = 0;
          }
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 4 || number === 6) {
          cell.numFmt = 'dd/mmm/yyyy';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 5) {
          cell.numFmt = '#,##0';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 8) {
          cell.numFmt = '#,##0.0#';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number >= 10) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 3){
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        } else {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }
        var isBold = false;
        var color = '00FFFFFF';
        if(d[2].split('-').length === 1){
          isBold = true;
          color = 'EEEEEEEE';
        }else{
          if(number === 3){
            cell.value = '  '+d[2].split('-')[1];
          }
        }
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: isBold,
          color: { argb: 'FF000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
          bgColor: { argb: 'FF000000' },
        };
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelActas(tit: string,encab: string[],datos: any,nombre: string,semaforos: any) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if (number === 6) {
          cell.numFmt = '#,##0.0#';
          cell.border = {
            left: {
              style: 'thick',
              color: {
                argb: this.semaforo(
                  semaforos,
                  (d[number - 1] * 100) / d[number - 2]
                ),
              },
            },
          };
          if(d[number - 1] === 'null'){
            cell.value = 0;
          }
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 2 || number === 3) {
          cell.numFmt = 'dd/mmm/yyyy';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 5) {
          cell.numFmt = '#,##0.0#';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 7) {
          if(d[number - 1] === 'null'){
            cell.value =  'Sin validar';
          }else if(d[number - 1] === 'false'){
            cell.value = 'Sin validar';
          }else if(d[number - 1] === 'true'){
            cell.value = 'Validado';
          }else if(d[number - 1] === ''){
            cell.value = '';
          }
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        } else if (number >= 8) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number ===   1){
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        } else {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }
        var isBold = false;
        var color = '00FFFFFF';
        if(d[0].split('-').length === 1){
          isBold = true;
          color = 'EEEEEEEE';
        }else{
          if(number === 1){
            cell.value = '  '+d[0].split('-')[1];
          }
        }
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: isBold,
          color: { argb: 'FF000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
          bgColor: { argb: 'FF000000' },
        };
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelPlanes(tit: string,encab: string[],datos: any,nombre: string,semaforos: any) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if (number === 7) {
          cell.numFmt = '#,##0.0#';
          cell.border = {
            left: {
              style: 'thick',
              color: {
                argb: this.semaforo(
                  semaforos,
                  (d[number - 1] * 100) / d[number - 2]
                ),
              },
            },
          };
          if(d[number - 1] === 'null'){
            cell.value = 0;
          }
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 2 || number === 3) {
          cell.numFmt = 'dd/mmm/yyyy';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 5) {
          cell.numFmt = '#,##0.0#';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number === 6) {
          cell.numFmt = '#,##0.0#';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number === 8) {
          cell.numFmt = '0.0#%';
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        } else if (number >= 9) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };
        } else if (number ===   1){
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        } else {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }
        var isBold = false;
        var color = '00FFFFFF';
        if(d[0].split('-').length === 1){
          isBold = true;
          color = 'EEEEEEEE';
        }else{
          if(number === 1){
            cell.value = '  '+d[0].split('-')[1];
          }
        }
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: isBold,
          color: { argb: 'FF000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
          bgColor: { argb: 'FF000000' },
        };
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelCMIFormulacion(tit: string,encab: string[],datos: any,nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: false,
          color: { argb: 'FF000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' },
        };
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelCMISeguimiento(tit: string,encab: string[],datos: any,nombre: string,semaforos: any){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    // Encabezados
    const encabezado = encab;
    let NuevaLinea = hojaTrabajo.addRow(encabezado);
    // Cell Style : Fill and Border
    NuevaLinea.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });
    // Formateo de los datos
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        if(number === 3 && d[number - 1] !== ''){
          cell.numFmt = '0.0#%';
          cell.border = {
            left: {
              style: 'thick',
              color: { argb: this.semaforo(semaforos, d[number - 1] * 100) },
            },
          };
        }else if(number === 3 && d[number - 1] === ''){
          cell.numFmt = '0.0#%';
          cell.border = {
            left: {
              style: 'thick',
              color: { argb: this.semaforo(semaforos, null) },
            },
          };
        }
        var isBold = false;
        var color = '00FFFFFF';
        if(d[1].split('-').length === 1){
          isBold = true;
          color = 'EEEEEEEE';
        }else{
          if(number === 2){
            cell.value = '  '+d[1].split('-')[1];
          }
        }
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: isBold,
          color: { argb: 'FF000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: 'FF000000' },
        };
      });
    }
    // Introducción de la imagen
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();
    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    //Tamaños de columnas
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);      
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    // Exportación del excel
    this.printExcel(nombre, libroExcel);
  }
  private semaforo(semaforos: any, porcentaje: any) {
    if(porcentaje === null){
      return '00b3b3b3';
    }
    var valfin = 0;
    for(const sem of semaforos){
      if(valfin < sem.valfin){
        valfin = sem.valfin
      }
    }
    if(porcentaje >= valfin){
      porcentaje = valfin-1;
    }
    var col = '';
    for (const sem of semaforos) {
      var ab = sem.valIn;
      if(ab !== 0){
        ab = ab-0.1;
      }
      if(porcentaje >= ab && porcentaje < sem.valfin){
        col = sem.color;
        break;
      }
    }
    switch (col) {
      case 'rojo':
        return '00FF495C';
      case 'naranja':
        return '00FF8300';
      case 'amarillo':
        return '00FFD100';
      case 'azul':
        return '000075C9';
      case 'verde':
        return '0072D54A';
      default:
        return '00b3b3b3';
    }
    return '00b3b3b3';
  }
  /** Administrador */
  public async generateExcelResponsables(tit: string,encab: string[],data: any,nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    const datos = data;
    this.titulos(hojaTrabajo, encab);
    for (const d of datos) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      row.eachCell((cell, number) => {
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 12,
          color: { argb: '00000000' },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
          bgColor: { argb: '00000000' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left',
        };
      });
    }

    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });

    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, tit, imageId1);
    this.printExcel(nombre, libroExcel);
  }
  /** Creación */
  private createExcel(): ExcelJS.Workbook{
    let libroExcel = new ExcelJS.Workbook();
    let hojaTrabajo = libroExcel.addWorksheet('Tabla',{});
    let primerLinea = hojaTrabajo.addRow([]);

    primerLinea.font = {
      name: 'Arial Narrow',
      family: 4,
      size: 16,
      bold: true,
    }
    hojaTrabajo.addRow([]);

    let fec = hojaTrabajo.addRow([]);
    fec.font = { name: 'Arial Narrow', family: 4, size: 12 };

    let realizado = hojaTrabajo.addRow([]);
    realizado.font = { name: 'Arial Narrow', family: 4, size: 12 };
    hojaTrabajo.addRow([]);
    
    return libroExcel;
  }
  private printExcel(nombre: string, libroExcel: ExcelJS.Workbook){
    libroExcel.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, nombre.concat('.xlsx'));
    });
  }
  private addTituloFechaPlataforma(hojaTrabajo: ExcelJS.Worksheet, titulo: string, img){
    hojaTrabajo.getRow(1).values = [titulo]; 
    const fecha = new Date();
    var fech = fecha.toLocaleString();
    hojaTrabajo.getRow(4).values = ['Fecha: '.concat(fech) +' | '+ this.capitalizacionTexto(this.dataService.name) + ' ' +
    this.capitalizacionTexto(this.dataService.last_name)];
    hojaTrabajo.getRow(5).values = [''];
    hojaTrabajo.mergeCells('A1:E2');
    hojaTrabajo.mergeCells('A4:E4');
    
    if(hojaTrabajo.lastColumn.number > 6){
      hojaTrabajo.addImage(img, {
        tl: { col: hojaTrabajo.lastColumn.number-2, row: 1 },
        ext: { width: 100, height: 40 }
      });
    }else{
      hojaTrabajo.addImage(img, {
        tl: { col: 5, row: 1 },
        ext: { width: 100, height: 40 }
      });
    }
  }
  private asignarTamanoDinamicoColumnas(hojaTrabajo: ExcelJS.Worksheet): void{
    let dataMax: number[];
    let max: number;
    hojaTrabajo.columns.forEach((column: any, index) => {
      dataMax = [];
      column.eachCell({ includeEmpty: false }, (cell: any) => {
        dataMax.push(cell.value?.toString().length || 0);
      });
      max = Math.max(...dataMax);
      column.width = max < 10 ? 10 : max+3;
    });
    if(hojaTrabajo.lastColumn.number > 7){
      hojaTrabajo.pageSetup.printArea = 'A:'+String.fromCodePoint(hojaTrabajo.lastColumn.number+64);
    }else{
      hojaTrabajo.pageSetup.printArea = 'A:'+String.fromCodePoint(7+64);
    }
    hojaTrabajo.pageSetup.scale = 70;
  }
  private capitalizacionTexto(texto){
    var palabra = texto;
    if(texto === "") return "";
    var mayuscula = palabra.substring(0,1).toUpperCase();
    if (palabra.length > 0) {
      var minuscula = palabra.substring(1).toLowerCase();
    }else{
      return mayuscula;
    }
    return mayuscula.concat(minuscula);
  }
  /** Formulación */
  public async generateExcelPlanificaciones(
    titulo: string,
    encab: string[],
    data: any,
    nombre: string
  ) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    let encabezado = hojaTrabajo.addRow(encab);

    encabezado.eachCell((cell, number) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });

    for (const d of data) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };
      let cell = row.getCell(6);

      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }
    let dataMax: number[];
    let max: number;
    hojaTrabajo.columns.forEach((column: any) => {
      dataMax = [];
      column.eachCell({ includeEmpty: false }, (cell: any) => {
        dataMax.push(cell.value?.toString().length || 0);
      });
      max = Math.max(...dataMax);
      column.width = max < 10 ? 10 : max;
    });
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelPerspectivas(titulo: string, encabezado: string[], data: any, nombre: string) {
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    let header = hojaTrabajo.addRow(encabezado);

    header.eachCell((cell) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });

    for(const d of data) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };

      row.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      row.getCell(1).font = {
        color: {argb: '0A4E97' }
      }
      row.getCell(2).alignment = {
        wrapText:true
      }
    }
      
      let dataMax: number[];
      let max: number;
      hojaTrabajo.columns.forEach((column: any, index) => {
        dataMax = [];
        column.eachCell({ includeEmpty: false }, (cell: any) => {
          dataMax.push(cell.value?.toString().length || 0);
        });
        max = Math.max(...dataMax);
        if(index == 1){
          column.width = max < 10 ? 10 : max /  2;
        }else{
          column.width = max < 10 ? 10 : max;
        }
      });
  
      const imageSrc = 'assets/img/creado.png';
      const response = await fetch(imageSrc);
      const buffer = await response.arrayBuffer();
  
      let imageId1 = libroExcel.addImage({
        buffer: buffer,
        extension: 'png',
      });
      
      this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);

    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelFilosofiaCorp(titulo: string, encabezado: string[], data: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    let header = hojaTrabajo.addRow(encabezado);

    header.eachCell((cell) => {
      cell.font = {
        name: 'Arial Narrow',
        family: 4,
        size: 11,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00C4C4C4' },
        bgColor: { argb: '00000000' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });

    for(const d of data) {
      let row = hojaTrabajo.addRow(d);
      row.font = { name: 'Arial Narrow', family: 4, size: 11 };

      row.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      row.getCell(1).font = {
        color: {argb: '0A4E97' }
      }
      row.getCell(2).alignment = {
        wrapText:true
      }
    }
    
    let dataMax: number[];
      let max: number;
      hojaTrabajo.columns.forEach((column: any, index) => {
        dataMax = [];
        column.eachCell({ includeEmpty: false }, (cell: any) => {
          dataMax.push(cell.value?.toString().length || 0);
        });
        max = Math.max(...dataMax);
        column.width = max < 10 ? 10 : max;
      });
  
      const imageSrc = 'assets/img/creado.png';
      const response = await fetch(imageSrc);
      const buffer = await response.arrayBuffer();
  
      let imageId1 = libroExcel.addImage({
        buffer: buffer,
        extension: 'png',
      });
      
      this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelMatrizFoda(titulo: string, header1: any, header2: any, data1: any, data2: any, nombre: string, tituloTabla1: string, tituloTabla2: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    let ubiTitle1 = hojaTrabajo.addRow([]);
    let headerT1 = hojaTrabajo.addRow(header1);

    headerT1.eachCell((cell) => {
      if(cell.value &&  cell.value?.toString().length > 0 ){
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: true,
          color: { argb: 'FFFFFFFF' },
        };
  
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
      }
    });

    let r = 8;
    for(const d of data1[0]){
      let row = hojaTrabajo.getRow(r)
      for(let i = 1; i  <= d.length;   i++){
        row.getCell(i).value = d[i-1];
      }
      r++;
    }

    r = 8;
    for(const d of data1[1]){
      let row = hojaTrabajo.getRow(r)
      for(let i = 0; i  <= d.length;   i++){
        row.getCell(i+4).value = d[i];
      }
      r++;
    }    

    hojaTrabajo.addRow([]);
    let ubiTitle2 = hojaTrabajo.addRow([]);
    let headerT2 = hojaTrabajo.addRow(header2);

    headerT2.eachCell((cell) => {
      if(cell.value &&  cell.value?.toString().length > 0 ){
        cell.font = {
          name: 'Arial Narrow',
          family: 4,
          size: 11,
          bold: true,
          color: { argb: 'FFFFFFFF' },
        };
  
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00C4C4C4' },
          bgColor: { argb: '00000000' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
      }
      
    });

    let lastRowOrig = hojaTrabajo.lastRow?.number;
    let lastRow = hojaTrabajo.lastRow?.number;
    if(lastRow){
      lastRow++;
      for(const d of data2[0]){
        let row = hojaTrabajo.getRow(lastRow)
        for(let i = 1; i  <= d.length;   i++){
          row.getCell(i).value = d[i-1];
        }
        lastRow++;
      }
      lastRow = lastRowOrig! + 1;
      for(const d of data2[1]){
        let row = hojaTrabajo.getRow(lastRow!)
        for(let i = 0; i  <= d.length;   i++){
          row.getCell(i+4).value = d[i];
        }
        lastRow!++;
      } 
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);
    ubiTitle2.values = [tituloTabla2];
    ubiTitle1.values = [tituloTabla1];
    hojaTrabajo.mergeCells(ubiTitle1.number, 1, ubiTitle1.number, 2);
    hojaTrabajo.mergeCells(ubiTitle2.number, 1, ubiTitle2.number, 2);

    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelObjetivosFormulacion(titulo: string, subtitulos: any, headers: any, contenido: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    let ubicTitulos = [];
    let ubicHeaders = [];
    for (let i = 0; i < subtitulos.length; i++) {
      ubicTitulos.push(hojaTrabajo.addRow([]));
      ubicHeaders.push(hojaTrabajo.addRow([...headers[i]]));
      for(const d of contenido[i]){
        hojaTrabajo.addRow(d);
      }
      hojaTrabajo.addRow([]);
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);

    for(let i = 0; i < ubicTitulos.length; i++){
      ubicTitulos[i].values = [subtitulos[i]];
      ubicTitulos[i].getCell(1).font = {
        color: {argb: '0A4E97' }
      }
      const rownumber = ubicTitulos[i].number
      hojaTrabajo.mergeCells(rownumber, 1, rownumber, 2);
    }

    for(const ubiHeader of ubicHeaders){
      ubiHeader.eachCell((cell) => {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
    
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        
      });
    }
    this.printExcel(nombre, libroExcel);
  }
  public async generateExcelIndicadoresFormulacion(titulo: string, aniosSeleccionados: any, subtitulos: any, headers: any, contenido: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    let ubicTitulos = [];
    let ubicHeaders = [];
    const ubicAniosSel = hojaTrabajo.addRow(['Años seleccionados: ', ... aniosSeleccionados]);
    for(let i = 0; i < subtitulos.length; i++){
      ubicTitulos.push(hojaTrabajo.addRow([]));
      ubicHeaders.push(hojaTrabajo.addRow([...headers[i]]));
      for(const d of contenido[i]){
        hojaTrabajo.addRow(d);
      }
      hojaTrabajo.addRow([]);
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);
    for(let i = 0; i < ubicTitulos.length; i++){
      ubicTitulos[i].values = [subtitulos[i]];
      ubicTitulos[i].getCell(1).font = {
        color: {argb: '0A4E97' }
      }
      const rownumber = ubicTitulos[i].number
      hojaTrabajo.mergeCells(rownumber, 1, rownumber, 2);
    }

    for(const ubiHeader of ubicHeaders){
      ubiHeader.eachCell((cell) => {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
    
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        
      });
    }
    this.printExcel(nombre, libroExcel);

  }

  public async generateExcelProyectosEstrategicosFormulacion(titulo: string, headers: any, proyectos: any, actividades: any, filtros: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    const ubicTableHeaders = hojaTrabajo.addRow([...headers[0]]);
    let row;
    for(let i = 0; i< proyectos.length; i++){
      row = hojaTrabajo.addRow([...proyectos[i]]);
      row.eachCell((cell, index) => {
        if(index === 1 || index === 2){
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }else if(index === 4 || index ===6){
          cell.numFmt = 'dd-mmm-yyyy';
        }else{
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00E6E7E8' },
          bgColor: { argb: '00E6E7E8' },
        };
      })
      for(const act of actividades[i]){
        let rowAct = hojaTrabajo.addRow([...act])
        rowAct.eachCell((cell, index) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00000000' },
          };
          if(index === 1 || index === 2){
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
          }else if(index === 4 || index ===6){
            cell.numFmt = 'dd-mmm-yyyy';
          }else{
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
          }
        })
      }
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);

      ubicTableHeaders.eachCell((cell) => {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
    
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        
      });


    this.printExcel(nombre, libroExcel);

  }
  public async generateExcelActasFormulacion(titulo: string, headers: any, proyectos: any, actividades: any, filtros: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');
    const ubicTableHeaders = hojaTrabajo.addRow([...headers[0]]);
    let row;
    for(let i = 0; i< proyectos.length; i++){
      row = hojaTrabajo.addRow([...proyectos[i]]);
      row.eachCell((cell, index) => {
        if(index === 1 || index === 2){
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
        }else if(index === 3 || index ===5){
          cell.numFmt = 'dd-mmm-yyyy';
        }else{
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00E6E7E8' },
          bgColor: { argb: '00E6E7E8' },
        };
      })
      for(const act of actividades[i]){
        let rowAct = hojaTrabajo.addRow([...act])
        rowAct.eachCell((cell, index) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00FFFFFF' },
            bgColor: { argb: '00000000' },
          };
          if(index === 1 || index === 2){
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
          }else if(index === 3 || index ===5){
            cell.numFmt = 'dd-mmm-yyyy';
          }else{
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
          }
        })
      }
    }
  
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);

      ubicTableHeaders.eachCell((cell) => {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
    
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        
      });


    this.printExcel(nombre, libroExcel);

  }
  public async generateExcelAlineamientoEstrategicoFormulacion(titulo: string, titulosTablas: any, headerTablas: any, contenidoTabla: any, nombre: string){
    let libroExcel = this.createExcel();
    let hojaTrabajo = libroExcel.getWorksheet('Tabla');

    let ubicTitulos = [];
    let ubicHeaders = [];

    for(let i = 0; i< titulosTablas.length; i++){
      ubicTitulos.push(hojaTrabajo.addRow([]));
      ubicHeaders.push(hojaTrabajo.addRow([...headerTablas[i]]))
      if(contenidoTabla[i].length >0 ){
        for(const c of contenidoTabla[i]){
          let row = hojaTrabajo.addRow(c)
          row.eachCell((cell, indx) => {
            if(indx == 1 ){
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              }
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FFFFFF' },
              bgColor: { argb: '00000000' },
            };
          })
        }
      }else{
        hojaTrabajo.addRow(['','','']).eachCell(cell => {cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFFFFF' },
          bgColor: { argb: '00000000' },
        };})
      }
      hojaTrabajo.addRow([])
    }
    
    const imageSrc = 'assets/img/creado.png';
    const response = await fetch(imageSrc);
    const buffer = await response.arrayBuffer();

    let imageId1 = libroExcel.addImage({
      buffer: buffer,
      extension: 'png',
    });
    
    this.asignarTamanoDinamicoColumnas(hojaTrabajo);
    this.addTituloFechaPlataforma(hojaTrabajo, titulo, imageId1);

    for(let i = 0; i < ubicTitulos.length; i++){
      ubicTitulos[i].values = [titulosTablas[i]];
      ubicTitulos[i].getCell(1).font = {
        color: {argb: '0A4E97' }
      }
      const rownumber = ubicTitulos[i].number
      hojaTrabajo.mergeCells(rownumber, 1, rownumber, 2);
    }

    for(const ubiHeader of ubicHeaders){
      ubiHeader.eachCell((cell) => {
          cell.font = {
            name: 'Arial Narrow',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
    
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00C4C4C4' },
            bgColor: { argb: '00000000' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        
      });
    }
    this.printExcel(nombre, libroExcel);

  }
}