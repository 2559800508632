import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Estrategias{
  public objectiveIds?: Number[];
  public name?: String;
  public id?:Number;
}
export class Proyectos{
  public id?: Number;
  public name?: String;
  public weighing?: Number;
  public description?: String;

  public objectiveIds?: Number[];
  public responsibleId?: Number;
  public strategyIds?: Number[];
}
@Injectable({
  providedIn: 'root'
})
export class AlineamientoService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getInstituciones(): Observable<any>{
    const apiURL = this.apiURL+"institucion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getEstrategias(): Observable<any>{
    const apiURL = this.apiURL+"estrategia";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getProyectos(): Observable<any>{
    const apiURL = this.apiURL+"proyectos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getResponsable(): Observable<any>{
    const apiURL = this.apiURL+"area";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public postEstrategias(estr: any): Observable<any>{
    const estrategi: Estrategias = {
      objectiveIds: estr.objetiveId,
      name: estr.name
    }
    const body = JSON.stringify(estrategi);
    const apiURL = this.apiURL+"estrategia";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public patchEstrategia(estr: any): Observable<any>{
    const estrategi: Estrategias = {
      objectiveIds: estr.objetiveId,
      name: estr.name,
    }
    const body = JSON.stringify(estrategi);
    const apiURL = this.apiURL+"estrategia/"+estr.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public postProyectos(proy: any): Observable<any>{
    var proyect: Proyectos;
    if(proy.descripcion !== ''){
      proyect = {
        strategyIds: proy.programaId,
        objectiveIds: proy.objetiveId,
        name: proy.nombre,
        description: proy.descripcion,
        weighing: Number(proy.ponderacion),
        responsibleId: Number(proy.responsableId)
      }
    }else{
      proyect = {
        strategyIds: proy.programaId,
        objectiveIds: proy.objetiveId,
        name: proy.nombre,
        weighing: Number(proy.ponderacion),
        responsibleId: Number(proy.responsableId)
      }
    }
    const body = JSON.stringify(proyect);
    const apiURL = this.apiURL+"proyectos";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }

  public patchProyectos(proy: any): Observable<any>{
    const proyect: Proyectos = {
      objectiveIds: proy.objetiveId,
      name: proy.name,
      description: proy.description,
      weighing: Number(proy.weighing),
      responsibleId: Number(proy.responsableId)
    }
    const body = JSON.stringify(proyect);
    const apiURL = this.apiURL+"proyectos/"+proy.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteEstrategias(estr: any): Observable<any>{
    const apiURL = this.apiURL+"estrategia/"+estr.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
  public deleteProyectos(proy: any): Observable<any>{
    const apiURL = this.apiURL+"proyectos/"+proy.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
}
