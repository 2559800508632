<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-quarter padreizquierda ">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span> 
        <span class="w3-text-deep-orange">&bull;</span>
        <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span> 
      </div>
    </div>
  </div>
</div>

<div class="w3-container w3-contenedor-template" style="height: 85vh; overflow: scroll;">
  <div class="w3-row " style="margin-top: 1vh;">
    <div class="w3-row w3-white">
      <div class="w3-noventa w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
        <div class="w3-row" style="overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">
          <span class="w3-margin-left-contenido ">Planes estratégicos</span>
        </div>
      </div>
      <div class="w3-rest w3-right-align_rest " >
        <div class="w3-right hijoderechaTitle" style="z-index: 1;">
          <mat-icon class="w3-margin-right-contenido w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        </div>
        <mat-menu #menu2="matMenu">
          <ng-container *ngIf="planeacion">
            <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="abrirFormAgregar(planeacion)">
              <mat-icon [ngStyle]="{'color':'gray'}">add_circle</mat-icon>
              <span>Agregar planeación</span>
            </button>
          </ng-container>
          <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
            <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
            <span>Exportar xlsx</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="w3-row w3-white w3-scroll">

      <table class="w3-table allBordes"> <!-- w3-margin-left-caja-->
        <thead>
          <tr>
            <th class="elemento-titulo-table borderInf tresPun" style="width: 22vw;">Nombre</th>
            <th class="elemento-titulo-table borderInf tresPun" style="width: 22vw;">Responsable</th>
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 11.5vw;"> Fecha inicial </th>                  
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 11.5vw;"> Fecha final </th>
            <th class="elemento-titulo-table borderInf tresPun" style="width: 18vw;"> Institución </th>
            <th class="elemento-titulo-table borderInf tresPun" style="width: 8vw;"> Envio reportes </th>
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 8vw;"> Edición periodos </th>
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 8vw;"> Semáforo </th>
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 8vw;"> Editar </th>
            <th class="elemento-titulo-table borderInf tresPun alinCen" style="width: 8vw;"> Eliminar </th>
            <!--<th class="w3-elemento-titulo-table"> Eliminar </th>-->
            <th class="elemento-titulo-table borderInf" style="margin-left: 1vh;"> Configuración </th>
          </tr>
        </thead>
        <tbody *ngFor="let ele of planeacion; let i = index">
          <td class="w3-table-trtdBalance tresPun textoOverflow" style="max-width: 20vw;"><span matTooltip="{{ele.nombre}}">{{ele.nombre}}</span></td>
          <td class="w3-table-trtdBalance tresPun textoOverflow" style="max-width: 20vw;"><span matTooltip="{{escribirres(ele.responsible)}}">{{escribirres(ele.responsible)}}</span></td>
          <td class="w3-table-trtdBalance w3-center"> {{escribirmes(ele.fecha_inicio)}}</td>
          <td class="w3-table-trtdBalance w3-center"> {{escribirmes(ele.fecha_fin)}}</td>
          <td class="w3-table-trtdBalance tresPun textoOverflow" style="max-width: 20vw;"><span matTooltip="{{obtInst(ele)}}">{{obtInst(ele)}}</span></td>
          <td class="w3-table-trtdBalance w3-center">
            <mat-checkbox [checked]="ele.enableMailReports" (change)="activacionNotify(ele)"></mat-checkbox> 
          </td>
          <td class="w3-table-trtdBalance w3-center">
            <mat-checkbox [checked]="ele.isActive" (change)="activacionEdicion(ele)"></mat-checkbox> 
          </td>
          <td class="w3-table-trtdBalance w3-center">
            <ng-container *ngIf="semaforo">
              <button mat-icon-button class="w3-button" (click)="abrirFormSem(ele)">
                <mat-icon [ngStyle]="{'color':'gray'}">visibility</mat-icon>
              </button>
            </ng-container>
            
          </td>
          <td class="w3-table-trtdBalance w3-center">
            <button mat-icon-button class="w3-button" (click)="abrirFormEdit(ele)">
              <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
            </button>
          </td>
          <td class="w3-table-trtdBalance w3-center">
            <button mat-icon-button class="w3-button" (click)="alertas('eliminarPlan',ele)">
              <mat-icon [ngStyle]="{'color':'gray'}">delete</mat-icon>
            </button>
          </td>
          <td class="w3-table-trtdBalance w3-center">
            <button mat-icon-button>
              <mat-icon [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="configurar" >settings</mat-icon>
              <mat-menu #configurar="matMenu" >
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveperspectivas(ele)" >
                  <span>Perspectivas </span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActivefilosofia(ele)" >
                  <span>Filosofía corporativa </span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveMatrizfloa(ele)"> 
                  <span>Matriz FODA</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveObjetivos(ele)">
                  <span>Objetivos</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveIndicadores(ele)">
                  <span>Indicadores-metas</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveAlineamiento(ele)">
                  <span>Alineamiento estratégico</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveProyectos(ele)">
                  <span>Proyectos estratégicos</span>
                </button>
                <button class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="isActiveCuadro(ele)">
                  <span>Cuadro mando integral</span>
                </button>
              </mat-menu>
            </button>
          </td>
        </tbody>
      </table>

    </div>
  </div>
</div>

<div id="formularionuevo" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;"> <!--Aqui se cambia el tamaño del modal-->
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido"> Nueva planeación </span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 45vh;">
      <div class="w3-container" style="height: 40vh; overflow: scroll;">
        
        <form [formGroup]="formNuevo" (ngSubmit)="alertas('guardar')">

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Nombre: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width:38.2vw ;">
                <mat-label> Ingrese el nombre </mat-label>
                <input type="text" matInput formControlName="inputName" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Días laborables: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 20vw;">
                <mat-label> Seleccione los días que se laboran </mat-label>
                <mat-select formControlName="inputDays" required>
                  <ng-container *ngFor="let dia of diasLab">
                    <mat-option (click)="filtrodias(dia)" value="{{dia}}"> {{dia}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-half">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Fecha inicial: </span>
                </div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline">
                  <mat-label>Ingrese la fecha inicial</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="inputFechaIni" [matDatepickerFilter]="myFilter" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker disabled="false"></mat-datepicker>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Fecha final: </span>
                </div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline">
                  <mat-label>Ingrese la fecha final</mat-label>
                  <input matInput [matDatepicker]="picker2" formControlName="inputFechaFin" [matDatepickerFilter]="myFilter" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker2 disabled="false"></mat-datepicker>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-half">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Instituciones: </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-col">
                  <mat-form-field appearance="outline">
                    <mat-label> Seleccione la institución </mat-label>
                    <mat-select formControlName="inputIntstitucion" required>
                      <ng-container *ngFor="let ins of instituciones">
                        <mat-option value="{{ins.id}}" > {{ins.acronimo}} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                  <button class="w3-button" matSuffix mat-icon-button >
                    <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Activa: <mat-checkbox id="activa" [(ngModel)]="isActiveSelect" [ngModelOptions]="{standalone: true}">
                  </mat-checkbox></span>
                </div>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Responsable: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 20vw;">
                <mat-label> Seleccione un responsable </mat-label>
                <mat-select formControlName="inputResponsble" required>
                  <ng-container *ngFor="let obj of responsables">
                    <mat-option *ngIf="existe(obj.institucionId )" value="{{obj.id}}"> {{obj.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 11vw; margin-bottom: 2vh;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">
                  Semáforo (configuración):
                </span>
              </div>
            </div>
          </div>
  
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;"></div>
            <div class="w3-rest">
              <mat-form-field appearance="outline">
                <mat-label> Seleccione la cantidad </mat-label>
                <mat-select formControlName="inputNumColor" required>
                  <mat-option value="tres" (click)="elegirSem('tres')">3 colores</mat-option>
                  <mat-option value="cuatro" (click)="elegirSem('cuatro')">4 colores</mat-option>
                  <mat-option value="cinco" (click)="elegirSem('cinco')">5 colores</mat-option>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngFor="let sem of newsemaforo; let i = index;">
            <div class="w3-row" style="margin-left: 3vw;">
              <div class="w3-quarter">
                <div class="w3-row" style="width: 10.2vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 9.6vw;">
                    <mat-label> Valor 1 </mat-label>
                    <input type="number" value="{{sem.valor_00}}" id='{{"valor1"+i}}' matInput
                    formControlName="{{'inputVal1Sem'+i}}">
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-quarter">
                <div class="w3-row" style="width: 10vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 9.7vw;">
                    <mat-label> Ingrese el valor 2 </mat-label>
                    <input formControlName="{{i === (newsemaforo.length-1)? 'inputVal2Sem4':'inputVal2Sem'+i}}" value="{{sem.valor_01}}"
                    type="number" (input)="intNumero(i)" id='{{"valor2"+i}}' matInput>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row" style="width: 12vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <mat-label>Seleccione un color</mat-label>
                    <mat-select formControlName="{{'inputColor'+i}}" required>
                      <mat-option (click)="setcolor(i, 'rojo')" value="rojo"><span class="w3-text-red">Rojo</span></mat-option>
                      <mat-option (click)="setcolor(i, 'naranja')" value="naranja"><span class="w3-text-orange">Naranja</span></mat-option>
                      <mat-option (click)="setcolor(i, 'amarillo')" value="amarillo"><span class="w3-text-yellow">Amarillo</span></mat-option>
                      <mat-option (click)="setcolor(i, 'verde')" value="verde"><span class="w3-text-green">Verde</span></mat-option>
                      <mat-option (click)="setcolor(i, 'azul')" value="azul"><span class="w3-text-blue">Azul</span></mat-option>
                    </mat-select>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="modal">
            <div class="w3-right">
              <div class="botonesModales" ngClass="{{puede_guardar()}}">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales">
                  <button mat-stroked-button color="success2" type="reset" (click)="cerrarForm()" style="height: 4vh;">
                    Cancelar
                  </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>

<div id="formularioedit" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido"> Editar planeación </span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 45vh;">
      <div class="w3-container" style="height: 40vh; overflow: scroll;">

        <form [formGroup]="formEdit" (ngSubmit)="alertas('editar')">
        
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Nombre: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width:38.2vw ;">
                <mat-label> Ingrese el nombre </mat-label>
                <input type="text" matInput formControlName="inputName" required> 
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Días laborables: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 20vw;">
                <mat-label> Seleccione los días que se laboran </mat-label>
                <mat-select formControlName="inputDays" required>
                  <ng-container *ngFor="let dia of diasLab">
                    <mat-option (click)="filtrodias(dia)" value="{{dia}}"> {{dia}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-half">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Fecha inicial: </span>
                </div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline">
                  <mat-label>Ingrese la fecha inicial</mat-label>
                  <input matInput [matDatepicker]="picker3" formControlName="inputFechaIni" [matDatepickerFilter]="myFilter" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker3 disabled="false"></mat-datepicker>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Fecha final: </span>
                </div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline">
                  <mat-label>Ingrese la fecha final</mat-label>
                  <input matInput [matDatepicker]="picker4" formControlName="inputFechaFin" [matDatepickerFilter]="myFilter" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker4 disabled="false"></mat-datepicker>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-half">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Instituciones: </span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-col">
                  <mat-form-field appearance="outline">
                    <mat-label> Seleccione la institución </mat-label>
                    <mat-select formControlName="inputIntstitucion" required>
                      <ng-container *ngFor="let ins of instituciones">
                        <mat-option value="{{ins.id}}" > {{ins.acronimo}} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                  <button class="w3-button" matSuffix mat-icon-button >
                    <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="w3-rest">
              <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
                <div class="w3-right" style="margin-right: 1vh;" >
                  <span class="w3-subtitulo2">Activa: <mat-checkbox id="activa" [(ngModel)]="isActiveSelect" [ngModelOptions]="{standalone: true}">
                  </mat-checkbox></span>
                </div>
              </div>
            </div>
          </div>

          <div class="w3-row">
            <div class="w3-quarter" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;">
                <span class="w3-subtitulo2"> Responsable: </span>
              </div>
            </div>
            <div class="w3-rest">
              <mat-form-field appearance="outline" style="width: 20vw;">
                <mat-label> Seleccione un responsable </mat-label>
                <mat-select formControlName="inputResponsble" required>
                  <ng-container *ngFor="let obj of responsables">
                    <mat-option *ngIf="existe(obj.institucionId )" value="{{obj.id}}"> {{obj.nombre}} </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w3-right" style="margin-top: 2vh;">
            <div class="botonesModales" ngClass="{{puede_guardarEdit()}}">
              <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                Guardar
              </button>
            </div>
            <div class="botonesModales">
              <button mat-stroked-button color="success2" type="reset" (click)="cerrarForm()" style="height: 4vh;">
                Cancelar
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>    
  </div>
</div>

<div id="formSemaforo" class="w3-modal">
  <div class="w3-modal-content" style="width: 50vw;">
    <div class="w3-row w3-titulo-contenidomenu w3-header-cuadrante" style="height: 8vh;">
      <div class="w3-twothird">
        <div class="hijoizquierdaTitle"> 
          <span class="w3-margin-left-contenido"> Configuración del semáforo </span>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="cerrarForm()" class="w3-button w3-display-topright" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 45vh;">
      <div class="w3-container" style="height: 40vh; overflow: scroll;">

        
        <form [formGroup]="formSemaforo" (ngSubmit)="alertas('editSem')"> <!--(click)=""-->
          
          <div class="w3-row">
            <div class="w3-col" style="margin-top: 2vh; width: 7vw;">
              <div class="w3-right" style="margin-right: 1vh;" >
                <span class="w3-subtitulo2">Semáforos: </span>
              </div>
            </div>
            <div [ngClass]="puedeSelec()" (click)="cambioEdit()" class="w3-rest">
              <mat-form-field appearance="outline">
                <mat-label> Seleccione la cantidad </mat-label>
                <mat-select formControlName="inputNumColor" required>
                  <mat-option value="tres" (click)="elegirSemEdit('tres')">3 colores</mat-option>
                  <mat-option value="cuatro" (click)="elegirSemEdit('cuatro')">4 colores</mat-option>
                  <mat-option value="cinco" (click)="elegirSemEdit('cinco')">5 colores</mat-option>
                </mat-select>
                <mat-error>
                  Campo <strong> obligatorio.
                  </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngFor="let sem of newsemaforo; let i = index;">
            <div class="w3-row" style="margin-left: 3vw;">
              <div class="w3-quarter">
                <div class="w3-row" style="width: 10.2vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 9.6vw;">
                    <mat-label> Valor 1 </mat-label>
                    <input type="number" value="{{sem.valor_00}}" id='{{"valor1"+i}}' matInput
                    formControlName="{{'inputVal1Sem'+i}}">
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-quarter">
                <div class="w3-row" style="width: 10vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 9.7vw;">
                    <mat-label> Ingrese el valor 2 </mat-label>
                    <input formControlName="{{i === (newsemaforo.length-1)? 'inputVal2Sem4':'inputVal2Sem'+i}}" value="{{sem.valor_01}}"
                    type="number" (input)="intNumero(i)" id='{{"valor2"+i}}' matInput>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-row" style="width: 12vw; margin-top: 2vh;">
                  <mat-form-field appearance="outline" style="width: 11vw;">
                    <mat-label>Seleccione un color</mat-label>
                    <mat-select formControlName="{{'inputColor'+i}}" required>
                      <mat-option (click)="setcolor(i, 'rojo')" value="rojo"><span class="w3-text-red">Rojo</span></mat-option>
                      <mat-option (click)="setcolor(i, 'naranja')" value="naranja"><span class="w3-text-orange">Naranja</span></mat-option>
                      <mat-option (click)="setcolor(i, 'amarillo')" value="amarillo"><span class="w3-text-yellow">Amarillo</span></mat-option>
                      <mat-option (click)="setcolor(i, 'verde')" value="verde"><span class="w3-text-green">Verde</span></mat-option>
                      <mat-option (click)="setcolor(i, 'azul')" value="azul"><span class="w3-text-blue">Azul</span></mat-option>
                    </mat-select>
                    <mat-error>
                      Campo <strong> obligatorio.
                      </strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
  
          <div *ngIf="modal3">
            <div class="w3-right">
              <div class="botonesModales" ngClass="{{puede_guardarEditSem()}}">
                <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                  Guardar
                </button>
              </div>
              <div class="botonesModales" [ngClass]="haysem()">
                <button mat-stroked-button color="success2" (click)="cerrarForm()" style="height: 4vh;">
                  Cancelar
                </button>
              </div>
            </div>
          </div>

        </form>

      </div>
    </div>    
  </div>
</div>