import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Matriz_FLOA, Perspectivas, Planificacion, Instituciones, procesamiento, obtenerError } from './../../../model/datos';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2' ;
import { MatrizfloaService } from '../../../services/formulacion/matrizfloa/matrizfloa.service';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
@Component({
  selector: 'app-matrizfloa',
  templateUrl: './matrizfloa.component.html',
  styleUrls: ['./matrizfloa.component.css']
})
export class MatrizfloaComponent implements OnInit {
  public instituciones: Instituciones[] = [];
  public planeacion: Planificacion[] =[];
  public perspectiva: Perspectivas[] = [];
  public elemento = [];
  public internos = [
    {id:1, tipoe:'FORTALEZA', elementoi:'Fortalezas'},
    {id:2, tipoe:'DEBILIDAD', elementoi:'Debilidades'},
  ];
  public externos = [
    {id:3, tipoe:'OPORTUNIDAD', elementoe:'Oportunidades'},
    {id:4, tipoe: 'AMENAZA', elementoe:'Amenazas'},
  ];

  public idelem: number = 0;
  public tipoelem: any; //para el tipo
  public elementt: any; //para el edit
  public modal = false;
  public modal2 = false;
  public PerSelect: any;
  public ncodigo: number = 0;
  public idPlanificacion: any;
  public diccionarioFLOA1: any = [];
  public diccionarioFLOA2: any = [];

  /** Grupo de validación de datos */
  formGeneral = new FormGroup({
    inputName: new FormControl('', [Validators.required]),
    inputNameEdit: new FormControl('', [Validators.required]),
    inputPerspective: new FormControl('', [Validators.required]),
    inputPerspectiveEdit: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });

  constructor(
    public dataService: DataService,
    private matrizService: MatrizfloaService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) { 
    this.obtData();
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param['planeacion']);
  }

  ngOnInit(): void { }

  private ordenarPerspe(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /** Obtención de datos */
  public obtData(){
    this.matrizService.getPlanificaciones().subscribe(response =>{
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive
          });
        }
      }
      this.matrizService.getPerspectivas().subscribe(response =>{
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              nombre: per.name,
              descripcion: per.descripcion,
              planningId: per.planning.id,
              id: per.id,
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        
        this.internos.forEach(per => { this.diccionarioFLOA1.push({internosss: per, elementooo : [], perspectivaaa:this.perspectiva}); });
        this.externos.forEach(per1 => { this.diccionarioFLOA2.push({externosss: per1, elementooo : [], perspectivaaa:this.perspectiva}); });

        this.elemento = [];
        this.matrizService.getFLOA().subscribe( response => {
          for(const per of this.diccionarioFLOA1){
            per.elementooo = [];
            for(const pers of per.perspectivaaa){
              for(const matrizf of response){
                if(matrizf.perspectiveId === pers.id){
                  if(matrizf.type === per.internosss.tipoe && !matrizf.name.includes('&&')){
                    per.elementooo.push({
                      id: matrizf.id,
                      tipo: matrizf.type,
                      nombre: matrizf.name,
                      perspectiveId: matrizf.perspectiveId
                    });
                    this.elemento.push(matrizf);
                    per.elementooo = this.ordenarPerspe(per.elementooo);
                  }
                }
              }
            }
          }
          for(const per of this.diccionarioFLOA2){
            per.elementooo = [];
            for(const pers of per.perspectivaaa){
              for(const matrizf of response){
                if(matrizf.perspectiveId === pers.id){
                  if(matrizf.type === per.externosss.tipoe && !matrizf.name.includes('&&')){
                    per.elementooo.push({
                      id: matrizf.id,
                      tipo: matrizf.type,
                      nombre: matrizf.name,
                      perspectiveId: matrizf.perspectiveId
                    });
                    this.elemento.push(matrizf);
                    per.elementooo = this.ordenarPerspe(per.elementooo);
                  }
                }
              }
            }
          }
          this.elemento = this.ordenarPerspe(this.elemento);
        });
      })
    })
  }
  /** Metodo de deción de guardado de datos */
  public alertas(aler: any,ele?:any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarDatosEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'eliminar'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar este elemento?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarele(ele);
        }
      });
    }
  }
  /** Almacenamiento de los datos */
  public almacenarD(){
    var element = new Matriz_FLOA();
    element.tipo = this.tipoelem;
    element.nombre = document.getElementById('nombre')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspective'].value);
    if(element.nombre != '' && element.perspectiveId != undefined){
      this.matrizService.postFLOA(element).subscribe(response =>{
        this.matrizService.getFLOA().subscribe(response => {
          for(const per of this.diccionarioFLOA1){
            per.elementooo = [];
            for(const pers of per.perspectivaaa){
              for(const matrizf of response){
                if(matrizf.perspectiveId === pers.id){
                  if(matrizf.type === per.internosss.tipoe){
                    per.elementooo.push({
                      id: matrizf.id,
                      tipo: matrizf.type,
                      nombre: matrizf.name,
                      perspectiveId: matrizf.perspectiveId
                    });
                    this.elemento = response;
                    per.elementooo = this.ordenarPerspe(per.elementooo);
                  }
                }
              }
            }
          }
          for(const per of this.diccionarioFLOA2){
            per.elementooo = [];
            for(const pers of per.perspectivaaa){
              for(const matrizf of response){
                if(matrizf.perspectiveId === pers.id){
                  if(matrizf.type === per.externosss.tipoe){
                    per.elementooo.push({
                      id: matrizf.id,
                      tipo: matrizf.type,
                      nombre: matrizf.name,
                      perspectiveId: matrizf.perspectiveId
                    });
                    this.elemento = response;
                    per.elementooo = this.ordenarPerspe(per.elementooo);
                  }
                }
              }
            }
          }
          this.elemento = this.ordenarPerspe(this.elemento);
          Swal.fire({
            title: 'Elemento agregado',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
      this.cerrarForm();
    }else{
      Swal.fire({
        title: 'Ingrese toda la información',
        text: '',
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
    }
  }
  public almacenarDatosEdit(){
    this.elementt.nombre = document.getElementById('nombreedit')['value'];
    this.elementt.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveEdit'].value);
    this.matrizService.patchFLOA(this.elementt).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(response => {
        for(const per of this.diccionarioFLOA1){
          per.elementooo = [];
          for(const pers of per.perspectivaaa){
            for(const matrizf of response){
              if(matrizf.perspectiveId === pers.id){
                if(matrizf.type === per.internosss.tipoe){
                  per.elementooo.push({
                    id: matrizf.id,
                    tipo: matrizf.type,
                    nombre: matrizf.name,
                    perspectiveId: matrizf.perspectiveId
                  });
                  this.elemento = response;
                  per.elementooo = this.ordenarPerspe(per.elementooo);
                }
              }
            }
          }
        }
        for(const per of this.diccionarioFLOA2){
          per.elementooo = [];
          for(const pers of per.perspectivaaa){
            for(const matrizf of response){
              if(matrizf.perspectiveId === pers.id){
                if(matrizf.type === per.externosss.tipoe){
                  per.elementooo.push({
                    id: matrizf.id,
                    tipo: matrizf.type,
                    nombre: matrizf.name,
                    perspectiveId: matrizf.perspectiveId
                  });
                  this.elemento = response;
                  per.elementooo = this.ordenarPerspe(per.elementooo);
                }
              }
            }
          }
        }
        this.elemento = this.ordenarPerspe(this.elemento);
        Swal.fire({
          title: 'Elemento actualizado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
      this.cerrarForm();
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    })
  }
  /** Desición de guardado */
  public puede_guardar(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombre')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspective'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    var element = new Matriz_FLOA();
    element.tipo = undefined;
    element.nombre = document.getElementById('nombreedit')['value'];
    element.perspectiveId = Number(this.formGeneral.controls['inputPerspectiveEdit'].value);
    if(element.nombre != '' && element.perspectiveId != 0 && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Obtención de datos para la tabla */
  public obtPers(ele: any){
    for(const obj of this.perspectiva){
      if(Number(ele.perspectiveId) === Number(obj.id)){
        return obj.nombre;
      }
    }
    return '';
  }
  public mostrarElem(eleme: any, ele: any){
    for(const obj of this.perspectiva){
      if(Number(obj.id) === Number(ele.perspectiveId) && ele.tipo === eleme){
        return true;
      }
    }
    return false;
  }
    // Obtención de los codigos
  public obtCodigo(inter:any){
    switch(inter.id){
      case 1: return 'F';
      case 2: return 'D';
      case 3: return 'O';
      case 4: return 'A';
    }
    return 'no';
  }
  /** Modales */
    // Abrir
  public abrirFormAgregar(elem: any, elemento1:any){
    this.idelem = elem.id;
    this.tipoelem = elem.tipoe;
    document.getElementById('formularionuevo')!.style.display = 'block';
    this.modal = true;
  }
  public abrirFormEdit(ele: any, eleme: any){
    this.modal2 = true;
    this.elementt = ele;
    this.idelem = eleme.id;
    this.formGeneral.controls['inputNameEdit'].setValue(this.elementt.nombre);
    this.formGeneral.controls['inputPerspectiveEdit'].setValue(this.elementt.perspectiveId);
    document.getElementById('formularioedit')!.style.display = 'block'
  }
    // Cerrar
  public cerrarForm(){
    this.modal = false;
    this.modal2 = false;
    document.getElementById('formularionuevo')!.style.display = 'none'
    document.getElementById('formularioedit')!.style.display = 'none'
    
    this.formGeneral.reset();
  }
  /** Eliminación de los datos */
  public eliminarele(ele: any){
    this.matrizService.deleteFLOA(ele).subscribe(response =>{
      this.matrizService.getFLOA().subscribe(response => {
        for(const per of this.diccionarioFLOA1){
          per.elementooo = [];
          for(const pers of per.perspectivaaa){
            for(const matrizf of response){
              if(matrizf.perspectiveId === pers.id){
                if(matrizf.type === per.internosss.tipoe){
                  per.elementooo.push({
                    id: matrizf.id,
                    tipo: matrizf.type,
                    nombre: matrizf.name,
                    perspectiveId: matrizf.perspectiveId
                  });
                  this.elemento = response;
                  per.elementooo = this.ordenarPerspe(per.elementooo);
                }
              }
            }
          }
        }
        for(const per of this.diccionarioFLOA2){
          per.elementooo = [];
          for(const pers of per.perspectivaaa){
            for(const matrizf of response){
              if(matrizf.perspectiveId === pers.id){
                if(matrizf.type === per.externosss.tipoe){
                  per.elementooo.push({
                    id: matrizf.id,
                    tipo: matrizf.type,
                    nombre: matrizf.name,
                    perspectiveId: matrizf.perspectiveId
                  });
                  this.elemento = response;
                  per.elementooo = this.ordenarPerspe(per.elementooo);
                }
              }
            }
          }
        }
        this.elemento = this.ordenarPerspe(this.elemento);
        Swal.fire({
          title: 'Elemento eliminado',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  /** Exportación */
  public exportar_excel(){
    let subtituloElementosInternos = "Elementos internos";
    let headersInternos = [];
    let elementosInternos = [];
    for(let i = 0; i < this.diccionarioFLOA1.length; i++){
      let header = [];
      headersInternos.push('#');
      headersInternos.push(this.diccionarioFLOA1[i].internosss.elementoi);
      headersInternos.push('');
      // headersInternos.push(header);
      let elemento = [];
        for(let j = 0; j < this.diccionarioFLOA1[i].elementooo.length; j++){
          let col1 = this.obtCodigo(this.diccionarioFLOA1[i].internosss).concat(''+(j+1));
          elemento.push([col1, this.diccionarioFLOA1[i].elementooo[j].nombre]);
          
        }
      
      elementosInternos.push(elemento);
    }

    let subtituloElementosExternos = "Elementos externos";
    let headersExternos = [];
    let elementosExternos: any = [];
    for(let i = 0; i < this.diccionarioFLOA1.length; i++){
      let header = [];
      headersExternos.push('#');
      headersExternos.push(this.diccionarioFLOA2[i].externosss.elementoe);
      headersExternos.push('');
      let elemento = [];
      for(let j = 0; j < this.diccionarioFLOA2[i].elementooo.length; j++){
        let col1 = this.obtCodigo(this.diccionarioFLOA2[i].externosss).concat(''+(j+1));
        elemento.push([col1, this.diccionarioFLOA2[i].elementooo[j].nombre]);
      }
      elementosExternos.push(elemento);
    }

    this.excelService.generateExcelMatrizFoda('Formulación: Matriz FODA', headersInternos,  headersExternos, elementosInternos,  elementosExternos, 'matriz_foda', subtituloElementosInternos, subtituloElementosExternos);
  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nombre')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nombreedit')["value"];
      }
      const existe = this.elemento.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() && element.id !== this.elementt.id );
        }
        return ( procesamiento.procesamiento_Texto(element.name).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formGeneral.hasError('isValid');
  }
}