<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ dataService.currentModule }} </b></span>
        <span class="w3-text-deep-orange">&bull;</span>
        <span class="w3-text-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
      </div>
    </div>

    <div class="w3-rest">
      <div class="w3-noventa2" style="width: 100%;">
        <div class="w3-quarter">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuanios" aria-label="Example icon-button with a menu">
              Años
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuanios="matMenu">
              <ng-container *ngFor="let anio of aniosDisponibles">
                <button mat-menu-item>
                  <mat-checkbox [checked]="anio.check" name="{{anio.name}}"
                  (change)="cambioAnio(anio)"
                  (click)="$event.stopPropagation();"
                  value="{{anio.name}}" aria-label="First checkbox"
                  >
                    {{ anio.name }}
                  </mat-checkbox>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-half hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="w3-row chips">
                <div [ngClass]="anios.check?
                'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu' : 'w3-hide'"
                            *ngFor="let anios of aniosDisponibles"
                            style="margin-left: 5px;" class="filtroMesAnioSeleccionado" >
                            <span class="contenidoChips">{{anios.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- lideres -->
        <div class="w3-third">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menulider" aria-label="Example icon-button with a menu">
              Líder
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menulider="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="selecAllResActas"
                (change)="selecAllLideres()"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox"> <!--  -->
                  Seleccionar todos
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selecAllResActas">
                <ng-container *ngFor="let anio of responsablesActas">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                    (change)="cambioLider(anio)"
                    (click)="$event.stopPropagation();"
                    value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                      {{ anio.nombre }}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>

          <div class="w3-rest hijoizquierda titulochipsr">
            <div class="w3-row padreizquierda containerChips">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of responsablesActas; let i = index">
                    <div [ngClass]="anio.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioLider(anio)">
                      <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                        {{anio.nombre}}
                      </span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                    </div>
                    <br *ngIf="anio.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="tresPuntosVis()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menuanios2">more_horiz</mat-icon>
              </div>
              <mat-menu #menuanios2="matMenu" xPosition="before">
                <div *ngFor="let anio of responsablesActas"
                    [ngClass]="anio.check?
                         'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado w3-border" (click)="cambioLider(anio); $event.stopPropagation();">
                    <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- responsables -->
        <div class="w3-third">
          <div class="w3-quarter hijoizquierda">
            <button class="w3-button w3-right" [matMenuTriggerFor]="menuliderres" aria-label="Example icon-button with a menu">
              Responsable
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuliderres="matMenu">
              <button mat-menu-item>
                <mat-checkbox [checked]="selecAllResActiv"
                (change)="selecAllResponsables()"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox"> <!--  -->
                  Seleccionar todos
                </mat-checkbox>
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!selecAllResActiv">
                <ng-container *ngFor="let anio of responsablesActividadesActas">
                  <button mat-menu-item>
                    <mat-checkbox [checked]="anio.check" name="{{anio.nombre}}"
                    (change)="cambioResponsable(anio)"
                    (click)="$event.stopPropagation();"
                    value="{{anio.nombre}}" aria-label="First checkbox"> <!--  -->
                      {{ anio.nombre}}
                    </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
          <div class="w3-rest hijoizquierda titulochipsr">
            <div class="w3-row padreizquierda containerChips">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of responsablesActividadesActas; let i = index">
                    <div [ngClass]="anio.check?
                      'w3-round-btn-large w3-gris-btn w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                      class="filtroMesAnioSeleccionado textoOverflow nombres" style="max-width: 11vw;" (click)="cambioResponsable(anio)">
                      <span style="margin-left: 5px;" matTooltip="{{anio.nombre}}">
                        {{anio.nombre}}
                      </span> 
                      <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                    </div>
                    <br *ngIf="anio.check">
                  </ng-container>
                </div>
              </div>
              <div *ngIf="tresPuntosVisResp()" class="tresPuntos">
                <mat-icon [ngStyle]="{'color':'gray'}" class="iconoClos w3-button" [matMenuTriggerFor]="menures">more_horiz</mat-icon>
              </div>
              <mat-menu #menures="matMenu" xPosition="before">
                <div *ngFor="let anio of responsablesActividadesActas"
                    [ngClass]="anio.check?
                         'w3-round-btn-large w3-gris-btn2 w3-show w3-left w3-center w3-elemento-menu w3-button' : 'w3-hide'"
                    class="filtroMesAnioSeleccionado w3-border" (click)="cambioResponsable(anio); $event.stopPropagation();">
                    <span style="margin-left: 5px;">{{anio.nombre}}</span> 
                    <span><mat-icon [ngStyle]="{'color':'gray'}" class="w3-tiny">close</mat-icon></span>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <!-- BORRAR FILTROS -->
        <div class="w3-rest w3-right">
          <div class="w3-row">
            <button mat-icon-button (click)="borrarFiltro()"> <!--(click)="quitarFiltros()"-->
              <mat-icon class="w3-margin-right-contenido" title="limpiar filtros">
                <img src="assets/img/graficos/eraser.png">
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div class="w3-row w3-white" style="height: 85vh;">
      <div class="w3-row w3-subtitulo botonDeNuevo">
        <div class="menuexp">
          <div class="w3-right" style="margin-top: 0.3vh;">
            <mat-icon class=" w3-zindex w3-dropdown-click" [ngStyle]="{'color':'gray'}"
              [matMenuTriggerFor]="menu2">more_vert</mat-icon>
          </div>
          <mat-menu #menu2="matMenu">
            
            <button (click)="exportarExcel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
              <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
              <span>Exportar xlsx</span>
            </button>
          </mat-menu>
        </div>
        <div class="botones">
          <button *appRoles="['Rol_Administrador','Rol_Carga']" (click)="abrirGuardarActas()" class="w3-right colorBotonDeNuevo" >
            <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
            Agregar plan
          </button>
        </div>
      </div>
      <div class="w3-row" style="height: 79vh; width: 98%; margin-left: 1%;">
        <div *ngIf="!mostabla">
          <section>
            <table class="w3-table" style="width: 99%;">
              <tr>
                <ng-container *ngFor="let m of skeletonColums">
                  <td>
                    <div class="info"></div>
                    <ng-container *ngFor="let n of skeleton">
                      <div class="info" style="margin-left: 3vw !important;"></div>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </table>
          </section>
        </div>
        <div *ngIf="mostabla" style="overflow: scroll;">
          <table class="w3-table borderIzq borderInf">
            <thead>
              <tr>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun">
                  <tr>
                    <th>
                      <mat-icon *ngIf="mostrarAllActas" (click)="mostrarTodasLasActas()" class="iconoMen w3-button" matTooltip="Ocultar todo" matTooltipPosition="left" >expand_less</mat-icon>
                      <mat-icon *ngIf="!mostrarAllActas" (click)="mostrarTodasLasActas()"  class="iconoMen w3-button" matTooltip="Mostrar todo" matTooltipPosition="left" >expand_more</mat-icon>
                    </th>
                  </tr>
                </th>
                <th (click)="ordenamientoTabla()" class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas enbezadosPro contenedorSort" style="width: 15vw; cursor: pointer;">
                  Planes
                  <mat-icon *ngIf="ordenamiento === 'id'" class="iconoSortIni">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'asc'" class="iconoSort">file_upload</mat-icon>
                  <mat-icon *ngIf="ordenamiento === 'des'" class="iconoSort">download</mat-icon>
                </th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas">Lider / Responsable</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen" >Inicio</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen" >Días</th>
                <th class="w3-elemento-titulo-table borderSup enbezados tresPun anchosColumnas alinCen borderDer" >Fin</th>
                <ng-container *ngFor="let mes of meses">
                  <ng-container>
                    <th class="w3-elemento-titulo-table borderSup enbezados borderDer alinCen" style="min-width: 3.2vw !important; padding: 0vh;"> {{mes.name}} </th>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="actasMostrar.length != 0">
                  <ng-container *ngFor="let ad of adicionales; let i = index">
                    <th class="w3-elemento-titulo-table borderSup tresPun enbezados borderDer alinCen anchosColumnas adicionAdicional textoOverflow" 
                    [matMenuTriggerFor]="manuEdicionAd"style="padding: 0vw 0.5vw;">
                      {{ad.name}}
                    </th>
                    <mat-menu class="w3-medium" #manuEdicionAd="matMenu">
                      <button (click)="editarAdicional(ad)" class="mat-menu-item" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button (click)="eliminarAdicional(ad)" class="mat-menu-item" mat-menu-item>
                        <mat-icon [ngStyle]="{'color':'gray'}">delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </ng-container>
                <th *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup enbezados" style="border-right: 1px solid #B9B9BC;"> 
                  <button matTooltip="Agregar adicional" class="colorBotonDeNuevo" (click)="agregarAdicional()">
                    <mat-icon class="iconoMen">add_circle_outline</mat-icon>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let actas of actasMostrar">
                <tr>
                  <td class="w3-table-trtdBalance borderSup">
                    <tr>
                      <td class="tresPun">
                        <mat-icon *ngIf="actas.check" class="iconoMen w3-button" (click)="mostrarActas(actas)" matTooltip="Ocultar" matTooltipPosition="left">expand_less</mat-icon>
                        <mat-icon *ngIf="!actas.check"class="iconoMen w3-button" (click)="mostrarActas(actas)" matTooltip="Mostrar" matTooltipPosition="left">expand_more</mat-icon>
                        <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" (click)="abrirGuardarActasEdit(actas)" class="w3-button iconoMen">edit</mat-icon>
                        <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" (click)="eliminarActa(actas)" class="iconoMen w3-button">delete</mat-icon>
                      </td>
                    </tr>
                    <tr>
                      <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="actas.check">
                        <br>
                      </td>
                    </tr>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                      <tr>
                        <td class="tresPun" style="margin-left: 2vh;">
                          <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" (click)="abrirGuardarActividadesEdit(act)" class="iconoMen w3-button">edit</mat-icon>
                          <mat-icon *appRoles="['Rol_Administrador','Rol_Carga']" (click)="eliminarActividad(act)" class="iconoMen w3-button">delete</mat-icon>
                        </td>
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      </tr>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="w3-table-trtdBalance borderSup mantenerFija">
                    <tr>
                      <td class="tresPun" style="font-weight: bold;">
                        <span matTooltip="{{actas.nombre}}">{{actas.nombre}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="actas.check">
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                        <button *appRoles="['Rol_Administrador','Rol_Carga']" (click)="abrirGuardarActividades(actas)" class="w3-button">
                          <mat-icon class="iconoMen w3-button">add_circle_outline</mat-icon> 
                          Agregar proyecto
                        </button>
                      </td>
                    </tr>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                        <tr>
                          <td class="tresPun anchosColumnas textoOverflow" style="margin-left: 2vh;"  matTooltip="{{act.nombre}}">
                            <span>{{act.nombre}}</span>
                          </td>
                          <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="w3-table-trtdBalance borderSup">
                    <tr>
                      <td class="tresPun">
                        {{actas.responsable.position}}
                      </td>
                    </tr>
                    <tr>
                      <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="actas.check">
                        <br>
                      </td>
                    </tr>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                      <tr>
                        <td class="tresPun" style="margin-left: 2vh;">
                          {{act.responsable.position}}
                        </td>
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      </tr>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="w3-table-trtdBalance borderSup">
                    <table class="w3-table">
                      <td class="tresPun textoOverflow">
                        {{obtFecha(actas.fecha_ini)}}
                      </td>
                    </table>
                    <table class="w3-table">
                      <td class="w3-subtitulo" *ngIf="actas.check">
                        <br>
                      </td>
                    </table>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                      <table class="w3-table">
                        <td class="tresPun textoOverflow alinCen">
                          {{obtFecha(act.fecha_inicio)}}
                        </td>
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      </table>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="w3-table-trtdBalance borderSup">
                    <table class="w3-table">
                      <td class="tresPun textoOverflow alinCen">
                        {{obtDias(actas.fecha_ini,actas.fecha_fin)}}
                      </td>
                    </table>
                    <table class="w3-table">
                      <td class="w3-subtitulo" *ngIf="actas.check">
                        <br>
                      </td>
                    </table>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                      <table class="w3-table">
                        <td class="tresPun textoOverflow alinCen">
                          {{obtDias(act.fecha_inicio,act.fecha_fin)}}
                        </td>
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      </table>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="w3-table-trtdBalance borderSup borderDer">
                    <table class="w3-table">
                      <td class="tresPun textoOverflow alinCen">
                        {{obtFecha(actas.fecha_fin)}}
                      </td>
                    </table>
                    <table class="w3-table">
                      <td class="w3-subtitulo" *ngIf="actas.check">
                        <br>
                      </td>
                    </table>
                    <ng-container *ngFor="let act of actas.actividades;">
                      <ng-container *ngIf="actas.check">
                      <table class="w3-table">
                        <td class="tresPun textoOverflow alinCen">
                          {{obtFecha(act.fecha_fin)}}
                        </td>
                        <div *appRolesNeg="['Rol_Administrador','Rol_Carga']"> <br> </div>
                      </table>
                      </ng-container>
                    </ng-container>
                  </td>
                  <ng-container *ngFor="let mes of meses; let me = index">
                    <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC; padding: 0vh !important;">
                      <table class="w3-table">
                        <td style="min-width: 3.2vw !important; padding: 0vh;">
                          <ng-container *ngFor="let dias of mes.value">
                            <div [ngClass]="pintarGantPro(actas, dias.name,me)"> <br> </div>
                          </ng-container>
                        </td>
                      </table>
                      <tr>
                        <td class="w3-subtitulo" style="margin-left: 2vh;" *ngIf="actas.check">
                          <br>
                        </td>
                      </tr>
                      <ng-container *ngFor="let act of actas.actividades;">
                        <ng-container *ngIf="actas.check">
                          <table class="w3-table">
                            <td style="min-width: 3.2vw !important; padding: 0vh !important;">
                              <ng-container *ngFor="let dias of mes.value">
                                <div [ngClass]="pintarGantAct(act, dias.name,me)"> <br> </div>
                              </ng-container>
                            </td>
                          </table>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="actas.actividades.length !== 0">
                    <ng-container *ngFor="let ad of actas.actividades[0].adicionales; let j = index;">
                      <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC;">
                        <tr>
                          <td class="textoOverflow tresPun" style="min-width: 5vw; max-width: 20vw;">
                            <div *ngIf="edicionPro(actas,j)" class="w3-row tresPun textoOverflow alinDer" style=" min-width: 3vw; max-width: 10vw;">
                              {{imprimirPro(actas,j)}} 
                            </div>
                            <div *ngIf="!edicionPro(actas,j)" class="w3-row tresPun textoOverflow" style="min-width: 3vw; max-width: 10vw;">
                              <br>
                            </div> 
                          </td>
                        </tr>
                        <tr>
                          <td class="w3-subtitulo" *ngIf="actas.check">
                            <br>
                          </td>
                        </tr>
                        <ng-container *ngFor="let act of actas.actividades; let k = index;">
                          <ng-container *ngIf="actas.check">
                            <tr>
                              <td class="textoOverflow tresPun" style=" min-width: 5vw; max-width: 20vw;">
                                <ng-container  *appRoles="['Rol_Administrador','Rol_Carga']">
                                  <div (dblclick)="edicionAdicional(act,j)" *ngIf="act.adicionales[j].type === 'moneda'?true:false"
                                  class="alinDer" style="cursor: pointer;">
                                    {{act.adicionales[j].type==='moneda'?this.formatearnormal(act.adicionales[j].amount):''}}
                                  </div>
                                  <div (dblclick)="edicionAdicionalN(act,j)" *ngIf="act.adicionales[j].type === 'numero'?true:false"
                                  class="alinIzq" style="cursor: pointer;">
                                    {{obtComment(act.adicionales[j].comments)}} <br>
                                  </div>
                                </ng-container>
                                <ng-container  *appRolesNeg="['Rol_Administrador','Rol_Carga']">
                                  <div *ngIf="act.adicionales[j].type === 'moneda'?true:false" class="alinDer"> 
                                    {{act.adicionales[j].type==='moneda'?this.formatearnormal(act.adicionales[j].amount):''}}
                                  </div>
                                  <div *ngIf="act.adicionales[j].type === 'numero'?true:false" class="alinIzq">
                                    {{act.adicionales[j].note}}
                                  </div>
                                </ng-container>
                                <div *ngIf="act.adicionales[j].type !== 'moneda'?true:false"></div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="actas.actividades.length === 0">
                    <ng-container *ngFor="let ad of adicionales; let j = index;">
                      <td class="w3-table-trtdBalance w3-center borderSup" style="border-right: 1px solid #B9B9BC;">
                        <tr>
                          <td class="w3-subtitulo" *ngIf="actas.check">
                            <br>
                          </td>
                        </tr>
                        <tr>
                          <td class="w3-subtitulo" *ngIf="actas.check">
                            <br>
                          </td>
                        </tr>
                        <tr>
                          <td class="w3-subtitulo" *ngIf="actas.check">
                            <br>
                          </td>
                        </tr>
                      </td>
                    </ng-container>
                  </ng-container>
                  <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-elemento-titulo-table borderSup" style="border-right: 1px solid #B9B9BC;">  </td>
                </tr><!---->
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modales de las actas-->
<div id="agregarActa" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Agregar plan </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow-y: scroll;">
          <div style="width: 99%;">
            <form [formGroup]="formActas" (ngSubmit)="guardarActa()">

              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-error *ngIf="formActas.hasError('isValid')" class="error-message">
                    Nombre <strong> existente.</strong>
                  </mat-error>
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Ingrese el nombre del plan </mat-label>
                    <input type="text" id="nomActa" matInput formControlName="inputNameActa" required>
                    <mat-error>
                      <span>Campo <strong> obligatorio.</strong></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Ingrese una descripción </mat-label>
                    <textarea type="text" #input maxlength="1000" id="desPro" formControlName="inputDescripcion" matInput></textarea>
                    <mat-hint align="end">{{input.value.length || 0}}/1000</mat-hint>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="w3-row" style="width: 90%;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
                </div>
                <div class="w3-rest">
                  <mat-form-field appearance="outline" style="width: 25vw;">
                    <mat-label> Seleccione un responsable </mat-label>
                    <mat-select required formControlName="inputLider">
                      <ng-container *ngFor="let res of responsables">
                        <mat-option *ngIf="res.isValidator" value="{{res.id}}" matTooltip="{{res.nombre}}"> {{res.nombre}} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>
                      <span>Campo <strong> obligatorio.</strong></span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="w3-row" style="width: 90%; display: none;">
                <div class="w3-quarter" style="margin-top: 2vh;">
                  <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
                </div>
                <div class="w3-rest">
                  <!--<label for="archivo" style="max-width: 15vw; min-width: 15vw; margin-right: 1vh;"> {{this.formActas.controls['inputArchivo'].value}} </label>
                  <label for="archivo" class="fileInputLabel">Elegir archivo ...</label>-->
                  <input type="file" id="archivoActa" style="margin-top: 1.5vh; cursor: pointer;" formControlName="inputArchivo">
                </div>
              </div>
  
              <div *ngIf="modalAgregarActa" style="width: 99%;">
                <div class="w3-right">
                  <div class="botonesModales" [ngClass]="puedeGuardarActas()">
                    <button mat-flat-button color="success" style="height: 4vh;">
                      Guardar
                    </button>
                  </div>
                  <div class="botonesModales">
                    <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="editarActa" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Editar plan </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow-y: scroll;">
          <form [formGroup]="formActasEdit" (ngSubmit)="guardarActaEdit()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formActasEdit.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del plan </mat-label>
                  <input type="text" id="nomActaEdit" matInput formControlName="inputNameActa" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Descripción: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese una descripción </mat-label>
                  <textarea type="text" #input maxlength="1000" id="desActaEdit" formControlName="inputDescripcion" matInput></textarea>
                  <mat-hint align="end">{{input.value.length || 0}}/1000</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select required formControlName="inputLider">
                    <ng-container *ngFor="let res of responsables">
                      <mat-option *ngIf="res.isValidator" value="{{res.id}}" matTooltip="{{res.nombre}}"> {{res.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%; display: none;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
              </div>
              <div class="w3-rest">
                <div *ngIf="editarArch" class="w3-row" style="height: 5vh;">
                  <input type="file" id="archivoActaEdit" style="margin-top: 1.9vh; cursor: pointer;" formControlName="inputArchivo">
                  <mat-icon [ngStyle]="{'color':'gray'}" (click)="editarArchivoActa()" class="w3-button iconoMen">cancel</mat-icon>
                </div>
                <div *ngIf="!editarArch" class="w3-row" style="height: 5vh;">
                  <input class="bordesPrueba" style="margin-top: 1.9vh; border: solid 1px white !important; min-width: 10vw !important; max-width: 15vw !important;" value="{{nombreArchivo}}" disabled="true">
                    <mat-icon [ngStyle]="{'color':'gray'}" (click)="editarArchivoActa()" class="w3-button iconoMen">edit</mat-icon>
                </div>
              </div>
            </div>

            <div *ngIf="modalEditarActa" style="width: 99%;">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardarActasEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                      Guardar
                    </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</div>
<!--Modales de actividades-->
<div id="agregarActividad" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Agregar actividades </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">
          <form [formGroup]="formActividad" (ngSubmit)="guardarActividades()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del proyecto </mat-label>
                  <input type="text" id="actividad" matInput formControlName="inputNameAct" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker"
                  formControlName="inputFechaIn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="formActividad.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker2"
                  formControlName="inputFechaFn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker2 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select formControlName="inputLider" required>
                    <ng-container *ngFor="let obj of responsables">
                      <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modalAgregarActividad">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardarActividades()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="editarActividad" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Editar proyecto </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">
          <form [formGroup]="formActividadEdit" (ngSubmit)="guardarActividadesEdit()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Proyecto: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del proyecto </mat-label>
                  <input type="text" id="actividad" matInput formControlName="inputNameAct" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha inicio: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker3"
                  formControlName="inputFechaIn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker3">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker3 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Fecha fin: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label>Selecciona una fecha</mat-label>
                  <input [min]="formActividadEdit.controls['inputFechaIn'].value || minDate" [max]="maxDate" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker4"
                  formControlName="inputFechaFn" [disabled]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker4">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker color="warn" startView="year" #picker4 disabled="false"></mat-datepicker>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select formControlName="inputLider" required>
                    <ng-container *ngFor="let obj of responsables">
                      <mat-option value="{{obj.id}}" matTooltip="{{obj.nombre}}"> {{obj.nombre}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modalEditarActividad">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardarActividadesEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modales de adicionales-->
<div id="agregarAdicional" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Columna adicional </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon class="w3-button w3-display-topright" (click)="close_modal()" style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh;">
          <form [formGroup]="formAdicional" (ngSubmit)="guardarAdi()">

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Adicional: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formAdicional.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese el nombre del adicional </mat-label>
                  <input type="text" id="adicionalNombre" matInput formControlName="inputName" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="w3-row" style="width: 90%;">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Tipo: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un tipo </mat-label>
                  <mat-select formControlName="inputType" required>
                    <mat-option value="texto" matTooltip="Agrega un campo para texto que se rellena mes a mes"> Texto </mat-option>
                    <mat-option value="moneda" matTooltip="Agrega un campo para cantidades"> Moneda </mat-option>
                    <mat-option value="archivo" matTooltip="Agrega un campo para adjuntar archivos"> Archivo </mat-option>
                    <mat-option value="numero" matTooltip="Agrega un campo para texto"> Texto fijo </mat-option>
                  </mat-select>
                  <mat-error>
                    Campo <strong> obligatorio.
                    </strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="modalAgregarAdicional">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puedeGuardarAdi()">
                  <button mat-flat-button color="success" style="height: 4vh;">
                    Guardar
                  </button>  
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="close_modal()"  style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>