<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row ">
    <div class="w3-caja-menu padreizquierda ">
      <div class="w3-row hijoizquierda">
        <div class="w3-noventa">
          <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"><b> {{ dataService.currentModule }} </b></span> 
          <span class="w3-text-deep-orange">&bull;</span>
          <span class=" w3-margin-left-filter w3-text-orange w3-subtituloPrincipal"> {{ dataService.currentSubmodule }} </span>
        </div>
        <div class="w3-rest">
          <div class="w3-right">
            <mat-icon class="w3-zindex w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" style="margin-right: 2vw;"
            [matMenuTriggerFor]="menu2">more_vert</mat-icon>  
          </div>
            <mat-menu #menu2="matMenu">
              <ng-container *ngIf="planeacion">
              </ng-container>
              <button (click)="exportar_excel()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span>Exportar xlsx</span>
              </button>
              <button (click)="ponderacionAutomatica()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                <span>Ponderación automatica</span>
              </button>
            </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template">
    <div id="objetivos_formulacion" class="w3-row">
      <div *ngFor="let per of Oobjetivos; let i = index">
        <div class="w3-half_e" style="height: 39vh;">
          <div class="w3-row w3-margin-left-caja" style="height: 36vh;">
            <div class="w3-row w3-white">
              <div class="w3-noventa titulo-contenidomenu">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido w3-tituloZonas"> {{per.perspectivaaa.nombre}} </span>
                </div>
              </div>
              <div class="w3-rest w3-right-align_rest" *ngIf="i === 1">
                        
              </div>
            </div>
            <div class="w3-row w3-white">
              <div class="w3-row w3-scroll" style="height: 28.8vh; margin-left: 1vw; margin-right: 1vw;">
                <table class="w3-table w3-border">
                  <thead>
                    <tr>
                      <th class="elemento-titulo-table borderInf tresPun encabezado alinIzq" style="width:3vw;"> # </th>
                      <th class="elemento-titulo-table borderInf tresPun encabezado"> Nombre </th>
                      <th class="elemento-titulo-table borderInf tresPun encabezado alinCen" style="width: 6vw;"> Peso </th>
                      <th *appRoles="['Rol_Administrador','Rol_Carga']" class="elemento-titulo-table borderInf alinCen tresPun encabezado" style="width:4vw;">
                        <button matTooltip="Agregar objetivo" class="colorBotonDeNuevo" (click)="open_modal(per.perspectivaaa)">
                          <mat-icon class="iconoMen">add_circle_outline</mat-icon> 
                          <!--Agregar {{ 'AlineamientoForm.add' | translate }}-->
                        </button>
                        <!--<mat-icon class="iconoMen" matTooltip="Agregar elemento" (click)="open_modal(per.perspectivaaa)">add_circle</mat-icon>-->
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let ele of per.objetivosss; let i = index">
                      <tr>
                        <td class="w3-table-trtdBalance tresPun alinIzq">O{{i+1}}</td>
                        <td class="w3-table-trtdBalance alinIzq tresPun textoOverflow" style="max-width: 25vw; overflow: hidden;">
                          <span matTooltip="{{ele.nombre}}">{{ele.nombre}}</span>
                        </td>
                        <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance alinCen tresPun" (dblclick)="edicionPond(ele)" 
                          style="cursor: pointer;">
                          {{ele.ponderacion}}
                        </td>
                        <td *appRolesNeg="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance alinCen tresPun"> {{ele.ponderacion}} </td>
                        <td *appRoles="['Rol_Administrador','Rol_Carga']" class="w3-table-trtdBalance w3-center tresPun"> 
                          <mat-icon class="iconoMen w3-button" (click)="open_edit(ele,per.perspectivaaa)" matTooltip="Editar objetivo">edit</mat-icon> 
                          <mat-icon class="iconoMen w3-button" matTooltip="Eliminar objetivo" (click)="alertas('eliminar',ele)">delete</mat-icon> 
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="formularionuevo" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Agregar objetivo </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <form [formGroup]="formNuevo" (ngSubmit)="alertas('guardar')">
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formNuevo.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese un objetivo </mat-label>
                  <input placeholder="Ingrese un nombre corto" type="text" id="nombre" matInput formControlName="inputName" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select id="responsable" formControlName="inputPerspective" required>
                    <ng-container *ngFor="let res of responsable">
                      <mat-option value="{{res.id}}"> {{res.cargo}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Ponderación/peso: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese la ponderación/peso </mat-label>
                  <input type="number" id="ponderacion" matInput>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf="modal">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puede_guardar()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="formularioedit" class="w3-modal">
  <div class="w3-modal-content" style="width: 50%;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-twothird">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda " style="height: 6vh; margin-top: 10px; margin-left: 20px;">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-margin-left-contenido"> Editar objetivo </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-third">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright " style="margin-top: 18px;margin-right: 24px;">clear</mat-icon>
      </div>
      <div class="w3-row w3-white" style="height: 60vh;">
        <div class="w3-container" style="height: 49vh; overflow: scroll;">

          <form [formGroup]="formNuevo" (ngSubmit)="alertas('editar')">
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Nombre: </span></div>
              </div>
              <div class="w3-rest">
                <mat-error *ngIf="formNuevo.hasError('isValid')" class="error-message">
                  Nombre <strong> existente.</strong>
                </mat-error>
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese un objetivo </mat-label>
                  <input placeholder="Ingrese un nombre corto" type="text" id="nombreedit" matInput formControlName="inputNameEdit" required>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Responsable: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Seleccione un responsable </mat-label>
                  <mat-select id="responsable" formControlName="inputPerspectiveEdit" required>
                    <ng-container *ngFor="let res of responsable">
                      <mat-option value="{{res.id}}"> {{res.cargo}} </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>
                    <span>Campo <strong> obligatorio.</strong></span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="w3-row">
              <div class="w3-quarter" style="margin-top: 2vh;">
                <div class="w3-right" style="margin-right: 1vh;"><span class="w3-subtitulo2"> Ponderación/peso: </span></div>
              </div>
              <div class="w3-rest">
                <mat-form-field appearance="outline" style="width: 25vw;">
                  <mat-label> Ingrese la ponderación/peso </mat-label>
                  <input type="number" id="ponderacionedit" matInput>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf="modal2">
              <div class="w3-right">
                <div class="botonesModales" [ngClass]="puede_guardarEdit()">
                  <button mat-flat-button color="success" type="submit" style="height: 4vh;">
                    Guardar
                  </button>
                </div>
                <div class="botonesModales">
                  <button mat-stroked-button color="success2" (click)="closeModal()" style="height: 4vh;">Cancelar</button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>