import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { MessageService, MessageDoneComponent, MessageWaitComponent, MessageErrorComponent } from './services/base/message.service';
import { HttpClientModule } from '@angular/common/http';
import {LOCALE_ID} from '@angular/core';

/* Importaciones  Angular Material */
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialogModule} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatDividerModule} from '@angular/material/divider';
import { MatInputModule} from '@angular/material/input';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule} from '@angular/material/radio';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule} from '@angular/material/button';
import { MatSelectModule} from '@angular/material/select';
import { MatChipsModule} from '@angular/material/chips';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';

import { SharedModule } from './modules/shared/shared.module';
import { LoginModule } from './modules/login/login.module';

/* Importaciones Modulos */
import { MenuComponent } from './components/menu/menu.component';
import { InicioComponent } from './inicio/inicio.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
//import { HeaderComponent } from './components/header/header.component';

import { FormulacionModule } from './modules/formulacion/formulacion.module';
import { SeguimientoModule } from './modules/seguimiento/seguimiento.module';
import { ReportesModule } from './modules/reportes/reportes.module';
import { AdministradorModule } from './modules/administrador/administrador.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory( http: HttpClient ){
  return new TranslateHttpLoader(http)
}
/* Importaciones Modulos */
@NgModule({
  declarations: [
    AppComponent,
    MessageDoneComponent,
    MessageWaitComponent,
    MessageErrorComponent,
    WelcomeComponent,
    MenuComponent,
    InicioComponent,
    //HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LoginModule,
    MatSnackBarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatDividerModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    FormulacionModule,
    SeguimientoModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    ReportesModule,
    AdministradorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageDoneComponent,
    MessageWaitComponent,
    MessageErrorComponent,
  ]
})
export class AppModule { }
