import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuadromandoService {

  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor(private http: HttpClient) { }

  public getPlanificaciones(): Observable<any>{
    const apiURL = this.apiURL+"planificacion";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getPerspectivas(): Observable<any>{
    const apiURL = this.apiURL+"perspectivas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getObjetivos(): Observable<any>{
    const apiURL = this.apiURL+"objetivos";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getUnides(): Observable<any>{
    const apiURL = this.apiURL+"medidas";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getIndicadores(obj: any): Observable<any>{
    const apiURL = this.apiURL+"objetivos/"+obj.id+'?indicators=true';
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getMetas(): Observable<any>{
    const apiURL = this.apiURL+"meta";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }

}
