<div class="w3-container w3-contenedor-template" style="height: 7vh;">
  <div class="w3-row">
    <!-- titulo del modulo -->
    <div class="w3-quarter padreizquierda">
      <div class="w3-row hijoizquierda">
        <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloPrincipal"> <b> {{ 'menu.'+dataService.currentModule | translate }} </b></span>
        <span class="w3-text-deep-orange"> &bull; </span>
        <span class="w3-text-deep-orange w3-margin-left-filter w3-subtituloPrincipal"> {{ 'menu.'+dataService.currentSubmodule | translate }} </span>
      </div>
    </div>
    <!-- filtros -->
    <div class="w3-rest">
      <div class="w3-rest w3-row">
        <!-- Filtro por periodicidad -->
        <div class="w3-quarter padreizquierda" style="margin-right: 1vh;">

          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuAnios"> <!--  -->
              {{ 'filtros.periodo' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuAnios="matMenu">
              <ng-container *ngFor="let an of aniosDisponibles">
                <button class="mat-menu-item" mat-menu-item [matMenuTriggerFor]="childMenu">
                  <span>{{an.anio}}</span>
                </button>
                <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
                  <span *ngFor="let child of an.series">
                    <span *ngIf="!child.children || child.children.length === 0">
                      <button mat-menu-item (click)="$event.stopPropagation(); anioChange(child.periodo);">
                        <mat-checkbox [id]="'check_'+child.anio+child.mes" style="cursor: pointer; margin-top: 7px;"
                        (change)="anioChange(child.periodo);"
                        [checked]="child.check" type="checkbox"
                        (click)="$event.stopPropagation();"
                        aria-label="First checkbox">
                          {{ 'filtros.'+child.mes | translate}}
                        </mat-checkbox>
                      </button>
                    </span>
                  </span>
                </mat-menu>
              </ng-container>
            </mat-menu>
          </div>
          
          <div class="w3-rest hijoizquierda titulochips">
            <div class="w3-row padreizquierda containerChips" id="contenedor_btnanios">
              <div class="chips nombres" id="Cchip">
                <div class="auto_ajuste">
                  <ng-container *ngFor="let anio of aniosDisponibles; let i = index">
                    <ng-container *ngFor="let mes of anio.series">
                      <div [ngClass]="mes.check?
                        'w3-round-btn-large grisBtn w3-center w3-elemento-menu' : 'w3-hide'"
                        class="filtroMesAnioSeleccionado" >
                        <span style="margin-left: 5px; margin-right: 5px;">{{ 'filtros.'+mes.mes | translate }}-{{mes.anio}}</span> 
                      </div>
                      <br *ngIf="mes.check">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Perspectivas -->
        <div class="w3-quarter_e padreizquierda" style="margin-right: 1vh;">
          <div class="w3-half hijoizquierda">
            <button class="w3-button w3-right"  [matMenuTriggerFor]="menuPer">
              {{ 'filtros.perspectiva' | translate }}
              <mat-icon class="iconoMen">expand_more</mat-icon>
            </button>
            <mat-menu class="w3-medium" #menuPer="matMenu">
              <button mat-menu-item (click)="$event.stopPropagation(); selecAllPer();">
                <mat-checkbox (change)="selecAllPer();"
                [checked]="todasPer" type="checkbox"
                (click)="$event.stopPropagation();"
                aria-label="First checkbox">
                  {{ 'filtros.selAll' | translate }}
                </mat-checkbox>     
              </button>
              <mat-divider></mat-divider>
              <ng-container *ngIf="!todasPer">
                <ng-container *ngFor="let per of diccionarioPer">
                  <button mat-menu-item (click)="$event.stopPropagation(); persChange(per.perspective);">
                      <mat-checkbox (change)="persChange(per.perspective)"
                      [checked]="per.perspective.check" type="checkbox"
                      (click)="$event.stopPropagation();"
                      aria-label="First checkbox">
                        {{per.perspective.name}}
                      </mat-checkbox>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</div>

<div class="contenedorPag">
  <div class="w3-container w3-contenedor-template" id="tarjetasTod">
    <div class="w3-row contenerdorObj mantenerFija" id="principal">
      <div class="titulo">
        <div class="w3-noventa2">
          <div class="filosofiaCMI">
            <div class="w3-row w3-titulo-contenidomenuSC padreizquierda">
              <div class="w3-noventa2 hijoizquierdaTitle">
                <span *ngIf="!acum" class="w3-margin-left-contenido textoZon">{{ 'seguimiento.cmi.avance' | translate }} | {{escrituraPeriodo(perSelected())}}</span>
                <span *ngIf="acum" class="w3-margin-left-contenido textoZon">{{ 'seguimiento.cmi.avanceGen' | translate }}</span>
              </div>
              <div class="w3-rest w3-right">
                <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"
                [matMenuTriggerFor]="tiposAvance">more_vert</mat-icon>
                <mat-menu #tiposAvance="matMenu">
                  <button *ngIf="!acum" class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="changeAvance()">
                    {{ 'seguimiento.cmi.avanceGen' | translate }}
                  </button>
                  <button *ngIf="acum" class="mat-menu-item" mat-menu-item class="w3-caja-menu" (click)="changeAvance()">
                    {{ 'seguimiento.cmi.avance' | translate }} | {{escrituraPeriodo(perSelected())}}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="contCifPrin"><span class="cifraPrin">{{avancePlan}} % </span></div>
          </div>
          <div class="filosofiaCMI" *ngIf="mision !== undefined">
            <div class="w3-row tituloFilosofiaCMI">{{mision.name}}:</div>
            <div class="w3-row contenidoFilosofiaCMI" matTooltipPosition="right" matTooltip="{{mision.description}}">
              <span>{{mision.description}}</span>
            </div>
          </div>
          <div class="filosofiaCMI" *ngIf="vision !== undefined">
            <div class="w3-row tituloFilosofiaCMI">{{vision.name}}:</div>
            <div class="w3-row contenidoFilosofiaCMI" matTooltipPosition="right" matTooltip="{{vision.description}}">
              <span>{{vision.description}}</span>
            </div>
          </div>
        </div>
        <div class="w3-rest">
          <div class="w3-right">
            <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}" [matMenuTriggerFor]="menutabla">more_vert</mat-icon>
            <mat-menu #menutabla="matMenu">
                <button *ngIf="tarjOrTab" (click)="exportarImge('principal', 'prin')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                  <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
                  <span>{{ 'seguimiento.cmi.expor' | translate }}</span>
                </button>
                <button *ngIf="tarjOrTab" (click)="exportarImge('tarjetasTod', 'tot')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                  <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
                  <span>{{ 'seguimiento.cmi.exporAll' | translate }}</span>
                </button>
                <button *ngIf="!tarjOrTab" (click)="exportarExcelTab()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                  <mat-icon [ngStyle]="{'color':'gray'}">description</mat-icon>
                  <span>{{ 'seguimiento.cmi.expor' | translate }}</span>
                </button>
                <button (click)="cambioTarjTab()" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                  <mat-icon *ngIf="tarjOrTab" [ngStyle]="{'color':'gray'}">table_chart</mat-icon>
                  <mat-icon *ngIf="!tarjOrTab" [ngStyle]="{'color':'gray'}">crop_landscape</mat-icon>
                  <span *ngIf="tarjOrTab">{{ 'seguimiento.cmi.table' | translate }}</span>
                  <span *ngIf="!tarjOrTab">{{ 'seguimiento.cmi.tarje' | translate }}</span>
                </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="tarjOrTab">
      <ng-container *ngFor="let per of diccionarioPer">
        <ng-container *ngIf="mostrarPers(per)">
          <div class="w3-row contenerdorObj" id="{{'graf'+per.perspective.name}}">
            <div class="titulo">
              <div class="w3-noventa2 w3-titulo-contenidomenuSC padreizquierda">
                <div class="hijoizquierdaTitle">
                  <span class="w3-margin-left-contenido w3-tituloZonas">{{per.perspective.name}}</span>
                </div>
              </div>
              <div class="w3-rest">
                <div class="w3-right">
                  <mat-icon class="w3-margin-right-contenido w3-button w3-dropdown-click" [ngStyle]="{'color':'gray'}"[matMenuTriggerFor]="menutabla">
                    more_vert
                  </mat-icon>
                  <mat-menu #menutabla="matMenu">
                      <button (click)="exportarImge(per.perspective.name, 'per')" class="mat-menu-item" mat-menu-item class="w3-caja-menu">
                        <mat-icon [ngStyle]="{'color':'gray'}">image</mat-icon>
                        <span>{{ 'seguimiento.cmi.expor' | translate }}</span>
                      </button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div class="w3-row">
              <ng-container *ngFor="let obj of per.objetivos">
                <div [ngClass]="pintarBorde(obj.avance)" class="tarjetas">
                  <div class="textoTarjeta">
                    <div class="textoObj" matTooltipPosition="right" matTooltipShowDelay="1000" matTooltip="{{obj.name}}">
                      <span>
                        {{obj.name}}
                      </span>
                    </div>
                    <div class="iconoInform">
                      <mat-icon (click)="modalInd(obj)" class="iconoTarjCMI">info_outline</mat-icon>
                    </div>
                  </div>
                  <div class="textoTarjeta2">
                    <div class="textoObj" *ngIf="obj.avance !== undefined">
                      <span [ngClass]="texto(obj.avance)" class="cifrasTarjeta">
                        {{obj.avance}}%
                      </span>
                    </div>
                    <div class="textoObj" *ngIf="obj.avance === undefined">
                      <span [ngClass]="texto(obj.avance)" class="cifrasTarjeta">
                      </span>
                    </div>
                    <div class="iconoInform" *ngIf="obj.avance === undefined">
                      <mat-icon (click)="modalInfo(0)" class="iconoTarjCMI">info_outline</mat-icon>
                    </div>
                  </div> 
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!tarjOrTab">
      <div class="w3-row contenedorTable2">
        <div class="tablaCont">
          <table class="w3-table">
            <thead>
              <tr class="allBordes">
                <th class="w3-elemento-titulo-table enbezados textosNorm">{{ 'seguimiento.cmi.persp' | translate }}</th>
                <th class="w3-elemento-titulo-table enbezados textosNorm">
                  <table class="w3-table">
                    <th class="" style="width: 1vw !important;">
                      <mat-icon *ngIf="!expandirObj" (click)="oculMostAll()" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                      <mat-icon *ngIf="expandirObj" (click)="oculMostAll()" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                    </th>
                    <th class="textoOverflow">
                      {{ 'seguimiento.cmi.objec' | translate }} / <span style="font-weight: lighter;">{{ 'seguimiento.cmi.indic' | translate }}</span>
                    </th>
                  </table>
                </th>
                <th class="w3-elemento-titulo-table enbezados ponderaciones">{{ 'seguimiento.cmi.avanc' | translate }}</th>
              </tr>
            </thead>
            <tbody class="allBordes">
              <ng-container *ngFor="let per of diccionarioPer">
                <ng-container *ngFor="let obj of per.objetivos; let i = index">
                    <tr>
                      <td *ngIf="i === 0" class="w3-table-trtdBalance borderSup">{{per.perspective.name}}</td>
                      <td *ngIf="i !== 0" class="w3-table-trtdBalance"></td>
                      <td [ngClass]="pintarBordes(i)" class="w3-table-trtdBalance borderIzq borderSup borderInf">
                        <table class="w3-table">
                          <td class="" style="width: 1vw !important;">
                            <mat-icon *ngIf="!obj.check" (click)="oculMost(obj)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_more</mat-icon>
                            <mat-icon *ngIf="obj.check" (click)="oculMost(obj)" class="w3-margin-right-contenido w3-button" [ngStyle]="{'color':'gray'}">expand_less</mat-icon>
                          </td>
                          <td class="textoOverflow">
                            <strong>{{obj.name}}</strong>
                          </td>
                        </table>
                      </td>
                      <td [ngClass]="pintarBorde(obj.avance)" *ngIf="obj.avance !== undefined" class="w3-table-trtdBalance alinCen borderInf borderSup">
                        <strong>{{obj.avance}} %</strong>
                      </td>
                      <td [ngClass]="pintarBorde(obj.avance)" *ngIf="obj.avance === undefined" class="w3-table-trtdBalance borderSup borderInf"></td>
                    </tr>
                    <ng-container *ngFor="let ind of obj.indicadores">
                      <tr *ngIf="obj.check">
                        <td class="w3-table-trtdBalance"></td>
                        <td class="w3-table-trtdBalance borderIzq">
                          <table class="w3-table">
                            <td class="" style="width: 1vw !important;">
                              <span *ngIf="verCodigo">Código</span>
                              <mat-icon *ngIf="!verCodigo" class="w3-margin-right-contenido" [ngStyle]="{'color':'white'}">expand_less</mat-icon>
                            </td>
                            <td class="textoOverflow">
                              {{ind.nombre}}
                            </td>
                          </table>
                        </td>
                        <td [ngClass]="pintarBorde(ind.avance)" *ngIf="ind.avance !== undefined" class="w3-table-trtdBalance alinCen">{{ind.avance}} %</td>
                        <td [ngClass]="pintarBorde(ind.avance)" *ngIf="ind.avance === undefined" class="w3-table-trtdBalance"></td>
                      </tr>
                    </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div id="info" class="w3-modal">
  <div class="w3-modal-content" style="margin-top: 15%; width: 20vw;">
    <div class="w3-row w3-header-cuadrante">
      <div class="w3-noventa2">
        <div class="w3-row w3-white w3-header-cuadrante padreizquierda modalHeader">
          <div class="w3-noventa2 w3-titulo-contenidomenu bottom hijoizquierdaTitle " >
            <div class="hijoizquierdaTitle">
                <div class="w3-row">
                  <!--Titulo-->
                  <div class="w3-noventa w3-titulo-contenidomenu padreizquierda">
                    <div class="hijoizquierdaTitle">
                      <span class="w3-text-deep-orange w3-margin-right-filter w3-tituloCuadro"> {{ 'seguimiento.cmi.info' | translate }} </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>        
      <div class="w3-rest">
        <mat-icon (click)="closeModal()" class="w3-button w3-display-topright botonclose">clear</mat-icon>
      </div>
    </div>
    <div class="w3-row w3-white" style="height: 10vh;">
      <div class="textoInfo">
        {{textoMostrar}}
      </div>
    </div>
  </div>
</div>
