import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './../../services/data-service/data.service';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class PermisosSubmodulosGuard implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.dataService.initializeMenu();
    /**
     * Jaja que pedo el siguiente fragmento no tiene pinche sentido xd
     * lo voy a dejar solo para que el siguiente programador vea que NO HACER
     * (no se quien haya hecho esto)
     * if (route.url.length > 0){
     * if (this.dataService.submodules.filter(result => result.route == route.url[0].path).length > 0) {
     *  return true;
     * } else {
     * return true;
     * }
     * } else {
     * return true;
     * }
     */
    return true;
  }
}
