import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormulacionRoutingModule } from './formulacion-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AltaplanificacionesComponent } from './altaplanificaciones/altaplanificaciones.component';
import { PerspectivasComponent } from './perspectivas/perspectivas.component';
import { FilosofiacorporativaComponent } from './filosofiacorporativa/filosofiacorporativa.component';
import { MatrizfloaComponent } from './matrizfloa/matrizfloa.component';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { AlineamientoComponent } from './alineamiento/alineamiento.component';
import { ProyectosestrategicosComponent } from './proyectosestrategicos/proyectosestrategicos.component';
import { CuadromandoComponent } from './cuadromando/cuadromando.component';
import { AltaactasComponent } from './altaactas/altaactas.component';
import { MatrizFodaNComponent } from './matriz-foda-n/matriz-foda-n.component';
import { Proyectos2Component } from './proyectos2/proyectos2.component'

@NgModule({
  declarations: [
    //AltaplanificacionesComponent,
    PerspectivasComponent,
    FilosofiacorporativaComponent,
    MatrizfloaComponent,
    ObjetivosComponent,
    IndicadoresComponent,
    AlineamientoComponent,
    ProyectosestrategicosComponent,
    CuadromandoComponent,
    AltaactasComponent,
    MatrizFodaNComponent,
    Proyectos2Component,
  ],
  imports: [
    CommonModule,
    FormulacionRoutingModule,
    SharedModule
  ]
})
export class FormulacionModule { }
