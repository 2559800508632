import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service'
import { ConstantesQubos } from 'src/app/util/app-util';

const PATH = ConstantesQubos.strPATH;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  //myForm: FormGroup;
  myForm = new FormGroup({'inputEmail': new FormControl(''), 'inputPassword': new FormControl('')})
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.myForm = new FormGroup({
      inputEmail: this.emailFormControl,
      inputPassword: this.passwordFormControl
    });
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate([PATH]);
    }
  }

  onSubmit() {
    this.authenticationService.login(this.myForm.get('inputEmail')?.value.toLowerCase(), this.myForm.get('inputPassword')?.value).subscribe(
      response => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 800,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
          willClose: (toast) =>{
            this.router.navigate([PATH]);
            location.reload();
          }
        });
        
        Toast.fire({
          icon: 'success',
          title: 'Signed in successfully'
        });

        
      },
      errorObject => {
        Swal.fire({
          title: 'Error',
          text: 'Credenciales incorrectas',
          icon: 'error',
          showConfirmButton: false,
          timer: 800,
        });
      });
  }

  public passVis = false;

  public revelar_password(){
    this.passVis = !this.passVis;
    var tipo = document.getElementById("pass");
    //@ts-ignore
    if(tipo.type == "password"){
      //@ts-ignore
      tipo.type = "text";
    }else{
      //@ts-ignore
      tipo.type = "password";
    }
  }

}
