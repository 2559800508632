import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Planificacion, Perspectivas, Objetivos, Responsables, procesamiento, obtenerError } from './../../../model/datos';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjetivosService } from '../../../services/formulacion/objetivos/objetivos.service';
import { Perspectiva } from '../../../model/datos';
import { ExcelService } from 'src/app/services/Exportar/excel.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
@Component({
  selector: 'app-objetivos',
  templateUrl: './objetivos.component.html',
  styleUrls: ['./objetivos.component.css']
})
export class ObjetivosComponent implements OnInit {
  //public instituciones: Instituciones[] = [];
  public planeacion: Planificacion[] = [];
  public perspectiva: Perspectivas[] = [];
  public objetivos: Objetivos[] = [];
  public responsable: Responsables[] = [];
  public Oobjetivos: any = []; //diccionarioObjetivos

  public idPers: number = 0; //para la perspectiva
  public idObj: number = 0; //para el objetivo
  public indic: any;
  public pondera: number = 0;
  public ponderaciontotal: number = 0;
  public objetivoo: any;
  public idPlanificacion: any;

  //public ObjSelect: any;
  public responsableSelect: any;
  public modal = false;
  public modal2 = false;

  /** Grupo de validación de datos */
  formNuevo = new FormGroup({ 
    inputName: new FormControl('', [Validators.required]),
    inputNameEdit: new FormControl('', [Validators.required]),
    inputPerspective: new FormControl('', [Validators.required]),
    inputPerspectiveEdit: new FormControl('', [Validators.required]),
  },{ validators: this.nombreDuplicidad() });
  
  constructor(
    private router: Router,
    public dataService: DataService,
    private objetivosService: ObjetivosService,
    private rutaActiva: ActivatedRoute,
    private excelService: ExcelService,
  ) {
    this.obtData();
    var param = this.rutaActiva.snapshot.params
    this.idPlanificacion = Number(param["planeacion"]);
  }
  
  ngOnInit(): void { }
  
  private ordenarPerspe(array: any){
    array.sort(function(a: any, b: any){
      if(Number(a.id) > Number(b.id)){
        return 1;
      }
      if(Number(a.id) < Number(b.id)){
        return -1;
      }
      return 0;
    });
    return array;
  }
  /** Obtención de datos */
  public obtData(){
    this.objetivosService.getPlanificaciones().subscribe(response => {
      for(const plan of response){
        if(this.idPlanificacion === Number(plan.id)){
          this.planeacion.push({
            id: plan.id,
            nombre: plan.name,
            fecha_inicio: new Date(plan.initial_date),
            fecha_fin: new Date(plan.end_date),
            institucionId: plan.institution.id,
            isActive: plan.isActive
          });
          this.objetivosService.getResponsable().subscribe(response => {
            this.responsable = [];
            for(const are of response){
              if(are.institution.id === plan.institution.id){
                for(const resp of are.responsibles){
                  this.responsable.push({
                    id: resp.id,
                    nombre: resp.position,
                    cargo: resp.position,
                  });
                }
              }
            }
            this.responsable = this.ordenarPerspe(this.responsable);
          });
        }
      }
      this.objetivosService.getPerspectivas().subscribe(response => {
        this.perspectiva = [];
        for (const per of response){
          if(Number(per.planning.id) === this.idPlanificacion){
            this.perspectiva.push({
              nombre: per.name,
              descripcion: per.descripcion,
              planningId: per.planning.id,
              id: per.id,
            });
          }
        }
        this.perspectiva = this.ordenarPerspe(this.perspectiva);
        this.perspectiva.forEach(per => { this.Oobjetivos.push({perspectivaaa: per, objetivosss : []}); });
        this.objetivosService.getObjetivos().subscribe(response => {
          this.obtObjetivos(response);
        });
      });
    });
  }
  private obtObjetivos(response){
    for(const per of this.Oobjetivos){
      per.objetivosss = [];
      for(const obj of response){
        if(obj.perspective.id === per.perspectivaaa.id){
          per.objetivosss.push({
            nombre: obj.name,
            descripcion: obj.description, 
            ponderacion: obj.weighing, 
            perspectiveId: obj.perspective.id, 
            responsableId: obj.responsibles,
            id: obj.id
          });
          this.objetivos.push({
            nombre: obj.name,
            descripcion: obj.description, 
            ponderacion: obj.weighing, 
            perspectiveId: obj.perspective.id, 
            responsableId: obj.responsibles,
            id: obj.id
          });
          per.objetivosss = this.ordenarPerspe(per.objetivosss);
        }
      }
    }
    this.objetivos = this.ordenarPerspe(this.objetivos);
  }
  public perPers(id: any){
    for(const per of this.perspectiva){
      if(per.id === id)
        return true;
    }
    return false;
  }
  /** Metodo de decición */
  public alertas(aler: any, ele?:any){
    if(aler === 'editar'){
      Swal.fire({
        title: '¿Seguro que deseas guardar los cambios?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.guardarEdit();
        }
      });
    }else if(aler === 'guardar'){
      Swal.fire({
        title: '¿La información es correcta?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.almacenarD();
        }
      });
    }else if(aler === 'eliminar'){
      Swal.fire({
        title: '¿Seguro que deseas eliminar este objetivo?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarele(ele);
        }
      });
    }
  }
  /** Guardado de datos */
  public almacenarD(){
    var objetive = new Objetivos();
    objetive.nombre = document.getElementById('nombre')["value"];
    objetive.ponderacion = document.getElementById('ponderacion')["value"];
    objetive.perspectiveId = Number(this.idPers);
    objetive.responsableId = [Number(this.formNuevo.controls['inputPerspective'].value)];
    if(objetive.nombre != '' && objetive.responsableId != undefined && objetive.ponderacion != undefined){
      this.objetivosService.postObjetivos(objetive).subscribe(response => {
        this.objetivosService.getObjetivos().subscribe(response => {
          this.obtObjetivos(response);
          Swal.fire({
            title: 'Información agregada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
      this.closeModal();
    }else{
      Swal.fire({
        title: 'Ingrese toda la información',
        text: '',
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
    }
  }
  public guardarEdit(){
    this.objetivoo.nombre = document.getElementById('nombreedit')["value"];
    this.objetivoo.responsableId = [Number(this.formNuevo.controls['inputPerspectiveEdit'].value)];
    this.objetivoo.ponderacion = document.getElementById('ponderacionedit')["value"];
    
    this.objetivosService.patchObjetivos(this.objetivoo).subscribe(response =>{
      this.objetivosService.getObjetivos().subscribe(response => {
        this.obtObjetivos(response);
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
    this.closeModal();
  }
  /** Metodo de comporbación de guardado */
  public puede_guardar(){
    var objetive = new Objetivos();
    objetive.nombre = document.getElementById('nombre')["value"];
    if(objetive.nombre !== '' && this.formNuevo.controls['inputPerspective'].value !== '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  public puede_guardarEdit(){
    var objetive = new Objetivos();
    objetive.nombre = document.getElementById('nombreedit')["value"];
    if(objetive.nombre !== '' && this.formNuevo.controls['inputPerspectiveEdit'].value !== '' && !this.nombreExiste()){
      return ''
    }
    return 'w3-disabled'
  }
  /** Obtener datos para la tabla */
  public obtResp(respon: any){
    for(const res of this.responsable){
      if(respon.responsableId === res.id){
        return res.cargo;
      }
    }
    return '';
  }
  /** Modal */
    // Abrir
  public open_modal(per: any){
    this.idPers = per.id;
    document.getElementById('formularionuevo')!.style.display = 'block';
    this.modal = true;
  }
  public open_edit(ind: any, per: any){
    this.objetivoo = ind;
    this.idPers = per.id;
    this.modal2 = true;
    this.formNuevo.controls['inputNameEdit'].setValue(this.objetivoo.nombre);
    for(const res of this.objetivoo.responsableId){
      this.formNuevo.controls['inputPerspectiveEdit'].setValue(res.id);
    }
    document.getElementById('ponderacionedit')["value"] = this.objetivoo.ponderacion;
    document.getElementById('formularioedit')!.style.display = 'block'
  }
    // Cerrar
  public closeModal(){
    this.modal = false;
    this.modal2 = false;
    document.getElementById('formularionuevo')!.style.display = 'none';
    document.getElementById('formularioedit')!.style.display = 'none';

    document.getElementById('nombre')["value"] = '';
    document.getElementById('ponderacion')["value"] = '';

    this.formNuevo.reset();
  }
  /** Eliminación de datos */
  public eliminarele(ele: any){
    this.objetivosService.deleteObjetivos(ele).subscribe(response =>{
      this.objetivosService.getObjetivos().subscribe(response => {
        for(const per of this.Oobjetivos){
          per.objetivosss = [];
          for(const obj of response){
            if(obj.perspective.id === per.perspectivaaa.id){
              per.objetivosss.push({
                nombre: obj.name,
                descripcion: obj.description, 
                ponderacion: obj.weighing, 
                perspectiveId: obj.perspective.id, 
                responsableId: obj.responsibles,
                id: obj.id
              });
              this.objetivos = response;
              per.objetivosss = this.ordenarPerspe(per.objetivosss);
            }
          }
        }
        this.objetivos = this.ordenarPerspe(this.objetivos);
        Swal.fire({
          title: 'Información eliminada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      });
    },err =>{
      let er = obtenerError.errorObt(err['error'].message);
      Swal.fire({
        title: er,
        text: '',
        icon: 'error',
        showConfirmButton: false,
        timer: this.dataService.tiempo,
      });
    });
  }
  
  /** Ediciones desde tabla */
  public async edicionPond(Obj: any){
    var inputValue = Obj.ponderacion, introdujo: any, guardo = false;
    var nombre = 'obj.name';

    introdujo = await Swal.fire({
      title: 'Ingrese la ponderación',
      text: "",
      input: 'number',
      inputValue: inputValue,
      confirmButtonColor: '#ff6b00',
      cancelButtonColor: '#B9B9BC',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Ingerese un dato valido';
        }
        guardo = true;
        return '';
      }
    });

    if(guardo){
      Obj.ponderacion = Number(introdujo.value);
      this.objetivosService.patchObjetivosPon(Obj).subscribe(response =>{
        this.objetivosService.getObjetivos().subscribe(response => {
          this.obtObjetivos(response);
          Swal.fire({
            title: 'Información actualizada',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: this.dataService.tiempo
          });
        });
      },err =>{
        let er = obtenerError.errorObt(err['error'].message);
        Swal.fire({
          title: er,
          text: '',
          icon: 'error',
          showConfirmButton: false,
          timer: this.dataService.tiempo,
        });
      });
    }
  }
  /* ponderación automatica equitativa */
  public ponderacionAutomatica(){
    var ponder = 0;
    for(const per of this.Oobjetivos){
      for(const obj of per.objetivosss){
        if(obj.ponderacion !== 0 || obj.ponderacion !== null){
          ponder++;
        }
      }
    }
    if(ponder!==0){
      Swal.fire({
        title: 'Uno o mas objetivos ya tienen ponderación. ¿Desea ponderar nuevamente?',
        text: "",
        showCancelButton: true,
        confirmButtonColor: '#ff6b00',
        cancelButtonColor: '#B9B9BC',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ponderarEquitativamente();
        }
      });
    }else{
      this.ponderarEquitativamente();
    }
  }
  private ponderarEquitativamente(){
    var ponderPerspectiva = Number((100/this.Oobjetivos.length).toFixed(1));
    var i=0,j=0;
    for(const per of this.Oobjetivos){
      if(100 > Number(ponderPerspectiva)*this.Oobjetivos.length && i === this.Oobjetivos.length-1){
        const decPer = Number((ponderPerspectiva - Number(ponderPerspectiva)*this.Oobjetivos.length).toFixed(1));
        ponderPerspectiva += decPer;
      }else if(100 < Number(ponderPerspectiva)*this.Oobjetivos.length && i === this.Oobjetivos.length-1){
        const decnPer = Number((Number(ponderPerspectiva)*this.Oobjetivos.length - 100).toFixed(1));
        ponderPerspectiva = Number((ponderPerspectiva - decnPer).toFixed(1));
      }

      var ponderObjetivo = Number((ponderPerspectiva/per.objetivosss.length).toFixed(1));
      for(const obj of per.objetivosss){
        j++;
        obj.ponderacion = Number(ponderObjetivo);
        if(ponderPerspectiva > Number(ponderObjetivo)*per.objetivosss.length && j === per.objetivosss.length){
          const dec = Number((ponderPerspectiva - Number(ponderObjetivo)*per.objetivosss.length).toFixed(1));
          obj.ponderacion += dec;
        }else if(ponderPerspectiva < Number(ponderObjetivo)*per.objetivosss.length && j === per.objetivosss.length){
          const decn = Number((Number(ponderObjetivo)*per.objetivosss.length - ponderPerspectiva).toFixed(1));
          obj.ponderacion = Number((obj.ponderacion - decn).toFixed(1));
        }
        this.objetivosService.patchObjetivosPon(obj).subscribe(response =>{
        },err =>{
          let er = obtenerError.errorObt(err['error'].message);
          Swal.fire({
            title: er,
            text: '',
            icon: 'error',
            showConfirmButton: false,
            timer: this.dataService.tiempo,
          });
        });
      }
      j=0;
      i++;
      if(i===this.Oobjetivos.length){
        Swal.fire({
          title: 'Información actualizada',
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: this.dataService.tiempo
        });
      }
    }
  }
  /** Exportación */
  public exportar_excel(){
    let subtitulosPerspectivas: any = [];
    let contenidoTabla: any = [];
    let headers : any= [];
    this.Oobjetivos.forEach((element: any) => {
      let contenidoObjetivos: any  = [];
       subtitulosPerspectivas.push(element.perspectivaaa.nombre);
       headers.push(['#','Nombre', 'Peso']);
      element.objetivosss.forEach((obj: any, index: number) => {
        contenidoObjetivos.push(['O-'.concat((index+1).toString()), obj.nombre, obj.ponderacion])
      });
      contenidoTabla.push(contenidoObjetivos);
    });    
    this.excelService.generateExcelObjetivosFormulacion('Formulación: Objetivos',subtitulosPerspectivas, headers, contenidoTabla, 'formulación_objetivos')

  }
  /** Comprobación de que el nombre no exista*/
  private nombreDuplicidad(): ValidatorFn {
    return (formGroup: FormGroup) => {
      var nombre = ""
      if(this.modal){
        nombre = document.getElementById('nombre')["value"];
      }else if(this.modal2){
        nombre = document.getElementById('nombreedit')["value"];
      }
      const existe = this.objetivos.find( element => {
        if(this.modal2){
          return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() && element.id !== this.objetivoo.id );
        }
        return ( procesamiento.procesamiento_Texto(element.nombre).toLowerCase() === nombre.toLowerCase() );
      });
      if(existe) return {isValid:true};      
      
      return null;
    };
  }
  public nombreExiste(): boolean{    
    return  this.formNuevo.hasError('isValid');
  }
}