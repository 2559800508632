import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export class Matriz_FLOA{
  public type?: string;
  public name?: string;
  public perspectiveId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class MatrizFloa2Service {
  private apiURL = environment.apiDevURL_institucion;
  private headers = new HttpHeaders().append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + sessionStorage.getItem('auth_token'));

  constructor( private http: HttpClient ) { }

  public getPlanificacion(idPlan): Observable<any>{
    const apiURL = this.apiURL+"planificacion/"+idPlan;
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  public getFLOA(): Observable<any>{
    const apiURL = this.apiURL+"matriz";
    return this.http.get(`${apiURL}`, {headers: this.headers});
  }
  
  public postFLOA(floa: any): Observable<any>{
    const matrizz: Matriz_FLOA = {
      type: floa.tipo,
      name: floa.nombre,
      perspectiveId: Number(floa.perspectiveId),
    }
    const body = JSON.stringify(matrizz);
    const apiURL = this.apiURL+"matriz";
    return this.http.post(`${apiURL}`, body, {headers: this.headers});
  }
  public patchFLOA(floa: any): Observable<any>{
    const matrizz: Matriz_FLOA = {
      type: floa.tipo,
      name: floa.nombre,
      perspectiveId: Number(floa.perspectiveId),
    }
    const body = JSON.stringify(matrizz);
    const apiURL = this.apiURL+"matriz/"+floa.id;
    return this.http.patch(`${apiURL}`, body, {headers: this.headers});
  }
  public deleteFLOA(floa: any): Observable<any>{
    const apiURL = this.apiURL+"matriz/"+floa.id;
    return this.http.delete(`${apiURL}`, {headers: this.headers});
  }
}
